import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import FormInput from 'components/ui/composed/formInput/FormInput';
import { useFormik } from 'formik';
import useDebouncedValidate from 'hooks/useDebouncedValidate';
import { ReactElement, useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WeighbridgeDeviceResponse } from 'store/weighbridges/weighbridge';
import SetupStyledComponents from 'views/setup/styles/Setup.styles';
import { ReloadOutlined } from '@ant-design/icons';
import { Button as AntButton } from 'antd';
import { isEmpty } from 'lodash';
import DeviceReaderView from '../components/DeviceReaderView';
import DeviceCalibrationStyledComponents from '../styles/DeviceCalibration.styles';
import useSetup from 'hooks/useSetup';

const { Main, Container, ScreenTitle, ScreenSubtitle, ButtonContainer, ScreenTitleSubtitleContainer } =
    SetupStyledComponents;
const { ReloadButtonContainer, DeviceReaderViewContainer, DeviceReaderText, DeviceReaderTextButtonsContainer, Text } =
    DeviceCalibrationStyledComponents;

interface FormInitialValues {
    location: string;
    weighbridgeLabel: string;
    device: WeighbridgeDeviceResponse | null | undefined;
}

enum Status {
    INITIAL,
    DEVICE_POSITION,
    DEVICE_CALIBRATION,
}

const initialValues: FormInitialValues = {
    location: '',
    weighbridgeLabel: '',
    device: null,
};

const DeviceCalibration = (): ReactElement => {
    // * hooks
    const { t } = useTranslation(['common', 'weighbridge']);

    const navigate = useNavigate();

    const isInSetupProcess = useSetup();

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: () => {},
    });

    useDebouncedValidate(formik);

    // * useState
    const [status, setStatus] = useState<Status>(Status.INITIAL);

    const [pressedNo, setPressedNo] = useState<boolean>(false);

    // * useMemo
    const isStatusInitial = useMemo((): boolean => status === Status.INITIAL, [status]);

    const screenTitle = useMemo(
        (): string =>
            ({
                [Status.INITIAL]: t('deviceCalibration.title.initial', { ns: 'weighbridge' }),
                [Status.DEVICE_POSITION]: t('deviceCalibration.title.devicePosition', { ns: 'weighbridge' }),
                [Status.DEVICE_CALIBRATION]: t('deviceCalibration.title.deviceCalibration', { ns: 'weighbridge' }),
            }[status]),
        [status, t]
    );

    const deviceReaderText = useMemo((): string => {
        if (status === Status.DEVICE_POSITION) {
            return pressedNo
                ? `Reposition your device to capture the full weighbridge screen.\nWhen the screen is fully displayed click Yes to continue the calibration process.`
                : 'Is the weighbridge display shown fully in the screen above?';
        } else {
            return pressedNo
                ? 'Please attempt to connect a different Ddispatch device and contact your Ddispatch help desk for assistance in calibrating your device.'
                : 'Does the reading match the weight shown on the screen?';
        }
    }, [pressedNo, status]);

    const hideReaderView = useMemo(
        (): boolean => status === Status.DEVICE_CALIBRATION && pressedNo,
        [pressedNo, status]
    );

    // * useCallback
    const handleBack = useCallback((): void => {
        setPressedNo(false);

        if (status !== Status.INITIAL) {
            setStatus((prev) => prev - 1);
        } else {
            navigate(-1);
        }
    }, [navigate, status]);

    const handleNext = useCallback((): void => {
        setPressedNo(false);

        // TODO Finalize the calibration

        if (status !== Status.DEVICE_CALIBRATION) {
            setStatus((prev) => prev + 1);
        } else {
            navigate('/weighbridges', { state: { finishedSetupProcess: isInSetupProcess } });
        }
    }, [isInSetupProcess, navigate, status]);

    const handleNo = useCallback((): void => {
        setPressedNo(true);
    }, []);

    const handleContactHelpCenter = useCallback((): void => {
        // TODO Contact Help Center
    }, []);

    // * useEffect
    useEffect(() => {
        // TODO Check if the selected weighbridge actually has a device that is present
        const deviceAvailable = true;

        if (!deviceAvailable) {
            navigate(-1);
        }

        // TODO Get weighbridge data
    }, [navigate]);

    return (
        <Main>
            <Container>
                <ScreenTitleSubtitleContainer>
                    <ScreenTitle>{screenTitle}</ScreenTitle>
                </ScreenTitleSubtitleContainer>

                <Row gutter={[30, 15]}>
                    <Col xl={!isStatusInitial ? 8 : 12}>
                        <FormInput
                            label={t('deviceCalibration.location.label', { ns: 'weighbridge' })}
                            fieldProps={formik.getFieldProps('location')}
                            fieldMeta={formik.getFieldMeta('location')}
                        />
                    </Col>
                    <Col xl={!isStatusInitial ? 8 : 12}>
                        <FormInput
                            label={t('weighbridge.label.label', { ns: 'weighbridge' })}
                            fieldProps={formik.getFieldProps('weighbridgeLabel')}
                            fieldMeta={formik.getFieldMeta('weighbridgeLabel')}
                        />
                    </Col>
                    <Col xl={!isStatusInitial ? 8 : 12}>
                        <Row gutter={15}>
                            <Col flex={1}>
                                <FormInput
                                    label={t('deviceCalibration.selectWeighbridgeReader.label', { ns: 'weighbridge' })}
                                    required={isStatusInitial}
                                    fieldProps={formik.getFieldProps('reader')}
                                    fieldMeta={formik.getFieldMeta('reader')}
                                />
                            </Col>
                            {isStatusInitial && (
                                <Col>
                                    <ReloadButtonContainer>
                                        <AntButton shape="circle" type="dashed" icon={<ReloadOutlined />}></AntButton>
                                    </ReloadButtonContainer>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    {isStatusInitial && (
                        <Col xl={12}>
                            <Text>{t('deviceCalibration.deviceNotAppearingText', { ns: 'weighbridge' })}</Text>
                        </Col>
                    )}
                </Row>

                {hideReaderView && (
                    <ScreenSubtitle>
                        {t('deviceCalibration.connectDifferentDeviceAndContactHelpDesk', { ns: 'weighbridge' })}
                    </ScreenSubtitle>
                )}

                {!isStatusInitial && !hideReaderView && (
                    <DeviceReaderViewContainer>
                        <DeviceReaderView />
                        <DeviceReaderTextButtonsContainer calibrating={status === Status.DEVICE_CALIBRATION}>
                            <DeviceReaderText>{deviceReaderText}</DeviceReaderText>
                            {status === Status.DEVICE_CALIBRATION && (
                                <div className="inputs">
                                    <div>
                                        <FormInput
                                            label={t('deviceCalibration.deviceReading.label', { ns: 'weighbridge' })}
                                            required
                                            fieldProps={formik.getFieldProps('deviceReading')}
                                            fieldMeta={formik.getFieldMeta('deviceReading')}
                                        />
                                    </div>
                                    <div>
                                        <FormInput
                                            label={t('deviceCalibration.unitOfMeasurement.label', {
                                                ns: 'weighbridge',
                                            })}
                                            required
                                            fieldProps={formik.getFieldProps('unitOfMeasurement')}
                                            fieldMeta={formik.getFieldMeta('unitOfMeasurement')}
                                        />
                                    </div>
                                </div>
                            )}
                            <ButtonContainer noMarginTop>
                                <Button type="primary" onClick={handleNext}>
                                    {t('buttons.yes', { ns: 'common' })}
                                </Button>
                                {!pressedNo && <Button onClick={handleNo}>{t('buttons.no', { ns: 'common' })}</Button>}
                            </ButtonContainer>
                        </DeviceReaderTextButtonsContainer>
                    </DeviceReaderViewContainer>
                )}

                <ButtonContainer>
                    <Button onClick={handleBack}>{t('buttons.back', { ns: 'common' })}</Button>
                    {hideReaderView && (
                        <Button type="primary" onClick={handleContactHelpCenter}>
                            {t('buttons.contactDdispatchHelpCenter', { ns: 'common' })}
                        </Button>
                    )}
                    {isStatusInitial && (
                        <Button type="primary" onClick={handleNext} disabled={!isEmpty(formik.errors)}>
                            {t('buttons.next', { ns: 'common' })}
                        </Button>
                    )}
                </ButtonContainer>
            </Container>
        </Main>
    );
};

export default DeviceCalibration;
