import { Row } from 'antd';
import { H4Style } from 'components/ui/base/typography/Typography.styles';
import useDeclarations from 'hooks/useDeclarations';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import DeclarationInformation from '../../common/DeclarationInformation';
import DocumentsTable from './components/DocumentsTable';
import UploadDocuments from './components/UploadDocuments';
import { DeclarationInformationWrapper, DocumentUploadButton, MainDiv } from './CustomerDocuments.styles';
import { UploadOutlined } from '@ant-design/icons';
import DocumentForm from './components/DocumentForm';
import { CmsFileAndFormAction, DeclarationDocument } from './types';
import { DeclarationCountry } from '../../../../store/declarations/enums/common/declaration-country';

interface Props {
    customerId?: string;
    formId?: string;
}

const CustomerDocuments: FC<Props> = () => {
    const { customerId, formId, country } = useCustomerDocuments();
    const { t } = useTranslation('form');
    const { documents: cmsFiles, listDeclarationDocuments: listCmsFiles, declaration } = useDeclarations();

    const [cmsFileAndFormAction, setCmsFileAndFormAction] = useState<CmsFileAndFormAction>({
        file: undefined,
        action: undefined,
    });
    const [declarationDocuments, setDeclarationDocuments] = useState<DeclarationDocument[] | undefined>(
        declaration?.uploadedFiles
    );

    useEffect(() => {
        listCmsFiles(formId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDeclarationDocumentBasedOnSelectedCmsFile = useMemo(
        () =>
            declarationDocuments?.find(
                (declarationDocument) => declarationDocument.fileId === cmsFileAndFormAction.file?.id
            ),
        [declarationDocuments, cmsFileAndFormAction.file?.id]
    );

    const inForm = useMemo(() => cmsFileAndFormAction.file, [cmsFileAndFormAction.file]);

    const addDeclarationDocument = (newDocument: DeclarationDocument) =>
        setDeclarationDocuments((allDocs) => allDocs && [...allDocs, newDocument]);

    return (
        <>
            <DeclarationInformationWrapper>
                <div style={{ width: 'fit-content', marginLeft: 'auto' }}>
                    <DeclarationInformation />
                </div>
            </DeclarationInformationWrapper>
            <MainDiv style={{ height: 'auto', filter: `drop-shadow(0 5px 4px rgba(9, 0, 255, 0.07))` }}>
                <Row justify="space-between" style={{ marginBottom: '1.5rem' }}>
                    <H4Style>{t('customerDocuments.title')}</H4Style>

                    {!inForm && (
                        <DocumentUploadButton
                            size="large"
                            type="link"
                            onClick={() => {
                                document.getElementById('file-upl')?.click();
                            }}
                        >
                            {t('customerDocuments.upload_document')} <UploadOutlined />
                        </DocumentUploadButton>
                    )}
                </Row>

                {!inForm ? (
                    <>
                        <UploadDocuments
                            declarationId={formId}
                            customerId={customerId}
                            country={country}
                            listCmsFiles={() => listCmsFiles(formId)}
                            setCmsFileAndFormAction={({ file, action }: CmsFileAndFormAction) =>
                                setCmsFileAndFormAction({ file, action })
                            }
                            addDeclarationDocument={addDeclarationDocument}
                        />

                        <DocumentsTable
                            country={country}
                            declarationId={formId}
                            listCmsFiles={() => listCmsFiles(formId)}
                            setCmsFileAndFormAction={({ file, action }: CmsFileAndFormAction) =>
                                setCmsFileAndFormAction({ file, action })
                            }
                            cmsFilesList={cmsFiles?.list}
                            declarationDocuments={declarationDocuments}
                            deleteDeclarationDocument={(deletedDocument: DeclarationDocument) =>
                                setDeclarationDocuments((allDocs) =>
                                    allDocs?.filter((doc) => doc.fileId !== deletedDocument.fileId)
                                )
                            }
                            submitDeclarationDocuments={(submittedDocumentIds: string[]) =>
                                setDeclarationDocuments((allDocs) =>
                                    allDocs?.reduce((declarationDocs: DeclarationDocument[], declarationDoc) => {
                                        if (
                                            submittedDocumentIds.some(
                                                (submittedId) => submittedId === declarationDoc.fileId
                                            )
                                        ) {
                                            declarationDocs.push({ ...declarationDoc, status: 'SUBMITTED' });
                                        } else {
                                            declarationDocs.push(declarationDoc);
                                        }

                                        return declarationDocs;
                                    }, [])
                                )
                            }
                        />
                    </>
                ) : (
                    <DocumentForm
                        declarationId={formId}
                        listCmsFiles={() => listCmsFiles(formId)}
                        cmsFileAndFormAction={cmsFileAndFormAction}
                        declarationDocument={getDeclarationDocumentBasedOnSelectedCmsFile}
                        cancelForm={() => setCmsFileAndFormAction({ file: undefined, action: undefined })}
                        addDeclarationDocument={addDeclarationDocument}
                        updateDeclarationDocument={(updatedDocument: DeclarationDocument) =>
                            setDeclarationDocuments((allDocs) =>
                                allDocs?.map((declarationDoc) =>
                                    declarationDoc.id === updatedDocument.id ? updatedDocument : declarationDoc
                                )
                            )
                        }
                    />
                )}
            </MainDiv>
        </>
    );
};
export default CustomerDocuments;

export function useCustomerDocuments() {
    return useOutletContext<{
        customerId: string;
        formId: string;
        country: DeclarationCountry;
    }>();
}
