import { FormikProps, FormikProvider } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MultipleItemsCard from '../../../common/MultipleItemsCard';
import AdditionalFiscalReferencesBlock from '../../export/blocks/AdditionalFiscalReferencesBlock';
import AdditionalInformationBlock from '../../export/blocks/AdditionalInformationBlock';
import AdditionalSupplyChainActorBlock from '../../export/blocks/AdditionalSupplyChainActorBlock';
import ContainerIdentificationNumberBlock from '../../export/blocks/ContainerIdentificationNumberBlock';
import GuaranteeReferenceBlock from '../../export/blocks/GuaranteeReferenceBlock';
import HolderOfAuthorisationIdentificationNumberBlock from '../../export/blocks/HolderOfAuthorisationIdentificationNumberBlock';
import PreviousDocumentsBlock from '../../export/blocks/PreviousDocumentsBlock';
import CountryOfDestinationCard from '../../export/cards/CountryOfDestinationCard';
import CustomerCard from '../../export/cards/CustomerCard';
import CustomsOfficesCard from '../../export/cards/CustomsOfficeCard';
import ExchangeRateCard from '../../export/cards/ExchangeRateCard';
import GrossMassCard from '../../export/cards/GrossMassCard';
import IdentificationOfWarehouseCard from '../../export/cards/IdentificationOfWarehouseCard';
import LocationOfGoodsCard from '../../export/cards/LocationOfGoodsCard';
import MasterDetailsCard from '../../export/cards/MasterDetailsCard';
import ModeOfTransportCard from '../../export/cards/ModeOfTransportCard';
import NationalityOfActiveMeansOfTransportCrossingTheBorderCard from '../../export/cards/NationalityOfActiveMeansOfTransportCrossingTheBorderCard';
import NatureOfTransactionCard from '../../export/cards/NatureOfTransactionCard';
import SupervisingCustomsOfficeCard from '../../export/cards/SupervisingCustomsOfficeCard';
import TotalAmountInvoicedCard from '../../export/cards/TotalAmountInvoicedCard';
import { ValidationSchema } from '../../export/validations/validations';
import AdditionsDeductionsCard from '../cards/AdditionsDeductionsCard';
import DeferredPaymentCard from '../cards/DeferredPaymentCard';
import DeliveryTermsCard from '../cards/DeliveryTermsCard';
import IdentityOfMeansOfTransportOnArrivalCard from '../cards/IdentityOfMeansOfTransportOnArrivalCard';
import PlaceOfLoadingAirportCodeCard from '../cards/PlaceOfLoadingAirportCodeCard';
import TransportCostsToTheFinalDestinationCard from '../cards/TransportCostsToTheFinalDestinationCard';
import { cdsImportProductSectionValidation } from './UkH2ProductsSection';
import useDeclarations from 'hooks/useDeclarations';
import shouldHideEntity from '../../../../../utils/shouldHideEntity';
import { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';

export const cdsImportFormValidation: ValidationSchema = {
    selfValidators: [],
    childValidators: {
        'goodsShipment.governmentAgencyGoodsItem': {
            selfValidators: [cdsImportProductSectionValidation],
        },
    },
};

interface Props {
    formik: FormikProps<any>; // FIXME use correct type
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    expandAll?: boolean;
    declarationInternalType?: string;
}

const UkImportH2Form: FC<Props> = ({ formik, toggleHelp, viewOnly, expandAll }) => {
    const { t } = useTranslation('form');
    const { declaration } = useDeclarations();

    const totalNumberOfCards = useMemo(() => 21, []);
    return (
        <FormikProvider value={formik}>
            <MasterDetailsCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard={`master-details-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={1}
                declarationInternalType={declaration?.declarationInternalType}
            />

            <CustomerCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard="importer-card"
                path="goodsShipment.importer"
                title={t('importer')}
                ucc="3/15"
                viewOnly={viewOnly}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={2}
            />

            <CustomerCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="exporter-card"
                path="exporter"
                title={t('exporter')}
                ucc="3/1"
                viewOnly={viewOnly}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={2}
            />

            <CustomerCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="seller-card"
                path="goodsShipment.seller"
                title={t('seller')}
                ucc="3/24"
                fieldOptions={{ 'communication.id': { show: true } }}
                viewOnly={viewOnly}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={2}
            />

            <CustomerCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="buyer-card"
                path="goodsShipment.buyer"
                title={t('buyer')}
                ucc="3/26"
                fieldOptions={{ 'communication.id': { show: true } }}
                viewOnly={viewOnly}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={2}
            />

            <CustomerCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard="declarant-card"
                path="declarant"
                title={t('declarant')}
                ucc="3/17"
                fieldOptions={{
                    id: { required: true },
                }}
                viewOnly={viewOnly}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={3}
            />

            <CustomerCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard="agent-card"
                path="agent"
                title={t('representative')}
                fieldOptions={{ functionCode: { show: true } }}
                ucc="3/19"
                viewOnly={viewOnly}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={4}
            />

            <DeliveryTermsCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="delivery-terms"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={6}
            />

            <MultipleItemsCard
                title={t('Holder of the authorisation identification No.')}
                keyCard="uk-import-holder-of-authorisation"
                viewOnly={viewOnly}
                path="authorisationHolder"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={5}
                expandAll={expandAll}
                initialValues={{ id: '', categoryCode: '' }}
                list={(list) => [
                    { field: t('authorisationHolder.identificationNumber'), value: list.id },
                    { field: t('authorisationHolder.authorisationType'), value: list.categoryCode },
                ]}
                formik={formik}
                required
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <FormCardContainer>
                        <HolderOfAuthorisationIdentificationNumberBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <IdentificationOfWarehouseCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard="identification-of-warehouse-card"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={6}
                fieldOptions={{ id: { required: true }, typeCode: { required: true } }}
            />

            <TotalAmountInvoicedCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="total-amount-invoiced"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={6}
            />

            <ExchangeRateCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="exchange-rate"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={6}
            />

            <TransportCostsToTheFinalDestinationCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="transport-to-the-final-destination"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={6}
            />

            <PlaceOfLoadingAirportCodeCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="transport-to-the-final-destination"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={6}
            />

            <NationalityOfActiveMeansOfTransportCrossingTheBorderCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="nationality-of-active-means"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={6}
            />

            <CountryOfDestinationCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard="uk-import-country-of-destination"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={7}
                fieldOptions={{ id: { required: false }, countryCode: { required: false } }}
            />

            <LocationOfGoodsCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard="uk-import-location-of-goods"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={8}
            />

            <SupervisingCustomsOfficeCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard={`uk-import-supervising-customs-office`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={9}
                fieldOptions={{ id: { required: true } }}
            />

            <CustomsOfficesCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard="uk-import-customs-office"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={10}
                isUkCdsImport={true}
            />

            <GrossMassCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard="uk-import-grossmass"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={11}
                fieldOptions={{
                    totalPackageQuantity: { validation: { max: 8 } },
                    totalGrossMassMeasure: { validation: { float: [16, 6] } },
                }}
            />

            <ModeOfTransportCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard={`uk-import-mode-of-transport`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={12}
                isUkCdsImport={true}
            />

            <IdentityOfMeansOfTransportOnArrivalCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard={`uk-import-identity-of-means-of-transport-on-arrival`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={13}
            />

            <MultipleItemsCard
                title={t('guaranteeReference.title')}
                keyCard="uk-import-guaranteeReference"
                viewOnly={viewOnly}
                path="obligationGuarantee"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={15}
                expandAll={expandAll}
                initialValues={{
                    securityDetailsCode: '',
                    referenceId: '',
                    id: '',
                    accessCode: '',
                    amountAmount: '',
                    amountAmountCurrencyId: '',
                    guaranteeOffice: { id: '' },
                }}
                list={(list) => [
                    { field: t('guaranteeType'), value: list.securityDetailsCode },
                    { field: t('guaranteeReference.grn'), value: list.referenceId },
                    { field: t('guaranteeReference.otherGuaranteeReference'), value: list.id },
                    { field: t('guaranteeReference.accessCode'), value: list.accessCode },
                    { field: t('guaranteeReference.amountOfImportDuty'), value: list.amountAmount },
                    { field: t('guaranteeReference.amountOfImportDutyCurrency'), value: list.amountAmountCurrencyId },
                    { field: t('guaranteeReference.guaranteeOfficeID'), value: list.guaranteeOffice?.id },
                ]}
                formik={formik}
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <FormCardContainer>
                        <GuaranteeReferenceBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NatureOfTransactionCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                propsPathPrefix="goodsShipment"
                viewOnly={viewOnly}
                keyCard={`nature-of-transaction`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={16}
            />

            <MultipleItemsCard
                title={t('previousDocuments')}
                keyCard="uk-import-previousDocuments"
                viewOnly={viewOnly}
                path="goodsShipment.previousDocument"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={17}
                expandAll={expandAll}
                initialValues={{ categoryCode: '', typeCode: '', id: '', lineNumeric: '' }}
                list={(list) => [
                    { field: t('previousDocumentCategory'), value: list.categoryCode },
                    { field: t('previousDocumentType'), value: list.typeCode },
                    { field: t('previousDocumentReference'), value: list.id },
                    { field: t('previousDocumentCode'), value: list.lineNumeric },
                ]}
                formik={formik}
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <FormCardContainer>
                        <PreviousDocumentsBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title={t('additionalInformation.title')}
                keyCard="uk-export-additionalInformation"
                viewOnly={viewOnly}
                path="additionalInformation"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={18}
                expandAll={expandAll}
                initialValues={{ statementCode: '', statementDescription: '' }}
                list={(list) => [
                    { field: t('additionalInformationCode'), value: list.statementCode },
                    { field: t('additionalInformationDescription'), value: list.statementDescription },
                ]}
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalInformationBlock
                            path={path}
                            fieldOptions={{ statementDescription: { validation: { max: 512 } } }}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <DeferredPaymentCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                propsPathPrefix="additionalDocument"
                viewOnly={viewOnly}
                keyCard={`uk-import-deferred-payment`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={16}
            />

            <MultipleItemsCard
                title={t('AdditionalSupplyChain')}
                keyCard="uk-import-additional-supply-chain-actor"
                viewOnly={viewOnly}
                path="goodsShipment.aeoMutualRecognitionParty"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={19}
                expandAll={expandAll}
                initialValues={{ id: '', roleCode: '' }}
                list={(list) => [
                    { field: t('identificationNumber'), value: list.id },
                    { field: t('roleCode'), value: list.roleCode },
                ]}
                formik={formik}
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalSupplyChainActorBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title={t('AddFiscalRefIdentification')}
                keyCard="uk-import-additional-references"
                viewOnly={viewOnly}
                path="goodsShipment.domesticDutyTaxParty"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={20}
                expandAll={expandAll}
                initialValues={{ id: '', roleCode: '' }}
                list={(list) => [
                    { field: t('vatIdentificationNumber'), value: list.id },
                    { field: t('roleCode'), value: list.roleCode },
                ]}
                formik={formik}
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalFiscalReferencesBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <AdditionsDeductionsCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                propsPathPrefix="goodsShipment.customsValuation.chargeDeduction"
                viewOnly={viewOnly}
                keyCard={`uk-import-addition-deductions`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={16}
            />

            <MultipleItemsCard
                title={t('containerIdentificationNumbers')}
                keyCard="uk-import-container-identification-number"
                viewOnly={viewOnly}
                path="goodsShipment.consignment.transportEquipment"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={21}
                expandAll={expandAll}
                initialValues={{ id: '' }}
                list={(list) => [{ field: t('containerIdentificationNumbers'), value: list.id }]}
                formik={formik}
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <FormCardContainer>
                        <ContainerIdentificationNumberBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
        </FormikProvider>
    );
};

export default UkImportH2Form;
