import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'id';

export const sealNumberBlockValidation: {
    childValidators: {
        'goodsShipment.consignment.transportEquipment': {
            childValidators: {
                seal: ValidationSchema<Fields>;
            };
        };
    };
} = {
    childValidators: {
        'goodsShipment.consignment.transportEquipment': {
            childValidators: {
                seal: {
                    childValidators: {
                        id: [validators.max(20)],
                    },
                    stopAtNull: true,
                },
            },
        },
    },
};

export const sealNumberFieldsValidation =
    sealNumberBlockValidation.childValidators['goodsShipment.consignment.transportEquipment'];

interface Props extends BlockProps<Fields> {}

const SealNumberBlock = ({
    path = 'goodsShipment.consignment.transportEquipment',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '7/18', []);
    return (
        <>
            <MultipleDeclarationField pathPrefix={path} parent="seal" name="id">
                {({ form, field }, controls) => (
                    <FormInput
                        refNumber={ucc}
                        label={t('seals.sealId')}
                        maxLength={20}
                        {...getFormikProps(field.name, form)}
                        multipleF={controls}
                        condensed
                    />
                )}
            </MultipleDeclarationField>
        </>
    );
};

export default SealNumberBlock;
