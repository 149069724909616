import { DefaultOptionType } from 'antd/lib/select';
import { TestProps } from 'core/utils/testTypes';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useCountries from 'hooks/useCountries';
import { FC, FocusEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCountriesToSelect } from '../selects/utils';
import DeclarationSelect, { MultipleProps } from './formSelect/DeclarationSelect';

export interface CountryProps extends TestProps {
    refNumber?: string;
    label?: string;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    icon?: boolean;
    required?: boolean;
    viewOnly?: boolean;
    multiple?: MultipleProps;
    tooltip?: string;
    refClicked?: (refNumber: string | number) => void;
    disabled?: boolean;
    condensed?: boolean;
    multipleF?: {
        onAdd: () => void;
        onRemove: () => void;
        canAdd?: boolean;
        canRemove?: boolean;
    };
    onChange?: (value: any, option: DefaultOptionType | DefaultOptionType[]) => void;
    onBlur?: FocusEventHandler<HTMLInputElement>;
}

const CountrySelect: FC<CountryProps> = ({
    refNumber,
    label,
    fieldProps,
    fieldMeta,
    required,
    fieldHelper,
    viewOnly,
    multiple,
    tooltip,
    refClicked,
    disabled,
    testId,
    condensed,
    multipleF,
    onChange,
    onBlur,
}) => {
    const { t } = useTranslation('common');
    const { countries } = useCountries();

    const countriesList = useMemo(() => normalizeCountriesToSelect(countries.list), [countries.list]);
    return (
        <>
            {countries && (
                <DeclarationSelect
                    viewOnly={viewOnly}
                    required={required}
                    refNumber={refNumber}
                    label={!label ? t('address.country') : label}
                    tooltip={tooltip}
                    multipleF={multipleF}
                    selectOptions={countriesList}
                    fieldProps={fieldProps}
                    fieldMeta={fieldMeta}
                    fieldHelper={fieldHelper}
                    multiple={multiple}
                    refClicked={refClicked}
                    disabled={disabled}
                    testId={testId}
                    condensed={condensed}
                    onChange={onChange}
                    fieldEvents={{
                        onBlur: (e: any) => onBlur?.(e),
                    }}
                />
            )}
        </>
    );
};

export default CountrySelect;
