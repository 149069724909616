import { Button as AButton, ButtonProps as AButtonProps, ModalProps as AModalProps, Modal as AModal } from 'antd';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useFormik, useFormikContext } from 'formik';
import useCodelists from 'hooks/useCodelists';
import { createContext, ReactElement, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import styled from 'styled-components';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { FormCardContainer } from '../cards/NewFormCard';
import CardList from '../list-card/CardList';
import { CloseOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import useProducts from 'hooks/useProducts';
import { useLocation, useParams } from 'react-router-dom';
import { hasNoValuesDeep } from '../modal-edit-list-item/ModalEditCardListItem';
import { omit } from 'lodash';
import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import { getDateFormatForBox44 } from './box-44-utils';

export const box44PathAndFieldNames = {
    H1: {
        masterPath: 'goodsShipment.producedDocumentsWritingOff',
        productPath: 'producedDocumentsWritingOff',
        documentTypeName: 'documentType',
        documentIdentifierName: 'documentIdentifier',
    },
    H7: {
        masterPath: 'goodsShipment.documentsAuthorisations.supportingDocument',
        productPath: 'documentsAuthorisations.supportingDocument',
        documentTypeName: 'supportingDocumentType',
        documentIdentifierName: 'supportingDocumentReferenceNumber',
    },
};

const SModal = styled(AModal)`
    .ant-modal-content {
        border-radius: 8px;
    }

    .ant-modal-close {
        display: none;
    }
`;

export const SBox44Button = styled(AButton)`
    background-color: #0040e4;
    padding: 0 1rem;
    border-radius: 4px;

    color: white;
    font-weight: 400;

    :focus,
    :hover {
        background-color: #0041e4dc;
        color: white;
    }
`;

interface ButtonProps extends AButtonProps {}
const Button = (props: ButtonProps): ReactElement => {
    const formik = useFormikContext();
    const { productId } = useParams();
    const location = useLocation();
    const { products } = useProducts();
    const { path } = useBox44Context();

    const numberOfCodes = useMemo(() => {
        const value = (formik.getFieldMeta(path).value ?? {}) as Record<string, string[]>;
        return Object.values(value).reduce((acc, v) => acc + v.length, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.getFieldMeta(path).value, path]);

    const productNumber = useMemo(() => {
        if (!(location.pathname.includes('products') && productId && products)) return undefined;
        return products.list.findIndex(({ id }) => id === productId) + 1;
    }, [location.pathname, productId, products]);

    return (
        <SBox44Button size="small" {...props}>
            Box 44 Codes ({numberOfCodes}){productNumber !== undefined && ` | Item ${productNumber}`}
        </SBox44Button>
    );
};

export const HollowButton = styled(AButton)`
    background-color: transparent;
    border-color: #161616;
    padding: 0 1rem;
    border-radius: ${({ shape }) => (shape === 'circle' ? '50%' : '4px')};

    color: #161616;
    font-weight: 400;

    transition: 100ms;

    :focus {
        background-color: transparent;
        border-color: #161616;
        color: #161616;
    }

    :hover {
        background-color: #161616;
        border-color: #161616;
        color: white;
    }
`;

export interface IBox44Context {
    path: string;
    documentTypeName: string;
    documentIdentifierName: string;
}
export const Box44Context = createContext<IBox44Context>({} as IBox44Context);
export const useBox44Context = () => useContext(Box44Context);

const dateFields = ['1D24'];

interface ModalProps extends AModalProps {}
const Modal = ({ ...modalProps }: ModalProps & { viewOnly?: boolean | undefined }): ReactElement => {
    const { viewOnly } = modalProps;
    const { codelists } = useCodelists();
    const { t } = useTranslation('form');
    const formik = useFormikContext();
    const { path = '', documentTypeName, documentIdentifierName } = useBox44Context();
    const [show, setShow] = useState<boolean>(false);
    const [edit, setEdit] = useState<Record<string, string> | undefined>(undefined);

    if (!path || !documentTypeName || !documentIdentifierName) {
        throw new Error('Box44 modal requires a Box44Context.Provider');
    }
    if (!formik) {
        throw new Error('Box44 modal requires a formik context provider');
    }

    const lFormik = useFormik({
        initialValues: {
            [documentTypeName]: '',
            [documentIdentifierName]: '',
        },
        onSubmit: () => {},
    });

    const disabledAddButton = useMemo(() => hasNoValuesDeep(omit(lFormik.values, 'id')), [lFormik.values]);

    const codeList = useMemo(() => {
        const box44Items = (formik?.getFieldProps(path).value ?? {}) as Record<string, string[]>;
        const currentCodes = Object.keys(box44Items);

        // Remove the code being edited from the exclude list
        if (edit) currentCodes.splice(currentCodes.indexOf(edit[documentTypeName]), 1);

        const shouldNotExclude = (code: string) => !currentCodes.includes(code);
        const list = (codelists?.irelandCommonDocumentsTypes ?? []).filter((l) => shouldNotExclude(l.code));
        return normalizeCodesToSelect(list);
    }, [codelists?.irelandCommonDocumentsTypes, documentTypeName, edit, formik, path]);

    const handleShowForm = () => {
        setShow(true);
    };
    const handleHideForm = () => {
        lFormik.resetForm();
        setEdit(undefined);
        setShow(false);
    };

    const handleAdd = () => {
        const value = lFormik.values;
        const originalValue = { ...((formik.getFieldMeta(path).value ?? {}) as Record<string, string[]>) };

        if (!originalValue[value[documentTypeName]]) originalValue[value[documentTypeName]] = [];
        originalValue[value[documentTypeName]].push(value[documentIdentifierName] ?? 'NAI');

        formik.setFieldValue(path, { ...originalValue });

        lFormik.resetForm();
        handleHideForm();
    };
    const handleEdit = () => {
        if (edit === undefined) return;
        const value = lFormik.values;
        const originalValue = { ...((formik.getFieldMeta(path).value ?? {}) as Record<string, string[]>) };

        /**
         * How to edit?
         * If documentType.new !== documentType.prev
         * -- Remove the documentIdentifier from the list
         * -- If the list is empty, remove the key
         * -- Do same as create
         * else
         * -- If there is same documentIdentifier, remove the current one
         * -- otherwise, replace with new value
         */
        const documentType = {
            prev: edit[documentTypeName],
            new: value[documentTypeName],
        };
        const documentIdentifier = {
            prev: edit[documentIdentifierName],
            new: value[documentIdentifierName],
        };
        if (documentType.new !== documentType.prev) {
            const index = originalValue[documentType.prev].indexOf(documentIdentifier.prev);
            originalValue[documentType.prev].splice(index, 1);

            if (!originalValue[documentType.prev].length) delete originalValue[documentType.prev];

            if (!originalValue[documentType.new]) originalValue[documentType.new] = [];
            originalValue[documentType.new] = [...originalValue[documentType.new], documentIdentifier.new ?? 'NAI'];
        } else {
            const index = originalValue[documentType.prev].indexOf(documentIdentifier.prev);
            const sameAsNewIdentifier = originalValue[documentType.prev].indexOf(documentIdentifier.new);

            if (sameAsNewIdentifier !== -1) originalValue[documentType.prev].splice(index);
            else {
                originalValue[documentType.prev] = [...originalValue[documentType.prev]];
                originalValue[documentType.prev][index] = documentIdentifier.new ?? 'NAI';
            }
        }

        formik.setFieldValue(path, { ...originalValue });

        setEdit(undefined);
        handleHideForm();
    };
    const recordHandleEdit = (_: number, obj: { value: string | undefined }[]) => {
        const box44Obj = { [documentTypeName]: obj[0].value!, [documentIdentifierName]: obj[1].value! };
        lFormik.setValues(box44Obj);
        setEdit(box44Obj);
        handleShowForm();
    };
    const recordHandleDelete = (_: number, obj: { value: string | undefined }[]) => {
        const originalValue = { ...((formik.getFieldMeta(path).value ?? {}) as Record<string, string[]>) };
        const box44Obj = { [documentTypeName]: obj[0].value!, [documentIdentifierName]: obj[1].value! };

        const documentType = box44Obj[documentTypeName];
        const documentIdentifier = box44Obj[documentIdentifierName];

        const index = originalValue[documentType].indexOf(documentIdentifier);
        originalValue[documentType].splice(index);

        if (!originalValue[documentType].length) delete originalValue[documentType];

        formik.setFieldValue(path, { ...originalValue });
    };

    const dataList = useMemo(() => {
        const data = (formik.getFieldMeta(path).value ?? {}) as Record<string, string[]>;
        if (Array.isArray(data)) return [];
        return Object.entries(data)?.flatMap(([documentType, documentIdentifiers]) => {
            return documentIdentifiers.map((documentIdentifier) => [
                { field: t('documentType'), value: documentType },
                { field: t('documentIdentifier'), value: documentIdentifier },
            ]);
        });
    }, [formik, path, t]);

    return (
        <SModal footer={false} width="80rem" {...modalProps}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <span style={{ fontWeight: 'bold', fontSize: '1.8rem' }}>
                        Additional Documents and References (Box 44)
                    </span>
                    {!viewOnly && (
                        <HollowButton onClick={handleShowForm} size="small">
                            Add Item +
                        </HollowButton>
                    )}
                </div>
                {show && (
                    <FormCardContainer oneColumn>
                        <DeclarationSelect
                            testId={'box-44-document-type-id'}
                            {...getFormikProps(documentTypeName, lFormik)}
                            refNumber="2.3"
                            label={t('documentType')}
                            selectOptions={codeList}
                            condensed
                        />
                        {dateFields.includes(lFormik.getFieldMeta(documentTypeName).value as string) ? (
                            <DeclarationDatepicker
                                showTime
                                testId={'box-44-document-identifier-id'}
                                onChange={(value) => {
                                    const fieldProps = getFormikProps(documentIdentifierName, lFormik).fieldProps;
                                    fieldProps.onChange({
                                        target: {
                                            value: getDateFormatForBox44(value),
                                            name: fieldProps.name,
                                        },
                                    });
                                }}
                                {...getFormikProps(documentIdentifierName, lFormik)}
                                refNumber="2.3"
                                label={t('documentIdentifier')}
                                condensed
                            />
                        ) : (
                            <DeclarationInput
                                maxLength={35}
                                testId={'box-44-document-identifier-id'}
                                {...getFormikProps(documentIdentifierName, lFormik)}
                                refNumber="2.3"
                                label={t('documentIdentifier')}
                                condensed
                            />
                        )}
                    </FormCardContainer>
                )}
                <CardList
                    data={dataList}
                    onDelete={recordHandleDelete}
                    onEdit={recordHandleEdit}
                    viewOnly={viewOnly}
                    condensed
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {show ? (
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <HollowButton onClick={handleHideForm} size="small">
                                Cancel <CloseOutlined height={'min-content'} />
                            </HollowButton>
                            <HollowButton
                                disabled={disabledAddButton}
                                onClick={edit !== undefined ? handleEdit : handleAdd}
                                size="small"
                            >
                                {edit !== undefined ? (
                                    <>
                                        Edit <EditOutlined />
                                    </>
                                ) : (
                                    <>
                                        Add <CheckOutlined />
                                    </>
                                )}
                            </HollowButton>
                        </div>
                    ) : (
                        <HollowButton onClick={modalProps.onCancel} size="small">
                            Close
                        </HollowButton>
                    )}
                </div>
            </div>
        </SModal>
    );
};

const Box44 = { Button, Modal };

export default Box44;
