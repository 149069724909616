import { FormikProvider, useFormik } from 'formik';
import { FC } from 'react';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import DocumentsAdditionalInfoCard from './cards/DocumentsAdditionalInfoCard';
import LocationOfGoods from './cards/LocationOfGoods';
import MasterDetailsCard from './cards/MasterDetailsCard';
import PreviousDocumentCard from './cards/PreviousDocumentCard';
import TransportCostsCard from './cards/TransportCostsCard';
import TransportDocumentsCard from './cards/TransportDocumentsCard';

interface Props {
    formik: ReturnType<typeof useFormik>;
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    expandAll?: boolean;
    amendment?: boolean;
}

// TODO 2082 Use the amendment prop to disable certain fields of the form.

const IrelandH7Form: FC<Props> = ({ formik, toggleHelp, viewOnly, expandAll, amendment }) => {
    return (
        <FormikProvider value={formik}>
            <MasterDetailsCard
                viewOnly={viewOnly}
                keyCard="master-details-card"
                expandAll={expandAll}
                getFieldMeta={formik.getFieldMeta}
                getFieldProps={formik.getFieldProps}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={4}
                declarationType={DeclarationName.H7}
            />

            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'goodsShipment.exporter',
                        header: 'Exporter',
                        refNumber: '3.1',
                    },
                    {
                        path: 'goodsShipment.importer',
                        header: 'Importer',
                        refNumber: '3.15',
                    },
                    {
                        path: 'declarant',
                        header: 'Declarant',
                        refNumber: '3.17',
                        eoriRequired: true,
                    },
                ]}
                paths={{
                    address: {
                        city: 'city',
                        country: 'country',
                        name: 'name',
                        postCode: 'postCode',
                        streetAndNumber: 'streetAndNumber',
                    },
                    eori: 'eori',
                }}
                viewOnly={viewOnly}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={2}
                keyCard={`parties-card`}
                expandAll={expandAll}
                condensed
            />

            <DocumentsAdditionalInfoCard
                viewOnly={viewOnly}
                keyCard={`document-additionalinfo-card`}
                expandAll={expandAll}
                getFieldMeta={formik.getFieldMeta}
                getFieldProps={formik.getFieldProps}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={5}
            />

            <PreviousDocumentCard
                viewOnly={viewOnly}
                keyCard={`previous-document-card`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={6}
            />

            <TransportDocumentsCard
                viewOnly={viewOnly}
                keyCard={`transport-documents-card`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={7}
            />

            <TransportCostsCard
                keyCard={`transport-costs-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                errors={formik.errors}
                cardTotal={9}
                cardNumber={8}
            />
            <LocationOfGoods
                keyCard={`location-goods-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                propsPathPrefix="goodsShipment"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={9}
            />
        </FormikProvider>
    );
};
export default IrelandH7Form;
