import Modal from 'components/ui/base/modal/Modal';
import { H5 } from 'components/ui/base/typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    declarationModalVisible: boolean;
    closeDeclarationDrawer: () => void;
    handleDeleteDeclaration: () => void;
}
const ConfirmDeclarationDeleteModal: FC<Props> = ({
    declarationModalVisible,
    handleDeleteDeclaration,
    closeDeclarationDrawer,
}) => {
    const { t } = useTranslation('dashboard');
    return (
        <>
            <Modal
                title={<H5>{t('declaration.delete-title')}</H5>}
                centered
                visible={declarationModalVisible}
                onOk={handleDeleteDeclaration}
                onCancel={closeDeclarationDrawer}
                width={762}
                contentText={t('declaration.delete-text')}
            />
        </>
    );
};
export default ConfirmDeclarationDeleteModal;
