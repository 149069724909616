import { FormikProps } from 'formik';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import { FC, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import DeclarationFormTabContent from 'views/declarations/common/declaration-form/DeclarationFormTabContent';
import { FormSection } from 'views/declarations/DeclarationView';
import { ProductsErrors } from 'views/declarations/sections/declaration-form/DeclarationForm';
import FormHelperDrawer from 'views/declarations/tooltips-help/FormHelpDrawer';
import { sectionsArraivalAtExitDocument } from './constants';

interface Props {
    formik: FormikProps<any>;
    declaration: Declaration;
    clearCancel: Function;
    updateDeclaration: Function;
    hasAlert: boolean;
    declarationErrors: ProductsErrors;
}

const IeArrivalAtExitTabContent: FC<Props> = ({
    declaration,
    formik,
    updateDeclaration,
    declarationErrors,
    hasAlert,
}) => {
    const { setDeclarationFooterType, expandDeclarationFooter } = useDeclarationFooter();
    const { declarationId }: { declarationId?: string } = useParams();
    const location = useLocation();
    const [selectedBt] = useState<FormSection>(
        location.pathname === `/declarations/${declarationId}` ? FormSection.MASTER_DETAIlS : FormSection.PRODUCTS
    );
    const [showHelp, setShowHelp] = useState<boolean>(false);
    const [expandAllCards] = useState(false);
    const [helpSection, setHelpSection] = useState<number | string | undefined>();
    const { declarationValidation, setFormAction } = useDeclarationValidation();

    useEffect(() => {
        setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
        expandDeclarationFooter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedBt === 0) {
            if (!declarationValidation.formAction) {
                setFormAction(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBt]);

    const handleDeclarationView = useMemo(() => {
        const handleToggleHelp = (refNumber: string | number) => {
            setHelpSection(refNumber);
            setShowHelp(true);
        };

        return (
            <Outlet
                context={{
                    formik: formik,
                    toggleHelp: handleToggleHelp,
                    expandAll: expandAllCards,
                    setSearchQuery: () => {},
                    errors: declarationErrors,
                    data: [],
                    onDelete: () => {},
                    itemsNumber: 0,
                    internalType: DeclarationInternalType.ARRIVAL,
                }}
            />
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBt, formik, declaration, updateDeclaration, declarationErrors]);

    return (
        <>
            <FormHelperDrawer
                section={helpSection}
                isVisible={showHelp}
                onClose={() => setShowHelp(false)}
                internalType={DeclarationInternalType.ENS}
                declarationCountry={DeclarationCountry.IRELAND}
            />
            <DeclarationFormTabContent navigationItems={sectionsArraivalAtExitDocument}>
                {handleDeclarationView}
            </DeclarationFormTabContent>
        </>
    );
};
export default IeArrivalAtExitTabContent;
