import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { IrelandExportNotification } from 'store/declarations/ireland/ireland-export-notification';
import { IrelandNotificationType, irelandNotificationTypeLabel } from 'store/declarations/ireland/ireland-notification';
import { toTitleCase } from 'views/declarations/utils/validation-utils';
import {
    NotificationCode,
    NotificationDescription,
    NotificationDescriptionCode,
    NotificationMessage,
    NotificationText,
    StyledDownOutlined,
    StyledNotificationRow,
} from './DeclarationNotifications.style';

export const GeneralNotification: FC<{ notification: IrelandExportNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const acceptanceDate = useMemo(() => {
        if (notification.acceptanceDate) {
            const dateTime = new Date(notification.acceptanceDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.acceptanceDate]);

    const exitStoppedDate = useMemo(() => {
        if (notification.exitStoppedDate) {
            const dateTime = new Date(notification.exitStoppedDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.exitStoppedDate]);

    const exportNotificationIssueDate = useMemo(() => {
        if (notification.exportNotificationIssueDate) {
            const dateTime = new Date(notification.exportNotificationIssueDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.exportNotificationIssueDate]);

    const controlDateLimit = useMemo(() => {
        if (notification.controlDateLimit) {
            const dateTime = new Date(notification.controlDateLimit);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.controlDateLimit]);

    return (
        <div>
            {notification?.exportNotificationIssueDate && (
                <NotificationDescriptionCode>
                    Issue Date:
                    <NotificationText>{exportNotificationIssueDate}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.exitStoppedDate && (
                <NotificationDescriptionCode>
                    Exit Stopped Date:
                    <NotificationText>{exitStoppedDate}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.acceptanceDate && (
                <NotificationDescriptionCode>
                    Acceptance Date:
                    <NotificationText>{acceptanceDate}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.controlResultCode && (
                <NotificationDescriptionCode>
                    Control Result Code:
                    <NotificationText>{notification.controlResultCode}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.controlDateLimit && (
                <NotificationDescriptionCode>
                    Control Date Limit:
                    <NotificationText>{controlDateLimit}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.issuingPlace && (
                <NotificationDescriptionCode>
                    Issuing Place:
                    <NotificationText>{notification.issuingPlace}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {children}
        </div>
    );
};

export const DeclarationAmendmentAccepted: FC<{ notification: IrelandExportNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    return <div>{children}</div>;
};

export const DeclarationAmendmentRequested: FC<{ notification: IrelandExportNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    return <div>{children}</div>;
};

export const DeclarationAmendmentRejected: FC<{
    notification: IrelandExportNotification;
    children?: ReactNode;
}> = ({ notification, children }) => {
    const notificationsMessages = useMemo(() => {
        const messages = notification.submissionErrors || [];

        if (messages?.length === 0) {
            return <></>;
        }

        const getDateFormat = (date: string) => {
            const newDate = new Date(date);
            return `${newDate.toLocaleDateString()} ${newDate.toLocaleTimeString()}`;
        };

        return messages.map((message) => {
            return (
                <React.Fragment key={message.id}>
                    {message.errorCode && (
                        <NotificationDescriptionCode>
                            Error Code:
                            <NotificationText> {message.errorCode}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                    {message.errorText && (
                        <NotificationDescriptionCode>
                            Error Decription:
                            <NotificationText> {message.errorText}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                    {message.errorDate && (
                        <NotificationDescriptionCode>
                            Error Date:
                            <NotificationText> {getDateFormat(message.errorDate)}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                    {message?.sequenceIdentifier && (
                        <NotificationDescriptionCode>
                            Identifier of Sequence:
                            <NotificationText> {message?.sequenceIdentifier}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                    {message?.fieldIdentifier && (
                        <NotificationDescriptionCode>
                            Field:
                            <NotificationText> {message?.fieldIdentifier}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                    {message?.validationType && (
                        <NotificationDescriptionCode>
                            Validaiton Type:
                            <NotificationText> {message?.validationType}</NotificationText>
                        </NotificationDescriptionCode>
                    )}
                </React.Fragment>
            );
        });
    }, [notification]);
    return (
        <div>
            {children}
            {notificationsMessages}
        </div>
    );
};

export const ControlDecisionNotification: FC<{ notification: IrelandExportNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const date = useMemo(() => {
        if (notification.controlNotificationDate) {
            const dateTime = new Date(notification.controlNotificationDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.controlNotificationDate]);

    return (
        <div>
            {notification.controlNotificationDate && (
                <NotificationDescriptionCode>
                    Control Notification Date:
                    <NotificationText>{date}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.riskRouting && (
                <NotificationDescriptionCode>
                    Risk Routing:
                    <NotificationText>{notification.riskRouting}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {children}
        </div>
    );
};

export const ExitReleaseNotification: FC<{ notification: IrelandExportNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const exitDate = useMemo(() => {
        if (notification?.exitDate) {
            const dateTime = new Date(notification.exitDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.exitDate]);

    return (
        <div>
            {notification.exitDate && (
                <NotificationDescriptionCode>
                    Exit Date:
                    <NotificationText>{exitDate}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.codeIdentifier && (
                <NotificationDescriptionCode>
                    Code Identifier:
                    <NotificationText>{notification.codeIdentifier}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.storingFlag && (
                <NotificationDescriptionCode>
                    Storing Flag:
                    <NotificationText>{notification.storingFlag}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {children}
        </div>
    );
};

export const RealeasedNotification: FC<{ notification: IrelandExportNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const exportReleaseIssueDate = useMemo(() => {
        if (notification.exportReleaseIssueDate) {
            const dateTime = new Date(notification.exportReleaseIssueDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.exportReleaseIssueDate]);

    const acceptanceDate = useMemo(() => {
        if (notification.acceptanceDate) {
            const dateTime = new Date(notification.acceptanceDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.acceptanceDate]);

    const controlDateLimit = useMemo(() => {
        if (notification.controlDateLimit) {
            const dateTime = new Date(notification.controlDateLimit);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.controlDateLimit]);

    return (
        <div>
            {notification.acceptanceDate && (
                <NotificationDescriptionCode>
                    Acceptance Date:
                    <NotificationText>{acceptanceDate}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.controlDateLimit && (
                <NotificationDescriptionCode>
                    Control Date Limit:
                    <NotificationText>{controlDateLimit}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.controlResultCode && (
                <NotificationDescriptionCode>
                    Control Result Code:
                    <NotificationText>{notification.controlResultCode}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.riskRouting && (
                <NotificationDescriptionCode>
                    Risk Routing:
                    <NotificationText>{notification.riskRouting}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.issuingPlace && (
                <NotificationDescriptionCode>
                    Issuing Place:
                    <NotificationText>{notification.issuingPlace}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.exportReleaseIssueDate && (
                <NotificationDescriptionCode>
                    Release Date:
                    <NotificationText>{exportReleaseIssueDate}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {children}
        </div>
    );
};

export const DeclarationNotReleased: FC<{ notification: IrelandExportNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const acceptanceDate = useMemo(() => {
        if (notification.acceptanceDate) {
            const dateTime = new Date(notification.acceptanceDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.acceptanceDate]);

    const controlDateLimit = useMemo(() => {
        if (notification.controlDateLimit) {
            const dateTime = new Date(notification.controlDateLimit);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.controlDateLimit]);

    return (
        <div>
            {notification.acceptanceDate && (
                <NotificationDescriptionCode>
                    Acceptance Date:
                    <NotificationText>{acceptanceDate}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.controlDateLimit && (
                <NotificationDescriptionCode>
                    Control Date Limit:
                    <NotificationText>{controlDateLimit}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.controlResultCode && (
                <NotificationDescriptionCode>
                    Control Result Code:
                    <NotificationText>{notification.controlResultCode}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.riskRouting && (
                <NotificationDescriptionCode>
                    Risk Routing:
                    <NotificationText>{notification.riskRouting}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification.issuingPlace && (
                <NotificationDescriptionCode>
                    Issuing Place:
                    <NotificationText>{notification.issuingPlace}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {children}
        </div>
    );
};

export const CustomsErrorNotification: FC<{ notification: IrelandExportNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    return (
        <div>
            {notification?.commentsText && (
                <NotificationDescriptionCode>
                    Comments:
                    <NotificationText>{notification.commentsText}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {children}
        </div>
    );
};

export const DeclarationRejected: FC<{ notification: IrelandExportNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    return (
        <div>
            {notification?.commentsText && (
                <NotificationDescriptionCode>
                    Comments:
                    <NotificationText>{notification.commentsText}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {children}
        </div>
    );
};

export const InvalidationRequestDecision: FC<{ notification: IrelandExportNotification; children?: ReactNode }> = ({
    notification,
    children,
}) => {
    const invalidationRequestDate = useMemo(() => {
        if (notification.invalidationRequestDate) {
            const dateTime = new Date(notification.invalidationRequestDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.invalidationRequestDate]);

    const invalidationDecisionDate = useMemo(() => {
        if (notification.invalidationDecisionDate) {
            const dateTime = new Date(notification.invalidationDecisionDate);
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        }
        return '';
    }, [notification?.invalidationDecisionDate]);

    return (
        <div>
            {notification?.invalidationRequestDate && (
                <NotificationDescriptionCode>
                    Invalidation Request Date:
                    <NotificationText>{invalidationRequestDate}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.invalidationDecisionDate && (
                <NotificationDescriptionCode>
                    Invalidation Decision Date:
                    <NotificationText>{invalidationDecisionDate}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.invalidationInitiated && (
                <NotificationDescriptionCode>
                    Invalidation Initiated:
                    <NotificationText>{notification.invalidationInitiated}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.invalidationDecision && (
                <NotificationDescriptionCode>
                    Invalidation Decision:
                    <NotificationText>{notification.invalidationDecision}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {notification?.invalidationJustification && (
                <NotificationDescriptionCode>
                    Invalidation Justification:
                    <NotificationText>{notification.invalidationJustification}</NotificationText>
                </NotificationDescriptionCode>
            )}
            {children}
        </div>
    );
};

export const NotificationRow: FC<{ notification: IrelandExportNotification }> = ({ notification }) => {
    const [showDescription, setShowDescription] = useState<boolean>(false);

    const getDateString = useCallback((dateTimeString: string) => {
        const dateTime = new Date(dateTimeString);
        try {
            return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
        } catch (error) {
            return '';
        }
    }, []);
    const date = useMemo(() => {
        if (notification.notificationDate) {
            return getDateString(notification.notificationDate);
        }
        return '';
    }, [notification?.notificationDate, getDateString]);

    const basicNotification = useMemo(() => {
        return (
            <>
                {notification.message && (
                    <NotificationDescriptionCode>
                        Message:
                        <NotificationText>{notification.message}</NotificationText>
                    </NotificationDescriptionCode>
                )}
                {notification.type && (
                    <NotificationDescriptionCode>
                        Type:
                        <NotificationText>{notification.type}</NotificationText>
                    </NotificationDescriptionCode>
                )}
                {notification.submissionErrors && (
                    <NotificationDescriptionCode>
                        Items:
                        {notification.submissionErrors.map((element, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <NotificationDescriptionCode>
                                        Error Code:
                                        <NotificationText>{element.errorCode}</NotificationText>
                                    </NotificationDescriptionCode>
                                    {element.errorText && (
                                        <NotificationDescriptionCode>
                                            Error Description:
                                            <NotificationText>{element.errorCode}</NotificationText>
                                        </NotificationDescriptionCode>
                                    )}
                                    {element.errorDate && (
                                        <NotificationDescriptionCode>
                                            Error Date:
                                            <NotificationText>{getDateString(element.errorDate)}</NotificationText>
                                        </NotificationDescriptionCode>
                                    )}
                                    {element.sequenceIdentifier && (
                                        <NotificationDescriptionCode>
                                            Sequence Identifier:
                                            <NotificationText>{element.sequenceIdentifier}</NotificationText>
                                        </NotificationDescriptionCode>
                                    )}
                                    {element.fieldIdentifier && (
                                        <NotificationDescriptionCode>
                                            Field Identifier:
                                            <NotificationText>{element.fieldIdentifier}</NotificationText>
                                        </NotificationDescriptionCode>
                                    )}
                                    {element.validationType && (
                                        <NotificationDescriptionCode>
                                            Validation Type:
                                            <NotificationText>{element.validationType}</NotificationText>
                                        </NotificationDescriptionCode>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </NotificationDescriptionCode>
                )}
            </>
        );
    }, [notification, getDateString]);

    const notificationDescription = useMemo(() => {
        switch (notification.type) {
            case IrelandNotificationType.GENERAL_NOTIFICATION:
                return <GeneralNotification notification={notification} children={basicNotification} />;
            case IrelandNotificationType.CONTROL_DECISION_NOTIFICATION:
                return <ControlDecisionNotification notification={notification} children={basicNotification} />;
            case IrelandNotificationType.EXIT_RELEASE_NOTIFICATION:
                return <ExitReleaseNotification notification={notification} children={basicNotification} />;
            case IrelandNotificationType.RELEASED:
                return <RealeasedNotification notification={notification} children={basicNotification} />;
            case IrelandNotificationType.CUSTOMS_ERROR:
                return <CustomsErrorNotification notification={notification} children={basicNotification} />;
            case IrelandNotificationType.INVALIDATION_REQUEST_DECISION:
                return <InvalidationRequestDecision notification={notification} children={basicNotification} />;
            case IrelandNotificationType.NOT_RELEASED:
                return <DeclarationNotReleased notification={notification} children={basicNotification} />;
            case IrelandNotificationType.AMENDMENT_ACCEPTED:
                return <DeclarationAmendmentAccepted notification={notification} children={basicNotification} />;
            case IrelandNotificationType.AMENDMENT_REQUEST:
                return <DeclarationAmendmentRequested notification={notification} children={basicNotification} />;
            default:
                return basicNotification;
        }
    }, [notification, basicNotification]);

    return (
        <StyledNotificationRow>
            <NotificationMessage onClick={() => setShowDescription(!showDescription)}>
                <StyledDownOutlined expanded={showDescription} />
                <NotificationCode>
                    Date:
                    <NotificationText>{date}</NotificationText>
                </NotificationCode>
                <NotificationCode>
                    Type:
                    <NotificationText>
                        {toTitleCase(
                            irelandNotificationTypeLabel.find((e) => e.id === notification.type)?.value ?? '-'
                        )}
                    </NotificationText>
                </NotificationCode>
            </NotificationMessage>
            {showDescription ? <NotificationDescription>{notificationDescription}</NotificationDescription> : <></>}
        </StyledNotificationRow>
    );
};

export default NotificationRow;
