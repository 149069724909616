import {
    getEoriValidation,
    getExactLengthValidation,
    getMaxValidation,
    getNumberOfItemsValidation,
    getRequiredMessage,
} from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';

const additionalInformation = Yup.array().of(
    Yup.object().shape({
        code: getMaxValidation('code', 5).nullable(),
        identifier: getMaxValidation('identifier', 255).nullable(),
    })
);

const consigment = Yup.object().shape({
    departureTransportMeansIdentity: getMaxValidation('departureTransportMeansIdentity', 27).nullable(),
    borderTransportMeans: Yup.object().shape({
        nationality: getMaxValidation('nationality', 2).nullable(),
        identity: getMaxValidation('identity', 35).nullable(),
        modeOfTransport: getMaxValidation('modeOfTransport', 2).nullable(),
    }),
    inlandModeOfTransport: getMaxValidation('inlandModeOfTransport', 2).nullable(),
    locationOfGoods: getExactLengthValidation('locationOfGoods', 8)
        .required(getRequiredMessage('locationOfGoods'))
        .nullable(),
});

const packaging = Yup.object().shape({
    containerNumbers: Yup.array().of(getExactLengthValidation('containerNumbers', 11).nullable()),
    typeOfPackage: getExactLengthValidation('typeOfPackage', 2).nullable(),
    uniqueLineOfPackageReference: getMaxValidation('uniqueLineOfPackageReference', 8)
        .required(getRequiredMessage('uniqueLineOfPackageReference'))
        .nullable(),
    quantityOfPackages: getMaxValidation('quantityOfPackages', 5).nullable(),
    numberOfPieces: getMaxValidation('numberOfPieces', 5).nullable(),
    marksOfPackages: getMaxValidation('marksOfPackages', 42).nullable(),
    packagesInItem: getMaxValidation('packagesInItem', 2).nullable(),
    description: getMaxValidation('descriptionOfGoods', 80)
        .required(getRequiredMessage('descriptionOfGoods'))
        .nullable(),
});

const commodity = Yup.object().shape({
    nomenclatureCode: getExactLengthValidation('nomenclatureCode', 8)
        .required(getRequiredMessage('nomenclatureCode'))
        .nullable(),
    taricAdditionalCode: getExactLengthValidation('taricAdditionalCode', 4).nullable(),
    taricAdditionalCode2: getExactLengthValidation('taricAdditionalCode2', 4).nullable(),
    nationalCode: getExactLengthValidation('nationalCode', 4).nullable(),
});

export const customsGoodsItem = Yup.object().shape({
    packaging: packaging,
    additionalInformation: additionalInformation,
    additionalDocument: additionalInformation,
    commodity: commodity,
    countryOrigin: getExactLengthValidation('countryOrigin', 2).nullable(),
    regionOrigin: getExactLengthValidation('regionOrigin', 2).nullable(),
    grossMass: getMaxValidation('grossMass', 8).required(getRequiredMessage('grossMass')).nullable(),
    procedureRequestedCode: getExactLengthValidation('procedureRequestedCode', 2).nullable(),
    procedurePreviousCode: getExactLengthValidation('procedurePreviousCode', 2).nullable(),
    procedureCategory: getExactLengthValidation('procedureCategory', 3).nullable(),
    netMass: getMaxValidation('netMass', 8)
        .nullable()
        .test('netMass-not-larger-grossMass', 'Net mass cannot be larger than gross mass', function (value) {
            if (!value || !this.parent.grossMass) {
                return true;
            }
            const numberValue = parseFloat(value);
            return this.parent.grossMass > numberValue;
        }),
    previousDocument: getMaxValidation('previousDocument', 42).nullable(),
    previousDocumentType: getMaxValidation('previousDocumentType', 1).nullable(),
    previousDocumentReference: getMaxValidation('previousDocumentReference', 6).nullable(),
    previousDocumentNumber: getMaxValidation('previousDocumentIdentifier', 35).nullable(),
    supplementaryUnits: getMaxValidation('supplementaryUnits', 8).nullable(),
    statisticalValue: getMaxValidation('statisticalValue', 9).nullable(),
});

const irelandExportGoodsShipment = Yup.object().shape({
    ucr: getMaxValidation('ucr', 35).nullable(),
    exportationCountry: getExactLengthValidation('exportationCountry', 2).nullable(),
    destinationCountry: getExactLengthValidation('destinationCountry', 2).nullable(),
    consigment: consigment,
    incotermCode: getExactLengthValidation('incotermCode', 3).nullable(),
    deliveryDestination: getMaxValidation('deliveryDestination', 35).nullable(),
    deliveryDestinationLocationCode: getExactLengthValidation('deliveryDestination', 1).nullable(),
    deferredPayment: getMaxValidation('deferredPayment', 12).nullable(),
    warehouseIdentifier: getMaxValidation('warehouseIdentifier', 14).nullable(),
    warehouseCountry: getMaxValidation('warehouseCountry', 2).nullable(),
    warehouseIdentificationNumber: getMaxValidation('warehouseIdentificationNumber', 14).nullable(),
    warehouseIdentifierType: getMaxValidation('warehouseIdentifierType', 1).nullable(),
    warehouseIdentifierCountry: getMaxValidation('warehouseIdentifierCountry', 2).nullable(),
    additionalInformation: additionalInformation,
    additionalDocument: additionalInformation,
    customsGoodsItem: Yup.array().of(customsGoodsItem),
    currencyCode: Yup.string().nullable(),
    totalAmountInvoiced: Yup.number().nullable(),
    firstNatureOfTransactionCode: getMaxValidation('firstNatureOfTransactionCode', 1).nullable(),
    secondNatureOfTransactionCode: getMaxValidation('secondNatureOfTransactionCode', 1).nullable(),
    exitOffice: getMaxValidation('exitOffice', 8).required(getRequiredMessage('exitOffice')).nullable(),
});

const exportCustomer = Yup.object().shape({
    revenueNumber: getMaxValidation('revenueNumber', 17).required(getRequiredMessage('revenueNumber')).nullable(),
    name: getMaxValidation('name', 35).nullable(),
    addressLine1: getMaxValidation('addressLine1', 35).nullable(),
    addressLine2: getMaxValidation('addressLine2', 35).nullable(),
    postCode: getMaxValidation('postCode', 9).nullable(),
    countryCode: getMaxValidation('countryCode', 2).nullable(),
});

const irelandExportValidation = (numberOfItems?: number) =>
    Yup.object().shape({
        numberOfItems: getNumberOfItemsValidation(numberOfItems)
            .required(getRequiredMessage('numberOfItems'))
            .nullable(),
        messageType: getExactLengthValidation('messageType', 3).required(getRequiredMessage('messageType')).nullable(),
        declarationType: getExactLengthValidation('declarationType', 1)
            .required(getRequiredMessage('delarationType'))
            .nullable(),
        exportationCode: getExactLengthValidation('exportationCode', 2)
            .required(getRequiredMessage('additionalDeclarationType'))
            .nullable(),
        representativeStatus: getExactLengthValidation('representativeStatus', 1)
            .required(getRequiredMessage('representativeStatus'))
            .nullable(),
        representativeEori: getEoriValidation('representativeEori').required(getRequiredMessage('representativeEori')),
        sealsQuantity: getMaxValidation('sealsQuantity', 3).required(getRequiredMessage('sealsQuantity')).nullable(),
        sealsIdentity: getMaxValidation('sealsIdentity', 20).required(getRequiredMessage('sealsIdentity')).nullable(),
        specificCircumstanceIndicator: getMaxValidation('specificCircumstanceIndicator', 1).nullable(),
        referenceNumber: getMaxValidation('referenceNumber', 35).nullable(),
        transportChargesMethodOfPayment: getMaxValidation('transportChargesMethodOfPayment', 1).nullable(),
        irelandExportGoodsShipment: irelandExportGoodsShipment,
        consignee: exportCustomer,
        consignor: exportCustomer,
        officeOfExport: getMaxValidation('officeOfExport', 8).required(getRequiredMessage('officeOfExport')).nullable(),
    });

export default irelandExportValidation;
