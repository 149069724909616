import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState } from 'views/declarations/utils/form-utils';
import CountryOfOriginBlock from '../../../export/blocks/CountryOfOriginBlock';
import CountryOfPreferentialOriginBlock from '../../blocks/CountryOfPreferentialOriginBlock';

const CountryOfOriginCard = (props: DeclarationFormCardProps): ReactElement => {
    const { t } = useTranslation('form');

    return (
        <FormCard
            hide={props.hide}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            title={t('countryOfOrigin')}
            cardNumber={props.cardNumber}
            total={props.cardTotal}
            state={getCardState(
                [
                    { name: 'origin.countryCode', required: false },
                    { name: 'origin.typeCode', required: false },
                    { name: 'goodsShipment.governmentAgencyGoodsItem.origin.countryCode', required: false },
                    { name: 'goodsShipment.governmentAgencyGoodsItem.origin.typeCode', required: false },
                ],
                props
            )}
        >
            <CountryOfOriginBlock />
            <CountryOfPreferentialOriginBlock />
        </FormCard>
    );
};

export default CountryOfOriginCard;
