import { Col } from 'antd';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import DeclarationNumberInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationNumberInput';

interface Props extends BlockProps<'rateNumeric'> {}

const ExchangeRateBlock = ({ path = 'currencyExchange', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '4/15', []);
    return (
        <>
            <Col xs={24} xxl={fieldOptions?.rateNumeric?.columnSpan ?? 12}>
                <DeclarationField
                    pathPrefix={path}
                    name="rateNumeric"
                    validation={{
                        name: t('exchangeRate'),
                        number: true,
                        float: [12, 5],
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <DeclarationNumberInput
                            refNumber={ucc}
                            label={t('exchangeRate')}
                            {...getFormikProps(field.name, form)}
                        />
                    )}
                </DeclarationField>
            </Col>
        </>
    );
};

export default ExchangeRateBlock;
