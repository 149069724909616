export enum AdditionDeductionCode {
    AB = 'AB',
    AC = 'AC',
    AD = 'AD',
    AE = 'AE',
    AF = 'AF',
    AX = 'AX',
    AG = 'AG',
    AH = 'AH',
    AZ = 'AZ',
    AI = 'AI',
    AM = 'AM',
    AJ = 'AJ',
    AK = 'AK',
    AL = 'AL',
    AN = 'AN',
    AO = 'AO',
    AP = 'AP',
    AQ = 'AQ',
    AR = 'AR',
    AS = 'AS',
    AT = 'AT',
    AV = 'AV',
    AW = 'AW',
    BA = 'BA',
    BU = 'BU',
    BB = 'BB',
    BC = 'BC',
    BD = 'BD',
    BL = 'BL',
    BE = 'BE',
    BF = 'BF',
    BM = 'BM',
    BG = 'BG',
    BK = 'BK',
    BH = 'BH',
    BI = 'BI',
    BR = 'BR',
    BS = 'BS',
    BT = 'BT',
}

export const AdditionDeductionTypeLabels = [
    {
        id: AdditionDeductionCode.AB,
        value: 'Commissions and brokerage, except buying commissions, declared as a monetary amount',
    },
    {
        id: AdditionDeductionCode.AC,
        value: 'Commissions and brokerage, except buying commissions, declared as a percentage addition',
    },
    { id: AdditionDeductionCode.AD, value: 'Containers and packing' },
    {
        id: AdditionDeductionCode.AE,
        value: 'Materials, components, parts and similar items incorporated in the imported goods',
    },
    {
        id: AdditionDeductionCode.AF,
        value: 'Tools, dies, moulds and similar items used in the production of the imported goods, declared as a monetary amount',
    },
    {
        id: AdditionDeductionCode.AX,
        value: 'Tools, dies, moulds and similar items used in the production of the imported goods, declared as a percentage addition',
    },
    { id: AdditionDeductionCode.AG, value: 'Materials consumed in the production of the imported goods' },
    {
        id: AdditionDeductionCode.AH,
        value: 'Engineering, development, artwork, design work and plans and sketches undertaken elsewhere than in the EU and necessary for the production of the imported goods, declared as a monetary amount',
    },
    {
        id: AdditionDeductionCode.AZ,
        value: 'Engineering, development, artwork, design work and plans and sketches undertaken elsewhere than in the EU and necessary for the production of the imported goods, declared as a percentage addition',
    },
    {
        id: AdditionDeductionCode.AI,
        value: 'Royalties and license fees, declared as a monetary amount. For percentage adjustments use code AM instead',
    },
    {
        id: AdditionDeductionCode.AM,
        value: 'Royalties and license fees, declared as a percentage adjustment. For additions declared as a monetary amount rather than a percentage use code AI',
    },
    {
        id: AdditionDeductionCode.AJ,
        value: 'Proceeds of any subsequent resale, disposal or use accruing to the seller',
    },
    { id: AdditionDeductionCode.AK, value: 'Insurance Costs' },
    { id: AdditionDeductionCode.AL, value: 'Indirect payments and other payments (Article 70 of the code)' },
    {
        id: AdditionDeductionCode.AN,
        value: 'Additions based on a decision granted in accordance with Article 71 of Delegated Regulation (EU) 2015/2446 in which an additional amount is specified',
    },
    {
        id: AdditionDeductionCode.AO,
        value: 'Additions based on a decision granted in accordance with Article 71 of Delegated Regulation (EU) 2015/2446 in which a percentage adjustment is specified',
    },
    {
        id: AdditionDeductionCode.AP,
        value: 'Transport costs, loading and handling charges up to the place of introduction in the EU. The amount declared should exclude any airfreight charges declared under codes AR or AS. For multi-item declarations, costs declared under this code will be apportioned across the items in proportion to their value. If the declarant would prefer the charges to be apportioned by gross mass rather than value then they should use code AQ instead of AP.',
    },
    {
        id: AdditionDeductionCode.AQ,
        value: 'Transport costs, loading and handling charges up to the place of introduction in the EU. The amount declared should exclude any airfreight charges declared under codes AR or AS. For multi-item declarations, costs declared under this code will be apportioned across the items in proportion to their gross mass. If the declarant would prefer the charges to be apportioned by value rather than gross mass then they should use code AP instead of AQ',
    },
    {
        id: AdditionDeductionCode.AR,
        value: 'Air Transport Costs up to the place of introduction in the European Union which have not already been included in the invoice price. This code is only to be used when claiming an adjustment in the value for duty for the section of the flight that is considered to be within the EU. For multi-item declarations, costs declared under this code will be apportioned across the items in proportion to their value. If the declarant would prefer the charges to be apportioned by gross mass rather than value then they should use code AS instead of AR',
    },
    {
        id: AdditionDeductionCode.AS,
        value: 'Air Transport Costs up to the place of introduction in the European Union which have not already been included in the invoice price. This code is only to be used when claiming an adjustment in the value for duty for the section of the flight that is considered to be within the EU. For multi-item declarations, costs declared under this code will be apportioned across the items in proportion to their gross mass. If the declarant would prefer the charges to be apportioned by value rather than gross mass then they should use code AR instead of AS',
    },
    { id: AdditionDeductionCode.AT, value: 'Other charges not elsewhere declared' },
    {
        id: AdditionDeductionCode.AV,
        value: 'Adjustment for VAT Value e.g. freight charges within the EU. For multi-item declarations, costs declared under this code will be apportioned across the items in proportion to their value. If the declarant would prefer the charges to be apportioned by gross mass rather than value then they should use code AW instead of AV',
    },
    {
        id: AdditionDeductionCode.AW,
        value: 'Adjustment for VAT Value e.g. freight charges within the EU. For multi-item declarations, costs declared under this code will be apportioned across the items in proportion to their gross mass. If the declarant would prefer the charges to be apportioned by value rather than gross mass then they should use code AV instead of AW',
    },
    {
        id: AdditionDeductionCode.BA,
        value: 'Costs of transport and insurance after arrival at the place of introduction, which have been included in the declared value. For multi-item declarations, costs declared under this code will be apportioned across the items in proportion to their value. If the declarant would prefer the charges to be apportioned by gross mass rather than value then they should use code BU instead of BA',
    },
    {
        id: AdditionDeductionCode.BU,
        value: 'Costs of transport and insurance after arrival at the place of introduction, which have been included in the declared value. For multi-item declarations, costs declared under this code will be apportioned across the items in proportion to their gross mass. If the declarant would prefer the charges to be apportioned by value rather than gross mass then they should use code BA instead of BU',
    },
    {
        id: AdditionDeductionCode.BB,
        value: 'Charges for construction, erection, assembly, maintenance or technical assistance undertaken after importation',
    },
    {
        id: AdditionDeductionCode.BC,
        value: 'Import duties or other charges payable in the Union for reason of the import or sale of goods',
    },
    { id: AdditionDeductionCode.BD, value: 'Interest charges declared as a monetary amount' },
    { id: AdditionDeductionCode.BL, value: 'Interest charges declared as a percentage adjustment' },
    { id: AdditionDeductionCode.BE, value: 'Charges for the right to reproduce the imported goods in the EU' },
    { id: AdditionDeductionCode.BF, value: 'Buying commissions, to be declared as a percentage adjustment' },
    { id: AdditionDeductionCode.BM, value: 'Buying commissions, to be declared as a monetary amount' },
    {
        id: AdditionDeductionCode.BG,
        value: 'Deductions based on a decision granted in accordance with Article 71 of Delegated Regulation (EU) 2015/2446 in which a deduction amount is specified',
    },
    {
        id: AdditionDeductionCode.BK,
        value: 'Deductions based on a decision granted in accordance with Article 71 of Delegated Regulation (EU) 2015/2446 in which a percentage adjustment is specified',
    },
    {
        id: AdditionDeductionCode.BH,
        value: 'Discount (amount) not elsewhere declared. The amount of the discount and the appropriate currency should be shown against this code. If the discount is given as a percentage rather than an actual amount then the discount should be declared using code BI instead of BH',
    },
    {
        id: AdditionDeductionCode.BI,
        value: 'Discount (Percentage) not elsewhere declared. The percentage of the discount should be shown against this code. If the discount is given as an actual amount rather than a percentage then the discount should be declared using code BH instead of BI',
    },
    {
        id: AdditionDeductionCode.BR,
        value: 'Air transport costs up to the place of introduction in the European Union which have been included in the declared invoice price. This code is only to be used when claiming an adjustment in the value for duty for the section of the flight that is considered to be within the EU. For multi-item declarations, costs declared under this code will be apportioned across the items in proportion to their value. If the declarant would prefer the charges to be apportioned by gross mass rather than value then they should use code BS instead of BR. If the freight charges were not included in the declared invoice value then they must be declared as an addition using code AR or AS rather than BR.',
    },
    {
        id: AdditionDeductionCode.BS,
        value: 'Air transport costs up to the place of introduction in the European Union which have been included in the declared invoice price. This code is only to be used when claiming an adjustment in the value for duty for the section of the flight that is considered to be within the EU. For multi-item declarations, costs declared under this code will be apportioned across the items in proportion to their gross mass. If the declarant would prefer the charges to be apportioned by value rather than gross mass then they should use code BR instead of BS. If the freight charges were not included in the declared invoice value then they must be declared as an addition using code AR or AS rather than BS.',
    },
    { id: AdditionDeductionCode.BT, value: 'Other deductions not elsewhere declared' },
] as const;
