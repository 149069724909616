import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import IdentityOfMeansOfTransportAtDepartureBlock, {
    identityOfMeansOfTransportAtDepartureBlockValidation,
} from '../blocks/IdentityOfMeansOfTransportAtDepartureBlock';

export const identityOfMeansOfTransportAtDepartureCardValidation = {
    selfValidators: [identityOfMeansOfTransportAtDepartureBlockValidation],
};

const IdentityOfMeansOfTransportAtDepartureCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('identityOfMeansOfTransportAtDeparture')}>
            <FormCardContainer>
                <IdentityOfMeansOfTransportAtDepartureBlock />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default IdentityOfMeansOfTransportAtDepartureCard;
