import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const CustomsOfficesCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('customsOfficeOfPresentation')}>
            <FormCardContainer>
                <FormInput
                    required
                    maxLength={17}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`specialPersonPresentingGoodsToCustoms`, props)}
                    refNumber="3.41"
                    label={t('specialPersonPresentingGoodsToCustoms')}
                    tooltip={getCdsTooltipsByRefNumberAndField('3.41', t('specialPersonPresentingGoodsToCustoms'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`customsOfficeOfPresentation`, props)}
                    refNumber="5.26"
                    label={t('customsOfficeOfPresentation')}
                    selectOptions={normalizeCodesToSelect(codelists?.ukSupervisingCustomsOffices || [])}
                    tooltip={getCdsTooltipsByRefNumberAndField('5.26', t('customsOfficeOfPresentation'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`supervisingCustomsOffice`, props)}
                    refNumber="5.27"
                    label={t('authorisation.supervisingCustomsOffice')}
                    selectOptions={normalizeCodesToSelect(codelists?.ukSupervisingCustomsOffices || [])}
                    tooltip={getCdsTooltipsByRefNumberAndField('5.27', t('authorisation.supervisingCustomsOffice'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default CustomsOfficesCard;
