import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'id';

export const nationalAdditionalCodesBlockValidation: {
    childValidators: {
        'commodity.classification.gn': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'commodity.classification.gn': {
            childValidators: {
                id: [validators.max(4)],
            },
            stopAtNull: true,
        },
    },
};

export const nationalAdditionalCodesFieldsValidation =
    nationalAdditionalCodesBlockValidation.childValidators['commodity.classification.gn'];

interface Props extends BlockProps<Fields> {}

const NationalAdditionalCodesBlock = ({ path = 'commodity.classification', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '6/17', []);
    return (
        <MultipleDeclarationField pathPrefix={path} parent="gn" name="id">
            {({ form, field }, controls) => (
                <FormInput
                    required={fieldOptions?.id?.required ?? false}
                    refNumber={ucc}
                    label={t('nationalAdditionalCodeIdentifier')}
                    {...getFormikProps(field.name, form)}
                    condensed
                    multipleF={controls}
                />
            )}
        </MultipleDeclarationField>
    );
};

export default NationalAdditionalCodesBlock;
