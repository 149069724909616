import { Col, Row } from 'antd';
import FormInput from '../../../../../components/ui/composed/formInput/FormInput';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { DeliveryIncotermLabels } from '../../../../../store/declarations/enums/uk/delivery-incoterm';
import CountrySelect from '../../../../../components/ui/composed/selects/CountrySelect';

interface Props extends BlockProps<'conditionCode' | 'locationID' | 'locationName'> {}

const DeliveryTermsBlock = ({ path = 'goodsShipment.tradeTerms', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '4/1', []);

    return (
        <Row gutter={18}>
            <Col xs={fieldOptions?.conditionCode?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="conditionCode"
                    validation={{
                        name: t('conditionCode'),
                        exact: 3,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormSelect
                            refNumber={ucc}
                            label={t('conditionCode')}
                            {...getFormikProps(field.name, form)}
                            selectOptions={DeliveryIncotermLabels}
                        />
                    )}
                </DeclarationField>
            </Col>

            <Col xs={fieldOptions?.locationID?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="locationID"
                    validation={{
                        name: t('locationID'),
                        max: 17,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormInput refNumber={ucc} label={t('locationID')} {...getFormikProps(field.name, form)} />
                    )}
                </DeclarationField>
            </Col>

            {/* <Col xs={fieldOptions?.locationName?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="locationName"
                    validation={{
                        name: t('goodsShipment.countryCode'),
                        max: 37,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <CountrySelect
                            refNumber={ucc}
                            label={t('goodsShipment.countryCode')}
                            {...getFormikProps(field.name, form)}
                        />
                    )}
                </DeclarationField>
            </Col> */}

            <Col xs={fieldOptions?.locationName?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="locationName"
                    validation={{
                        name: t('locationName'),
                        max: 37,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormInput refNumber={ucc} label={t('locationName')} {...getFormikProps(field.name, form)} />
                    )}
                </DeclarationField>
            </Col>
        </Row>
    );
};

export default DeliveryTermsBlock;
