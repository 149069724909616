export enum PartyRoleAuthorisationType {
    ACE = 'ACE',
    ACP = 'ACP',
    ACR = 'ACR',
    ACT = 'ACT',
    AEO = 'AEOC',
    AEOF = 'AEOF',
    AEOS = 'AEOS',
    APE = 'APEX',
    AWB = 'AWB',
    BOI = 'BOI',
    BTI = 'BTI',
    CCL = 'CCL',
    CGU = 'CGU',
    CSDR = 'CSDR',
    CSE = 'CSE',
    CVA = 'CVA',
    CW1 = 'CW1',
    CW2 = 'CW2',
    CWP = 'CWP',
    DEP = 'DEP',
    DPO = 'DPO',
    EIR = 'EIR',
    EORI = 'EORI',
    EPSS = 'EPSS',
    ETD = 'ETD',
    EUS = 'EUS',
    EXEE = 'EXEE',
    EXOR = 'EXOR',
    EXW = 'EXW',
    EXWH = 'EXWH',
    FAS = 'FAS',
    FZ = 'FZ',
    GGA = 'GGA',
    GVS = 'GVS',
    IPO = 'IPO',
    LVBI = 'LVBI',
    MOU = 'MOU',
    OPO = 'OPO',
    REM = 'REM',
    REP = 'REP',
    REX = 'REX',
    RSS = 'RSS',
    SAS = 'SAS',
    SASP = 'SASP',
    SDE = 'SDE',
    SIVA = 'SIVA',
    SSE = 'SSE',
    TEA = 'TEA',
    TEAH = 'TEAH',
    TRD = 'TRD',
    TST = 'TST',
    UKCS = 'UKCS',
    EXRR = 'EXRR',
}
export const partyRoleAuthorisationTypeLabels = [
    { id: PartyRoleAuthorisationType.ACE, value: 'ACE' },
    { id: PartyRoleAuthorisationType.ACP, value: 'ACP' },
    { id: PartyRoleAuthorisationType.ACR, value: 'ACR' },
    { id: PartyRoleAuthorisationType.ACT, value: 'ACT' },
    { id: PartyRoleAuthorisationType.AEO, value: 'AEO' },
    { id: PartyRoleAuthorisationType.AEOF, value: 'AEOF' },
    { id: PartyRoleAuthorisationType.AEOS, value: 'AEOS' },
    { id: PartyRoleAuthorisationType.APE, value: 'APE' },
    { id: PartyRoleAuthorisationType.AWB, value: 'AWB' },
    { id: PartyRoleAuthorisationType.BOI, value: 'BOI' },
    { id: PartyRoleAuthorisationType.BTI, value: 'BTI' },
    { id: PartyRoleAuthorisationType.CCL, value: 'CCL' },
    { id: PartyRoleAuthorisationType.CGU, value: 'CGU' },
    { id: PartyRoleAuthorisationType.CSDR, value: 'CSDR' },
    { id: PartyRoleAuthorisationType.CSE, value: 'CSE' },
    { id: PartyRoleAuthorisationType.CVA, value: 'CVA' },
    { id: PartyRoleAuthorisationType.CW1, value: 'CW1' },
    { id: PartyRoleAuthorisationType.CW2, value: 'CW2' },
    { id: PartyRoleAuthorisationType.CWP, value: 'CWP' },
    { id: PartyRoleAuthorisationType.DEP, value: 'DEP' },
    { id: PartyRoleAuthorisationType.DPO, value: 'DPO' },
    { id: PartyRoleAuthorisationType.EIR, value: 'EIR' },
    { id: PartyRoleAuthorisationType.EORI, value: 'EORI' },
    { id: PartyRoleAuthorisationType.EPSS, value: 'EPSS' },
    { id: PartyRoleAuthorisationType.ETD, value: 'ETD' },
    { id: PartyRoleAuthorisationType.EUS, value: 'EUS' },
    { id: PartyRoleAuthorisationType.EXEE, value: 'EXEE' },
    { id: PartyRoleAuthorisationType.EXOR, value: 'EXOR' },
    { id: PartyRoleAuthorisationType.EXW, value: 'EXW' },
    { id: PartyRoleAuthorisationType.EXWH, value: 'EXWH' },
    { id: PartyRoleAuthorisationType.FAS, value: 'FAS' },
    { id: PartyRoleAuthorisationType.FZ, value: 'FZ' },
    { id: PartyRoleAuthorisationType.GGA, value: 'GGA' },
    { id: PartyRoleAuthorisationType.GVS, value: 'GVS' },
    { id: PartyRoleAuthorisationType.IPO, value: 'IPO' },
    { id: PartyRoleAuthorisationType.LVBI, value: 'LVBI' },
    { id: PartyRoleAuthorisationType.MOU, value: 'MOU' },
    { id: PartyRoleAuthorisationType.OPO, value: 'OPO' },
    { id: PartyRoleAuthorisationType.REM, value: 'REM' },
    { id: PartyRoleAuthorisationType.REP, value: 'REP' },
    { id: PartyRoleAuthorisationType.REX, value: 'REX' },
    { id: PartyRoleAuthorisationType.RSS, value: 'RSS' },
    { id: PartyRoleAuthorisationType.SAS, value: 'SAS' },
    { id: PartyRoleAuthorisationType.SASP, value: 'SASP' },
    { id: PartyRoleAuthorisationType.SDE, value: 'SDE' },
    { id: PartyRoleAuthorisationType.SIVA, value: 'SIVA' },
    { id: PartyRoleAuthorisationType.SSE, value: 'SSE' },
    { id: PartyRoleAuthorisationType.TEA, value: 'TEA' },
    { id: PartyRoleAuthorisationType.TEAH, value: 'TEAH' },
    { id: PartyRoleAuthorisationType.TRD, value: 'TRD' },
    { id: PartyRoleAuthorisationType.TST, value: 'TST' },
    { id: PartyRoleAuthorisationType.UKCS, value: 'UKCS' },
    { id: PartyRoleAuthorisationType.EXRR, value: 'EXRR' },
] as const;
