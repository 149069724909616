import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import BorderlessCard from '../../../../../common/cards/borderless-card/BorderlessCard';
import { FormCardContainer } from '../../../../../common/cards/NewFormCard';

const AuthorisationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <BorderlessCard
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            keyCard={props.keyCard}
            title={`A. ${t('authorisation.title')}`}
        >
            <FormCardContainer>
                <FormInput
                    maxLength={8}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`customsOfficeOfDischarge`, props)}
                    refNumber="4.11"
                    label={t('authorisation.customsOfficeOfDischarge')}
                    tooltip={getH1TooltipsByRefNumberAndField('4.11', t('authorisation.customsOfficeOfDischarge'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`supervisingCustomsOffice`, props)}
                    refNumber="4.13"
                    label={t('authorisation.supervisingCustomsOffice')}
                    tooltip={getH1TooltipsByRefNumberAndField('4.13', t('authorisation.supervisingCustomsOffice'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={normalizeCodesToSelect(codelists?.irelandNationalCustomsOfficeCodes || [])}
                    condensed
                />

                <DeclarationNumberInput
                    maxLength={2}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`period`, props)}
                    refNumber="4.17"
                    label={t('authorisation.period')}
                    tooltip={getH1TooltipsByRefNumberAndField('4.17', t('authorisation.period'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormInput
                    maxLength={512}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`periodDetails`, props)}
                    refNumber="4.17"
                    label={t('authorisation.periodDetails')}
                    tooltip={getH1TooltipsByRefNumberAndField('4.17', t('authorisation.periodDetails'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`billOfDischargeDeadline`, props)}
                    refNumber="4.18"
                    label={t('authorisation.billOfDischargeDeadline')}
                    tooltip={getH1TooltipsByRefNumberAndField('4.18', t('authorisation.billOfDischargeDeadline'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`billOfDischargeDetails`, props)}
                    refNumber="4.18"
                    label={t('authorisation.billOfDischargeDetails')}
                    tooltip={getH1TooltipsByRefNumberAndField('4.18', t('authorisation.billOfDischargeDetails'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`detailsOfPlannedActivities`, props)}
                    refNumber="7.5"
                    label={t('authorisation.detailsOfPlannedActivities')}
                    tooltip={getH1TooltipsByRefNumberAndField('7.5', t('authorisation.detailsOfPlannedActivities'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`additionalInformation`, props)}
                    refNumber="8.5"
                    label={t('authorisation.additionalInformation')}
                    tooltip={getH1TooltipsByRefNumberAndField('8.5', t('authorisation.additionalInformation'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </BorderlessCard>
    );
};
export default AuthorisationCard;
