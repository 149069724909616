import { Row } from 'antd';
import Button from 'components/ui/base/button';
import { H5, Text, TextBold } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors } from 'theme';

export const StyledErrorRow = styled(Row)`
    margin-bottom: 0.5rem;
`;

export const CodeDiv = styled.div`
    color: ${colors.error};
    margin-right: 1.6rem;
    min-height: 3.6rem;
`;

export const MessageDiv = styled.div`
    min-height: 3.6rem;
    width: 40rem;
`;

export const ShowDetailsDiv = styled.div`
    margin-left: 1.6rem;
    cursor: pointer;
    width: 8.6rem;
`;

export const DetailsLabelDiv = styled.div`
    min-height: 3.6rem;
    width: 8rem;
`;

export const DetailsLabel = styled(TextBold)`
    font-size: 1.4rem;
    margin-right: 1.6rem;
`;

export const DetailsFieldsLabel = styled(DetailsLabel)`
    &:hover {
        cursor: pointer;
    }
    text-decoration: underline;
`;

export const DetailsValueDiv = styled.div`
    min-height: 3.6rem;
    width: 48rem;
`;

export const DetailsValue = styled(Text)`
    font-size: 1.4rem;
`;

export const Title = styled(H5)`
    margin-bottom: 2.4rem;
`;

export const BackToList = styled(Button)`
    margin-bottom: 2.4rem;
`;

export const StyledHr = styled.hr`
    margin: 2rem 0;
`;
