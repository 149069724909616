import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState } from 'views/declarations/utils/form-utils';
import NationalityOfActiveMeansOfTransportCrossingTheBorderBlock from '../blocks/NationalityOfActiveMeansOfTransportCrossingTheBorderBlock';

interface Props extends DeclarationFormCardProps {}

const NationalityOfActiveMeansOfTransportCrossingTheBorderCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');

    return (
        <FormCard
            hide={props.hide}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            title={t('activeBorderTransportMeansNationality')}
            cardNumber={props.cardNumber}
            total={props.cardTotal}
            state={getCardState([{ name: 'borderTransportMeans.registrationNationalityCode', required: false }], props)}
        >
            <NationalityOfActiveMeansOfTransportCrossingTheBorderBlock />
        </FormCard>
    );
};
export default NationalityOfActiveMeansOfTransportCrossingTheBorderCard;
