import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarationHeader from 'hooks/useDeclarationHeader';
import { camelCase } from 'lodash';
import { ReactNode, useMemo, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useDeclarationContext } from 'utils/DeclarationContext';
import { useProductTemplateContext } from 'utils/ProductTemplateContext';
import { CollapsablesDiv, FormDiv, StyledContent, StyledLayout } from 'views/declarations/Form.styles';
import DeclarationVerticalNavigation from '../DeclarationVerticalNavigation';
import { NavItem } from '../nav-item';

type Props = {
    navigationItems: NavItem[];
    children: ReactNode;
};

const DeclarationFormTabContent = ({ navigationItems, children }: Props) => {
    const inMasterDetails = useMatch('declarations/:declarationId');
    const inProducts = useMatch('declarations/:declarationId/products/:productId');
    const inProductTemplates = useMatch('customs-declarations/:country/:internalType/products/:type/*');
    const inViewOnlyMasterDetails = useMatch('declarations/:declarationId/view-only');
    const inViewOnlyProducts = useMatch('declarations/:declarationId/view-only/products/:productId');

    const { collapsed } = useDeclarationFooter();
    const { declarationHeaderCollapsed } = useDeclarationHeader();

    const [active, setActive] = useState(0);

    const { form, cards, registerCard } = useDeclarationContext();
    const { inProductTemplate } = useProductTemplateContext();
    /**
     * Construct the navigation items from the registered visible cards
     */
    const navItems = useMemo(() => {
        if (!cards) return [];
        return cards
            .filter((card) => !card.section && (inProductTemplate ? 'product' : form) === card.form && card.visible)
            .map((card) => ({ title: card.title, href: '' }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerCard, cards]);

    const handleScroll = () => {
        const mainSection = document.getElementById('master-details-main-content');
        const container = document.getElementById('master-details-container');

        if (mainSection && container) {
            const sections = mainSection?.children;

            for (let i = 0; i < sections.length; i++) {
                const section = sections.item(i)!;

                if (isVisible(section, container)) {
                    const currentSectionTitle =
                        section.querySelector('.section-title')?.innerHTML ?? // Current design
                        section.querySelector('.ant-card-meta-title')?.innerHTML; // New design

                    const index = (navItems ?? navigationItems).findIndex(
                        // eslint-disable-next-line no-loop-func
                        (navigationItem) => {
                            return (
                                navigationItem.title === currentSectionTitle ||
                                camelCase(navigationItem.title) === camelCase(currentSectionTitle)
                            );
                        }
                    );

                    setActive(index);

                    return;
                }
            }
        }
    };

    const isVisible = function (element: Element, container: HTMLElement) {
        if (!element || !container) {
            return false;
        }

        const elementTop = element.getBoundingClientRect().top;
        const elementBottom = element.getBoundingClientRect().bottom;

        const containerTop = container.getBoundingClientRect().top;

        // 20 gives a bit of margin for the visible section of the element.
        // It adds 20 pixels to the top position of the element making it being perceived 20 pixels lower.
        const TOP_OFFSET = 20;
        const BOTTOM_OFFSET = 40;

        return elementTop + TOP_OFFSET > containerTop || elementBottom - BOTTOM_OFFSET > containerTop;
    };

    const showNavigation = useMemo(
        () =>
            Boolean(
                inMasterDetails ?? inProducts ?? inProductTemplates ?? inViewOnlyMasterDetails ?? inViewOnlyProducts
            ),
        [inMasterDetails, inProducts, inProductTemplates, inViewOnlyMasterDetails, inViewOnlyProducts]
    );

    return (
        <StyledLayout>
            <DeclarationVerticalNavigation
                show={showNavigation}
                navigationItems={navItems}
                active={active}
                onChange={setActive}
            />
            <StyledContent
                id="master-details-container"
                onScroll={handleScroll}
                $hasLeftNavigation={showNavigation}
                collapsedFooter={collapsed}
                collapsedHeader={declarationHeaderCollapsed}
            >
                <FormDiv>
                    <CollapsablesDiv id="master-details-main-content">{children}</CollapsablesDiv>
                </FormDiv>
            </StyledContent>
        </StyledLayout>
    );
};

export default DeclarationFormTabContent;
