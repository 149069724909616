import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import addPathPrefix from '../../../../../../../utils/addPathPrefix';
import { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import MultipleItemsCard from '../../../../../common/MultipleItemsCard';
import { simplifiedDeclarationDocumentWritingOffItem } from '../../validation/ValidationSchema';

const SimplifiedDeclarationDocumentWritingOffItemCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path="simplifiedDeclarationDocumentWritingOff"
            initialValues={{
                previousDocumentType: '',
                previousDocumentIdentifier: '',
                previousDocumentLineId: '',
                measurementUnitAndQualifier: '',
                typeOfPackages: '',
                numberOfPackages: '',
            }}
            list={(list) => [
                {
                    field: t('simplifiedDeclarationDocumentWritingOff.previousDocumentType'),
                    value: list.previousDocumentType,
                },
                {
                    field: t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier'),
                    value: list.previousDocumentIdentifier,
                },
                {
                    field: t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId'),
                    value: list.previousDocumentLineId,
                },
                {
                    field: t('simplifiedDeclarationDocumentWritingOff.measurementUnitAndQualifier'),
                    value: list.measurementUnitAndQualifier,
                },
                {
                    field: t('quantity'),
                    value: list.measurementUnitAndQualifier,
                },
                {
                    field: t('packages.packageType'),
                    value: list.typeOfPackages,
                },
                {
                    field: t('packages.numberOfPackages'),
                    value: list.numberOfPackages,
                },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            keyCard={props.keyCard}
            title={t('simplifiedDeclarationDocumentWritingOff.title')}
            condensed
            refNumber="2.1"
            validationSchema={simplifiedDeclarationDocumentWritingOffItem}
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'previousDocumentType'), formProps)}
                        label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentType')}
                        selectOptions={normalizeCodesToSelect(codelists?.irelandPreviousDocumentTypes || [])}
                        refNumber="2.1"
                        tooltip={getH1TooltipsByRefNumberAndField(
                            '2.1',
                            t('simplifiedDeclarationDocumentWritingOff.previousDocumentType')
                        )}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormInput
                        numbersAndLetters
                        maxLength={35}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'previousDocumentIdentifier'), formProps)}
                        label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier')}
                        refNumber="2.1"
                        tooltip={getH1TooltipsByRefNumberAndField(
                            '2.1',
                            t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier')
                        )}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormInput
                        numbersAndLetters
                        maxLength={5}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'previousDocumentLineId'), formProps)}
                        label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId')}
                        refNumber="2.1"
                        tooltip={getH1TooltipsByRefNumberAndField(
                            '2.1',
                            t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId')
                        )}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'measurementUnitAndQualifier'), formProps)}
                        label={t('simplifiedDeclarationDocumentWritingOff.measurementUnitAndQualifier')}
                        selectOptions={normalizeCodesToSelect(codelists?.irelandBaseMeasurements || [])}
                        refNumber="2.1"
                        tooltip={getH1TooltipsByRefNumberAndField(
                            '2.3',
                            t('simplifiedDeclarationDocumentWritingOff.measurementUnitAndQualifier')
                        )}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <DeclarationNumberInput
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'measurementUnitAndQualifier'), formProps)}
                        label={t('quantity')}
                        refNumber="2.1"
                        tooltip={getH1TooltipsByRefNumberAndField('2.3', t('quantity'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormInput
                        maxLength={2}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'typeOfPackages'), formProps)}
                        label={t('packages.packageType')}
                        refNumber="2.1"
                        tooltip={getH1TooltipsByRefNumberAndField('6.9', t('packages.packageType'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <DeclarationNumberInput
                        integerNumbers
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'numberOfPackages'), formProps)}
                        label={t('packages.numberOfPackages')}
                        refNumber="2.1"
                        tooltip={getH1TooltipsByRefNumberAndField('6.10', t('packages.numberOfPackages'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default SimplifiedDeclarationDocumentWritingOffItemCard;
