import { Modal } from 'antd';
import config from 'config';
import { useFormik } from 'formik';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import MasterDetails from 'views/declarations/MasterDetails';
import { TemplateResponse, Template, TemplateContext, useTemplateContext } from './TemplateContext';
import axiosClient from 'config/axios';
import { SuccessResponse } from 'core/http/response';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import parseTemplateOptions from './parseTemplateOptions';
import DeclarationProductView from 'views/declarations/DeclarationProductView';
import TemplateHeader from './TemplateHeader';
import useTemplates from 'hooks/useTemplates';
import declarationViewMap from 'views/declarations/utils/declarationViewMap';
import { getDeclarationKey } from 'views/declarations/utils/declaration-utils';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { TransformData } from 'views/declarations/common/declaration-view/DeclarationView';
import { cloneDeep, isEqual } from 'lodash';
import { createTemplate } from 'store/template/client';

const transformTemplateData = (
    template: Template | undefined,
    transform: TransformData | undefined | null,
    target: 'forClient' | 'forServer'
) => {
    const templateCopy = cloneDeep(template);
    if (transform && templateCopy) {
        templateCopy.master.defaults = transform?.declaration?.[target]?.(templateCopy?.master?.defaults, true);
        templateCopy.product.defaults = transform?.product?.[target]?.(
            templateCopy?.product?.defaults?.governmentAgencyGoodsItem ?? templateCopy?.product?.defaults,
            true
        );
    }
    return templateCopy;
};

const SModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 8px;
    }
`;

interface Props {
    creating?: boolean;
}

const TemplateModal = ({ creating }: Props): ReactElement => {
    const { listTemplates } = useTemplates();
    const { closeModal, open, templateId, options, setForm, form, isViewOnly } = useTemplateContext();
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

    const [activeForm, setActiveForm] = useState<'master' | 'product'>('master');

    const [template, setTemplate] = useState<TemplateResponse | undefined>(undefined);
    const [saveChanges, setSaveChanges] = useState<boolean>(false);

    const isCds = useMemo(() => options?.country === 'CDS' || options?.country === 'UK', [options?.country]);

    const transform = useMemo(() => {
        /**
         * 1. Get declaration key by external, internal, and name.
         * 2. Get view map data using the key
         * 3. Get the transform object
         */
        const _options = template ?? {
            declarationInternalType: options?.declarationType,
            declarationExternalType: options?.country === 'IRELAND' ? 'REVENUE' : 'CDS',
        };
        if (!_options) return null;
        const declarationKey = getDeclarationKey({
            declarationInternalType: _options.declarationInternalType as DeclarationInternalType,
            declarationExternalEntity: _options.declarationExternalType as DeclarationExternalEntity,
        });
        if (!declarationKey) return null;
        return declarationViewMap[declarationKey].transformData;
    }, [options?.country, options?.declarationType, template]);

    const templateData = useMemo(() => {
        const data = cloneDeep(template?.template);
        if (isCds && data) {
            data.master.defaults = { cdsExportDeclarationPayload: data.master.defaults };
        }
        const transformedData = transformTemplateData(data, transform, 'forClient');
        if (isCds && transformedData) {
            transformedData.master.defaults = transformedData.master.defaults.cdsExportDeclarationPayload;
        }
        return transformedData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template?.template]);

    const templateFormik = useFormik<Template>({
        initialValues: templateData ?? {
            master: { defaults: {}, meta: {} },
            product: { defaults: {}, meta: {} },
        },
        enableReinitialize: true,
        onSubmit: () => {},
    });

    useEffect(() => {
        if (!templateId) return;

        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Loading template data...' } });
        axiosClient
            .get<SuccessResponse<TemplateResponse>>(`${config.declarationTemplatesUrl}/${templateId}`)
            .then((res) => {
                hideGlobalOverlay();

                const _template = res.data.payload;
                if (!_template) return;
                setTemplate(_template);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateId]);

    useEffect(() => {
        const isTemplateUpdated = !isEqual(templateFormik.initialValues, templateFormik.values);

        setSaveChanges(isTemplateUpdated);
    }, [templateFormik.initialValues, templateFormik.values]);

    const { path, declaration } = useMemo(() => parseTemplateOptions(options), [options]);

    // This is duplicating
    const edit = async (data: Template | undefined, templateName?: string | null) => {
        const { id, ..._template } = template ?? ({} as TemplateResponse);
        if (!options?.country || !options.declarationType || !options.formType || !data)
            throw new Error('Data missing for creating template');
        const country =
            options.country === 'CDS'
                ? 'uk'
                : options.country === 'REVENUE'
                ? 'ireland'
                : (options.country.toLowerCase() as 'ireland' | 'uk');
        await createTemplate(country, options.declarationType.toLowerCase() as 'import' | 'export', options.formType!, {
            ..._template,
            templateName: templateName ?? 'Template',
            template: data,
        });
    };
    const create = async (data: Template | undefined, templateName?: string | null) => {
        if (!options?.country || !options.declarationType || !options.formType || !data)
            throw new Error('Data missing for creating template');
        const country =
            options.country === 'CDS'
                ? 'uk'
                : options.country === 'REVENUE'
                ? 'ireland'
                : (options.country.toLowerCase() as 'ireland' | 'uk');
        await createTemplate(country, options.declarationType.toLowerCase() as 'import' | 'export', options.formType!, {
            declarationName: options.formType,
            templateName: templateName ?? 'Template',
            template: data,
        });
    };

    const handleOkay = async (templateName?: string | null) => {
        const values = cloneDeep(templateFormik.values);
        if (isCds) {
            values.master.defaults = {
                cdsExportDeclarationPayload: templateFormik.values.master.defaults,
            };
        }
        const data = transformTemplateData(values, transform, 'forServer');
        if (isCds && data) {
            data.master.defaults = data.master.defaults.cdsExportDeclarationPayload;
        }
        const isEditing = Boolean(templateId);
        if (isEditing) {
            await edit(data, templateName);
        } else {
            await create(data, templateName);
        }

        const [country, type] = path?.split('/') ?? ['ireland', 'import'];

        if (!options?.formType) throw new Error('No form type set');

        listTemplates(country as any, type as any, options?.formType);
        openSuccessModal();
    };

    const handleChangeForm = (form: 'master' | 'product') => {
        setActiveForm(form);
        setForm?.(form);
    };

    const openSuccessModal = () =>
        Modal.success({
            content: `Template ${options?.name ?? ''} has been successfully created!`,
            closable: true,
            onOk: () => closeModal?.(),
            onCancel: () => closeModal?.(),
        });

    const saveChangesModal = () =>
        Modal.confirm({
            content: `Do you want to save ${options?.name ? `Template ${options?.name}` : 'this template'} ?`,
            closable: true,
            cancelText: 'No',
            okText: 'Yes',
            onOk: () => handleOkay(),
            onCancel: () => closeModal?.(),
        });

    return (
        <SModal
            visible={open}
            width={'100rem'}
            onCancel={() => (saveChanges ? saveChangesModal() : closeModal?.())}
            onOk={() => handleOkay()}
            footer={false}
            closable={false}
            destroyOnClose
            maskClosable={false}
        >
            <TemplateContext.Provider value={{ templateFormik, template: true, form, options, isViewOnly }}>
                <div style={{ position: 'relative' }}>
                    <TemplateHeader
                        creating={creating}
                        style={{
                            position: 'sticky',
                            top: 0,
                            zIndex: 2,
                            backgroundColor: 'white',
                            paddingTop: '1rem',
                        }}
                        onSave={handleOkay}
                        onClose={saveChanges ? saveChangesModal : closeModal}
                        onChangeActiveForm={handleChangeForm}
                        active={activeForm}
                    />
                    {activeForm === 'master' && <MasterDetails declaration={declaration} />}
                    {activeForm === 'product' && <DeclarationProductView declaration={declaration} />}
                </div>
            </TemplateContext.Provider>
        </SModal>
    );
};

export default TemplateModal;
