import { CheckSquareOutlined, CloseSquareOutlined, PhoneOutlined } from '@ant-design/icons';
import { ColumnsType, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import Avatar from 'components/ui/base/avatar/Avatar';
import Button from 'components/ui/base/button';
import Table from 'components/ui/base/table/Table';
import { TextExtraSmallTable } from 'components/ui/base/typography';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import { FC } from 'react';
import { Customer, CustomerSortParameter } from 'store/customers/customer';

interface Props {
    data?: ListPayload<Customer & { jobs: number }>;
    handlePagination: (paginator: Partial<PaginatedParams>) => void;
    handleSort: (paginator: Partial<PaginatedParams>) => void;
    loading: boolean;
    handleSelect?: (customer: Customer) => void;
}

const CustomersTable: FC<Props> = ({ data, handlePagination, handleSort, loading, handleSelect }) => {
    const columns: ColumnsType<any> = [
        {
            title: 'ID',
            dataIndex: CustomerSortParameter.ID,
            key: CustomerSortParameter.ID,
            align: 'left',
            sorter: true,
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.id ? <TextExtraSmallTable>{record.id}</TextExtraSmallTable> : <span>-</span>,
        },
        {
            title: 'Customer',
            dataIndex: CustomerSortParameter.NAME,
            key: CustomerSortParameter.NAME,
            align: 'left',
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.name ? (
                    <TextExtraSmallTable>
                        <Avatar name={record.name}></Avatar>
                        {record.name}
                    </TextExtraSmallTable>
                ) : (
                    <TextExtraSmallTable>-</TextExtraSmallTable>
                ),
            sorter: true,
        },
        {
            title: 'Active Jobs',
            dataIndex: 'JOBS',
            key: 'JOBS',
            align: 'left',
            render: (text: string, record: Customer & { jobs: number }, index: number) => (
                <TextExtraSmallTable>
                    {record.jobs > 0 ? (
                        <>
                            {record.jobs} active jobs
                            <i style={{ color: '#219653', marginLeft: '2.6rem' }}>
                                <CheckSquareOutlined />
                            </i>
                        </>
                    ) : (
                        <>
                            No active jobs
                            <i style={{ color: '#EB5757', marginLeft: '0.9rem' }}>
                                <CloseSquareOutlined />
                            </i>
                        </>
                    )}
                </TextExtraSmallTable>
            ),
            sorter: (a: any, b: any) => a.jobs - b.jobs,
        },
        {
            title: 'Email',
            dataIndex: CustomerSortParameter.EMAIL,
            key: CustomerSortParameter.EMAIL,
            align: 'left',
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.contact?.email ? (
                    <TextExtraSmallTable>{record.contact.email}</TextExtraSmallTable>
                ) : (
                    <TextExtraSmallTable>-</TextExtraSmallTable>
                ),
            sorter: true,
        },
        {
            title: 'Phone Number',
            dataIndex: CustomerSortParameter.PHONE,
            key: CustomerSortParameter.PHONE,
            align: 'left',
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.contact?.phoneNumber ? (
                    <TextExtraSmallTable>
                        <i style={{ marginRight: '0.9rem' }}>
                            <PhoneOutlined />
                        </i>
                        {record.contact.phoneNumber}
                    </TextExtraSmallTable>
                ) : (
                    <TextExtraSmallTable>-</TextExtraSmallTable>
                ),
            sorter: true,
        },
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            align: 'center',
            render: (text: string, record: Customer & { jobs: number }, index: number) => {
                return (
                    <>
                        <Button
                            onClick={() => {
                                handleSelect?.(record);
                            }}
                        >
                            Assign Customer
                        </Button>
                    </>
                );
            },
        },
    ];

    function onChange(
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<any> | SorterResult<any>[],
        extra: TableCurrentDataSource<any>
    ) {
        if (!Array.isArray(sorter) && sorter.columnKey !== 'JOBS') {
            const sortParameter = sorter.columnKey?.toString();
            if (sorter.order === 'ascend') {
                handleSort({ sortParameter, sortDirection: 'ASC' });
            } else if (sorter.order === 'descend') {
                handleSort({ sortParameter, sortDirection: 'DESC' });
            }
        }
    }

    return (
        <>
            <Table
                rowKey="id"
                style={{ marginTop: '2.5rem' }}
                columns={columns}
                dataSource={data?.list}
                pagination={{
                    defaultCurrent: 1,
                    total: data?.total,
                    showSizeChanger: false,
                    pageSize: data?.pageSize,
                    position: ['bottomCenter'],
                    onChange: (page: number, size: number | undefined) => {
                        const params = { page: page - 1, size };
                        handlePagination(params);
                    },
                }}
                onChange={onChange}
                loading={loading}
            />
        </>
    );
};
export default CustomersTable;
