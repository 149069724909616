import { ReactElement } from 'react';
import { Tabs } from 'antd';
import { PaginatedParams } from 'core/http/pagination';
import { FormikErrors, setNestedObjectValues, useFormik } from 'formik';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useCustomers from 'hooks/useCustomers';
import useDeclarationAutoRefresh from 'hooks/useDeclarationAutoRefresh';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import useDeclarationNotifications from 'hooks/useDeclarationNotifications';
import useDeclarationProductTaxes from 'hooks/useDeclarationProductTaxes';
import useDeclarations, { UpdateDeclarationFunction } from 'hooks/useDeclarations';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useExchanges from 'hooks/useExchanges';
import useModalDeclarationSubmit from 'hooks/useModalDeclarationSubmit';
import useProducts, { CreateProductFunction, ListProductFunction, UpdateProductFunction } from 'hooks/useProducts';
import useSession from 'hooks/useSession';
import { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Declaration, NotifyEmails } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import { DeclarationErrors } from 'views/declarations/common/declaration-errors';
import InformationAlert from 'views/declarations/common/InformationAlert';
import ModalPreviewSadAndClearanceSlip from 'views/declarations/common/ModalPreviewSadAndClearanceSlip';
import ValidationErrorContainer from 'views/declarations/common/ValidationErrorContainer/ValidationErrorContainer';
import WarningSubmitModal from 'views/declarations/common/WarningSubmitModal';
import DeclarationTabKeys from 'views/declarations/utils/DeclarationTabKeys';
import FormFooter from 'views/declarations/footer/Footer';
import { StyledHeader, StyledLayout, StyledTabs } from 'views/declarations/Form.styles';
import FormHeader from 'views/declarations/header/FormHeader';
import {
    isDeclarationRefundDepositRequested,
    isDeclarationRefundRemissionRequested,
} from 'views/declarations/utils/declaration-utils';
import {
    declarationHasBeenSubmitted,
    FormAction,
    removeEmptyObjectsFromDeclarationArrays,
    removeEmtpyObjects as removeEmptyObjects,
    trimWhiteSpaces,
} from 'views/declarations/utils/form-utils';
import { get, isEmpty, sortBy } from 'lodash';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import { AnyObjectSchema } from 'yup';
import { DeclarationFormErrors } from 'store/declaration-form-errors/DeclarationFormErrors';
import { JobResponse } from 'store/jobs/job';
import AlertRefundRemissionRequested from '../../ireland/common/AlertRefundRemissionRequested';
import AlertSubmitDocuments from '../../ireland/common/AlertSubmitDocuments';
import AlertInvalidationRequested from '../../ireland/common/AlertInvalidationRequested';
import AlertInsufficientFunds from '../../ireland/common/AlertInsufficientFunds';
import AlertRefundDepositRequested from '../../ireland/common/AlertRefundDepositRequested';
import SubmissionErrorsDrawer, { SubmissionError } from '../submissionErrors/SubmissionErrorsDrawer';
import useIndividuals from 'hooks/useIndividuals';
import ExternalSystemInvalidationRequested from 'views/declarations/ireland/common/ExternalSystemInvalidationRequested';
import useHandleFormAction from './utils/useHandleFormAction';
import useHandleActiveTabKeyBasedOnLocation, { updateActiveTabKey } from './utils/useHandleActiveTabKeyBasedOnLocation';
import useRefetchDeclarationOnTabChangeIfAmendable from './utils/useRefetchDeclarationOnTabChangeIfAmendable';
import useAutoSaveProduct from './utils/useAutoSaveProduct';
import useHandleErrors from './utils/useHandleErrors';
import useHandleUnsavedChanges from './utils/useHandleUnsavedChanges';
import validate, {
    FormModel,
    transformErrorsForFormik,
    ValidationSchema,
} from 'views/declarations/uk/export/validations/validations';
import { ErrorButton } from 'views/declarations/header/FormHeader.styles';
import { WarningOutlined } from '@ant-design/icons';
import TemplateModal from 'components/ui/composed/template/TemplateModal';
import { TemplateContextProvider } from 'components/ui/composed/template/TemplateContext';
import { DeclarationType } from '../declarationType';
import { IrelandNotification } from 'store/declarations/ireland/ireland-notification';
import { IrelandEnsNotification } from 'store/declarations/ireland/entry-summary-notification';
import { CdsExportNotification } from 'store/declarations/uk/export-declaration';
import useUpdateViewOfDeclaration from './utils/useUpdateViewOfDeclaration';

const { TabPane } = Tabs;

export interface DeclarationContentProps {
    activeTabKey: DeclarationTabKeys;
    setActiveTabKey: Function;
    declaration: Declaration;
    hasHmrc?: boolean;
    formik: any;
    productsFormik: any;
    jobCustomerId: string;
    hasCertificate: boolean;
    declarationErrors: DeclarationErrors;
    setEmails: Function;
    showSubmitModal: boolean;
    setShowSubmitModal: Function;
    setCancelClicked: Function;
    cancelClicked: boolean;
    saveProductClicked: boolean;
    setSaveProductClicked: Function;
    showErrorDrawer: boolean;
    setShowErrorDrawer: Function;
    handleSubmitButton: () => void;
}

type Declarations = NonNullable<Declaration[DeclarationType]>;

interface Transform<TReturn = any> {
    forClient?: (data: any, straight?: boolean) => TReturn;
    forServer?: (data: any, straight?: boolean) => TReturn;
}

export interface TransformData {
    product?: Transform;
    declaration?: Transform;
}

export interface DeclarationViewProps<TDeclaration extends Declarations> {
    declarationValidationSchema?: AnyObjectSchema | ((numberOfItems?: number) => AnyObjectSchema);
    declarationFormValidations?: ValidationSchema;
    productFormValidations?: ValidationSchema;
    productValidationSchema?: AnyObjectSchema | undefined;
    validationErrorsParser?: (errors: FormikErrors<TDeclaration>) => DeclarationFormErrors;
    declarationType: DeclarationType;
    declarationInternalType: DeclarationInternalType;
    declarationName: string;
    DeclarationContentComponent: FC<DeclarationContentProps>;
    updateDeclarationFuncName: keyof OnlyFunctions<ReturnType<typeof useDeclarations>>;
    updateProductFuncName: keyof OnlyFunctions<ReturnType<typeof useProducts>> | undefined;
    createProductFuncName: keyof OnlyFunctions<ReturnType<typeof useProducts>> | undefined;
    listProductsFuncName: keyof OnlyFunctions<ReturnType<typeof useProducts>> | undefined;
    transformData?: TransformData;
    productFieldsToAutosaveOn?: string[];
    payloadPath?: string;
    transformProductCreateResponse?: (res: any) => any;
    numberOfItemsFieldName?: string;
}

interface Props<TDeclaration extends Declarations> {
    declaration: Declaration | undefined;
    job: JobResponse;
    props: DeclarationViewProps<TDeclaration>;
}

export const ProductContext = createContext<{ productId?: string; setProductId?: (id?: string) => void }>({});

const DeclarationView = <TDeclaration extends Declarations>({
    declaration: declarationProp,
    job,
    props,
}: Props<TDeclaration>): ReactElement => {
    // * UseState
    const location = useLocation();
    const [declaration, setDeclaration] = useState<Declaration | undefined>(declarationProp);
    const [triedToSubmit, setTriedToSubmit] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState<DeclarationTabKeys>(() => updateActiveTabKey(location));
    const [cancelClicked, setCancelClicked] = useState<boolean>(false);
    const [emails, setEmails] = useState<NotifyEmails>();
    const [declarationErrors, setDeclarationErrors] = useState<DeclarationErrors>({
        masterDetailsError: false,
        productsError: [],
    });
    const { productId: urlParamsProductId } = useParams();
    const [productId, setProductId] = useState<string | undefined>(urlParamsProductId);
    const [isSaving, setIsSaving] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [declarationSaved, setDeclarationSaved] = useState(true);
    const [validationErrorContainer, setValidationErrorContainer] = useState(false);
    const [saveProductClicked, setSaveProductClicked] = useState<boolean>(false);
    const [showErrorDrawer, setShowErrorDrawer] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [openSubmissionErrorsDrawer, setOpenSubmissionErrorsDrawer] = useState(false);
    const [submissionErrors, setSubmissionErrors] = useState<SubmissionError[] | null | undefined>(null);

    // * Hooks
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { userInfo } = useSession();
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const declarations = useDeclarations({
        declarationId: declaration?.id,
        customerId: job?.customerId,
    });
    const { individual } = useIndividuals({ individualId: userInfo?.individualId });
    const products = useProducts({ productId });
    const { setFormDeclarationErrors, clearFormDeclarationErrors } = useDeclarationFormErrors();
    const { customer } = useCustomers({ customerId: userInfo?.customerId });
    const {
        modalPreviewSadAndClearanceSlip,
        digitalCertificateModal,
        onCloseModalPreviewSadAndClearanceSlip,
        onCloseDigitalCertificateModal,
        displayModalSubmit,
    } = useModalDeclarationSubmit();
    const { getDeclarationProductTaxes } = useDeclarationProductTaxes(declaration?.id);
    const { listExchanges } = useExchanges();
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const { showErrorNotification } = useDeclarationNotifications();
    const { declarationValidation, setFormAction, setPressedSubmit, setHasUnsavedChanges, isSavingAsDraft } =
        useDeclarationValidation({
            formAction: null,
        });

    const currentDeclaration = useMemo(
        () => declaration?.[props.declarationType],
        [declaration, props.declarationType]
    );

    const getValidationSchema = (): AnyObjectSchema | undefined =>
        props.declarationValidationSchema instanceof Function
            ? props.declarationValidationSchema(products.products?.total)
            : props.declarationValidationSchema;

    const formik = useFormik<TDeclaration>({
        initialValues:
            (props.payloadPath ? get(currentDeclaration, props.payloadPath) : (currentDeclaration as any)) ?? {},
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: getValidationSchema(),
        validate: props.declarationFormValidations
            ? async (values) => {
                  // TODO: Memoize already validated fields to speed up the process.
                  // If we don't do this, we may end up with a lot of unnecessary requests to eori check service for example.
                  return transformErrorsForFormik(
                      await validate(new FormModel(values), props.declarationFormValidations!)
                  );
              }
            : () => {},
        onSubmit: () => {},
    });

    const productInitialValues = useMemo(() => {
        if (props.transformData?.product?.forClient) {
            return props.transformData?.product?.forClient(
                isEmpty(products.product)
                    ? declarations.declarationTemplate?.template?.product.defaults
                    : products.product
            );
        }
        if (isEmpty(products.product)) {
            return declarations.declarationTemplate?.template?.product.defaults;
        }
        return products.product;
    }, [declarations.declarationTemplate?.template?.product.defaults, products.product, props.transformData?.product]);

    const productsFormik = useFormik({
        initialValues: productInitialValues ?? {},
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: props.productValidationSchema,
        validate: props.productFormValidations
            ? async (values) =>
                  // TODO: Memoize already validated fields to speed up the process.
                  // If we don't do this, we may end up with a lot of unnecessary requests to eori check service for example.
                  transformErrorsForFormik(await validate(new FormModel(values), props.productFormValidations!))
            : () => {},
        onSubmit: () => {},
    });

    // * UseMemo

    const showPresentationNotification = useMemo(
        () =>
            declaration?.status === DeclarationStatus.REGISTERED &&
            currentDeclaration &&
            'additionalDeclarationType' in currentDeclaration &&
            currentDeclaration.additionalDeclarationType === 'D',
        [currentDeclaration, declaration?.status]
    );

    const formTabName = useMemo(() => {
        if (declaration?.status === DeclarationStatus.RELEASED) return 'Summary';
        return `${props.declarationName} Form`;
    }, [declaration?.status, props.declarationName]);

    const isUkDeclaration = useMemo(
        () => declaration?.cdsImportDeclaration ?? declaration?.cdsExportDeclaration,
        [declaration]
    );

    const hasRightCertificate: boolean = useMemo(() => {
        if (isUkDeclaration) return individual?.hasHmrcToken ?? false;
        else return customer?.hasAisCertificate ?? false;
    }, [customer?.hasAisCertificate, individual?.hasHmrcToken, isUkDeclaration]);

    const declarationCountry = useMemo(() => {
        return isUkDeclaration ? DeclarationCountry.UK : DeclarationCountry.IRELAND;
    }, [isUkDeclaration]);

    const remarks = useMemo(() => {
        if (currentDeclaration && !('notifications' in currentDeclaration)) return;

        const notifications = currentDeclaration?.notifications?.sort((elementA: any, elementB: any) => {
            const dateA = new Date(elementA.notificationDate);
            const dateB = new Date(elementB.notificationDate);
            return dateB.getTime() - dateA.getTime();
        });

        if (!notifications || notifications.length === 0 || !('submissionErrors' in notifications[0])) return;

        return (notifications[0].submissionErrors as any[]).find((e: any) => !!e.remarks)?.remarks;
    }, [currentDeclaration]);

    // * Functions
    const touchFieldsToShowErrorsMasterDetails = useCallback(
        (validations: FormikErrors<TDeclaration>) => {
            formik.setTouched(setNestedObjectValues(validations, true));
        },
        [formik]
    );

    const validateForm = useCallback(async () => {
        const masterDetailsValidation = await formik.validateForm();

        touchFieldsToShowErrorsMasterDetails(masterDetailsValidation);

        if (isEmpty(masterDetailsValidation)) {
            // Reset errors
            setDeclarationErrors({ masterDetailsError: false, productsError: [] });
            clearFormDeclarationErrors();

            return true;
        }

        if (isSavingAsDraft) return true;

        if (props.validationErrorsParser) {
            setFormDeclarationErrors(props.validationErrorsParser(masterDetailsValidation));
        }

        return false;
    }, [
        formik,
        touchFieldsToShowErrorsMasterDetails,
        isSavingAsDraft,
        props,
        clearFormDeclarationErrors,
        setFormDeclarationErrors,
    ]);

    const handleSubmitForm = async () => {
        if (!declaration || declarationValidation.formAction !== FormAction.SUBMIT) return;

        setIsSubmitting(true);
        await declarations.submitDeclaration(declaration.id!, { ...emails } as NotifyEmails);
        setIsSubmitting(false);
    };

    const updateProduct = async (form: any, declarationId: string) => {
        if (!products.product?.id || !props.updateProductFuncName) return;

        return (products[props.updateProductFuncName] as UpdateProductFunction)(
            removeEmptyObjects(form),
            declarationId,
            products.product.id
        ).then((res) => {
            if (!res) return;
            setHasUnsavedChanges(false);
            return res;
        });
    };
    const createProduct = async (form: any, declarationId: string) => {
        if (products.product?.id || !props.createProductFuncName) return;

        await (products[props.createProductFuncName] as CreateProductFunction)(form, declarationId).then((res) => {
            if (!res?.id) return;

            let data;

            if (props.transformProductCreateResponse) {
                const transformed = props.transformProductCreateResponse(res);
                data = props.transformData?.product?.forClient?.(transformed) ?? transformed ?? {};
            } else {
                data = props.transformData?.product?.forClient?.(res) ?? res ?? {};
            }

            productsFormik.setValues(data);
            setProductId(res.id);
            navigate(`/declarations/${declarationId}/products/${res.id}`);
            return data;
        });
    };
    const handleProductsFormSubmit = async (item: GoodsShipmentItem) => {
        if (!declaration) return;

        let form = removeEmptyObjectsFromDeclarationArrays(trimWhiteSpaces({ ...item }, { emptyStringToNull: true }));

        const isProductCreated = products.product?.id;

        if (props.transformData?.product?.forServer) {
            form = props.transformData.product.forServer(form);
        }

        let data;
        if (isProductCreated) {
            data = await updateProduct(form, declaration.id!);
        } else {
            data = await createProduct(form, declaration.id!);
        }

        window.EventBus.publish('afterDeclarationSave', data);

        await declarations.getDeclaration(declaration.id!);
    };

    const saveDeclaration = async (data: any, declarationId: string) => {
        return (declarations[props.updateDeclarationFuncName] as UpdateDeclarationFunction)(
            job?.customerId,
            declarationId,
            data
        ).then((res) => {
            if (!res) return;

            window.EventBus.publish('afterDeclarationSave');
            setHasUnsavedChanges(false);
            setDeclarationSaved(true);
        });
    };
    const saveAsDraft = async () => {
        if (!declaration) return;

        let form = removeEmptyObjects(
            removeEmptyObjectsFromDeclarationArrays(
                trimWhiteSpaces(formik.values, {
                    emptyStringToNull: true,
                })
            )
        );

        if (props.transformData?.declaration?.forServer !== undefined) {
            if (props.payloadPath) {
                form = {
                    ...declaration?.[props.declarationType],
                    [props.payloadPath]: form,
                };
            }

            form = props.transformData.declaration.forServer(form);
        }

        const data = { ...declaration, [props.declarationType]: form };

        window.EventBus.publish('beforeDeclarationSave', true);
        return saveDeclaration(data, declaration.id!);
    };

    const handleSubmit = async () => {
        const valid = await validateForm();
        setPressedSubmit();

        if (!valid) return;

        onCloseModalPreviewSadAndClearanceSlip();
        setShowSubmitModal(true);
    };

    const handleSubmitButton = () => {
        setFormAction(FormAction.SUBMIT);
        setShowSubmitModal(false);
    };

    const refetchDeclaration = useCallback(() => {
        return declarations.getDeclaration(declaration?.id!);
    }, [declaration?.id, declarations]);

    const handleFormErrorDetails = () => {
        setValidationErrorContainer(true);
    };

    // * useEffects
    useDeclarationAutoRefresh();
    useUpdateViewOfDeclaration({ declarationStatus: declaration?.status });
    useEffect(() => {
        setBreadcrumbRoutes([
            { breadcrumbName: 'Home', path: '' },
            { breadcrumbName: 'Forms', path: '' },
            { breadcrumbName: 'In Progress', path: '' },
        ]);

        clearFormDeclarationErrors();
        listExchanges();
        return () => {
            clearFormDeclarationErrors();
            products.clearError();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Submission validation errors drawer
    useEffect(() => {
        if (!declarations.error || declarations.error.category !== 'VALIDATION') {
            return;
        }

        // Disable submission error drawer for all declarations other than ENS
        // TODO Remove when necessary
        if (declarations.declaration?.entrySummaryDeclaration == null) return;

        setOpenSubmissionErrorsDrawer(true);

        setSubmissionErrors(
            declarations.error?.subErrors
                ?.filter((error) => error.category === 'VALIDATION')
                .map((error) => ({ message: error.message }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declarations.error]);

    useEffect(() => {
        formik.setValues(
            (props.payloadPath ? get(currentDeclaration, props.payloadPath) : (currentDeclaration as any)) ?? {}
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDeclaration]);
    useEffect(() => {
        productsFormik.setValues(productInitialValues ?? {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productInitialValues]);

    // Trigger transform
    useEffect(() => {
        if (props.transformData?.declaration?.forClient) {
            setDeclaration(props.transformData.declaration.forClient(declarationProp));
        } else {
            setDeclaration(declarationProp);
        }
    }, [props.transformData?.declaration?.forClient, declarationProp, props.transformData]);

    useHandleFormAction({
        productsFormik,
        saveDeclaration: saveAsDraft,
        saveProduct: handleProductsFormSubmit,
        submitDeclaration: handleSubmitForm,
        setIsSaving,
        validateForm,
        refetchDeclaration,
    });
    useRefetchDeclarationOnTabChangeIfAmendable({ declarations, declaration, currentDeclaration, activeTabKey });
    useHandleActiveTabKeyBasedOnLocation({ setActiveTabKey });
    useAutoSaveProduct({
        setFormAction,
        declarationStatus: declaration?.status,
        productsFormik,
        fieldsToAutosaveOn: props.productFieldsToAutosaveOn,
    });
    useHandleErrors({
        formik,
        productsFormik,
        validationErrorsParser: props.validationErrorsParser,
        triedToSubmit,
    });
    useHandleUnsavedChanges({
        declarationSaved,
        formik,
        productsFormik,
        setDeclarationSaved,
    });

    const headerRender = useMemo(
        () =>
            declaration &&
            activeTabKey !== DeclarationTabKeys.VIEW_ONLY &&
            activeTabKey !== DeclarationTabKeys.HISTORY ? (
                <FormHeader
                    title={`${props.declarationName} declaration`}
                    declaration={declaration}
                    job={job}
                    activeCustomer={() => {
                        navigate(`/declarations/${declaration.id}/${DeclarationTabKeys.CUSTOMER_DETAILS}`, {
                            state: { activeTabKey: DeclarationTabKeys.CUSTOMER_DETAILS },
                        });
                        setActiveTabKey(DeclarationTabKeys.CUSTOMER_DETAILS);
                    }}
                    showErrors={() => setShowErrorDrawer(true)}
                    remarks={remarks}
                />
            ) : null,
        [declaration, activeTabKey, props.declarationName, job, remarks, navigate]
    );

    const declarationErrorsWarning = useMemo(() => {
        const declarationType =
            declaration?.irelandImportDeclaration ??
            declaration?.irelandH7ImportDeclaration ??
            declaration?.entrySummaryDeclaration ??
            declaration?.cdsExportDeclaration;

        if (!declarationType?.notifications) return <></>;

        const lastNotificationIndex = declarationType.notifications.length - 1;

        const latestNotification = (
            declarationType.notifications.some(
                (declarationNotification) => 'notificationDate' in declarationNotification
            )
                ? sortBy(declarationType.notifications as IrelandNotification[], 'notificationDate')
                : declarationType.notifications
        )?.[lastNotificationIndex];
        const declarationNotificationErrors =
            (latestNotification as IrelandNotification | undefined)?.submissionErrors ?? // Ireland Import
            (latestNotification as IrelandEnsNotification | undefined)?.rejectedNotification?.errors ?? // ENS
            (latestNotification as CdsExportNotification | undefined)?.validationMessages; // CDS Export

        if (!declarationNotificationErrors?.length) return <></>;

        return (
            <ErrorButton onClick={() => setShowErrorDrawer(true)}>
                View error messages <WarningOutlined />
            </ErrorButton>
        );
    }, [declaration]);

    const showCertificateAlert = useMemo(() => {
        if (isUkDeclaration) {
            if (!individual) return false;
            return !individual?.hasHmrcToken;
        } else {
            if (!customer) return false;
            return !customer?.hasAisCertificate;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer, individual]);

    const handleDeclarationFormTabs = useMemo(() => {
        if (!declaration?.status) return;

        if (declarationHasBeenSubmitted(declaration?.status)) {
            return <TabPane tab="Form info" key={DeclarationTabKeys.VIEW_ONLY} />;
        } else if (declaration.status === DeclarationStatus.RELEASED) {
            return (
                <>
                    <TabPane tab={formTabName} key={DeclarationTabKeys.SUMMARY} />
                    <TabPane tab="Form info" key={DeclarationTabKeys.VIEW_ONLY} />
                </>
            );
        } else {
            return <TabPane tab={formTabName} key={DeclarationTabKeys.FORM} />;
        }
    }, [declaration?.status, formTabName]);

    return (
        <StyledLayout>
            <TemplateContextProvider>
                <StyledHeader>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <StyledTabs
                            onChange={(k) => {
                                const tab = k as DeclarationTabKeys;
                                if (tab === DeclarationTabKeys.FORM) {
                                    navigate(`/declarations/${declaration?.id}`, { state: { activeTabKey: tab } });
                                } else {
                                    navigate(`/declarations/${declaration?.id}/${tab}`, {
                                        state: { activeTabKey: tab },
                                    });
                                }
                                setActiveTabKey(tab);
                            }}
                            activeKey={activeTabKey}
                        >
                            {handleDeclarationFormTabs}
                            <TabPane tab="Documentation" key={DeclarationTabKeys.DOCUMENTATION} />
                            <TabPane tab="Customer Details" key={DeclarationTabKeys.CUSTOMER_DETAILS} />
                            <TabPane tab="History" key={DeclarationTabKeys.HISTORY} />
                            {declarations.declaration?.status !== 'DRAFT' && (
                                <TabPane tab="Refunds" key={DeclarationTabKeys.REFUNDS} />
                            )}
                        </StyledTabs>
                        {(declaration?.irelandH7ImportDeclaration ||
                            declaration?.entrySummaryDeclaration ||
                            declaration?.irelandImportDeclaration ||
                            declaration?.cdsExportDeclaration) &&
                            declarationErrorsWarning}
                    </div>
                    {!(
                        declaration?.irelandH7ImportDeclaration ||
                        declaration?.entrySummaryDeclaration ||
                        declaration?.irelandImportDeclaration ||
                        declaration?.cdsExportDeclaration ||
                        declaration?.cdsImportDeclaration
                    ) && headerRender}
                    <InformationAlert showAlert={showCertificateAlert} country={declarationCountry} />
                    <AlertRefundRemissionRequested show={isDeclarationRefundRemissionRequested(declaration)} />
                    {isDeclarationRefundDepositRequested(declaration) && <AlertRefundDepositRequested />}
                    <AlertInsufficientFunds
                        insufficientFunds={declaration?.status === DeclarationStatus.INSUFFICIENT_FUNDS}
                    />
                    <AlertSubmitDocuments
                        documentsUploadRequested={declaration?.irelandImportDeclaration?.documentsUploadRequested}
                        documentsPresentationRequested={
                            declaration?.irelandImportDeclaration?.documentsPresentationRequested
                        }
                    />
                    <AlertInvalidationRequested
                        userInvalidationRequested={declaration?.irelandImportDeclaration?.userInvalidationSubmitted}
                    />
                    <ExternalSystemInvalidationRequested
                        systemInvalidationRequested={
                            declaration?.irelandImportDeclaration?.revenueInvalidationRequested
                        }
                        external="Revenue"
                    />
                </StyledHeader>

                <StyledLayout>
                    <ProductContext.Provider
                        value={{ productId: productId, setProductId: (value) => setProductId(value) }}
                    >
                        <props.DeclarationContentComponent
                            activeTabKey={activeTabKey}
                            setActiveTabKey={setActiveTabKey}
                            declaration={declaration!}
                            formik={formik}
                            productsFormik={productsFormik}
                            jobCustomerId={job?.customerId}
                            hasCertificate={hasRightCertificate}
                            declarationErrors={declarationErrors}
                            setEmails={setEmails}
                            setShowSubmitModal={setShowSubmitModal}
                            showSubmitModal={showSubmitModal}
                            handleSubmitButton={handleSubmitButton}
                            cancelClicked={cancelClicked}
                            setCancelClicked={setCancelClicked}
                            saveProductClicked={saveProductClicked}
                            setSaveProductClicked={setSaveProductClicked}
                            showErrorDrawer={showErrorDrawer}
                            setShowErrorDrawer={setShowErrorDrawer}
                            hasHmrc={hasRightCertificate}
                        />
                    </ProductContext.Provider>
                </StyledLayout>
                {activeTabKey === DeclarationTabKeys.FORM && (
                    <FormFooter
                        declartionInternalType={props.declarationInternalType}
                        declarationId={declaration?.id}
                        declarationStatus={declaration?.status}
                        isSaving={isSaving}
                        iconDeclarationSaved={declarationSaved}
                        isSubmiting={isSubmitting}
                        disabled={
                            currentDeclaration &&
                            typeof currentDeclaration === 'object' &&
                            'userInvalidationSubmitted' in currentDeclaration &&
                            !!currentDeclaration.userInvalidationSubmitted
                        }
                        externalInvalidationRequested={
                            currentDeclaration &&
                            typeof currentDeclaration === 'object' &&
                            'revenueInvalidationRequested' in currentDeclaration &&
                            currentDeclaration.revenueInvalidationRequested
                        }
                        showPresentationNotificaition={showPresentationNotification}
                        cancelSaveProduct={() => {
                            setCancelClicked(true);
                            setFormAction(null);
                            navigate(`/declarations/${declaration?.id}/products`, {
                                replace: true,
                                state: { comingBackFromProductView: true },
                            });
                        }}
                        saveProduct={{
                            trigger: () => {
                                showGlobalOverlay({
                                    type: 'LoadingOverlay',
                                });
                                setTriedToSubmit(false);
                                setFormAction(FormAction.PRODUCT);
                            },
                            loading: isSaving,
                        }}
                        saveDraft={{
                            trigger: async () => {
                                showGlobalOverlay({
                                    type: 'LoadingOverlay',
                                });
                                setTriedToSubmit(false);
                                setFormAction(FormAction.DRAFT);
                            },
                            loading: isSaving,
                        }}
                        submitDeclaration={{
                            trigger: async () => {
                                showGlobalOverlay({
                                    type: 'LoadingOverlay',
                                });

                                if (!declaration?.id) return;

                                /* FIXME: this setVALUE it's just for the declaration's product list to have the same ordering as the product table */
                                const params: Partial<PaginatedParams> = { size: 1000 };

                                if (props.listProductsFuncName) {
                                    const productsList = await (
                                        products[props.listProductsFuncName] as ListProductFunction
                                    )(declaration.id, params);

                                    if (productsList?.list) {
                                        formik
                                            .getFieldHelpers('goodsShipment.goodsShipmentItem')
                                            .setValue(productsList.list);
                                    }
                                }

                                await saveAsDraft();
                                const isValid = await validateForm();

                                if (!isValid) {
                                    showErrorNotification(
                                        t('error.declaration_invalid_title'),
                                        t('error.formRequired')
                                    );
                                }

                                displayModalSubmit(
                                    hasRightCertificate,
                                    getDeclarationProductTaxes,
                                    undefined,
                                    undefined,
                                    declaration.irelandImportDeclaration !== undefined
                                );

                                setTriedToSubmit(true);

                                hideGlobalOverlay();
                            },
                            loading: isSubmitting,
                        }}
                    />
                )}
                <WarningSubmitModal
                    country={declarationCountry}
                    isOpen={digitalCertificateModal}
                    close={() => {
                        onCloseDigitalCertificateModal();
                    }}
                />
                {declaration?.id && (
                    <ModalPreviewSadAndClearanceSlip
                        declarationId={declaration.id}
                        isOpen={modalPreviewSadAndClearanceSlip}
                        onClickErrorDetails={handleFormErrorDetails}
                        close={() => {
                            onCloseModalPreviewSadAndClearanceSlip();
                        }}
                        confirm={() => {
                            handleSubmit();
                        }}
                        taxes
                        declaration={declaration}
                    />
                )}
                <ValidationErrorContainer
                    isOpen={validationErrorContainer}
                    close={() => {
                        setValidationErrorContainer(false);
                    }}
                />
                <SubmissionErrorsDrawer
                    errors={submissionErrors}
                    open={openSubmissionErrorsDrawer}
                    onClose={() => setOpenSubmissionErrorsDrawer(false)}
                />
                <TemplateModal />
            </TemplateContextProvider>
        </StyledLayout>
    );
};

export default DeclarationView;
