import { NavItem } from 'views/declarations/common/nav-item';

export const exportSectionsMasterDetails: NavItem[] = [
    { title: 'Master Details', href: '#master-details' },
    { title: 'Parties', href: '#deferment-account' },
    { title: 'Holder of the authorisation identification No.', href: '#notify-party' },
    { title: 'Identification of Warehouse', href: '#premisses' },
    { title: 'Total Amount Invoiced', href: '#supervising-office' },
    { title: 'Country of Destination', href: '#extra-details' },
    { title: 'Location of Goods', href: '#transport-information' },
    { title: 'Supervising Customs Office', href: '#processing-instructions' },
    { title: 'Customs Office of Presentation', href: '#reasons-for-amendment' },
    { title: 'Gross Mass', href: '#reasons-for-amendment' },
    { title: 'Mode of Transport At The Border', href: '#reasons-for-amendment' },
    { title: 'Identity of means of transport at departure', href: '#reasons-for-amendment' },
    { title: 'Identity of active means of transport crossing the border', href: '#reasons-for-amendment' },
    { title: 'Countries Of Routing Codes', href: '#reasons-for-amendment' },
    { title: 'Guarantee Reference', href: '#reasons-for-amendment' },
    { title: 'Nature of Transaction', href: '#reasons-for-amendment' },
    { title: 'Previous Documents', href: '#reasons-for-amendment' },
    { title: 'Additional Information', href: '#reasons-for-amendment' },
    { title: 'Additional supply chain actor(s) Ident. No.', href: '#reasons-for-amendment' },
    { title: 'Additional Fiscal References Identification', href: '#reasons-for-amendment' },
    { title: 'Transport Equipment', href: '#reasons-for-amendment' },
    { title: 'Consignment Items', href: '#reasons-for-amendment' },
];

export const exportSectionsProducts: NavItem[] = [
    { title: 'Commodity Codes', href: '#goods-information' },
    { title: 'Goods Information', href: '#goods-information' },
    { title: 'Procedure', href: '#goods-information' },
    { title: 'Parties', href: '#goods-information' },
    { title: 'Additional Information', href: '#goods-information' },
    { title: 'Additional Document', href: '#goods-information' },
    { title: 'Nature of Transaction', href: '#goods-information' },
    { title: 'Packages', href: '#goods-information' },
    { title: 'Previous Documents', href: '#goods-information' },
    { title: 'Additional supply chain actor(s) Ident. No.', href: '#goods-information' },
    { title: 'Additional Fiscal References Identification', href: '#goods-information' },
    { title: 'Country of Origin', href: '#goods-information' },
    { title: 'Transport Equipment', href: '#goods-information' },
];
