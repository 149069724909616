import { FormikErrors } from 'formik';
import { get, set, startCase } from 'lodash';
import { FieldError, ItemsFieldError } from 'store/declaration-form-errors/DeclarationFormErrors';
import { CardError } from 'store/declaration-form-errors/DeclarationFormErrors';
import { CdsExportCustomer, CdsExportDeclaration } from '../store/declarations/uk/export-declaration';
import { CdsImportDeclaration } from '../store/declarations/uk/import-declaration';

const addIndexToPathBeforeLast = (path: string) => {
    const split = path.split('.');
    split.splice(split.length - 1, 0, '1');
    return split.join('.');
};

const subtractOneFromFirstFoundNumber = (string: string | undefined) => {
    let newString = string;
    const splitString = string?.split(' ');
    const indexOfNumber = splitString?.findIndex((part) => !isNaN(Number(part)));
    if (splitString && indexOfNumber && indexOfNumber !== -1) {
        splitString[indexOfNumber] = (Number(splitString[indexOfNumber]) - 1).toString();
        newString = splitString?.join(' ');
    }
    return newString;
};
const getCardAndFieldName = (cardErrors: CardError[]) => {
    const card = cardErrors.find(({ fields }) => fields !== undefined);
    const cardName = card?.name ?? 'Unknown card';
    const fieldName = card?.fields?.[0].name ?? 'Unknown field';
    return { cardName, fieldName };
};
export const getFieldName = (
    validationParser: (validation: FormikErrors<any>) => {
        masterDetails: CardError[];
        items: ItemsFieldError[];
    },
    path: string,
    productPath: string
) => {
    const isItem = path.includes(productPath);
    // if (productPath === 'governmentAgencyGoodsItem' && isItem) {
    //     const split = path.split('.');
    //     path = [split.at(0), split.at(3), split.at(2), ...split.slice(4)].join('.');
    // }

    let errors = validationParser(set({}, path, 'error'));
    if (!errors.items.length && !errors.masterDetails.length) {
        errors = validationParser(set({}, addIndexToPathBeforeLast(path), 'error'));
    }

    if (isItem && errors.items.length) {
        const product = errors.items[0];
        const productNumber = product.index - 1;

        let { cardName, fieldName } = getCardAndFieldName(product.errors);
        cardName = subtractOneFromFirstFoundNumber(cardName) ?? 'Unknown card';

        return `Item Details - Product ${productNumber} - ${cardName} - ${fieldName}`;
    } else if (isItem) {
        const regex = new RegExp(/\bgoodsShipment\.governmentAgencyGoodsItem\.(\d+)\b/g);
        const match = regex.exec(path);
        if (match?.length) return `Product ${match[1]}`;
    } else if (errors.masterDetails.length) {
        let { cardName, fieldName } = getCardAndFieldName(errors.masterDetails);
        cardName = subtractOneFromFirstFoundNumber(cardName) ?? 'Unknown card';

        return `Master Information - ${cardName} - ${fieldName}`;
    }

    return undefined;
};

export const parseFieldErrorsForCard = (
    cardName: string,
    paths: (string | { [path: string]: string })[],
    errors: CardError[],
    validation: FormikErrors<CdsExportDeclaration | CdsImportDeclaration>
) => {
    const fields = parseAnyValidationError(paths, validation);
    if (fields.length > 0) {
        errors.push({ name: cardName, fields });
    }
};

export const parseFieldErrorsForMultipleCard = (
    cardName: string,
    cardPath: string,
    paths: (string | { [path: string]: string })[],
    errors: CardError[],
    validation: FormikErrors<CdsExportDeclaration | CdsImportDeclaration>
) => {
    let hasErrors = false;

    const data = get(validation, cardPath);

    if (typeof data === 'string') {
        errors.push({ name: cardName, fields: [{ name: cardName, message: data }] });
    } else if (Array.isArray(data)) {
        data?.forEach((val: any, index: number) => {
            const fields = parseAnyValidationError(paths, val);
            if (!hasErrors && fields.length > 0) {
                errors.push({ name: cardName, fields: undefined });
                hasErrors = true;
            }
            if (fields.length > 0) {
                errors.push({ name: `${cardName} ${index + 1}`, fields });
            }
        });
    }
};

export const parseFieldErrorsForCustomerCard = (
    cardName: string,
    customerPath: string,
    errors: CardError[],
    validation: FormikErrors<CdsExportDeclaration | CdsImportDeclaration>
) => {
    const fields = parseDeclarationCustomerErrors(get(validation, customerPath), cardName);
    if (fields.length > 0) {
        errors.push({ name: 'Parties', fields });
    }
};

export const parseDeclarationCustomerErrors = (customer: CdsExportCustomer, customerName: string) => {
    const fields: FieldError[] = [];

    const addCustomerName = (fieldName: string) => {
        if (!customerName) {
            return fieldName;
        }
        return customerName + ' - ' + fieldName;
    };

    if (typeof customer === 'string') {
        fields.push({ name: customerName, message: customer });
    }

    if (customer?.id) {
        fields.push({ name: addCustomerName('Identification Number'), message: customer.id });
    }

    if (customer?.name) {
        fields.push({ name: addCustomerName('Name'), message: customer.name });
    }

    if (customer?.address?.line) {
        fields.push({ name: addCustomerName('Street And Number'), message: customer.address?.line });
    }

    if (customer?.address?.cityName) {
        fields.push({ name: addCustomerName('City'), message: customer.address.cityName });
    }

    if (customer?.address?.postcodeID) {
        fields.push({ name: addCustomerName('Post Code'), message: customer.address.postcodeID });
    }

    if (customer?.address?.countryCode) {
        fields.push({ name: addCustomerName('Country'), message: customer.address.countryCode });
    }

    return fields;
};

export const parseAnyValidationError = (
    paths: (string | { [path: string]: string })[],
    validation: FormikErrors<unknown>,
    prefix?: string
) => {
    const fields: FieldError[] = [];

    paths.forEach((path) => {
        const pathIsString = typeof path === 'string';
        const pathIsObject = typeof path === 'object' && path !== null;

        if (pathIsString) {
            const val = get(validation, path);
            if (!val) return;

            const name = startCase(path.split('.').at(-1));
            const finalName = prefix ? `${prefix} - ${name}` : name;
            fields.push({ name: finalName, message: val });
        } else if (pathIsObject) {
            const pathKey = Object.keys(path)[0];
            const val = get(validation, pathKey);
            if (!val) return;

            const pathValue = Object.values(path)[0];
            const name = startCase(pathValue);
            const finalName = prefix ? `${prefix} - ${name}` : name;
            fields.push({ name: finalName, message: val });
        } else {
            throw new Error('Validation parser paths type violated');
        }
    });

    return fields;
};
