import { FC } from 'react';
import { Option, SearchIcon } from './Filter.styles';

interface FilterOptionsProps {
    options: string[];
    selectedOption?: (option: string) => void;
}
export const FilterOptions: FC<FilterOptionsProps> = ({ options, selectedOption }) => {
    return (
        <>
            {options.map((o, index) => (
                <Option key={`${o}-${index}`} onClick={() => selectedOption && selectedOption(o)}>
                    <SearchIcon /> {o}
                </Option>
            ))}
        </>
    );
};

export default FilterOptions;
