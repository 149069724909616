import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import CountrySelect from '../../../../../components/ui/composed/declarations/CountrySelect';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { normalizeCodesToSelect } from 'store/codelists/code';
import useCodelists from 'hooks/useCodelists';
import { ValidationSchema, validators } from '../validations/validations';

export type Fields = 'countryCode' | 'typeCode';

export const countryOfOriginBlockValidation: {
    childValidators: {
        origin: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        origin: {
            childValidators: {
                countryCode: [validators.exact(2)],
                typeCode: [validators.number(), validators.exact(1)],
            },
            stopAtNull: true,
        },
    },
};

export const countryOfOriginFields = countryOfOriginBlockValidation.childValidators.origin;

interface Props extends BlockProps<Fields> {}

const CountryOfOriginBlock = ({ path = 'origin', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();

    const ucc = useMemo(() => '5/15', []);
    return (
        <>
            <DeclarationField pathPrefix={path} name="countryCode">
                {({ form, field }: FieldProps<any>) => (
                    <CountrySelect
                        refNumber={ucc}
                        label={t('countryOfOriginCountryCode')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="typeCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        label={t('countryOfOriginRole')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={normalizeCodesToSelect(codelists?.ukPartyRoleStatusTypes || [])}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default CountryOfOriginBlock;
