import config from 'config';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload, ListResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { TemplateResponse } from './template';
import { AvailableTemplateFormTypes } from './action';

export const getTemplates = (
    country: 'ireland' | 'uk',
    internalType: 'import' | 'export' | 'ens',
    declarationType: AvailableTemplateFormTypes,
    params?: Partial<PaginatedParams>
): Promise<ListPayload<TemplateResponse>> => {
    let url = `${config.declarationTemplatesUrl}/${country === 'uk' ? 'cds' : country}/${internalType}`;
    if (declarationType === 'H7') url = url.concat('/h7');
    return axiosClient.get<ListResponse<TemplateResponse>>(url, { params }).then((response) => response.data.payload);
};

export const getTemplate = () => {};

export const createTemplate = (
    country: 'ireland' | 'uk',
    internalType: 'import' | 'export' | 'ens',
    declarationType: AvailableTemplateFormTypes,
    template: Partial<TemplateResponse>
) => {
    let url = `${config.declarationTemplatesUrl}/${country === 'uk' ? 'cds' : country}/${internalType}`;
    if (declarationType === 'H7') url = url.concat('/h7');
    const { declarationName, templateName, template: templateData } = template;
    return axiosClient.post(url, {
        declarationName,
        templateName,
        template: templateData,
    });
};

export const editTemplate = () => {};
