import { FC } from 'react';
import { StyledStatus, StyledStatusTag } from './StatusTag.styles';

const StatusTag: FC<{ color: string; status: string }> = ({ color, status }) => {
    return (
        <StyledStatus>
            <StyledStatusTag color={color} />
            <span>{status}</span>
        </StyledStatus>
    );
};

export default StatusTag;
