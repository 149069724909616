import { Checkbox, CheckboxProps as AntCheckboxProps } from 'antd';

export interface CheckboxProps extends AntCheckboxProps {
    autoFocus?: boolean;
    checked?: boolean;
    defaultChecked?: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
    children?: any;
    label?: string;
    style?: any;
}

export const Group = Checkbox.Group;

const ACheckbox = (props: CheckboxProps) => <Checkbox {...props}>{props.children}</Checkbox>;
export default ACheckbox;
