import { Declaration } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { TemplateOptions } from './TemplateContext';

export const getDeclarationForTemplate = (options: TemplateOptions): Declaration | null => {
    if (options.country === 'IRELAND' || options.country === 'REVENUE') {
        if (options.declarationType === 'IMPORT') {
            if (options.formType === 'H1') {
                return {
                    irelandImportDeclaration: {},
                    declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                    declarationInternalType: DeclarationInternalType.IMPORT,
                    status: 'DRAFT',
                };
            } else if (options.formType === 'H7') {
                return {
                    irelandH7ImportDeclaration: {},
                    declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                    declarationInternalType: DeclarationInternalType.IMPORT,
                    status: 'DRAFT',
                };
            }
        } else if (options.declarationType === 'ENS') {
            return {
                entrySummaryDeclaration: {},
                declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                declarationInternalType: DeclarationInternalType.ENS,
                status: 'DRAFT',
            };
        }
    }
    if (options.country === 'UK' || options.country === 'CDS') {
        if (options.declarationType === 'EXPORT') {
            return {
                cdsExportDeclaration: {},
                declarationExternalEntity: DeclarationExternalEntity.CDS,
                declarationInternalType: DeclarationInternalType.EXPORT,
                status: 'DRAFT',
            };
        }
    }
    return null;
};

const parseTemplateOptions = (options: TemplateOptions | undefined) => {
    if (!options) return {};

    const country = options.country?.toLowerCase();
    const declarationType = options.declarationType?.toLocaleLowerCase();

    const path = `${country === 'uk' ? 'cds' : country}/${declarationType}`;
    const declaration = getDeclarationForTemplate(options);

    return { path, declaration };
};

export default parseTemplateOptions;
