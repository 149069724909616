import Drawer from 'components/ui/base/drawer/Drawer';
import Notification from 'components/ui/base/notification/Notification';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import { FormikProps, FormikProvider, setNestedObjectValues } from 'formik';
import Fuse from 'fuse.js';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarationProducts from 'hooks/useDeclarationProducts';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useProducts from 'hooks/useProducts';
import useProductsTemplates from 'hooks/useProductsTemplates';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { GoodsShipmentItem as H7GoodsShipmentItem } from 'store/declarations/ireland/h7-import-declaration';
import { ProductTemplate } from 'store/products-templates/products';
import DeclarationFormTabContent from 'views/declarations/common/declaration-form/DeclarationFormTabContent';
import DeclarationsProductsTemplates from 'views/declarations/common/DeclarationsProductsTemplates';
import MasterProductDeclarationNav from 'views/declarations/common/MasterProductDeclarationNav';
import { FormSection } from 'views/declarations/DeclarationView';
import { StyledHeader } from 'views/declarations/Form.styles';
import { ProductsErrors } from 'views/declarations/sections/declaration-form/DeclarationForm';
import FormHelperDrawer from 'views/declarations/tooltips-help/FormHelpDrawer';
import { sectionsH7MainDocument, sectionsH7Products } from '../constants';
import { ProductContext } from 'views/declarations/common/declaration-view/DeclarationView';
import { Box44Context, box44PathAndFieldNames } from 'views/declarations/common/box44/Box44';
import IrelandImportH7DeclarationUtils from './utils';
import useDeclarations from 'hooks/useDeclarations';
import { ProductTabKeys } from '../../../../custom-declaration/products/ListProductTemplates';

interface Props {
    formik: FormikProps<any>;
    productsFormik: FormikProps<any>;
    declaration: Declaration;
    cancelProducts: boolean;
    clearCancel: Function;
    saveProduct: boolean;
    clearSaveProduct: Function;
    updateDeclaration: Function;
    hasAlert?: boolean;
    declarationErrors: ProductsErrors;
}

const IrelandH7TabContent: FC<Props> = ({
    formik,
    productsFormik,
    declaration,
    cancelProducts,
    clearCancel,
    saveProduct,
    clearSaveProduct,
    updateDeclaration,
    declarationErrors,
}) => {
    const { setDeclarationFooterType, expandDeclarationFooter } = useDeclarationFooter();
    const { declarationId }: { declarationId?: string } = useParams();
    const { declarationTemplate } = useDeclarations();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedBt, setSelectedBt] = useState<FormSection>(
        location.pathname === `/declarations/${declarationId}` ? FormSection.MASTER_DETAIlS : FormSection.PRODUCTS
    );
    const [showProducts, setShowProducts] = useState<boolean>(false);
    const [showHelp, setShowHelp] = useState<boolean>(false);
    const [helpSection, setHelpSection] = useState<number | string | undefined>();
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>();
    const { productId } = useParams<string>();
    const [searchQuery, setSearchQuery] = useState('');
    const [myProducts, setMyProducts] = useState<H7GoodsShipmentItem[]>([]);
    const controlProduct = useContext(ProductContext);
    const [paginatorProducts, setPaginatorProducts] = useState<Partial<PaginatedParams>>();
    const { productsTemplates, listH7ProductsTemplates, error, isLoading } = useProductsTemplates({
        country: DeclarationCountry.IRELAND,
    });
    const {
        deleteIrelandH7ImportProduct,
        error: productsError,
        products,
        listIrelandH7Products,
        getIrelandH7Product,
    } = useProducts();
    const { addProductsTemplatesToIrelandH7 } = useDeclarationProducts();
    const { declarationValidation, setFormAction } = useDeclarationValidation();

    const handleSort = (params: Partial<PaginatedParams>) => {
        setPaginator({ ...paginator, ...params });
    };
    useEffect(() => {
        listH7ProductsTemplates({
            ...(paginator ?? {}),
            declarationTemplateId: declarationTemplate?.id ?? 'default',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator, declarationTemplate?.id]);

    useEffect(() => {
        setSelectedBt(
            location.pathname === `/declarations/${declarationId}` ? FormSection.MASTER_DETAIlS : FormSection.PRODUCTS
        );
    }, [location, declarationId]);

    useEffect(() => {
        setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
        expandDeclarationFooter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePagination = (params: Partial<PaginatedParams>) => {
        if (paginator && paginator.page && paginator.size) {
            setPaginator({ page: params.page, size: paginator.size });
        } else {
            setPaginator({ page: params.page, size: defaultPagination.size });
        }
    };

    const handlePaginationProductsOfDeclaration = (params: Partial<PaginatedParams>) => {
        if (paginatorProducts && paginatorProducts.page && paginatorProducts.size) {
            setPaginatorProducts({ page: params.page, size: paginatorProducts.size });
        } else {
            setPaginatorProducts({ page: params.page, size: defaultPagination.size });
        }
    };

    useEffect(() => {
        if (paginatorProducts && declarationId) {
            const p = { ...paginatorProducts };
            listIrelandH7Products(declarationId, p);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginatorProducts]);

    const declarationHasItems = useMemo(() => products?.total, [products]);

    useEffect(() => {
        if (products?.total) {
            const myProducts = products?.list as H7GoodsShipmentItem[];
            setMyProducts(myProducts);
        }
    }, [products]);

    useEffect(() => {
        if (cancelProducts) {
            productsFormik.resetForm();
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
            clearCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelProducts]);

    useEffect(() => {
        if (saveProduct) {
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
            clearSaveProduct();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveProduct]);

    useEffect(() => {
        if (searchQuery && products?.list) {
            const array = [...products.list];
            const fuse = new Fuse(array, {
                keys: ['itemAmount', 'descriptionOfGoods', 'commodityCodeHarmonizedSystemSubHeadingCode'],
            });
            const res: unknown[] = fuse.search(searchQuery);
            setMyProducts(res as H7GoodsShipmentItem[]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    useEffect(() => {
        if (selectedBt === 0) {
            if (!declarationValidation.formAction) {
                setFormAction(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBt]);

    const addNewProduct = useCallback(() => {
        if (controlProduct.setProductId) {
            controlProduct.setProductId(undefined);
        }
        productsFormik.resetForm();
        setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
        navigate(`/declarations/${declarationId}/products/new`);
    }, [setDeclarationFooterType, productsFormik, controlProduct, declarationId, navigate]);

    const handleAddProducts = async (products: ProductTemplate[]) => {
        setShowProducts(false);
        const productTemplatesIds = products.map((obj) => obj.id!);
        const response = await addProductsTemplatesToIrelandH7(declaration.id!, productTemplatesIds);
        if (response && declarationId) {
            Notification({
                type: 'success',
                messageTitle: 'Product Added',
                description: 'Product Template has been successfully added to declaration!',
            });
            await listIrelandH7Products(declarationId);
            navigate(`/declarations/${declarationId}/products`);
        }
    };

    const verticalNavigation = useMemo(() => {
        return selectedBt === 0 ? sectionsH7MainDocument : sectionsH7Products;
    }, [selectedBt]);

    const handleDeclarationView = useMemo(() => {
        const handleToggleHelp = (refNumber: string | number) => {
            setHelpSection(refNumber);
            setShowHelp(true);
        };

        const handleDeleteProducts = async (id: string) => {
            setShowProducts(false);
            await deleteIrelandH7ImportProduct(id, declaration?.id!);
            if (!productsError) {
                updateDeclaration();
                Notification({
                    type: 'success',
                    messageTitle: 'Product Deleted',
                    description: 'Product has been successfully deleted!',
                });
            }
        };

        const handleEditProducts = async (id: string) => {
            if (declarationHasItems) {
                const product = await getIrelandH7Product(declaration.id!, id);
                if (product) {
                    if (controlProduct.setProductId) {
                        controlProduct.setProductId(product.id);
                    }
                    productsFormik.setValues(IrelandImportH7DeclarationUtils.transformProductForClient(product));
                    if (!!declarationValidation.pressedSubmit) {
                        await productsFormik.validateForm().then((v) => {
                            productsFormik.setTouched(setNestedObjectValues(v, true));
                            return v;
                        });
                    }
                    navigate(`/declarations/${declarationId}/products/${product.id}`);
                }
            }
        };

        return (
            <Outlet
                context={{
                    formik: formik,
                    productsFormik: productsFormik,
                    toggleHelp: handleToggleHelp,
                    setSearchQuery,
                    errors: declarationErrors,
                    data: myProducts,
                    declarationType: DeclarationName.H7,
                    onDelete: handleDeleteProducts,
                    onEdit: handleEditProducts,
                    handlePagination: handlePaginationProductsOfDeclaration,
                    pageNumber: products?.pageNumber ?? 0,
                    itemsNumber: declarationHasItems,
                }}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        myProducts,
        selectedBt,
        formik,
        productsFormik,
        declarationHasItems,
        productId,
        declaration,
        deleteIrelandH7ImportProduct,
        error,
        listH7ProductsTemplates,
        productsError,
        updateDeclaration,
        isLoading,
        declarationErrors,
    ]);

    const box44Path = useMemo(
        () =>
            !location.pathname.includes('products')
                ? box44PathAndFieldNames.H7.masterPath
                : box44PathAndFieldNames.H7.productPath,
        [location.pathname]
    );
    const formikForProvider = useMemo(
        () => (!location.pathname.includes('products') ? formik : productsFormik),
        [formik, location.pathname, productsFormik]
    );

    return (
        <>
            <FormHelperDrawer
                section={helpSection}
                isVisible={showHelp}
                onClose={() => setShowHelp(false)}
                declarationType={DeclarationName.H7}
                internalType={DeclarationInternalType.IMPORT}
                declarationCountry={DeclarationCountry.IRELAND}
            />
            <StyledHeader>
                {formikForProvider && (
                    /**
                     * Check if DeclarationContentComponent has returned correct content.
                     * This is needed when we switch between different tabs (Form, Documentation etc..)
                     */
                    <FormikProvider value={formikForProvider}>
                        <Box44Context.Provider
                            value={{
                                documentTypeName: box44PathAndFieldNames.H7.documentTypeName,
                                documentIdentifierName: box44PathAndFieldNames.H7.documentIdentifierName,
                                path: box44Path,
                            }}
                        >
                            <MasterProductDeclarationNav
                                selectedBt={selectedBt}
                                addNewProduct={addNewProduct}
                                setShowProducts={setShowProducts}
                                country={DeclarationCountry.IRELAND}
                                declarationType={DeclarationName.H7}
                            />
                        </Box44Context.Provider>
                    </FormikProvider>
                )}
            </StyledHeader>
            <DeclarationFormTabContent navigationItems={verticalNavigation}>
                {handleDeclarationView}
            </DeclarationFormTabContent>
            <Drawer
                title="View all H7 product templates"
                width="786"
                visible={showProducts}
                onClose={() => {
                    setShowProducts(false);
                }}
            >
                {declaration && showProducts && (
                    <DeclarationsProductsTemplates
                        internalEntity={DeclarationInternalType.IMPORT}
                        country={DeclarationCountry.IRELAND}
                        productTemplateType={ProductTabKeys.H7}
                        products={productsTemplates}
                        refresh={() => {
                            listH7ProductsTemplates();
                        }}
                        handlePagination={handlePagination}
                        handleSort={handleSort}
                        addProduct={true}
                        addProductsToDeclaration={handleAddProducts}
                    />
                )}
            </Drawer>
        </>
    );
};
export default IrelandH7TabContent;
