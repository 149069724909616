import IconTooltip from 'components/ui/base/tooltip/IconTooltip';
import { TextSmall } from 'components/ui/base/typography';
import { TestProps } from 'core/utils/testTypes';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useDeclarationInputFocused from 'hooks/useDeclarationInputFocused';
import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInfoCircleFilled } from 'views/declarations/Form.styles';
import { handleBlur, handleFocus } from '../utils';
import {
    ErrorDiv,
    ErrorLabel,
    FormItem,
    InputDiv,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
    RefDiv,
    StyledNumberInput,
    ViewOnlyLabel,
} from './DeclarationInput.styles';
import { getFormikProps, toPlaceholder } from 'views/declarations/utils/form-utils';
import MultipleButtons from '../MultipleButtons';
import { useLocation, useOutletContext } from 'react-router-dom';
import { TemplateCheckboxes } from 'components/ui/composed/template/TemplateCheckbox';
import { useTemplateContext } from '../../template/TemplateContext';
import { useRegisterField } from '../../template/CardContext';
import useFieldTemplateMetaData from '../../template/useTemplateViewEditControls';
import { InputNumberProps } from 'antd';

export interface FormNumberInputProps extends TestProps, InputNumberProps {
    label?: string;
    tooltip?: string;
    icon?: ReactNode;
    refNumber?: string;
    children?: ReactNode;
    fieldProps?: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    required?: boolean;
    maxLength?: number;
    viewOnly?: boolean;
    integerNumbers?: boolean;
    min?: string;
    max?: string;
    disabled?: boolean;
    stringMode?: boolean;
    refClicked?: (refNumber: string | number) => void;
    fieldEvents?: {
        onBlur?: (value: string | null) => void;
        onChange?: (value: number | null) => void;
    };
    value?: number;
    condensed?: boolean;
    multipleF?: {
        onAdd: () => void;
        onRemove: () => void;
        canAdd?: boolean;
        canRemove?: boolean;
    };
    specialName?: string; // Used for template meta handle of taxBox34Bis fields as of 13/04/23
}

const DeclarationNumberInput: FC<FormNumberInputProps> = ({
    label,
    refNumber,
    tooltip,
    fieldMeta: fieldMetaProp,
    fieldProps: fieldPropsProp,
    fieldHelper: fieldHelpersProp,
    required,
    viewOnly: viewOnlyProp,
    min = '0',
    disabled,
    refClicked,
    testId,
    fieldEvents,
    value,
    maxLength,
    condensed,
    multipleF,
    specialName,
    ...numberInputProps
}) => {
    const { t } = useTranslation('common');
    const { setFocused } = useDeclarationInputFocused();
    const outletContext = useOutletContext<{
        amendment?: boolean;
    }>();
    const location = useLocation();
    const inViewOnly = location.pathname.includes('view-only');
    const { template, templateFormik, form, isViewOnly: isTemplateViewOnly } = useTemplateContext();

    useRegisterField({ path: fieldPropsProp?.name, required });
    const { isViewable, isInvisible } = useFieldTemplateMetaData(specialName ?? fieldPropsProp?.name);

    const { fieldProps, fieldMeta, fieldHelpers } = useMemo(() => {
        if (template && templateFormik && form) {
            const f = getFormikProps(`${form}.defaults.${fieldPropsProp?.name}`, templateFormik);
            return { ...f, fieldHelpers: f.fieldHelper };
        }

        return { fieldProps: fieldPropsProp, fieldMeta: fieldMetaProp, fieldHelpers: fieldHelpersProp };
    }, [template, templateFormik, form, fieldPropsProp, fieldMetaProp, fieldHelpersProp]);

    const { onBlur, ...otherFieldEvents } = fieldEvents ?? {};

    const handleRefClick = useCallback(() => {
        if (refClicked) {
            refNumber ? refClicked(refNumber) : refClicked(label ?? '');
        }
    }, [refClicked, refNumber, label]);

    useEffect(() => {
        if (fieldProps?.value === '' && fieldHelpers) {
            fieldHelpers.setValue(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldProps?.value]);

    const showTooltip = useMemo(() => tooltip && tooltip.length > 0, [tooltip]);

    const viewOnly = useMemo(() => {
        if (template) return isTemplateViewOnly;
        if (outletContext?.amendment) return isViewable;
        return isViewable || inViewOnly;
    }, [template, isTemplateViewOnly, inViewOnly, outletContext?.amendment, isViewable]);

    if (isInvisible && !template) return null;

    return (
        <>
            {viewOnly ? (
                <>
                    <ViewOnlyLabel>{label && label}:</ViewOnlyLabel>
                    {template && <TemplateCheckboxes disabled fieldPath={fieldPropsProp?.name} />}
                    <TextSmall>{fieldProps?.value ?? '-'} </TextSmall>
                </>
            ) : (
                <>
                    <LabelDiv condensed={condensed}>
                        {label && (
                            <InputLabel>
                                {label}
                                {required && (
                                    <InputLabelRequired>{condensed ? '*' : t('mandatory')}</InputLabelRequired>
                                )}
                            </InputLabel>
                        )}

                        {refNumber && <RefDiv>{refNumber}</RefDiv>}
                        {showTooltip && (
                            <IconTooltip
                                title={label}
                                tooltip={tooltip ?? t('defaultTooltip')}
                                icon={<StyledInfoCircleFilled />}
                                tooltipClicked={handleRefClick}
                            />
                        )}
                    </LabelDiv>
                    {template && (
                        <TemplateCheckboxes
                            required={required}
                            fieldPath={fieldPropsProp?.name}
                            specialName={specialName}
                            onChange={numberInputProps.onChange ?? otherFieldEvents.onChange}
                        />
                    )}
                    <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                        <InputDiv>
                            <StyledNumberInput
                                maxLength={maxLength}
                                placeholder={toPlaceholder(label) ?? ''}
                                name={fieldProps?.name}
                                data-testid={testId}
                                min={min}
                                disabled={disabled || isInvisible}
                                onFocus={() => handleFocus(setFocused)}
                                status={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}
                                multiple={fieldProps?.multiple}
                                checked={fieldProps?.checked}
                                value={value ? value : fieldProps?.value}
                                onBlur={(e) => {
                                    handleBlur(setFocused, fieldProps, e);
                                    onBlur?.(e.target.value);
                                }}
                                onChange={(value: number | null) => {
                                    fieldProps?.onChange({ target: { value, name: fieldProps.name } });
                                }}
                                {...fieldMeta}
                                {...otherFieldEvents}
                                size={condensed ? 'middle' : 'large'}
                                condensed={condensed}
                                {...numberInputProps}
                            />
                            {multipleF && <MultipleButtons {...multipleF} />}
                        </InputDiv>
                        <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched} condensed={condensed}>
                            {!!fieldMeta?.error && !!fieldMeta?.touched && <ErrorLabel>{fieldMeta?.error}</ErrorLabel>}
                        </ErrorDiv>
                    </FormItem>
                </>
            )}
        </>
    );
};

export default DeclarationNumberInput;
