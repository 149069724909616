import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { fiscalReferenceRoleLabels } from '../../../../../store/declarations/enums/common/fiscal-reference-role';
import { additionalFiscal } from 'store/declarations/enums/uk/additional-fiscal';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'id' | 'roleCode';

export const additionalFiscalReferencesBlockValidation: {
    childValidators: {
        'goodsShipment.domesticDutyTaxParty': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.domesticDutyTaxParty': {
            childValidators: {
                id: [validators.required(), validators.max(17)],
                roleCode: [validators.required(), validators.exact(3)],
            },
            stopAtNull: true,
        },
    },
};

export const additionalFiscalReferencesFields =
    additionalFiscalReferencesBlockValidation.childValidators['goodsShipment.domesticDutyTaxParty'];

export const additionalFiscalReferencesBlockProductValidation: {
    childValidators: {
        domesticDutyTaxParty: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        domesticDutyTaxParty: {
            childValidators: {
                id: [validators.required(), validators.max(17)],
                roleCode: [validators.required(), validators.exact(3)],
            },
            stopAtNull: true,
        },
    },
};

export const additionalFiscalReferencesProductFields =
    additionalFiscalReferencesBlockProductValidation.childValidators.domesticDutyTaxParty;

interface Props extends BlockProps<Fields> {}

const AdditionalFiscalReferencesBlock = ({
    path = 'goodsShipment.domesticDutyTaxParty',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '3/40', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        required
                        label={t('vatIdentificationNumber')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={additionalFiscal}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="roleCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        required
                        label={t('roleCode')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={fiscalReferenceRoleLabels}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default AdditionalFiscalReferencesBlock;
