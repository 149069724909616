import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import FormSelect from '../../../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import NewFormCard, { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import MultipleDeclarationField from '../../../../../common/MultipleDeclarationField';

const AdditionalProcedureCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('additionalProcedure')}>
            <FormCardContainer>
                <MultipleDeclarationField name="additionalProcedure">
                    {(fieldProps, controls, index) => (
                        <FormSelect
                            required={index === 0}
                            refNumber="1.11"
                            viewOnly={props.viewOnly}
                            {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                            label={t('additionalProcedure')}
                            selectOptions={normalizeCodesToSelect(codelists?.ukSpecialProcedureTypes || [])}
                            tooltip={getCdsTooltipsByRefNumberAndField('1.11', t('additionalProcedure'))}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            condensed
                            multipleF={controls}
                        />
                    )}
                </MultipleDeclarationField>
            </FormCardContainer>
        </NewFormCard>
    );
};
export default AdditionalProcedureCard;
