import { FormikErrors } from 'formik';
import { CardError, FieldError, ItemsFieldError } from 'store/declaration-form-errors/DeclarationFormErrors';
import {
    IrelandExportDeclaration,
    RevenueExportAdditionalInformation,
    RevenueExportBorderTransportMeans,
    RevenueExportCommodity,
    RevenueExportConsigment,
    RevenueExportCustomer,
    RevenueExportCustomsGoodsItem,
    revenueExportGoodsShipment,
    RevenueExportPackaging
} from 'store/declarations/ireland/export-declaration';

export const parseValidationErrors = (validation: FormikErrors<IrelandExportDeclaration>) => {
    const masterDetailsErros: CardError[] = [];
    parseMasterdetails(masterDetailsErros, validation);
    parseGoodsShipment(masterDetailsErros, validation);
    parseConsignor(masterDetailsErros, validation);
    parseConsignee(masterDetailsErros, validation);
    parseDeliveryTerms(masterDetailsErros, validation);
    parseConsignment(masterDetailsErros, validation);
    parseWarehouse(masterDetailsErros, validation);
    parseAdditionalInformation(masterDetailsErros, validation);
    parseAdditionalDocument(masterDetailsErros, validation);
    const itemsErrors: ItemsFieldError[] = [];
    parseDeclarationItems(itemsErrors, validation);
    const errors = {
        masterDetails: masterDetailsErros,
        items: itemsErrors,
    };
    return errors;
};

const parseMasterdetails = (errors: CardError[], validation: FormikErrors<IrelandExportDeclaration>) => {
    const fields: FieldError[] = [];

    if (validation?.messageType) {
        fields.push({ name: 'Message Type', message: validation?.messageType });
    }

    if (validation?.exportationCode) {
        fields.push({ name: 'Exportation Code', message: validation?.exportationCode });
    }


    if (validation?.declarationType) {
        fields.push({ name: 'Declaration Type', message: validation?.declarationType });
    }

    if (validation?.representativeStatus) {
        fields.push({ name: 'Representative Status', message: validation?.representativeStatus });
    }

    if (validation?.representativeEori) {
        fields.push({ name: 'Representative Eori', message: validation?.representativeEori });
    }

    if (validation?.sealsQuantity) {
        fields.push({ name: 'Seals Quantity', message: validation?.sealsQuantity });
    }

    if (validation?.sealsIdentity) {
        fields.push({ name: 'Seals Identity', message: validation?.sealsIdentity });
    }

    if (validation?.specificCircumstanceIndicator) {
        fields.push({ name: 'Specific Circumstance Indicator', message: validation?.specificCircumstanceIndicator });
    }

    if (validation?.referenceNumber) {
        fields.push({ name: 'Reference Number', message: validation?.referenceNumber });
    }

    if (validation?.transportChargesMethodOfPayment) {
        fields.push({
            name: 'Transport Charges Method Of Payment',
            message: validation?.transportChargesMethodOfPayment,
        });
    }

    if (validation?.numberOfItems) {
        fields.push({ name: 'Number of items', message: validation?.numberOfItems });
    }

    if(validation?.officeOfExport){
        fields.push({ name: 'Office of Export', message: validation?.officeOfExport });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Master details', fields });
    }
};

const parseGoodsShipment = (errors: CardError[], validation: FormikErrors<IrelandExportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);


    if (goodsShipment?.exportationCountry) {
        fields.push({ name: 'Country of dispatch/export code', message: goodsShipment?.exportationCountry });
    }

    if (goodsShipment?.destinationCountry) {
        fields.push({ name: 'Country of Destination', message: goodsShipment?.destinationCountry });
    }

    if (goodsShipment?.deferredPayment) {
        fields.push({ name: 'Deferred Payment', message: goodsShipment?.deferredPayment });
    }

    if (goodsShipment?.currencyCode) {
        fields.push({ name: 'Currency', message: goodsShipment?.currencyCode });
    }

    if (goodsShipment?.totalAmountInvoiced) {
        fields.push({ name: 'Total Amount Invoiced', message: goodsShipment?.totalAmountInvoiced });
    }

    if (goodsShipment?.firstNatureOfTransactionCode) {
        fields.push({ name: 'First Nature Of Transaction Code', message: goodsShipment?.firstNatureOfTransactionCode });
    }

    if (goodsShipment?.secondNatureOfTransactionCode) {
        fields.push({
            name: 'Second Nature Of Transaction Code',
            message: goodsShipment?.secondNatureOfTransactionCode,
        });
    }

    if (goodsShipment?.exitOffice) {
        fields.push({ name: 'Exit office', message: goodsShipment?.exitOffice });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Goods shipment', fields });
    }
};

const parseConsignor = (errors: CardError[], validation: FormikErrors<IrelandExportDeclaration>) => {
    const consignor = validation?.consignor as FormikErrors<RevenueExportCustomer>;
    const fields: FieldError[] = parseExporterCustomerErrors(consignor);
    if (fields.length > 0) {
        errors.push({ name: 'Consignor', fields });
    }
};

const parseConsignee = (errors: CardError[], validation: FormikErrors<IrelandExportDeclaration>) => {
    const consignee = validation?.consignee as FormikErrors<RevenueExportCustomer>;
    const fields: FieldError[] = parseExporterCustomerErrors(consignee);
    if (fields.length > 0) {
        errors.push({ name: 'Consignee', fields });
    }
};

const parseExporterCustomerErrors = (customer: FormikErrors<RevenueExportCustomer>) => {
    const fields: FieldError[] = [];

    if (customer?.revenueNumber) {
        fields.push({ name: 'Revenue Number', message: customer?.revenueNumber });
    }

    if (customer?.name) {
        fields.push({ name: 'Name', message: customer?.name });
    }

    if (customer?.addressLine1) {
        fields.push({ name: 'Address Line 1', message: customer?.addressLine1 });
    }

    if (customer?.addressLine2) {
        fields.push({ name: 'Address Line 2', message: customer?.addressLine2 });
    }

    if (customer?.postCode) {
        fields.push({ name: 'Post Code', message: customer?.postCode });
    }

    if (customer?.countryCode) {
        fields.push({ name: 'Country', message: customer?.countryCode });
    }

    return fields;
};

const parseDeliveryTerms = (errors: CardError[], validation: FormikErrors<IrelandExportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);

    if (!goodsShipment) {
        return;
    }
    const fields: FieldError[] = [];

    if (goodsShipment?.incotermCode) {
        fields.push({ name: 'Delivery code', message: goodsShipment?.incotermCode });
    }

    if (goodsShipment?.deliveryDestination) {
        fields.push({ name: 'Delivery destination', message: goodsShipment?.deliveryDestination });
    }

    if (goodsShipment?.deliveryDestinationLocationCode) {
        fields.push({ name: 'Delivery location', message: goodsShipment?.deliveryDestinationLocationCode });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Delivery Terms', fields });
    }
};

const parseConsignment = (errors: CardError[], validation: FormikErrors<IrelandExportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);

    if (!goodsShipment) {
        return;
    }

    const consigment = goodsShipment.consigment as FormikErrors<RevenueExportConsigment>;
    const fields: FieldError[] = [];

    if (consigment?.departureTransportMeansIdentity) {
        fields.push({
            name: 'Identity and nationality of means of transport at departure',
            message: consigment?.departureTransportMeansIdentity,
        });
    }

    const borderTransportMeans = consigment?.borderTransportMeans as FormikErrors<RevenueExportBorderTransportMeans>;

    if (borderTransportMeans?.identity) {
        fields.push({ name: 'Transport Identity Means Id', message: borderTransportMeans?.identity });
    }

    if (borderTransportMeans?.nationality) {
        fields.push({ name: 'Transport Nationality Means at the Border', message: borderTransportMeans?.nationality });
    }

    if(borderTransportMeans?.modeOfTransport){
        fields.push({ name: 'Transport mode', message: borderTransportMeans?.modeOfTransport });
    }

    if (consigment?.inlandModeOfTransport) {
        fields.push({ name: 'Inland mode of transport', message: consigment?.inlandModeOfTransport });
    }

    if (consigment?.locationOfGoods) {
        fields.push({ name: 'Location of goods', message: consigment?.locationOfGoods });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Consigment', fields });
    }
};

const parseWarehouse = (errors: CardError[], validation: FormikErrors<IrelandExportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);

    if (!goodsShipment) {
        return;
    }

    const fields: FieldError[] = [];

    if (goodsShipment?.warehouseType) {
        fields.push({ name: 'Warehouse Type', message: goodsShipment?.warehouseType });
    }

    if (goodsShipment?.warehouseIdentifier) {
        fields.push({ name: 'Warehouse identification number', message: goodsShipment?.warehouseIdentifier });
    }

    if (goodsShipment?.warehouseCountry) {
        fields.push({ name: 'Warehouse identifier country', message: goodsShipment?.warehouseCountry });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Wharehouse', fields });
    }
};

const parseAdditionalInformation = (errors: CardError[], validation: FormikErrors<IrelandExportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);

    if (!goodsShipment) {
        return;
    }
    const additionalDocument = (goodsShipment.additionalInformation as unknown as any[]) || [];
    additionalDocument?.forEach((element: FormikErrors<RevenueExportAdditionalInformation>, index: number) => {
        const fields: FieldError[] = [];

        if (element?.code) {
            fields.push({
                name: `Code Identifier`,
                message: element?.code,
            });
        }

        if (element?.identifier) {
            fields.push({
                name: `Additional information description`,
                message: element?.identifier,
            });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Information ${index}`, fields });
        }
    });
};

const parseAdditionalDocument = (errors: CardError[], validation: FormikErrors<IrelandExportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);

    if (!goodsShipment) {
        return;
    }

    const additionalDocument = (goodsShipment.additionalDocument as unknown as any[]) || [];
    additionalDocument?.forEach((element: FormikErrors<RevenueExportAdditionalInformation>, index: number) => {
        const fields: FieldError[] = [];

        if (element?.code) {
            fields.push({
                name: `Code Identifier`,
                message: element?.code,
            });
        }

        if (element?.identifier) {
            fields.push({
                name: `Additional information description`,
                message: element?.identifier,
            });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Document ${index}`, fields });
        }
    });
};

const getGoodShipment = (validation: FormikErrors<IrelandExportDeclaration>) => {
    return validation.irelandExportGoodsShipment as FormikErrors<revenueExportGoodsShipment>;
};

const parseDeclarationItems = (errors: ItemsFieldError[], validation: FormikErrors<IrelandExportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    if (!goodsShipment) {
        return;
    }

    const items = (goodsShipment?.customsGoodsItem as unknown as any[]) || [];

    items.forEach((itemErrors: FormikErrors<RevenueExportCustomsGoodsItem>, index) => {
        const item = {
            index: index + 1,
            errors: [],
        };

        parseItemsGoodsInformation(item.errors, itemErrors);
        parsePreviousDocument(item.errors, itemErrors);
        parseComodity(item.errors, itemErrors);
        parsePackages(item.errors, itemErrors);
        parseAdditionalItemInformation(item.errors, itemErrors);
        parseAdditionalItemDocument(item.errors, itemErrors);
        if (item.errors.length > 0) {
            errors.push(item);
        }
    });
};

function parseItemsGoodsInformation(errors: CardError[], validation: FormikErrors<RevenueExportCustomsGoodsItem>) {
    const packing = validation?.packaging as FormikErrors<RevenueExportPackaging>;
    const fields: FieldError[] = [];

    if (packing?.description) {
        fields.push({ name: `Description of Goods`, message: packing.description });
    }

    if (validation?.countryOrigin) {
        fields.push({ name: `Country origin`, message: validation.countryOrigin });
    }

    if (validation?.regionOrigin) {
        fields.push({ name: `Region origin`, message: validation.regionOrigin });
    }

    if (validation?.grossMass) {
        fields.push({ name: `Gross Mass`, message: validation.grossMass });
    }

    if (validation?.netMass) {
        fields.push({ name: `Net Mass`, message: validation.netMass });
    }

    if (validation?.procedureRequestedCode) {
        fields.push({ name: `Procedure Code`, message: validation.procedureRequestedCode });
    }

    if (validation?.procedurePreviousCode) {
        fields.push({ name: `Previous Procedure Code`, message: validation.procedurePreviousCode });
    }

    if (validation?.procedureCategory) {
        fields.push({ name: `Procedure Category`, message: validation.procedureCategory });
    }

    if (validation?.supplementaryUnits) {
        fields.push({ name: `Supplementary Units`, message: validation.supplementaryUnits });
    }

    if (validation?.statisticalValue) {
        fields.push({ name: `Statistical Value`, message: validation.statisticalValue });
    }

    if (validation?.vatRate) {
        fields.push({ name: `Vat Rate`, message: validation.vatRate });
    }

    if (validation?.dutyRate) {
        fields.push({ name: `Duty Value`, message: validation.dutyRate });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Goods Information', fields });
    }
}

function parsePreviousDocument(errors: CardError[], validation: FormikErrors<RevenueExportCustomsGoodsItem>) {
    const fields: FieldError[] = [];

    if (validation?.previousDocumentType) {
        fields.push({ name: `Previous Document Type`, message: validation.previousDocumentType });
    }

    if (validation?.previousDocumentReference) {
        fields.push({ name: `Previous Document Reference`, message: validation.previousDocumentReference });
    }

    if (validation?.previousDocumentIdentifier) {
        fields.push({ name: `Previous Document Identification`, message: validation.previousDocumentIdentifier });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Previous Document', fields });
    }
}

function parseComodity(errors: CardError[], validation: FormikErrors<RevenueExportCustomsGoodsItem>) {
    const fields: FieldError[] = [];

    const commodity = validation?.commodity as FormikErrors<RevenueExportCommodity>;

    if (commodity?.nomenclatureCode) {
        fields.push({ name: `Combined nomenclature code`, message: commodity.nomenclatureCode });
    }

    if (commodity?.taricAdditionalCode) {
        fields.push({ name: `TARIC additional code`, message: commodity.taricAdditionalCode });
    }

    if (commodity?.taricAdditionalCode2) {
        fields.push({ name: `TARIC additional code 2`, message: commodity.taricAdditionalCode2 });
    }

    if (commodity?.nationalCode) {
        fields.push({ name: `National code`, message: commodity.nationalCode });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Commodity', fields });
    }
}

function parsePackages(errors: CardError[], validation: FormikErrors<RevenueExportCustomsGoodsItem>) {
    const fields: FieldError[] = [];

    const packaging = validation?.packaging as FormikErrors<RevenueExportPackaging>;

    if (packaging?.containerNumbers) {
        fields.push({ name: `Container Numbers`, message: packaging.containerNumbers });
    }

    if (packaging?.typeOfPackage) {
        fields.push({ name: `Package Type Code`, message: packaging.typeOfPackage });
    }

    if (packaging?.uniqueLineOfPackageReference) {
        fields.push({ name: `Unique line of package reference`, message: packaging.uniqueLineOfPackageReference });
    }

    if (packaging?.quantityOfPackages) {
        fields.push({ name: `Number of Packages`, message: packaging.quantityOfPackages });
    }

    if (packaging?.numberOfPieces) {
        fields.push({ name: `Number of pieces`, message: packaging.numberOfPieces });
    }

    if (packaging?.marksOfPackages) {
        fields.push({ name: `Marks of Packages`, message: packaging.marksOfPackages });
    }

    if (packaging?.packagesInItem) {
        fields.push({ name: `Packages in Item`, message: packaging.packagesInItem });
    }

    if (packaging?.itemNumber) {
        fields.push({ name: `Item number`, message: packaging.itemNumber });
    
    }

    if (fields.length > 0) {
        errors.push({ name: 'Packages', fields });
    }
}

function parseAdditionalItemInformation(errors: CardError[], itemErrors: FormikErrors<RevenueExportCustomsGoodsItem>) {
    const additionalInformation = (itemErrors.additionalInformation as unknown as any[]) || [];
    additionalInformation?.forEach((element: FormikErrors<RevenueExportAdditionalInformation>, index: number) => {
        const fields: FieldError[] = [];

        if (element?.code) {
            fields.push({
                name: `Code Identifier`,
                message: element?.code,
            });
        }

        if (element?.identifier) {
            fields.push({
                name: `Additional information description`,
                message: element?.identifier,
            });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Information ${index}`, fields });
        }
    });
}
function parseAdditionalItemDocument(errors: CardError[], itemErrors: FormikErrors<RevenueExportCustomsGoodsItem>) {
    const additionalDocument = (itemErrors.additionalDocument as unknown as any[]) || [];
    additionalDocument?.forEach((element: FormikErrors<RevenueExportAdditionalInformation>, index: number) => {
        const fields: FieldError[] = [];

        if (element?.code) {
            fields.push({
                name: `Code Identifier`,
                message: element?.code,
            });
        }

        if (element?.identifier) {
            fields.push({
                name: `Additional information description`,
                message: element?.identifier,
            });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Document ${index}`, fields });
        }
    });
}
