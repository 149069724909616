import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveryIncotermLabels } from 'store/declarations/enums/uk/delivery-incoterm';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const DeliveryTermsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('deliveryTerms')}>
            <FormCardContainer>
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps('deliveryIncoterm', props)}
                    refNumber="4.1"
                    label={t('incotermCode')}
                    selectOptions={DeliveryIncotermLabels}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.1', t('incotermCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    numbersAndLetters
                    maxLength={17}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('deliveryUnlocode', props)}
                    refNumber="4.1"
                    label={t('unLocodeCode')}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.1', t('unLocodeCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps('deliveryCountryCode', props)}
                    refNumber="4.1"
                    label={t('goodsShipment.countryCode')}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.1', t('goodsShipment.countryCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    numbersAndLetters
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('deliveryLocationName', props)}
                    refNumber="4.1"
                    label={t('goodsShipment.locationName')}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.1', t('goodsShipment.locationName'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default DeliveryTermsCard;
