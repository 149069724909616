import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationViewProps } from '../common/declaration-view/DeclarationView';

import IrelandH1Content from '../ireland/import/h1/IrelandH1Content';
import ieH1ValidationSchema, {
    goodsShipmentItemSchemaRequired as ieH1ProductValidationSchema,
} from '../ireland/import/h1/validation/ValidationSchema';
import { parseValidationErrors as h1ParseValidationErrors } from '../ireland/import/h1/validation/utils';

import IrelandH7Content from '../ireland/import/h7/IrelandH7Content';
import ieH7ValidationSchema from '../ireland/import/h7/validation/ValidationSchema';
import ieH7ProductValidationSchema from '../ireland/import/h7/validation/H7ProductValidationSchema';
import { parseValidationErrors as h7ParseValidationErrors } from '../ireland/import/h7/validation/utils';

import IrelandExportContent from '../ireland/export/IrelandExportContent';
import ieExportValidationSchema, {
    customsGoodsItem as ieExportProductValidationSchema,
} from '../ireland/export/validation/ExportValidationSchema';
import { parseValidationErrors as ieExportParseValidationErrors } from '../ireland/export/validation/utils';

import IrelandEnsContent from '../ireland/ens/IrelandEnsContent';
import ieEnsValidationSchema, {
    goodsItems as ieEnsProductValidationSchema,
} from '../ireland/ens/validation/irelandEnsValidation';
import ieEnsParseValidationErrors from '../ireland/ens/validation/parseValidationErrors';

import IrelandEtdContent from '../ireland/etd/IrelandEtdContent';
import ieEtdValidationSchema, {
    etdItemValidation as ieEtdProductValidationSchema,
} from '../ireland/etd/validations/irelandEtdSubmitValidation';

import IrelandTsdContent from '../ireland/tsd/IrelandTsdContent';
import ieTsdValidationSchema from '../ireland/tsd/validations/irelandTsdSubmitValidation';

import IeArrivalAtExitContent from '../ireland/arrival/IeArrivalAtExitContent';
import { ieArrivalAtExitSubmitValidation as ieArrivalValidationSchema } from '../ireland/arrival/validation/validation-submit';

import UkExportContent from '../uk/export/UkExportContent';

import UkImportContent from '../uk/import/UkImportContent';
import ukImportValidationSchema from '../uk/import/h1/validation/ValidationSchema';
import ukImportProductValidationSchema from '../uk/import/h1/validation/UKProductValidationSchema';
import { parseValidationErrors as ukImportParseValidationErrors } from '../uk/import/h1/validation/utils';
import CdsExportDeclarationUtils from '../uk/export/utils';
import ukExportParseValidationErrors from '../uk/export/parseValidationErrors';
import ukImportH2ParseValidationErrors from '../uk/import/h2/parseValidationErrors';
import { cdsExportFormValidation } from '../uk/export/UkExportForm';
import { cdsExportProductSectionValidation } from '../uk/export/UkExportProductsSection';
import { cdsImportProductSectionValidation } from '../uk/import/h2/UkH2ProductsSection';
import { cdsImportFormValidation } from '../uk/import/h2/UkImportH2Form';
import {
    ukImportDeclarationTransformedForClient,
    ukImportDeclarationTransformedForServer,
    ukImportProductTransformedForClient,
    ukImportProductTransformedForServer,
} from '../uk/import/h1/utils';
import IrelandImportH1DeclarationUtils from '../ireland/import/h1/utils';
import IrelandImportH7DeclarationUtils from '../ireland/import/h7/utils';
import { DeclarationType } from '../common/declarationType';

type DeclarationViewMap = { [key in DeclarationType]: DeclarationViewProps<any> };

const declarationViewMap: DeclarationViewMap = {
    [DeclarationType.IE_IMPORT_H1]: {
        DeclarationContentComponent: IrelandH1Content,
        declarationName: 'Import',
        declarationType: DeclarationType.IE_IMPORT_H1,
        declarationInternalType: DeclarationInternalType.IMPORT,
        declarationValidationSchema: ieH1ValidationSchema,
        productValidationSchema: ieH1ProductValidationSchema,
        validationErrorsParser: h1ParseValidationErrors,
        createProductFuncName: 'createIrelandImportProduct',
        listProductsFuncName: 'listIrelandH1Products',
        updateDeclarationFuncName: 'updateIrelandImportDeclaration',
        updateProductFuncName: 'updateIrelandImportProduct',
        productFieldsToAutosaveOn: ['itemAmount', 'taxBox43bis'],
        numberOfItemsFieldName: 'numberOfItems',
        transformData: {
            declaration: {
                forClient: (data: any, straight?: boolean) => {
                    if (straight) return IrelandImportH1DeclarationUtils.transformForClient(data);

                    const currentDeclaration = data?.irelandImportDeclaration;
                    if (!currentDeclaration) return;
                    return {
                        ...data,
                        irelandImportDeclaration:
                            IrelandImportH1DeclarationUtils.transformForClient(currentDeclaration),
                    };
                },
                forServer: (data: any) => {
                    return IrelandImportH1DeclarationUtils.transformForServer(data);
                },
            },
            product: {
                forClient: (data: any) => {
                    return IrelandImportH1DeclarationUtils.transformProductForClient(data);
                },
                forServer: (data: any) => {
                    return IrelandImportH1DeclarationUtils.transformProductForServer(data);
                },
            },
        },
    },
    [DeclarationType.IE_IMPORT_H7]: {
        DeclarationContentComponent: IrelandH7Content,
        declarationName: 'H7',
        declarationType: DeclarationType.IE_IMPORT_H7,
        declarationInternalType: DeclarationInternalType.IMPORT,
        declarationValidationSchema: ieH7ValidationSchema,
        productValidationSchema: ieH7ProductValidationSchema,
        validationErrorsParser: h7ParseValidationErrors,
        createProductFuncName: 'createIrelandH7ImportProduct',
        listProductsFuncName: 'listIrelandH7Products',
        updateDeclarationFuncName: 'updateIrelandH7ImportDeclaration',
        updateProductFuncName: 'updateIrelandH7ImportProduct',
        numberOfItemsFieldName: 'numberOfItems',
        transformData: {
            declaration: {
                forClient: (data: any, straight?: boolean) => {
                    if (straight) return IrelandImportH7DeclarationUtils.transformForClient(data);

                    const currentDeclaration = data?.irelandH7ImportDeclaration;
                    if (!currentDeclaration) return;
                    return {
                        ...data,
                        irelandH7ImportDeclaration:
                            IrelandImportH7DeclarationUtils.transformForClient(currentDeclaration),
                    };
                },
                forServer: (data: any) => {
                    return IrelandImportH7DeclarationUtils.transformForServer(data);
                },
            },
            product: {
                forClient: (data: any) => {
                    return IrelandImportH7DeclarationUtils.transformProductForClient(data);
                },
                forServer: (data: any) => {
                    return IrelandImportH7DeclarationUtils.transformProductForServer(data);
                },
            },
        },
    },
    [DeclarationType.IE_EXPORT]: {
        DeclarationContentComponent: IrelandExportContent,
        declarationName: 'Ireland Export',
        declarationType: DeclarationType.IE_EXPORT,
        declarationInternalType: DeclarationInternalType.EXPORT,
        declarationValidationSchema: ieExportValidationSchema,
        productValidationSchema: ieExportProductValidationSchema,
        validationErrorsParser: ieExportParseValidationErrors,
        createProductFuncName: 'createIrelandExportProduct',
        listProductsFuncName: 'listIrelandExportProducts',
        updateDeclarationFuncName: 'updateIrelandExportDeclaration',
        updateProductFuncName: 'updateIrelandExportProduct',
    },
    [DeclarationType.IE_ENS]: {
        DeclarationContentComponent: IrelandEnsContent,
        declarationName: 'ENS',
        declarationType: DeclarationType.IE_ENS,
        declarationInternalType: DeclarationInternalType.ENS,
        declarationValidationSchema: ieEnsValidationSchema,
        productValidationSchema: ieEnsProductValidationSchema,
        validationErrorsParser: ieEnsParseValidationErrors,
        createProductFuncName: 'createIrelandEnsProduct',
        numberOfItemsFieldName: 'numberOfItems',
        listProductsFuncName: 'listIrelandEnsProducts',
        updateDeclarationFuncName: 'updateEntrySummaryDeclaration',
        updateProductFuncName: 'updateIrelandEnsProduct',
    },
    [DeclarationType.IE_ETD]: {
        DeclarationContentComponent: IrelandEtdContent,
        declarationName: 'ETD',
        declarationType: DeclarationType.IE_ETD,
        declarationInternalType: DeclarationInternalType.ETD,
        declarationValidationSchema: ieEtdValidationSchema,
        productValidationSchema: ieEtdProductValidationSchema,
        validationErrorsParser: undefined,
        createProductFuncName: 'createIrelandEtdProduct',
        listProductsFuncName: 'listIrelandEtdProducts',
        updateDeclarationFuncName: 'updateElectronicTransportDocument',
        updateProductFuncName: 'updateIrelandEtdProduct',
    },
    [DeclarationType.IE_TSD]: {
        DeclarationContentComponent: IrelandTsdContent,
        declarationName: 'TSD',
        declarationType: DeclarationType.IE_TSD,
        declarationInternalType: DeclarationInternalType.TSD,
        declarationValidationSchema: ieTsdValidationSchema,
        productValidationSchema: undefined,
        validationErrorsParser: undefined,
        createProductFuncName: 'createIrelandTsdProduct',
        listProductsFuncName: 'listIrelandTsdProducts',
        updateDeclarationFuncName: 'updateTemporaryStorageDeclaration',
        updateProductFuncName: 'updateIrelandTsdProduct',
    },
    [DeclarationType.IE_ARRIVAL]: {
        DeclarationContentComponent: IeArrivalAtExitContent,
        declarationName: 'Arrival At Exit',
        declarationType: DeclarationType.IE_ARRIVAL,
        declarationInternalType: DeclarationInternalType.ARRIVAL,
        declarationValidationSchema: ieArrivalValidationSchema,
        productValidationSchema: undefined,
        validationErrorsParser: undefined,
        createProductFuncName: undefined,
        listProductsFuncName: undefined,
        updateDeclarationFuncName: 'updateArrivalAtExitDeclaration',
        updateProductFuncName: undefined,
    },
    [DeclarationType.UK_EXPORT]: {
        DeclarationContentComponent: UkExportContent,
        declarationName: 'Export',
        declarationType: DeclarationType.UK_EXPORT,
        declarationInternalType: DeclarationInternalType.EXPORT,
        validationErrorsParser: ukExportParseValidationErrors,
        declarationFormValidations: cdsExportFormValidation,
        productFormValidations: cdsExportProductSectionValidation,
        createProductFuncName: 'createUkExportProduct',
        listProductsFuncName: 'listUkExportProducts',
        updateDeclarationFuncName: 'updateUkExportDeclaration',
        updateProductFuncName: 'updateUkExportProduct',
        payloadPath: 'cdsExportDeclarationPayload',
        transformProductCreateResponse: (res: any) => res?.governmentAgencyGoodsItem,
        transformData: {
            declaration: {
                forClient: (data: any, straight?: boolean) => {
                    if (straight) return CdsExportDeclarationUtils.transformForClient(data);

                    const currentDeclaration = data?.cdsExportDeclaration;
                    if (!currentDeclaration) return;
                    return {
                        ...data,
                        cdsExportDeclaration: CdsExportDeclarationUtils.transformForClient(currentDeclaration),
                    };
                },
                forServer: (data: any) => {
                    return CdsExportDeclarationUtils.transformForServer(data);
                },
            },
            product: {
                forClient: (data: any, straight?: boolean) => {
                    if (straight) return CdsExportDeclarationUtils.transformProductForClient(data);
                    return CdsExportDeclarationUtils.transformProductForClient(data?.governmentAgencyGoodsItem);
                },
                forServer: (data: any) => {
                    return { governmentAgencyGoodsItem: CdsExportDeclarationUtils.transformProductForServer(data) };
                },
            },
        },
        numberOfItemsFieldName: 'goodsItemQuantity',
    },
    [DeclarationType.UK_IMPORT]: {
        DeclarationContentComponent: UkImportContent,
        declarationName: 'Import',
        declarationType: DeclarationType.UK_IMPORT,
        declarationInternalType: DeclarationInternalType.IMPORT,
        declarationValidationSchema: ukImportValidationSchema,
        productValidationSchema: ukImportProductValidationSchema,
        validationErrorsParser: ukImportParseValidationErrors,
        createProductFuncName: 'createUkImportProduct',
        listProductsFuncName: 'listUkImportProducts',
        updateDeclarationFuncName: 'updateUkImportDeclaration',
        updateProductFuncName: 'updateUkImportProduct',
        numberOfItemsFieldName: 'numberOfItems',
        transformData: {
            declaration: {
                forClient: (data: any, straight?: boolean) => {
                    if (straight) return ukImportDeclarationTransformedForClient(data);

                    if (!data) return undefined;

                    return {
                        ...data,
                        cdsImportDeclaration: ukImportDeclarationTransformedForClient(data.cdsImportDeclaration),
                    };
                },
                forServer: (data: any) => {
                    if (!data) return undefined;

                    return {
                        ...data,
                        ...ukImportDeclarationTransformedForServer(data),
                    };
                },
            },
            product: {
                forClient: (data: any) => {
                    return ukImportProductTransformedForClient(data);
                },
                forServer: (data: any) => {
                    return ukImportProductTransformedForServer(data);
                },
            },
        },
    },
    [DeclarationType.UK_IMPORT_USING_BLOCKS]: {
        DeclarationContentComponent: UkImportContent,
        declarationName: 'Import',
        declarationType: DeclarationType.UK_EXPORT,
        declarationInternalType: DeclarationInternalType.IMPORT_NEW,
        validationErrorsParser: ukImportH2ParseValidationErrors,
        declarationFormValidations: cdsImportFormValidation,
        productFormValidations: cdsImportProductSectionValidation,
        createProductFuncName: 'createUkExportProduct',
        listProductsFuncName: 'listUkExportProducts',
        updateDeclarationFuncName: 'updateUkExportDeclaration',
        updateProductFuncName: 'updateUkExportProduct',
        numberOfItemsFieldName: 'goodsItemQuantity',
        payloadPath: 'cdsExportDeclarationPayload',
        transformData: {
            declaration: {
                forClient: (data: any, straight?: boolean) => {
                    if (straight) return CdsExportDeclarationUtils.transformForClient(data);

                    const currentDeclaration = data?.cdsExportDeclaration;
                    if (!currentDeclaration) return;
                    return {
                        ...data,
                        cdsExportDeclaration: CdsExportDeclarationUtils.transformForClient(currentDeclaration),
                    };
                },
                forServer: (data: any) => {
                    return CdsExportDeclarationUtils.transformForServer(data);
                },
            },
            product: {
                forClient: (data: any, straight?: boolean) => {
                    if (straight) return CdsExportDeclarationUtils.transformProductForClient(data);
                    return CdsExportDeclarationUtils.transformProductForClient(data?.governmentAgencyGoodsItem);
                },
                forServer: (data: any) => {
                    return { governmentAgencyGoodsItem: CdsExportDeclarationUtils.transformProductForServer(data) };
                },
            },
        },
    },
};

export default declarationViewMap;
