import { listAepBusinessRules } from './client';

export const doGetAepBusinessRules: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'LIST_AEP_BUSINESS_RULES_REQUEST' });
    try {
        const payload = await listAepBusinessRules();

        dispatch({
            type: 'LIST_AEP_BUSINESS_RULES_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_AEP_BUSINESS_RULES_ERROR', error: e?.response?.data });
    }
};
