export enum AdditionalDeclarationTypes {
    A = 'A', // - Standard Post-Lodged Declaration
    B = 'B', // - Simplified Article 116(1)
    BA = 'B*',
    C = 'C', // - Simplified Article 11621)
    D = 'D', // - Standard Pre-Lodged Declaration
    E = 'E', // - Simplified d(B) Article 171
    EA = 'E*', // - Simplified d(B) Article 171
    F = 'F', // - Simplified d(C) Article 171
    G = 'G',
    H = 'H',
    J = 'J', // - Additional Declaration Type on CDS
    K = 'K', // - Additional Declaration Type on CDS
    N = 'N', // - Normal EXS declaration
    P = 'P', // - Express EXS declaration
    Q = 'Q', // - Additional Declaration Type on CDS
    X = 'X', // - Simplified d(B/E) Supplementary
    Y = 'Y', // - Simplified d(C/F) Supplementary
    YAA = 'Y**', // - Simplified d(C/F) Supplementary
    Z = 'Z', // - Supplementary - Article 182
    ZAA = 'Z**', // - Supplementary - Article 182
}

export const additionalDeclarationTypesLabels = [
    { id: AdditionalDeclarationTypes.A, value: 'Standard Post-Lodged Declaration' },
    { id: AdditionalDeclarationTypes.B, value: 'Simplified Article 116(1)' },
    { id: AdditionalDeclarationTypes.C, value: 'Simplified Article 11621)' },
    { id: AdditionalDeclarationTypes.D, value: 'Standard Pre-Lodged Declaration' },
    { id: AdditionalDeclarationTypes.E, value: 'Simplified (B) Article 171' },
    { id: AdditionalDeclarationTypes.F, value: 'Simplified (C) Article 171' },
    { id: AdditionalDeclarationTypes.J, value: 'Additional Declaration Type on CDS' },
    { id: AdditionalDeclarationTypes.K, value: 'Additional Declaration Type on CDS' },
    { id: AdditionalDeclarationTypes.N, value: 'Normal EXS declaration' },
    { id: AdditionalDeclarationTypes.P, value: 'Express EXS declaration' },
    { id: AdditionalDeclarationTypes.Q, value: 'Additional Declaration Type on CDS' },
    { id: AdditionalDeclarationTypes.X, value: 'Simplified (B/E) Supplementary' },
    { id: AdditionalDeclarationTypes.Y, value: 'Simplified (C/F) Supplementary' },
    { id: AdditionalDeclarationTypes.Z, value: 'Supplementary - Article 182' },
] as const;

export const chiefAdditionalDeclarationTypesLabels = [
    { id: AdditionalDeclarationTypes.A, value: 'Standard customs declaration' },
    { id: AdditionalDeclarationTypes.B, value: 'Simplified declaration on an occasional basis' },
    { id: AdditionalDeclarationTypes.C, value: 'Simplified declaration with regular use (pre-authorised)' },
    { id: AdditionalDeclarationTypes.D, value: 'Standard customs declaration (pre-lodged)' },
    { id: AdditionalDeclarationTypes.E, value: 'Simplified declaration on an occasional basis (pre-lodged)' },
    {
        id: AdditionalDeclarationTypes.F,
        value: 'Simplified declaration with regular use (pre-authorised) (pre-lodged)',
    },

    { id: AdditionalDeclarationTypes.J, value: '' },
    { id: AdditionalDeclarationTypes.K, value: '' },
    { id: AdditionalDeclarationTypes.N, value: '' },
    { id: AdditionalDeclarationTypes.P, value: '' },
    { id: AdditionalDeclarationTypes.Q, value: '' },
    { id: AdditionalDeclarationTypes.X, value: '' },

    { id: AdditionalDeclarationTypes.Y, value: 'Supplementary declaration covered by types C and F' },
    { id: AdditionalDeclarationTypes.Z, value: 'Supplementary declarations for Entry in Declarants Records' },
] as const;
