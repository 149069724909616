import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import SearchCustomer from 'views/declarations/common/search-customer/SearchCustomer';
import useSearchCustomer from 'views/declarations/common/search-customer/useSearchCustomer';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const ExtraDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    const {
        handleSelectCustomer,
        show: showSearchCustomerDrawer,
        triggerShowDrawer: triggerShowSearchCustomerDrawer,
        triggerHideDrawer: triggerHideSearchCustomerDrawer,
    } = useSearchCustomer();

    return (
        <section
            id="extra-details-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('extra-details-form-card');
                }
            }}
            data-testid="extra-details-form-card"
        >
            <NewFormCard title={t('Extra details')}>
                <FormCardContainer>
                    <FormInput
                        maxLength={35}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`deferredPayment`, props)}
                        label={t('deferredPayment')}
                        testId="deferredPayment"
                        refNumber="2.6"
                        tooltip={getH1TooltipsByRefNumberAndField('2.6', t('deferredPayment'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        onSearchCustomer={({ fieldHelpers }) => {
                            triggerShowSearchCustomerDrawer((customer) => {
                                fieldHelpers?.setValue(customer.eori);
                            });
                        }}
                        condensed
                    />
                    <CurrencySelect
                        disabled
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`internalCurrency`, props)}
                        label={t('internalCurrency')}
                        testId="internalCurrency"
                        refNumber="4.12"
                        tooltip={getH1TooltipsByRefNumberAndField('4.12', t('internalCurrency'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormInput
                        maxLength={17}
                        viewOnly={props.viewOnly}
                        testId="personProvidingGuarantee"
                        {...getFormikProps(`personProvidingGuarantee`, props)}
                        refNumber="3.45"
                        label={t('personProvidingGuarantee')}
                        tooltip={getH1TooltipsByRefNumberAndField('3.45', t('personProvidingGuarantee'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        onSearchCustomer={({ fieldHelpers }) => {
                            triggerShowSearchCustomerDrawer((customer) => {
                                fieldHelpers?.setValue(customer.eori);
                            });
                        }}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <SearchCustomer.Drawer
                onClose={triggerHideSearchCustomerDrawer}
                show={showSearchCustomerDrawer}
                onSelectCustomer={handleSelectCustomer}
            />
        </section>
    );
};

export default ExtraDetailsCard;
