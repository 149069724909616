import { Button, Col, Divider, Row } from 'antd';
import { H4, TextSmall, TextSmallBold } from 'components/ui/base/typography';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import { FC } from 'react';
import { CardNameTitle, CustomModal, ProductTitle } from './ValidationErrorContainer.styles';

interface Props {
    isOpen: boolean;
    close: Function;
}

const ValidationErrorContainer: FC<Props> = ({ isOpen, close }) => {
    const { declarationErrors } = useDeclarationFormErrors();

    return (
        <CustomModal
            title={
                <>
                    <H4>Form Errors</H4>
                </>
            }
            centered
            closable={false}
            visible={isOpen}
            onCancel={() => {}}
            width={800}
            cancelText="Cancel"
            zIndex={1000}
            footer={[
                <Button
                    key="goToSettings"
                    onClick={() => {
                        close();
                    }}
                    size="large"
                >
                    Close
                </Button>,
            ]}
        >
            {declarationErrors.masterDetails.map((card) => {
                return (
                    <>
                        <CardNameTitle>{card.name}</CardNameTitle>
                        <Row gutter={[16, 16]}>
                            {card.fields?.map((field) => {
                                return (
                                    <>
                                        <Col span={8}>
                                            <TextSmallBold>{field.name}:</TextSmallBold>
                                        </Col>
                                        <Col span={16}>
                                            <TextSmall>{field.message}</TextSmall>
                                        </Col>
                                    </>
                                );
                            })}
                        </Row>
                        <Divider />
                    </>
                );
            })}
            {declarationErrors.items.map((item) => {
                return (
                    <>
                        <ProductTitle>{`Product ${item.index}`}</ProductTitle>
                        {item.errors.map((error) => {
                            return (
                                <>
                                    <CardNameTitle>{error.name}</CardNameTitle>
                                    <Row gutter={[16, 16]}>
                                        {error.fields?.map((field) => {
                                            return (
                                                <>
                                                    <Col span={8}>
                                                        <TextSmallBold>{field.name}:</TextSmallBold>
                                                    </Col>
                                                    <Col span={16}>
                                                        <TextSmall>{field.message}</TextSmall>
                                                    </Col>
                                                </>
                                            );
                                        })}
                                    </Row>
                                    <Divider />
                                </>
                            );
                        })}
                    </>
                );
            })}
        </CustomModal>
    );
};

export default ValidationErrorContainer;
