import { SuccessResponse } from '../../core/http/response';
import axiosClient from '../../config/axios';
import config from '../../config';
import { CustomsOfficeOfExit } from './CustomsOfficeOfExit';

export const createCustomsOfficeOfExit = (office: CustomsOfficeOfExit): Promise<any> =>
    axiosClient
        .post<SuccessResponse<any>>(`${config.cmsUrl}/codelist/uk/exitcustomsoffices`, office)
        .then((response) => response.data);

export const deleteCustomsOfficeOfExit = (officeId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(`${config.cmsUrl}/codelist/uk/exitcustomsoffices/${officeId}`)
        .then((response) => response.data);
