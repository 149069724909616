import { CdsExportMessageType } from './export-declaration';

export enum UkImportDeclarationName {
    H1 = 'H1',
    H2 = 'H2',
    H3 = 'H3',
    H4 = 'H4',
    H5 = 'H5',
}

export enum UkExportDeclarationName {
    LCP = 'LCP',
    PSA = 'PSA',
    SDP = 'SDP',
    SUPP_DEC = 'SUPP_DEC',
    FULL_DEC = 'FULL_DEC',
    CCR = 'CCR',
    EXS = 'EXS',
    INS = 'INS',
    REP = 'REP',
}

export const ukDeclarationNameLabels = [
    {
        key: UkImportDeclarationName.H1,
        value: '(H1) Free Circulation',
    },
    {
        key: UkImportDeclarationName.H2,
        value: '(H2) Customs Warehousing',
    },
    {
        key: UkImportDeclarationName.H3,
        value: '(H3) Temporary Admission',
    },
    {
        key: UkImportDeclarationName.H4,
        value: '(H4) Inward Processing ',
    },
    {
        key: UkImportDeclarationName.H5,
        value: '(H5) Introductions of Goods (SFT)',
    },
] as const;

export const ukExportDeclarationNameLabels = [
    {
        key: CdsExportMessageType.B1,
        value: 'B1',
    },
    {
        key: CdsExportMessageType.C1,
        value: 'C1',
    },
    {
        key: CdsExportMessageType.B2,
        value: 'B2',
    },
    {
        key: CdsExportMessageType.B4,
        value: 'B4',
    },
    {
        key: CdsExportMessageType.C21E,
        value: 'C21E',
    },
] as const;
