import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { partySubRoleTypeLabels } from '../../../../../store/declarations/enums/uk/party-sub-role-type';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'id' | 'roleCode';

export const additionalSupplyChainActorBlockValidation: {
    childValidators: {
        'goodsShipment.aeoMutualRecognitionParty': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.aeoMutualRecognitionParty': {
            childValidators: {
                id: [validators.required(), validators.eori()],
                roleCode: [validators.required(), validators.max(3)],
            },
            stopAtNull: true,
        },
    },
};

export const additionalSupplyChainActorFields =
    additionalSupplyChainActorBlockValidation.childValidators['goodsShipment.aeoMutualRecognitionParty'];

export const additionalSupplyChainActorBlockProductValidation: {
    childValidators: {
        aeoMutualRecognitionParty: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        aeoMutualRecognitionParty: {
            childValidators: {
                id: [validators.required(), validators.eori()],
                roleCode: [validators.required(), validators.max(3)],
            },
            stopAtNull: true,
        },
    },
};

export const additionalSupplyChainActorProductFields =
    additionalSupplyChainActorBlockProductValidation.childValidators.aeoMutualRecognitionParty;

interface Props extends BlockProps<Fields> {}

const AdditionalSupplyChainActorBlock = ({
    path = 'goodsShipment.aeoMutualRecognitionParty',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '3/37', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        required
                        label={t('identificationNumber')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="roleCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        required
                        label={t('roleCode')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={partySubRoleTypeLabels}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default AdditionalSupplyChainActorBlock;
