export const additionalFiscal = [
    { id: 'AD', value: '' },
    { id: 'AE', value: '' },
    { id: 'AF', value: '' },
    { id: 'AG', value: '' },
    { id: 'AI', value: '' },
    { id: 'AL', value: '' },
    { id: 'AM', value: '' },
    { id: 'AO', value: '' },
    { id: 'AQ', value: '' },
    { id: 'AR', value: '' },
    { id: 'AS', value: '' },
    { id: 'AT', value: '' },
    { id: 'AU', value: '' },
    { id: 'AW', value: '' },
    { id: 'AZ', value: '' },
    { id: 'BA', value: '' },
    { id: 'BB', value: '' },
    { id: 'BD', value: '' },
    { id: 'BE', value: '' },
    { id: 'BF', value: '' },
    { id: 'BG', value: '' },
    { id: 'BH', value: '' },
    { id: 'BI', value: '' },
    { id: 'BJ', value: '' },
    { id: 'BL', value: '' },
    { id: 'BM', value: '' },
    { id: 'BN', value: '' },
    { id: 'BO', value: '' },
    { id: 'BQ', value: '' },
    { id: 'BR', value: '' },
    { id: 'BS', value: '' },
    { id: 'BT', value: '' },
    { id: 'BV', value: '' },
    { id: 'BW', value: '' },
    { id: 'BY', value: '' },
    { id: 'BZ', value: '' },
    { id: 'CA', value: '' },
    { id: 'CC', value: '' },
    { id: 'CD', value: '' },
    { id: 'CF', value: '' },
    { id: 'CG', value: '' },
    { id: 'CH', value: '' },
    { id: 'CI', value: '' },
    { id: 'CK', value: '' },
    { id: 'CL', value: '' },
    { id: 'CM', value: '' },
    { id: 'CN', value: '' },
    { id: 'CO', value: '' },
    { id: 'CR', value: '' },
    { id: 'CU', value: '' },
    { id: 'CV', value: '' },
    { id: 'CW', value: '' },
    { id: 'CX', value: '' },
    { id: 'CY', value: '' },
    { id: 'CZ', value: '' },
    { id: 'DE', value: '' },
    { id: 'DJ', value: '' },
    { id: 'DK', value: '' },
    { id: 'DM', value: '' },
    { id: 'DO', value: '' },
    { id: 'DZ', value: '' },
    { id: 'EC', value: '' },
    { id: 'EE', value: '' },
    { id: 'EG', value: '' },
    { id: 'EH', value: '' },
    { id: 'ER', value: '' },
    { id: 'ES', value: '' },
    { id: 'ET', value: '' },
    { id: 'EU', value: '' },
    { id: 'FI', value: '' },
    { id: 'FJ', value: '' },
    { id: 'FK', value: '' },
    { id: 'FM', value: '' },
    { id: 'FO', value: '' },
    { id: 'FR', value: '' },
    { id: 'GA', value: '' },
    { id: 'GB', value: '' },
    { id: 'GD', value: '' },
    { id: 'GE', value: '' },
    { id: 'GG', value: '' },
    { id: 'GH', value: '' },
    { id: 'GI', value: '' },
    { id: 'GL', value: '' },
    { id: 'GM', value: '' },
    { id: 'GN', value: '' },
    { id: 'GQ', value: '' },
    { id: 'GR', value: '' },
    { id: 'GS', value: '' },
    { id: 'GT', value: '' },
    { id: 'GU', value: '' },
    { id: 'GW', value: '' },
    { id: 'GY', value: '' },
    { id: 'HK', value: '' },
    { id: 'HM', value: '' },
    { id: 'HN', value: '' },
    { id: 'HR', value: '' },
    { id: 'HT', value: '' },
    { id: 'HU', value: '' },
    { id: 'ID', value: '' },
    { id: 'IE', value: '' },
    { id: 'IL', value: '' },
    { id: 'IM', value: '' },
    { id: 'IN', value: '' },
    { id: 'IO', value: '' },
    { id: 'IQ', value: '' },
    { id: 'IR', value: '' },
    { id: 'IS', value: '' },
    { id: 'IT', value: '' },
    { id: 'JE', value: '' },
    { id: 'JM', value: '' },
    { id: 'JO', value: '' },
    { id: 'JP', value: '' },
    { id: 'KE', value: '' },
    { id: 'KG', value: '' },
    { id: 'KH', value: '' },
    { id: 'KI', value: '' },
    { id: 'KM', value: '' },
    { id: 'KN', value: '' },
    { id: 'KP', value: '' },
    { id: 'KR', value: '' },
    { id: 'KW', value: '' },
    { id: 'KY', value: '' },
    { id: 'KZ', value: '' },
    { id: 'LA', value: '' },
    { id: 'LB', value: '' },
    { id: 'LC', value: '' },
    { id: 'LI', value: '' },
    { id: 'LK', value: '' },
    { id: 'LR', value: '' },
    { id: 'LS', value: '' },
    { id: 'LT', value: '' },
    { id: 'LU', value: '' },
    { id: 'LV', value: '' },
    { id: 'LY', value: '' },
    { id: 'MA', value: '' },
    { id: 'MD', value: '' },
    { id: 'ME', value: '' },
    { id: 'MG', value: '' },
    { id: 'MH', value: '' },
    { id: 'MK', value: '' },
    { id: 'ML', value: '' },
    { id: 'MM', value: '' },
    { id: 'MN', value: '' },
    { id: 'MO', value: '' },
    { id: 'MP', value: '' },
    { id: 'MR', value: '' },
    { id: 'MS', value: '' },
    { id: 'MT', value: '' },
    { id: 'MU', value: '' },
    { id: 'MV', value: '' },
    { id: 'MW', value: '' },
    { id: 'MX', value: '' },
    { id: 'MY', value: '' },
    { id: 'MZ', value: '' },
    { id: 'NA', value: '' },
    { id: 'NC', value: '' },
    { id: 'NE', value: '' },
    { id: 'NF', value: '' },
    { id: 'NG', value: '' },
    { id: 'NI', value: '' },
    { id: 'NL', value: '' },
    { id: 'NO', value: '' },
    { id: 'NP', value: '' },
    { id: 'NR', value: '' },
    { id: 'NU', value: '' },
    { id: 'NZ', value: '' },
    { id: 'OM', value: '' },
    { id: 'PA', value: '' },
    { id: 'PE', value: '' },
    { id: 'PF', value: '' },
    { id: 'PG', value: '' },
    { id: 'PH', value: '' },
    { id: 'PK', value: '' },
    { id: 'PL', value: '' },
    { id: 'PM', value: '' },
    { id: 'PN', value: '' },
    { id: 'PS', value: '' },
    { id: 'PT', value: '' },
    { id: 'PW', value: '' },
    { id: 'PY', value: '' },
    { id: 'QA', value: '' },
    { id: 'QP', value: '' },
    { id: 'QQ', value: '' },
    { id: 'QR', value: '' },
    { id: 'QS', value: '' },
    { id: 'QU', value: '' },
    { id: 'QV', value: '' },
    { id: 'QW', value: '' },
    { id: 'QX', value: '' },
    { id: 'QY', value: '' },
    { id: 'QZ', value: '' },
    { id: 'RO', value: '' },
    { id: 'RU', value: '' },
    { id: 'RW', value: '' },
    { id: 'SA', value: '' },
    { id: 'SB', value: '' },
    { id: 'SC', value: '' },
    { id: 'SD', value: '' },
    { id: 'SE', value: '' },
    { id: 'SG', value: '' },
    { id: 'SH', value: '' },
    { id: 'SI', value: '' },
    { id: 'SK', value: '' },
    { id: 'SL', value: '' },
    { id: 'SM', value: '' },
    { id: 'SN', value: '' },
    { id: 'SO', value: '' },
    { id: 'SR', value: '' },
    { id: 'SS', value: '' },
    { id: 'ST', value: '' },
    { id: 'SV', value: '' },
    { id: 'SX', value: '' },
    { id: 'SY', value: '' },
    { id: 'SZ', value: '' },
    { id: 'TC', value: '' },
    { id: 'TD', value: '' },
    { id: 'TF', value: '' },
    { id: 'TG', value: '' },
    { id: 'TH', value: '' },
    { id: 'TJ', value: '' },
    { id: 'TK', value: '' },
    { id: 'TL', value: '' },
    { id: 'TM', value: '' },
    { id: 'TN', value: '' },
    { id: 'TO', value: '' },
    { id: 'TR', value: '' },
    { id: 'TT', value: '' },
    { id: 'TV', value: '' },
    { id: 'TW', value: '' },
    { id: 'TZ', value: '' },
    { id: 'UA', value: '' },
    { id: 'UG', value: '' },
    { id: 'UM', value: '' },
    { id: 'US', value: '' },
    { id: 'UY', value: '' },
    { id: 'UZ', value: '' },
    { id: 'VA', value: '' },
    { id: 'VC', value: '' },
    { id: 'VE', value: '' },
    { id: 'VG', value: '' },
    { id: 'VI', value: '' },
    { id: 'VN', value: '' },
    { id: 'VU', value: '' },
    { id: 'WF', value: '' },
    { id: 'WS', value: '' },
    { id: 'XC', value: '' },
    { id: 'XK', value: '' },
    { id: 'XS', value: '' },
    { id: 'XL', value: '' },
    { id: 'YE', value: '' },
    { id: 'YT', value: '' },
    { id: 'ZA', value: '' },
    { id: 'ZB', value: '' },
    { id: 'ZD', value: '' },
    { id: 'ZE', value: '' },
    { id: 'ZF', value: '' },
    { id: 'ZG', value: '' },
    { id: 'ZH', value: '' },
    { id: 'ZM', value: '' },
    { id: 'ZN', value: '' },
    { id: 'ZU', value: '' },
    { id: 'ZW', value: '' },
    { id: 'ZC', value: '' },
    { id: 'ZI', value: '' },
    { id: 'ZJ', value: '' },
    { id: 'ZK', value: '' },
    { id: 'ZL', value: '' },
    { id: 'ZS', value: '' },
    { id: 'XI', value: '' },
    { id: 'AED', value: '' },
    { id: 'AFN', value: '' },
    { id: 'ALL', value: '' },
    { id: 'AMD', value: '' },
    { id: 'ANG', value: '' },
    { id: 'AOA', value: '' },
    { id: 'ARS', value: '' },
    { id: 'AUD', value: '' },
    { id: 'AWG', value: '' },
    { id: 'AZN', value: '' },
    { id: 'BAM', value: '' },
    { id: 'BBD', value: '' },
    { id: 'BDT', value: '' },
    { id: 'BGN', value: '' },
    { id: 'BHD', value: '' },
    { id: 'BIF', value: '' },
    { id: 'BMD', value: '' },
    { id: 'BND', value: '' },
    { id: 'BOB', value: '' },
    { id: 'BRL', value: '' },
    { id: 'BSD', value: '' },
    { id: 'BWP', value: '' },
    { id: 'BYN', value: '' },
    { id: 'BZD', value: '' },
    { id: 'CAD', value: '' },
    { id: 'CDF', value: '' },
    { id: 'CHF', value: '' },
    { id: 'CLP', value: '' },
    { id: 'CNY', value: '' },
    { id: 'COP', value: '' },
    { id: 'CRC', value: '' },
    { id: 'CUP', value: '' },
    { id: 'CVE', value: '' },
    { id: 'CZK', value: '' },
    { id: 'DJF', value: '' },
    { id: 'DKK', value: '' },
    { id: 'DOP', value: '' },
    { id: 'DZD', value: '' },
    { id: 'EGP', value: '' },
    { id: 'ERN', value: '' },
    { id: 'ETB', value: '' },
    { id: 'EUR', value: '' },
    { id: 'FJD', value: '' },
    { id: 'FKP', value: '' },
    { id: 'GBP', value: '' },
    { id: 'GEL', value: '' },
    { id: 'GHS', value: '' },
    { id: 'GIP', value: '' },
    { id: 'GMD', value: '' },
    { id: 'GNF', value: '' },
    { id: 'GTQ', value: '' },
    { id: 'GYD', value: '' },
    { id: 'HKD', value: '' },
    { id: 'HNL', value: '' },
    { id: 'HRK', value: '' },
    { id: 'HTG', value: '' },
    { id: 'HUF', value: '' },
    { id: 'IDR', value: '' },
    { id: 'ILS', value: '' },
    { id: 'INR', value: '' },
    { id: 'IQD', value: '' },
    { id: 'IRR', value: '' },
    { id: 'ISK', value: '' },
    { id: 'JMD', value: '' },
    { id: 'JOD', value: '' },
    { id: 'JPY', value: '' },
    { id: 'KES', value: '' },
    { id: 'KGS', value: '' },
    { id: 'KHR', value: '' },
    { id: 'KMF', value: '' },
    { id: 'KPW', value: '' },
    { id: 'KRW', value: '' },
    { id: 'KWD', value: '' },
    { id: 'KYD', value: '' },
    { id: 'KZT', value: '' },
    { id: 'LAK', value: '' },
    { id: 'LBP', value: '' },
    { id: 'LKR', value: '' },
    { id: 'LRD', value: '' },
    { id: 'LYD', value: '' },
    { id: 'MAD', value: '' },
    { id: 'MDL', value: '' },
    { id: 'MGA', value: '' },
    { id: 'MKD', value: '' },
    { id: 'MMK', value: '' },
    { id: 'MNT', value: '' },
    { id: 'MOP', value: '' },
    { id: 'MRO', value: '' },
    { id: 'MUR', value: '' },
    { id: 'MVR', value: '' },
    { id: 'MWK', value: '' },
    { id: 'MXN', value: '' },
    { id: 'MYR', value: '' },
    { id: 'MZN', value: '' },
    { id: 'NAD', value: '' },
    { id: 'NGN', value: '' },
    { id: 'NIO', value: '' },
    { id: 'NOK', value: '' },
    { id: 'NPR', value: '' },
    { id: 'NZD', value: '' },
    { id: 'OMR', value: '' },
    { id: 'PAB', value: '' },
    { id: 'PEN', value: '' },
    { id: 'PGK', value: '' },
    { id: 'PHP', value: '' },
    { id: 'PKR', value: '' },
    { id: 'PLN', value: '' },
    { id: 'PYG', value: '' },
    { id: 'QAR', value: '' },
    { id: 'RON', value: '' },
    { id: 'RSD', value: '' },
    { id: 'RUB', value: '' },
    { id: 'RWF', value: '' },
    { id: 'SAR', value: '' },
    { id: 'SBD', value: '' },
    { id: 'SCR', value: '' },
    { id: 'SDG', value: '' },
    { id: 'SEK', value: '' },
    { id: 'SGD', value: '' },
    { id: 'SHP', value: '' },
    { id: 'SLL', value: '' },
    { id: 'SOS', value: '' },
    { id: 'SRD', value: '' },
    { id: 'SSP', value: '' },
    { id: 'STD', value: '' },
    { id: 'SVC', value: '' },
    { id: 'SYP', value: '' },
    { id: 'SZL', value: '' },
    { id: 'THB', value: '' },
    { id: 'TJS', value: '' },
    { id: 'TMT', value: '' },
    { id: 'TND', value: '' },
    { id: 'TOP', value: '' },
    { id: 'TRY', value: '' },
    { id: 'TTD', value: '' },
    { id: 'TWD', value: '' },
    { id: 'TZS', value: '' },
    { id: 'UAH', value: '' },
    { id: 'USD', value: '' },
    { id: 'UYU', value: '' },
    { id: 'UZS', value: '' },
    { id: 'VEF', value: '' },
    { id: 'VND', value: '' },
    { id: 'VUV', value: '' },
    { id: 'WST', value: '' },
    { id: 'XAF', value: '' },
    { id: 'XCD', value: '' },
    { id: 'XOF', value: '' },
    { id: 'XPF', value: '' },
    { id: 'YER', value: '' },
    { id: 'YUM', value: '' },
    { id: 'ZAR', value: '' },
    { id: 'ZMW', value: '' },
    { id: 'ZWL', value: '' },
    { id: 'UGX', value: '' },
];
