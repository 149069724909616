import { Col, Row } from 'antd';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { normalizeCodesToSelect } from 'store/codelists/code';
import useCodelists from 'hooks/useCodelists';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';

interface Props extends BlockProps<'identificationTypeCode' | 'Id'> {}

const IdentityOfMeansOfTransportOnArrivalBlock = ({
    path = 'goodsShipment.consignment.arrivalTransportMeans',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();

    const ucc = useMemo(() => '7/9', []);
    return (
        <Row gutter={18}>
            <Col xs={fieldOptions?.identificationTypeCode?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="identificationTypeCode"
                    validation={{
                        name: t('transportIdentityMeansType'),
                        number: true,
                        exact: 2,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormSelect
                            refNumber={ucc}
                            label={t('transportIdentityMeansType')}
                            {...getFormikProps(field.name, form)}
                            selectOptions={normalizeCodesToSelect(codelists?.ukTransportMeansIdentificationTypes || [])}
                        />
                    )}
                </DeclarationField>
            </Col>
            <Col xs={fieldOptions?.Id?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="Id"
                    validation={{
                        name: t('transportIdentityMeansId'),
                        max: 35,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormInput
                            refNumber={ucc}
                            maxLength={35}
                            label={t('transportIdentityMeansId')}
                            {...getFormikProps(field.name, form)}
                        />
                    )}
                </DeclarationField>
            </Col>
        </Row>
    );
};

export default IdentityOfMeansOfTransportOnArrivalBlock;
