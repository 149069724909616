import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { supplyChainActorRoleCodeLabels } from 'store/declarations/enums/ireland/supply-chain-actors-role-code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import addPathPrefix from '../../../../../../utils/addPathPrefix';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';
import MultipleItemsCard from '../../../../common/MultipleItemsCard';

const SupplyChainActorCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix!}
            initialValues={{ roleCode: '', traderIdentification: '' }}
            list={(list) => [
                {
                    field: t('supplyChainActor.roleCode'),
                    value: list.roleCode,
                },
                {
                    field: t('supplyChainActor.traderIdentification'),
                    value: list.traderIdentification,
                },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            keyCard={props.keyCard}
            title={t('supplyChainActor.title')}
            condensed
            refNumber="3.37"
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'roleCode'), formProps)}
                        refNumber="3.37"
                        label={t('supplyChainActor.roleCode')}
                        tooltip={getH1TooltipsByRefNumberAndField('3.37', t('supplyChainActor.roleCode'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        selectOptions={supplyChainActorRoleCodeLabels}
                        condensed
                    />
                    <FormInput
                        maxLength={18}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'traderIdentification'), formProps)}
                        refNumber="3.37"
                        label={t('supplyChainActor.traderIdentification')}
                        tooltip={getH1TooltipsByRefNumberAndField('3.37', t('supplyChainActor.traderIdentification'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default SupplyChainActorCard;
