import { removeDuplicate } from 'core/utils/arrays';
import { format, parseISO } from 'date-fns';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { FilterResult, FilterType } from './filter-result';

export const datepickerClass = [
    'ant-picker-cell',
    'ant-picker-cell-in-view',
    'ant-picker-cell-inner',
    'ant-picker-now-btn',
    'ant-btn ant-btn-primary ant-btn-sm',
    'ant-picker-prev-icon',
    'ant-picker-super-prev-icon',
    'ant-picker-super-next-icon',
    'ant-picker-month-btn',
    'ant-picker-header-next-btn',
    'ant-picker-header-view',
    'ant-picker-body',
    'ant-picker-year-btn',
];

export const updateFiltersList = (filter: FilterResult, filterResult: FilterResult[], setFilterResult: Function) => {
    const types = filterResult.map((f) => f.type);
    if (types.includes(filter.type)) {
        const index = filterResult.findIndex((f) => f.type === filter.type);
        if (Number.isInteger(index)) {
            if (filter.type === FilterType.DATE) {
                const newFilters = [...filterResult];
                newFilters[index].value = filter.value;
                setFilterResult(newFilters);
            } else {
                const newFilters = [...filterResult];
                const values = [...newFilters[index].value];
                const newValues = removeDuplicate([...values, ...filter.value]);
                newFilters[index].value = newValues;
                setFilterResult(newFilters);
            }
        }
    } else {
        setFilterResult([...filterResult, filter]);
    }
};

export const handleDeclarationFilters = (filterResult: FilterResult[]) => {
    let startDate = '';
    let declarationStatus = [] as DeclarationStatus[];
    let declarationType = [] as DeclarationName[];
    filterResult.forEach((res) => {
        if (res.type === FilterType.DATE && res.type) {
            const date = parseISO(res.value[0]);
            startDate = format(date, 'yyyy-MM-dd');
        } else if (res.type === FilterType.DECLARATION_STATUS && res.value) {
            declarationStatus = res.value as DeclarationStatus[];
        } else if (res.type === FilterType.DECLARATION_TYPE && res.value) {
            declarationType = res.value as DeclarationName[];
        }
    });
    return { startDate, endDate: startDate, declarationStatus, declarationType };
};
