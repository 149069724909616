import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import addPathPrefix from '../../../../../../utils/addPathPrefix';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';
import MultipleItemsCard from '../../../../common/MultipleItemsCard';
import { additionalInformations } from '../validation/UKCommonValidations';

const AdditionalInformationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix!}
            initialValues={{
                additionalInformationCode: '',
                additionalInformationText: '',
            }}
            list={(list) => [
                { field: t('additionalInformation.code'), value: list.additionalInformationCode },
                { field: t('additionalInformation.description'), value: list.additionalInformationText },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            cardNumber={props.cardNumber}
            cardTotal={props.cardTotal}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            title={t('additionalInformation.title')}
            condensed
            validationSchema={additionalInformations}
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormSelect
                        required
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'additionalInformationCode')}`, formProps)}
                        refNumber="2.2"
                        label={t('additionalInformation.code')}
                        selectOptions={normalizeCodesToSelect(codelists?.ukImportSpecialMentions || [])}
                        tooltip={getCdsTooltipsByRefNumberAndField('2.2', t('additionalInformation.code'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormInput
                        maxLength={512}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'additionalInformationText')}`, formProps)}
                        refNumber="2.2"
                        label={t('additionalInformation.description')}
                        tooltip={getCdsTooltipsByRefNumberAndField('2.2', t('additionalInformation.description'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default AdditionalInformationCard;
