export enum MeasureUnitTypes {
    ASV = 'ASV',
    CCT = 'CCT',
    CEN = 'CEN',
    CTM = 'CTM',
    DAP = 'DAP',
    DHS = 'DHS',
    DTN = 'DTN',
    EUR = 'EUR',
    FC1 = 'FC1',
    GBP = 'GBP',
    GFI = 'GFI',
    GP1 = 'GP1',
    GRM = 'GRM',
    GRT = 'GRT',
    HLT = 'HLT',
    HMT = 'HMT',
    KAC = 'KAC',
    KCC = 'KCC',
    KCL = 'KCL',
    KGM = 'KGM',
    KLT = 'KLT',
    KMA = 'KMA',
    KMT = 'KMT',
    KNI = 'KNI',
    KNS = 'KNS',
    KPH = 'KPH',
    KPO = 'KPO',
    KPP = 'KPP',
    KSD = 'KSD',
    KSH = 'KSH',
    KUR = 'KUR',
    KW1 = 'KW1',
    LHC = 'LHC',
    LPA = 'LPA',
    LTR = 'LTR',
    MIL = 'MIL',
    MTK = 'MTK',
    MTQ = 'MTQ',
    MTR = 'MTR',
    MWH = 'MWH',
    NAR = 'NAR',
    NCL = 'NCL',
    NPR = 'NPR',
    P1 = 'P1',
    RET = 'RET',
    TCE = 'TCE',
    TJO = 'TJO',
    TNE = 'TNE',
    MGM = 'MGM',
    MLT = 'MLT',
    MCG = 'MCG',
    MCL = 'MCL',
}

export const measureUnitTypeLabels = [
    { id: MeasureUnitTypes.ASV, value: 'ASV' },
    { id: MeasureUnitTypes.CCT, value: 'CCT' },
    { id: MeasureUnitTypes.CEN, value: 'CEN' },
    { id: MeasureUnitTypes.CTM, value: 'CTM' },
    { id: MeasureUnitTypes.DAP, value: 'DAP' },
    { id: MeasureUnitTypes.DHS, value: 'DHS' },
    { id: MeasureUnitTypes.DTN, value: 'DTN' },
    { id: MeasureUnitTypes.EUR, value: 'EUR' },
    { id: MeasureUnitTypes.FC1, value: 'FC1' },
    { id: MeasureUnitTypes.GBP, value: 'GBP' },
    { id: MeasureUnitTypes.GFI, value: 'GFI' },
    { id: MeasureUnitTypes.GP1, value: 'GP1' },
    { id: MeasureUnitTypes.GRM, value: 'GRM' },
    { id: MeasureUnitTypes.GRT, value: 'GRT' },
    { id: MeasureUnitTypes.HLT, value: 'HLT' },
    { id: MeasureUnitTypes.HMT, value: 'HMT' },
    { id: MeasureUnitTypes.KAC, value: 'KAC' },
    { id: MeasureUnitTypes.KCC, value: 'KCC' },
    { id: MeasureUnitTypes.KCL, value: 'KCL' },
    { id: MeasureUnitTypes.KGM, value: 'KGM' },
    { id: MeasureUnitTypes.KLT, value: 'KLT' },
    { id: MeasureUnitTypes.KMA, value: 'KMA' },
    { id: MeasureUnitTypes.KMT, value: 'KMT' },
    { id: MeasureUnitTypes.KNI, value: 'KNI' },
    { id: MeasureUnitTypes.KNS, value: 'KNS' },
    { id: MeasureUnitTypes.KPH, value: 'KPH' },
    { id: MeasureUnitTypes.KPO, value: 'KPO' },
    { id: MeasureUnitTypes.KPP, value: 'KPP' },
    { id: MeasureUnitTypes.KSD, value: 'KSD' },
    { id: MeasureUnitTypes.KSH, value: 'KSH' },
    { id: MeasureUnitTypes.KUR, value: 'KUR' },
    { id: MeasureUnitTypes.KW1, value: 'KW1' },
    { id: MeasureUnitTypes.LHC, value: 'LHC' },
    { id: MeasureUnitTypes.LPA, value: 'LPA' },
    { id: MeasureUnitTypes.LTR, value: 'LTR' },
    { id: MeasureUnitTypes.MIL, value: 'MIL' },
    { id: MeasureUnitTypes.MTK, value: 'MTK' },
    { id: MeasureUnitTypes.MTQ, value: 'MTQ' },
    { id: MeasureUnitTypes.MTR, value: 'MTR' },
    { id: MeasureUnitTypes.MWH, value: 'MWH' },
    { id: MeasureUnitTypes.NAR, value: 'NAR' },
    { id: MeasureUnitTypes.NCL, value: 'NCL' },
    { id: MeasureUnitTypes.NPR, value: 'NPR' },
    { id: MeasureUnitTypes.P1, value: 'P1' },
    { id: MeasureUnitTypes.RET, value: 'RET' },
    { id: MeasureUnitTypes.TCE, value: 'TCE' },
    { id: MeasureUnitTypes.TJO, value: 'TJO' },
    { id: MeasureUnitTypes.TNE, value: 'TNE' },
    { id: MeasureUnitTypes.MGM, value: 'MGM' },
    { id: MeasureUnitTypes.MLT, value: 'MLT' },
    { id: MeasureUnitTypes.MCG, value: 'MCG' },
    { id: MeasureUnitTypes.MCL, value: 'MCL' },
] as const;
