import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const PlaceOfLoadingCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('placeOfLoading')}>
            <FormCardContainer>
                <FormInput
                    maxLength={17}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('placeOfLoading', props)}
                    refNumber="5.21"
                    label={t('placeOfLoading')}
                    tooltip={getCdsTooltipsByRefNumberAndField('5.21', t('placeOfLoading'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default PlaceOfLoadingCard;
