import { ReactElement } from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

const { DeviceReaderContainer } = {
    DeviceReaderContainer: styled.div`
        border: 1px solid ${colors.grey};
        width: 100%;
        border-radius: 8px;
        height: 25rem;
    `,
};

const DeviceReaderView = (): ReactElement => {
    return <DeviceReaderContainer></DeviceReaderContainer>;
};

export default DeviceReaderView;
