import { DatePicker } from 'antd';
import styled from 'styled-components';

export const AStyledDatePicker = styled(DatePicker)<{ condensed?: boolean }>`
    width: 100%;
    height: 3.2rem;
    border-radius: ${({ condensed }) => (condensed ? '4px' : '2px')};
    border: ${({ condensed }) => (condensed ? '1px solid #cecece' : 'hsv(0, 0, 85%)')};
`;

export const DatePickerDiv = styled.div`
    width: 100%;
    display: inline-flex;
`;
