import { FormikProvider, useFormik } from 'formik';
import { FC, useMemo } from 'react';
import ConsignmentCard from './cards/ConsignmentCard';
import BorderTransportMeansCard from './cards/BorderTransportMeansCard';
import FirstEntryCard from './cards/FirstEntryCard';
import GoodsShipmentCard from './cards/GoodsShipmentCard';
import MasterDetailsCard from './cards/MasterDetailsCard';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { useTranslation } from 'react-i18next';
import {
    sealsIdentity,
    subsequentEntryCustomsOffice,
} from 'views/declarations/ireland/ens/validation/irelandEnsValidation';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import addPathPrefix from 'utils/addPathPrefix';
import { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import ItineraryCard from './cards/ItineraryCard';

interface Props {
    formik: ReturnType<typeof useFormik>; // FIXME use correct type
    toggleHelp?: (refNumber: string | number) => void;
    expandAll?: boolean;
    viewOnly?: boolean;
}

const IrelandEnsForm: FC<Props> = ({ formik, toggleHelp, expandAll, viewOnly }) => {
    const { t } = useTranslation('form');

    const totalCards = useMemo(() => 14, []);

    return (
        <FormikProvider value={formik}>
            <MasterDetailsCard
                keyCard={`master-details-card`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                errors={formik.errors}
                cardTotal={totalCards}
                viewOnly={viewOnly}
                cardNumber={1}
            />

            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'agent',
                        header: 'Declarant',
                        refNumber: undefined,
                        eoriRequired: true,
                    },
                    {
                        path: 'carrier',
                        header: 'Entry carrier',
                        refNumber: undefined,
                    },
                    {
                        path: 'goodsShipment.consignor',
                        header: 'Consignor',
                        refNumber: '2.0',
                    },
                    {
                        path: 'goodsShipment.consignee',
                        header: 'Consignee',
                        refNumber: '8.0',
                    },
                    {
                        path: 'goodsShipment.notifyParty',
                        header: 'Notify party',
                        refNumber: undefined,
                    },
                    {
                        path: 'personLodgingTheSummaryDeclaration',
                        header: 'Representative',
                        refNumber: '14.0',
                    },
                ]}
                paths={{
                    address: {
                        city: 'city',
                        country: 'country',
                        name: 'name',
                        postCode: 'postCode',
                        streetAndNumber: 'streetAndNumber',
                    },
                    eori: 'eori',
                }}
                labels={{
                    eori: 'tin',
                }}
                viewOnly={viewOnly}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={2}
                keyCard={`parties-card`}
                expandAll={expandAll}
                condensed
            />

            <GoodsShipmentCard
                keyCard={`goodshipment-card`}
                viewOnly={viewOnly}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                errors={formik.errors}
                cardTotal={totalCards}
                cardNumber={8}
            />

            <FirstEntryCard
                keyCard={`first-entry-card`}
                viewOnly={viewOnly}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                errors={formik.errors}
                cardTotal={totalCards}
                cardNumber={9}
            />

            <MultipleItemsCard
                title={t('customsOfficeOfSubsequentEntry')}
                keyCard={`subsequent-entry-card`}
                viewOnly={viewOnly}
                path="goodsShipment.entryCustomsOffice"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalCards}
                cardNumber={10}
                expandAll={expandAll}
                initialValues={{ referenceNumber: '' }}
                validationSchema={subsequentEntryCustomsOffice()}
                list={(_package) => [{ field: t('referenceNumber'), value: _package.referenceNumber }]}
                condensed
                form={(props, path) => (
                    <FormCardContainer oneColumn>
                        <FormInput
                            required
                            maxLength={8}
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), props)}
                            label={t('referenceNumber')}
                            tooltip={t('defaultTooltip')}
                            condensed
                        />
                    </FormCardContainer>
                )}
            />

            <BorderTransportMeansCard
                keyCard={`border-transport-means-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                errors={formik.errors}
                cardTotal={totalCards}
                cardNumber={11}
            />

            <ConsignmentCard
                keyCard={`consignment-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                errors={formik.errors}
                cardTotal={totalCards}
                cardNumber={12}
            />

            <ItineraryCard />

            <MultipleItemsCard
                title={t('Seals')}
                keyCard={`seals-card`}
                viewOnly={viewOnly}
                path="goodsShipment.consignment.transportEquipment"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalCards}
                cardNumber={14}
                expandAll={expandAll}
                initialValues={{ sealsIdentity: '' }}
                validationSchema={sealsIdentity()}
                list={(_package) => [{ field: t('sealsIdentity'), value: _package.sealsIdentity }]}
                condensed
                form={(props, path) => (
                    <FormCardContainer oneColumn>
                        <FormInput
                            required
                            maxLength={20}
                            {...getFormikProps(addPathPrefix(path, 'sealsIdentity'), props)}
                            label={t('sealsIds')}
                            tooltip={t('defaultTooltip')}
                            condensed
                        />
                    </FormCardContainer>
                )}
            />
        </FormikProvider>
    );
};
export default IrelandEnsForm;
