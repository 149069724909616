import Table from 'components/ui/base/table/Table';
import useDeclarations from 'hooks/useDeclarations';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { HistoryUtils } from './historyUtils';
import { HistoryViewContainer, HistoryStatusContainer } from './components/styledComponents';
import { H5 } from 'components/ui/base/typography';
import { useRequestPromise } from 'hooks/useRequest';
import { orderBy } from 'lodash';
import { listIndividuals } from 'store/individuals/client';

type Column = { date: string; user: string; action: string; description?: string; notificationType?: string };

const DeclarationHistoryTable: FC<{}> = () => {
    const { declarationId } = useParams();
    const { declarationHistory, declaration } = useDeclarations({ declarationId });

    const { data: users } = useRequestPromise(async () => {
        const allUsers = await listIndividuals({ size: 9999 });
        const userMap = new Map();
        for (const user of allUsers.list) {
            userMap.set(user.id, user);
        }
        return userMap;
    });

    const currentKeys = useMemo(() => {
        let keys: Column = {} as Column;

        if (
            declaration?.irelandImportDeclaration ||
            declaration?.irelandH7ImportDeclaration ||
            declaration?.entrySummaryDeclaration
        ) {
            keys = {
                date: 'notificationDate',
                action: 'type',
                description: 'message',
                user: 'user',
            };
        } else if (declaration?.cdsExportDeclaration) {
            keys = {
                date: 'issueDateTime',
                action: 'statusMessage',
                notificationType: 'functionCode',
                description: 'message',
                user: 'user',
            };
        }

        return keys;
    }, [
        declaration?.cdsExportDeclaration,
        declaration?.entrySummaryDeclaration,
        declaration?.irelandH7ImportDeclaration,
        declaration?.irelandImportDeclaration,
    ]);

    const history = useMemo(() => {
        const statusHistory =
            declaration?.irelandImportDeclaration?.notifications ??
            declaration?.irelandH7ImportDeclaration?.notifications ??
            declaration?.entrySummaryDeclaration?.notifications ??
            declaration?.cdsExportDeclaration?.notifications ??
            [];

        const _formHistory = declarationHistory ?? [];
        let formHistory: any[] = [];
        formHistory = _formHistory.map((h) => {
            const user = users?.get(h.declaration.individualId)?.fullName;
            return { user, [currentKeys.action]: h.type, [currentKeys.date]: h.createdAt };
        }) as Column[];

        const history = [...statusHistory.map((h) => ({ ...h, user: 'Customs' })), ...formHistory];

        return orderBy(history, currentKeys.date, 'desc');
        // return statusHistory;
    }, [
        currentKeys,
        declaration?.cdsExportDeclaration?.notifications,
        declaration?.entrySummaryDeclaration?.notifications,
        declaration?.irelandH7ImportDeclaration?.notifications,
        declaration?.irelandImportDeclaration?.notifications,
        declarationHistory,
        users,
    ]);

    const historyColumns = useMemo(() => HistoryUtils.columns.statusHistory(currentKeys), [currentKeys]);

    return (
        <HistoryViewContainer>
            <HistoryStatusContainer>
                <H5>Status History</H5>
                <Table pagination={false} columns={historyColumns} dataSource={history} />
            </HistoryStatusContainer>
        </HistoryViewContainer>
    );
};

export default DeclarationHistoryTable;
