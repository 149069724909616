export const identificationOfLocationCodeList = [
    { id: 'ABDABDABD', value: '' },
    { id: 'ABDABDABM', value: '' },
    { id: 'ABDABDLUE', value: '' },
    { id: 'ABDABDSEI', value: '' },
    { id: 'ACMMANXXX', value: '' },
    { id: 'ADGBHXMCL', value: '' },
    { id: 'AGNLHRRRL', value: '' },
    { id: 'ANFDOGWSP', value: '' },
    { id: 'ANTBFSIFX', value: '' },
    { id: 'ASFLHRIMI', value: '' },
    { id: 'ASFLHRXCZ', value: '' },
    { id: 'AULINVEWE', value: '' },
    { id: 'AVOAVOAVO', value: '' },
    { id: 'AVOAVOBAC', value: '' },
    { id: 'AVOAVOCSX', value: '' },
    { id: 'AVOAVODTA', value: '' },
    { id: 'AVOBRSAEX', value: '' },
    { id: 'AVOBRSCFX', value: '' },
    { id: 'AVOBRSEXX', value: '' },
    { id: 'AVOBRSFSX', value: '' },
    { id: 'AVOBRSGEX', value: '' },
    { id: 'AVOBRSGWX', value: '' },
    { id: 'AVOBRSMCX', value: '' },
    { id: 'AVOBRSXAD', value: '' },
    { id: 'AYRAYRAYR', value: '' },
    { id: 'AYSTHPHZN1', value: '' },
    { id: 'BADCDFBAD', value: '' },
    { id: 'BADCDFVPK', value: '' },
    { id: 'BASLONGEO1', value: '' },
    { id: 'BASLONPPF1', value: '' },
    { id: 'BASLONPXN1', value: '' },
    { id: 'BASLONPXX1', value: '' },
    { id: 'BDNNCLNPX', value: '' },
    { id: 'BELBELBEL', value: '' },
    { id: 'BELBELBTC1', value: '' },
    { id: 'BELBELBTM2', value: '' },
    { id: 'BELBELCAF1', value: '' },
    { id: 'BELBELHLX', value: '' },
    { id: 'BELBELHYX', value: '' },
    { id: 'BELBELLCC', value: '' },
    { id: 'BELBELOB4', value: '' },
    { id: 'BELBELUML', value: '' },
    { id: 'BELBELUPS1', value: '' },
    { id: 'BELBELVKT2', value: '' },
    { id: 'BELBELVLQ1', value: '' },
    { id: 'BELBELVRB1', value: '' },
    { id: 'BELBFSAEX', value: '' },
    { id: 'BELBFSBEL', value: '' },
    { id: 'BELBFSCRX', value: '' },
    { id: 'BELBFSCUX', value: '' },
    { id: 'BELBFSFDS', value: '' },
    { id: 'BELBFSHWS', value: '' },
    { id: 'BELBFSSLS', value: '' },
    { id: 'BFBGRGP40', value: '' },
    { id: 'BFBGRGSHL', value: '' },
    { id: 'BFYSWABFY', value: '' },
    { id: 'BGKLHRRRL', value: '' },
    { id: 'BHDBHDMWS', value: '' },
    { id: 'BHKBHKBHK', value: '' },
    { id: 'BHMBHXAEX', value: '' },
    { id: 'BHMBHXBHM', value: '' },
    { id: 'BHMBHXBLE1', value: '' },
    { id: 'BHMBHXFDS', value: '' },
    { id: 'BHMBHXLPX', value: '' },
    { id: 'BHMBHXPLS', value: '' },
    { id: 'BHMBHXSLS', value: '' },
    { id: 'BHVGRGWYQ', value: '' },
    { id: 'BHWMIDBRO', value: '' },
    { id: 'BHWMIDCOI', value: '' },
    { id: 'BIDWATBID', value: '' },
    { id: 'BIFBARBDD', value: '' },
    { id: 'BIFBARBIF', value: '' },
    { id: 'BITLBAFUT1', value: '' },
    { id: 'BKGLONBDW', value: '' },
    { id: 'BKGLONKWS1', value: '' },
    { id: 'BKGLONPIN', value: '' },
    { id: 'BKGLONPYP1', value: '' },
    { id: 'BKGLONPZL1', value: '' },
    { id: 'BKGLONPZX1', value: '' },
    { id: 'BKGLONSVW', value: '' },
    { id: 'BLKBLKBLK', value: '' },
    { id: 'BLSCOLBOW', value: '' },
    { id: 'BLYBLYBLY', value: '' },
    { id: 'BLYBLYRIO', value: '' },
    { id: 'BMYLBAGBL', value: '' },
    { id: 'BOCFXTRBF', value: '' },
    { id: 'BOSBOSBOS', value: '' },
    { id: 'BOYSTNWAC', value: '' },
    { id: 'BQHBQHBQH', value: '' },
    { id: 'BRAFXTAMS', value: '' },
    { id: 'BRAFXTLOX', value: '' },
    { id: 'BRAFXTSHW', value: '' },
    { id: 'BRFLBASUR1', value: '' },
    { id: 'BRKLIVBRK', value: '' },
    { id: 'BRSBRSBRS', value: '' },
    { id: 'BRVHULBRV', value: '' },
    { id: 'BSHNHVGBL', value: '' },
    { id: 'BSTLSAXXX', value: '' },
    { id: 'BTAEMAUPI1', value: '' },
    { id: 'BTLGRGBTL', value: '' },
    { id: 'BUCFRBBUC', value: '' },
    { id: 'BWKBLYBWK', value: '' },
    { id: 'BZZBZNRAF', value: '' },
    { id: 'CADEMAEXX', value: '' },
    { id: 'CADEMANPX', value: '' },
    { id: 'CADEMAPZX', value: '' },
    { id: 'CANLONCAL', value: '' },
    { id: 'CANLONHHW', value: '' },
    { id: 'CBRLHRAGX', value: '' },
    { id: 'CBRLHRCOX', value: '' },
    { id: 'CBRLHRCPR2', value: '' },
    { id: 'CBRLHRDHXCUK', value: '' },
    { id: 'CBRLHRDHXDHL', value: '' },
    { id: 'CBRLHRDTX', value: '' },
    { id: 'CBRLHRFRX', value: '' },
    { id: 'CBRLHRGGX', value: '' },
    { id: 'CBRLHRHEX', value: '' },
    { id: 'CBRLHRHFX', value: '' },
    { id: 'CBRLHRJMX', value: '' },
    { id: 'CBRLHRKWX', value: '' },
    { id: 'CBRLHRLNX', value: '' },
    { id: 'CBRLHRMJX', value: '' },
    { id: 'CBRLHROCS2', value: '' },
    { id: 'CBRLHROCX', value: '' },
    { id: 'CBRLHRPYX', value: '' },
    { id: 'CBRLHRQUX', value: '' },
    { id: 'CBRLHRQYX', value: '' },
    { id: 'CBRLHRRBX', value: '' },
    { id: 'CBRLHRRNX', value: '' },
    { id: 'CBRLHRRYX', value: '' },
    { id: 'CBRLHRSJX', value: '' },
    { id: 'CBRLHRSNX', value: '' },
    { id: 'CBRLHRUWX', value: '' },
    { id: 'CBRLHRWFX', value: '' },
    { id: 'CBRLHRXAD', value: '' },
    { id: 'CBRLHRXAF', value: '' },
    { id: 'CBRLHRXAJ', value: '' },
    { id: 'CBRLHRXBR', value: '' },
    { id: 'CBRLHRXBU', value: '' },
    { id: 'CBRLHRXCA', value: '' },
    { id: 'CBRLHRXCR', value: '' },
    { id: 'CBRLHRXDD', value: '' },
    { id: 'CBRLHRXXD', value: '' },
    { id: 'CBTARGNPJ', value: '' },
    { id: 'CBTARGONQ', value: '' },
    { id: 'CDFCDFCDF', value: '' },
    { id: 'CEHMANMPW1', value: '' },
    { id: 'CEHMANPPX', value: '' },
    { id: 'CFDFXTWIE', value: '' },
    { id: 'CFGBFSIQX', value: '' },
    { id: 'CG9LHRGTX', value: '' },
    { id: 'CG9LHRKPX', value: '' },
    { id: 'CGPBELCGP', value: '' },
    { id: 'CHEMOSCHE', value: '' },
    { id: 'CLDGLAGDT', value: '' },
    { id: 'CLDGLAXAD', value: '' },
    { id: 'CLLBHXBDT1', value: '' },
    { id: 'CLLBHXEXX', value: '' },
    { id: 'CLLBHXXAD', value: '' },
    { id: 'CLRCLRCLR', value: '' },
    { id: 'CLTAVOGBL', value: '' },
    { id: 'CLYGLWRTH', value: '' },
    { id: 'CMGCBGCMG', value: '' },
    { id: 'CNUARGCNU', value: '' },
    { id: 'CORINVCOR', value: '' },
    { id: 'COTGLAPFL', value: '' },
    { id: 'COUGRKCOU', value: '' },
    { id: 'COWCOWCOW', value: '' },
    { id: 'CTMMEDNO1', value: '' },
    { id: 'CTMMEDNO2', value: '' },
    { id: 'CTMMEDTSS', value: '' },
    { id: 'CVTBHXFZX', value: '' },
    { id: 'CVTCVTCVT', value: '' },
    { id: 'CVTCVTPRX', value: '' },
    { id: 'CVTCVTRMG', value: '' },
    { id: 'CWMCDFFOX', value: '' },
    { id: 'CWYLGWPIX', value: '' },
    { id: 'CWYLGWXBB', value: '' },
    { id: 'CWYLGWXCS', value: '' },
    { id: 'CYYHLDCYY', value: '' },
    { id: 'DAGLONARC', value: '' },
    { id: 'DAGLONCMX', value: '' },
    { id: 'DAGLONDTJ', value: '' },
    { id: 'DAGLONEJ9', value: '' },
    { id: 'DAGLONFRD', value: '' },
    { id: 'DAGLONPXP', value: '' },
    { id: 'DAGLONPZO1', value: '' },
    { id: 'DENMANNNX', value: '' },
    { id: 'DFDLGPXAD', value: '' },
    { id: 'DFDLONDFD', value: '' },
    { id: 'DFDTEPDFD', value: '' },
    { id: 'DFDTHPBON', value: '' },
    { id: 'DFDTHPEUS', value: '' },
    { id: 'DFDTHPJWP1', value: '' },
    { id: 'DFDTHPPZH1', value: '' },
    { id: 'DFDTHPTNT3', value: '' },
    { id: 'DNUARGHLM', value: '' },
    { id: 'DONDSARQS', value: '' },
    { id: 'DRDBHXEGX', value: '' },
    { id: 'DRLMMEDRL', value: '' },
    { id: 'DVRDOVAPC', value: '' },
    { id: 'DVRDOVDOG', value: '' },
    { id: 'DVRDOVDVR', value: '' },
    { id: 'DWYLBAUZX', value: '' },
    { id: 'DYCABZAEX', value: '' },
    { id: 'DYCABZBOND', value: '' },
    { id: 'DYCABZBSTW', value: '' },
    { id: 'DYCABZBWE', value: '' },
    { id: 'DYCABZCHC', value: '' },
    { id: 'DYCABZDAX', value: '' },
    { id: 'DYCABZDYC', value: '' },
    { id: 'DYCABZEGX', value: '' },
    { id: 'DYCABZEPX', value: '' },
    { id: 'DYCABZEXX', value: '' },
    { id: 'DYCABZJEX', value: '' },
    { id: 'DYCABZKNX', value: '' },
    { id: 'DYCABZLVQ', value: '' },
    { id: 'DYCABZPSA', value: '' },
    { id: 'DYCABZSLS', value: '' },
    { id: 'EATSOUEAT', value: '' },
    { id: 'EATSOUSVIP', value: '' },
    { id: 'EATSTNOAS1', value: '' },
    { id: 'EATSTNPBN1', value: '' },
    { id: 'EATSTNSAB1', value: '' },
    { id: 'EATSTNSCS1', value: '' },
    { id: 'EATSTNSFM1', value: '' },
    { id: 'EATSTNUTI1', value: '' },
    { id: 'EDIEDIBAC', value: '' },
    { id: 'EDIEDIBWS', value: '' },
    { id: 'EDIEDIEDI', value: '' },
    { id: 'EDIEDIEDS', value: '' },
    { id: 'EDIEDITNT', value: '' },
    { id: 'EDIEDITRX', value: '' },
    { id: 'EGHLHRYUX', value: '' },
    { id: 'EKIGLAEGX', value: '' },
    { id: 'ELLELLELL', value: '' },
    { id: 'ELLELLEPT', value: '' },
    { id: 'EMAEMABIP', value: '' },
    { id: 'EMAEMADHS', value: '' },
    { id: 'EMAEMAEMA', value: '' },
    { id: 'EMAEMASLS', value: '' },
    { id: 'EMAEMATNT', value: '' },
    { id: 'DUNDUNDUN', value: '' },
    { id: 'ENFLSACWG', value: '' },
    { id: 'ENFLSADOL', value: '' },
    { id: 'ERIMEDEOW', value: '' },
    { id: 'ESHESHESH', value: '' },
    { id: 'ESHSHOEGY', value: '' },
    { id: 'ESHSHOESH', value: '' },
    { id: 'ESHSHOKWW', value: '' },
    { id: 'EVTINVEVT', value: '' },
    { id: 'EWLFXTHHE', value: '' },
    { id: 'EXEEXTEXE', value: '' },
    { id: 'FALFALFAL', value: '' },
    { id: 'FASGRKFAS', value: '' },
    { id: 'FBOFABFBO', value: '' },
    { id: 'FBOLHRWAC', value: '' },
    { id: 'FDYBHXHEX', value: '' },
    { id: 'FELFLTFEL', value: '' },
    { id: 'FEMLHRADX', value: '' },
    { id: 'FEMLHRBRX', value: '' },
    { id: 'FEMLHRBTX', value: '' },
    { id: 'FEMLHRCFX', value: '' },
    { id: 'FEMLHRCIX', value: '' },
    { id: 'FEMLHRCKX', value: '' },
    { id: 'FEMLHRCTG2', value: '' },
    { id: 'FEMLHRCTX', value: '' },
    { id: 'FEMLHRCYX', value: '' },
    { id: 'FEMLHRDAX', value: '' },
    { id: 'FEMLHRERX', value: '' },
    { id: 'FEMLHREXX', value: '' },
    { id: 'FEMLHRFDX', value: '' },
    { id: 'FEMLHRFOX', value: '' },
    { id: 'FEMLHRFTX', value: '' },
    { id: 'FEMLHRFWX', value: '' },
    { id: 'FEMLHRGAX', value: '' },
    { id: 'FEMLHRHMX', value: '' },
    { id: 'FEMLHRHWX', value: '' },
    { id: 'FEMLHRJEX', value: '' },
    { id: 'FEMLHRJGX', value: '' },
    { id: 'FEMLHRJZX', value: '' },
    { id: 'FEMLHRKNX', value: '' },
    { id: 'FEMLHRKRX', value: '' },
    { id: 'FEMLHRLPX', value: '' },
    { id: 'FEMLHRMQX', value: '' },
    { id: 'FEMLHRNNX', value: '' },
    { id: 'FEMLHRNSX', value: '' },
    { id: 'FEMLHRPDX', value: '' },
    { id: 'FEMLHRPPX', value: '' },
    { id: 'FEMLHRSKX', value: '' },
    { id: 'FEMLHRWLX', value: '' },
    { id: 'FEMLHRWTX', value: '' },
    { id: 'FEMLHRXAC', value: '' },
    { id: 'FEMLHRXAL', value: '' },
    { id: 'FEMLHRXBC', value: '' },
    { id: 'FEMLHRXBF', value: '' },
    { id: 'FEMLHRXBK', value: '' },
    { id: 'FEMLHRXCB', value: '' },
    { id: 'FEMLHRXCQ', value: '' },
    { id: 'FEMLHRXCY', value: '' },
    { id: 'FEMLHRXDA', value: '' },
    { id: 'FFOFFDFFO', value: '' },
    { id: 'FHMSTNSFL1', value: '' },
    { id: 'FISFISFIS', value: '' },
    { id: 'FLEFLEFLE', value: '' },
    { id: 'FLHKWLFLH', value: '' },
    { id: 'FLWTRTFLW', value: '' },
    { id: 'FNWMANMSL1', value: '' },
    { id: 'FNWMANTSX', value: '' },
    { id: 'FOYFOYFOY', value: '' },
    { id: 'FRBFRBFRB', value: '' },
    { id: 'FXTFXTDFE', value: '' },
    { id: 'FXTFXTFXT', value: '' },
    { id: 'FXTFXTGBE', value: '' },
    { id: 'FXTFXTGMF', value: '' },
    { id: 'FXTFXTIRE', value: '' },
    { id: 'FXTFXTLDF', value: '' },
    { id: 'FXTFXTMSP', value: '' },
    { id: 'FXTFXTPTV', value: '' },
    { id: 'FXTFXTSCE', value: '' },
    { id: 'FXTFXTSEE', value: '' },
    { id: 'GADGRGFOT', value: '' },
    { id: 'GADGRGOFD', value: '' },
    { id: 'GARINVGAR', value: '' },
    { id: 'GATNCLFZX', value: '' },
    { id: 'GFRLHRSMS', value: '' },
    { id: 'GFRLHRWYX', value: '' },
    { id: 'GLDLIVGLD', value: '' },
    { id: 'GLWGLAAEX', value: '' },
    { id: 'GLWGLABIP', value: '' },
    { id: 'GLWGLABWS', value: '' },
    { id: 'GLWGLADTX', value: '' },
    { id: 'GLWGLAEXX', value: '' },
    { id: 'GLWGLAFDS', value: '' },
    { id: 'GLWGLAGLW', value: '' },
    { id: 'GLWGLAGPS', value: '' },
    { id: 'GLWGLAKNX', value: '' },
    { id: 'GLWGLANPX', value: '' },
    { id: 'GLWGLAPZS', value: '' },
    { id: 'GLWGLASLS', value: '' },
    { id: 'GLWGLWBCW', value: '' },
    { id: 'GLWGLWGLW', value: '' },
    { id: 'GLWGLWGRB', value: '' },
    { id: 'GLWGLWIGD', value: '' },
    { id: 'GNWLONFCT', value: '' },
    { id: 'GNWLONVIC', value: '' },
    { id: 'GOAHRHGOA', value: '' },
    { id: 'GOOGOOGOO', value: '' },
    { id: 'GOSPTMOFD', value: '' },
    { id: 'GRGGRGDMC', value: '' },
    { id: 'GRGGRGE12', value: '' },
    { id: 'GRGGRGGRG', value: '' },
    { id: 'GRGGRGINR', value: '' },
    { id: 'GRGGRGLPG', value: '' },
    { id: 'GRGHPTHPT', value: '' },
    { id: 'GRKGRKGRK', value: '' },
    { id: 'GRKGRKSFG', value: '' },
    { id: 'GSAOBAGSA', value: '' },
    { id: 'GSYGRIGSY', value: '' },
    { id: 'GTMIMMFNW', value: '' },
    { id: 'GTNLIVGTN', value: '' },
    { id: 'GTYGTYGGT', value: '' },
    { id: 'GTYGTYGTY', value: '' },
    { id: 'GTYGTYKGF', value: '' },
    { id: 'GUSTRTGRO', value: '' },
    { id: 'GUSTRTNHW', value: '' },
    { id: 'GUWTRTGUW', value: '' },
    { id: 'GVSMEDCMT', value: '' },
    { id: 'GVSTHPCCK', value: '' },
    { id: 'GYSLONGOJ', value: '' },
    { id: 'GYSLONGTW', value: '' },
    { id: 'GYSLONNST', value: '' },
    { id: 'GYSLONPXW1', value: '' },
    { id: 'HAFBHXGBL', value: '' },
    { id: 'HDDGOOHDD', value: '' },
    { id: 'HESLHRBPX', value: '' },
    { id: 'HESLHRCTN2', value: '' },
    { id: 'HESLHRELX', value: '' },
    { id: 'HESLHRLJX', value: '' },
    { id: 'HESLHRMHX', value: '' },
    { id: 'HESLHRTNX', value: '' },
    { id: 'HESLHRTQX', value: '' },
    { id: 'HGRLTNBON', value: '' },
    { id: 'HHELTNJMI', value: '' },
    { id: 'HLYHLDHLY', value: '' },
    { id: 'HMILHRAEX', value: '' },
    { id: 'HMILHRHIX', value: '' },
    { id: 'HMILHRMBX', value: '' },
    { id: 'HMILHRNPX', value: '' },
    { id: 'HMILHRPYT1', value: '' },
    { id: 'HMILHRWIX', value: '' },
    { id: 'HMILHRXBW', value: '' },
    { id: 'HMILHRXDB', value: '' },
    { id: 'HMWLHRBJX', value: '' },
    { id: 'HMWLHRETX', value: '' },
    { id: 'HMWLHRGJX', value: '' },
    { id: 'HMWLHRGYX', value: '' },
    { id: 'HMWLHRSIX', value: '' },
    { id: 'HMWLHRWRX', value: '' },
    { id: 'HOULHRUNX', value: '' },
    { id: 'HOULHRXAA', value: '' },
    { id: 'HOULHRXBQ', value: '' },
    { id: 'HOULHRXBT', value: '' },
    { id: 'HOULHRXBV', value: '' },
    { id: 'HOULHRXCU', value: '' },
    { id: 'HOULHRYTX', value: '' },
    { id: 'HRTMWHHRT', value: '' },
    { id: 'HRWHRHHRW', value: '' },
    { id: 'HRWHRHNYW', value: '' },
    { id: 'HTHDOGSPE', value: '' },
    { id: 'HTHDOGWSP', value: '' },
    { id: 'HTOLHRGBX', value: '' },
    { id: 'HTOLHRWEX', value: '' },
    { id: 'HTPHTPHTP', value: '' },
    { id: 'HTPHTPSPQ', value: '' },
    { id: 'HULHULHUL', value: '' },
    { id: 'HYMHEYHYM', value: '' },
    { id: 'HYNLIVJMI', value: '' },
    { id: 'HYNLIVLLF', value: '' },
    { id: 'HYNLIVLSF', value: '' },
    { id: 'IMMIMMESI', value: '' },
    { id: 'IMMIMMIMM', value: '' },
    { id: 'INKGRGENT', value: '' },
    { id: 'INKGRGINK', value: '' },
    { id: 'IOGMEDBPO', value: '' },
    { id: 'IOGMEDLNG', value: '' },
    { id: 'IOGMEDNSJ', value: '' },
    { id: 'IPSFXTAME', value: '' },
    { id: 'IPSFXTBIE', value: '' },
    { id: 'IPSFXTCFE', value: '' },
    { id: 'IPSFXTCHI', value: '' },
    { id: 'IPSFXTEFE', value: '' },
    { id: 'IPSFXTGMA', value: '' },
    { id: 'IPSFXTGSE', value: '' },
    { id: 'IPSFXTHFE', value: '' },
    { id: 'IPSFXTKKE', value: '' },
    { id: 'IPSFXTLSE', value: '' },
    { id: 'IPSFXTPTE', value: '' },
    { id: 'IPSFXTSPE', value: '' },
    { id: 'IPSFXTSTL', value: '' },
    { id: 'IPSFXTTVE', value: '' },
    { id: 'IPSFXTWER', value: '' },
    { id: 'IPSIPSIPS', value: '' },
    { id: 'IPSIPSOTE', value: '' },
    { id: 'IRLMANEGX', value: '' },
    { id: 'IVELHRITX', value: '' },
    { id: 'IVGIVGAMP', value: '' },
    { id: 'IVGIVGIVG', value: '' },
    { id: 'KBEKLBKBE', value: '' },
    { id: 'KEATRTKEA', value: '' },
    { id: 'KKDGRGKKD', value: '' },
    { id: 'KLNKLNBNT', value: '' },
    { id: 'KLNKLNKLN', value: '' },
    { id: 'KMGHUYKMG', value: '' },
    { id: 'KMGHUYNGS', value: '' },
    { id: 'KNWLIVLSK', value: '' },
    { id: 'KPSBELKPS', value: '' },
    { id: 'KRYBHXUXI1', value: '' },
    { id: 'KSUIMMAPT', value: '' },
    { id: 'KSUIMMDSI', value: '' },
    { id: 'EMAEMAUZS', value: '' },
    { id: 'LAILONPXQ1', value: '' },
    { id: 'LAILONRED2', value: '' },
    { id: 'LAKLKHLAK', value: '' },
    { id: 'LALLHRPMX', value: '' },
    { id: 'LALLHRRMGWDC', value: '' },
    { id: 'LANBARWAC', value: '' },
    { id: 'LANLHRRMGILC', value: '' },
    { id: 'LARBELLAR', value: '' },
    { id: 'LBALBACWG', value: '' },
    { id: 'LCGARGLCG', value: '' },
    { id: 'LCTLCYEAS', value: '' },
    { id: 'LCTLCYLCT', value: '' },
    { id: 'LEILEILEI', value: '' },
    { id: 'LERLERLER', value: '' },
    { id: 'LGPLGPLGP', value: '' },
    { id: 'LGSGRKLGS', value: '' },
    { id: 'LGWLGWBIP', value: '' },
    { id: 'LGWLGWGWK1', value: '' },
    { id: 'LGWLGWLGW', value: '' },
    { id: 'LGWLGWPZS', value: '' },
    { id: 'LGWLGWVWS', value: '' },
    { id: 'LHFBHXXCR', value: '' },
    { id: 'LHRLHRAAS', value: '' },
    { id: 'LHRLHRAFS', value: '' },
    { id: 'LHRLHRANS', value: '' },
    { id: 'INVINVINV', value: '' },
    { id: 'LHRLHRBIP', value: '' },
    { id: 'LHRLHRCFL1', value: '' },
    { id: 'LHRLHRCIS', value: '' },
    { id: 'LHRLHRCPS', value: '' },
    { id: 'LHRLHRDHS', value: '' },
    { id: 'LHRLHREUB', value: '' },
    { id: 'LHRLHRJLS', value: '' },
    { id: 'LHRLHRJZS', value: '' },
    { id: 'LHRLHRLHR', value: '' },
    { id: 'LHRLHRNAS', value: '' },
    { id: 'LHRLHRNBS', value: '' },
    { id: 'LHRLHRNCS', value: '' },
    { id: 'LHRLHRNDS', value: '' },
    { id: 'LHRLHRNHS', value: '' },
    { id: 'LHRLHRPVS', value: '' },
    { id: 'LHRLHRRMG', value: '' },
    { id: 'LHRLHRSLS', value: '' },
    { id: 'LHRLHRUAS', value: '' },
    { id: 'LHRLHRWAS', value: '' },
    { id: 'LHRLHRWXS', value: '' },
    { id: 'LHRLHRXBN', value: '' },
    { id: 'LHSGRKLHS', value: '' },
    { id: 'LIOGLAGEX', value: '' },
    { id: 'LITSHOLIT', value: '' },
    { id: 'LIVLIVCRU', value: '' },
    { id: 'LIVLIVJKE', value: '' },
    { id: 'LIVLIVLIV', value: '' },
    { id: 'LIVLIVLWF', value: '' },
    { id: 'LIVLIVMFE', value: '' },
    { id: 'LIVLIVTTE', value: '' },
    { id: 'LONLONTMP', value: '' },
    { id: 'LOVLCVLOV', value: '' },
    { id: 'LOWLOWLNQ', value: '' },
    { id: 'LOWLOWLOW', value: '' },
    { id: 'LSILSILSI', value: '' },
    { id: 'LTPTHPLTP', value: '' },
    { id: 'LUTLTNLCS', value: '' },
    { id: 'LUTLTNLPX', value: '' },
    { id: 'LUTLTNLUT', value: '' },
    { id: 'LUTLTNQAX', value: '' },
    { id: 'LUTLTNXBG', value: '' },
    { id: 'LWMLBARED1', value: '' },
    { id: 'LWTLONEGA1', value: '' },
    { id: 'LWTLONJLR1', value: '' },
    { id: 'LYELYHLYE', value: '' },
    { id: 'LYNDOGLAS', value: '' },
    { id: 'LYNDOGSSW', value: '' },
    { id: 'LYOLCYMNX', value: '' },
    { id: 'LYXLYDLYX', value: '' },
    { id: 'MANMANXAD', value: '' },
    { id: 'MANMANXBB', value: '' },
    { id: 'MANMANXBJ', value: '' },
    { id: 'MANMANXBM', value: '' },
    { id: 'MAWSTNSMC', value: '' },
    { id: 'ME5LGWBMI', value: '' },
    { id: 'MIDMIDCCW', value: '' },
    { id: 'MIDMIDDWW', value: '' },
    { id: 'MIDMIDLVE', value: '' },
    { id: 'MIDMIDNMB', value: '' },
    { id: 'MINBHXKNX', value: '' },
    { id: 'MISHRHMIS', value: '' },
    { id: 'KWLKWLKWL', value: '' },
    { id: 'MLFMILPEM', value: '' },
    { id: 'MLFMILRTB', value: '' },
    { id: 'MLFMILSEM', value: '' },
    { id: 'MLKBFSEXX', value: '' },
    { id: 'MLLFXTEGI', value: '' },
    { id: 'MLLFXTPFS', value: '' },
    { id: 'MLLMDHMLL', value: '' },
    { id: 'MNCMANBWS', value: '' },
    { id: 'MNCMANBXX', value: '' },
    { id: 'MNCMANDTX', value: '' },
    { id: 'MNCMANFDS', value: '' },
    { id: 'MNCMANFZX', value: '' },
    { id: 'MNCMANKNX', value: '' },
    { id: 'MNCMANMFT1', value: '' },
    { id: 'MNCMANMFTX', value: '' },
    { id: 'MNCMANMNC', value: '' },
    { id: 'MNCMANMWI1', value: '' },
    { id: 'MNCMANNAS', value: '' },
    { id: 'MNCMANPHS', value: '' },
    { id: 'MNCMANSLS', value: '' },
    { id: 'MNCMANUMX', value: '' },
    { id: 'MNCMANWIX', value: '' },
    { id: 'MONMONMON', value: '' },
    { id: 'MSCMNCMSC', value: '' },
    { id: 'MTHGRGMTH', value: '' },
    { id: 'MYNLDYMYN', value: '' },
    { id: 'NCTFXTKTE', value: '' },
    { id: 'NFTMED42W', value: '' },
    { id: 'LDYLDYLDY', value: '' },
    { id: 'NFTMEDRLW', value: '' },
    { id: 'NGGABDNGG', value: '' },
    { id: 'NGRCWLDGX', value: '' },
    { id: 'NHOIMMNHD', value: '' },
    { id: 'NHOIMMNHP', value: '' },
    { id: 'NHTLHRRAF', value: '' },
    { id: 'NHVNHVNHV', value: '' },
    { id: 'NKHIMMNKH', value: '' },
    { id: 'NPTNPTALX', value: '' },
    { id: 'NPTNPTBPW', value: '' },
    { id: 'NPTNPTLSW', value: '' },
    { id: 'NPTNPTNPT', value: '' },
    { id: 'NQYNQYNQY', value: '' },
    { id: 'NRWNWINRW', value: '' },
    { id: 'NRWNWIRFS', value: '' },
    { id: 'NSHTYNAQE', value: '' },
    { id: 'NSHTYNNSH', value: '' },
    { id: 'NTNBHXBXX', value: '' },
    { id: 'NURSTNPGM1', value: '' },
    { id: 'NWBEDIXXX', value: '' },
    { id: 'NYLPENNYL', value: '' },
    { id: 'OBYBHXHKH', value: '' },
    { id: 'PASGLAXCN', value: '' },
    { id: 'PASGLAXXX', value: '' },
    { id: 'PAYGLAQGX', value: '' },
    { id: 'PCUEDIBIS', value: '' },
    { id: 'PEDMILPED', value: '' },
    { id: 'PERDUNPER', value: '' },
    { id: 'PESNHVRWL', value: '' },
    { id: 'PFTLONCMJ', value: '' },
    { id: 'PFTLONDHE', value: '' },
    { id: 'PFTLONESS', value: '' },
    { id: 'PFTLONFPY2', value: '' },
    { id: 'PFTLONJUJ', value: '' },
    { id: 'PFTLONPFT', value: '' },
    { id: 'PFTLONPHX1', value: '' },
    { id: 'PFTLONPXI1', value: '' },
    { id: 'PFTLONPXZ1', value: '' },
    { id: 'PFTLONPZT1', value: '' },
    { id: 'PHDPHDPHD', value: '' },
    { id: 'PIKPIKGPS', value: '' },
    { id: 'PIKPIKPIK', value: '' },
    { id: 'PLELHRCFX2', value: '' },
    { id: 'PLELHRFIX', value: '' },
    { id: 'PLELHRXAH', value: '' },
    { id: 'PLYPLYCDW', value: '' },
    { id: 'PLYPLYCOR', value: '' },
    { id: 'PLYPLYPLY', value: '' },
    { id: 'PLYPLYPPJ', value: '' },
    { id: 'PLYPLYVCW', value: '' },
    { id: 'PMEPTMAJQ', value: '' },
    { id: 'PMEPTMFHQ', value: '' },
    { id: 'PMEPTMHMN', value: '' },
    { id: 'PMEPTMPME', value: '' },
    { id: 'PMEPTMPOR2', value: '' },
    { id: 'PMEPTMPOR3', value: '' },
    { id: 'PMEPTMWAC', value: '' },
    { id: 'PNLNCLBAC', value: '' },
    { id: 'PNLNCLFDS', value: '' },
    { id: 'PNLNCLKNX', value: '' },
    { id: 'PNLNCLPNL', value: '' },
    { id: 'PNLNCLPZS', value: '' },
    { id: 'POCMIDNAV', value: '' },
    { id: 'POCMIDNTS', value: '' },
    { id: 'POOPOOBXX', value: '' },
    { id: 'POOPOOHWW', value: '' },
    { id: 'POOPOOPOO', value: '' },
    { id: 'POTABZJSX', value: '' },
    { id: 'PRHWEYIBB', value: '' },
    { id: 'PRHWEYPRH', value: '' },
    { id: 'PRTABDPRT', value: '' },
    { id: 'PSBKLNPSB', value: '' },
    { id: 'PTBPTBPTB', value: '' },
    { id: 'PTSSHOPTS', value: '' },
    { id: 'PYALHRCTL', value: '' },
    { id: 'PYALHRSFR', value: '' },
    { id: 'RAHLONPZI1', value: '' },
    { id: 'RAHLONPZQ1', value: '' },
    { id: 'RCSMEDRCW', value: '' },
    { id: 'RDDBHXRRL', value: '' },
    { id: 'RENGLAKWX', value: '' },
    { id: 'RENGLAXBS', value: '' },
    { id: 'RENGLWREN', value: '' },
    { id: 'RERMIDRED', value: '' },
    { id: 'RIDMEDGHJ', value: '' },
    { id: 'RIDMEDRBS', value: '' },
    { id: 'RIDMEDRST', value: '' },
    { id: 'RMFLONGCC', value: '' },
    { id: 'RMGRMGRMG', value: '' },
    { id: 'ROYROSRND', value: '' },
    { id: 'ROYROSROY', value: '' },
    { id: 'RS8CWLRS8', value: '' },
    { id: 'RS8CWLRVS', value: '' },
    { id: 'RUILHRCWG', value: '' },
    { id: 'RUNRUNRUN', value: '' },
    { id: 'RYEFOLRYE', value: '' },
    { id: 'SBYLHRTRX', value: '' },
    { id: 'SCFKWLSCF', value: '' },
    { id: 'SCRINVSCR', value: '' },
    { id: 'SEASUNSEA', value: '' },
    { id: 'SEDHULRXE', value: '' },
    { id: 'SENSENSEN', value: '' },
    { id: 'SFDMANAPI', value: '' },
    { id: 'SFDMANCKXCUK', value: '' },
    { id: 'SFDMANCKXFCP', value: '' },
    { id: 'SFDMNCCSR', value: '' },
    { id: 'SFDMNCDD1', value: '' },
    { id: 'SH2MANCDM1', value: '' },
    { id: 'SH2MANIHX', value: '' },
    { id: 'SH2MANJHX', value: '' },
    { id: 'SHKSHOSHK', value: '' },
    { id: 'SHSMEDSHS', value: '' },
    { id: 'SILSILSIL', value: '' },
    { id: 'SLGIMMBCE', value: '' },
    { id: 'SLGIMMPTS', value: '' },
    { id: 'SLHLONCRT', value: '' },
    { id: 'SLHLONSHJ', value: '' },
    { id: 'SLLLHRAPX', value: '' },
    { id: 'SLLLHRBGX', value: '' },
    { id: 'SLLLHRFGX', value: '' },
    { id: 'SLLLHRGBL', value: '' },
    { id: 'SLLLHRMDX', value: '' },
    { id: 'SLLLHRQIX', value: '' },
    { id: 'SLLLHRVTX', value: '' },
    { id: 'SLLLHRXBB', value: '' },
    { id: 'SLLLHRXZY', value: '' },
    { id: 'SLOLHRKAX', value: '' },
    { id: 'SLOLHRXBA', value: '' },
    { id: 'SMEBHXIDX', value: '' },
    { id: 'SNSKWLSNS', value: '' },
    { id: 'SOLBHXROX', value: '' },
    { id: 'SOLBHXXBW', value: '' },
    { id: 'SOUSTNJAM1', value: '' },
    { id: 'SOUSTNPNT1', value: '' },
    { id: 'SOUSTNPWS', value: '' },
    { id: 'SOUSTNSCT1', value: '' },
    { id: 'SOUSTNSOU', value: '' },
    { id: 'LHRLHRBAC', value: '' },
    { id: 'SPKLPLTNT', value: '' },
    { id: 'SPKLPLWNS', value: '' },
    { id: 'SPPLHRVIX', value: '' },
    { id: 'SSHTYNNSA', value: '' },
    { id: 'SSHTYNSSH', value: '' },
    { id: 'SSSSSSSSS', value: '' },
    { id: 'STDMEDSLT', value: '' },
    { id: 'STDTHPPYO1', value: '' },
    { id: 'STNLSABAC', value: '' },
    { id: 'STNLSABIP', value: '' },
    { id: 'STNLSAFDS', value: '' },
    { id: 'STNLSAFHS', value: '' },
    { id: 'STNLSASLS', value: '' },
    { id: 'STNLSASTN', value: '' },
    { id: 'STNLSATNT', value: '' },
    { id: 'STNLSAUPS', value: '' },
    { id: 'STOSTOARN', value: '' },
    { id: 'STWFXTGRS', value: '' },
    { id: 'SULLERSUL', value: '' },
    { id: 'SUNSUNSUN', value: '' },
    { id: 'SURLHRBIS', value: '' },
    { id: 'SVTLONTLS', value: '' },
    { id: 'SWASWAKDE', value: '' },
    { id: 'SWASWASWA', value: '' },
    { id: 'SWBTHPSRS', value: '' },
    { id: 'SWLLHREPX', value: '' },
    { id: 'SWLLHRJAX', value: '' },
    { id: 'SWLLHRUKX', value: '' },
    { id: 'SWLLHRUVX', value: '' },
    { id: 'SWLLHRXAG', value: '' },
    { id: 'SWYLERSWY', value: '' },
    { id: 'TAWBHXDST', value: '' },
    { id: 'TAWBHXTNT2', value: '' },
    { id: 'TBLLTNDHX', value: '' },
    { id: 'TEEMIDINE', value: '' },
    { id: 'TEEMIDQPT', value: '' },
    { id: 'TEEMIDTEE', value: '' },
    { id: 'TEFBHXWAC', value: '' },
    { id: 'TFDDOGUFS', value: '' },
    { id: 'TFDFXTARR', value: '' },
    { id: 'TFKMANEXX', value: '' },
    { id: 'TFKMANGEX', value: '' },
    { id: 'TFKMANMCS1CNS', value: '' },
    { id: 'TFKMANMCS1FCP', value: '' },
    { id: 'TFKMANMCX', value: '' },
    { id: 'TFKMANMFM', value: '' },
    { id: 'TFKMANMFT1', value: '' },
    { id: 'TFKMANMFT2', value: '' },
    { id: 'TFKMANMLI1', value: '' },
    { id: 'TFKMANMTI1', value: '' },
    { id: 'TFKMANXCL', value: '' },
    { id: 'TILLONEGK1', value: '' },
    { id: 'TILLONPYD1', value: '' },
    { id: 'TILLONPYD2', value: '' },
    { id: 'TILLONTIL', value: '' },
    { id: 'TLGSTNFOX', value: '' },
    { id: 'TLQSOUSVID', value: '' },
    { id: 'TLQSTNACC1', value: '' },
    { id: 'TLQSTNEFT1', value: '' },
    { id: 'TLQSTNPDC1', value: '' },
    { id: 'TLQSTNSOF1', value: '' },
    { id: 'TLQSTNSSL1', value: '' },
    { id: 'TLQSTNYOU1', value: '' },
    { id: 'TNMTNMTNM', value: '' },
    { id: 'TOBARGTOB', value: '' },
    { id: 'TONEDICWG', value: '' },
    { id: 'TORTNMTOR', value: '' },
    { id: 'TRNGLWTRN', value: '' },
    { id: 'TRUFALTRU', value: '' },
    { id: 'TTLHULTTL', value: '' },
    { id: 'ULLINVULL', value: '' },
    { id: 'UURBOHBVEE', value: '' },
    { id: 'UURBOHUUR', value: '' },
    { id: 'UXBLHRAGS', value: '' },
    { id: 'WACLONWAC', value: '' },
    { id: 'WAFLTNERL', value: '' },
    { id: 'WBDLHRGCX', value: '' },
    { id: 'WBYLHRAPI', value: '' },
    { id: 'WBYLHREIM', value: '' },
    { id: 'WBYLHRPIC', value: '' },
    { id: 'WDRLHRNIX', value: '' },
    { id: 'WENSOURSL1', value: '' },
    { id: 'WEYWEYWEY', value: '' },
    { id: 'WHOLONLIF1', value: '' },
    { id: 'WICINVWIC', value: '' },
    { id: 'WIHFXTPFE', value: '' },
    { id: 'WIHFXTPQX', value: '' },
    { id: 'WIHLONGRS', value: '' },
    { id: 'WIHLONUFE', value: '' },
    { id: 'WISKLNWIS', value: '' },
    { id: 'WLALONBTW', value: '' },
    { id: 'WLKTYNNEP', value: '' },
    { id: 'WLKTYNOTP', value: '' },
    { id: 'WNESTNWAC', value: '' },
    { id: 'WOHCVTEGI', value: '' },
    { id: 'WORWORWOR', value: '' },
    { id: 'WPHSTNEPQ1', value: '' },
    { id: 'WPTWPTWPT', value: '' },
    { id: 'MLFMILLNG', value: '' },
    { id: 'WT9LBAELF1', value: '' },
    { id: 'WT9LBAELX', value: '' },
    { id: 'WTSWTSWTS', value: '' },
    { id: 'WYHMANCNX', value: '' },
    { id: 'WYHMANDNX', value: '' },
    { id: 'WYHMANDSE', value: '' },
    { id: 'WYHMANIMX', value: '' },
    { id: 'WYHMANJEX', value: '' },
    { id: 'WYHMANMC11', value: '' },
    { id: 'WYHMANNPX', value: '' },
    { id: 'WYHMANNTX', value: '' },
    { id: 'WYHMANPQX', value: '' },
    { id: 'WYHMANQJX', value: '' },
    { id: 'WYHMANTDX', value: '' },
    { id: 'WYHMANWLX', value: '' },
    { id: 'XSSMIDEKO', value: '' },
    { id: 'XSSMIDITT', value: '' },
    { id: 'XSSMIDNAV', value: '' },
    { id: 'YDNLBABWS', value: '' },
    { id: 'YDNLBAMFL', value: '' },
    { id: 'YDNLBAYDN', value: '' },
    { id: 'ZAHABZGMX', value: '' },
    { id: 'ZAHABZXCQ', value: '' },
    { id: 'NFTMEDNTW', value: '' },
    { id: 'ABPCWLABP', value: '' },
    { id: 'ALRLHRSTO', value: '' },
    { id: 'ASDDOVASD', value: '' },
    { id: 'AWLFXTBAC', value: '' },
    { id: 'AWLFXTTAC', value: '' },
    { id: 'BB2BHXBB2', value: '' },
    { id: 'BBPPTMBBP', value: '' },
    { id: 'BBSLHRBBS', value: '' },
    { id: 'BCCFXTBCC', value: '' },
    { id: 'BCKLTNBCK', value: '' },
    { id: 'BDMPLYBDM', value: '' },
    { id: 'BEFLTNBEF', value: '' },
    { id: 'BHDBHDBHD', value: '' },
    { id: 'BIDPLHBID', value: '' },
    { id: 'BIFLIVBWF', value: '' },
    { id: 'BLKLIVBLK', value: '' },
    { id: 'BNOBHXBNO', value: '' },
    { id: 'BSFEMABSF', value: '' },
    { id: 'BTTLHRBTT', value: '' },
    { id: 'CAXLIVCAX', value: '' },
    { id: 'CGVLHRCGV', value: '' },
    { id: 'CHBLHRCHB', value: '' },
    { id: 'CLDGLACLD', value: '' },
    { id: 'CLSHRHCLS', value: '' },
    { id: 'CNBDOVCNB', value: '' },
    { id: 'CNVHLDCNV', value: '' },
    { id: 'CPNBRSCPN', value: '' },
    { id: 'CRFLTNCRF', value: '' },
    { id: 'DHMLHRDHM', value: '' },
    { id: 'DIDLHRDID', value: '' },
    { id: 'DKWPLHDKW', value: '' },
    { id: 'DONDSADON', value: '' },
    { id: 'DXFLSADXF', value: '' },
    { id: 'ECOHRHECO', value: '' },
    { id: 'ECSMANECS', value: '' },
    { id: 'EENBHXEEN', value: '' },
    { id: 'ENKBFSENK', value: '' },
    { id: 'ETRLHRETR', value: '' },
    { id: 'FGNTHPFGN', value: '' },
    { id: 'FODLHRFOD', value: '' },
    { id: 'FR4LHRFR4', value: '' },
    { id: 'FRELSAFRE', value: '' },
    { id: 'FSTLBAFST', value: '' },
    { id: 'GAIDSAGAI', value: '' },
    { id: 'GLOGLOGLO', value: '' },
    { id: 'GMTDSAGMT', value: '' },
    { id: 'GSLLSAGSL', value: '' },
    { id: 'HDLFXTHDL', value: '' },
    { id: 'HMELSAHME', value: '' },
    { id: 'HVRCWLHVR', value: '' },
    { id: 'HWNHLDHWN', value: '' },
    { id: 'SPKLPLSPK', value: '' },
    { id: 'ISCPLYISC', value: '' },
    { id: 'KEMKEMKEM', value: '' },
    { id: 'KMTLHRKMT', value: '' },
    { id: 'KZOLHRKZO', value: '' },
    { id: 'LATBRSLAT', value: '' },
    { id: 'LCSEMALCS', value: '' },
    { id: 'STOSTOSTO', value: '' },
    { id: 'LEDBRSLED', value: '' },
    { id: 'LESPTMLES', value: '' },
    { id: 'LORDSALOR', value: '' },
    { id: 'LPREDILPR', value: '' },
    { id: 'LTDPLYLTD', value: '' },
    { id: 'LWENHVLWE', value: '' },
    { id: 'MDSLHRMDS', value: '' },
    { id: 'MLWLHRMLW', value: '' },
    { id: 'MMBLHRMMB', value: '' },
    { id: 'NPTCWLNPT', value: '' },
    { id: 'NWLLSANWL', value: '' },
    { id: 'OKEDSAOKE', value: '' },
    { id: 'PMYCWLPMY', value: '' },
    { id: 'PNPPLYPNP', value: '' },
    { id: 'QLALASATC', value: '' },
    { id: 'QLALASLGS', value: '' },
    { id: 'QUGPTMQUG', value: '' },
    { id: 'RCSTHPRCS', value: '' },
    { id: 'REHLGWREH', value: '' },
    { id: 'RRSBRSRRS', value: '' },
    { id: 'SAWLSASAW', value: '' },
    { id: 'SBALONSBA', value: '' },
    { id: 'SBLLBASBL', value: '' },
    { id: 'SCSABZSCS', value: '' },
    { id: 'SD5DSASD5', value: '' },
    { id: 'SDOPTMSDO', value: '' },
    { id: 'SHHFXTSHH', value: '' },
    { id: 'SIHLHRSIH', value: '' },
    { id: 'SIYBHXSIY', value: '' },
    { id: 'SJUPLHSJU', value: '' },
    { id: 'SJYPOOSJY', value: '' },
    { id: 'SSJFXTSSJ', value: '' },
    { id: 'STGFXTSTG', value: '' },
    { id: 'WRTWARBAE', value: '' },
    { id: 'SWACWLSWA', value: '' },
    { id: 'SYWLTNSYW', value: '' },
    { id: 'UMBPLHUMB', value: '' },
    { id: 'VLYHLDVLY', value: '' },
    { id: 'WGCLTNWGC', value: '' },
    { id: 'WNRBHXWNR', value: '' },
    { id: 'WPSHLDWPS', value: '' },
    { id: 'WRKDSAWRK', value: '' },
    { id: 'WSUBHXWSU', value: '' },
    { id: 'WWMLHRWWM', value: '' },
    { id: 'YEOYVLYEO', value: '' },
    { id: 'BIFBIFBGTGAS', value: '' },
    { id: 'GTYGTYPGTGAS', value: '' },
    { id: 'HULHULEGTGAS', value: '' },
    { id: 'HWLHWLPGTGAS', value: '' },
    { id: 'MTPMTPTGTGAS', value: '' },
    { id: 'PHDPHDSGTGAS', value: '' },
    { id: 'SULSULSGTGAS', value: '' },
    { id: 'XSSXSSTGTGAS', value: '' },
    { id: 'DGSDGSDGS', value: '' },
    { id: 'IOMRWYIOM', value: '' },
    { id: 'MIDLAFCSE', value: '' },
    { id: 'FAWSTNFAW', value: '' },
    { id: 'HAMSTNHAM', value: '' },
    { id: 'BELBELCAM1', value: '' },
    { id: 'BELBFSCMX', value: '' },
    { id: 'CBRLHRDHX', value: '' },
    { id: 'CBRLHRCNO2', value: '' },
    { id: 'MNCMANDHX', value: '' },
    { id: 'NWBEDIDHX', value: '' },
    { id: 'PAYGLADHX', value: '' },
    { id: 'UDDGLWAPI', value: '' },
    { id: 'ABDLEACSE', value: '' },
    { id: 'AYRLECCSE', value: '' },
    { id: 'BA5LAQ2CSE', value: '' },
    { id: 'BASLDFCSE', value: '' },
    { id: 'BELLCACSE', value: '' },
    { id: 'BHMLDJCSE', value: '' },
    { id: 'BLBLAQ1CSE', value: '' },
    { id: 'BRSLFECSE', value: '' },
    { id: 'CAXLAECSE', value: '' },
    { id: 'CDFLAPCSE', value: '' },
    { id: 'CDLLAM2CSE', value: '' },
    { id: 'CEGLBB2CSE', value: '' },
    { id: 'CHALAM1CSE', value: '' },
    { id: 'CMGLDECSE', value: '' },
    { id: 'CNBLGMCSE', value: '' },
    { id: 'COTLEECSE', value: '' },
    { id: 'CSRLBB1CSE', value: '' },
    { id: 'DUNLEGCSE', value: '' },
    { id: 'DXYLDNCSE', value: '' },
    { id: 'EDILEHCSE', value: '' },
    { id: 'ELGLEBCSE', value: '' },
    { id: 'ESDLHHCSE', value: '' },
    { id: 'FEMLHRCSE', value: '' },
    { id: 'FXTFXTCSE', value: '' },
    { id: 'GLOLFFCSE', value: '' },
    { id: 'GLWLEFCSE', value: '' },
    { id: 'GLWLEJCSE', value: '' },
    { id: 'GRGLEDCSE', value: '' },
    { id: 'GSYLAICSE', value: '' },
    { id: 'HFXLALCSE', value: '' },
    { id: 'HRLLDGCSE', value: '' },
    { id: 'HULLADCSE', value: '' },
    { id: 'IMMLAGCSE', value: '' },
    { id: 'IPSLDBCSE', value: '' },
    { id: 'KTRLDMCSE', value: '' },
    { id: 'LBALAACSE', value: '' },
    { id: 'LCSLDLCSE', value: '' },
    { id: 'LIVLANCSE', value: '' },
    { id: 'LUTLDICSE', value: '' },
    { id: 'MAHLGKCSE', value: '' },
    { id: 'NCLLAOCSE', value: '' },
    { id: 'NHPLDOCSE', value: '' },
    { id: 'NRWLDDCSE', value: '' },
    { id: 'NTGLDACSE', value: '' },
    { id: 'OXFLFHCSE', value: '' },
    { id: 'PETLDHCSE', value: '' },
    { id: 'PLYLFJCSE', value: '' },
    { id: 'RDNLGCCSE', value: '' },
    { id: 'RDNLGFCSE', value: '' },
    { id: 'REHLGBCSE', value: '' },
    { id: 'SFYLAHCSE', value: '' },
    { id: 'SHELAJCSE', value: '' },
    { id: 'SNNLGGCSE', value: '' },
    { id: 'SOULFGCSE', value: '' },
    { id: 'SPPLGDCSE', value: '' },
    { id: 'STFLHBCSE', value: '' },
    { id: 'STSLGECSE', value: '' },
    { id: 'SWALBCCSE', value: '' },
    { id: 'SWRLHCCSE', value: '' },
    { id: 'SWRLHECSE', value: '' },
    { id: 'SWRLHGCSE', value: '' },
    { id: 'TAULFICSE', value: '' },
    { id: 'TILTILCSE', value: '' },
    { id: 'UXBLHFCSE', value: '' },
    { id: 'WGNLARCSE', value: '' },
    { id: 'WHGLAB2CSE', value: '' },
    { id: 'WNNLAB1CSE', value: '' },
    { id: 'WOVLDKCSE', value: '' },
    { id: 'YRKLDCCSE', value: '' },
    { id: 'SHBSOUSHB', value: '' },
    { id: 'WICWIKWIC', value: '' },
    { id: 'DEUDEUDEU', value: '' },
    { id: 'EUTEUTEUT', value: '' },
    { id: 'KLHKILKLH', value: '' },
    { id: 'HRHHRHHRH', value: '' },
    { id: 'MILMILMIL', value: '' },
    { id: 'NCLTYNNCL', value: '' },
    { id: 'PUFPUFPUF', value: '' },
    { id: 'MEDMEDMED', value: '' },
    { id: 'BELBELGVM', value: '' },
    { id: 'LARBELGVM', value: '' },
    { id: 'WPTWPTGVM', value: '' },
    { id: 'BELBELDHX', value: '' },
    { id: 'RDNLHRDXX', value: '' },
    { id: 'BSTLSADHX', value: '' },
    { id: 'BELBFSHYS', value: '' },
    { id: 'BELBHDBWS', value: '' },
    { id: 'BELBFSDHX', value: '' },
    { id: 'BELBFSEOX', value: '' },
    { id: 'BELBFSHLX', value: '' },
    { id: 'BELBFSRXX', value: '' },
    { id: 'BA5ABDCGD', value: '' },
    { id: 'BELBFSXXX', value: '' },
    { id: 'BFSBFSPBE', value: '' },
    { id: 'BRFMANXCJ', value: '' },
    { id: 'CBRLHROND', value: '' },
    { id: 'CBRLHRXAI', value: '' },
    { id: 'CBRLHRXBD', value: '' },
    { id: 'CLPTILCTD', value: '' },
    { id: 'DFDTHPEUD', value: '' },
    { id: 'DVRDOGFSA', value: '' },
    { id: 'DVRDOGWDG', value: '' },
    { id: 'DVRDOGWNL', value: '' },
    { id: 'LY9DOGWSE', value: '' },
    { id: 'FEMLHRXCK', value: '' },
    { id: 'OBYBHXPDE', value: '' },
    { id: 'OBYBHXPFE', value: '' },
    { id: 'PTLPTLPTL', value: '' },
    { id: 'RDNLHRXXX', value: '' },
    { id: 'GLDGLDGLD', value: '' },
    { id: 'RYEDOGRYE', value: '' },
    { id: 'NFTLONTER', value: '' },
    { id: 'NFTLONTOW', value: '' },
    { id: 'EMAEMAUPS4', value: '' },
    { id: 'MLFMILSOH', value: '' },
    { id: 'MLFMILDRA', value: '' },
    { id: 'SFDMANXDH', value: '' },
    { id: 'RCSRCSXDI', value: '' },
    { id: 'LRKMEDWSG', value: '' },
    { id: 'LAILONXAM', value: '' },
    { id: 'DVYDVYES1 ', value: '' },
    { id: 'DVYDVYES2 ', value: '' },
    { id: 'DAGDAGFRD ', value: '' },
    { id: 'KMKKMKUPM ', value: '' },
    { id: 'SCPSCPBRS ', value: '' },
    { id: 'WDNWDNEST ', value: '' },
    { id: 'NGONGONGO ', value: '' },
    { id: 'ASDASDASD ', value: '' },
    { id: 'LBKLBKLBK ', value: '' },
    { id: 'EATELWELW', value: '' },
    { id: 'XLWLLNTSU', value: '' },
    { id: 'KYNABDKYN', value: '' },
    { id: 'GLSNPTGLS', value: '' },
    { id: 'WTWTYNWTW', value: '' },
    { id: 'PLYPLYBBN', value: '' },
    { id: 'PLYPLYTPT', value: '' },
    { id: 'BRXTNMBRX', value: '' },
    { id: 'POCMIDPOC', value: '' },
    { id: 'BKGLONBKG', value: '' },
    { id: 'BKGTILRVR', value: '' },
    { id: 'SLHLONSLH', value: '' },
    { id: 'GYSTILGYS', value: '' },
    { id: 'EMAEMAHWS', value: '' },
    { id: 'LHRLHRACS', value: '' },
    { id: 'LHRLHRAPH', value: '' },
    { id: 'LHRLHRARC', value: '' },
    { id: 'SWLLHRPHS', value: '' },
    { id: 'MNCMANBIP', value: '' },
    { id: 'MNCMANNBS', value: '' },
    { id: 'POEABZEBX', value: '' },
    { id: 'LISBFSEOX', value: '' },
    { id: 'BHMBHXLKX', value: '' },
    { id: 'ATHBHXRMX', value: '' },
    { id: 'CNKBHXRUX', value: '' },
    { id: 'BHMBHXTFX', value: '' },
    { id: 'LWMLBARUX', value: '' },
    { id: 'HTOLHREBX ', value: '' },
    { id: 'CBRLHRFKX', value: '' },
    { id: 'FEMLHRFLX', value: '' },
    { id: 'CBRLHRHGX', value: '' },
    { id: 'CBRLHRHSX', value: '' },
    { id: 'HESLHRIWX', value: '' },
    { id: 'SWLLHRJPX', value: '' },
    { id: 'EGHLHRLLX', value: '' },
    { id: 'HOULHRLRX', value: '' },
    { id: 'SBYLHRMZX', value: '' },
    { id: 'CBRLHRNEX', value: '' },
    { id: 'PYALHRNFX', value: '' },
    { id: 'SLPLHRNJX', value: '' },
    { id: 'CBRLHROTX', value: '' },
    { id: 'CBRLHRRWX', value: '' },
    { id: 'CBRLHRTFX', value: '' },
    { id: 'CBRLHRTOX', value: '' },
    { id: 'SLOLHRPTX', value: '' },
    { id: 'HMWLHRUPX', value: '' },
    { id: 'MNCMANHSX', value: '' },
    { id: 'ECSMANRUX', value: '' },
    { id: 'PLELHRXDC ', value: '' },
    { id: 'LUTLTNDHS', value: '' },
    { id: 'CLRBFSDDE', value: '' },
    { id: 'DYLBFSAGE', value: '' },
    { id: 'CVTBHXFPX', value: '' },
    { id: 'PBKEMAPDX', value: '' },
    { id: 'COOBFSDXX', value: '' },
    { id: 'AGLBELAGL', value: '' },
    { id: 'BYCCLRBYC', value: '' },
    { id: 'BLRBELBLR', value: '' },
    { id: 'BANBELBAN', value: '' },
    { id: 'KLKWPTKLK', value: '' },
    { id: 'PVGBELPVG', value: '' },
    { id: 'WHEBELWHE', value: '' },
    { id: 'SJLBELSJL', value: '' },
    { id: '8TNBFSFBD2', value: '' },
    { id: 'CFGBFSFDC2', value: '' },
    { id: 'BHIEMAKZX', value: '' },
    { id: 'HOORCSKZX', value: '' },
    { id: 'FEMLHRYSX', value: '' },
    { id: 'FEMLHRTKX', value: '' },
    { id: 'LUTLTNKDX', value: '' },
    { id: 'FEMLHRFQX', value: '' },
    { id: 'DFDDOVEPX', value: '' },
    { id: 'LWTLONTLE', value: '' },
    { id: 'SLOLHRTTX', value: '' },
    { id: 'WMHLTNUPX', value: '' },
    { id: 'BLELTNKYX', value: '' },
    { id: 'BHMBHXKEX', value: '' },
    { id: 'GBAUCYNAYRCYNGVM', value: '' },
    { id: 'GBAUDEUDEUDEUGVM', value: '' },
    { id: 'GBAUDVRDOVDVRGVM', value: '' },
    { id: 'GBAUEUTEUTEUTGVM', value: '' },
    { id: 'GBAUFISFISFISGVM', value: '' },
    { id: 'GBAUHRHHRHHRHGVM', value: '' },
    { id: 'GBAUHYMHEYHYMGVM', value: '' },
    { id: 'GBAUHLYHLDHLYGVM', value: '' },
    { id: 'GBAUHULHULHULGVM', value: '' },
    { id: 'GBAUIMMIMMIMMGVM', value: '' },
    { id: 'GBAUKLHKILKLHGVM', value: '' },
    { id: 'GBAULIVLIVLIVGVM', value: '' },
    { id: 'GBAUNHVNHVNHVGVM', value: '' },
    { id: 'GBAUPEDMILPEDGVM', value: '' },
    { id: 'GBAUPLYPLYPLYGVM', value: '' },
    { id: 'GBAUPOOPOOPOOGVM', value: '' },
    { id: 'GBAUNCLTYNNCLGVM', value: '' },
    { id: 'GBAUPMEPTMPMEGVM', value: '' },
    { id: 'GBAUPUFPUFPUFGVM', value: '' },
    { id: 'GBAURMGRMGRMGGVM', value: '' },
    { id: 'GBAUMEDMEDMEDGVM', value: '' },
    { id: 'GBAUTEEMIDTEEGVM', value: '' },
    { id: 'GBAUTILLONTILGVM', value: '' },
    { id: 'CBRLHRKBX', value: '' },
    { id: 'GBAUADGBHXEJX ', value: '' },
    { id: 'GBAUHOULHRYRX', value: '' },
    { id: 'GBAUABDABDABDGVM', value: '' },
    { id: 'GBAULRPAYRLRPGVM', value: '' },
    { id: 'GBAUQDLBRSPTE ', value: '' },
    { id: 'GBAUPLELHRTGX', value: '' },
    { id: 'GBAUCBRLHRWVX', value: '' },
    { id: 'GBAUCOAEMAOIE', value: '' },
    { id: 'GBAUBELBELGVM', value: '' },
    { id: 'GBAULARBELGVM', value: '' },
    { id: 'GBAUWPTWPTGVM', value: '' },
    { id: 'GBAURSDDONMUE', value: '' },
    { id: 'GBAUFXTFXTFXTGVM ', value: '' },
    { id: 'GBAUASFLHROWD', value: '' },
    { id: 'GBAUCCVBELDPB', value: '' },
    { id: 'GBAUANTBELDPK', value: '' },
    { id: 'GBAUMLKBELDPM', value: '' },
    { id: 'GBAUXLUBELDPL', value: '' },
    { id: 'GBAUCRGBELDPC', value: '' },
    { id: 'GBAUTOOBELDPT', value: '' },
    { id: 'GBAUURLBELHAN', value: '' },
    { id: 'GBAUBBGBELEOS', value: '' },
    { id: 'GBAUDFDDOVEPX', value: '' },
    { id: 'GBAULWTLONTLE', value: '' },
    { id: 'GBAUFEMLHRFQX', value: '' },
    { id: 'GBAUCBRLHRKBX', value: '' },
    { id: 'GBAULUTLTNKDX', value: '' },
    { id: 'GBAUFEMLHRTKX', value: '' },
    { id: 'GBAUFEMLHRYSX', value: '' },
    { id: 'GBAUBLELTNKYX', value: '' },
    { id: 'GBAUHOORCSKZX', value: '' },
    { id: 'GBAUBHIEMAKZX', value: '' },
    { id: 'GBAUEDIEDIEDX', value: '' },
    { id: 'GBAUIPSFXTEWB', value: '' },
    { id: 'GBAUFEMLHROXE', value: '' },
    { id: 'GBAUASFDOGTCE', value: '' },
    { id: 'GBAUIPSFXTNVE', value: '' },
    { id: 'GBAULBKLONMCE', value: '' },
    { id: 'GBAUBILLONNSE', value: '' },
    { id: 'GBAUPFTLONMKE', value: '' },
    { id: 'GBAUQWHEMADVE', value: '' },
    { id: 'GBAUCFDLSADCE', value: '' },
    { id: 'GBAUKTRBHXCVE', value: '' },
    { id: 'GBAUTFDFXTADE', value: '' },
    { id: 'GBAUFEMLHRWTX', value: '' },
    { id: 'GBAUWIHLSAUGE', value: '' },
    { id: 'GBAUHHELTNETE', value: '' },
    { id: 'GBAULIVLIVAWD', value: '' },
    { id: 'GBAUBRAFXTARE', value: '' },
    { id: 'GBAURDDBHXRRE', value: '' },
    { id: 'GBAUWBYLHRPME', value: '' },
    { id: 'GBAUMLLLSACPE', value: '' },
    { id: 'GBAUGYSLONDIE', value: '' },
    { id: 'GBAUHYNLIVMLE', value: '' },
    { id: 'GBAUHHELTNMLE', value: '' },
    { id: 'GBAURMFTILCOE', value: '' },
    { id: 'GBAUTLQSTNFSE', value: '' },
    { id: 'GBAUDFDTHPERE', value: '' },
    { id: 'GBAURUILHRCRE', value: '' },
    { id: 'GBAUBXETILCTE', value: '' },
    { id: 'GBAUBRALSAABE', value: '' },
    { id: 'GBAUCBYEMAHIE', value: '' },
    { id: 'GBAUMMLLBANDE', value: '' },
    { id: 'GBAUCNKBHXCNE', value: '' },
    { id: 'GBAULWMLBAKLE', value: '' },
    { id: 'GBAUBWOBHXLPE', value: '' },
    { id: 'GBAUERIDOVRLE', value: '' },
    { id: 'GBAUBLVMEDATE', value: '' },
    { id: 'GBAUTILLONASE', value: '' },
    { id: 'GBAUBASLONCSE', value: '' },
    { id: 'GBAUBASTILTFE', value: '' },
    { id: 'GBAUBMBLIVBLE', value: '' },
    { id: 'GBDUNRMFXTBLC', value: '' },
    { id: 'GBDUSDWRMGNMO', value: '' },
    { id: 'GBDURCSMEDBND', value: '' },
    { id: 'GBDUWSSPTMIFA', value: '' },
    { id: 'GBDUASFDOVIFA', value: '' },
    { id: 'GBDUCOQMOSEIR', value: '' },
    { id: 'GBDUNRMGTYITC', value: '' },
    { id: 'GBDUKBTABDGNI', value: '' },
    { id: 'GBAULGXLONEYX', value: '' },
    { id: 'GBAUHALLSAGFE', value: '' },
    { id: 'GBAUSTSLHRJBX', value: '' },
    { id: 'GBAUSLLLHRGWE', value: '' },
    { id: 'GBAUSLOLHRENX', value: '' },
    { id: 'GBAUWFEDOGMBE', value: '' },
    { id: 'GBAUCBRLHRAUE', value: '' },
    { id: 'GBAUWEDBHXEHE', value: '' },
    { id: 'GBAUIPSIPSDME', value: '' },
    { id: 'GBAUNRYBELTSC', value: '' },
    { id: 'GBAUENKBELTSB', value: '' },
    { id: 'GBAUGRBFXTCKE', value: '' },
    { id: 'GBAULTPRCSCKX', value: '' },
    { id: 'GBAUPLELHRHNX', value: '' },
    { id: 'GBAUFXTFXTHEE', value: '' },
    { id: 'GBAUABDABDGPE', value: '' },
    { id: 'GBAUIPSFXTGME', value: '' },
    { id: 'GBAUBASLONCGE', value: '' },
    { id: 'GBAUSNNBRSLEE', value: '' },
    { id: 'GBAUEVCBRSPRE', value: '' },
    { id: 'GBAUSTWFXTSRE', value: '' },
    { id: 'GBAUGRBFXTRME', value: '' },
    { id: 'GBAUMHTFXTEWE', value: '' },
    { id: 'GBAUGRBFXTCHE', value: '' },
    { id: 'GBAUIPSFXTBSE', value: '' },
    { id: 'GBAUIPSFXTSXE', value: '' },
    { id: 'GBAUFXTFXTMAE', value: '' },
    { id: 'GBAUFXTFXTKEE', value: '' },
    { id: 'GBAUGTYGTYLLE', value: '' },
    { id: 'GBAUHULHULMRE', value: '' },
    { id: 'GBAUSLGIMMHTE', value: '' },
    { id: 'GBAUKSUIMMITE', value: '' },
    { id: 'GBAUAPTMNCLRE', value: '' },
    { id: 'GBAUAPTMNCYTE', value: '' },
    { id: 'GBAUNHPLTNTFM', value: '' },
    { id: 'GBAUCMTTYNLBE', value: '' },
    { id: 'GBAUWIHLSASWE', value: '' },
    { id: 'GBAUWIHLSASGE', value: '' },
    { id: 'GBAUSOTEMASTE', value: '' },
    { id: 'GBAUROYLSACBE', value: '' },
    { id: 'GBAUNHPLTNTGE', value: '' },
    { id: 'GBAUTFKMANCWE', value: '' },
    { id: 'GBAUECSMANHSE', value: '' },
    { id: 'GBAUHRDLHRHDE', value: '' },
    { id: 'GBAUFDYBHXTSE', value: '' },
    { id: 'GBAUHUDLTNOIE', value: '' },
    { id: 'GBAURUHBHXBDE', value: '' },
    { id: 'GBAUCVTBHXNTE', value: '' },
    { id: 'GBAUAYLLTNMGE', value: '' },
    { id: 'GBAUIVSLSAMEE', value: '' },
    { id: 'GBAUDWYLBAWHE', value: '' },
    { id: 'GBAUCVTBHXWGE', value: '' },
    { id: 'GBAULYNDOVPDX', value: '' },
    { id: 'GBAUHMWLHRWPX', value: '' },
    { id: 'GBAUHEYLHRHOX', value: '' },
    { id: 'GBAUCTFLBAVIE', value: '' },
    { id: 'GBAUHROLHREHX', value: '' },
    { id: 'GBAUCBRLHRKJX', value: '' },
    { id: 'GBAUSLOLHRTTX', value: '' },
    { id: 'GBAUWMHLTNUPX', value: '' },
    { id: 'GBAUBHMBHXKEX', value: '' },
    { id: 'GBAUIVELHRTJX', value: '' },
    { id: 'GBAUCBRLHRNOX', value: '' },
    { id: 'GBAULRPAYRLRP', value: '' },
    { id: 'GBAUHESLHRNLX', value: '' },
    { id: 'GBAUDVRDOGWNL', value: '' },
    { id: 'GBAUCVTBHXPCE', value: '' },
    { id: 'GBAUHATLTNKSX', value: '' },
    { id: 'GBAUBHDBHDBWS', value: '' },
    { id: 'GBAUCHNLHRVBX', value: '' },
    { id: 'GBAUMALLSARUX', value: '' },
    { id: 'GBAUHHELTNKUX', value: '' },
    { id: 'IMAUCSWDGSCSW', value: '' },
    { id: 'GBAUNWLLSAAMG', value: '' },
    { id: 'GBAUMIKLTNBKE', value: '' },
    { id: 'GBAUTOMFXTEGR', value: '' },
    { id: 'GBAUNCVHULOJE', value: '' },
    { id: 'GBAUFXTFXTUNE', value: '' },
    { id: 'GBAULGPLGPUPS2', value: '' },
    { id: 'GBAUKTWLONUPS6', value: '' },
    { id: 'GBAUMLKBFSUPS7', value: '' },
    { id: 'GBAUFEMLHRBPX', value: '' },
    { id: 'GBAUCBRLHRCN02', value: '' },
    { id: 'GBAUDVYBHXAUK', value: '' },
    { id: 'GBAUPMEPTMWCP1', value: '' },
    { id: 'GBAUPMEPTMFRA1', value: '' },
    { id: 'GBAUPMEPTMIMC1', value: '' },
    { id: 'GBAUCVTBHXLTX', value: '' },
    { id: 'GBCUHOFBHXUPS', value: '' },
    { id: 'GBCUWBDLONGWS', value: '' },
    { id: 'GBAUDYCABDASW', value: '' },
    { id: 'GBAUWLORUNWCT', value: '' },
    { id: 'GBAUABDABDTJL', value: '' },
    { id: 'GBAUABDABDADW', value: '' },
    { id: 'GBAUABDABDARY', value: '' },
    { id: 'GBAUPNOLIVEOT', value: '' },
    { id: 'GBAULEAEMASTV', value: '' },
    { id: 'GBAUOBNMIDNPT', value: '' },
    { id: 'GBAUABDABDAFT', value: '' },
    { id: 'GBAUSWASWACPW', value: '' },
    { id: 'GBAUAVOAVOBAT', value: '' },
    { id: 'GBAUSWASWAAQD', value: '' },
    { id: 'GBAUBRVHULOFW', value: '' },
    { id: 'GBAUBIFBARNTS', value: '' },
    { id: 'GBAUBKGLONBRT', value: '' },
    { id: 'GBAUBRKLIVNWB', value: '' },
    { id: 'GBAUBRKLIVBKN', value: '' },
    { id: 'GBAUBRKLIVNWV', value: '' },
    { id: 'GBAUBRKLIVBKW', value: '' },
    { id: 'GBAUSWASWAAKD', value: '' },
    { id: 'GBAUYKHSHOTWW', value: '' },
    { id: 'GBAUYKHSHOBSL', value: '' },
    { id: 'GBAUYKHSHOSHO', value: '' },
    { id: 'GBAUMSCMNCCPP', value: '' },
    { id: 'GBAUROYROSRFT', value: '' },
    { id: 'GBAUROYROSDAW', value: '' },
    { id: 'GBAURCSMEDSTR', value: '' },
    { id: 'GBAUXLWNPTTSL', value: '' },
    { id: 'GBAUAVOAVOETX', value: '' },
    { id: 'GBAUAVOAVOSPG', value: '' },
    { id: 'GBAUAVOAVOUMP', value: '' },
    { id: 'GBAUIVGIVGTGC', value: '' },
    { id: 'GBAUIVGIVGSPW', value: '' },
    { id: 'GBAUELLELLESC', value: '' },
    { id: 'GBAUMIDMIDCRW', value: '' },
    { id: 'GBAUNPTNPTDSQ', value: '' },
    { id: 'GBAUNPTNPTDNQ', value: '' },
    { id: 'GBAUNPTNPTDMQ', value: '' },
    { id: 'GBAUNPTNPTDTS', value: '' },
    { id: 'GBAUCTMMEDCPD', value: '' },
    { id: 'GBAUCTMMEDAGI', value: '' },
    { id: 'GBAUEAMLIVEAW', value: '' },
    { id: 'GBAUNPTNPTSIN', value: '' },
    { id: 'GBAUNPTNPTMVF', value: '' },
    { id: 'GBAUNPTNPTCXW', value: '' },
    { id: 'GBAUNPTNPTATS', value: '' },
    { id: 'GBAUNPTNPTASQ', value: '' },
    { id: 'GBAUNPTNPTANQ', value: '' },
    { id: 'GBAUELLELLEXM', value: '' },
    { id: 'GBAUELLELLESP', value: '' },
    { id: 'GBAUELLELLEMW', value: '' },
    { id: 'GBAUELLELLAGM', value: '' },
    { id: 'GBAUSOWELLESO', value: '' },
    { id: 'GBAUSOWELLARW', value: '' },
    { id: 'GBAUHTNGLWAGG', value: '' },
    { id: 'GBAUGGUGRGEXG', value: '' },
    { id: 'GBAUGGUGRGEGW', value: '' },
    { id: 'GBAUNPTNPTAMQ', value: '' },
    { id: 'GBAUNHOIMMHES', value: '' },
    { id: 'GBAUNHOIMMNHW', value: '' },
    { id: 'GBAUNGGINVNGG', value: '' },
    { id: 'GBAUNFTLONKIM', value: '' },
    { id: 'GBAUSSHTYNNCT', value: '' },
    { id: 'GBAUWTWTYNNEP', value: '' },
    { id: 'GBAUSSHTYNSSW', value: '' },
    { id: 'GBAUGYSLONWLA', value: '' },
    { id: 'GBAUGYSLONDCW', value: '' },
    { id: 'GBAUNEATRTGRW', value: '' },
    { id: 'GBAUNEATRTNPW', value: '' },
    { id: 'GBAUVSTLONORS', value: '' },
    { id: 'GBAUGTYGTYADM', value: '' },
    { id: 'GBAUGTYGTYTGY', value: '' },
    { id: 'GBAUHDDGOOCPH', value: '' },
    { id: 'GBAUHSTGRKPPH', value: '' },
    { id: 'GBAUHULHULAST', value: '' },
    { id: 'GBAUHULHULDBW', value: '' },
    { id: 'GBAUBOOLIVTJE', value: '' },
    { id: 'GBAUBOOLIVBCW', value: '' },
    { id: 'GBAUBOOLIVEFT', value: '' },
    { id: 'GBAUBOOLIVBEA', value: '' },
    { id: 'GBAUBOOLIVHUS', value: '' },
    { id: 'GBAUBOOLIVBEG', value: '' },
    { id: 'GBAUBOOLIVSDO', value: '' },
    { id: 'GBAUBOOLIVULS', value: '' },
    { id: 'GBAUBOOLIVBCB', value: '' },
    { id: 'GBAUBOOLIVBCR', value: '' },
    { id: 'GBAUBOOLIVTFR', value: '' },
    { id: 'GBAUBOOLIVBPW', value: '' },
    { id: 'GBAUBOOLIVOSL', value: '' },
    { id: 'GBAUBOOLIVBCG', value: '' },
    { id: 'GBAULIVLIVRIL', value: '' },
    { id: 'GBAUKISINVRIL', value: '' },
    { id: 'GBAUDDDKMKUPK', value: '' },
    { id: 'GBAUIRLMNCICT', value: '' },
    { id: 'GBAUIPSIPSSII', value: '' },
    { id: 'GBAUIPSIPSBAI', value: '' },
    { id: 'GBAUIPSIPSSCW', value: '' },
    { id: 'GBAUIPSIPSSLW', value: '' },
    { id: 'GBAUIPSIPSOMX', value: '' },
    { id: 'GBAUIPSIPSANI', value: '' },
    { id: 'GBAUIMMIMMTSI', value: '' },
    { id: 'GBAUIMMIMMMGD', value: '' },
    { id: 'GBAUIMMIMMBRW', value: '' },
    { id: 'GBAUHULHULTSH', value: '' },
    { id: 'GBAUHULHULSCP', value: '' },
    { id: 'GBAUHULHULRMA', value: '' },
    { id: 'GBAUHULHULHKI', value: '' },
    { id: 'GBAUHULHULSGW', value: '' },
    { id: 'GBAUWAVBHXTBX', value: '' },
    { id: 'GBAULHFBHXMCX', value: '' },
    { id: 'GBAUFEMLHRBHX', value: '' },
    { id: 'GBAULHRLHRRRX', value: '' },
    { id: 'GBAUHESLHRHAX', value: '' },
    { id: 'GBCUKRYCVTGFO', value: '' },
    { id: 'GBCUGTNLIVGFO', value: '' },
    { id: 'GBCUADLMNCPDP', value: '' },
    { id: 'GBCUEALSTNMIS', value: '' },
    { id: 'GBAUSTWFXTGSE', value: '' },
    { id: 'GBAUBOOLIVMFE', value: '' },
    { id: 'GBAUHSEHULBWE', value: '' },
    { id: 'GBAUSLLLHRBYX', value: '' },
    { id: 'GBAUFXTFXTBIE', value: '' },
    { id: 'GBAUCBRLHRXAZ', value: '' },
    { id: 'GBAUSLOLHRTCX', value: '' },
    { id: 'GBAUSLOLHRCFT2', value: '' },
    { id: 'GBAUSLLLHRMVX', value: '' },
    { id: 'GBAUPFTTILTMG1', value: '' },
    { id: 'GBAUBRFLBATMC1', value: '' },
    { id: 'GBAURDDBHXTMB1', value: '' },
    { id: 'GBAUHHELTNMHE', value: '' },
    { id: 'GBAUBASTILLOV1,', value: '' },
    { id: 'GBAUISWLHRTUX,', value: '' },
    { id: 'GBAUWYHMANTFX', value: '' },
    { id: 'GBCUKLSDVYESL,', value: '' },
    { id: 'GBDUBLRBELMYL', value: '' },
    { id: 'GBAUSLSSTNTCL1', value: '' },
    { id: 'GBAUKNMDOVAGO1', value: '' },
    { id: 'GBAULHRLHRAHL', value: '' },
    { id: 'GBAUCBRLHRKTX', value: '' },
    { id: 'GBAULYNDOGACI', value: '' },
    { id: 'GBAUBASTILLIX', value: '' },
    { id: 'GBAUWYHMANLIX', value: '' },
    { id: 'GBAUDOVDOVCLF', value: '' },
    { id: 'GBAULGWLGWBCP', value: '' },
    { id: 'GBAULTNDOGBGE', value: '' },
    { id: 'GBAUCBRLHRBDX', value: '' },
    { id: 'GBAUWYHMANCDM2', value: '' },
    { id: 'GBAUBHMBHXLES', value: '' },
    { id: 'GBAUIRLMANCIX', value: '' },
    { id: 'GBAUBELBFSCIX', value: '' },
    { id: 'GBAUHOLBHXFYX', value: '' },
    { id: 'GBAUSOUSTNDEP1', value: '' },
    { id: 'GBAUGLWGLADHX', value: '' },
    { id: 'GBAUMNCMANNCS', value: '' },
    { id: 'GBAUSMEBHXPGE', value: '' },
    { id: 'GBAUHROLHRLHR', value: '' },
    { id: 'GBAUFEMLHRVCX', value: '' },
    { id: 'GBAUABDABZVCX', value: '' },
    { id: 'GBAUNHPLTNERE', value: '' },
    { id: 'GBAUMAWSTNFPL1', value: '' },
    { id: 'GBAUCWYLGWEEZ1', value: '' },
    { id: 'GBAUMRDDOVFDP1', value: '' },
    { id: 'GBAUDFDDOVFDD1', value: '' },
    { id: 'GBAUTAWBHXFXK1', value: '' },
    { id: 'GBAUNCLNCLFXN1', value: '' },
    { id: 'GBAUEDIEDIFXE1', value: '' },
    { id: 'GBAUASDDOVGBA1', value: '' },
    { id: 'GBAUCLLBHXHSX', value: '' },
    { id: 'GBAUFEMLHRIRX', value: '' },
    { id: 'GBAUCLLBHXIFE', value: '' },
    { id: 'GBAUEMAEMAKND1', value: '' },
    { id: 'GBAUKNWLPLLSK', value: '' },
    { id: 'GBAUBASLONMAG1', value: '' },
    { id: 'GBAUHESLHRMGX', value: '' },
    { id: 'GBAULGPLGPPFS1', value: '' },
    { id: 'GBAUNCLNCLPET', value: '' },
    { id: 'GBAUGLWGLAPET', value: '' },
    { id: 'GBAUDVRDOVDCT1', value: '' },
    { id: 'GBAUCVTBHXPME', value: '' },
    { id: 'GBAUDVRDOGPLE', value: '' },
    { id: 'GBAUAREEDIRIX', value: '' },
    { id: 'GBAURCSMEDRSW1', value: '' },
    { id: 'GBAUPESLGWRLW1', value: '' },
    { id: 'GBAULANLHRRMX', value: '' },
    { id: 'GBAUPOOBOHBXX', value: '' },
    { id: 'GBAUBASTILRXX', value: '' },
    { id: 'GBAULWMLBARXX', value: '' },
    { id: 'GBAUNTABFSRXX', value: '' },
    { id: 'GBAUCVTBHXSSW2', value: '' },
    { id: 'GBAUAVOBRSBMX', value: '' },
    { id: 'GBAUHOULHRPOS', value: '' },
    { id: 'GBAUCAYLHRTUN1', value: '' },
    { id: 'GBAUBASTILPXQ1', value: '' },
    { id: 'GBAUBOLSTNWCS1', value: '' },
    { id: 'GBAUGYSLONFPY1', value: '' },
    { id: 'GBAUPFTTILFPY2', value: '' },
    { id: 'GBAUNURSTNYOU1', value: '' },
    { id: 'GBAULGXLGPZIE1', value: '' },
    { id: 'GBAULHRLHRBAS', value: '' },
    { id: 'GBAUSLOLHRTPX', value: '' },
    { id: 'GBAUWRNMANNIE', value: '' },
    { id: 'GBAUTLELIVETC', value: '' },
    { id: 'GBAUAMSBDNMOD', value: '' },
    { id: 'GBAUODMODIRAF', value: '' },
] as const;
