import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { DeclarationBlock } from '../../../../../utils/DeclarationBlock';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'specificCircumstancesCodeCode';

export const specificCircumstanceIndicatorBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        specificCircumstancesCodeCode: [validators.exact(3)],
    },
};

interface Props extends BlockProps<Fields> {}

const SpecificCircumstanceIndicatorBlock = ({ fieldOptions, hide }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '1/7', []);

    return (
        <DeclarationBlock hide={hide}>
            <DeclarationField name="specificCircumstancesCodeCode">
                {({ form, field }) => (
                    <FormSelect
                        {...getFormikProps(field.name, form)}
                        refNumber={ucc}
                        label={t('specificCircumstanceIndicator')}
                        selectOptions={[{ id: 'A20', value: 'A20' }]}
                        condensed
                    />
                )}
            </DeclarationField>
        </DeclarationBlock>
    );
};

export default SpecificCircumstanceIndicatorBlock;
