import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PreviousDocumentCodesLabels } from 'store/declarations/enums/ireland/previous-document-codes';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';

const PreviousDocumentCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getIrelandExportTooltipsByRefNumberAndField } = useTooltips();

    const handleToggleHelp = (refNumber: string | number): void => {
        props.toggleHelp && props.toggleHelp(refNumber);
    };

    const previousDocument = useMemo(() => {
        return getFormikProps(`previousDocument`, props);
    }, [props]);

    const previousDocumentType = useMemo(() => {
        return getFormikProps(`previousDocumentType`, props);
    }, [props]);

    const previousDocumentRelevantDoc = useMemo(() => {
        return getFormikProps(`previousDocumentReference`, props);
    }, [props]);

    const previousDocumentNumber = useMemo(() => {
        return getFormikProps(`previousDocumentNumber`, props);
    }, [props]);

    useEffect(() => {
        const updatePreviousDocument = () => {
            const previousDoc = `${previousDocumentType.fieldProps.value}${previousDocumentRelevantDoc.fieldProps.value}${previousDocumentNumber.fieldProps.value}`;
            previousDocument.fieldHelper?.setValue(previousDoc);
        };

        const fillForm = () => {
            if (
                !previousDocumentType.fieldProps.value &&
                !previousDocumentRelevantDoc.fieldProps.value &&
                !previousDocumentNumber.fieldProps.value &&
                previousDocument.fieldProps.value
            ) {
                const pd: string = previousDocument.fieldProps.value;
                const type = pd.substring(0, 1);
                const relevant = pd.substring(1, 7);
                const number = pd.substring(7);
                previousDocumentType.fieldHelper?.setValue(type);
                previousDocumentRelevantDoc.fieldHelper?.setValue(relevant);
                previousDocumentNumber.fieldHelper?.setValue(number);
            }
        };

        if (
            previousDocumentType.fieldProps.value &&
            previousDocumentRelevantDoc.fieldProps.value &&
            previousDocumentNumber.fieldProps.value
        ) {
            updatePreviousDocument();
        } else {
            fillForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        previousDocumentType.fieldProps.value,
        previousDocumentRelevantDoc.fieldProps.value,
        previousDocumentNumber.fieldProps.value,
        previousDocument.fieldProps.value,
    ]);

    return (
        <section
            id="previous-document-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('previous-document-form-card');
                }
            }}
        >
            <FormCard
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('previousDocument')}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                expandAll={props.expandAll}
                keyCard={props.keyCard}
                state={getCardState(
                    [
                        { name: `previousDocumentType`, required: false },
                        { name: `previousDocumentReference`, required: false },
                        { name: `previousDocumentIdentifier`, required: false },
                    ],
                    props
                )}
            >
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`previousDocumentType`, props)}
                    refNumber="40"
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('40', 'Previous Document Type')}
                    refClicked={handleToggleHelp}
                    label={t('previousDocumentType')}
                    selectOptions={PreviousDocumentCodesLabels}
                />

                <FormInput
                    maxLength={6}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`previousDocumentReference`, props)}
                    refNumber="40"
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('40', 'Previous Document Reference')}
                    refClicked={handleToggleHelp}
                    label={t('previousDocumentReference')}
                />
                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`previousDocumentIdentifier`, props)}
                    refNumber="40"
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('40', 'Previous Document Identifier')}
                    refClicked={handleToggleHelp}
                    label={t('previousDocumentIdentifier')}
                />
            </FormCard>
        </section>
    );
};

export default PreviousDocumentCard;
