import { FC } from 'react';

const EuIcon: FC = () => {
    return (
        <svg width="37" height="37" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 96H480V416H32V96Z" fill="#2A2FAF" />
            <path
                d="M255.997 136L261.386 152.584H278.823L264.716 162.833L270.104 179.416L255.997 169.167L241.89 179.416L247.279 162.833L233.172 152.584H250.609L255.997 136Z"
                fill="#FFE70E"
            />
            <path
                d="M255.997 328L261.386 344.584H278.823L264.716 354.833L270.104 371.416L255.997 361.167L241.89 371.416L247.279 354.833L233.172 344.584H250.609L255.997 328Z"
                fill="#FFE70E"
            />
            <path
                d="M191.997 296L197.386 312.584H214.823L200.716 322.833L206.104 339.416L191.997 329.167L177.89 339.416L183.279 322.833L169.172 312.584H186.609L191.997 296Z"
                fill="#FFE70E"
            />
            <path
                d="M191.997 168L197.386 184.584H214.823L200.716 194.833L206.104 211.416L191.997 201.167L177.89 211.416L183.279 194.833L169.172 184.584H186.609L191.997 168Z"
                fill="#FFE70E"
            />
            <path
                d="M319.997 296L325.386 312.584H342.823L328.716 322.833L334.104 339.416L319.997 329.167L305.89 339.416L311.279 322.833L297.172 312.584H314.609L319.997 296Z"
                fill="#FFE70E"
            />
            <path
                d="M319.997 168L325.386 184.584H342.823L328.716 194.833L334.104 211.416L319.997 201.167L305.89 211.416L311.279 194.833L297.172 184.584H314.609L319.997 168Z"
                fill="#FFE70E"
            />
            <path
                d="M151.997 232L157.386 248.584H174.823L160.716 258.833L166.104 275.416L151.997 265.167L137.89 275.416L143.279 258.833L129.172 248.584H146.609L151.997 232Z"
                fill="#FFE70E"
            />
            <path
                d="M359.997 232L365.386 248.584H382.823L368.716 258.833L374.104 275.416L359.997 265.167L345.89 275.416L351.279 258.833L337.172 248.584H354.609L359.997 232Z"
                fill="#FFE70E"
            />
        </svg>
    );
};
export default EuIcon;
