import StatusTag from 'components/ui/base/status/StatusTag';
import { enumToText } from 'core/utils/enum-to-text';
import { FC } from 'react';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { colors } from 'theme';

interface Props {
    status: DeclarationStatus;
    multiple?: boolean;
}

export const declarationStatusColor = {
    [DeclarationStatus.DRAFT]: colors.grey,
    [DeclarationStatus.SUBMITTED]: colors.lightGreenA70,
    [DeclarationStatus.REJECTED]: colors.lightRed,
    [DeclarationStatus.INVALID]: colors.lightRed,
    [DeclarationStatus.AWAITING_TARIFF_CALCULATION]: colors.lightOrange,
    [DeclarationStatus.ACCEPTED]: colors.lightGreen2,
    [DeclarationStatus.AWAITING_RISK]: colors.lightOrange,
    [DeclarationStatus.UNDER_PAYMENT]: colors.lightOrange1,
    [DeclarationStatus.INSUFFICIENT_FUNDS]: colors.pink,
    [DeclarationStatus.UNDER_CONTROL]: colors.darkOrange,
    [DeclarationStatus.RELEASED]: colors.darkGreen,
    [DeclarationStatus.REGISTERED]: colors.lightGreenA70,
    [DeclarationStatus.NOT_RELEASED]: colors.lightPink,
    [DeclarationStatus.UPLOAD_DOCUMENTS]: colors.grey,
    [DeclarationStatus.INVALIDATE]: colors.black,
    [DeclarationStatus.RISKED]: colors.darkOrange,
};

const DeclarationStatusTag: FC<Props> = ({ status, multiple }) => {
    if (multiple) {
        return <StatusTag color={colors.grey} status="Multiple" />;
    }
    return <StatusTag color={declarationStatusColor[status]} status={enumToText(status)} />;
};
export default DeclarationStatusTag;
