import { FormikProvider, useFormik } from 'formik';
import { FC, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import AdditionalInformationBlock, {
    additionalInformationBlockValidation,
    additionalInformationFields,
} from './blocks/AdditionalInformationBlock';
import AdditionalDocumentBlock, {
    additionalDocumentBlockValidation,
    additionalDocumentFields,
} from './blocks/AdditionalDocumentBlock';
import PreviousDocumentsBlock, {
    previousDocumentsBlockProductValidation,
    previousDocumentsProductFields,
} from './blocks/PreviousDocumentsBlock';
import { customerCardProductValidation } from './cards/CustomerCard';
import AdditionalFiscalReferencesBlock, {
    additionalFiscalReferencesBlockProductValidation,
    additionalFiscalReferencesProductFields,
} from './blocks/AdditionalFiscalReferencesBlock';
import AdditionalSupplyChainActorBlock, {
    additionalSupplyChainActorBlockProductValidation,
    additionalSupplyChainActorProductFields,
} from './blocks/AdditionalSupplyChainActorBlock';
import NatureOfTransactionCard, { natureOfTransactionCardProductValidation } from './cards/NatureOfTransactionCard';
import ItemFieldsCard, { itemFieldsCardValidation } from './cards/Products/ItemFieldsCard';
import PackagingCard, { packagingCardValidation } from './cards/Products/PackagingCard';
import ProcedureCard, { procedureCardValidation } from './cards/Products/ProcedureCard';
import CountryOfOriginBlock, {
    countryOfOriginBlockValidation,
    countryOfOriginFields,
} from './blocks/CountryOfOriginBlock';
import useProducts from 'hooks/useProducts';
import { CdsExportGovernmentAgencyGoodsItem } from 'store/declarations/uk/export-declaration';
import { useParams } from 'react-router';
import CdsExportDeclarationUtils from './utils';
import { ValidationSchema } from './validations/validations';
import PartiesAddressForm from 'views/declarations/common/parties/cds/PartiesAddressForm';
import PartiesEoriForm from 'views/declarations/common/parties/cds/PartiesEoriForm';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import { ProductContext } from 'views/declarations/common/declaration-view/DeclarationView';
import { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import TransportEquipmentCard, { transportEquipmentCardValidationProduct } from './cards/TransportEquipmentCard';
import CommodityCodesCard, { commodityCodesCardValidation } from './cards/Products/CommodityCodesCard';

// Multiple items card

const previousDocumentsCardValidation = {
    selfValidators: [previousDocumentsBlockProductValidation],
};

const additionalSupplyChainActorCardValidation = {
    selfValidators: [additionalSupplyChainActorBlockProductValidation],
};

const additionalFiscalReferencesCardValidation = {
    selfValidators: [additionalFiscalReferencesBlockProductValidation],
};

const countryOfOriginCardValidation = {
    selfValidators: [countryOfOriginBlockValidation],
};

export const cdsExportProductSectionValidation: ValidationSchema = {
    selfValidators: [
        commodityCodesCardValidation,
        itemFieldsCardValidation,
        customerCardProductValidation,
        additionalInformationBlockValidation,
        additionalDocumentBlockValidation,
        natureOfTransactionCardProductValidation,
        packagingCardValidation,
        previousDocumentsCardValidation,
        additionalSupplyChainActorCardValidation,
        additionalFiscalReferencesCardValidation,
        countryOfOriginCardValidation,
        transportEquipmentCardValidationProduct,
        procedureCardValidation,
    ],
};

interface Props {
    formik: ReturnType<typeof useFormik>; // FIXME use correct type
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    cancelFetchProduct?: boolean;
}

const UkExportProductsSection: FC<Props> = ({ formik, toggleHelp, viewOnly, cancelFetchProduct = false }) => {
    const { t } = useTranslation('form');

    const { productId, declarationId } = useParams<string>();
    const { getUkExportProduct, product } = useProducts({ productId });
    const { setProductId } = useContext(ProductContext);

    useEffect(() => {
        const setUpContextAndFormik = (product: any, productId: string | undefined) => {
            setProductId?.(productId);
            formik.setValues(
                CdsExportDeclarationUtils.transformProductForClient(
                    (product as any)?.governmentAgencyGoodsItem as CdsExportGovernmentAgencyGoodsItem
                ) ?? {}
            );
        };

        const fetchProduct = async (declarationId: string, productId: string) => {
            const fetchedProduct = await getUkExportProduct(declarationId, productId);
            setUpContextAndFormik(fetchedProduct, productId);
        };

        if (declarationId && productId && productId !== 'new') {
            if (product?.id !== productId && !cancelFetchProduct) {
                fetchProduct(declarationId, productId);
            } else {
                setUpContextAndFormik(product, product?.id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, declarationId, cancelFetchProduct]);

    const totalNumberOfCards = useMemo(() => 15, []);

    return (
        <FormikProvider value={formik}>
            <CommodityCodesCard />

            <ItemFieldsCard
                keyCard={`item-fields-card`}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={3}
            />
            <ProcedureCard
                keyCard="procedure-card"
                formik={formik}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={4}
            />
            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'consignee',
                        header: 'Consignee',
                        refNumber: '3/9',
                    },
                ]}
                paths={{
                    address: {
                        city: 'address.cityName',
                        country: 'address.countryCode',
                        name: 'name',
                        postCode: 'address.postcodeID',
                        streetAndNumber: 'address.line',
                    },
                    additional: {
                        representativeStatus: 'functionCode',
                    },
                    eori: 'id',
                }}
                PartiesAddressForm={PartiesAddressForm}
                PartiesEoriForm={PartiesEoriForm}
                viewOnly={viewOnly}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={5}
                keyCard={`parties-card`}
                condensed
            />
            <MultipleItemsCard
                title={t('additionalInformation.title')}
                keyCard="uk-export-additionalInformation"
                viewOnly={viewOnly}
                path="additionalInformation"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={7}
                validate={additionalInformationFields}
                initialValues={{ statementCode: '', statementDescription: '' }}
                list={(list) => [
                    { field: t('additionalInformationCode'), value: list.statementCode },
                    { field: t('additionalInformationDescription'), value: list.statementDescription },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalInformationBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title={t('additionalDocument.title')}
                keyCard="uk-export-additionalDocument"
                viewOnly={viewOnly}
                path="additionalDocument"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={8}
                validate={additionalDocumentFields}
                initialValues={{
                    code: '',
                    id: '',
                    lpcoExemptionCode: '',
                    name: '',
                    effectiveDateTime: '',
                    submitter: { name: '' },
                    writeOff: { quantityQuantity: '', amountAmount: '' },
                }}
                condensed
                list={(list) => [
                    { field: t('documentsProduced.code'), value: list.code },
                    { field: t('documentsProduced.identifier'), value: list.id },
                    { field: t('documentsProduced.status'), value: list.lpcoExemptionCode },
                    { field: t('documentsProduced.reason'), value: list.name },
                    { field: t('writingOff.dateOfValidity'), value: list.effectiveDateTime },
                    { field: t('writingOff.issuingAuthority'), value: list.submitter?.name },
                    { field: t('writingOff.measurementUnitAndQualifier'), value: list.writeOff?.amountAmount },
                    { field: t('writingOff.quantity'), value: list.writeOff?.quantityQuantity },
                ]}
                required={false}
                formik={formik}
            >
                {(path) => (
                    <FormCardContainer oneColumn>
                        <AdditionalDocumentBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <NatureOfTransactionCard
                propsPathPrefix=""
                viewOnly={viewOnly}
                keyCard={`nature-of-transaction`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={9}
            />
            <PackagingCard
                viewOnly={viewOnly}
                keyCard={`uk-export-packaging-card`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={10}
                formik={formik}
            />
            <MultipleItemsCard
                title={t('previousDocuments')}
                keyCard="uk-export-previousDocuments"
                viewOnly={viewOnly}
                path="previousDocument"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={11}
                validate={previousDocumentsProductFields}
                initialValues={{ categoryCode: '', typeCode: '', id: '', lineNumeric: '' }}
                list={(list) => [
                    { field: t('previousDocumentCategory'), value: list.categoryCode },
                    { field: t('previousDocumentType'), value: list.typeCode },
                    { field: t('previousDocumentReference'), value: list.id },
                    { field: t('previousDocumentCode'), value: list.lineNumeric },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <PreviousDocumentsBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title={t('AdditionalSupplyChain')}
                keyCard="uk-export-additional-supply-chain-actor"
                viewOnly={viewOnly}
                path="aeoMutualRecognitionParty"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={12}
                validate={additionalSupplyChainActorProductFields}
                initialValues={{ id: '', roleCode: '' }}
                list={(list) => [
                    { field: t('identificationNumber'), value: list.id },
                    { field: t('roleCode'), value: list.roleCode },
                ]}
                formik={formik}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalSupplyChainActorBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title={t('AddFiscalRefIdentification')}
                keyCard="uk-export-additional-references"
                viewOnly={viewOnly}
                path="domesticDutyTaxParty"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={13}
                validate={additionalFiscalReferencesProductFields}
                initialValues={{ id: '', roleCode: '' }}
                list={(list) => [
                    { field: t('vatIdentificationNumber'), value: list.id },
                    { field: t('roleCode'), value: list.roleCode },
                ]}
                formik={formik}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalFiscalReferencesBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title={t('countryOfOrigin')}
                keyCard="uk-export-country-of-origin"
                viewOnly={viewOnly}
                path="origin"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={14}
                validate={countryOfOriginFields}
                initialValues={{ countryCode: '', typeCode: '' }}
                list={(list) => [
                    { field: t('countryOfOriginCountryCode'), value: list.countryCode },
                    { field: t('countryOfOriginRole'), value: list.typeCode },
                ]}
                formik={formik}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <CountryOfOriginBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <TransportEquipmentCard
                isProduct
                propsPathPrefix="commodity.transportEquipment"
                viewOnly={viewOnly}
                keyCard={`uk-export-container-identification-number`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={15}
            />
        </FormikProvider>
    );
};
export default UkExportProductsSection;
