import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import { ValidationSchema, validators } from '../validations/validations';
import CountrySelect from '../../../../../components/ui/composed/declarations/CountrySelect';

type Fields = 'sequenceNumeric' | 'routingCountryCode';

export const countriesOfRoutingCodesBlockValidation: {
    childValidators: {
        'consignment.itinerary': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'consignment.itinerary': {
            childValidators: {
                sequenceNumeric: [validators.required(), validators.number(), validators.max(2)],
                routingCountryCode: [validators.required(), validators.exact(2)],
            },
            stopAtNull: true,
        },
    },
};

export const countriesOfRoutingCodesFields =
    countriesOfRoutingCodesBlockValidation.childValidators['consignment.itinerary'];

interface Props extends BlockProps<Fields> {}

const CountriesOfRoutingCodesBlock = ({ path = 'consignment.itinerary', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '5/18', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="sequenceNumeric">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        required
                        refNumber={ucc}
                        label={t('sequenceNumeric')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="routingCountryCode">
                {({ form, field }: FieldProps<any>) => (
                    <CountrySelect
                        required
                        refNumber={ucc}
                        label={t('routingCountryCode')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default CountriesOfRoutingCodesBlock;
