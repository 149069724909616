import { Col, Row } from 'antd';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';

interface Props extends BlockProps<'id'> {}

const PlaceOfLoadingAirportCodeBlock = ({
    fieldOptions,
    path = 'goodsShipment.consignment.loadingLocation',
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '5/21', []);
    return (
        <Row gutter={18}>
            <Col xs={fieldOptions?.id?.columnSpan ?? 24}>
                <DeclarationField
                    name="id"
                    pathPrefix={path}
                    validation={{
                        name: t('placeOfLoadingAirportCode'),
                        max: 17,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        // TODO : Make select
                        <FormInput
                            refNumber={ucc}
                            label={t('placeOfLoadingAirportCode')}
                            {...getFormikProps(field.name, form)}
                        />
                    )}
                </DeclarationField>
            </Col>
        </Row>
    );
};

export default PlaceOfLoadingAirportCodeBlock;
