import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { FormikProvider } from 'formik';
import useTooltips from 'hooks/useTooltips';
import { set } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { additionalDeclarationTypesLabels } from 'store/declarations/enums/common/additional-declaration-type';
import { declarationTypeLabels } from 'store/declarations/enums/common/declaration-type';
import {
    getBox44FieldPath,
    h1Box44KeyNames,
    h1PathBox44,
    handleBox44Field,
} from 'views/declarations/common/box44/box-44-utils';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import AutoFillModal from '../components/AutoFillModal';
import useNumberOfItemsModal from 'views/declarations/common/declaration-view/utils/useNumberOfItemsModal';
import useProducts from 'hooks/useProducts';

const PREV_DOC_PATH = 'goodsShipment.simplifiedDeclarationDocumentWritingOff';

const MasterDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumber } = useTooltips();
    const { template, form, templateFormik } = useTemplateContext();
    const { createIrelandImportProduct, listIrelandH1Products } = useProducts();

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | null>(null);
    const [prevValue, setPrevValue] = useState<string | null>(null);
    const [changedInvoiceNumber, setChangedInvoiceNumber] = useState(false);

    const openModal = (value: string | null) => {
        setChangedInvoiceNumber(false);
        setOpen(true);
        setValue(value);
    };
    const closeModal = () => {
        setOpen(false);
        setValue(null);
        setPrevValue(value);
    };

    const formik = useMemo(() => {
        if (template && templateFormik) return templateFormik;
        return props.formik;
    }, [props.formik, template, templateFormik]);

    const prevDocumentsIndex = useMemo(() => {
        const prevDocsRecords = formik?.getFieldProps(PREV_DOC_PATH).value;

        const prevDocWithType380 = prevDocsRecords?.findIndex((prevDoc: any) => prevDoc.previousDocumentType === '380');

        if (prevDocWithType380 !== -1) return prevDocWithType380;

        return prevDocsRecords.length;
    }, [formik]);

    const autoFill = (fields: string[]) => {
        const values = formik?.values;
        const newValues = fields.reduce((acc, field) => set(acc, field, value), values);
        formik?.setValues(newValues);

        if (fields.includes(`${PREV_DOC_PATH}.${prevDocumentsIndex}.previousDocumentIdentifier`)) {
            formik?.setFieldValue(`${PREV_DOC_PATH}.${prevDocumentsIndex}.previousDocumentType`, '380');
        }
    };

    const [handleNumberOfItems, modalContextHolder] = useNumberOfItemsModal({
        createProduct: createIrelandImportProduct,
        listProducts: listIrelandH1Products,
    });

    return (
        <section
            id={'master-details-form-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('master-details-form-card');
                }
            }}
            data-testid="master-details-form-card"
        >
            <FormikProvider value={props.formik!}>
                <NewFormCard title={t('masterDetails')}>
                    <FormCardContainer>
                        <FormSelect
                            required
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`declarationType`, props)}
                            refNumber="1.1"
                            label={t('declarationType')}
                            testId="declarationType"
                            tooltip={getH1TooltipsByRefNumber('1.1')}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            selectOptions={declarationTypeLabels}
                            disabled={props.amendment}
                            condensed
                        />

                        <FormSelect
                            required
                            disabled={props.amendment}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`additionalDeclarationType`, props)}
                            refNumber="1.2"
                            label={t('additionalDeclarationType')}
                            tooltip={getH1TooltipsByRefNumber('1.2')}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            selectOptions={additionalDeclarationTypesLabels}
                            testId="additional-declaration-type"
                            condensed
                        />

                        <FormInput
                            maxLength={35}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`goodsShipment.ucr`, props)}
                            refNumber="2.4"
                            label={t('ucr')}
                            testId="goodsShipment.ucr"
                            tooltip={getH1TooltipsByRefNumber('2.4')}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            condensed
                        />

                        <DeclarationNumberInput
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`numberOfItems`, props)}
                            onBlur={(e) => handleNumberOfItems(Number(e.target.value))}
                            required
                            label={t('numberOfItems')}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            testId="numberOfItems"
                            condensed
                        />
                        {modalContextHolder}

                        <FormInput
                            maxLength={512}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(
                                getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: 'N935' }),
                                props
                            )}
                            onChange={(e) => {
                                handleBox44Field(
                                    e.target.value,
                                    template && templateFormik ? templateFormik : props,
                                    {
                                        path: h1PathBox44,
                                        keyNames: h1Box44KeyNames,
                                        type: 'N935',
                                    },
                                    { template, form }
                                );
                                setChangedInvoiceNumber(true);
                            }}
                            label={t('Invoice Number')}
                            tooltip={
                                getH1TooltipsByRefNumber('N935') !== 'N/A'
                                    ? getH1TooltipsByRefNumber('N935')
                                    : undefined
                            }
                            refNumber="N935"
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            onBlur={(e) => {
                                const value = e.target.value;
                                if (!changedInvoiceNumber) return;
                                openModal(value);
                            }}
                            testId="invoice-number"
                            condensed
                        />
                        <AutoFillModal
                            visible={open}
                            onCancel={closeModal}
                            fields={[
                                { label: t('roadConsignmentNote'), name: `${h1PathBox44}.N730.0` },
                                {
                                    label: t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier'),
                                    name: `${PREV_DOC_PATH}.${prevDocumentsIndex}.previousDocumentIdentifier`,
                                },
                            ]}
                            value={value}
                            prevValue={prevValue}
                            onOk={autoFill}
                        />
                    </FormCardContainer>
                </NewFormCard>
            </FormikProvider>
        </section>
    );
};

export default MasterDetailsCard;
