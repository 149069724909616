import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import InlandModeOfTransportBlock, { inlandModeOfTransportBlockValidation } from '../blocks/InlandModeOfTransportBlock';
import ModeOfTransportAtTheBorderBlock, {
    modeOfTransportAtTheBorderBlockValidation,
} from '../blocks/ModeOfTransportAtTheBorderBlock';
import NationalityOfActiveMeansOfTransportCrossingTheBorderBlock, {
    nationalityOfActiveMeansOfTransportCrossingTheBorderBlockValidation,
} from '../blocks/NationalityOfActiveMeansOfTransportCrossingTheBorderBlock';

export const modeOfTransportCardValidation = {
    selfValidators: [
        modeOfTransportAtTheBorderBlockValidation,
        inlandModeOfTransportBlockValidation,
        nationalityOfActiveMeansOfTransportCrossingTheBorderBlockValidation,
    ],
};

interface Props extends DeclarationFormCardProps {
    isUkCdsImport?: boolean;
}

const ModeOfTransportCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('modeOfTransportAtTheBorder')}>
            <FormCardContainer>
                <ModeOfTransportAtTheBorderBlock fieldOptions={{ modeCode: { required: true } }} />
                <InlandModeOfTransportBlock />
                {!props.isUkCdsImport && <NationalityOfActiveMeansOfTransportCrossingTheBorderBlock />}
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ModeOfTransportCard;
