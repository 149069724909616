import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { additionalDeclarationTypesLabels } from 'store/declarations/enums/common/additional-declaration-type';
import { declarationTypeLabels } from 'store/declarations/enums/common/declaration-type';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const MasterDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    const { getCdsTooltipsByRefNumber, getCdsTooltipsByField } = useTooltips();

    return (
        <NewFormCard title={t('masterDetails')}>
            <FormCardContainer>
                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`declarationType`, props)}
                    refNumber="1.1"
                    label={t('declarationType')}
                    tooltip={getCdsTooltipsByRefNumber('1.1')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={declarationTypeLabels}
                    condensed
                />
                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`additionalDeclarationType`, props)}
                    refNumber="1.2"
                    label={t('addDeclarationTypes')}
                    tooltip={getCdsTooltipsByRefNumber('1.2')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={additionalDeclarationTypesLabels}
                    condensed
                />
                <FormInput
                    numbersAndLetters
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsShipment.ucr`, props)}
                    refNumber="2.4"
                    label={t('ucr')}
                    tooltip={getCdsTooltipsByField(t('ucr'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    required
                    refNumber="1.9"
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`numberOfItems`, props)}
                    label={t('numberOfItems')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default MasterDetailsCard;
