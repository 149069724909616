import { useState } from 'react';
import { StyledSider } from '../Form.styles';
import { NavItem } from './nav-item';
import VerticalNav from './VerticalNav';

interface Props {
    selectedBt?: number;
    navigationItems: NavItem[];
    onSelect?: (index: number) => void;
    activeSection?: number | undefined;
    productFormVisible?: boolean;
    hasAlert?: boolean;
    show?: boolean;
    active?: number;
    onChange?: (index: number) => void;
}
export const DeclarationVerticalNavigation = ({
    navigationItems,
    show,
    active: activeProp,
    onChange: setActiveProp,
}: Props) => {
    const [active, setActive] = useState(0);

    if (!show) return null;

    return (
        <StyledSider>
            <VerticalNav navs={navigationItems} setActive={setActiveProp ?? setActive} active={activeProp ?? active} />
        </StyledSider>
    );
};

export default DeclarationVerticalNavigation;
