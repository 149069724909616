import { Form } from 'antd';
import Button from 'components/ui/base/button';
import { FormItem } from 'components/ui/base/form';
import { TextSmall } from 'components/ui/base/typography';
import FormDatepicker from 'components/ui/composed/formDatepicker/FormDatepicker';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import { useFormik } from 'formik';
import useCustomers from 'hooks/useCustomers';
import useJourneys from 'hooks/useJourneys';
import { FC, useEffect, useMemo } from 'react';
import { DeclarationCustomer } from 'store/declarations/common/declaration-customer';
import { JobResponse } from 'store/jobs/job';
import { Journey } from 'store/journeys/journeys';
import { DetailsLabel, DetailsRow } from 'views/jobs/components/tables/JobsTable.styles';
import * as Yup from 'yup';

interface Props {
    job: JobResponse;
    closeDrawer: () => void;
    handleOk: (newJourney?: Journey) => void;
    handleEdit?: (journey?: Journey) => void;
    handleCreateError?: Function;
    handleEditError?: Function;
    journey?: Journey;
    exporters?: DeclarationCustomer[];
    importers?: DeclarationCustomer[];
}

const journeySchema = () => {
    return Yup.object().shape({
        id: Yup.string(),
        consignorId: Yup.string().required('Consignor is required.'),
        consigneeId: Yup.string().required('Consignee is required.'),
        startDateTime: Yup.string().required('Start date is required.'),
        endDateTime: Yup.string().required('End date is required.'),
        description: Yup.string().nullable(),
        specialInstructions: Yup.string().nullable(),
    });
};

const CreateJourney: FC<Props> = ({
    closeDrawer,
    handleOk,
    exporters,
    importers,
    handleEdit,
    handleCreateError,
    handleEditError,
    journey,
    job,
}) => {
    const { getFieldProps, resetForm, getFieldMeta, handleSubmit, isValid, getFieldHelpers } = useFormik<
        Partial<Journey>
    >({
        initialValues: journey || ({} as Journey),
        validationSchema: journeySchema(),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => createJourneySubmit(values),
    });

    const { createJourney, editJourney } = useJourneys();
    const { customers, listCustomers } = useCustomers();

    useEffect(() => {
        listCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createJourneySubmit = async (values: Partial<Journey>) => {
        const data = { ...values };
        data.jobId = job.id;
        if (journey && journey.id) {
            const response = await editJourney(data as Journey);
            if (response && response.id) {
                handleOk();
                resetForm();
            }
        } else {
            const response = await createJourney(data as Journey);
            if (response && response.id) {
                handleOk();
                resetForm();
            }
        }
    };

    const onCloseButton = () => {
        closeDrawer();
        resetForm();
    };

    const optionCustomers = useMemo(() => {
        return customers.list.map((obj) => {
            return {
                id: obj.id,
                value: obj.name,
            };
        });
    }, [customers]);

    return (
        <Form layout="vertical">
            <DetailsRow style={{ marginBottom: '2.4rem' }}>
                <DetailsLabel>Job id: </DetailsLabel>
                <TextSmall>{job?.referenceId || '-'}</TextSmall>
            </DetailsRow>
            <FormSelect
                required
                label="Consignor"
                hideKeys
                fieldMeta={getFieldMeta('consignorId')}
                fieldProps={getFieldProps('consignorId')}
                fieldHelper={getFieldHelpers('consignorId')}
                selectOptions={optionCustomers}
            />
            <FormSelect
                required
                label="Consignee"
                hideKeys
                fieldMeta={getFieldMeta('consigneeId')}
                fieldProps={getFieldProps('consigneeId')}
                fieldHelper={getFieldHelpers('consigneeId')}
                selectOptions={optionCustomers}
            />
            <FormDatepicker
                label={`Start Date Time`}
                showTime={true}
                fieldProps={getFieldProps('startDateTime')}
                fieldMeta={getFieldMeta('startDateTime')}
            />

            <FormDatepicker
                label={`End Date Time`}
                showTime={true}
                fieldProps={getFieldProps('endDateTime')}
                fieldMeta={getFieldMeta('endDateTime')}
            />
            <FormInput
                label="Description"
                fieldMeta={getFieldMeta('description')}
                fieldProps={getFieldProps('description')}
                fieldHelper={getFieldHelpers('description')}
            />
            <FormInput
                label="Special Instructions"
                fieldMeta={getFieldMeta('specialInstructions')}
                fieldProps={getFieldProps('specialInstructions')}
                fieldHelper={getFieldHelpers('specialInstructions')}
            />

            <FormItem style={{ textAlign: 'right', marginTop: '3.6rem' }}>
                <Button size="large" style={{ marginRight: '1.6rem' }} onClick={() => onCloseButton()}>
                    Cancel
                </Button>
                <Button disabled={!isValid} size="large" type="primary" onClick={() => handleSubmit()}>
                    Confirm
                </Button>
            </FormItem>
        </Form>
    );
};
export default CreateJourney;
