import { SearchOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import Drawer from 'components/ui/base/drawer/Drawer';
import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import CustomerEoriAddressRadioButton from 'components/ui/composed/declarations/customerEoriAddressRadioButton/CustomerEoriAddressRadioButton';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormEori from 'components/ui/composed/formEori/FormEori';
import { customerHasValues } from 'components/ui/composed/formEori/utils';
import SearchCustomer from 'components/ui/composed/searchCustomer/SearchCustomer';
import useTooltips from 'hooks/useTooltips';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from 'store/customers/customer';
import { Eori } from 'store/eori/eori';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import {
    appendFieldPath,
    fillCustomerFromListOfCustomers,
    getCardState,
    getFormikProps,
    handleToggleHelp,
} from 'views/declarations/utils/form-utils';
import { invalidEoriMessage } from 'views/declarations/utils/validation-utils';

export enum ExportCustomerTypes {
    CONSIGNEE = 'consignee',
    CONSIGNOR = 'consignor',
}

const ExportCustomerCard: FC<
    DeclarationFormCardProps & {
        type: ExportCustomerTypes;
        addressRefNumber?: string;
        eoriRefNumber?: string;
        statusCodeRefNumber?: string;
        addressRequired?: boolean;
        eoriRequired?: boolean;
        statusCodeRequired?: boolean;
        disableSearchCustomer?: boolean;
    }
> = (props) => {
    const { t } = useTranslation('form');
    const [showCustomers, setShowCustomers] = useState(false);
    const { getIrelandExportTooltipsByRefNumber, getIrelandExportTooltipsByRefNumberAndField } = useTooltips();

    const fullFieldPath = appendFieldPath(props.propsPathPrefix);
    const {
        getFieldProps,
        getFieldHelpers,
        type,
        addressRefNumber,
        eoriRefNumber,
        addressRequired,
        eoriRequired,
        disableSearchCustomer = false,
    } = props;

    const handleSearchCustomer = () => {
        setShowCustomers(!showCustomers);
    };

    const handleSelectCustomer = (customer: Customer) => {
        const path = fullFieldPath(`${type}`);
        fillCustomerFromListOfCustomers(customer, path, setShowCustomers, getFieldHelpers);
    };

    const fillEoriInfo = useCallback(
        (eori: Eori) => {
            const path = fullFieldPath(`${type}`);
            getFieldHelpers(`${path}.name`).setValue(eori?.name);
            getFieldHelpers(`${path}.addressLine1`).setValue(eori?.street);
            getFieldHelpers(`${path}.postCode`).setValue(eori?.postCode);
            getFieldHelpers(`${path}.city`).setValue(eori?.city);
            getFieldHelpers(`${path}.revenueNumber`).setValue(eori?.eori);
        },
        [fullFieldPath, getFieldHelpers, type]
    );

    const onError = useCallback(() => {
        const path = fullFieldPath(`${type}.revenueNumber`);
        getFieldHelpers!(path).setError(invalidEoriMessage);
    }, [fullFieldPath, getFieldHelpers, type]);

    const onSuccess = useCallback(() => {
        const path = fullFieldPath(`${type}.revenueNumber`);
        getFieldHelpers!(path).setError(undefined);
    }, [fullFieldPath, getFieldHelpers, type]);

    const hasValues = useMemo(() => {
        const path = fullFieldPath(`${type}`);
        return customerHasValues(getFieldProps, path);
    }, [fullFieldPath, getFieldProps, type]);

    return (
        <section
            id={'export-customer-form-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard(`${type}-form-card`);
                }
            }}
        >
            <FormCard
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t(`${type}`)}
                cardNumber={props.cardNumber}
                expandAll={props.expandAll}
                keyCard={props.keyCard}
                total={props.cardTotal}
                action={
                    !props.viewOnly && !disableSearchCustomer
                        ? { title: 'Search Customer', onClick: handleSearchCustomer, icon: <SearchOutlined /> }
                        : undefined
                }
                state={getCardState(
                    [
                        { name: `${type}.revenueNumber`, required: true },
                        { name: `${type}.name`, required: addressRequired },
                        { name: `${type}.addressLine1`, required: addressRequired },
                        { name: `${type}.addressLine2`, required: addressRequired },
                        { name: `${type}.countryCode`, required: addressRequired },
                        { name: `${type}.postCode`, required: addressRequired },
                    ],
                    props
                )}
            >
                <CustomerEoriAddressRadioButton>
                    <Row>
                        <Col sm={24}>
                            <FormEori
                                declaration
                                viewOnly={props.viewOnly}
                                required={eoriRequired}
                                {...getFormikProps(`${type}.revenueNumber`, props)}
                                refNumber={eoriRefNumber}
                                label={t('Revenue Number')}
                                hasValues={hasValues}
                                onApply={fillEoriInfo}
                                onError={onError}
                                onSuccess={onSuccess}
                                tooltip={getIrelandExportTooltipsByRefNumber(eoriRefNumber || '')}
                                refClicked={(ref) => handleToggleHelp(ref, props)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24}>
                            <FormInput
                                required={addressRequired}
                                maxLength={70}
                                viewOnly={props.viewOnly}
                                {...getFormikProps(`${type}.name`, props)}
                                refNumber={addressRefNumber}
                                label={t('name')}
                                tooltip={getIrelandExportTooltipsByRefNumber(addressRefNumber || '')}
                                refClicked={(ref) => handleToggleHelp(ref, props)}
                            />
                        </Col>
                        <Col sm={24}>
                            <FormInput
                                required={addressRequired}
                                maxLength={70}
                                viewOnly={props.viewOnly}
                                {...getFormikProps(`${type}.addressLine1`, props)}
                                refNumber={addressRefNumber}
                                label={t('addressLine1')}
                                tooltip={getIrelandExportTooltipsByRefNumberAndField(
                                    addressRefNumber || '',
                                    `${type} - Address Line 1`
                                )}
                                refClicked={(ref) => handleToggleHelp(ref, props)}
                            />
                        </Col>
                        <Col sm={24}>
                            <FormInput
                                required={addressRequired}
                                maxLength={70}
                                viewOnly={props.viewOnly}
                                {...getFormikProps(`${type}.addressLine2`, props)}
                                refNumber={addressRefNumber}
                                label={t('addressLine2')}
                                tooltip={getIrelandExportTooltipsByRefNumberAndField(
                                    addressRefNumber || '',
                                    `${type} - Address Line 2`
                                )}
                                refClicked={(ref) => handleToggleHelp(ref, props)}
                            />
                        </Col>
                        <Col sm={24}>
                            <FormInput
                                required={addressRequired}
                                maxLength={70}
                                viewOnly={props.viewOnly}
                                {...getFormikProps(`${type}.postCode`, props)}
                                refNumber={addressRefNumber}
                                label={t('postCode')}
                                tooltip={getIrelandExportTooltipsByRefNumberAndField(
                                    addressRefNumber || '',
                                    `${type} - Post Code`
                                )}
                                refClicked={(ref) => handleToggleHelp(ref, props)}
                            />
                        </Col>
                        <Col sm={24}>
                            <CountrySelect
                                viewOnly={props.viewOnly}
                                required={addressRequired}
                                refNumber={addressRefNumber}
                                {...getFormikProps(`${type}.countryCode`, props)}
                                tooltip={getIrelandExportTooltipsByRefNumberAndField(
                                    addressRefNumber || '',
                                    `${type} - Country`
                                )}
                                refClicked={(ref) => handleToggleHelp(ref, props)}
                            />
                        </Col>
                    </Row>
                </CustomerEoriAddressRadioButton>
            </FormCard>
            <Drawer
                title="Search for customer"
                width="1098"
                visible={showCustomers}
                onClose={() => {
                    setShowCustomers(false);
                }}
            >
                <SearchCustomer handleSelect={handleSelectCustomer} />
            </Drawer>
        </section>
    );
};
export default ExportCustomerCard;
