import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import addPathPrefix from '../../../../../../utils/addPathPrefix';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';
import MultipleItemsCard from '../../../../common/MultipleItemsCard';
import { guaranteeReference } from '../validation/ValidationSchema';

const GuaranteeReferenceCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix!}
            initialValues={{
                grn: '',
                otherGuaranteeReference: '',
                accessCode: '',
                amountOfImportDuty: '',
                currencyCode: '',
                customsOfficeOfGuarantee: '',
            }}
            list={(list) => [
                { field: t('guaranteeReference.grn'), value: list.grn },
                { field: t('guaranteeReference.otherGuaranteeReference'), value: list.otherGuaranteeReference },
                { field: t('accessCode'), value: list.accessCode },
                { field: t('guaranteeReference.amountOfImportDuty'), value: list.amountOfImportDuty },
                { field: t('currency'), value: list.currencyCode },
                { field: t('guaranteeReference.guaranteeCustomsOffice'), value: list.customsOfficeOfGuarantee },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            cardNumber={props.cardNumber}
            cardTotal={props.cardTotal}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            title={t('guaranteeReference.title')}
            condensed
            validationSchema={guaranteeReference}
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormInput
                        maxLength={24}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'grn')}`, formProps)}
                        refNumber="8.3"
                        label={t('guaranteeReference.grn')}
                        tooltip={getCdsTooltipsByRefNumberAndField('8.3', t('guaranteeReference.grn'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormInput
                        maxLength={35}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'otherGuaranteeReference')}`, formProps)}
                        refNumber="8.3"
                        label={t('guaranteeReference.otherGuaranteeReference')}
                        tooltip={getCdsTooltipsByRefNumberAndField(
                            '8.3',
                            t('guaranteeReference.otherGuaranteeReference')
                        )}
                        condensed
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                    />

                    <FormInput
                        maxLength={4}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'accessCode')}`, formProps)}
                        refNumber="8.3"
                        label={t('accessCode')}
                        tooltip={getCdsTooltipsByRefNumberAndField('8.3', t('accessCode'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <DeclarationNumberInput
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'amountOfImportDuty')}`, formProps)}
                        refNumber="8.3"
                        label={t('guaranteeReference.amountOfImportDuty')}
                        tooltip={getCdsTooltipsByRefNumberAndField('8.3', t('guaranteeReference.amountOfImportDuty'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <CurrencySelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'currencyCode')}`, formProps)}
                        refNumber="8.3"
                        tooltip={getCdsTooltipsByRefNumberAndField('8.3', t('currency'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormInput
                        maxLength={8}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'customsOfficeOfGuarantee')}`, formProps)}
                        refNumber="8.3"
                        label={t('guaranteeReference.guaranteeCustomsOffice')}
                        tooltip={getCdsTooltipsByRefNumberAndField(
                            '8.3',
                            t('guaranteeReference.guaranteeCustomsOffice')
                        )}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default GuaranteeReferenceCard;
