import Drawer from 'components/ui/base/drawer/Drawer';
import Notification from 'components/ui/base/notification/Notification';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import { FormikProps, setNestedObjectValues } from 'formik';
import Fuse from 'fuse.js';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarationProducts from 'hooks/useDeclarationProducts';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useProducts from 'hooks/useProducts';
import useProductsTemplates from 'hooks/useProductsTemplates';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { RevenueExportCustomsGoodsItem } from 'store/declarations/ireland/export-declaration';
import { ProductTemplate } from 'store/products-templates/products';
import { listIrelandExportProduct } from 'store/products/client';
import DeclarationFormTabContent from 'views/declarations/common/declaration-form/DeclarationFormTabContent';
import DeclarationsProductsTemplates from 'views/declarations/common/DeclarationsProductsTemplates';
import MasterProductDeclarationNav from 'views/declarations/common/MasterProductDeclarationNav';
import { FormSection } from 'views/declarations/DeclarationView';
import { StyledHeader } from 'views/declarations/Form.styles';
import { ProductsErrors } from 'views/declarations/sections/declaration-form/DeclarationForm';
import FormHelperDrawer from 'views/declarations/tooltips-help/FormHelpDrawer';
import { exportSectionsMasterDetails, exportSectionsProducts } from './constants';
import { ProductContext } from 'views/declarations/common/declaration-view/DeclarationView';

interface Props {
    formik: FormikProps<any>;
    productsFormik: FormikProps<any>;
    declaration: Declaration;
    cancelProducts: boolean;
    clearCancel: Function;
    saveProduct: boolean;
    clearSaveProduct: Function;
    updateDeclaration: Function;
    hasAlert?: boolean;
    declarationErrors: ProductsErrors;
}

const IrelandExportTabContent: FC<Props> = ({
    formik,
    productsFormik,
    declaration,
    cancelProducts,
    clearCancel,
    saveProduct,
    clearSaveProduct,
    updateDeclaration,
    hasAlert,
    declarationErrors,
}) => {
    const { setDeclarationFooterType, expandDeclarationFooter } = useDeclarationFooter();
    const { declarationId }: { declarationId?: string } = useParams();
    const location = useLocation();
    const [selectedBt, setSelectedBt] = useState<FormSection>(
        location.pathname === `/declarations/${declarationId}` ? FormSection.MASTER_DETAIlS : FormSection.PRODUCTS
    );
    const [showProducts, setShowProducts] = useState<boolean>(false);
    const [showHelp, setShowHelp] = useState<boolean>(false);
    const [helpSection, setHelpSection] = useState<number | string | undefined>();
    const [searchQuery, setSearchQuery] = useState('');
    const [myProducts, setMyProducts] = useState<RevenueExportCustomsGoodsItem[]>([]);
    const {
        deleteIrelandExportProduct,
        error: productsError,
        isLoading: productsIsLoading,
        getIrelandExportProduct,
        products,
        listIrelandExportProducts,
    } = useProducts();
    const [expandAllCards, setExpandAllCards] = useState(false);
    const { productsTemplates, listH1ProductsTemplates, saveH1ProductTemplate, error, isLoading } =
        useProductsTemplates({
            country: DeclarationCountry.IRELAND,
        });
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>();
    const [paginatorProducts] = useState<Partial<PaginatedParams>>();
    const { addProductsTemplatesToIrelandExport } = useDeclarationProducts();
    const { declarationValidation, setFormAction } = useDeclarationValidation();
    const navigate = useNavigate();
    const controlProduct = useContext(ProductContext);

    useEffect(() => {
        expandDeclarationFooter();
        setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
        listH1ProductsTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedBt(
            location.pathname === `/declarations/${declarationId}` ? FormSection.MASTER_DETAIlS : FormSection.PRODUCTS
        );
    }, [location, declarationId]);

    useEffect(() => {
        if (myProducts.length === 0 && declarationId) {
            listIrelandExportProduct(declarationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (paginator) {
            const p = { ...paginator };
            listH1ProductsTemplates(p);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator]);

    useEffect(() => {
        if (paginatorProducts && declarationId) {
            const p = { ...paginatorProducts };
            listIrelandExportProducts(declarationId, p);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginatorProducts]);

    const declarationHasItems = useMemo(() => products?.total, [products]);

    useEffect(() => {
        if (products?.total && products.total > 0) {
            const listProducts: RevenueExportCustomsGoodsItem[] = products.list as RevenueExportCustomsGoodsItem[];
            setMyProducts(listProducts);
        }
    }, [products]);

    useEffect(() => {
        if (cancelProducts) {
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
            clearCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelProducts]);

    useEffect(() => {
        if (saveProduct) {
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
            clearSaveProduct();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveProduct]);

    useEffect(() => {
        if (searchQuery && products?.list) {
            const array = [...products.list];
            const fuse = new Fuse(array, {
                keys: ['packaging.description', 'commodity.nomenclatureCode'],
            });
            const res: unknown[] = fuse.search(searchQuery);
            setMyProducts(res as RevenueExportCustomsGoodsItem[]);
        }
        if (!searchQuery) {
            setMyProducts(products?.list as RevenueExportCustomsGoodsItem[]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    useEffect(() => {
        if (controlProduct.setProductId) {
            controlProduct.setProductId(undefined);
        }
        if (selectedBt === 0) {
            if (!declarationValidation.formAction) {
                setFormAction(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBt]);

    const addNewProduct = useCallback(() => {
        productsFormik.resetForm();
        setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
    }, [setDeclarationFooterType, productsFormik]);

    const verticalNavigation = useMemo(() => {
        return selectedBt === 0 ? exportSectionsMasterDetails : exportSectionsProducts;
    }, [selectedBt]);

    /*
    const handlePaginationProductsOfDeclaration = (params: Partial<PaginatedParams>) => {
        if (paginatorProducts && paginatorProducts.page && paginatorProducts.size) {
            setPaginatorProducts({ page: params.page, size: paginatorProducts.size });
        } else {
            setPaginatorProducts({ page: params.page, size: defaultPagination.size });
        }
    };
    */

    const handleDeclarationView = useMemo(() => {
        const handleDeleteProducts = async (id: string) => {
            setShowProducts(false);
            await deleteIrelandExportProduct(id, declaration.id!);
            if (!productsError) {
                Notification({
                    type: 'success',
                    messageTitle: 'Product Deleted',
                    description: 'Product has been successfully deleted!',
                });
                updateDeclaration();
            }
        };

        const handleEditProducts = async (id: string) => {
            if (declarationHasItems) {
                const product = await getIrelandExportProduct(declaration.id!, id);
                if (product) {
                    if (controlProduct.setProductId) {
                        controlProduct.setProductId(product.id);
                    }
                    productsFormik.setValues(product);
                    if (declarationValidation.pressedSubmit === true) {
                        await productsFormik.validateForm().then((v) => {
                            productsFormik.setTouched(setNestedObjectValues(v, true));
                            return v;
                        });
                    }
                    navigate(`/declarations/${declarationId}/products/${product.id}`);
                }
            }
        };

        const handleToggleHelp = (refNumber: string | number) => {
            setHelpSection(refNumber);
            setShowHelp(true);
        };
        return (
            <Outlet
                context={{
                    formik: formik,
                    productsFormik: productsFormik,
                    toggleHelp: handleToggleHelp,
                    expandAll: expandAllCards,
                    setSearchQuery,
                    errors: declarationErrors,
                    data: myProducts,
                    onDelete: handleDeleteProducts,
                    onEdit: handleEditProducts,
                    itemsNumber: declarationHasItems,
                    // handlePagination: handlePaginationProductsOfDeclaration,
                    internalType: DeclarationInternalType.EXPORT,
                    pageNumber: products?.pageNumber ?? 0,
                }}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        declaration,
        myProducts,
        selectedBt,
        formik,
        productsFormik,
        declarationHasItems,
        deleteIrelandExportProduct,
        updateDeclaration,
        productsError,
        error,
        listH1ProductsTemplates,
        saveH1ProductTemplate,
        productsIsLoading,
        isLoading,
        declarationErrors,
        products,
    ]);

    const handlePagination = (params: Partial<PaginatedParams>) => {
        if (paginator && paginator.page && paginator.size) {
            setPaginator({ page: params.page, size: paginator.size });
        } else {
            setPaginator({ page: params.page, size: defaultPagination.size });
        }
    };

    const handleSort = (params: Partial<PaginatedParams>) => {
        setPaginator({ ...paginator, ...params });
    };

    const handleAddProducts = (products: ProductTemplate[]) => {
        setShowProducts(false);
        const productTemplatesIds = products.map((obj) => obj.id!);
        addProductsTemplatesToIrelandExport(declaration.id!, productTemplatesIds);
    };

    return (
        <>
            <FormHelperDrawer
                section={helpSection}
                isVisible={showHelp}
                onClose={() => setShowHelp(false)}
                internalType={DeclarationInternalType.EXPORT}
                declarationCountry={DeclarationCountry.IRELAND}
            />
            <StyledHeader>
                <MasterProductDeclarationNav
                    selectedBt={selectedBt}
                    handleCollapseAllCards={() => {
                        setExpandAllCards((expandAllCards) => !expandAllCards);
                    }}
                    expandAllCards={expandAllCards}
                    addNewProduct={addNewProduct}
                    setShowProducts={setShowProducts}
                    country={DeclarationCountry.IRELAND}
                />
            </StyledHeader>
            <DeclarationFormTabContent navigationItems={verticalNavigation}>
                {handleDeclarationView}
            </DeclarationFormTabContent>
            <Drawer
                title="View all products from Irish Customs"
                width="786"
                visible={showProducts}
                onClose={() => {
                    setShowProducts(false);
                }}
            >
                {declaration && showProducts && (
                    <DeclarationsProductsTemplates
                        country={DeclarationCountry.IRELAND}
                        products={productsTemplates}
                        refresh={() => {
                            listH1ProductsTemplates();
                        }}
                        handlePagination={handlePagination}
                        handleSort={handleSort}
                        addProduct={true}
                        addProductsToDeclaration={handleAddProducts}
                    />
                )}
            </Drawer>
        </>
    );
};
export default IrelandExportTabContent;
