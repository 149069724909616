export enum IncotermCode {
    CFR = 'CFR', //Cost and freight
    CIF = 'CIF', //Cost, insurance and freight
    CIP = 'CIP', //Carriage and insurance paid to
    CPT = 'CPT', //Carriage paid to
    DAF = 'DAF', //Delivered at frontier
    DAP = 'DAP', //Delivered at place
    DAT = 'DAT', //Delivered at terminal
    DDP = 'DDP', //Delivered duty paid
    DDU = 'DDU', //Delivered duty unpaid
    DEQ = 'DEQ', //Delivered ex quay
    DES = 'DES', //Delivered ex ship
    EXW = 'EXW', //Ex works
    FAS = 'FAS', //Free along ship
    FCA = 'FCA', //Free carrier
    FOB = 'FOB', //Free on board
    XXX = 'XXX', //Delivery terms other than those listed above
}

export const incotermCodeLabels = [
    { id: IncotermCode.CFR, value: 'Cost and freight' },
    { id: IncotermCode.CIF, value: 'Cost, insurance and freight' },
    { id: IncotermCode.CIP, value: 'Carriage and insurance paid to' },
    { id: IncotermCode.CPT, value: 'Carriage paid to' },
    { id: IncotermCode.DAF, value: 'Delivered at frontier' },
    { id: IncotermCode.DAP, value: 'Delivered at place' },
    { id: IncotermCode.DAT, value: 'Delivered at terminal' },
    { id: IncotermCode.DDP, value: 'Delivered duty paid' },
    { id: IncotermCode.DDU, value: 'Delivered duty unpaid' },
    { id: IncotermCode.DEQ, value: 'Delivered ex quay' },
    { id: IncotermCode.DES, value: 'Delivered ex ship' },
    { id: IncotermCode.EXW, value: 'Ex works' },
    { id: IncotermCode.FAS, value: 'Free along ship' },
    { id: IncotermCode.FCA, value: 'Free carrier' },
    { id: IncotermCode.FOB, value: 'Free on board' },
    { id: IncotermCode.XXX, value: 'Delivery terms other than those listed above' },
] as const;
