import { Col, Row } from 'antd';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import DeclarationField from '../../../common/DeclarationField';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { calculationTaxesTaxType } from 'store/declarations/enums/uk/calculation-taxes-tax-type';

interface Props extends BlockProps<'typeCode'> {}

const CalculationOfTaxesTaxTypeBlock = ({
    path = 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '4/3', []);

    return (
        <Row gutter={18}>
            <Col xs={fieldOptions?.typeCode?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="typeCode"
                    validation={{
                        name: t('taxType'),
                        exact: 3,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormSelect
                            refNumber={ucc}
                            label={t('taxType')}
                            {...getFormikProps(field.name, form)}
                            selectOptions={calculationTaxesTaxType}
                        />
                    )}
                </DeclarationField>
            </Col>
        </Row>
    );
};

export default CalculationOfTaxesTaxTypeBlock;
