export enum LocationTypes {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
}
export const locationTypesLabels = [
    { id: LocationTypes.A, value: 'Designated location' },
    { id: LocationTypes.B, value: 'Authorised place' },
    { id: LocationTypes.C, value: 'Approved place' },
    { id: LocationTypes.D, value: 'Other' },
] as const;
