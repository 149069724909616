import { Col, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { RcFile, UploadFile as UploadFileAnt } from 'antd/lib/upload/interface';
import Button from 'components/ui/base/button';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmUploadFile, documentsPreUpload } from 'store/documents/client';
import useRequest from '../../../hooks/useRequest';
import { BrowseSpan, CustomRow, DraggerRow, InboxOutlinedIcon, ReverseCol, SectionTitle } from './InvoiceUpload.styles';

interface FileUpdate {
    filename: string;
    id: string;
}
interface Props {
    successUpdate: (file: FileUpdate) => void;
    customerId?: string;
    declarationId?: string;
    disabled?: boolean;
    createDeclaration: Function;
}

interface FileUploadSuccess {
    payload: {
        id: string;
        link: string;
        documentType: string;
    };
}

const UploadFile: FC<Props> = ({ successUpdate, customerId, declarationId, disabled = false, createDeclaration }) => {
    const { data, doRequest: preUpload } = useRequest(documentsPreUpload);
    const url = !data ? '' : data.preSignedUrl;
    const { t } = useTranslation('customs_declarations');

    const handleOnChange = async (info: UploadChangeParam<UploadFileAnt<any>>) => {
        const { status } = info.file;
        switch (status) {
            case 'uploading':
                break;
            case 'done':
                const response = (await confirmUploadFile({
                    type: 'INVOICE',
                    filename: info.file.name,
                    customerId: customerId,
                    declarationId,
                })) as unknown as FileUploadSuccess;
                successUpdate({
                    filename: info.file.name,
                    id: response.payload.id,
                });
                break;
            case 'error':
                break;
        }
    };

    const beforeUpload = async (file: RcFile, fileList: RcFile[]) => {
        let id = declarationId;
        if (!id) {
            const declaration = await createDeclaration();
            id = declaration?.id;
        }

        await preUpload({
            type: 'INVOICE',
            filename: file.name,
            customerId,
            declarationId: id,
        });
        return true;
    };

    return (
        <>
            <CustomRow className={`${disabled ? 'disabled' : ''}`}>
                <Col span={16}>
                    <SectionTitle className={disabled ? 'disabled' : ''}>{t('uploadYourDocs')}</SectionTitle>
                </Col>
                <ReverseCol span={8}>
                    <Upload
                        disabled={disabled}
                        action={url}
                        onChange={handleOnChange}
                        beforeUpload={beforeUpload}
                        method="PUT"
                        showUploadList={false}
                    >
                        <Button
                            size="large"
                            style={{ width: '16rem' }}
                            disabled={disabled}
                            onClick={() => createDeclaration()}
                        >
                            {t('upload')}
                        </Button>
                    </Upload>
                </ReverseCol>
            </CustomRow>
            <DraggerRow>
                <Col span={24}>
                    <Dragger
                        disabled={disabled}
                        action={url}
                        method="PUT"
                        onChange={handleOnChange}
                        beforeUpload={beforeUpload}
                        showUploadList={false}
                    >
                        <p className="ant-upload-text">
                            <InboxOutlinedIcon /> Drag files to upload or, <BrowseSpan>browse</BrowseSpan> for a file on
                            your computer
                        </p>
                    </Dragger>
                </Col>
            </DraggerRow>
        </>
    );
};

export default UploadFile;
