import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import UcrBlock, { ucrBlockValidation } from '../../blocks/UcrBlock';
import CountryOfDestinationCodeBlock, {
    countryOfDestinationCodeBlockProductValidation,
} from '../../blocks/CountryOfDestinationCodeBlock';
import DescriptionOfGoodsBlock, { descriptionOfGoodsBlockValidation } from '../../blocks/DescriptionOfGoodsBlock';
import GrossMassBlock, { grossMassBlockProductValidation } from '../../blocks/GrossMassBlock';
import NetMassBlock, { netMassBlockValidation } from '../../blocks/NetMassBlock';
import StatisticalValueBlock, { statisticalValueBlockValidation } from '../../blocks/StatisticalValueBlock';
import SupplementaryUnitsBlock, { supplementaryUnitsBlockValidation } from '../../blocks/SupplementaryUnitsBlock';
import UnDangerousGoodsCodeBlock, { unDangerousGoodsCodeBlockValidation } from '../../blocks/UnDangerousGoodsCodeBlock';
import CountryOfDispatchExportCodeBlock from '../../blocks/CountryOfDispatchExportCodeBlock';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

interface Props extends DeclarationFormCardProps {
    fieldOptions?: FieldOptions<'netNetWeightMeasure' | 'grossMassMeasure' | 'statisticalValueAmount'>;
    isUkCdsImport?: boolean;
}

export const itemFieldsCardValidation = {
    selfValidators: [
        descriptionOfGoodsBlockValidation,
        ucrBlockValidation,
        countryOfDestinationCodeBlockProductValidation,
        netMassBlockValidation,
        supplementaryUnitsBlockValidation,
        grossMassBlockProductValidation,
        statisticalValueBlockValidation,
        unDangerousGoodsCodeBlockValidation,
    ],
};

const ItemFieldsCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('goodsItems.title')}>
            <FormCardContainer>
                <DescriptionOfGoodsBlock fieldOptions={{ description: { required: true, validation: { max: 512 } } }} />

                <UcrBlock path="ucr" />

                <CountryOfDestinationCodeBlock path="destination" />

                {props.isUkCdsImport && <CountryOfDispatchExportCodeBlock />}

                <NetMassBlock
                    fieldOptions={{
                        netNetWeightMeasure: {
                            required: props.fieldOptions?.netNetWeightMeasure?.required ?? true,
                            validation: { float: props.fieldOptions?.netNetWeightMeasure?.validation.float },
                        },
                    }}
                />

                <SupplementaryUnitsBlock />

                <GrossMassBlock
                    fieldOptions={{
                        grossMassMeasure: {
                            required: props.fieldOptions?.grossMassMeasure?.required,
                            validation: { float: props.fieldOptions?.grossMassMeasure?.validation.float },
                        },
                    }}
                    path="commodity.goodsMeasure"
                    isProduct
                />

                <StatisticalValueBlock
                    fieldOptions={{
                        statisticalValueAmount: {
                            validation: { float: props.fieldOptions?.statisticalValueAmount?.validation.float },
                        },
                    }}
                />

                {!props.isUkCdsImport && <UnDangerousGoodsCodeBlock />}
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ItemFieldsCard;
