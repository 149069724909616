import Modal from 'components/ui/base/modal/Modal';
import { H5 } from 'components/ui/base/typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    jobsModalVisible: boolean;
    closeJobsDrawer: () => void;
    handleDeleteJobs: () => void;
}
const ConfirmJobDeleteModal: FC<Props> = ({ jobsModalVisible, closeJobsDrawer, handleDeleteJobs }) => {
    const { t } = useTranslation('dashboard');
    return (
        <>
            <Modal
                title={<H5>{t('job.delete-title')}</H5>}
                centered
                visible={jobsModalVisible}
                onOk={handleDeleteJobs}
                onCancel={closeJobsDrawer}
                width={762}
                contentText={t('job.delete-text')}
            />
        </>
    );
};
export default ConfirmJobDeleteModal;
