import Drawer from 'components/ui/base/drawer/Drawer';
import Notification from 'components/ui/base/notification/Notification';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import { FormikProps, setNestedObjectValues } from 'formik';
import Fuse from 'fuse.js';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useProducts from 'hooks/useProducts';
import useProductsTemplates from 'hooks/useProductsTemplates';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { GoodsShipmentItem } from 'store/declarations/ireland/electronic-transport-document';
import { ProductTemplate } from 'store/products-templates/products';
import DeclarationFormTabContent from 'views/declarations/common/declaration-form/DeclarationFormTabContent';
import DeclarationsProductsTemplates from 'views/declarations/common/DeclarationsProductsTemplates';
import MasterProductDeclarationNav from 'views/declarations/common/MasterProductDeclarationNav';
import { FormSection } from 'views/declarations/DeclarationView';
import { StyledHeader } from 'views/declarations/Form.styles';
import { ProductsErrors } from 'views/declarations/sections/declaration-form/DeclarationForm';
import FormHelperDrawer from 'views/declarations/tooltips-help/FormHelpDrawer';
import { removeEmptyObjectsFromDeclarationArrays, removeIdFromArrayObject } from 'views/declarations/utils/form-utils';
import { sectionsEtdMainDocument, sectionsEtdProducts } from './constants';
import { ProductContext } from 'views/declarations/common/declaration-view/DeclarationView';

interface Props {
    formik: FormikProps<any>;
    productsFormik: FormikProps<any>;
    declaration: Declaration;
    cancelProducts: boolean;
    clearCancel: Function;
    saveProduct: boolean;
    clearSaveProduct: Function;
    updateDeclaration: Function;
    hasAlert: boolean;
    declarationErrors: ProductsErrors;
}

const IrelandEtdTabContent: FC<Props> = ({
    declaration,
    formik,
    productsFormik,
    saveProduct,
    clearSaveProduct,
    updateDeclaration,
    cancelProducts,
    clearCancel,
    declarationErrors,
    hasAlert,
}) => {
    const { setDeclarationFooterType, expandDeclarationFooter } = useDeclarationFooter();
    const [showProducts, setShowProducts] = useState(false);
    const { declarationId }: { declarationId?: string } = useParams();
    const location = useLocation();
    const [selectedBt, setSelectedBt] = useState<FormSection>(
        location.pathname === `/declarations/${declarationId}` ? FormSection.MASTER_DETAIlS : FormSection.PRODUCTS
    );
    const [showHelp, setShowHelp] = useState<boolean>(false);
    const [expandAllCards, setExpandAllCards] = useState(false);
    const [helpSection, setHelpSection] = useState<number | string | undefined>();
    const [searchQuery, setSearchQuery] = useState('');
    const [myProducts, setMyProducts] = useState<GoodsShipmentItem[]>([]);
    const { deleteIrelandEnsProduct, error: productsError, getIrelandEtdProduct } = useProducts();
    const { productsTemplates, listH1ProductsTemplates, saveH1ProductTemplate, error, isLoading } =
        useProductsTemplates({
            country: DeclarationCountry.IRELAND,
        });
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>();
    const { declarationValidation, setFormAction } = useDeclarationValidation();
    const controlProduct = useContext(ProductContext);
    const { productId } = useParams<string>();
    const navigate = useNavigate();

    useEffect(() => {
        setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
        expandDeclarationFooter();
        listH1ProductsTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedBt(
            location.pathname === `/declarations/${declarationId}` ? FormSection.MASTER_DETAIlS : FormSection.PRODUCTS
        );
    }, [location, declarationId]);

    const handleSort = (params: Partial<PaginatedParams>) => {
        setPaginator({ ...paginator, ...params });
    };

    const addNewProduct = useCallback(() => {
        if (controlProduct.setProductId) {
            controlProduct.setProductId(undefined);
        }
        productsFormik.resetForm();
        setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
        navigate(`/declarations/${declarationId}/products/new`);
    }, [setDeclarationFooterType, productsFormik, controlProduct, declarationId, navigate]);

    const declarationHasItems = useMemo(
        () => declaration.irelandETDDeclaration?.goodsShipment?.goodsShipmentItems?.length,
        [declaration]
    );

    const declarationItems = useMemo(
        () => declaration.irelandETDDeclaration?.goodsShipment?.goodsShipmentItems,
        [declaration]
    );

    useEffect(() => {
        if (declarationHasItems) {
            setMyProducts(declarationItems!);
        }
    }, [declarationItems, declarationHasItems]);

    useEffect(() => {
        if (searchQuery && declarationItems) {
            const array = [...declarationItems];
            const fuse = new Fuse(array, {
                keys: ['commodityCode'], //TODO change
            });
            const res: unknown[] = fuse.search(searchQuery);
            setMyProducts(res as GoodsShipmentItem[]);
        }
        if (!searchQuery) {
            setMyProducts(declarationItems!);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    useEffect(() => {
        if (saveProduct) {
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
            clearSaveProduct();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveProduct]);

    useEffect(() => {
        if (cancelProducts) {
            productsFormik.resetForm();
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
            clearCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelProducts]);

    useEffect(() => {
        if (selectedBt === 0) {
            if (!declarationValidation.formAction) {
                setFormAction(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBt]);

    const verticalNavigation = useMemo(() => {
        return selectedBt === 0 ? sectionsEtdMainDocument : sectionsEtdProducts;
    }, [selectedBt]);

    const handleAddProducts = (products: ProductTemplate[]) => {
        setShowProducts(false);
        const newProducts = removeIdFromArrayObject(products) as ProductTemplate[];
        newProducts.map(async (item: ProductTemplate) => {
            const withoutEmpties = { ...removeEmptyObjectsFromDeclarationArrays({ ...item }) } as ProductTemplate;
            productsFormik.setValues({ ...withoutEmpties, id: undefined });
            await productsFormik.submitForm();
        });
        Notification({
            type: 'success',
            messageTitle: 'Product Added',
            description: 'Product Template has been successfully added to declaration!',
        });
    };

    const handlePagination = (params: Partial<PaginatedParams>) => {
        if (paginator && paginator.page && paginator.size) {
            setPaginator({ page: params.page, size: paginator.size });
        } else {
            setPaginator({ page: params.page, size: defaultPagination.size });
        }
    };

    const handleDeclarationView = useMemo(() => {
        const handleToggleHelp = (refNumber: string | number) => {
            setHelpSection(refNumber);
            setShowHelp(true);
        };

        const handleDeleteProducts = async (id: string) => {
            setShowProducts(false);
            await deleteIrelandEnsProduct(id, declaration.id!);

            if (!productsError) {
                Notification({
                    type: 'success',
                    messageTitle: 'Product Deleted',
                    description: 'Product has been successfully deleted!',
                });
                updateDeclaration();
            }
        };

        const handleEditProducts = async (id: string) => {
            if (declarationHasItems) {
                const product = await getIrelandEtdProduct(declarationId!, id);
                if (product) {
                    if (controlProduct.setProductId) {
                        controlProduct.setProductId(product.id);
                    }
                    productsFormik.setValues(product);
                    if (declarationValidation.pressedSubmit === true) {
                        await productsFormik.validateForm().then((v) => {
                            productsFormik.setTouched(setNestedObjectValues(v, true));
                            return v;
                        });
                    }
                    navigate(`/declarations/${declarationId}/products/${product.id}`);
                }
            }
        };

        return (
            <Outlet
                context={{
                    formik: formik,
                    productsFormik: productsFormik,
                    toggleHelp: handleToggleHelp,
                    expandAll: expandAllCards,
                    setSearchQuery,
                    errors: declarationErrors,
                    data: myProducts,
                    onDelete: handleDeleteProducts,
                    onEdit: handleEditProducts,
                    itemsNumber: declarationHasItems,
                    internalType: DeclarationInternalType.ENS,
                }}
            />
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedBt,
        formik,
        productsFormik,
        declarationHasItems,
        myProducts,
        productId,
        declarationItems,
        declaration,
        deleteIrelandEnsProduct,
        error,
        listH1ProductsTemplates,
        productsError,
        saveH1ProductTemplate,
        updateDeclaration,
        isLoading,
        declarationErrors,
    ]);

    return (
        <>
            <FormHelperDrawer
                section={helpSection}
                isVisible={showHelp}
                onClose={() => setShowHelp(false)}
                internalType={DeclarationInternalType.ENS}
                declarationCountry={DeclarationCountry.IRELAND}
            />
            <StyledHeader>
                <MasterProductDeclarationNav
                    selectedBt={selectedBt}
                    handleCollapseAllCards={() => {
                        setExpandAllCards((expandAllCards) => !expandAllCards);
                    }}
                    expandAllCards={expandAllCards}
                    addNewProduct={addNewProduct}
                    setShowProducts={setShowProducts}
                    country={DeclarationCountry.IRELAND}
                />
            </StyledHeader>
            <DeclarationFormTabContent navigationItems={verticalNavigation}>
                {handleDeclarationView}
            </DeclarationFormTabContent>
            <Drawer
                title="View all products from Irish Customs"
                width="786"
                visible={showProducts}
                onClose={() => {
                    setShowProducts(false);
                }}
            >
                {declaration && showProducts && (
                    <DeclarationsProductsTemplates
                        country={DeclarationCountry.IRELAND}
                        products={productsTemplates}
                        refresh={() => {
                            listH1ProductsTemplates();
                        }}
                        handlePagination={handlePagination}
                        handleSort={handleSort}
                        addProduct={true}
                        addProductsToDeclaration={handleAddProducts}
                    />
                )}
            </Drawer>
        </>
    );
};
export default IrelandEtdTabContent;
