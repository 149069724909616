import {
    CheckSquareOutlined,
    CloseSquareOutlined,
    DeleteOutlined,
    EditOutlined,
    PhoneOutlined,
} from '@ant-design/icons';
import { ColumnsType, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import Avatar from 'components/ui/base/avatar/Avatar';
import Button from 'components/ui/base/button';
import Table from 'components/ui/base/table/Table';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import { FC, useState } from 'react';
import { Customer, CustomerSortParameter } from 'store/customers/customer';
import { Row } from 'antd';

interface Props {
    data?: ListPayload<Customer & { jobs: number }>;
    onDelete?: (ids: string[]) => void;
    onEdit: (id: string) => void;
    onDetails: (id: string) => void;
    handlePagination: (paginator: Partial<PaginatedParams>) => void;
    handleSort: (paginator: Partial<PaginatedParams>) => void;
    loading: boolean;
}

const CustomersTable: FC<Props> = ({ data, onDelete, onEdit, onDetails, handlePagination, handleSort, loading }) => {
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: (Customer & { jobs: number })[]) => {
            const ids = selectedRows.map((c) => c.id);
            setDeleteIds(ids);
        },
    };

    const columns: ColumnsType<any> = [
        {
            title: 'EORI',
            dataIndex: CustomerSortParameter.EORI,
            key: CustomerSortParameter.EORI,
            align: 'left',
            onCell: (record: Customer & { jobs: number }, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            sorter: true,
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.eori ? <span>{record.eori}</span> : <span>-</span>,
        },
        {
            title: 'Reference Code',
            dataIndex: CustomerSortParameter.REFERENCE_CODE,
            key: CustomerSortParameter.REFERENCE_CODE,
            align: 'left',
            onCell: (record: Customer & { jobs: number }, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            sorter: true,
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.referenceCode ? <span>{record.referenceCode}</span> : <span>-</span>,
        },
        {
            title: 'Customer',
            dataIndex: CustomerSortParameter.NAME,
            key: CustomerSortParameter.NAME,
            align: 'left',
            onCell: (record: Customer & { jobs: number }, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.name ? (
                    <span>
                        <Row align="middle" wrap={false}>
                            <Avatar name={record.name}></Avatar>
                            {record.name}
                        </Row>
                    </span>
                ) : (
                    <span>-</span>
                ),
            sorter: true,
        },
        {
            title: 'Active Jobs',
            dataIndex: 'JOBS',
            key: 'JOBS',
            align: 'left',
            onCell: (record: Customer & { jobs: number }, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Customer & { jobs: number }, index: number) => (
                <span>
                    {record.jobs > 0 ? (
                        <>
                            <Row style={{ width: '80%' }} justify="space-between" align="middle" wrap={false}>
                                <span>{record.jobs} active jobs</span>
                                <i style={{ color: '#219653' }}>
                                    <CheckSquareOutlined />
                                </i>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row style={{ width: '80%' }} justify="space-between" align="middle" wrap={false}>
                                <span>No active jobs</span>
                                <i style={{ color: '#EB5757' }}>
                                    <CloseSquareOutlined />
                                </i>
                            </Row>
                        </>
                    )}
                </span>
            ),
            sorter: (a: any, b: any) => a.jobs - b.jobs,
        },
        {
            title: 'Email',
            dataIndex: CustomerSortParameter.EMAIL,
            key: CustomerSortParameter.EMAIL,
            align: 'left',
            onCell: (record: Customer & { jobs: number }, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.contact?.email ? <span>{record.contact.email}</span> : <span>-</span>,
            sorter: true,
        },
        {
            title: 'Phone Number',
            dataIndex: CustomerSortParameter.PHONE,
            key: CustomerSortParameter.PHONE,
            align: 'left',
            onCell: (record: Customer & { jobs: number }, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Customer & { jobs: number }, index: number) =>
                record.contact?.phoneNumber ? (
                    <Row style={{ width: '80%' }} justify="space-between" align="middle" wrap={false}>
                        <i style={{ marginRight: '0.9rem' }}>
                            <PhoneOutlined />
                        </i>

                        <span>{record.contact.phoneNumber}</span>
                    </Row>
                ) : (
                    <span>-</span>
                ),
            sorter: true,
        },
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            align: 'center',
            render: (text: string, record: Customer & { jobs: number }, index: number) => {
                return (
                    <>
                        <span style={{ marginRight: '1.2rem', cursor: 'pointer' }} onClick={() => onEdit(record.id)}>
                            <EditOutlined />
                        </span>
                        {onDelete && (
                            <span
                                style={{ marginRight: '1.2rem', cursor: 'pointer' }}
                                onClick={() => onDelete?.([record.id])}
                            >
                                <DeleteOutlined />
                            </span>
                        )}
                    </>
                );
            },
        },
    ];

    const onChange = (
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<any> | SorterResult<any>[],
        extra: TableCurrentDataSource<any>
    ) => {
        if (!Array.isArray(sorter) && sorter.columnKey !== 'JOBS') {
            const sortParameter = sorter.columnKey?.toString();
            if (sorter.order === 'ascend') {
                handleSort({ sortParameter, sortDirection: 'ASC' });
            } else if (sorter.order === 'descend') {
                handleSort({ sortParameter, sortDirection: 'DESC' });
            }
        }
    };

    return (
        <>
            <Table
                rowKey="id"
                style={{ marginTop: '2.5rem' }}
                columns={columns}
                dataSource={data?.list}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                pagination={{
                    defaultCurrent: 1,
                    total: data?.total,
                    showSizeChanger: false,
                    pageSize: data?.pageSize,
                    position: ['bottomCenter'],
                    onChange: (page: number | undefined, size: number | undefined) => {
                        if (page) {
                            const params = { page: page - 1, size };
                            handlePagination(params);
                        }
                    },
                }}
                onChange={onChange}
                loading={loading}
            />
            {deleteIds.length ? (
                <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        onDelete?.(deleteIds);
                        setDeleteIds([]);
                    }}
                >
                    delete
                </Button>
            ) : (
                <></>
            )}
        </>
    );
};
export default CustomersTable;
