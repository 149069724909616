import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { FormikProvider, setNestedObjectValues, useFormik } from 'formik';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import useDeclarations from 'hooks/useDeclarations';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { useDeclarationContext } from 'utils/DeclarationContext';
import EnsProductSection from './ireland/ens/EnsProductSection';
import EtdProductSection from './ireland/etd/products/EtdProductSection';
import IrelandExportProductsSection from './ireland/export/IrelandExportProductsSection';
import IrelandH1ProductsSection from './ireland/import/h1/IrelandH1ProductsSection';
import IrelandH7ProductsSection from './ireland/import/h7/IrelandH7ProductsSection';
import TsdProductSection from './ireland/tsd/products/TsdProductSection';
import UkExportProductsSection from './uk/export/UkExportProductsSection';
import UkH1ProductsSection from './uk/import/h1/UkH1ProductsSection';
import UkH2ProductsSection from './uk/import/h2/UkH2ProductsSection';

interface Props {
    declaration?: Declaration | null | undefined;
}

const DeclarationProductView = ({ declaration: declarationProp }: Props) => {
    const context = useProductView();

    const { template } = useTemplateContext();
    const { setForm } = useDeclarationContext();
    useEffect(() => {
        if (template) return;
        setForm?.('product');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mockFormik = useFormik({
        initialValues: {},
        onSubmit: () => {},
    });

    const { declarationId } = useParams<{ declarationId: string }>();
    const { declaration: _declaration } = useDeclarations({
        declarationId: declarationId,
        forceFetchDeclaration: true,
    });
    const declaration = useMemo(() => {
        return declarationProp ?? _declaration;
    }, [declarationProp, _declaration]);

    const { declarationErrors: formDeclarationErrors } = useDeclarationFormErrors();

    useEffect(() => {
        if (isEmpty(formDeclarationErrors.items)) return;

        context?.productsFormik?.validateForm().then((v) => {
            context?.productsFormik?.setTouched(setNestedObjectValues(v, true));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const container = useMemo(() => {
        if (declaration) {
            switch (declaration.declarationExternalEntity) {
                case DeclarationExternalEntity.REVENUE: {
                    if (declaration.declarationInternalType === DeclarationInternalType.IMPORT) {
                        if (declaration.irelandImportDeclaration) {
                            return (
                                <IrelandH1ProductsSection
                                    formik={context?.productsFormik ?? mockFormik}
                                    toggleHelp={context?.toggleHelp}
                                    viewOnly={context?.viewOnly}
                                    productTemplate={context?.productTemplate}
                                    selectCard={context?.selectCard}
                                    cancelFetchProduct={context?.cancelFetchProduct}
                                />
                            );
                        } else {
                            return (
                                <IrelandH7ProductsSection
                                    formik={context?.productsFormik ?? mockFormik}
                                    toggleHelp={context?.toggleHelp}
                                    viewOnly={context?.viewOnly}
                                />
                            );
                        }
                    } else if (declaration.declarationInternalType === DeclarationInternalType.EXPORT) {
                        return (
                            <IrelandExportProductsSection
                                formik={context?.productsFormik ?? mockFormik}
                                toggleHelp={context?.toggleHelp}
                                viewOnly={context?.viewOnly}
                                expandAll={context?.expandAll}
                                selectCard={context?.selectCard}
                            />
                        );
                    } else if (declaration.declarationInternalType === DeclarationInternalType.ENS) {
                        return (
                            <EnsProductSection
                                formik={context?.productsFormik ?? mockFormik}
                                toggleHelp={context?.toggleHelp}
                                viewOnly={context?.viewOnly}
                                expandAll={context?.expandAll}
                            />
                        );
                    } else if (declaration.declarationInternalType === DeclarationInternalType.TSD) {
                        return (
                            <TsdProductSection
                                formik={context?.productsFormik ?? mockFormik}
                                toggleHelp={context?.toggleHelp}
                                viewOnly={context?.viewOnly}
                                expandAll={context?.expandAll}
                            />
                        );
                    } else {
                        return (
                            <EtdProductSection
                                formik={context?.productsFormik ?? mockFormik}
                                toggleHelp={context?.toggleHelp}
                                viewOnly={context?.viewOnly}
                                expandAll={context?.expandAll}
                            />
                        );
                    }
                }
                case DeclarationExternalEntity.CHIEF:
                    return (
                        <UkExportProductsSection
                            formik={context?.productsFormik ?? mockFormik}
                            toggleHelp={context?.toggleHelp}
                            viewOnly={context?.viewOnly}
                        />
                    );
                case DeclarationExternalEntity.CDS:
                    if (declaration.declarationInternalType === DeclarationInternalType.IMPORT) {
                        return (
                            <UkH1ProductsSection
                                formik={context?.productsFormik ?? mockFormik}
                                toggleHelp={context?.toggleHelp}
                                viewOnly={context?.viewOnly}
                                productTemplate={context?.productTemplate}
                            />
                        );
                    } else if (declaration.declarationInternalType === DeclarationInternalType.IMPORT_NEW) {
                        return (
                            <UkH2ProductsSection
                                formik={context?.productsFormik ?? mockFormik}
                                toggleHelp={context?.toggleHelp}
                                viewOnly={context?.viewOnly}
                                declarationInternalType={declaration.declarationInternalType}
                            />
                        );
                    } else {
                        return (
                            <UkExportProductsSection
                                formik={context?.productsFormik ?? mockFormik}
                                toggleHelp={context?.toggleHelp}
                                viewOnly={context?.viewOnly}
                            />
                        );
                    }
                default:
                    <></>;
            }
        }
        return <></>;
    }, [declaration, context, mockFormik]);

    return <FormikProvider value={context?.productsFormik ?? mockFormik}>{container}</FormikProvider>;
};
export default DeclarationProductView;

export function useProductView() {
    return useOutletContext<
        | {
              productsFormik?: ReturnType<typeof useFormik>; // FIXME use correct type
              toggleHelp?: (refNumber: string | number) => void;
              viewOnly?: boolean;
              productTemplate?: boolean;
              expandAll?: boolean;
              selectCard?: (id: string) => void;
              cancelFetchProduct?: boolean;
          }
        | undefined
    >();
}
