import { Select, Tag, Typography, Input } from 'antd';
import { ReactElement, useMemo } from 'react';
import styled from 'styled-components';
import { getFieldName } from 'utils/validationParserUtils';
import DeclarationField from 'views/declarations/common/DeclarationField';
import parseValidationErrors from 'views/declarations/uk/export/parseValidationErrors';
import { AmendmentRecord } from './CdsAmendmentModal';
import amendmentTypeCodeCodeList from '../../utils/amendmentTypeCodeList';

const { Text } = Typography;
const { TextArea } = Input;

const Error = styled(Text)`
    color: red;
    font-size: 1.25rem;
`;

interface Props {
    amendment: AmendmentRecord;
    index: number;
}

const CdsAmendmentRecord = ({ amendment, index }: Props): ReactElement => {
    const actionTagColor = amendment.action === 'ADD' ? 'green' : amendment.action === 'EDIT' ? 'gold' : 'red';
    const valueColor = amendment.action === 'ADD' ? 'success' : amendment.action === 'EDIT' ? 'warning' : 'danger';

    const amendedFieldName = useMemo(
        () => getFieldName(parseValidationErrors, amendment.path, 'governmentAgencyGoodsItem'),
        [amendment.path]
    );

    return (
        <>
            <div>
                <Text italic>{amendedFieldName}</Text>
            </div>
            <div>
                <Text type={valueColor}>{amendment.value}</Text>
            </div>
            <div>
                <Tag color={actionTagColor} style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                    {amendment.action}
                </Tag>
            </div>
            <div>
                <DeclarationField name={`${index}.amendmentReasonCode`}>
                    {({ field, meta, form }) => (
                        <>
                            <Select
                                {...field}
                                onChange={(value) =>
                                    field.onChange({ target: { name: field.name, value, touched: field.checked } })
                                }
                                onBlur={() => form.setFieldTouched(field.name)}
                                placeholder="Choose an amendment type code"
                                options={amendmentTypeCodeCodeList}
                                style={{ width: '100%' }}
                            />
                            {meta.error && meta.touched && <Error>{meta.error}</Error>}
                        </>
                    )}
                </DeclarationField>
            </div>
            <div>
                <DeclarationField name={`${index}.reason`}>
                    {({ field }) => (
                        <TextArea
                            {...field}
                            placeholder="Reason for the amendment"
                            rows={2}
                            style={{ resize: 'none' }}
                        />
                    )}
                </DeclarationField>
            </div>
        </>
    );
};

export default CdsAmendmentRecord;
