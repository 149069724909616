export const valuationIndicatorTypes = [
    { id: '0000', value: '' },
    { id: '0001', value: '' },
    { id: '0010', value: '' },
    { id: '0011', value: '' },
    { id: '0100', value: '' },
    { id: '0101', value: '' },
    { id: '0110', value: '' },
    { id: '0111', value: '' },
    { id: '1000', value: '' },
    { id: '1001', value: '' },
    { id: '1010', value: '' },
    { id: '1011', value: '' },
    { id: '1100', value: '' },
    { id: '1101', value: '' },
    { id: '1110', value: '' },
    { id: '1111', value: '' },
    { id: '2', value: '' },
    { id: '3', value: '' },
    { id: '4', value: '' },
    { id: '5', value: '' },
    { id: '6', value: '' },
    { id: '1', value: '' },
];
