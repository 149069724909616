import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { TextBold } from 'components/ui/base/typography';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useProducts from 'hooks/useProducts';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import {
    BtMasterGoodsNotSelected,
    BtMasterGoodsSelected,
    ColExpandCards,
    CompletedSection,
    DivButtonsOptionsForProducts,
    ExpandButton,
    InvalidIcon,
    NumberItemsRounderInfo,
    ProductButton,
    ProductExpandButton,
} from '../Form.styles';
import { FormAction } from '../utils/form-utils';
import { isEmpty } from 'lodash';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import { useTranslation } from 'react-i18next';
import DeclarationInformation from './DeclarationInformation';
import useDeclarations from 'hooks/useDeclarations';

interface Props {
    selectedBt: number;
    setNewProduct?: Function;
    addNewProduct?: Function;
    setShowProducts?: Function;
    country?: DeclarationCountry;
    handleCollapseAllCards?: Function;
    expandAllCards?: boolean;
    totalCards?: string | number;
    completedCards?: string | number;
    declarationType?: string;
    viewOnly?: boolean;
}
export const MasterProductDeclarationNav: FC<Props> = ({
    selectedBt,
    setNewProduct,
    addNewProduct,
    setShowProducts,
    country,
    handleCollapseAllCards,
    expandAllCards = false,
    totalCards = 17,
    completedCards = 4,
    declarationType,
    viewOnly,
}) => {
    const { setDeclarationFooterType } = useDeclarationFooter();
    const { declaration } = useDeclarations();
    const {
        setFormAction,
        setHasUnsavedChanges,
        declarationValidation: { hasUnsavedChanges },
    } = useDeclarationValidation({
        formAction: null,
    });
    const navigate = useNavigate();
    const { declarationId, productId }: { declarationId?: string; productId?: string } = useParams();
    const { products } = useProducts();
    const { declarationErrors: formDeclarationErrors } = useDeclarationFormErrors();
    const { t } = useTranslation('customs_declarations');

    const expandAllCardsBt = useMemo(() => {
        return expandAllCards ? (
            <>
                {t('buttons.collapseCard', { ns: 'common' })} <DownOutlined />
            </>
        ) : (
            <>
                {t('buttons.uncollapseard', { ns: 'common' })} <RightOutlined />
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandAllCards]);

    const hasMasterDetailsError = useMemo(
        () => !isEmpty(formDeclarationErrors.masterDetails),
        [formDeclarationErrors.masterDetails]
    );
    const hasProductsError = useMemo(() => !isEmpty(formDeclarationErrors.items), [formDeclarationErrors.items]);

    const invalidMasterDetailsIcon = useMemo(() => hasMasterDetailsError && <InvalidIcon />, [hasMasterDetailsError]);
    const invalidProductIcon = useMemo(() => hasProductsError && <InvalidIcon />, [hasProductsError]);

    useEffect(() => {
        handleCollapseAllCards?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToProducts = useCallback(() => {
        setHasUnsavedChanges(false);
        navigate(`/declarations/${declarationId}${viewOnly ? '/view-only/products' : '/products'}`);
        setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
    }, [declarationId, navigate, setDeclarationFooterType, setHasUnsavedChanges, viewOnly]);

    const goToMasterDetails = useCallback(() => {
        setHasUnsavedChanges(false);
        setNewProduct?.(false);
        navigate(`/declarations/${declarationId}${viewOnly ? '/view-only' : ''}`);
        setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
    }, [declarationId, navigate, setDeclarationFooterType, setHasUnsavedChanges, setNewProduct, viewOnly]);

    const saveChangesAndMove = useCallback(() => {
        if (selectedBt === 0) {
            if (hasUnsavedChanges && !viewOnly) setFormAction(FormAction.DRAFT); // save declaration
            goToProducts();
        } else {
            if (hasUnsavedChanges && !viewOnly) setFormAction(FormAction.PRODUCT); // save product
            goToMasterDetails();
        }
    }, [goToMasterDetails, goToProducts, hasUnsavedChanges, selectedBt, setFormAction, viewOnly]);

    const handleExpandCads = () => {
        handleCollapseAllCards?.();
    };

    return (
        <>
            <Row justify="space-between" align="middle" style={{ marginBottom: '0.8rem', gap: '2rem' }}>
                {selectedBt === 0 ? (
                    <>
                        <div>
                            <Col>
                                <BtMasterGoodsSelected error={hasMasterDetailsError}>
                                    Master Information {invalidMasterDetailsIcon}
                                </BtMasterGoodsSelected>
                                <BtMasterGoodsNotSelected
                                    error={hasProductsError}
                                    onClick={() => {
                                        saveChangesAndMove();
                                    }}
                                >
                                    Item Details {invalidProductIcon}
                                    {!invalidProductIcon && products?.list && (
                                        <NumberItemsRounderInfo title="Number of products">
                                            {products.total}
                                        </NumberItemsRounderInfo>
                                    )}
                                </BtMasterGoodsNotSelected>
                            </Col>
                        </div>
                        {declaration?.irelandH7ImportDeclaration ||
                        declaration?.entrySummaryDeclaration ||
                        declaration?.irelandImportDeclaration ||
                        declaration?.cdsExportDeclaration ||
                        declaration?.cdsImportDeclaration ? (
                            <DeclarationInformation
                                viewOnly={viewOnly}
                                hasBox44={
                                    !!declaration?.irelandH7ImportDeclaration || !!declaration?.irelandImportDeclaration
                                }
                            />
                        ) : (
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    alignItems: 'center',
                                    gap: '1rem',
                                }}
                            >
                                <ColExpandCards xl={11} xxl={15}>
                                    <ExpandButton onClick={handleExpandCads}>{expandAllCardsBt}</ExpandButton>
                                </ColExpandCards>
                                <CompletedSection>
                                    <TextBold>{`${completedCards} of ${totalCards} Completed`}</TextBold>
                                </CompletedSection>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <Col>
                            <BtMasterGoodsNotSelected
                                error={hasMasterDetailsError}
                                onClick={() => {
                                    saveChangesAndMove();
                                }}
                            >
                                Master Information {invalidMasterDetailsIcon}
                            </BtMasterGoodsNotSelected>
                            <BtMasterGoodsSelected error={hasProductsError}>
                                Item Details {invalidProductIcon}
                                {!invalidProductIcon && products?.list && (
                                    <NumberItemsRounderInfo title="Number of products">
                                        {products.total}
                                    </NumberItemsRounderInfo>
                                )}
                            </BtMasterGoodsSelected>
                        </Col>

                        {declaration?.irelandH7ImportDeclaration ||
                        declaration?.entrySummaryDeclaration ||
                        declaration?.irelandImportDeclaration ||
                        declaration?.cdsExportDeclaration ||
                        declaration?.cdsImportDeclaration ? (
                            <DeclarationInformation
                                viewOnly={viewOnly}
                                hasBox44={
                                    !!declaration?.irelandH7ImportDeclaration || !!declaration?.irelandImportDeclaration
                                }
                                handleViewAllProductsBtn={() => setShowProducts?.(true)}
                                handleAddNewProductBtn={() => addNewProduct?.()}
                            />
                        ) : (
                            <Col>
                                <DivButtonsOptionsForProducts>
                                    <Row gutter={10}>
                                        {!productId && (
                                            <Col>
                                                <ProductButton onClick={() => addNewProduct?.()}>
                                                    {declarationType
                                                        ? `Add New ${declarationType} Product`
                                                        : 'Add New Product'}
                                                </ProductButton>
                                            </Col>
                                        )}

                                        <Col>
                                            <ProductButton onClick={() => setShowProducts?.(true)}>
                                                {declarationType
                                                    ? `View all ${declarationType} product templates`
                                                    : `View all products from ${country} customs`}
                                            </ProductButton>
                                        </Col>

                                        {!!productId && (
                                            <Col>
                                                <ProductExpandButton onClick={handleExpandCads}>
                                                    {expandAllCardsBt}
                                                </ProductExpandButton>
                                            </Col>
                                        )}
                                    </Row>
                                </DivButtonsOptionsForProducts>
                            </Col>
                        )}
                    </>
                )}
            </Row>
        </>
    );
};
export default MasterProductDeclarationNav;
