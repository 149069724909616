import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';

const CommodityCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getIrelandExportTooltipsByRefNumber } = useTooltips();

    const handleToggleHelp = (refNumber: string | number): void => {
        props.toggleHelp && props.toggleHelp(refNumber);
    };

    return (
        <section
            id="commodity-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('commodity-form-card');
                }
            }}
        >
            <FormCard
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('commodity.title')}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                expandAll={props.expandAll}
                keyCard={props.keyCard}
                state={getCardState(
                    [
                        { name: `nomenclatureCode`, required: true },
                        { name: `taricAdditionalCode`, required: false },
                        { name: `taricAdditionalCode2`, required: false },
                        { name: `nationalCode`, required: false },
                    ],
                    props
                )}
            >
                <FormInput
                    required
                    maxLength={8}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`nomenclatureCode`, props)}
                    refNumber="33.1"
                    tooltip={getIrelandExportTooltipsByRefNumber('33.1')}
                    refClicked={handleToggleHelp}
                    label={t('commodity.nomenclatureCode')}
                />
                <FormInput
                    maxLength={4}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`taricAdditionalCode`, props)}
                    refNumber="33.3"
                    tooltip={getIrelandExportTooltipsByRefNumber('33.3')}
                    refClicked={handleToggleHelp}
                    label={t('commodity.taricAdditionalCode')}
                />
                <FormInput
                    maxLength={4}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`taricAdditionalCode2`, props)}
                    refNumber="33.4"
                    tooltip={getIrelandExportTooltipsByRefNumber('33.4')}
                    refClicked={handleToggleHelp}
                    label={t('commodity.taricAdditionalCode2')}
                />
                <FormInput
                    maxLength={4}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`nationalCode`, props)}
                    refNumber="33.5"
                    tooltip={getIrelandExportTooltipsByRefNumber('33.5')}
                    refClicked={handleToggleHelp}
                    label={t('commodity.nationalCode')}
                />
            </FormCard>
        </section>
    );
};

export default CommodityCard;
