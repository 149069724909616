import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Collapse, Row } from 'antd';
import styled from 'styled-components';
import { colors } from 'theme';

export const CardRow = styled(Row)`
    margin-bottom: 0.8rem;
`;

export const CollapseIcon = styled(MinusSquareOutlined)`
    color: ${colors.darkPurple};
    font-size: 2.1rem;
`;
export const ExpandIcon = styled(PlusSquareOutlined)`
    color: ${colors.darkPurple};
    font-size: 2.1rem;
`;

export const ExpandedIconDiv = styled.div`
    margin-top: 0.4rem;
`;

export const BorderlessCardContent = styled.div`
    width: 90%;
`;

export const StyledCollapse = styled(Collapse)<{ invalid?: boolean; viewOnly?: boolean; lastCard?: boolean }>`
    width: 100%;
    align-content: center;
    background: ${colors.white};
    margin-bottom: ${({ lastCard }) => (lastCard ? `0rem` : `4rem`)};

    .ant-collapse-header {
        padding: 0 0 0rem !important;
        border-bottom: ${({ invalid }) => (invalid ? `1px solid ${colors.error}` : `1px solid ${colors.darkPurple}`)};
        color: ${({ invalid }) => (invalid ? colors.error : colors.darkPurple)};
    }

    .ant-progress {
        margin-right: 2.4rem;
        vertical-align: text-bottom;
    }

    .ant-collapse-content {
        padding-top: 2rem;
        margin-left: 0rem;
        margin-bottom: ${({ viewOnly }) => (viewOnly ? `2.4rem` : `0.8rem`)};
    }

    .ant-collapse-content-box {
        padding: 0;
    }
`;

export const Header = styled.div`
    display: flex;
    font-size: 1.8rem;
`;
