import { ButtonProps, Tooltip, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ConditionalWrapper from 'components/ConditionalWrapper';
import { colors } from 'theme';
import { HollowButton } from '../box44/Box44';

interface Props extends ButtonProps {
    compact?: boolean;
    condensed?: boolean;
    title?: string;
}

const SearchCustomerButton = ({ compact, condensed, title, ...buttonProps }: Props) => {
    return (
        <ConditionalWrapper
            condition={compact}
            wrapper={(children) => <Tooltip title={title ?? 'Search Customer'}>{children}</Tooltip>}
        >
            <HollowButton
                {...buttonProps}
                type={condensed ? undefined : 'primary'}
                shape={compact ? 'circle' : 'default'}
                onClick={(e) => {
                    e.stopPropagation();
                    buttonProps.onClick?.(e);
                }}
                icon={<SearchOutlined />}
                style={{
                    backgroundColor: condensed ? undefined : colors.darkPurple,
                    borderColor: condensed ? undefined : colors.darkPurple,
                    borderRadius: condensed && !compact ? '4px' : undefined,
                    marginLeft: 'auto',
                    ...buttonProps.style,
                }}
            >
                {!compact && 'Search Customer'}
            </HollowButton>
        </ConditionalWrapper>
    );
};

export default SearchCustomerButton;
