export enum DeclarationExternalEntity {
    CDS = 'CDS',
    CHIEF = 'CHIEF',
    REVENUE = 'REVENUE',
}

export const declarationExternalEntityLabels = [
    { id: DeclarationExternalEntity.CDS, value: 'CDS' },
    { id: DeclarationExternalEntity.CHIEF, value: 'CHIEF' },
    { id: DeclarationExternalEntity.REVENUE, value: 'REVENUE' },
] as const;
