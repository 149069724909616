import { PaginatedParams } from "core/http/pagination";
import { Leg } from "./legs/leg";

export interface Journey{
    id: string;
    jobId: string;
    consignorId: string;
    consigneeId: string,
    startDateTime: string,
    endDateTime: string,
    description: string,
    specialInstructions: string,
    legs: Leg[],
    //todo add list
}

export interface JourneyParams extends PaginatedParams{
    jobId: string;
    consignorId: string;
    consigneeId: string;
    startDateTime: string;
    endDateTime: string;
    description: string;
}

export enum JourneySortParameter {
    ID = 'ID',
    CONSIGNEE = 'CONSIGNEE',
    CONSIGNOR = 'CONSIGNOR',
    START_TIME = 'DATE',
    END_TIME = 'DATE',
}
