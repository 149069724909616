import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { partyRoleAuthorisationTypeLabels } from 'store/declarations/enums/uk/party-role-authorisation-type';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import addPathPrefix from '../../../../../../utils/addPathPrefix';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';
import MultipleItemsCard from '../../../../common/MultipleItemsCard';
import { holderOfTheAuthorisations } from '../validation/ValidationSchema';

const AuthorizationHolderCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix!}
            initialValues={{
                holderOfTheAuthorisationIdentifier: '',
                authorisationType: '',
            }}
            list={(list) => [
                {
                    field: t('authorisationHolder.identificationNumber'),
                    value: list.holderOfTheAuthorisationIdentifier,
                },
                { field: t('authorisationHolder.authorisationType'), value: list.authorisationType },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            cardNumber={props.cardNumber}
            cardTotal={props.cardTotal}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            title={t('Holder of the authorisation identification No.')}
            condensed
            validationSchema={holderOfTheAuthorisations}
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormInput
                        required
                        maxLength={17}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'holderOfTheAuthorisationIdentifier')}`, formProps)}
                        refNumber="3.39"
                        label={t('authorisationHolder.identificationNumber')}
                        tooltip={getCdsTooltipsByRefNumberAndField(
                            '3.39',
                            t('authorisationHolder.identificationNumber')
                        )}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormSelect
                        required
                        refNumber="3.39"
                        label={t('authorisationHolder.authorisationType')}
                        selectOptions={partyRoleAuthorisationTypeLabels}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'authorisationType')}`, formProps)}
                        tooltip={getCdsTooltipsByRefNumberAndField('3.39', t('authorisationHolder.authorisationType'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default AuthorizationHolderCard;
