import { LeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import { useFormik } from 'formik';
import useProducts from 'hooks/useProducts';
import { FC, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams, useLocation } from 'react-router-dom';
import { DeclarationHistory } from 'store/declarations/declaration';
import { EnsGoodsShipmentItem } from 'store/declarations/ireland/entry-summary-declaration';
import { RevenueExportCustomsGoodsItem } from 'store/declarations/ireland/export-declaration';
import { GoodsShipmentItem as GoodsShipmentItemH7 } from 'store/declarations/ireland/h7-import-declaration';
import { GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import { GovernmentAgencyGoodsItem } from 'store/declarations/uk/goods-item';
import VerticalNav from 'views/declarations/common/VerticalNav';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
    BtMasterGoodsNotSelected,
    BtMasterGoodsSelected,
    CollapsablesDiv,
    ExpandButton,
    FormDiv,
    StyledHeader,
    StyledLayout,
    ViewOnlyContent,
    ViewOnlySider,
} from 'views/declarations/Form.styles';
import { sectionsENSMainDocument, sectionsENSProducts } from 'views/declarations/ireland/ens/constants';
import { sectionsEtdMainDocument, sectionsEtdProducts } from 'views/declarations/ireland/etd/constants';
import { exportSectionsMasterDetails, exportSectionsProducts } from 'views/declarations/ireland/export/constants';
import {
    sectionsH1MainDocument,
    sectionsH1Products,
    sectionsH7MainDocument,
    sectionsH7Products,
} from 'views/declarations/ireland/import/constants';
import {
    exportSectionsMasterDetails as ukExportMasterDetails,
    exportSectionsProducts as ukExportProducts,
} from 'views/declarations/uk/export/constants';
import { sectionsUkH1MainDocument, sectionsUkH1Products } from 'views/declarations/uk/import/h1/constants';
import { getFormInitialValuesByDeclarationType, getProductsOfDeclaration } from 'views/declarations/utils/form-utils';
import DeclarationFormTabContent from 'views/declarations/common/declaration-form/DeclarationFormTabContent';

type Product =
    | EnsGoodsShipmentItem
    | GovernmentAgencyGoodsItem
    | RevenueExportCustomsGoodsItem
    | GoodsShipmentItem
    | GoodsShipmentItemH7;

const DeclarationHistoryDetails: FC<{}> = () => {
    const navigate = useNavigate();
    const { declarationId, historyId, productId } = useParams();

    const { declaration } = useDeclarationHistoryDetails();
    const url = useLocation();

    const {
        products,
        listIrelandH1Products,
        listIrelandH7Products,
        listIrelandExportProducts,
        listUkExportProducts,
        listUkImportProducts,
        listIrelandEtdProducts,
        listIrelandEnsProducts,
    } = useProducts();

    const [selectedBt, setSelectedBt] = useState(0);
    const [paginatorProducts, setPaginatorProducts] = useState<Partial<PaginatedParams>>();
    const [myProducts, setMyProducts] = useState<Product[]>([]);
    const [newProduct, setNewProduct] = useState(false);
    const [expandAll, setExpandAll] = useState<boolean>(false);
    const { t } = useTranslation();

    const productFormVisible = useMemo(() => newProduct, [newProduct]);

    const formik = useFormik({
        initialValues: declaration?.declaration ? getFormInitialValuesByDeclarationType(declaration?.declaration) : {},
        enableReinitialize: true,
        onSubmit: () => {},
    });

    const productsFormik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: () => {},
    });

    const verticalNavigation = useMemo(() => {
        if (declaration?.declaration?.cdsImportDeclaration) {
            return selectedBt === 0 ? sectionsUkH1MainDocument : sectionsUkH1Products;
        } else if (declaration?.declaration?.cdsExportDeclaration) {
            return selectedBt === 0 ? ukExportMasterDetails : ukExportProducts;
        } else if (declaration?.declaration?.irelandExportDeclaration) {
            return selectedBt === 0 ? exportSectionsMasterDetails : exportSectionsProducts;
        } else if (declaration?.declaration?.irelandH7ImportDeclaration) {
            return selectedBt === 0 ? sectionsH7MainDocument : sectionsH7Products;
        } else if (declaration?.declaration?.irelandImportDeclaration) {
            return selectedBt === 0 ? sectionsH1MainDocument : sectionsH1Products;
        } else if (declaration?.declaration?.irelandETDDeclaration) {
            return selectedBt === 0 ? sectionsEtdMainDocument : sectionsEtdProducts;
        } else if (declaration?.declaration?.entrySummaryDeclaration) {
            return selectedBt === 0 ? sectionsENSMainDocument : sectionsENSProducts;
        }
        return [];
    }, [selectedBt, declaration]);

    useEffect(() => {
        if (paginatorProducts && declarationId) {
            const p = { ...paginatorProducts };
            if (declaration?.declaration?.cdsImportDeclaration) {
                listUkImportProducts(declarationId);
            } else if (declaration?.declaration?.cdsExportDeclaration) {
                listUkExportProducts(declarationId);
            } else if (declaration?.declaration?.irelandExportDeclaration) {
                listIrelandExportProducts(declarationId, p);
            } else if (declaration?.declaration?.irelandH7ImportDeclaration) {
                listIrelandH7Products(declarationId, p);
            } else if (declaration?.declaration?.irelandImportDeclaration) {
                listIrelandH1Products(declarationId, p);
            } else if (declaration?.declaration?.irelandETDDeclaration) {
                listIrelandEtdProducts(declarationId);
            } else if (declaration?.declaration?.entrySummaryDeclaration) {
                listIrelandEnsProducts(declarationId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginatorProducts]);

    useEffect(() => {
        if (products?.list) {
            setMyProducts(products?.list);
        } else {
            setMyProducts([]);
        }
    }, [products]);

    const declarationHasItems = useMemo(() => products?.total, [products]);

    const showVerticalNavigation = useMemo(() => {
        if (selectedBt === 0 || productFormVisible || productId || myProducts?.length === 0) {
            return (
                <ViewOnlySider>
                    <VerticalNav navs={verticalNavigation} />
                </ViewOnlySider>
            );
        }
    }, [selectedBt, productFormVisible, verticalNavigation, myProducts, productId]);

    const expandCollapseButtonContent = useMemo(() => {
        return expandAll ? (
            <>
                {t('buttons.collapseCard', { ns: 'common' })} <DownOutlined />
            </>
        ) : (
            <>
                {t('buttons.uncollapseard', { ns: 'common' })} <RightOutlined />
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandAll]);

    const handleToggleExpandCards = () => {
        setExpandAll(!expandAll);
    };

    const productsButtons = useMemo(() => {
        if (productFormVisible && declarationHasItems && url.pathname.includes('product')) {
            return (
                <Col>
                    <Row align="middle">
                        <Button
                            style={{ marginRight: '1.6rem' }}
                            type="text"
                            onClick={() => {
                                setNewProduct(false);
                                navigate(`/declarations/${declarationId}/history/${historyId}/products`);
                            }}
                        >
                            <LeftOutlined />
                            Back to products list
                        </Button>
                    </Row>
                </Col>
            );
        } else {
            return <></>;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productFormVisible, declarationId, navigate, historyId, declarationHasItems]);

    const masterDetailsButton = useMemo(() => {
        if (selectedBt === 0 || (!productFormVisible && selectedBt === 1)) {
            return (
                <Col>
                    <Row align="middle">
                        <Button
                            style={{ marginRight: '1.6rem' }}
                            type="text"
                            onClick={() => {
                                setNewProduct(false);
                                navigate(`/declarations/${declarationId}/history`);
                            }}
                        >
                            <LeftOutlined />
                            Back to history
                        </Button>
                    </Row>
                </Col>
            );
        }
        return <></>;
    }, [productFormVisible, navigate, selectedBt, declarationId]);

    const selectSectionDiv = useMemo(() => {
        return selectedBt === 0 ? (
            <>
                <Col>
                    <BtMasterGoodsSelected>Master Information</BtMasterGoodsSelected>
                    <BtMasterGoodsNotSelected
                        onClick={() => {
                            navigate('products');
                            setNewProduct(false);
                            setSelectedBt(1);
                        }}
                    >
                        Item Details
                    </BtMasterGoodsNotSelected>
                </Col>
            </>
        ) : (
            <>
                <Col>
                    <BtMasterGoodsNotSelected
                        onClick={() => {
                            navigate(`/declarations/${declarationId}/history/${historyId}`);
                            setSelectedBt(0);
                        }}
                    >
                        Master Information
                    </BtMasterGoodsNotSelected>
                    <BtMasterGoodsSelected>Item Details</BtMasterGoodsSelected>
                </Col>
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBt]);

    const hasLeftNavigation = useMemo(() => {
        if (selectedBt === 0) {
            return true;
        } else {
            if (productFormVisible || productId) {
                return true;
            } else {
                if (myProducts && myProducts?.length === 0) {
                    return true;
                }
                return false;
            }
        }
    }, [selectedBt, productFormVisible, myProducts, productId]);

    const handlePaginationProductsOfDeclaration = (params: Partial<PaginatedParams>) => {
        if (paginatorProducts && paginatorProducts.page && paginatorProducts.size) {
            setPaginatorProducts({ page: params.page, size: paginatorProducts.size });
        } else {
            setPaginatorProducts({ page: params.page, size: defaultPagination.size });
        }
    };

    return (
        <>
            <StyledHeader>
                <Row justify="space-between">
                    {selectSectionDiv}

                    <Row justify="end" align="middle">
                        {masterDetailsButton}
                        {productsButtons}

                        {selectedBt === 0 || productId || productFormVisible ? (
                            <ExpandButton onClick={handleToggleExpandCards}>{expandCollapseButtonContent}</ExpandButton>
                        ) : null}
                    </Row>
                </Row>
            </StyledHeader>
            <StyledLayout>
                {showVerticalNavigation}
                <ViewOnlyContent hasLeftNavigation={hasLeftNavigation}>
                    <DeclarationFormTabContent navigationItems={verticalNavigation}>
                        <FormDiv>
                            <CollapsablesDiv>
                                <Outlet
                                    context={{
                                        hasAlert: true,
                                        formik: formik,
                                        expandAll: expandAll,
                                        viewOnly: true,
                                        productsFormik: productsFormik,
                                        cancelFetchProduct: true,
                                        cancelProducts: () => {},
                                        clearCancel: async () => {},
                                        saveProduct: () => {},
                                        clearSaveProduct: () => {},
                                        updateDeclaration: () => {},
                                        data: myProducts,
                                        onEdit: (id: string) => {
                                            const products: Product[] =
                                                getProductsOfDeclaration(declaration?.declaration) || [];
                                            const selectedProducts = products.find(
                                                (product: Product) => product.id === id
                                            );
                                            if (selectedProducts) {
                                                productsFormik.setValues(selectedProducts);
                                                setNewProduct(true);
                                                navigate(
                                                    `/declarations/${declarationId}/history/${historyId}/products/${selectedProducts.id}`
                                                );
                                            }
                                        },
                                        itemsNumber: declarationHasItems ?? 0,
                                        pageNumber: products?.pageNumber ?? 0,
                                        handlePagination: handlePaginationProductsOfDeclaration,
                                    }}
                                />
                            </CollapsablesDiv>
                        </FormDiv>
                    </DeclarationFormTabContent>
                </ViewOnlyContent>
            </StyledLayout>
        </>
    );
};
export default DeclarationHistoryDetails;

export function useDeclarationHistoryDetails() {
    return useOutletContext<{
        declaration: DeclarationHistory;
    }>();
}
