import { Row } from 'antd';
import DeclarationStatusTag from 'components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import { allRecordEquals } from 'core/utils/arrays';
import { Declaration } from 'store/declarations/declaration';
import { JobResponse } from 'store/jobs/job';
import { getDeclarationPayload, getExporterName, getImporterName } from 'views/declarations/utils/declaration-utils';
import { OptionsCol } from '../SearchBar.style';
import OptionTooltip from './OptionTooltip';

const JobsOptions = (list: JobResponse[], declarationFromJobs: { [key: string]: Declaration[] }) => {
    const getNumberOfDeclarationsOfJob = (jobId: string) => {
        if (declarationFromJobs[jobId]) {
            return declarationFromJobs[jobId].length;
        }
        return 0;
    };

    const getJobExporter = (jobId: string) => {
        if (declarationFromJobs[jobId]) {
            const d = declarationFromJobs[jobId]
                .map((res) => {
                    const payload = getDeclarationPayload(res);
                    return getExporterName(payload);
                })
                .filter((res) => res !== '-');
            return allRecordEquals(d) ? d[0] ?? '-' : 'Multiple';
        }
        return '-';
    };

    const getJobImporter = (jobId: string) => {
        if (declarationFromJobs[jobId]) {
            const d = declarationFromJobs[jobId]
                .map((res) => {
                    const payload = getDeclarationPayload(res);
                    return getImporterName(payload);
                })
                .filter((res) => res !== '-');
            return allRecordEquals(d) ? d[0] ?? '-' : 'Multiple';
        }
        return '-';
    };

    const getJobDeclarationStatus = (jobId: string) => {
        if (declarationFromJobs[jobId]) {
            const d = declarationFromJobs[jobId].map((res) => res.status);
            return allRecordEquals(d) ? (
                <DeclarationStatusTag status={d[0]} />
            ) : (
                <DeclarationStatusTag multiple status={d[0]} />
            );
        }
        return <></>;
    };

    const renderItem = (job: JobResponse) => ({
        value: `/jobs/${job.id}`,
        label: (
            <Row justify="space-between" align="middle" wrap={false} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <OptionsCol>
                    <OptionTooltip data={job.referenceId} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={`${getNumberOfDeclarationsOfJob(job.id)} declarations`} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={getJobExporter(job.id)} />
                </OptionsCol>
                <OptionsCol>
                    <OptionTooltip data={getJobImporter(job.id)} />
                </OptionsCol>
                <OptionsCol>{getJobDeclarationStatus(job.id)}</OptionsCol>
                <OptionsCol></OptionsCol>
            </Row>
        ),
    });

    const jobs = () => list.map((c) => renderItem(c));

    const options = [
        {
            label: <span>Jobs</span>,
            options: jobs(),
        },
    ];

    return options;
};
export default JobsOptions;
