import { Col, Row } from 'antd';
import Alert from 'components/ui/base/alert/Alert';
import Button from 'components/ui/base/button';
import Container from 'components/ui/base/container';
import Divider from 'components/ui/base/divider';
import Drawer from 'components/ui/base/drawer/Drawer';
import CustomModal from 'components/ui/base/modal/Modal';
import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useIndividuals from 'hooks/useIndividuals';
import useRequest from 'hooks/useRequest';
import useSession from 'hooks/useSession';
import debounce from 'lodash.debounce';
import { FC, useEffect, useState } from 'react';
import { deleteIndividual, editIndividual } from 'store/individuals/client';
import { Individual, IndividualType } from 'store/individuals/individual';
import { ReverseCol } from 'views/dashboard/components/Dashboard.styles';
import CreateNewIndividual from './components/CreateNewIndividual';
import IndividualDetails from './components/IndividualDetails';
import IndividualsTable from './components/IndividualsTable';

const UserManagement: FC = () => {
    const [showUserDetails, setShowUserDetails] = useState(false);
    const [individual, setIndividual] = useState<Individual>();
    const [showIndividualDrawer, setShowIndividualDrawer] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>(defaultPagination);
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const { doRequest: doDeleteIndividual, error: deleteError } = useRequest(deleteIndividual);
    const { doRequest: doUpdateIndividual, error: updateError } = useRequest(editIndividual);

    const [messages, setMessages] = useState<{ message: string; type: 'success' | 'error' }>();
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const { userInfo } = useSession();
    const individualId = userInfo?.individualId;
    const { listIndividuals, individuals, isLoading } = useIndividuals({ individualId: individualId });

    const querySearch = async (query: string) => {
        if (query) {
            const params = { query };
            await listIndividuals(params);
        } else {
            await listIndividuals();
        }
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'User Management',
                path: '',
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUser = (id: string) => individuals.list.find((element) => element.id === id);

    useEffect(() => {
        if (!individuals.list.length && !individuals.pageSize && isLoading !== false) {
            listIndividuals();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [individuals, isLoading]);

    useEffect(() => {
        if (paginator) {
            listIndividuals(paginator);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator]);

    useEffect(() => {
        if (deleteError) {
            if (deleteIds.length > 1) {
                showMessage('Failed to delete customers', 'error');
            } else {
                showMessage('Failed to delete customer', 'error');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteError]);

    useEffect(() => {
        if (updateError) {
            showMessage('Failed to update user role', 'error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateError]);

    const addNewUser = () => {
        setShowIndividualDrawer(true);
    };

    const handlePagination = (params: Partial<PaginatedParams>) => {
        setPaginator({ page: params.page, size: paginator.size });
    };

    const handleSort = (params: Partial<PaginatedParams>) => {
        setPaginator({ ...paginator, ...params });
    };

    const handleDelete = (ids: string[]) => {
        setDeleteIds(ids);
        setModalVisible(true);
    };

    const handleEdit = (id: string) => {
        const user = getUser(id);
        setIndividual(user);
        setShowIndividualDrawer(true);
    };

    const handleUpdateRole = async (role: IndividualType, id: string) => {
        const user = getUser(id);
        await doUpdateIndividual(id, { ...user, type: role });
        if (!updateError) {
            showMessage('User role has been successfully edited!', 'success');
        }
        listIndividuals();
    };

    const handleDetails = (id: string) => {
        const user = getUser(id);
        setIndividual(user);
        setShowUserDetails(true);
    };

    const handleEditError = () => {
        setIndividual(undefined);
        setShowIndividualDrawer(false);
        showMessage('Failed to edit customer', 'error');
    };

    const handleCreateError = () => {
        setIndividual(undefined);
        setShowIndividualDrawer(false);
        showMessage('Failed to create customer', 'error');
    };

    const showMessage = (message: string, type: 'success' | 'error') => {
        setMessages({ message, type });
    };

    const addedNewIndividual = () => {
        setIndividual(undefined);
        listIndividuals();
        setShowIndividualDrawer(false);
        showMessage('User has been successfully created!', 'success');
    };

    const editedIndividual = () => {
        setIndividual(undefined);
        setShowIndividualDrawer(false);
        listIndividuals();
        showMessage('User has been successfully edited!', 'success');
    };

    const clearIndividualData = () => {
        setIndividual(undefined);
        setShowIndividualDrawer(false);
    };

    const clearIndividualDetailsData = () => {
        setIndividual(undefined);
        setShowUserDetails(false);
    };

    const deleteIndividuals = () => {
        setModalVisible(false);
        const toDelete: string[] = [...deleteIds];
        toDelete.map(async (id) => {
            await doDeleteIndividual(id);
            listIndividuals();
        });
        if (!deleteError) {
            if (toDelete.length > 1) {
                showMessage('Users has been successfully deleted!', 'success');
            } else {
                showMessage('User has been successfully deleted!', 'success');
            }
        }
    };

    const showAlert = () => {
        return messages?.type && messages?.message ? (
            <Alert
                message={messages.message}
                showIcon
                style={{ marginBottom: '2.4rem' }}
                closable
                type={messages.type}
            />
        ) : (
            <></>
        );
    };

    return (
        <>
            {showAlert()}
            <Drawer
                title={!individual ? 'Add New User' : 'Edit User'}
                width={627}
                visible={showIndividualDrawer}
                onClose={clearIndividualData}
            >
                <CreateNewIndividual
                    closeDrawer={clearIndividualData}
                    handleOk={addedNewIndividual}
                    handleEdit={editedIndividual}
                    individual={individual}
                    handleCreateError={handleCreateError}
                    handleEditError={handleEditError}
                    registeredUsers={individuals?.list}
                />
            </Drawer>

            <Container>
                <Row>
                    <Col span={18}>
                        <H5>User management</H5>
                    </Col>
                    <ReverseCol span={6}>
                        <Button size="large" type="primary" onClick={addNewUser}>
                            Add New User
                        </Button>
                    </ReverseCol>
                </Row>
                <Divider />
                <SearchBar
                    onSearch={(value) => debouncedSearch(value)}
                    inputPlaceholder="Search by name, role, email and phone number"
                    onClear={() => {
                        listIndividuals();
                    }}
                />
                <IndividualsTable
                    handleUpdateRole={handleUpdateRole}
                    handleSort={handleSort}
                    handlePagination={handlePagination}
                    onEdit={handleEdit}
                    onDetails={handleDetails}
                    onDelete={handleDelete}
                    data={individuals}
                    loading={isLoading}
                />
            </Container>

            <CustomModal
                title={
                    deleteIds.length > 1 ? (
                        <H5>Do you want to remove these users?</H5>
                    ) : (
                        <H5>Do you want to remove this user?</H5>
                    )
                }
                centered
                visible={modalVisible}
                onOk={deleteIndividuals}
                onCancel={() => setModalVisible(false)}
                width={762}
                contentText={
                    deleteIds.length > 1
                        ? 'If you remove all these users, you will lose all the information associated to them.'
                        : 'If you remove this user, you will lose all the information associated with him.'
                }
            />
            <Drawer title="User Details" width="627" visible={showUserDetails} onClose={clearIndividualDetailsData}>
                <IndividualDetails individual={individual}></IndividualDetails>
            </Drawer>
        </>
    );
};
export default UserManagement;
