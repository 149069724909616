import { Col, Dropdown, Menu, Row } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from 'store/customers/customer';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { JobResponse } from 'store/jobs/job';
import {
    CloseIcon,
    CustomRow,
    DownIcon,
    FileIcon,
    InvoiceButton,
    ReverseCol,
    SectionTitle,
} from './InvoiceUpload.styles';

interface Props {
    customer?: Customer;
    job?: JobResponse;
    setFormType: Function;
    declarationNameLabels: {
        key: any;
        value: string;
    }[];
    disableFormType: boolean;
    formType?: string;
    country?: DeclarationCountry;
}
const SelectFormType: FC<Props> = ({
    setFormType,
    declarationNameLabels,
    disableFormType,
    formType,
    customer,
    job,
}) => {
    const { t } = useTranslation('customs_declarations');

    const selectFormType = (value: string) => {
        setFormType(value);
    };

    const disable = useMemo(() => !customer || !job || disableFormType, [customer, job, disableFormType]);

    const selectedFormOption = useMemo(() => {
        return formType ? (
            <Row align="middle">
                <CloseIcon
                    className={`${disable && 'disabled'}`}
                    onClick={() => {
                        if (!disable) {
                            setFormType(undefined);
                        }
                    }}
                    disabled={disable}
                />
                <FileIcon /> {formType}
            </Row>
        ) : (
            <></>
        );
    }, [formType, setFormType, disable]);

    return (
        <>
            <CustomRow>
                <Col span={16}>
                    <SectionTitle className={`${disable && 'disabled'}`}>{t('selectForm')}</SectionTitle>
                </Col>
                <ReverseCol span={8}>
                    <Dropdown
                        disabled={disable}
                        overlay={
                            <Menu onClick={(a) => selectFormType(a.key)}>
                                {declarationNameLabels.map((name) => (
                                    <Menu.Item key={name.value}>{name.value}</Menu.Item>
                                ))}
                            </Menu>
                        }
                    >
                        <InvoiceButton size="large">
                            <DownIcon /> {t('selectForm')}
                        </InvoiceButton>
                    </Dropdown>
                </ReverseCol>
                {selectedFormOption}
            </CustomRow>
        </>
    );
};

export default SelectFormType;
