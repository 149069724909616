import { Modal, ModalProps } from 'antd';
import { FormikProvider, setNestedObjectValues, useFormik } from 'formik';
import { MouseEvent, ReactElement } from 'react';
import CdsAmendmentListForm from './CdsAmendmentListForm';
import * as Yup from 'yup';
import { Shape } from 'views/declarations/utils/validation-utils';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { AmendmentSummaryData } from 'store/declarations/common/amendment-summary-data';

const amendmentValidationSchema = Yup.array().of(
    Yup.object().shape<Shape<AmendmentRecord>>({
        reason: Yup.string().nullable(),
        amendmentReasonCode: Yup.string().required('Field required').nullable(),
    })
);

export interface AmendmentRecord {
    path: string;
    value: string;
    action: 'ADD' | 'EDIT' | 'DELETE';
    amendmentReasonCode?: string;
    reason?: string;
}

interface Props extends ModalProps {
    amendments: AmendmentRecord[] | undefined;
    submitAmendmentRequest: (value: AmendmentRecord[]) => Promise<void | AmendmentSummaryData>;
}

const CdsAmendmentModal = ({
    amendments,
    submitAmendmentRequest: submitAmendmentRequestProp,
    ...modalProps
}: Props): ReactElement => {
    const { declarationId } = useParams();

    const submit = async (value: AmendmentRecord[]) => {
        if (!declarationId) return;
        submitAmendmentRequestProp(value).then(() => {
            modalProps.onCancel?.(undefined as unknown as MouseEvent<HTMLElement>);
        });
    };

    const formik = useFormik<AmendmentRecord[]>({
        initialValues: amendments ?? [],
        onSubmit: submit,
        enableReinitialize: true,
        validateOnChange: false,
        validationSchema: amendmentValidationSchema,
    });

    const handleOk = async () => {
        const errors = await formik.validateForm();
        formik.setTouched(setNestedObjectValues(errors, true));
        if (isEmpty(errors)) {
            formik.submitForm();
        }
    };

    return (
        <Modal title="Amendment Summary" width={1000} onOk={handleOk} {...modalProps}>
            <FormikProvider value={formik as any}>
                <CdsAmendmentListForm />
            </FormikProvider>
        </Modal>
    );
};

export default CdsAmendmentModal;
