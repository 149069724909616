import { useCallback, useEffect } from 'react';
import { doGetAepBusinessRules } from 'store/aep-business-rules/action';
import { useAppDispatch, useAppSelector } from '../config/hooks';

const useAepBusinessRules = () => {
    const rules = useAppSelector((state) => state.aepBusinessRules.rules.list);
    const error = useAppSelector((state) => state.aepBusinessRules.error);
    const isLoading = useAppSelector((state) => state.aepBusinessRules.isLoading);

    const dispatch = useAppDispatch();

    const getAepBusinessRules = useCallback(() => dispatch(doGetAepBusinessRules()), [dispatch]);

    useEffect(() => {
        if (!rules.length) {
            getAepBusinessRules();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoading,
        error,
        rules,
    };
};

export default useAepBusinessRules;
