import { Col, Row } from 'antd';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';

interface Props extends BlockProps<'dutyRegimeCode'> {}

const PreferenceBlock = ({
    fieldOptions,
    path = 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee',
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '4/17', []);
    return (
        <Row gutter={18}>
            <Col xs={fieldOptions?.dutyRegimeCode?.columnSpan ?? 24}>
                <DeclarationField
                    name="dutyRegimeCode"
                    pathPrefix={path}
                    validation={{
                        name: t('preference'),
                        number: true,
                        max: 3,
                        required: fieldOptions?.dutyRegimeCode?.required,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        // TODO: Make select
                        <FormInput refNumber={ucc} label={t('preference')} {...getFormikProps(field.name, form)} />
                    )}
                </DeclarationField>
            </Col>
        </Row>
    );
};

export default PreferenceBlock;
