import useCodelists from 'hooks/useCodelists';
import { FC, useCallback } from 'react';
import { CommodityCodeTaxes } from 'store/declaration-product-taxes/CommodityCodeTaxes';
import { CommodityCell, CommodityRow, NumberCell, TaxesTable, TaxRow } from './CommodityCodeTable.styles';

interface Props {
    data?: CommodityCodeTaxes;
}
const CommodityCodeTable: FC<Props> = ({ data }) => {
    const { codelists } = useCodelists();

    const taxTypeDescription = useCallback(
        (taxType: string) => codelists?.irelandTaxTypes.find((e) => e.code === taxType)?.description,
        [codelists]
    );

    return (
        <>
            <TaxesTable width={700}>
                <thead>
                    <th>Tax type</th>
                    <th style={{ textAlign: 'right' }}>Tax base</th>
                    <th style={{ textAlign: 'right' }}>Duty rate %</th>
                    <th style={{ textAlign: 'right' }}>Assessed Amount</th>
                    <th style={{ textAlign: 'right' }}>Total liability</th>
                </thead>
                <tbody>
                    {data?.products.map((product, index) => {
                        return (
                            <>
                                <CommodityRow key={product.id}>
                                    <CommodityCell colSpan={5}>
                                        <strong>Commodity code:</strong> {product.commodityCode}
                                    </CommodityCell>
                                </CommodityRow>
                                {product.taxes.map((tax, index) => {
                                    return (
                                        <TaxRow
                                            key={`${product.id}-${index}`}
                                            last={index === product.taxes.length - 1}
                                        >
                                            <td style={{ paddingTop: 8 }}>
                                                {tax.taxType}
                                                <span> - </span>
                                                {taxTypeDescription(tax.taxType)}
                                            </td>
                                            <NumberCell>{tax.taxBase?.toFixed(4)}</NumberCell>
                                            <NumberCell>{tax.dutyRate?.toFixed(4)}</NumberCell>
                                            <NumberCell>{tax.assessedAmount?.toFixed(2)}</NumberCell>
                                            <NumberCell>{tax.totalLiability?.toFixed(2)}</NumberCell>
                                        </TaxRow>
                                    );
                                })}
                                {
                                    /**
                                     *  FIXME hack to add empty row to separate products
                                     */
                                    index < data.products.length - 1 ? <tr style={{ height: 16 }}></tr> : <></>
                                }
                            </>
                        );
                    })}
                </tbody>
            </TaxesTable>
        </>
    );
};
export default CommodityCodeTable;
