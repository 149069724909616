export enum DeliveryIncoterm {
    DAF = 'DAF', // (Delivered at Frontier)
    EXW = 'EXW', // (Ex works)
    FCA = 'FCA', // (Free carrier)
    CPT = 'CPT', // (Carriage paid to)
    CIP = 'CIP', // (Carriage and insurance paid to)
    DAT = 'DAT', // (Delivered at Terminal)
    DAP = 'DAP', // (Delivered at Place)
    DDP = 'DDP', // (Delivered duty paid)
    DDU = 'DDU', // (Delivered duty unpaid)
    FAS = 'FAS', // (Free alongside ship)
    FOB = 'FOB', // (Free on board)
    CFR = 'CFR', // (Cost and freight)
    CIF = 'CIF', // (Cost, insurance and freight)
    DES = 'DES', // (Delivered ex Ship)
    DEQ = 'DEQ', // (Delivered ex Quay)
    XXX = 'XXX', // (Other)
}

export const DeliveryIncotermLabels = [
    { id: DeliveryIncoterm.DAF, value: 'Delivered at Frontier' },
    { id: DeliveryIncoterm.EXW, value: 'Ex works' },
    { id: DeliveryIncoterm.FCA, value: 'Free carrier' },
    { id: DeliveryIncoterm.CPT, value: 'Carriage paid to' },
    { id: DeliveryIncoterm.CIP, value: 'Carriage and insurance paid to' },
    { id: DeliveryIncoterm.DAT, value: 'Delivered at Terminal' },
    { id: DeliveryIncoterm.DAP, value: 'Delivered at Place' },
    { id: DeliveryIncoterm.DDP, value: 'Delivered duty paid' },
    { id: DeliveryIncoterm.DDU, value: 'Delivered duty unpaid' },
    { id: DeliveryIncoterm.FAS, value: 'Free alongside ship' },
    { id: DeliveryIncoterm.FOB, value: 'Free on board' },
    { id: DeliveryIncoterm.CFR, value: 'Cost and freight' },
    { id: DeliveryIncoterm.CIF, value: 'Cost, insurance and freight' },
    { id: DeliveryIncoterm.DES, value: 'Delivered ex Ship' },
    { id: DeliveryIncoterm.DEQ, value: 'Delivered ex Quay' },
    { id: DeliveryIncoterm.XXX, value: 'Other' },
] as const;
