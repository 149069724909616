export const DeclarationStatus = {
    DRAFT: 'DRAFT',
    SUBMITTED: 'SUBMITTED',
    INVALID: 'INVALID',
    REJECTED: 'REJECTED',
    AWAITING_TARIFF_CALCULATION: 'AWAITING_TARIFF_CALCULATION',
    ACCEPTED: 'ACCEPTED',
    AWAITING_RISK: 'AWAITING_RISK',
    UNDER_PAYMENT: 'UNDER_PAYMENT',
    INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
    UNDER_CONTROL: 'UNDER_CONTROL',
    RELEASED: 'RELEASED',
    REGISTERED: 'REGISTERED',
    NOT_RELEASED: 'NOT_RELEASED',
    UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
    INVALIDATE: 'INVALIDATE',
    RISKED: 'RISKED',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DeclarationStatus = typeof DeclarationStatus[keyof typeof DeclarationStatus];

export const declarationStatusLabels = [
    { id: DeclarationStatus.DRAFT, value: 'Draft' },
    { id: DeclarationStatus.SUBMITTED, value: 'Submitted' },
    { id: DeclarationStatus.INVALID, value: 'Invalid' },
    { id: DeclarationStatus.REJECTED, value: 'Rejected' },
    { id: DeclarationStatus.AWAITING_TARIFF_CALCULATION, value: 'Awaiting tariff calculation' },
    { id: DeclarationStatus.ACCEPTED, value: 'Accepted' },
    { id: DeclarationStatus.AWAITING_RISK, value: 'Awaiting risk' },
    { id: DeclarationStatus.UNDER_PAYMENT, value: 'Under payment' },
    { id: DeclarationStatus.INSUFFICIENT_FUNDS, value: 'Insufficient funds' },
    { id: DeclarationStatus.UNDER_CONTROL, value: 'Under control' },
    { id: DeclarationStatus.RELEASED, value: 'Released' },
    { id: DeclarationStatus.NOT_RELEASED, value: 'Not released' },
    { id: DeclarationStatus.UPLOAD_DOCUMENTS, value: 'Upload documents' },
    { id: DeclarationStatus.INVALIDATE, value: 'Invalidate' },
    { id: DeclarationStatus.RISKED, value: 'Risked' },
    { id: DeclarationStatus.REGISTERED, value: 'Registered' },
] as const;
