import Button from 'components/ui/base/button';
import Notification from 'components/ui/base/notification/Notification';
import { H4 } from 'components/ui/base/typography';
import { enumToText } from 'core/utils/enum-to-text';
import useDeclarations from 'hooks/useDeclarations';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getIrelandSadDraft, getIrelandClearanceDraft } from 'store/declarations/client';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import DeclarationTabKeys from 'views/declarations/utils/DeclarationTabKeys';
import {
    isDeclarationAmendable,
    isDeclarationRefundDepositRequested,
    isDeclarationRefundRemissionRequested,
} from 'views/declarations/utils/declaration-utils';
import InvalidateModal from '../invalidate-modal/InvalidateModal';
import DocumentTable from './DocumentTable';
import { CheckIcon, DocumentsDiv, ExtraSpaceRow, MainDiv, SadDocTitle, StyledButton } from './SummaryView.styles';

const SummaryView: FC = () => {
    const { t } = useTranslation('customs_declarations');
    const [showInvalidateModal, setShowInvalidateModal] = useState(false);
    const { updateCoreTemplateStatus, error, isLoading } = useDeclarations();
    const navigate = useNavigate();
    const { declaration, country, showFormInfo, createAmendment } =
        useOutletContext<{
            declaration: Declaration;
            country: DeclarationCountry;
            showFormInfo: () => void;
            createAmendment: () => void;
        }>() || {};

    const handleCoreTemplate = useCallback(async () => {
        await updateCoreTemplateStatus(declaration.id!);
        if (!error && !isLoading) {
            Notification({
                type: 'success',
                messageTitle: t('released-screen.core-template-success-title'),
                description: t('released-screen.core-template-success-message'),
            });
        } else {
            Notification({
                type: 'error',
                messageTitle: t('released-screen.core-template-error-title'),
                description: t('released-screen.core-template-error-message'),
            });
        }
    }, [updateCoreTemplateStatus, declaration, error, t, isLoading]);

    const handleDuplicate = useCallback(async () => {
        navigate(`/invoice-upload/${country}/${declaration.declarationInternalType}/job/${declaration.jobId}`, {
            state: { declaration: declaration },
        });
    }, [declaration, country, navigate]);

    const handleViewForm = useCallback(() => {
        if (showFormInfo) {
            return showFormInfo();
        }
    }, [showFormInfo]);

    const invalidate = useCallback(() => {
        setShowInvalidateModal(true);
    }, []);

    const handleCreateAmendment = () => {
        createAmendment?.();
    };

    const handleCreateRefundRemission = () => {
        navigate('refunds/remissions/new', {
            state: { activeTabKey: DeclarationTabKeys.REFUNDS },
        });
    };

    const handleCreateRefundDeposit = () => {
        navigate('refunds/deposits/new', {
            state: { activeTabKey: DeclarationTabKeys.REFUNDS },
        });
    };

    return (
        <MainDiv>
            <ExtraSpaceRow>
                <H4>
                    <CheckIcon />
                    {`${country?.charAt(0).toUpperCase()}${country?.slice(1)}  ${
                        declaration?.declarationInternalType && enumToText(declaration?.declarationInternalType)
                    } submitted successfully`}
                </H4>
            </ExtraSpaceRow>
            <ExtraSpaceRow>
                <StyledButton onClick={() => handleViewForm()}>View Form</StyledButton>
                <StyledButton onClick={() => handleDuplicate()}>Duplicate</StyledButton>
                <StyledButton onClick={() => handleCoreTemplate()}> Use as core template</StyledButton>
                <StyledButton onClick={() => invalidate()}> Invalidate</StyledButton>
                {isDeclarationAmendable(declaration) && (
                    <StyledButton onClick={() => handleCreateAmendment()}> Create Amendment</StyledButton>
                )}

                {isDeclarationRefundRemissionRequested(declaration) && (
                    <StyledButton onClick={() => handleCreateRefundRemission()}> Create Refund Remission</StyledButton>
                )}

                {isDeclarationRefundDepositRequested(declaration) && (
                    <Button onClick={() => handleCreateRefundDeposit()}> Create Refund Deposit</Button>
                )}
            </ExtraSpaceRow>
            <DocumentsDiv>
                <SadDocTitle>Sad Document</SadDocTitle>
                <DocumentTable id={declaration?.id} request={getIrelandSadDraft} fileName="sad-document" />
            </DocumentsDiv>
            <DocumentsDiv>
                <SadDocTitle>Clearance slip</SadDocTitle>
                <DocumentTable
                    id={declaration?.id}
                    request={getIrelandClearanceDraft}
                    fileName="clearanceslip-document"
                />
            </DocumentsDiv>
            {declaration?.id && declaration?.declarationInternalType && (
                <InvalidateModal
                    declarationType={declaration.declarationInternalType}
                    declarationId={declaration.id}
                    visible={showInvalidateModal}
                    handleCancel={() => setShowInvalidateModal(false)}
                />
            )}
        </MainDiv>
    );
};
export default SummaryView;
