import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { warehouseTypeLabels } from 'store/declarations/enums/common/warehouse-type';
import {
    getBox44FieldPath,
    getDateFormatForBox44,
    h1Box44KeyNames,
    h1PathBox44,
    handleBox44Field,
} from 'views/declarations/common/box44/box-44-utils';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const OtherDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { viewOnly } = props;
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getH1TooltipsByRefNumber, getH1TooltipsByRefNumberAndField } = useTooltips();
    const { template, form, templateFormik } = useTemplateContext();

    return (
        <section
            id="other-details-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('other-details-form-card');
                }
            }}
            data-testid="otherDetails"
        >
            <NewFormCard title={t('otherDetails')}>
                <FormCardContainer>
                    <FormSelect
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsShipment.warehouseType`, props)}
                        refNumber="2.7"
                        label={t('goodsShipment.warehouseType')}
                        testId="warehouseType"
                        selectOptions={warehouseTypeLabels}
                        tooltip={getH1TooltipsByRefNumberAndField('2.7', t('goodsShipment.warehouseType'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormInput
                        maxLength={35}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`warehouseIdentifier`, props)}
                        refNumber="2.7"
                        testId="warehouseIdentifier"
                        label={t('goodsShipment.warehouseIdentifier')}
                        tooltip={getH1TooltipsByRefNumberAndField('2.7', t('goodsShipment.warehouseIdentifier'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <CountrySelect
                        required
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsShipment.countryDestination`, props)}
                        refNumber="5.8"
                        testId="country-destination"
                        label={t('countryDestination')}
                        tooltip={getH1TooltipsByRefNumberAndField('5.8', t('Country Of Destination'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <CountrySelect
                        required
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsShipment.countryDispatch`, props)}
                        refNumber="5.14"
                        testId="country-dispatch"
                        label={t('countryDispatch')}
                        tooltip={getH1TooltipsByRefNumberAndField('5.14', t('Country of Dispatch'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <DeclarationDatepicker
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsShipment.acceptanceDate`, props)}
                        refNumber="5.31"
                        testId="acceptanceDate"
                        label={t('goodsShipment.acceptanceDate')}
                        tooltip={getH1TooltipsByRefNumberAndField('5.31', t('goodsShipment.acceptanceDate'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <DeclarationDatepicker
                        showTime
                        viewOnly={viewOnly}
                        {...getFormikProps(
                            getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: '1D24' }),
                            props
                        )}
                        onChange={(value) =>
                            handleBox44Field(
                                getDateFormatForBox44(value),
                                template && templateFormik ? templateFormik : props,
                                {
                                    path: h1PathBox44,
                                    keyNames: h1Box44KeyNames,
                                    type: '1D24',
                                },
                                { template, form }
                            )
                        }
                        refNumber="1D24"
                        testId="estimatedTimeOfArrival"
                        label={t('estimatedTimeOfArrival')}
                        tooltip={getH1TooltipsByRefNumber('1D24')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormSelect
                        viewOnly={viewOnly}
                        {...getFormikProps(`goodsShipment.transactionNature`, props)}
                        refNumber="8.5"
                        testId="transactionNature"
                        label={t('transactionNature')}
                        tooltip={getH1TooltipsByRefNumberAndField('8.5', t('transactionNature'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        selectOptions={normalizeCodesToSelect(codelists?.irelandNatureOfTransactions || [])}
                        condensed
                    />

                    <FormInput
                        maxLength={8}
                        viewOnly={viewOnly}
                        {...getFormikProps(
                            getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: '1D94' }),
                            props
                        )}
                        onChange={(e) =>
                            handleBox44Field(
                                e.target.value,
                                template && templateFormik ? templateFormik : props,
                                {
                                    path: h1PathBox44,
                                    keyNames: h1Box44KeyNames,
                                    type: '1D94',
                                },
                                { template, form }
                            )
                        }
                        label={t('roroShipImo')}
                        tooltip={getH1TooltipsByRefNumber('1D94')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        numbersAndLetters
                        refNumber="1D94"
                        testId="roroShipId"
                        condensed
                    />
                    <FormInput
                        maxLength={32}
                        viewOnly={viewOnly}
                        numbersAndLetters
                        label={t('roroUnaccompaniedTrailerReg')}
                        {...getFormikProps(
                            getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: '1D95' }),
                            props
                        )}
                        onChange={(e) =>
                            handleBox44Field(
                                e.target.value,
                                template && templateFormik ? templateFormik : props,
                                {
                                    path: h1PathBox44,
                                    keyNames: h1Box44KeyNames,
                                    type: '1D95',
                                },
                                { template, form }
                            )
                        }
                        tooltip={getH1TooltipsByRefNumber('1D95')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        refNumber="1D95"
                        testId="roroUnaccompaniedTrailerReg"
                        condensed
                    />
                    <FormInput
                        maxLength={255}
                        viewOnly={viewOnly}
                        {...getFormikProps(
                            getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: '1Q35' }),
                            props
                        )}
                        onChange={(e) =>
                            handleBox44Field(
                                e.target.value,
                                template && templateFormik ? templateFormik : props,
                                {
                                    path: h1PathBox44,
                                    keyNames: h1Box44KeyNames,
                                    type: '1Q35',
                                },
                                { template, form }
                            )
                        }
                        numbersAndLetters
                        label={t('textilesCertOfOrigin')}
                        tooltip={getH1TooltipsByRefNumber('1Q35')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        refNumber="1Q35"
                        testId="textilesCertOfOrigin"
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>
        </section>
    );
};

export default OtherDetailsCard;
