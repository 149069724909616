import { Row } from 'antd';
import { Text } from 'components/ui/base/typography';
import SelectTag from 'components/ui/composed/selectTag/SelectTag';
import { ListPayload } from 'core/http/response';
import useProductsTemplates from 'hooks/useProductsTemplates';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import ProductsTable, { Product } from '../components/ProductsTable';
import { StyledDiv } from './ListBulkUploadFiles.styles';

interface Props {
    products: Product[];
    selectedTags: string[];
    onTagsSelected: (tags: string[]) => void;
}

const ProductBulkUploadModal: FC<Props> = ({ products, selectedTags, onTagsSelected }) => {
    const { country } = useParams<{ country: DeclarationCountry }>();
    const list: ListPayload<Product> = {
        list: products,
        pageNumber: 0,
        pageSize: 5,
        total: products?.length ?? 0,
    };
    const { listH1Tags, tagsList } = useProductsTemplates({ country: country! }); // FIXME remove assertion
    const [listOfTags, setListOfTags] = useState(tagsList);

    useEffect(() => {
        listH1Tags();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tagsList) {
            setListOfTags(tagsList);
        }
    }, [tagsList]);

    return (
        <div>
            <Text>Check the products from your CSV file and select the ones you would like to import</Text>
            <StyledDiv>
                <Row>
                    <SelectTag
                        usedTags={selectedTags}
                        suggestedTags={listOfTags}
                        onSuggestedTagsChange={(value: string[]) => setListOfTags(value)}
                        onUsedTagsChange={(value: string[]) => onTagsSelected(value)}
                    />
                </Row>
            </StyledDiv>
            <ProductsTable
                country={country!} // FIXME remove assertion
                commands={false}
                source={list}
                refresh={() => {}}
                handlePagination={() => {}} // FIXME: The payload isn't list payload
                handleSort={() => {}} // FIXME: The payload isn't list payload
            />
        </div>
    );
};
export default ProductBulkUploadModal;
