import SearchBar from 'components/ui/base/searchbar';
import CustomerOptions from 'components/ui/base/searchbar/search-options/CustomersOptions';
import DeclarationsOptions from 'components/ui/base/searchbar/search-options/DeclarationsOptions';
import JobsOptions from 'components/ui/base/searchbar/search-options/JobsOptions';
import debounce from 'lodash.debounce';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Customer } from 'store/customers/customer';
import { Declaration } from 'store/declarations/declaration';
import { JobResponse } from 'store/jobs/job';

interface Props {
    querySearch: (query: string) => void;
    declarations?: Declaration[];
    jobs: JobResponse[];
    customers: Customer[];
    declarationFromJobs: {
        [key: string]: Declaration[];
    };
}
const DashboardSearchBar: FC<Props> = ({ querySearch, declarations, jobs, customers, declarationFromJobs }) => {
    const { t } = useTranslation('dashboard');

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);
    const navigate = useNavigate();

    const getSearchOptions = useMemo(() => {
        let jobOptions: any[] = [];
        let customerOptions: any[] = [];
        let declarationOptions: any[] = [];
        if (declarations) {
            declarationOptions = [...DeclarationsOptions(declarations)];
        }
        if (jobs) {
            jobOptions = [...JobsOptions(jobs, declarationFromJobs)];
        }
        if (customers) {
            customerOptions = [...CustomerOptions(customers)];
        }

        return [...customerOptions, ...jobOptions, ...declarationOptions];
    }, [declarations, customers, jobs, declarationFromJobs]);

    return (
        <SearchBar
            onSearch={(value: string) => {
                debouncedSearch(value);
            }}
            onSelect={(value: string) => {
                navigate(value);
            }}
            options={getSearchOptions}
            inputPlaceholder={t('placeholder')}
        />
    );
};
export default DashboardSearchBar;
