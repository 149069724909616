import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const GrossMassCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('goodsItems.grossMass')}>
            <FormCardContainer>
                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`totalGrossMass`, props)}
                    refNumber="6.5"
                    label={t('goodsItems.grossMass')}
                    tooltip={getCdsTooltipsByRefNumberAndField('6.5', t('goodsItems.grossMass'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`totalPackages`, props)}
                    refNumber="6.18"
                    label={t('totalPackages')}
                    tooltip={getCdsTooltipsByRefNumberAndField('6.18', t('totalPackages'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsShipment.container`, props)}
                    refNumber="7.2"
                    label={t('container')}
                    tooltip={getCdsTooltipsByRefNumberAndField('7.2', t('container'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default GrossMassCard;
