import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BorderlessCard from 'views/declarations/common/cards/borderless-card/BorderlessCard';
import { DeclarationMultipleFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { FormCardContainer } from '../../../../../common/cards/NewFormCard';

const ContainerIdentificationNumberCard: FC<DeclarationMultipleFormCardProps & { subSection: string }> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    const index = props.index;
    const size = props.size;
    const last = index === size - 1;

    const state = useMemo(
        () =>
            getCardState(
                [{ name: `containerIdentificationNumbers.${index}.containerIdentificationNumber`, required: false }],
                props
            ),
        [index, props]
    );

    return (
        <BorderlessCard
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            title={`${props.subSection}${t('containerIdentificationNumbers')}`}
            multiple
            addCard={() => {
                if (last) {
                    props.add();
                }
            }}
            removeCard={() => {
                if (props.size > 1) {
                    props.remove(index);
                }
            }}
            isFirst={index === 0}
            isLast={last}
            state={state}
            keyCard={props.keyCard}
        >
            <FormCardContainer>
                <DeclarationInput
                    {...getFormikProps(`containerIdentificationNumbers.${index}.containerIdentificationNumber`, props)}
                    viewOnly={props.viewOnly}
                    maxLength={17}
                    refNumber="7.10"
                    label={t('containerIdentificationNumbers')}
                    tooltip={getCdsTooltipsByRefNumberAndField('7.10', t('containerIdentificationNumbers'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </BorderlessCard>
    );
};
export default ContainerIdentificationNumberCard;
