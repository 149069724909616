import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const MeansOfTransportAtArrivalCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('identityOfMeansOfTransportOnArrival')}>
            <FormCardContainer>
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps('transportIdentityMeansType', props)}
                    refNumber="7.9"
                    label={t('transportIdentityMeansType')}
                    selectOptions={normalizeCodesToSelect(codelists?.ukTransportMeansIdentificationTypes || [])}
                    tooltip={getCdsTooltipsByRefNumberAndField('7.9', t('transportIdentityMeansType'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('transportIdentityMeansId', props)}
                    refNumber="7.9"
                    label={t('transportIdentityMeansId')}
                    tooltip={getCdsTooltipsByRefNumberAndField('7.9', t('transportIdentityMeansId'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default MeansOfTransportAtArrivalCard;
