import Drawer from 'components/ui/base/drawer/Drawer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CreateNewIndividual from 'views/user-management/components/CreateNewIndividual';

interface Props {
    closeDrawer: () => void;
    handleOk: () => void;
    handleCreateError: () => void;
    visible: boolean;
}
const AddTeamMember: FC<Props> = ({ closeDrawer, handleOk, handleCreateError, visible }) => {
    const { t } = useTranslation('dashboard');

    return (
        <Drawer title={t('addNewUser.title')} width={627} visible={visible} onClose={closeDrawer}>
            <CreateNewIndividual closeDrawer={closeDrawer} handleOk={handleOk} handleCreateError={handleCreateError} />
        </Drawer>
    );
};
export default AddTeamMember;
