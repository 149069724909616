import config from 'config';
import { SuccessResponse } from 'core/http/response';
import { Declaration } from 'store/declarations/declaration';
import axiosClient from '../../config/axios';

export const addProductsTemplatesToIrelandH1 = (
    declarationId: string,
    productTemplateIds: string[]
): Promise<Declaration> => {
    return axiosClient
        .post<SuccessResponse<Declaration>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/product-templates`,
            {
                productTemplateIds,
            }
        )
        .then((response) => response.data.payload);
};

export const addProductsTemplatesToIrelandH7 = (
    declarationId: string,
    productTemplateIds: string[]
): Promise<Declaration> => {
    return axiosClient
        .post<SuccessResponse<Declaration>>(
            `${config.declarationsUrl}/ireland/import/declarations/${declarationId}/h7/product-templates`,
            {
                productTemplateIds,
            }
        )
        .then((response) => response.data.payload);
};

export const addProductsTemplatesToIrelandExport = (
    declarationId: string,
    productTemplateIds: string[]
): Promise<Declaration> => {
    return axiosClient
        .post<SuccessResponse<Declaration>>(
            `${config.declarationsUrl}/ireland/export/declarations/${declarationId}/product-templates`,
            {
                productTemplateIds,
            }
        )
        .then((response) => response.data.payload);
};

export const addProductsTemplatesToUkExport = (
    declarationId: string,
    productTemplateIds: string[]
): Promise<Declaration> => {
    return axiosClient
        .post<SuccessResponse<Declaration>>(
            `${config.declarationsUrl}/uk/export/declarations/${declarationId}/product-templates`,
            {
                productTemplateIds,
            }
        )
        .then((response) => response.data.payload);
};

export const addProductsTemplatesToUkImport = (
    declarationId: string,
    productTemplateIds: string[]
): Promise<Declaration> => {
    return axiosClient
        .post<SuccessResponse<Declaration>>(
            `${config.declarationsUrl}/uk/import/declarations/${declarationId}/product-templates`,
            {
                productTemplateIds,
            }
        )
        .then((response) => response.data.payload);
};
