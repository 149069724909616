import { NavItem } from 'views/declarations/common/nav-item';

export const sectionsH7MainDocument: NavItem[] = [
    { title: 'Master Details', href: '#master-details' },
    { title: 'Parties', href: 'parties-form-card' },
    { title: 'Documents Additional Information', href: '#document-additional-info' },
    { title: 'Previous Document', href: '#previous-document' },
    { title: 'Transport Document', href: '#transport-document' },
    { title: 'Transport Costs', href: '#transport-costs' },
    { title: 'Location Of Goods', href: '#location-of-goods' },
];

export const sectionsH7Products: NavItem[] = [
    { title: 'Goods Information', href: '#goods-information' },
    { title: 'Additional Procedure Code', href: '#additional-procedures' },
    { title: 'Documents Additional Information', href: '#document-additional-info' },
    { title: 'Previous Document', href: '#previous-document' },
    { title: 'Valuation Information', href: '#valuation-information' },
    { title: 'Transport Document', href: '#tra-details' },
    { title: 'Parties', href: '#parties-card' },
    { title: 'Additional Fiscal Reference', href: '#master-details' },
    { title: 'Duties And Taxes', href: '#master-details' },
];

export const sectionsH1MainDocument: NavItem[] = [
    { title: 'Master Details', href: 'master-details-form-card' },
    { title: 'Parties', href: 'parties-form-card' },
    { title: 'Value details', href: 'value-details-form-card' },
    { title: 'Previous Document', href: 'previous-document-information-form-card' },
    { title: 'Delivery terms', href: 'delivery-terms-form-card' },
    { title: 'Transport information', href: 'transport-information-form-card' },
    { title: 'Transport costs', href: 'transport-costs-form-card' },
    { title: 'Transport document information', href: 'transport-document-information-form-card' },
    { title: 'Goods location', href: 'goods-location-form-card' },
    { title: 'Other details', href: 'other-details-form-card' },
    { title: 'Extra details', href: 'extra-details-form-card' },
    { title: 'Authorisation', href: 'authorisation-form-card' },
    { title: 'Authorisation Holder', href: 'authorisation-holder-form-card' },
    { title: 'Guarantee', href: 'guarantee-form-card' },
    { title: 'Goods shipment', href: 'goods-shipment-form-card' },
    { title: 'Additional Information', href: 'additional-information0-form-card0' },
    { title: 'Additional Conditional Information', href: 'add-conditional-information-form-card' },
];

export const sectionsH1Products: NavItem[] = [
    { title: 'Goods Information', href: 'goods-information-form-card' },
    { title: 'Item Details', href: 'item-details-form-card' },
    { title: 'Packages', href: 'packages-form-card' },
    { title: 'Value Details', href: 'value-details-form-card' },
    { title: 'Other Details', href: 'other-details-form-card' },
    { title: 'Extra item details', href: 'extra-item-details-form-card' },
    { title: 'Parties', href: 'parties-form-card' },
    {
        title: 'Simplified Declaration Document Writing Off Header',
        href: 'simplified-declaration-document-writing-off-header-form-card',
    },
    { title: 'Additional Information', href: 'additional-information0-form-card0' },
    { title: 'Additional Conditional Information', href: 'add-conditional-information-form-card' },
];
