import {
    InfoCircleFilled,
    LoadingOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { Col, Collapse, Layout, Tabs } from 'antd';
import Button from 'components/ui/base/button/Button';
import styled from 'styled-components';
import { colors, fonts } from 'theme';

const { Panel } = Collapse;
const { Header, Sider, Content } = Layout;

interface ActiveProp {
    active: boolean;
}

interface ErrorProp {
    error?: boolean;
}

interface DeclarationButtonProps {
    error?: boolean;
    marginRight?: string;
    active?: boolean;
}

interface HeightProps {
    $hasLeftNavigation?: boolean;
    $hasAlert?: boolean;
    collapsedFooter?: boolean;
    collapsedHeader?: boolean;
}

export const FormDiv = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
`;

export const CollapsablesDiv = styled.div`
    width: 100%;
    margin-right: 2rem;
`;

export const StyledPanel = styled(Panel)`
    font-size: 2.4rem;
    font-weight: ${fonts.weight.bold};
    line-height: 3.2rem;
    border: none;

    .ant-collapse-header-text {
        width: 100%;
    }

    .ant-collapse-arrow {
        right: 40px !important;
    }
`;

export const StyledTabs = styled(Tabs)`
    color: ${colors.darkPurple};
    overflow: visible;
    font-family: Open Sans;
    font-style: normal;
    font-weight: ${fonts.weight.regular};
    font-size: 16px;
    line-height: 24px;

    .ant-tabs-tab {
        font-size: 16px;
        cursor: pointer;
    }

    .ant-tabs-tab:hover {
        color: rgba(56, 55, 82, 1);
    }

    > .ant-tabs-nav::before {
        border-bottom: 0.1rem solid ${colors.lightGrey};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${colors.darkPurple};
        text-shadow: 0 0 0.25px currentColor;
        font-family: Open Sans;
        font-style: normal;
        font-weight: ${fonts.weight.semibold};
        font-size: 16px;
        line-height: 24px;
    }

    .ant-tabs-ink-bar {
        background: ${colors.darkPurple};
    }

    .ant-tabs-nav .ant-tabs-ink-bar {
        height: 0.4rem;
    }
`;

export const StyledMinusCircleOutlined = styled(MinusCircleOutlined)`
    color: ${colors.lightBlue};
    font-size: 2.1rem;
`;
export const StyledPlusCircleOutlined = styled(PlusCircleOutlined)`
    color: ${colors.lightBlue};
    font-size: 2.1rem;
`;

export const ExpandedIconDiv = styled.div`
    margin-top: 0.4rem;
`;

export const VerticalNav = styled.div`
    border: 1px solid ${colors.darkGrey2};
    border-radius: 8px;
    overflow: hidden;
`;

export const StyledA = styled.a<ActiveProp & ErrorProp>`
    display: flex;
    align-items: center;
    min-height: 4rem;
    padding: 0 1rem;
    color: ${({ active }) => (active ? colors.white : colors.darkGrey)};
    background: ${({ active }) => (active ? colors.darkPurple : colors.white)};
    font-family: Open Sans;
    font-style: normal;
    font-weight: ${fonts.weight.semibold};
    font-size: 14px;
    line-height: 22px;

    outline: ${({ error }) => (error ? `2px solid ${colors.lightRed}` : 'none')};
    outline-offset: -3px;

    &:hover {
        color: ${({ active }) => (active ? colors.white : colors.darkPurple)};
        outline: ${({ error }) => (error ? `2px solid ${colors.lightRed}` : 'none')};
    }
`;

export const StyledInfoCircleFilled = styled(InfoCircleFilled)`
    margin-left: 0.8rem;
    font-size: 1.4rem;
    align-items: center;
    display: flex;
    color: ${colors.darkGrey2};
`;

export const FilterCustomButtom = styled(Button)`
    border-color: ${colors.purpleBlue};
    color: ${colors.purpleBlue};
    &:hover {
        background: ${colors.purpleBlue};
        color: ${colors.white};
        border-color: ${colors.white};
    }
`;

export const BtMasterInformationItemDetails = styled(Button)<DeclarationButtonProps>`
    height: 4.4rem;
    background: ${({ active }) => (active ? colors.lightBlue1 : 'transparent')};
    margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}rem` : 0)};
    border: ${({ active }) => `1px solid ${active ? colors.darkPurple : colors.lightGrey5}`};
    border-bottom: 0;
    color: ${({ error }) => (error ? colors.white : colors.darkPurple)};
    font-weight: ${fonts.weight.semibold};
    font-size: 1.6rem;
    line-height: 2.22rem;
    border-radius: 4px 4px 0 0;
    &:hover {
        color: ${({ error }) => (error ? colors.white : colors.darkPurple)};
        border: ${({ error, active }) =>
            error ? `1px solid ${colors.error}` : `1px solid ${active ? colors.darkPurple : colors.lightGrey5}`};
        border-bottom: 0;
        background: ${({ error }) => (error ? colors.error : colors.lightBlue1)};
    }
    &:focus {
        color: ${({ error }) => (error ? colors.white : colors.darkPurple)};
        border: ${({ error, active }) =>
            error ? `1px solid ${colors.error}` : `1px solid ${active ? colors.darkPurple : colors.lightGrey5}`};
        border-bottom: 0;
        background: ${({ error }) => (error ? colors.error : colors.lightBlue1)};
    }
`;

export const BtMasterGoodsSelected = styled(Button)<DeclarationButtonProps>`
    height: 4.4rem;
    background: ${({ error }) => (error ? colors.error : colors.lightBlue1)};
    margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}rem` : 0)};
    border: ${({ error }) => (error ? `1px solid ${colors.error}` : `1px solid ${colors.darkPurple}`)};
    border-bottom: 0;
    color: ${({ error }) => (error ? colors.white : colors.darkPurple)};
    font-weight: ${fonts.weight.semibold};
    font-size: 1.6rem;
    line-height: 2.22rem;
    border-radius: 4px 4px 0 0;
    &:hover {
        color: ${({ error }) => (error ? colors.white : colors.darkPurple)};
        border: ${({ error }) => (error ? `1px solid ${colors.error}` : `1px solid ${colors.darkPurple}`)};
        border-bottom: 0;
        background: ${({ error }) => (error ? colors.error : colors.lightBlue1)};
    }
    &:focus {
        color: ${({ error }) => (error ? colors.white : colors.darkPurple)};
        border: ${({ error }) => (error ? `1px solid ${colors.error}` : `1px solid ${colors.darkPurple}`)};
        border-bottom: 0;
        background: ${({ error }) => (error ? colors.error : colors.lightBlue1)};
    }
`;

export const BtMasterGoodsNotSelected = styled(Button)<DeclarationButtonProps>`
    margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}rem` : 0)};
    border: ${({ error }) => (error ? `1px solid ${colors.error}` : `1px solid ${colors.lightGrey5}`)};
    border-bottom: 0;
    height: 4.4rem;
    color: ${({ error }) => (error ? colors.error : colors.darkPurple)};
    font-weight: ${fonts.weight.semibold};
    font-size: 1.6rem;
    line-height: 2.22rem;
    border-radius: 4px 4px 0 0;
    &:hover {
        background: ${({ error }) => (error ? colors.error : colors.lightBlue1)};
        border: ${({ error }) => (error ? `1px solid ${colors.error}` : `1px solid ${colors.lightGrey5}`)};
        border-bottom: 0;
        color: ${({ error }) => (error ? colors.white : colors.darkPurple)};
    }
    &:focus {
        color: ${({ error }) => (error ? colors.white : colors.darkPurple)};
        border: ${({ error }) => (error ? `1px solid ${colors.error}` : `1px solid ${colors.lightGrey5}`)};
        border-bottom: 0;
        background: ${({ error }) => (error ? colors.error : colors.lightBlue1)};
    }
`;

export const InvalidIcon = styled(WarningOutlined)`
    font-size: 1.8rem;
`;

export const CompletedSection = styled.div`
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    border: 1px solid #383752;
    box-sizing: border-box;
    border-radius: 4px;
    color: ${colors.darkPurple};
`;

export const ProductButton = styled(Button)`
    background-color: #ffffff;
    color: #0040e4;
    border: 1px solid #0040e4;
    height: 40px;
    margin-right: 1.6rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

export const ExpandButton = styled(Button)`
    height: 4rem;
    width: 20.4rem;
`;
export const ProductExpandButton = styled(ExpandButton)`
    margin-right: 1.6rem;
`;

export const DivButtonsOptionsForProducts = styled.div`
    display: flex;
`;

export const ColExpandCards = styled(Col)`
    text-align: right;
`;

export const StyledHeader = styled(Header)`
    background: ${colors.white};
    padding: 0;
    z-index: 2;
    position: sticky;
    width: 100%;
    top: 0;
    height: min-content;
`;

export const StyledLayout = styled(Layout)`
    background: ${colors.white};
    padding: 0;
    height: 100%;
`;

const handleHeightWithoutFooter = (hasAlert?: boolean, collapsedHeader?: boolean) => {
    const pageDefault = 17.4; // header + padding (top and bottom) + tabs
    const header = !collapsedHeader ? 9.2 : 4.6;
    const alert = hasAlert ? 4.2 : 0;
    const height = pageDefault + header + alert;
    return `calc(100vh - ${height}rem)`;
};

export const StyledContent = styled(Content)<HeightProps>`
    height: 100%;
    background: ${colors.white};
    padding: 0;
    position: relative;
    /* margin-left: ${({ $hasLeftNavigation }) => ($hasLeftNavigation ? '28rem' : '0')}; */
    margin-left: ${({ $hasLeftNavigation }) => ($hasLeftNavigation ? '2rem' : '0')};
    overflow: auto;
    padding-right: 4px;
`;

export const ContentWithoutFooter = styled(Content)<HeightProps>`
    background: ${colors.white};
    padding: 0;
    overflow: auto;
    height: ${({ collapsedHeader, $hasAlert }) => handleHeightWithoutFooter($hasAlert, collapsedHeader)};
    padding-right: 4px;
`;

export const StyledSider = styled(Sider)<HeightProps>`
    height: 100%;
    background: ${colors.white};
    padding: 0;
    min-width: 26.4rem !important;
    max-width: 26.4rem !important;
    overflow-y: auto;
`;

export const ViewOnlyContent = styled(Content)<{ hasLeftNavigation?: boolean }>`
    background: ${colors.white};
    padding: 0;
    margin-left: ${({ hasLeftNavigation }) => (hasLeftNavigation ? '28rem' : '0')};
    overflow: auto;
    height: calc(100vh - 26.3rem);
    padding-right: 4px;
`;

export const ViewOnlySider = styled(Sider)`
    background: ${colors.white};
    padding: 0;
    position: fixed;
    height: calc(100vh - 26.3rem);
    min-width: 26.4rem !important;
    max-width: 26.4rem !important;
    overflow-y: auto;
`;

export const SubmitLoading = styled(LoadingOutlined)`
    font-size: 1.6rem;
    margin-left: 1rem;
`;

export const NumberItemsRounderInfo = styled.span`
    width: 2.22rem;
    height: 2.22rem;
    line-height: 2.22rem;
    font-size: 1.3rem;
    overflow: hidden;
    border-radius: 50%;
    background: ${colors.darkPurple};
    color: ${colors.white};
    margin-left: 0.5rem;
    white-space: nowrap;
    text-align: center;
    vertical-align: text-bottom;
`;

export const Tags = styled.div`
    display: inline-block;
    max-width: 500px;
    display: flex;
`;
