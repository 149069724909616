import { useFormikContext } from 'formik';
import { merge } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import { FormCardContainer } from '../../../common/cards/NewFormCard';
import ContainerIdentificationNumberBlock, {
    containerIdentificationNumberBlockProductValidation,
    containerIdentificationNumberBlockValidation,
    containerIdentificationNumberFieldsValidation,
} from '../blocks/ContainerIdentificationNumberBlock';
import SealNumberBlock, { sealNumberBlockValidation, sealNumberFieldsValidation } from '../blocks/SealNumberBlock';

export const transportEquipmentCardValidation = {
    selfValidators: [sealNumberBlockValidation, containerIdentificationNumberBlockValidation],
};

export const transportEquipmentCardValidationProduct = {
    selfValidators: [containerIdentificationNumberBlockProductValidation],
};

const TransportEquipmentCard: FC<DeclarationFormCardProps & { isProduct?: boolean }> = ({
    isProduct,
    propsPathPrefix,
    viewOnly,
}) => {
    const { t } = useTranslation('form');
    const formik = useFormikContext();

    return (
        <MultipleItemsCard
            viewOnly={viewOnly}
            title={t('transportEquipment')}
            path={propsPathPrefix ?? 'goodsShipment.consignment.transportEquipment'}
            getFieldProps={formik.getFieldProps}
            getFieldMeta={formik.getFieldMeta}
            getFieldHelpers={formik.getFieldHelpers}
            validate={merge(!isProduct && sealNumberFieldsValidation, containerIdentificationNumberFieldsValidation)}
            initialValues={{ id: '', seal: [{ id: '' }] }}
            list={(list) => [
                { field: t('containerIdentificationNumbers'), value: list.id },
                { field: 'Number of seals', value: list.seal?.length.toString() ?? '0' },
            ]}
            condensed
        >
            {(path) => (
                <FormCardContainer oneColumn>
                    <ContainerIdentificationNumberBlock path={path} />
                    {!isProduct && <SealNumberBlock path={path} />}
                </FormCardContainer>
            )}
        </MultipleItemsCard>
    );
};
export default TransportEquipmentCard;
