import { ListPayload } from 'core/http/response';

enum ProductTemplateTypes {
    H1 = 'H1',
    H7 = 'H7',
    B1 = 'B1',
    ENS = 'ENS',
}

type ListType = { [id: string]: any };

const addTemplateType = (payload: ListPayload<ListType>, type: ProductTemplateTypes) => {
    const list = payload.list.map((p) => ({ ...p, templateType: type }));
    return { ...payload, list };
};

const generalizeSingleNewProductTemplates = (payload: any) => {
    const { id, customerId, tags, declarationTemplateId } = payload;

    return { ...payload.template, id, customerId, tags, declarationTemplateId };
};

const generalizeListNewProductTemplates = (payload: any) => {
    const { pageNumber, pageSize, total } = payload;
    const list = payload.list.map((productTemplate: any) => {
        const { id, customerId, tags, templateType, declarationTemplateId } = productTemplate;
        return { ...productTemplate.template, id, customerId, tags, templateType, declarationTemplateId };
    });
    return { list, pageNumber, pageSize, total };
};

export {
    ProductTemplateTypes,
    addTemplateType,
    generalizeSingleNewProductTemplates,
    generalizeListNewProductTemplates,
};
