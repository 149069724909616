import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const BorderTransportMeansCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('modeOfTransportAtTheBorder')}>
            <FormCardContainer>
                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`modeOfTransportAtTheBorder`, props)}
                    refNumber="7.4"
                    label={t('modeOfTransportAtTheBorder')}
                    selectOptions={normalizeCodesToSelect(codelists?.ukTransportModeTypes || [])}
                    tooltip={getCdsTooltipsByRefNumberAndField('7.4', t('modeOfTransportAtTheBorder'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsShipment.inlandModeOfTransport`, props)}
                    refNumber="7.5"
                    label={t('inlandModeOfTransport')}
                    selectOptions={normalizeCodesToSelect(codelists?.ukTransportModeTypes || [])}
                    tooltip={getCdsTooltipsByRefNumberAndField('7.5', t('inlandModeOfTransport'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`nationalityOfMeansOfTransportAtTheBorder`, props)}
                    refNumber="7.15"
                    label={t('nationalityOfMeansOfTransportAtTheBorder')}
                    tooltip={getCdsTooltipsByRefNumberAndField('7.15', t('nationalityOfMeansOfTransportAtTheBorder'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default BorderTransportMeansCard;
