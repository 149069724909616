import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { additionalDeclarationTypesLabels } from 'store/declarations/enums/common/additional-declaration-type';
import { taxPaymentMethodLabels } from 'store/declarations/enums/ireland/tax-payment-method';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { Customer } from 'store/customers/customer';
import SearchCustomer from 'views/declarations/common/search-customer/SearchCustomer';
import useSearchCustomer from 'views/declarations/common/search-customer/useSearchCustomer';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import {
    getBox44FieldPath,
    h7Box44KeyNames,
    h7PathBox44,
    handleBox44Field,
} from 'views/declarations/common/box44/box-44-utils';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import useProducts from 'hooks/useProducts';
import useNumberOfItemsModal from 'views/declarations/common/declaration-view/utils/useNumberOfItemsModal';

const MasterDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getH7TooltipsByRefNumber, getH7TooltipsByField, getH7TooltipsByRefNumberAndField } = useTooltips();
    const { template, form, templateFormik } = useTemplateContext();
    const { createIrelandH7ImportProduct, listIrelandH7Products } = useProducts();

    const {
        handleSelectCustomer,
        show: showSearchCustomerDrawer,
        triggerShowDrawer: triggerShowSearchCustomerDrawer,
        triggerHideDrawer: triggerHideSearchCustomerDrawer,
    } = useSearchCustomer();

    const [handleNumberOfItems, modalContextHolder] = useNumberOfItemsModal({
        createProduct: createIrelandH7ImportProduct,
        listProducts: listIrelandH7Products,
    });

    return (
        <NewFormCard title="Master Details">
            <FormCardContainer>
                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`additionalDeclarationType`, props)}
                    refNumber="1.2"
                    label={t('additionalDeclarationType')}
                    tooltip={getH7TooltipsByRefNumber('1.2')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={additionalDeclarationTypesLabels}
                    condensed
                />
                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`customsOfficeLodgement`, props)}
                    refNumber="1.11"
                    label={t('customsOfficeLodgement')}
                    tooltip={getH7TooltipsByRefNumber('1.11')}
                    selectOptions={normalizeCodesToSelect(codelists?.irelandNationalCustomsOfficeCodes || [])}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`deferredPayment`, props)}
                    label={t('deferredPayment')}
                    tooltip={getH7TooltipsByField(t('deferredPayment'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    onSearchCustomer={({ fieldHelpers }) => {
                        triggerShowSearchCustomerDrawer((customer: Customer) => {
                            fieldHelpers?.setValue(customer.eori);
                        });
                    }}
                    condensed
                />
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`preferredPaymentMethod`, props)}
                    label={t('preferredPaymentMethod')}
                    tooltip={getH7TooltipsByField(t('preferredPaymentMethod'))}
                    selectOptions={taxPaymentMethodLabels}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    maxLength={17}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`representativeIdentificationNumber`, props)}
                    refNumber="3.20"
                    label={t('representativeIdNumber')}
                    tooltip={getH7TooltipsByRefNumber('3.20')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    onSearchCustomer={({ fieldHelpers }) =>
                        triggerShowSearchCustomerDrawer((customer: Customer) => {
                            fieldHelpers?.setValue(customer.eori);
                        })
                    }
                    condensed
                />
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`representativeStatus`, props)}
                    refNumber="3.4"
                    label={t('representativeStatus')}
                    tooltip={getH7TooltipsByRefNumber('3.4')}
                    selectOptions={normalizeCodesToSelect(codelists?.irelandRepresentativeStatusCodes || [])}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    viewOnly={props.viewOnly}
                    maxLength={35}
                    {...getFormikProps(`documentsAuthorisations.referenceNumberUcr`, props)}
                    refNumber="2.4"
                    label={t('referenceNumberUCR')}
                    tooltip={getH7TooltipsByRefNumberAndField('2.4', t('referenceNumberUCR'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`numberOfItems`, props)}
                    onBlur={(e) => handleNumberOfItems(Number(e.target.value))}
                    label={t('numberOfItems')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                {modalContextHolder}
                <FormInput
                    viewOnly={props.viewOnly}
                    label={t('vatSpacialArrangementAuthorisation')}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h7PathBox44, keyNames: h7Box44KeyNames, type: '1A06' }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h7PathBox44,
                                keyNames: h7Box44KeyNames,
                                type: '1A06',
                            },
                            { template, form }
                        )
                    }
                    refNumber="1A06"
                    condensed
                />
                <SearchCustomer.Drawer
                    onClose={triggerHideSearchCustomerDrawer}
                    show={showSearchCustomerDrawer}
                    onSelectCustomer={handleSelectCustomer}
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default MasterDetailsCard;
