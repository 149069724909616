export const supervisingCustomsOffice = [
    { id: 'GBABD001', value: '' },
    { id: 'GBAVO001', value: '' },
    { id: 'GBBEL001', value: '' },
    { id: 'GBBEL002', value: '' },
    { id: 'GBBEL003', value: '' },
    { id: 'GBBEL004', value: '' },
    { id: 'GBBHM001', value: '' },
    { id: 'GBBHM002', value: '' },
    { id: 'GBBLB001', value: '' },
    { id: 'GBBLT001', value: '' },
    { id: 'GBBOE001', value: '' },
    { id: 'GBBOE002', value: '' },
    { id: 'GBBOE003', value: '' },
    { id: 'GBBOH001', value: '' },
    { id: 'GBBRD001', value: '' },
    { id: 'GBBRD002', value: '' },
    { id: 'GBBRS001', value: '' },
    { id: 'GBBRS002', value: '' },
    { id: 'GBBRS003', value: '' },
    { id: 'GBBSH001', value: '' },
    { id: 'GBBTH001', value: '' },
    { id: 'GBCDF001', value: '' },
    { id: 'GBCDF002', value: '' },
    { id: 'GBCLD001', value: '' },
    { id: 'GBCMG001', value: '' },
    { id: 'GBCNB001', value: '' },
    { id: 'GBCRG001', value: '' },
    { id: 'GBCRO001', value: '' },
    { id: 'GBCRO002', value: '' },
    { id: 'GBCVT001', value: '' },
    { id: 'GBCWY001', value: '' },
    { id: 'GBDUN001', value: '' },
    { id: 'GBDUN002', value: '' },
    { id: 'GBDVR001', value: '' },
    { id: 'GBDXY001', value: '' },
    { id: 'GBEDI001', value: '' },
    { id: 'GBEDI002', value: '' },
    { id: 'GBEDI003', value: '' },
    { id: 'GBEKB001', value: '' },
    { id: 'GBEKB002', value: '' },
    { id: 'GBENK001', value: '' },
    { id: 'GBERI001', value: '' },
    { id: 'GBEXE001', value: '' },
    { id: 'GBGLO001', value: '' },
    { id: 'GBGLW001', value: '' },
    { id: 'GBGLW002', value: '' },
    { id: 'GBGSY001', value: '' },
    { id: 'GBGTC001', value: '' },
    { id: 'GBGVS001', value: '' },
    { id: 'GBHRW001', value: '' },
    { id: 'GBHUL001', value: '' },
    { id: 'GBHUL002', value: '' },
    { id: 'GBINV001', value: '' },
    { id: 'GBIPS001', value: '' },
    { id: 'GBIPS002', value: '' },
    { id: 'GBLBA001', value: '' },
    { id: 'GBLBA002', value: '' },
    { id: 'GBLBA003', value: '' },
    { id: 'GBLBA004', value: '' },
    { id: 'GBLCN001', value: '' },
    { id: 'GBLCS001', value: '' },
    { id: 'GBLDY001', value: '' },
    { id: 'GBLIS001', value: '' },
    { id: 'GBLIV001', value: '' },
    { id: 'GBLIV002', value: '' },
    { id: 'GBLIV003', value: '' },
    { id: 'GBLON001', value: '' },
    { id: 'GBLON002', value: '' },
    { id: 'GBLON003', value: '' },
    { id: 'GBLON004', value: '' },
    { id: 'GBLON005', value: '' },
    { id: 'GBLON006', value: '' },
    { id: 'GBLON007', value: '' },
    { id: 'GBLON008', value: '' },
    { id: 'GBLON009', value: '' },
    { id: 'GBLUT001', value: '' },
    { id: 'GBMDT001', value: '' },
    { id: 'GBMID001', value: '' },
    { id: 'GBMID002', value: '' },
    { id: 'GBMNC001', value: '' },
    { id: 'GBMNC002', value: '' },
    { id: 'GBNCL001', value: '' },
    { id: 'GBNCL002', value: '' },
    { id: 'GBNHP001', value: '' },
    { id: 'GBNTE001', value: '' },
    { id: 'GBNTG001', value: '' },
    { id: 'GBNTG002', value: '' },
    { id: 'GBNTG003', value: '' },
    { id: 'GBNTG004', value: '' },
    { id: 'GBNTG005', value: '' },
    { id: 'GBNTG006', value: '' },
    { id: 'GBOXF001', value: '' },
    { id: 'GBPET001', value: '' },
    { id: 'GBPET002', value: '' },
    { id: 'GBPME001', value: '' },
    { id: 'GBPME002', value: '' },
    { id: 'GBPRE001', value: '' },
    { id: 'GBPRE002', value: '' },
    { id: 'GBPRE003', value: '' },
    { id: 'GBPRE004', value: '' },
    { id: 'GBPRE005', value: '' },
    { id: 'GBPRE006', value: '' },
    { id: 'GBPTD001', value: '' },
    { id: 'GBPTE001', value: '' },
    { id: 'GBRDN001', value: '' },
    { id: 'GBREH001', value: '' },
    { id: 'GBRIH001', value: '' },
    { id: 'GBRRU001', value: '' },
    { id: 'GBSAA001', value: '' },
    { id: 'GBSFY001', value: '' },
    { id: 'GBSFY002', value: '' },
    { id: 'GBSHE001', value: '' },
    { id: 'GBSHI001', value: '' },
    { id: 'GBSHY001', value: '' },
    { id: 'GBSNN001', value: '' },
    { id: 'GBSOL001', value: '' },
    { id: 'GBSOL002', value: '' },
    { id: 'GBSOL003', value: '' },
    { id: 'GBSOT001', value: '' },
    { id: 'GBSOU001', value: '' },
    { id: 'GBSTS001', value: '' },
    { id: 'GBSTS002', value: '' },
    { id: 'GBSTT001', value: '' },
    { id: 'GBSUN001', value: '' },
    { id: 'GBSUR001', value: '' },
    { id: 'GBSWA001', value: '' },
    { id: 'GBTEF001', value: '' },
    { id: 'GBTEF002', value: '' },
    { id: 'GBTEF003', value: '' },
    { id: 'GBTON001', value: '' },
    { id: 'GBUXB001', value: '' },
    { id: 'GBWAF001', value: '' },
    { id: 'GBWBY001', value: '' },
    { id: 'GBWKG001', value: '' },
    { id: 'GBWNN001', value: '' },
    { id: 'GBWOR001', value: '' },
    { id: 'GBWOT001', value: '' },
    { id: 'GBWOT002', value: '' },
    { id: 'GBWOV001', value: '' },
    { id: 'GBWRN001', value: '' },
    { id: 'GBWTR001', value: '' },
    { id: 'GBWXH001', value: '' },
    { id: 'GBYRK001', value: '' },
    { id: 'AT100000', value: '' },
    { id: 'AT230000', value: '' },
    { id: 'AT320000', value: '' },
    { id: 'AT420000', value: '' },
    { id: 'AT600000', value: '' },
    { id: 'AT700000', value: '' },
    { id: 'AT800000', value: '' },
    { id: 'AT920000', value: '' },
    { id: 'BE000001', value: '' },
    { id: 'BE000011', value: '' },
    { id: 'BE000033', value: '' },
    { id: 'BE000034', value: '' },
    { id: 'BE100011', value: '' },
    { id: 'BE200011', value: '' },
    { id: 'BE300011', value: '' },
    { id: 'BE400011', value: '' },
    { id: 'BE500011', value: '' },
    { id: 'BE600011', value: '' },
    { id: 'BE700011', value: '' },
    { id: 'BG001000', value: '' },
    { id: 'BG002000', value: '' },
    { id: 'BG003000', value: '' },
    { id: 'BG004000', value: '' },
    { id: 'BG005800', value: '' },
    { id: 'BG009999', value: '' },
    { id: 'CY000100', value: '' },
    { id: 'CY000610', value: '' },
    { id: 'CZ510101', value: '' },
    { id: 'CZ520101', value: '' },
    { id: 'CZ530101', value: '' },
    { id: 'CZ540101', value: '' },
    { id: 'CZ550101', value: '' },
    { id: 'CZ560101', value: '' },
    { id: 'CZ570101', value: '' },
    { id: 'CZ580000', value: '' },
    { id: 'CZ580101', value: '' },
    { id: 'CZ590101', value: '' },
    { id: 'CZ600101', value: '' },
    { id: 'CZ610101', value: '' },
    { id: 'CZ620101', value: '' },
    { id: 'CZ630101', value: '' },
    { id: 'CZ640101', value: '' },
    { id: 'CZ650101', value: '' },
    { id: 'DE002150', value: '' },
    { id: 'DE002300', value: '' },
    { id: 'DE002600', value: '' },
    { id: 'DE002650', value: '' },
    { id: 'DE002900', value: '' },
    { id: 'DE003000', value: '' },
    { id: 'DE003200', value: '' },
    { id: 'DE003300', value: '' },
    { id: 'DE003450', value: '' },
    { id: 'DE003650', value: '' },
    { id: 'DE003700', value: '' },
    { id: 'DE004050', value: '' },
    { id: 'DE004100', value: '' },
    { id: 'DE004600', value: '' },
    { id: 'DE004900', value: '' },
    { id: 'DE005100', value: '' },
    { id: 'DE005207', value: '' },
    { id: 'DE005300', value: '' },
    { id: 'DE005350', value: '' },
    { id: 'DE005550', value: '' },
    { id: 'DE005850', value: '' },
    { id: 'DE006150', value: '' },
    { id: 'DE006200', value: '' },
    { id: 'DE006334', value: '' },
    { id: 'DE006335', value: '' },
    { id: 'DE006550', value: '' },
    { id: 'DE007050', value: '' },
    { id: 'DE007150', value: '' },
    { id: 'DE007350', value: '' },
    { id: 'DE007400', value: '' },
    { id: 'DE007500', value: '' },
    { id: 'DE007600', value: '' },
    { id: 'DE007750', value: '' },
    { id: 'DE008000', value: '' },
    { id: 'DE008100', value: '' },
    { id: 'DE008300', value: '' },
    { id: 'DE008750', value: '' },
    { id: 'DE008800', value: '' },
    { id: 'DE008850', value: '' },
    { id: 'DE009150', value: '' },
    { id: 'DE009300', value: '' },
    { id: 'DE009450', value: '' },
    { id: 'DE009550', value: '' },
    { id: 'DE009650', value: '' },
    { id: 'EE1000EE', value: '' },
    { id: 'ES0000DA', value: '' },
    { id: 'ES000725', value: '' },
    { id: 'ES000811', value: '' },
    { id: 'ES001161', value: '' },
    { id: 'ESD06600', value: '' },
    { id: 'ESD07600', value: '' },
    { id: 'ESD08600', value: '' },
    { id: 'ESD15600', value: '' },
    { id: 'ESD26600', value: '' },
    { id: 'ESD28600', value: '' },
    { id: 'ESD30600', value: '' },
    { id: 'ESD31600', value: '' },
    { id: 'ESD33600', value: '' },
    { id: 'ESD35600', value: '' },
    { id: 'ESD39600', value: '' },
    { id: 'ESD41600', value: '' },
    { id: 'ESD45600', value: '' },
    { id: 'ESD46600', value: '' },
    { id: 'ESD47600', value: '' },
    { id: 'ESD48600', value: '' },
    { id: 'ESD50600', value: '' },
    { id: 'ESD57960', value: '' },
    { id: 'FI001210', value: '' },
    { id: 'FI651704', value: '' },
    { id: 'FI904006', value: '' },
    { id: 'FR750681', value: '' },
    { id: 'FR930002', value: '' },
    { id: 'FR930003', value: '' },
    { id: 'FR930004', value: '' },
    { id: 'FR930005', value: '' },
    { id: 'FR930006', value: '' },
    { id: 'FR930007', value: '' },
    { id: 'FR930009', value: '' },
    { id: 'FR930010', value: '' },
    { id: 'FR930011', value: '' },
    { id: 'FR930012', value: '' },
    { id: 'FR930013', value: '' },
    { id: 'FR930014', value: '' },
    { id: 'FR930015', value: '' },
    { id: 'FR930016', value: '' },
    { id: 'FR930017', value: '' },
    { id: 'FR930018', value: '' },
    { id: 'GR002015', value: '' },
    { id: 'GR004019', value: '' },
    { id: 'GR009901', value: '' },
    { id: 'GR009917', value: '' },
    { id: 'HR011002', value: '' },
    { id: 'HU190000', value: '' },
    { id: 'IE003151', value: '' },
    { id: 'IENEN003', value: '' },
    { id: 'IENEN004', value: '' },
    { id: 'IT014000', value: '' },
    { id: 'IT015000', value: '' },
    { id: 'IT016000', value: '' },
    { id: 'IT017000', value: '' },
    { id: 'IT018000', value: '' },
    { id: 'IT019000', value: '' },
    { id: 'IT022999', value: '' },
    { id: 'IT024000', value: '' },
    { id: 'IT025000', value: '' },
    { id: 'IT026000', value: '' },
    { id: 'IT027000', value: '' },
    { id: 'IT028000', value: '' },
    { id: 'IT029000', value: '' },
    { id: 'IT034000', value: '' },
    { id: 'IT035000', value: '' },
    { id: 'IT043000', value: '' },
    { id: 'IT044000', value: '' },
    { id: 'IT051999', value: '' },
    { id: 'IT054000', value: '' },
    { id: 'IT055000', value: '' },
    { id: 'IT056000', value: '' },
    { id: 'IT057000', value: '' },
    { id: 'IT066000', value: '' },
    { id: 'IT067000', value: '' },
    { id: 'IT068000', value: '' },
    { id: 'IT079000', value: '' },
    { id: 'IT084000', value: '' },
    { id: 'IT085000', value: '' },
    { id: 'IT086000', value: '' },
    { id: 'IT087000', value: '' },
    { id: 'IT088000', value: '' },
    { id: 'IT089000', value: '' },
    { id: 'IT096000', value: '' },
    { id: 'IT097000', value: '' },
    { id: 'IT098000', value: '' },
    { id: 'IT099000', value: '' },
    { id: 'IT105999', value: '' },
    { id: 'IT107000', value: '' },
    { id: 'IT116000', value: '' },
    { id: 'IT117000', value: '' },
    { id: 'IT118000', value: '' },
    { id: 'IT119000', value: '' },
    { id: 'IT126000', value: '' },
    { id: 'IT127000', value: '' },
    { id: 'IT128000', value: '' },
    { id: 'IT129000', value: '' },
    { id: 'IT134000', value: '' },
    { id: 'IT135000', value: '' },
    { id: 'IT136000', value: '' },
    { id: 'IT137000', value: '' },
    { id: 'IT138000', value: '' },
    { id: 'IT221000', value: '' },
    { id: 'IT222000', value: '' },
    { id: 'IT261000', value: '' },
    { id: 'IT262000', value: '' },
    { id: 'IT263000', value: '' },
    { id: 'IT271000', value: '' },
    { id: 'IT272000', value: '' },
    { id: 'IT273000', value: '' },
    { id: 'IT274000', value: '' },
    { id: 'IT275000', value: '' },
    { id: 'IT276000', value: '' },
    { id: 'IT277000', value: '' },
    { id: 'IT278000', value: '' },
    { id: 'IT279000', value: '' },
    { id: 'IT281000', value: '' },
    { id: 'IT282000', value: '' },
    { id: 'IT291000', value: '' },
    { id: 'IT292000', value: '' },
    { id: 'IT301000', value: '' },
    { id: 'IT302000', value: '' },
    { id: 'IT303000', value: '' },
    { id: 'IT304000', value: '' },
    { id: 'IT305000', value: '' },
    { id: 'IT306000', value: '' },
    { id: 'IT307000', value: '' },
    { id: 'IT308000', value: '' },
    { id: 'IT309000', value: '' },
    { id: 'IT311000', value: '' },
    { id: 'IT312000', value: '' },
    { id: 'IT313000', value: '' },
    { id: 'IT314000', value: '' },
    { id: 'IT321000', value: '' },
    { id: 'IT371000', value: '' },
    { id: 'IT922103', value: '' },
    { id: 'IT922104', value: '' },
    { id: 'IT922105', value: '' },
    { id: 'IT922106', value: '' },
    { id: 'IT922107', value: '' },
    { id: 'LTKM0000', value: '' },
    { id: 'LTLC0100', value: '' },
    { id: 'LTLM0000', value: '' },
    { id: 'LTMM0000', value: '' },
    { id: 'LTVM0000', value: '' },
    { id: 'LU700000', value: '' },
    { id: 'LV009999', value: '' },
    { id: 'MT000118', value: '' },
    { id: 'MT000702', value: '' },
    { id: 'MT000900', value: '' },
    { id: 'MT000910', value: '' },
    { id: 'MT000930', value: '' },
    { id: 'NL000120', value: '' },
    { id: 'NL000563', value: '' },
    { id: 'NL000566', value: '' },
    { id: 'NL000567', value: '' },
    { id: 'NL000568', value: '' },
    { id: 'NL000702', value: '' },
    { id: 'NL000703', value: '' },
    { id: 'NL000707', value: '' },
    { id: 'PL300000', value: '' },
    { id: 'PL301000', value: '' },
    { id: 'PL302000', value: '' },
    { id: 'PL303000', value: '' },
    { id: 'PL310000', value: '' },
    { id: 'PL318000', value: '' },
    { id: 'PL320000', value: '' },
    { id: 'PL321000', value: '' },
    { id: 'PL322000', value: '' },
    { id: 'PL323000', value: '' },
    { id: 'PL328000', value: '' },
    { id: 'PL330000', value: '' },
    { id: 'PL331000', value: '' },
    { id: 'PL332000', value: '' },
    { id: 'PL333000', value: '' },
    { id: 'PL335000', value: '' },
    { id: 'PL338000', value: '' },
    { id: 'PL340000', value: '' },
    { id: 'PL341000', value: '' },
    { id: 'PL350000', value: '' },
    { id: 'PL351000', value: '' },
    { id: 'PL353000', value: '' },
    { id: 'PL360000', value: '' },
    { id: 'PL361000', value: '' },
    { id: 'PL362000', value: '' },
    { id: 'PL363000', value: '' },
    { id: 'PL370000', value: '' },
    { id: 'PL378000', value: '' },
    { id: 'PL380000', value: '' },
    { id: 'PL381000', value: '' },
    { id: 'PL390000', value: '' },
    { id: 'PL392000', value: '' },
    { id: 'PL393000', value: '' },
    { id: 'PL394000', value: '' },
    { id: 'PL398000', value: '' },
    { id: 'PL400000', value: '' },
    { id: 'PL408000', value: '' },
    { id: 'PL410000', value: '' },
    { id: 'PL420000', value: '' },
    { id: 'PL428000', value: '' },
    { id: 'PL430000', value: '' },
    { id: 'PL438000', value: '' },
    { id: 'PL440000', value: '' },
    { id: 'PL448000', value: '' },
    { id: 'PL450000', value: '' },
    { id: 'PL451000', value: '' },
    { id: 'PL452000', value: '' },
    { id: 'PL454000', value: '' },
    { id: 'PT000900', value: '' },
    { id: 'PT000909', value: '' },
    { id: 'ROANV001', value: '' },
    { id: 'ROANV002', value: '' },
    { id: 'ROANV003', value: '' },
    { id: 'ROANV005', value: '' },
    { id: 'ROBU1040', value: '' },
    { id: 'ROBU1200', value: '' },
    { id: 'ROBU1380', value: '' },
    { id: 'ROBU1400', value: '' },
    { id: 'ROBU7100', value: '' },
    { id: 'ROBU8600', value: '' },
    { id: 'ROBV0300', value: '' },
    { id: 'ROBV0900', value: '' },
    { id: 'ROBV5600', value: '' },
    { id: 'ROBV7820', value: '' },
    { id: 'ROBV7900', value: '' },
    { id: 'ROBV8800', value: '' },
    { id: 'ROCJ0400', value: '' },
    { id: 'ROCJ0500', value: '' },
    { id: 'ROCJ1800', value: '' },
    { id: 'ROCJ6570', value: '' },
    { id: 'ROCJ7810', value: '' },
    { id: 'ROCJ9700', value: '' },
    { id: 'ROCR0310', value: '' },
    { id: 'ROCR2000', value: '' },
    { id: 'ROCR2100', value: '' },
    { id: 'ROCR7000', value: '' },
    { id: 'ROCR7700', value: '' },
    { id: 'ROCR8210', value: '' },
    { id: 'ROCR8810', value: '' },
    { id: 'ROCT1710', value: '' },
    { id: 'ROCT8220', value: '' },
    { id: 'RODRVBU0', value: '' },
    { id: 'RODRVBV0', value: '' },
    { id: 'RODRVCJ0', value: '' },
    { id: 'RODRVCR0', value: '' },
    { id: 'RODRVGL0', value: '' },
    { id: 'RODRVIS0', value: '' },
    { id: 'RODRVPL0', value: '' },
    { id: 'RODRVTM0', value: '' },
    { id: 'ROGL0710', value: '' },
    { id: 'ROGL1500', value: '' },
    { id: 'ROGL3600', value: '' },
    { id: 'ROIS0600', value: '' },
    { id: 'ROIS1600', value: '' },
    { id: 'ROIS7400', value: '' },
    { id: 'ROIS9610', value: '' },
    { id: 'ROTM0200', value: '' },
    { id: 'ROTM2300', value: '' },
    { id: 'ROTM7600', value: '' },
    { id: 'ROTM8100', value: '' },
    { id: 'SE009251', value: '' },
    { id: 'SI000010', value: '' },
    { id: 'SI001000', value: '' },
    { id: 'SI002006', value: '' },
    { id: 'SI003001', value: '' },
    { id: 'SI004009', value: '' },
    { id: 'SI005001', value: '' },
    { id: 'SI006001', value: '' },
    { id: 'SI007008', value: '' },
    { id: 'SI008004', value: '' },
    { id: 'SK100000', value: '' },
    { id: 'SK510000', value: '' },
    { id: 'SK520000', value: '' },
    { id: 'SK530000', value: '' },
    { id: 'SK560000', value: '' },
    { id: 'SK580000', value: '' },
    { id: 'SK600000', value: '' },
    { id: 'SK610000', value: '' },
    { id: 'SK620000', value: '' },
    { id: 'SK660000', value: '' },
    { id: 'CY000130', value: '' },
    { id: 'CY000410', value: '' },
    { id: 'CY000510', value: '' },
    { id: 'DK004700', value: '' },
    { id: 'ES000311', value: '' },
    { id: 'ES000321', value: '' },
    { id: 'ES000411', value: '' },
    { id: 'ES000415', value: '' },
    { id: 'ES000711', value: '' },
    { id: 'ES000717', value: '' },
    { id: 'ES000721', value: '' },
    { id: 'ES000731', value: '' },
    { id: 'ES000812', value: '' },
    { id: 'ES001111', value: '' },
    { id: 'ES001131', value: '' },
    { id: 'ES001133', value: '' },
    { id: 'ES001211', value: '' },
    { id: 'ES001511', value: '' },
    { id: 'ES001521', value: '' },
    { id: 'ES001711', value: '' },
    { id: 'ES001811', value: '' },
    { id: 'ES002011', value: '' },
    { id: 'ES002111', value: '' },
    { id: 'ES002711', value: '' },
    { id: 'ES002911', value: '' },
    { id: 'ES003011', value: '' },
    { id: 'ES003311', value: '' },
    { id: 'ES003331', value: '' },
    { id: 'ES003541', value: '' },
    { id: 'ES003561', value: '' },
    { id: 'ES003591', value: '' },
    { id: 'ES003611', value: '' },
    { id: 'ES003621', value: '' },
    { id: 'ES003631', value: '' },
    { id: 'ES003861', value: '' },
    { id: 'ES003891', value: '' },
    { id: 'ES003911', value: '' },
    { id: 'ES004111', value: '' },
    { id: 'ES004311', value: '' },
    { id: 'ES004321', value: '' },
    { id: 'ES004611', value: '' },
    { id: 'ES004621', value: '' },
    { id: 'ES004631', value: '' },
    { id: 'ES004811', value: '' },
    { id: 'FR000040', value: '' },
    { id: 'FR000380', value: '' },
    { id: 'FR000390', value: '' },
    { id: 'FR000610', value: '' },
    { id: 'FR000630', value: '' },
    { id: 'FR000690', value: '' },
    { id: 'FR000720', value: '' },
    { id: 'FR000800', value: '' },
    { id: 'FR001200', value: '' },
    { id: 'FR001260', value: '' },
    { id: 'FR001560', value: '' },
    { id: 'FR002090', value: '' },
    { id: 'FR002270', value: '' },
    { id: 'FR002300', value: '' },
    { id: 'FR002310', value: '' },
    { id: 'FR002600', value: '' },
    { id: 'FR002730', value: '' },
    { id: 'FR002860', value: '' },
    { id: 'FR003030', value: '' },
    { id: 'FR003080', value: '' },
    { id: 'FR003500', value: '' },
    { id: 'FR003620', value: '' },
    { id: 'FR003630', value: '' },
    { id: 'FR003650', value: '' },
    { id: 'FR003680', value: '' },
    { id: 'FR003690', value: '' },
    { id: 'FR003920', value: '' },
    { id: 'FR004060', value: '' },
    { id: 'FR004080', value: '' },
    { id: 'FR004250', value: '' },
    { id: 'FR004430', value: '' },
    { id: 'FR006240', value: '' },
    { id: 'FR006340', value: '' },
    { id: 'FR006420', value: '' },
    { id: 'FR006480', value: '' },
    { id: 'FR006610', value: '' },
    { id: 'FR006800', value: '' },
    { id: 'GR000701', value: '' },
    { id: 'GR000731', value: '' },
    { id: 'GR001102', value: '' },
    { id: 'GR001202', value: '' },
    { id: 'GR001302', value: '' },
    { id: 'GR001802', value: '' },
    { id: 'GR001902', value: '' },
    { id: 'GR002001', value: '' },
    { id: 'GR002102', value: '' },
    { id: 'GR002302', value: '' },
    { id: 'GR002602', value: '' },
    { id: 'GR003102', value: '' },
    { id: 'GR003502', value: '' },
    { id: 'GR003702', value: '' },
    { id: 'GR004005', value: '' },
    { id: 'HU101000', value: '' },
    { id: 'HU102000', value: '' },
    { id: 'HU121000', value: '' },
    { id: 'HU160000', value: '' },
    { id: 'HU170000', value: '' },
    { id: 'HU211000', value: '' },
    { id: 'HU221000', value: '' },
    { id: 'HU231000', value: '' },
    { id: 'HU311500', value: '' },
    { id: 'HU321000', value: '' },
    { id: 'HU411000', value: '' },
    { id: 'HU421000', value: '' },
    { id: 'HU511000', value: '' },
    { id: 'HU521000', value: '' },
    { id: 'HU611000', value: '' },
    { id: 'HU621000', value: '' },
    { id: 'HU631000', value: '' },
    { id: 'HU711000', value: '' },
    { id: 'HU721000', value: '' },
    { id: 'HU811000', value: '' },
    { id: 'HU821000', value: '' },
    { id: 'HU831000', value: '' },
    { id: 'HU911000', value: '' },
    { id: 'HU921000', value: '' },
    { id: 'PL321010', value: '' },
    { id: 'PL321030', value: '' },
    { id: 'PL321070', value: '' },
    { id: 'PL322020', value: '' },
    { id: 'PL322030', value: '' },
    { id: 'PL322080', value: '' },
    { id: 'PL418000', value: '' },
    { id: 'PL421030', value: '' },
    { id: 'PL421080', value: '' },
    { id: 'PL422020', value: '' },
    { id: 'PT000015', value: '' },
    { id: 'PT000020', value: '' },
    { id: 'PT000040', value: '' },
    { id: 'PT000070', value: '' },
    { id: 'PT000080', value: '' },
    { id: 'PT000088', value: '' },
    { id: 'PT000115', value: '' },
    { id: 'PT000164', value: '' },
    { id: 'PT000265', value: '' },
    { id: 'PT000284', value: '' },
    { id: 'PT000305', value: '' },
    { id: 'PT000340', value: '' },
    { id: 'PT000455', value: '' },
    { id: 'PT000665', value: '' },
    { id: 'PT000750', value: '' },
    { id: 'ROBU1030', value: '' },
    { id: 'ROBU3910', value: '' },
    { id: 'ROBU3980', value: '' },
    { id: 'ROBV7910', value: '' },
    { id: 'ROCJ1810', value: '' },
    { id: 'ROCJ4310', value: '' },
    { id: 'ROCJ8000', value: '' },
    { id: 'ROCR1700', value: '' },
    { id: 'ROCR1720', value: '' },
    { id: 'ROCR2110', value: '' },
    { id: 'ROCR5800', value: '' },
    { id: 'ROCR7270', value: '' },
    { id: 'ROCR7280', value: '' },
    { id: 'ROCR9000', value: '' },
    { id: 'ROCR9100', value: '' },
    { id: 'ROCT1900', value: '' },
    { id: 'ROCT1970', value: '' },
    { id: 'ROCT5100', value: '' },
    { id: 'ROCT5400', value: '' },
    { id: 'ROGL0700', value: '' },
    { id: 'ROGL3800', value: '' },
    { id: 'ROGL3810', value: '' },
    { id: 'ROGL3850', value: '' },
    { id: 'ROGL8900', value: '' },
    { id: 'ROIS0100', value: '' },
    { id: 'ROIS0620', value: '' },
    { id: 'ROIS1610', value: '' },
    { id: 'ROIS1620', value: '' },
    { id: 'ROIS2700', value: '' },
    { id: 'ROIS4650', value: '' },
    { id: 'ROIS4660', value: '' },
    { id: 'ROIS4990', value: '' },
    { id: 'ROIS8200', value: '' },
    { id: 'ROIS8230', value: '' },
    { id: 'ROTM0230', value: '' },
    { id: 'ROTM5010', value: '' },
    { id: 'ROTM5510', value: '' },
    { id: 'ROTM6100', value: '' },
    { id: 'ROTM8720', value: '' },
    { id: 'ROTM8730', value: '' },
    { id: 'SI006044', value: '' },
];
