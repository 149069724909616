import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';

const GoodsInformationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getIrelandExportTooltipsByRefNumber } = useTooltips();

    const handleToggleHelp = (refNumber: string | number): void => {
        props.toggleHelp && props.toggleHelp(refNumber);
    };

    return (
        <section
            id="goods-information-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('goods-information-form-card');
                }
            }}
        >
            <FormCard
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('goodsItems.title')}
                expandAll={props.expandAll}
                keyCard={props.keyCard}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                state={getCardState(
                    [
                        { name: 'packaging.description', required: true },
                        { name: 'countryOrigin', required: false },
                        { name: 'regionOrigin', required: false },
                        { name: 'grossMass', required: true },
                        { name: 'netMass', required: false },
                        { name: 'procedureRequestedCode', required: false },
                        { name: 'procedurePreviousCode', required: false },
                        { name: 'procedureCategory', required: false },
                        { name: 'supplementaryUnits', required: false },
                        { name: 'statisticalValue', required: false },
                        { name: 'vatRate', required: false },
                        { name: 'dutyRate', required: false },
                    ],
                    props
                )}
            >
                <DeclarationInput
                    maxLength={80}
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`packaging.description`, props)}
                    refNumber="31.7"
                    label={t('goodsItems.goodsDescription')}
                    tooltip={getIrelandExportTooltipsByRefNumber('31.7')}
                    refClicked={handleToggleHelp}
                />

                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`countryOrigin`, props)}
                    refNumber="34a"
                    tooltip={getIrelandExportTooltipsByRefNumber('34a')}
                    refClicked={handleToggleHelp}
                    label={t('countryOrigin')}
                />
                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`regionOrigin`, props)}
                    refNumber="34b"
                    tooltip={getIrelandExportTooltipsByRefNumber('34b')}
                    refClicked={handleToggleHelp}
                    label={t('regionOrigin')}
                />

                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`grossMass`, props)}
                    refNumber="35"
                    tooltip={getIrelandExportTooltipsByRefNumber('35')}
                    refClicked={handleToggleHelp}
                    label={t('goodsItems.grossMass')}
                />
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`netMass`, props)}
                    refNumber="38"
                    tooltip={getIrelandExportTooltipsByRefNumber('38')}
                    refClicked={handleToggleHelp}
                    label={t('goodsItems.netMass')}
                />
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`procedureRequestedCode`, props)}
                    refNumber="37a.1"
                    tooltip={getIrelandExportTooltipsByRefNumber('37a.1')}
                    refClicked={handleToggleHelp}
                    label={t('requestedProcedure')}
                    selectOptions={normalizeCodesToSelect(codelists?.irelandProcedureCodes || [])}
                />

                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`procedurePreviousCode`, props)}
                    refNumber="37a.2"
                    tooltip={getIrelandExportTooltipsByRefNumber('37a.2')}
                    refClicked={handleToggleHelp}
                    label={t('previousProcedure')}
                    selectOptions={normalizeCodesToSelect(codelists?.irelandProcedureCodes || [])}
                />

                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`procedureCategory`, props)}
                    refNumber="37b"
                    tooltip={getIrelandExportTooltipsByRefNumber('37b')}
                    refClicked={handleToggleHelp}
                    label={t('procedureCategory')}
                    selectOptions={normalizeCodesToSelect(codelists?.irelandExportProcedureCategories || [])}
                />

                <DeclarationNumberInput
                    integerNumbers
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`supplementaryUnits`, props)}
                    refNumber="41"
                    tooltip={getIrelandExportTooltipsByRefNumber('41')}
                    refClicked={handleToggleHelp}
                    label={t('goodsItems.supplementaryUnits')}
                />
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`statisticalValue`, props)}
                    refNumber="46"
                    tooltip={getIrelandExportTooltipsByRefNumber('46')}
                    refClicked={handleToggleHelp}
                    label={t('statisticalValue')}
                />
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`vatRate`, props)}
                    label={t('goodsItems.vatRate')}
                />

                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`dutyRate`, props)}
                    label={t('goodsItems.dutyRate')}
                />
            </FormCard>
        </section>
    );
};

export default GoodsInformationCard;
