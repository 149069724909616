import useSession from 'hooks/useSession';
import { FC } from 'react';
import { IndividualType } from 'store/individuals/individual';
import BrokerInvoiceUpload from './BrokerInvoiceUpload';
import TraderInvoiceUpload from './TraderInvoiceUpload';

const InvoiceUpload: FC<{}> = () => {
    const { userInfo } = useSession();

    if (
        userInfo?.role === IndividualType.BROKER_ADMIN ||
        userInfo?.role === IndividualType.BROKER_CLERK ||
        userInfo?.role === IndividualType.SYSTEM_ADMIN
    ) {
        return <BrokerInvoiceUpload />;
    }

    if (userInfo?.role === IndividualType.TRADER_ADMIN || IndividualType.TRADER_USER) {
        return <TraderInvoiceUpload />;
    }

    return <></>;
};

export default InvoiceUpload;
