import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useFormik } from 'formik';
import useCodelists from 'hooks/useCodelists';
import useModalMultipleCards from 'hooks/useModalMultipleCards';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { SimplifiedDeclarationDocumentWritingOffHeader } from 'store/declarations/common/simplified-declaration-document-writing-off-header';
import { Package } from 'store/declarations/ireland/temporary-storage-declaration';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import CardList from 'views/declarations/common/list-card/CardList';
import ModalEditCardListItem from 'views/declarations/common/modal-edit-list-item/ModalEditCardListItem';
import { getCardState, getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import * as Yup from 'yup';

const SimplifiedDeclarationDocumentWritingOffCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { viewOnly } = props;
    const fields = props.getFields ? props.getFields() : [];
    const { codelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    const formik = useFormik<Package>({
        initialValues: {},
        validationSchema: Yup.object().shape({}),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            addItem(values);
        },
    });

    const { setValues, getFieldProps, getFieldHelpers, getFieldMeta, resetForm } = formik;

    const formikProps = useMemo(() => {
        return {
            fieldProps: props.getFieldProps(`${props.propsPathPrefix}.simplifiedDeclarationDocumentWritingOff`),
            fieldMeta:
                props.getFieldMeta &&
                props.getFieldMeta(`${props.propsPathPrefix}.simplifiedDeclarationDocumentWritingOff`),
            fieldHelper: props.getFieldHelpers(`${props.propsPathPrefix}.simplifiedDeclarationDocumentWritingOff`),
        };
    }, [props]);

    const { modalVisible, handleEdit, handleDelete, handleCancel, onClickAddItem, addItem } = useModalMultipleCards({
        formikProps,
        setValues,
        resetForm,
    });

    const simplifiedDeclarationDocumentWritingOffList = useMemo(() => {
        const list = props.getFieldProps(`${props.propsPathPrefix}.simplifiedDeclarationDocumentWritingOff`).value;
        if (Array.isArray(list)) {
            return list.map((obj: SimplifiedDeclarationDocumentWritingOffHeader, index) => {
                return [
                    { field: 'Previous document type', value: obj.previousDocumentType },
                    { field: 'Previous document identifier', value: obj.previousDocumentIdentifier },
                    { field: 'Goods Item Identifier', value: obj.previousDocumentLineId },
                ];
            });
        }
        return [];
    }, [props]);

    return (
        <section
            id={'tsd-simplified-documents-writing-off-form-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('tsd-simplified-documents-writing-off-form-card');
                }
            }}
        >
            <FormCard
                key={props.key}
                keyCard={props.keyCard}
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('Simplified Document Writing Off')}
                expandAll={props.expandAll}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                state={getCardState(fields, props)}
            >
                <CardList
                    data={simplifiedDeclarationDocumentWritingOffList}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    viewOnly={viewOnly ?? false}
                />
                {!viewOnly && <Button onClick={onClickAddItem}>Add item</Button>}
            </FormCard>
            <ModalEditCardListItem visible={modalVisible} onCancel={handleCancel} width={1020} formik={formik}>
                <Row gutter={12}>
                    <Col xs={24}>
                        <FormSelect
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`previousDocumentType`, {
                                getFieldHelpers: getFieldHelpers,
                                getFieldMeta: getFieldMeta,
                                getFieldProps: getFieldProps,
                            })}
                            refNumber="2.1"
                            label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentType')}
                            selectOptions={normalizeCodesToSelect(codelists?.irelandPreviousDocumentTypes || [])}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '2.1',
                                t('simplifiedDeclarationDocumentWritingOff.previousDocumentType')
                            )}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                        />
                    </Col>
                    <Col xs={24} xxl={24}>
                        <FormInput
                            maxLength={35}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`previousDocumentIdentifier`, {
                                getFieldHelpers: getFieldHelpers,
                                getFieldMeta: getFieldMeta,
                                getFieldProps: getFieldProps,
                            })}
                            refNumber="2.1"
                            label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier')}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '2.1',
                                t('simplifiedDeclarationDocumentWritingOff.previousDocumentIdentifier')
                            )}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                        />
                    </Col>
                    <Col xs={24} xxl={24}>
                        <FormInput
                            numbersAndLetters
                            maxLength={5}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`previousDocumentLineId`, {
                                getFieldHelpers: getFieldHelpers,
                                getFieldMeta: getFieldMeta,
                                getFieldProps: getFieldProps,
                            })}
                            refNumber="2.1"
                            label={t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId')}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '2.1',
                                t('simplifiedDeclarationDocumentWritingOff.previousDocumentLineId')
                            )}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                        />
                    </Col>
                </Row>
            </ModalEditCardListItem>
        </section>
    );
};

export default SimplifiedDeclarationDocumentWritingOffCard;
