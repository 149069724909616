import { FormikErrors, FormikProps, setNestedObjectValues } from 'formik';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import { useEffect } from 'react';
import { DeclarationFormErrors } from 'store/declaration-form-errors/DeclarationFormErrors';

interface Props<TDeclaration> {
    validationErrorsParser: ((errors: FormikErrors<TDeclaration>) => DeclarationFormErrors) | undefined;
    formik: FormikProps<any>;
    productsFormik: FormikProps<any>;
    triedToSubmit: boolean;
}

const useHandleErrors = <TDeclaration,>({
    validationErrorsParser,
    formik,
    productsFormik,
    triedToSubmit,
}: Props<TDeclaration>) => {
    const { hasFormDeclarationErrors, setFormDeclarationErrors } = useDeclarationFormErrors();

    useEffect(() => {
        if (validationErrorsParser === undefined) return;

        if (!triedToSubmit || (!triedToSubmit && !hasFormDeclarationErrors)) return;

        setFormDeclarationErrors(validationErrorsParser(formik.errors));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.errors, productsFormik.errors]);

    useEffect(() => {
        if (hasFormDeclarationErrors) {
            formik.validateForm().then((validations) => {
                formik.setTouched(setNestedObjectValues(validations, true));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.initialValues]);

    useEffect(() => {
        if (hasFormDeclarationErrors) {
            productsFormik.validateForm().then((validations) => {
                productsFormik.setTouched(setNestedObjectValues(validations, true));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsFormik.initialValues]);
};

export default useHandleErrors;
