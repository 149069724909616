import { Col, Row } from 'antd';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';

interface Props extends BlockProps<'quotaOrderId'> {}

const QuotaOrderNumberBlock = ({
    fieldOptions,
    path = 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee',
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '8/1', []);
    return (
        <Row gutter={18}>
            <Col xs={fieldOptions?.quotaOrderId?.columnSpan ?? 24}>
                <DeclarationField
                    name="quotaOrderId"
                    pathPrefix={path}
                    validation={{
                        name: t('quotaOrderNumber'),
                        max: 6,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormInput
                            refNumber={ucc}
                            label={t('quotaOrderNumber')}
                            {...getFormikProps(field.name, form)}
                        />
                    )}
                </DeclarationField>
            </Col>
        </Row>
    );
};

export default QuotaOrderNumberBlock;
