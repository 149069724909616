import Notification from 'components/ui/base/notification/Notification';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import { FormikProvider, setNestedObjectValues, useFormik } from 'formik';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarations from 'hooks/useDeclarations';
import useProducts from 'hooks/useProducts';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { Declaration, DeclarationHistory } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import {
    IrelandExportDeclaration,
    IrelandExportDeclarationPayload,
    RevenueExportCustomsGoodsItem,
} from 'store/declarations/ireland/export-declaration';
import {
    GoodsShipmentItem as H7GoodsShipmentItem,
    IrelandH7ImportDeclaration,
    IrelandH7ImportDeclarationPayload,
} from 'store/declarations/ireland/h7-import-declaration';
import {
    GoodsShipmentItem as H1GoodsShipmentItem,
    IrelandImportDeclaration,
    IrelandImportDeclarationPayload,
} from 'store/declarations/ireland/import-declaration';
import { GovernmentAgencyGoodsItem } from 'store/declarations/uk/goods-item';
import { StyledHeader, StyledLayout } from 'views/declarations/Form.styles';
import { sectionsENSMainDocument, sectionsENSProducts } from 'views/declarations/ireland/ens/constants';
import { exportSectionsMasterDetails, exportSectionsProducts } from 'views/declarations/ireland/export/constants';
import {
    sectionsH1MainDocument,
    sectionsH1Products,
    sectionsH7MainDocument,
    sectionsH7Products,
} from 'views/declarations/ireland/import/constants';
import {
    exportSectionsMasterDetails as ukExportMasterDetails,
    exportSectionsProducts as ukExportProducts,
} from 'views/declarations/uk/export/constants';
import { sectionsUkH1MainDocument, sectionsUkH1Products } from 'views/declarations/uk/import/h1/constants';
import { isDeclarationAmendable } from 'views/declarations/utils/declaration-utils';
import { removeEmptyObjectsFromDeclarationArrays, removeEmtpyObjects } from 'views/declarations/utils/form-utils';
import { FormSection } from 'views/declarations/DeclarationView';
import AmendmentFooter from './AmendmentFooter';
import AmendmentReasonModal from './AmendmentReasonModal';
import MasterProductDeclarationNav from '../../common/MasterProductDeclarationNav';
import { Box44Context } from '../../common/box44/Box44';
import DeclarationTabKeys from '../../utils/DeclarationTabKeys';
import DeclarationFormTabContent from 'views/declarations/common/declaration-form/DeclarationFormTabContent';
import { FooterRow, SubmitDiv } from 'views/declarations/footer/Footer.styles';
import { Button, notification } from 'antd';
import axiosClient from 'config/axios';
import { SuccessResponse } from 'core/http/response';
import config from 'config/config';
import styled from 'styled-components';
import CdsAmendmentModal, { AmendmentRecord } from './components/cds/CdsAmendmentModal';
import useRequest from 'hooks/useRequest';
import { prepareAmendment, submitAmendmentRequest, updateUkExportDeclaration } from 'store/declarations/client';
import CdsExportDeclarationUtils from 'views/declarations/uk/export/utils';
import { isEmpty } from 'lodash';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import IrelandImportH7DeclarationUtils from 'views/declarations/ireland/import/h7/utils';
import IrelandImportH1DeclarationUtils from 'views/declarations/ireland/import/h1/utils';
import { CdsExportGovernmentAgencyGoodsItemPayload } from 'store/products/reducer';

const FooterButton = styled(Button)`
    padding-inline: 3rem;
    height: 4.5rem;
`;

type SubmitDeclaration = IrelandImportDeclaration | IrelandExportDeclaration | IrelandH7ImportDeclaration;

const ViewOnlyTab: FC<{}> = () => {
    const {
        declaration,
        productsFormik,
        formik,
        amendment = false,
        createAmendment,
        cancelAmendment,
        box44PathAndFieldNames,
        activeTabKey,
    } = useViewOnly() || {};
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const { declarationId, productId } = useParams();
    const location = useLocation();
    const [selectedBt, setSelectedBt] = useState(
        location.pathname === `/declarations/${declarationId}/view-only`
            ? FormSection.MASTER_DETAIlS
            : FormSection.PRODUCTS
    );
    const [myProducts, setMyProducts] = useState<
        GovernmentAgencyGoodsItem[] | RevenueExportCustomsGoodsItem[] | H7GoodsShipmentItem[] | H1GoodsShipmentItem[]
    >([]);
    const navigate = useNavigate();
    const { setDeclarationFooterType } = useDeclarationFooter();
    const {
        updateIrelandImportProduct,
        updateIrelandH7ImportProduct,
        updateIrelandExportProduct,
        updateUkExportProduct,
        products,
        listIrelandH1Products,
        listIrelandH7Products,
        listIrelandExportProducts,
        listIrelandEnsProducts,
        listUkExportProducts,
        listUkImportProducts,
    } = useProducts({
        productId,
    });

    const [isAmendmentReasonModalOpen, setIsAmendmentReasonModalOpen] = useState<boolean>(false);
    const [isCdsAmendmentModalOpen, setIsCdsAmendmentModalOpen] = useState<boolean>(false);

    const {
        updateIrelandImportDeclaration,
        createAmendmentIrelandImportDeclaration,
        updateIrelandH7ImportDeclaration,
        updateIrelandExportDeclaration,
        createAmendmentIrelandExportDeclaration,
        createAmendmentIrelandEntrySummaryDeclaration,
        getDeclaration,
    } = useDeclarations({
        declarationId: declarationId,
        customerId: declaration?.customerId,
    });
    const [paginatorProducts, setPaginatorProducts] = useState<Partial<PaginatedParams>>();

    useEffect(() => {
        setSelectedBt(
            location.pathname === `/declarations/${declarationId}/view-only`
                ? FormSection.MASTER_DETAIlS
                : FormSection.PRODUCTS
        );
    }, [location, declarationId]);

    useEffect(() => {
        if (!declarationId) return;

        const p = { ...paginatorProducts };
        if (declaration?.cdsImportDeclaration) {
            listUkImportProducts(declarationId);
        } else if (declaration?.cdsExportDeclaration) {
            listUkExportProducts(declarationId);
        } else if (declaration?.irelandExportDeclaration) {
            listIrelandExportProducts(declarationId, p);
        } else if (declaration?.irelandH7ImportDeclaration) {
            listIrelandH7Products(declarationId, p);
        } else if (declaration?.irelandImportDeclaration) {
            listIrelandH1Products(declarationId, p);
        } else if (declaration?.entrySummaryDeclaration) {
            listIrelandEnsProducts(declarationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginatorProducts]);

    useEffect(() => {
        if (products?.list) {
            setMyProducts(products?.list);
        } else {
            setMyProducts([]);
        }
    }, [products]);

    const declarationHasItems = useMemo(() => products?.total, [products]);

    useEffect(() => {
        if (selectedBt === 0) {
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBt]);

    const handlePaginationProductsOfDeclaration = (params: Partial<PaginatedParams>) => {
        if (paginatorProducts && paginatorProducts.page && paginatorProducts.size) {
            setPaginatorProducts({ page: params.page, size: paginatorProducts.size });
        } else {
            setPaginatorProducts({ page: params.page, size: defaultPagination.size });
        }
    };

    const verticalNavigation = useMemo(() => {
        if (declaration?.cdsImportDeclaration) {
            return selectedBt === 0 ? sectionsUkH1MainDocument : sectionsUkH1Products;
        } else if (declaration?.cdsExportDeclaration) {
            return selectedBt === 0 ? ukExportMasterDetails : ukExportProducts;
        } else if (declaration?.irelandExportDeclaration) {
            return selectedBt === 0 ? exportSectionsMasterDetails : exportSectionsProducts;
        } else if (declaration?.irelandH7ImportDeclaration) {
            return selectedBt === 0 ? sectionsH7MainDocument : sectionsH7Products;
        } else if (declaration?.irelandImportDeclaration) {
            return selectedBt === 0 ? sectionsH1MainDocument : sectionsH1Products;
        } else if (declaration?.entrySummaryDeclaration) {
            return selectedBt === 0 ? sectionsENSMainDocument : sectionsENSProducts;
        }
        return [];
    }, [selectedBt, declaration]);

    const productFormVisible = useMemo(() => productId, [productId]);

    const isProductsFormValid = useCallback(async () => {
        const errors = await productsFormik.validateForm().then((v) => {
            productsFormik.setTouched(setNestedObjectValues(v, true));
            return v;
        });
        return isEmpty(errors);
    }, [productsFormik]);

    const handleProductsFormSubmit = async (
        item:
            | GovernmentAgencyGoodsItem
            | RevenueExportCustomsGoodsItem
            | H7GoodsShipmentItem
            | H1GoodsShipmentItem
            | CdsExportGovernmentAgencyGoodsItemPayload
    ) => {
        let form = removeEmptyObjectsFromDeclarationArrays({ ...item });
        if (productId && declarationId) {
            if (declaration?.irelandImportDeclaration) {
                form = IrelandImportH1DeclarationUtils.transformProductForServer(form as any);
                await updateIrelandImportProduct(removeEmtpyObjects(form), declarationId, productId);
            }

            if (declaration?.irelandH7ImportDeclaration) {
                form = IrelandImportH7DeclarationUtils.transformProductForServer(form);
                await updateIrelandH7ImportProduct(removeEmtpyObjects(form), declarationId, productId);
            }

            if (declaration?.irelandExportDeclaration) {
                await updateIrelandExportProduct(removeEmtpyObjects(form), declarationId, productId);
            }

            if (declaration?.cdsExportDeclaration) {
                form = CdsExportDeclarationUtils.transformProductForServer(form as any);
                form = {
                    id: productId,
                    governmentAgencyGoodsItem: form as any,
                };
                await updateUkExportProduct(removeEmtpyObjects(form), declarationId, productId);
            }
        }
    };

    const productsNumber = useMemo(() => myProducts?.length, [myProducts]);

    const viewOnly = useMemo(() => {
        if (!productFormVisible && productsNumber && selectedBt !== 0) {
            return true;
        }
        return !amendment;
    }, [amendment, productFormVisible, productsNumber, selectedBt]);

    const handleSubmitForm = async (values: SubmitDeclaration) => {
        const form = removeEmptyObjectsFromDeclarationArrays(values);

        if (declaration?.customerId && declarationId) {
            if (declaration?.irelandImportDeclaration) {
                const d = {
                    ...(declaration as IrelandImportDeclarationPayload),
                    irelandImportDeclaration: IrelandImportH1DeclarationUtils.transformForServer(
                        removeEmtpyObjects(form)
                    ),
                };
                const response = await updateIrelandImportDeclaration(declaration?.customerId, declarationId, d);
                return response;
            }

            if (declaration?.irelandH7ImportDeclaration) {
                const d = {
                    ...(declaration as IrelandH7ImportDeclarationPayload),
                    irelandH7ImportDeclaration: IrelandImportH7DeclarationUtils.transformForServer(
                        removeEmtpyObjects(form)
                    ),
                };
                const response = await updateIrelandH7ImportDeclaration(declaration?.customerId, declarationId, d);
                return response;
            }

            if (declaration?.irelandExportDeclaration) {
                const d = {
                    ...(declaration as IrelandExportDeclarationPayload),
                    irelandExportDeclaration: removeEmtpyObjects(form),
                };
                const response = await updateIrelandExportDeclaration(declaration?.customerId, declarationId, d);
                return response;
            }

            if (declaration?.cdsExportDeclaration) {
                const d: Declaration = {
                    ...declaration,
                    cdsExportDeclaration: {
                        ...declaration.cdsExportDeclaration,
                        cdsExportDeclarationPayload: {
                            ...CdsExportDeclarationUtils.transformForServer(form as any),
                        },
                    },
                };
                const response = await updateUkExportDeclaration(declaration?.customerId, declarationId, d as any);
                return response;
            }
        }
    };

    const box44Path = useMemo(
        () =>
            !location.pathname.includes('products')
                ? box44PathAndFieldNames?.masterPath
                : box44PathAndFieldNames?.productPath,
        [location.pathname, box44PathAndFieldNames]
    );
    const formikForProvider = useMemo(
        () => (!location.pathname.includes('products') ? formik : productsFormik),
        [formik, location.pathname, productsFormik]
    );

    const { data: amendments, doRequest: doPrepareAmendment } = useRequest(prepareAmendment);

    const footer = useMemo(() => {
        if (amendment) return null;

        const canSendArrivalNotification =
            declaration?.cdsExportDeclaration && ['REGISTERED', 'RISKED'].includes(declaration?.status);
        const sendArrivalNotification = () => {
            if (!canSendArrivalNotification) throw new Error('Not allowed to send arrival notification');

            axiosClient
                .post<SuccessResponse<any>>(
                    `${config.declarationsUrl}/uk/export/declarations/${declarationId}/arrivalNotification`
                )
                .then(() => {
                    notification.success({ message: 'Arrival Notification sent' });
                    getDeclaration(declarationId!);
                })
                .catch(() => {
                    notification.error({ message: 'Error sending Arrival Notification' });
                });
        };

        return (
            <SubmitDiv>
                <FooterRow justify="end" align="middle" padding="1.2rem 2.4rem 1.2rem 2.4rem">
                    <div style={{ display: 'grid', gridAutoFlow: 'column', gap: '1rem' }}>
                        {canSendArrivalNotification && (
                            <FooterButton onClick={sendArrivalNotification}>Send Arrival Notification</FooterButton>
                        )}
                        {declaration && isDeclarationAmendable(declaration) && (
                            <FooterButton onClick={() => createAmendment?.()}>Amend Declaration</FooterButton>
                        )}
                    </div>
                </FooterRow>
            </SubmitDiv>
        );
    }, [amendment, createAmendment, declaration, declarationId, getDeclaration]);

    useEffect(() => {
        if (selectedBt === 0) {
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
        } else {
            setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
        }
    }, [selectedBt, setDeclarationFooterType]);

    return (
        <>
            <StyledHeader>
                {activeTabKey === DeclarationTabKeys.VIEW_ONLY && (
                    <FormikProvider value={formikForProvider}>
                        <Box44Context.Provider
                            value={{
                                documentTypeName: box44PathAndFieldNames?.documentTypeName,
                                documentIdentifierName: box44PathAndFieldNames?.documentIdentifierName,
                                path: box44Path,
                            }}
                        >
                            <MasterProductDeclarationNav viewOnly selectedBt={selectedBt} />
                        </Box44Context.Provider>
                    </FormikProvider>
                )}
            </StyledHeader>
            <StyledLayout>
                <DeclarationFormTabContent navigationItems={verticalNavigation}>
                    <Outlet
                        context={{
                            hasAlert: true,
                            formik,
                            internalType: declaration?.declarationInternalType,
                            viewOnly: viewOnly,
                            amendment: amendment,
                            productsFormik,
                            cancelProducts: () => {},
                            clearCancel: async () => {
                                // await getDeclaration(declarationId);
                                //setCancelClicked(false);
                            },
                            saveProduct: () => {},
                            clearSaveProduct: () => {},
                            updateDeclaration: () => {
                                // getDeclaration(declarationId)
                            },
                            data: myProducts,
                            onEdit: (id: string) => {
                                navigate(`/declarations/${declarationId}/view-only/products/${id}`);
                                setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
                            },
                            itemsNumber: declarationHasItems ?? 0,
                            pageNumber: products?.pageNumber ?? 0,
                            handlePagination: handlePaginationProductsOfDeclaration,
                        }}
                    />
                </DeclarationFormTabContent>
            </StyledLayout>
            {footer}
            {amendment && declaration && isDeclarationAmendable(declaration) && (
                <AmendmentFooter
                    cancelSaveProduct={() => {
                        navigate(`/declarations/${declarationId}/view-only/products`);
                        setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
                    }}
                    saveProduct={async () => {
                        const valid = await isProductsFormValid();
                        if (valid) {
                            await handleProductsFormSubmit(productsFormik.values);
                        }
                    }}
                    declaration={declaration}
                    cancelAmendment={() => {
                        if (cancelAmendment) {
                            cancelAmendment();
                        }
                    }}
                    saveDraft={async () => {
                        const response = await handleSubmitForm(formik.values);
                        if (response?.id === declarationId) {
                            Notification({
                                type: 'success',
                                messageTitle: 'Amendment saved',
                                description: 'A new amendment was saved as draft.',
                            });
                        }
                    }}
                    submitAmendment={async () => {
                        showGlobalOverlay({
                            type: 'LoadingOverlay',
                            payload: { message: 'Saving declaration...' },
                        });
                        const response = await handleSubmitForm(formik.values);

                        if (!response?.id) {
                            console.error('No response on form submission');
                            hideGlobalOverlay();
                            return;
                        }

                        if (declaration?.cdsExportDeclaration) {
                            showGlobalOverlay({
                                type: 'LoadingOverlay',
                                payload: { message: 'Preparing amendment...' },
                            });
                            const items = await doPrepareAmendment(declarationId!);
                            hideGlobalOverlay();
                            if (isEmpty(items)) {
                                notification.error({ message: 'No items to be amended' });
                                return;
                            }
                            setIsCdsAmendmentModalOpen(true);
                            return;
                        }

                        hideGlobalOverlay();

                        if (declaration?.irelandImportDeclaration || declaration?.entrySummaryDeclaration) {
                            setIsAmendmentReasonModalOpen(true);

                            return;
                        }

                        if (declaration?.irelandExportDeclaration) {
                            const createAmendmentResponse = await createAmendmentIrelandExportDeclaration();

                            if (createAmendmentResponse?.id && createAmendmentResponse?.type === 'SUBMIT') {
                                Notification({
                                    type: 'success',
                                    messageTitle: 'New Amendment',
                                    description: 'A new amendment was successfully created.',
                                });
                            }

                            return;
                        }
                    }}
                />
            )}
            <AmendmentReasonModal
                open={isAmendmentReasonModalOpen}
                onCancel={() => {
                    setIsAmendmentReasonModalOpen(false);
                }}
                onSubmit={async ({ detailsAmended }) => {
                    const createAmendment =
                        declaration?.irelandImportDeclaration || declaration?.irelandH7ImportDeclaration
                            ? createAmendmentIrelandImportDeclaration
                            : declaration?.entrySummaryDeclaration
                            ? createAmendmentIrelandEntrySummaryDeclaration
                            : undefined;

                    const response: DeclarationHistory | undefined = await createAmendment?.(detailsAmended);

                    if (response?.id) {
                        setIsAmendmentReasonModalOpen(false);

                        Notification({
                            type: 'success',
                            messageTitle: 'New Amendment',
                            description: 'A new amendment was successfully created.',
                        });
                    }
                }}
            />
            <CdsAmendmentModal
                amendments={
                    amendments?.items ?? [
                        {
                            path: 'goodsShipment.governmentAgencyGoodsItem.1.additionalDocument.1.writeOff.quantityQuantity',
                            value: 'some value 1',
                            action: 'ADD',
                        },
                        {
                            path: 'exporter.name',
                            value: 'some value 2',
                            action: 'EDIT',
                        },
                        {
                            path: 'goodsShipment.warehouse.id',
                            value: 'some value 3',
                            action: 'DELETE',
                        },
                    ]
                }
                visible={isCdsAmendmentModalOpen}
                onCancel={() => setIsCdsAmendmentModalOpen(false)}
                submitAmendmentRequest={async (value: AmendmentRecord[]) => {
                    if (!declarationId || !declaration?.mrn || !declaration?.cdsExportDeclaration?.lrn) {
                        notification.error({ message: 'Missing information for submitting amendment request' });
                        return Promise.reject();
                    }

                    showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Submitting amendment...' } });
                    return submitAmendmentRequest(declarationId!, {
                        items: value,
                        declarationId: declarationId,
                        lrn: declaration.cdsExportDeclaration?.lrn,
                        mrn: declaration.mrn,
                    })
                        .then(() => {
                            cancelAmendment?.();
                            notification.success({ message: 'Amendment submitted' });
                        })
                        .catch(() => {
                            notification.error({ message: 'Could not submit amendment' });
                        })
                        .finally(() => {
                            hideGlobalOverlay();
                        });
                }}
            />
        </>
    );
};
export default ViewOnlyTab;

function useViewOnly() {
    return useOutletContext<{
        formik: ReturnType<typeof useFormik>;
        productsFormik: ReturnType<typeof useFormik>;
        declaration: Declaration | undefined;
        country: DeclarationCountry;
        amendment?: boolean;
        createAmendment?: Function;
        cancelAmendment?: Function;
        box44PathAndFieldNames?: any;
        activeTabKey: string;
    }>();
}
