import { useFormik } from 'formik';
import useDeclarationHeader from 'hooks/useDeclarationHeader';
import useDeclarations from 'hooks/useDeclarations';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import { FC, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Declaration, NotifyEmails } from 'store/declarations/declaration';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { DeclarationErrors } from 'views/declarations/common/declaration-errors';
import SubmitModal from 'views/declarations/common/submit-modal/SubmitModal';
import DeclarationTabKeys from 'views/declarations/utils/DeclarationTabKeys';
import { ContentWithoutFooter } from 'views/declarations/Form.styles';
import { FormAction } from 'views/declarations/utils/form-utils';

interface Props {
    activeTabKey: DeclarationTabKeys;
    declaration: Declaration;
    formik: ReturnType<typeof useFormik>;
    hasCertificate: boolean;
    declarationErrors: DeclarationErrors;
    showSubmitModal: boolean;
    setShowSubmitModal: Function;
    setCancelClicked: Function;
    cancelClicked: boolean;
    handleSubmitButton: () => void;
}
export const IeArrivalAtExitContent: FC<Props> = ({
    activeTabKey,
    declaration,
    formik,
    hasCertificate,
    declarationErrors,
    setShowSubmitModal,
    showSubmitModal,
    cancelClicked,
    setCancelClicked,
    handleSubmitButton,
}) => {
    const { declarationHeaderCollapsed } = useDeclarationHeader();
    const { getDeclaration } = useDeclarations();
    const declarationId = useMemo(() => declaration?.id ?? '', [declaration]);
    const declarationCustomerId = useMemo(() => declaration?.customerId ?? '', [declaration]);
    const { setFormAction } = useDeclarationValidation();
    const formTabContent = useMemo(() => {
        const handleSubmitAndNotify = (emails: NotifyEmails) => {
            setFormAction(FormAction.SUBMIT);
            setShowSubmitModal(false);
        };

        if (declaration) {
            return declaration.status !== DeclarationStatus.RELEASED ? (
                <>
                    <Outlet
                        context={{
                            declarationErrors,
                            hasAlert: !hasCertificate,
                            formik,
                            cancelProducts: cancelClicked,
                            clearCancel: async () => {
                                await getDeclaration(declarationId);
                                setCancelClicked(false);
                            },
                            saveProduct: false,
                            clearSaveProduct: () => {},
                            updateDeclaration: () => getDeclaration(declarationId),
                        }}
                    />
                    <SubmitModal
                        visible={showSubmitModal}
                        handleSubmit={handleSubmitButton}
                        handleCancel={() => {
                            setShowSubmitModal(false);
                        }}
                        handleSubmitAndNotify={handleSubmitAndNotify}
                        customerId={declaration.customerId!}
                    />
                </>
            ) : (
                <></>
            );
        }

        return <></>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        handleSubmitButton,
        formik,
        declaration,
        cancelClicked,
        declarationId,
        getDeclaration,
        showSubmitModal,
        declarationErrors,
        hasCertificate,
        setCancelClicked,
        setShowSubmitModal,
    ]);

    const handleContent = useMemo(() => {
        switch (activeTabKey) {
            case DeclarationTabKeys.FORM:
                return formTabContent;
            case DeclarationTabKeys.DOCUMENTATION:
                return (
                    <ContentWithoutFooter collapsedHeader={declarationHeaderCollapsed}>
                        <Outlet context={{ customerId: declarationCustomerId, formId: declarationId }} />;
                    </ContentWithoutFooter>
                );
            case DeclarationTabKeys.CUSTOMER_DETAILS:
                return (
                    <ContentWithoutFooter collapsedHeader={declarationHeaderCollapsed}>
                        <Outlet context={{ customerId: declarationCustomerId }} />;
                    </ContentWithoutFooter>
                );
            case DeclarationTabKeys.NOTIFICATIONS:
                return (
                    <ContentWithoutFooter collapsedHeader={declarationHeaderCollapsed} $hasAlert={!hasCertificate}>
                        <Outlet context={{ declaration: declaration! }} />
                    </ContentWithoutFooter>
                );
            default:
                return <></>;
        }
    }, [
        activeTabKey,
        formTabContent,
        declarationHeaderCollapsed,
        declarationCustomerId,
        declarationId,
        hasCertificate,
        declaration,
    ]);

    return <>{handleContent}</>;
};

export default IeArrivalAtExitContent;
