import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined';
import { Row } from 'antd';
import { Text } from 'components/ui/base/typography';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from 'store/customers/customer';
import { JobResponse } from 'store/jobs/job';
import { CloseIcon, CustomDiv, CustomRow, SectionTitle } from './InvoiceUpload.styles';
import UploadFile from './UploadFile';

interface Props {
    file?: { filename: string; id: string };
    handleDeleteFile: Function;
    customer?: Customer;
    job?: JobResponse;
    disableFormType: boolean;
    successUploadInvoice: (file: { filename: string; id: string }) => void;
    declarationId?: string;
    handleCreateDeclaration: Function;
}
const SelectInvoiceFile: FC<Props> = ({
    file,
    handleDeleteFile,
    job,
    customer,
    disableFormType,
    successUploadInvoice,
    declarationId,
    handleCreateDeclaration,
}) => {
    const { t } = useTranslation('customs_declarations');
    const disableUpload = useMemo(() => !job || !customer || disableFormType, [job, customer, disableFormType]);

    const selectedFile = useMemo(() => {
        return file ? (
            <>
                <Row>
                    <SectionTitle>{t('uploadYourDocs')}</SectionTitle>
                </Row>
                <Row align="middle">
                    <CloseIcon onClick={() => handleDeleteFile(file?.id)} />
                    <Text>
                        <FilePdfOutlined /> {file?.filename}
                    </Text>
                </Row>
            </>
        ) : (
            <></>
        );
    }, [file, handleDeleteFile, t]);

    return (
        <CustomRow>
            <CustomDiv>
                {file ? (
                    <>{selectedFile}</>
                ) : (
                    <UploadFile
                        disabled={disableUpload}
                        successUpdate={successUploadInvoice}
                        customerId={customer?.id}
                        declarationId={declarationId}
                        createDeclaration={handleCreateDeclaration}
                    />
                )}
            </CustomDiv>
        </CustomRow>
    );
};

export default SelectInvoiceFile;
