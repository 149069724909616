export const calculationTaxesTaxType = [
    { id: '407', value: '' },
    { id: '411', value: '' },
    { id: '412', value: '' },
    { id: '413', value: '' },
    { id: '415', value: '' },
    { id: '419', value: '' },
    { id: '421', value: '' },
    { id: '422', value: '' },
    { id: '423', value: '' },
    { id: '425', value: '' },
    { id: '429', value: '' },
    { id: '431', value: '' },
    { id: '433', value: '' },
    { id: '435', value: '' },
    { id: '438', value: '' },
    { id: '440', value: '' },
    { id: '441', value: '' },
    { id: '442', value: '' },
    { id: '443', value: '' },
    { id: '444', value: '' },
    { id: '445', value: '' },
    { id: '446', value: '' },
    { id: '447', value: '' },
    { id: '451', value: '' },
    { id: '461', value: '' },
    { id: '462', value: '' },
    { id: '463', value: '' },
    { id: '473', value: '' },
    { id: '481', value: '' },
    { id: '483', value: '' },
    { id: '485', value: '' },
    { id: '511', value: '' },
    { id: '520', value: '' },
    { id: '521', value: '' },
    { id: '522', value: '' },
    { id: '540', value: '' },
    { id: '541', value: '' },
    { id: '542', value: '' },
    { id: '551', value: '' },
    { id: '556', value: '' },
    { id: '561', value: '' },
    { id: '570', value: '' },
    { id: '571', value: '' },
    { id: '572', value: '' },
    { id: '589', value: '' },
    { id: '591', value: '' },
    { id: '592', value: '' },
    { id: '595', value: '' },
    { id: '611', value: '' },
    { id: '615', value: '' },
    { id: '619', value: '' },
    { id: '623', value: '' },
    { id: '627', value: '' },
    { id: '99A', value: '' },
    { id: '99B', value: '' },
    { id: '99C', value: '' },
    { id: '99D', value: '' },
    { id: 'A00', value: '' },
    { id: 'A20', value: '' },
    { id: 'A30', value: '' },
    { id: 'A35', value: '' },
    { id: 'A40', value: '' },
    { id: 'A45', value: '' },
    { id: 'B00', value: '' },
    { id: 'B05', value: '' },
    { id: 'C00', value: '' },
    { id: 'C10', value: '' },
    { id: 'E00', value: '' },
    { id: '487', value: '' },
    { id: 'A50', value: '' },
    { id: 'A70', value: '' },
    { id: 'A80', value: '' },
    { id: 'A85', value: '' },
    { id: 'A90', value: '' },
    { id: 'A95', value: '' },
];
