import { Col, Dropdown, Menu, Row } from 'antd';
import Button from 'components/ui/base/button/Button';
import Drawer from 'components/ui/base/drawer/Drawer';
import Notification from 'components/ui/base/notification/Notification';
import { H5 } from 'components/ui/base/typography';
import BulkUpload, { BulkUploadUploadFunc, FileType } from 'components/ui/composed/bulkUpload/BulkUpload';
import { TemplateResponse } from 'components/ui/composed/template/TemplateContext';
import useCustomers from 'hooks/useCustomers';
import useDeclarations from 'hooks/useDeclarations';
import useJobs from 'hooks/useJobs';
import debounce from 'lodash.debounce';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Customer } from 'store/customers/customer';
import {
    downloadIrelandH1ImportDeclarationSubmissionDetails,
    listDeclarations as listDeclarationsReq,
} from 'store/declarations/client';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { IrelandExportMessageType } from 'store/declarations/enums/ireland/message-type';
import { IrelandDeclarationName } from 'store/declarations/ireland/ireland-declaration-name';
import { JobResponse } from 'store/jobs/job';
import CreateNewCustomer from 'views/customers/components/CreateNewCustomer';
import { getDeclarationName } from 'views/declarations/utils/declaration-utils';
import { UkImportDeclarationName } from '../../store/declarations/uk/uk-declaration-name';
import {
    CloseIcon,
    ColButtonFillForm,
    Container,
    CustomRow,
    DownIcon,
    FileIcon,
    InvoiceButton,
    LeftRow,
    ReverseCol,
    SectionTitle,
    TitleRow,
} from './components/InvoiceUpload.styles';
import SelectCustomer from './components/SelectCustomer';
import SelectFormType from './components/SelectFormType';
import SelectJob from './components/SelectJob';
import { createDeclarations, createOrDuplicateDeclarations, getDeclarationNameLabels, getMessageType } from './utils';
import { getTemplates } from 'store/template/client';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import { AvailableTemplateFormTypes } from 'store/template/action';

const availableTemplateTypes = ['H1', 'H7', 'B1', 'ens'];

const BrokerInvoiceUpload: FC = () => {
    const { t } = useTranslation('customs_declarations');
    const { hideGlobalOverlay, showGlobalOverlay } = useGlobalOverlay();
    const { country, type, jobId } = useParams<{
        country: DeclarationCountry;
        type: DeclarationInternalType;
        jobId: string;
    }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { customers, listCustomers } = useCustomers();
    const [myCustomer, setMyCustomer] = useState<Customer | undefined>();
    const [showCreateCustomer, setShowCreateCustomer] = useState(false);
    const [myDeclaration, setMyDeclaration] = useState<Declaration | undefined>();
    const {
        createIrelandImportDeclaration,
        createIrelandH1ImportDeclarationWithFile,
        createIrelandH7ImportDeclaration,
        createIrelandExportDeclaration,
        createUkImportDeclaration,
        createUkExportDeclaration,
        createEntrySummaryDeclaration,
        createElectronicTransportDocument,
        createTemporaryStorageDeclaration,
        createArrivalAtExitDeclaration,
        createUkImportNewDeclaration,
        duplicateDeclaration,
    } = useDeclarations();
    const { job, createJob, listJobs, jobs, editJob } = useJobs({ jobId });
    const [myJob, setMyJob] = useState<JobResponse | undefined>(job);
    const [formType, setFormType] = useState<string>();
    const [declarationFromJobs, setDeclarationFromJobs] = useState<{
        [key: string]: Declaration[];
    }>({});
    const [fillForm, setFillForm] = useState(false);
    const [duplicateDeclarationId, setDuplicateDeclarationId] = useState<string>();

    const [h1SubmissionDetailsFile, setH1SubmissionDetailsFile] = useState<File>();

    const isEnsDeclaration = useMemo(
        () => (country === DeclarationCountry.IRELAND && type === DeclarationInternalType.ENS ? true : false),
        [country, type]
    );

    const isTsdDeclaration = useMemo(
        () => country === DeclarationCountry.IRELAND && type === DeclarationInternalType.TSD,
        [country, type]
    );

    const isEtdDeclaration = useMemo(
        () => country === DeclarationCountry.IRELAND && type === DeclarationInternalType.ETD,
        [country, type]
    );

    const isArrivalDeclaration = useMemo(
        () => country === DeclarationCountry.IRELAND && type === DeclarationInternalType.ARRIVAL,
        [country, type]
    );

    const handleFormType = useMemo(
        () => (isEtdDeclaration || isTsdDeclaration || isArrivalDeclaration ? false : !formType),
        [formType, isEtdDeclaration, isTsdDeclaration, isArrivalDeclaration]
    );

    const disabledFillForm = useMemo(
        () => !myJob || !myCustomer || handleFormType,
        [myJob, myCustomer, handleFormType]
    );

    const disableFormType = useMemo(
        () => !!duplicateDeclarationId || !!myDeclaration,
        [duplicateDeclarationId, myDeclaration]
    );

    const hasCreatedDeclaration = useMemo(() => !!myDeclaration, [myDeclaration]);

    const isH1FormTypeSelected = useMemo(() => formType === '(H1) Free Circulation', [formType]);

    const callbackGetDeclarationNameLabels = useCallback(() => {
        return getDeclarationNameLabels(country!, type!); // FIXME remove assertions
    }, [country, type]);

    const hasTemplatesImplementation = (
        country: DeclarationCountry,
        type: DeclarationInternalType,
        formType: AvailableTemplateFormTypes
    ) => {
        if (
            country === DeclarationCountry.IRELAND &&
            type === DeclarationInternalType.IMPORT &&
            ['H1', 'H7'].includes(formType)
        )
            return true;
        if (country === DeclarationCountry.UK && type === DeclarationInternalType.EXPORT && ['B1'].includes(formType))
            return true;

        if (country === DeclarationCountry.IRELAND && type === DeclarationInternalType.ENS) {
            return true;
        }
    };

    const [templates, setTemplates] = useState<TemplateResponse[] | undefined>(undefined);
    const [template, setTemplate] = useState<TemplateResponse | undefined>(undefined);
    useEffect(() => {
        if (!country || !type || !formType) {
            setTemplates(undefined);
            return;
        }
        const _formType = country === DeclarationCountry.IRELAND ? formType?.match(/\((.*)\)/)?.[1]! : formType;

        if (!hasTemplatesImplementation(country, type, _formType as AvailableTemplateFormTypes)) {
            setTemplates(undefined);
            return;
        }

        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Loading template data...' } });
        getTemplates(country!, type!.toLowerCase() as 'import' | 'export', _formType! as any, {
            size: 9999,
        })
            .then((payload) => setTemplates(payload.list))
            .finally(() => {
                hideGlobalOverlay();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formType]);

    useEffect(() => {
        if (type === DeclarationInternalType.ENS) setFormType('ens');
    }, [type, setFormType]);

    const handleTemplateSelect = (templateId: string | undefined) => {
        if (templateId) {
            setTemplate(templates?.find(({ id }) => id === templateId));
        }
    };

    const disableTemplate = useMemo(() => {
        return (
            !availableTemplateTypes.some((at) => formType?.includes(at)) || !myCustomer || !myJob || !templates?.length
        );
    }, [formType, myCustomer, myJob, templates?.length]);

    useEffect(() => {
        if (!customers.list.length) {
            listCustomers({ size: 200 });
        }
        if (!jobs.list.length) {
            listJobs({ size: 200 });
        }

        if (location && location.state && !!jobs.list.length && !!customers.list.length) {
            const previousInformation = location.state as { declaration?: Declaration; job?: JobResponse };
            if (previousInformation?.declaration && previousInformation?.declaration?.customerId) {
                setDuplicateDeclarationId(previousInformation.declaration.id);
                handleSelectCustomer(previousInformation.declaration.customerId);
                const name = getDeclarationName(previousInformation.declaration);
                callbackGetDeclarationNameLabels().forEach((a: any) => {
                    if (a.key === name) {
                        setFormType(a.value);
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, location.state, jobs, customers]);

    //TODO: Add this to hook
    useEffect(() => {
        const getDeclarations = async () => {
            await Promise.all(jobs.list.map(async (j) => (await listDeclarationsReq({ jobId: j.id })).list)).then(
                (j) => {
                    const declarations = j.map((res) => {
                        if (res && res.length > 0) {
                            const jobId: string = res[0].jobId!;
                            return { [jobId]: [...res] };
                        } else {
                            return {};
                        }
                    });
                    setDeclarationFromJobs(Object.assign({}, ...declarations));
                }
            );
        };
        if (jobs.list) {
            getDeclarations();
        }
    }, [jobs]);

    useEffect(() => {
        if (jobId && jobs) {
            const j = jobs.list.find((value) => value.id === jobId);
            if (j) {
                setMyJob(j);
            }
        }
    }, [jobId, jobs]);

    const handleSelectCustomer = useCallback(
        async (value: string) => {
            const element = customers.list.find((e) => e.id === value);
            const newJob = { ...myJob } as JobResponse;
            if (element?.id) {
                setMyCustomer(element);
                if (newJob?.id) {
                    newJob.customerId = element?.id;
                    editJob(newJob.id, newJob);
                    setMyJob(newJob);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [customers, myJob, editJob]
    );

    const handleSelectJob = useCallback(
        async (id: string) => {
            const j = jobs.list.find((e) => e.id === id);
            if (j) {
                setMyJob(j);
                navigate(`/invoice-upload/${country}/${type}/job/${j.id}`, { state: { j } });
                if (myCustomer?.id && j?.id) {
                    const newJob = { ...j } as JobResponse;
                    newJob.customerId = myCustomer.id;
                    editJob(newJob.id, newJob);
                    setMyJob(newJob);
                }
            }
        },
        [jobs, country, editJob, navigate, myCustomer?.id, type]
    );

    const createNewJob = async () => {
        const job = (await createJob()) as JobResponse;
        setMyJob(job);
        navigate(`/invoice-upload/${country}/${type}/job/${job.id}`, { state: { job } });
    };

    const createDeclaration = async (
        country: DeclarationCountry,
        customerId: string,
        jobId: string,
        messageType?: IrelandDeclarationName | IrelandExportMessageType | UkImportDeclarationName
    ) => {
        return createDeclarations(
            formType,
            country,
            customerId,
            jobId,
            type!, // FIXME remove assertion
            createIrelandH7ImportDeclaration,
            createIrelandImportDeclaration,
            createEntrySummaryDeclaration,
            createTemporaryStorageDeclaration,
            createIrelandExportDeclaration,
            createElectronicTransportDocument,
            createUkImportDeclaration,
            createUkExportDeclaration,
            createUkImportNewDeclaration,
            createArrivalAtExitDeclaration,
            messageType,
            template
        );
    };

    const labelTitle = useMemo(() => {
        return t(`create${country}Declaration${type}`);
    }, [country, type, t]);

    useEffect(() => {
        if (myDeclaration && fillForm) {
            setFillForm(false);
            navigate(`/declarations/${myDeclaration.id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fillForm, myDeclaration]);

    const handleFillFormClick = () => {
        if (!myDeclaration) {
            if (isH1FormTypeSelected && h1SubmissionDetailsFile) {
                handleCreateIrelandH1ImportDeclarationWithFile();
            } else {
                handleCreateDeclaration();
            }
        }
        setFillForm(true);
    };

    const handleCreateIrelandH1ImportDeclarationWithFile = async () => {
        const _jobId = jobId ?? myJob?.id;
        const _customerId = myCustomer?.id;
        if (!_jobId || !_customerId || !h1SubmissionDetailsFile) {
            Notification({
                type: 'error',
                messageTitle: 'Error creating declaration',
                description: 'Please select Job, Customer, and a Submission Details File',
            });

            return;
        }

        const response = await createIrelandH1ImportDeclarationWithFile(h1SubmissionDetailsFile, _jobId, _customerId);

        if (response) {
            Notification({
                type: 'success',
                messageTitle: 'Declaration created',
                description: 'Successfully created declaration',
            });

            setMyDeclaration(response);
        } else {
            Notification({
                type: 'error',
                messageTitle: 'Error creating declaration',
                description: 'An error occurred while creating a declaration',
            });
        }
    };

    const refreshCustomers = () => {
        listCustomers();
    };

    const addNewCustomer = (newCustomer: Customer) => {
        const job = { ...myJob } as JobResponse;
        if (job && newCustomer.id) {
            job.customerId = newCustomer?.id;
            editJob(job.id, job);
            setMyJob(job);
        }
        setMyCustomer(newCustomer);
        setShowCreateCustomer(false);
    };

    const updateDeclaration = async (declarationId: string, customerId: string, jobId: string) => {
        return await duplicateDeclaration(declarationId, customerId, jobId);
    };

    const handleCreateDeclaration = async () => {
        if (!myDeclaration) {
            let messageType;

            if (!isEnsDeclaration && !isTsdDeclaration && !isArrivalDeclaration && formType) {
                messageType = getMessageType(country!, type!, formType); // FIXME remove assertions
            }

            const jId = jobId ?? myJob?.id;
            const customerId = myCustomer?.id;
            return await createOrDuplicateDeclarations(
                updateDeclaration,
                createDeclaration,
                jId!, // FIXME remove assertion
                setMyDeclaration,
                country!, // FIXME remove assertion
                customerId,
                duplicateDeclarationId,
                messageType
            );
        }
    };

    const querySearchJob = async (query: string) => {
        if (query) {
            const params = { query };
            await listJobs(params);
        } else {
            await listJobs({ size: 200 });
        }
    };

    const debouncedSearchJob = debounce((query: string) => querySearchJob(query), 500);
    const querySearchCustomer = async (query: string) => {
        if (query) {
            const params = { query };
            await listCustomers(params);
        } else {
            await listCustomers({ size: 200 });
        }
    };

    const debouncedSearchCustomers = debounce((query: string) => querySearchCustomer(query), 500);

    const handleUpload: BulkUploadUploadFunc = ({ file }) => {
        setH1SubmissionDetailsFile(file as File);
        return true;
    };

    const handleDownload = () => {
        return downloadIrelandH1ImportDeclarationSubmissionDetails();
    };

    const handleDelete = () => {
        setH1SubmissionDetailsFile(undefined);
    };

    return (
        <>
            <Container>
                <TitleRow>
                    <Col span={18}>
                        <H5>{labelTitle}</H5>
                    </Col>
                    <ColButtonFillForm span={6}>
                        <Button size="large" disabled={disabledFillForm} type="primary" onClick={handleFillFormClick}>
                            {t('fillForm')}
                        </Button>
                    </ColButtonFillForm>
                </TitleRow>

                <LeftRow gutter={32}>
                    <Col span={12}>
                        <SelectJob
                            disabled={hasCreatedDeclaration}
                            job={myJob}
                            jobs={jobs?.list}
                            createNewJob={createNewJob}
                            handleSelectJob={handleSelectJob}
                            setJob={setMyJob}
                            setCustomer={setMyCustomer}
                            declarationFromJobs={declarationFromJobs}
                            search={(e) => debouncedSearchJob(e)}
                        />

                        <SelectCustomer
                            disabled={hasCreatedDeclaration}
                            customer={myCustomer}
                            customers={customers?.list}
                            job={myJob}
                            setShowCreateCustomer={setShowCreateCustomer}
                            handleSelectCustomer={handleSelectCustomer}
                            setCustomer={setMyCustomer}
                            search={(e) => debouncedSearchCustomers(e)}
                        />
                        {!isEnsDeclaration && !isTsdDeclaration && !isEtdDeclaration && !isArrivalDeclaration && (
                            <SelectFormType
                                customer={myCustomer}
                                job={myJob}
                                setFormType={setFormType}
                                declarationNameLabels={callbackGetDeclarationNameLabels()}
                                disableFormType={!myCustomer || !myJob || disableFormType}
                                formType={formType}
                            />
                        )}

                        {!isTsdDeclaration && !isEtdDeclaration && !isArrivalDeclaration && (
                            <CustomRow>
                                <Col span={16}>
                                    <SectionTitle className={`${disableTemplate && 'disabled'}`}>
                                        {t('selectTemplate')}
                                    </SectionTitle>
                                </Col>
                                <ReverseCol span={8}>
                                    <Dropdown
                                        disabled={disableTemplate}
                                        overlay={
                                            <Menu onClick={(item) => handleTemplateSelect(item.key)}>
                                                {templates?.map((template) => (
                                                    <Menu.Item key={template.id}>{template.templateName}</Menu.Item>
                                                ))}
                                            </Menu>
                                        }
                                    >
                                        <InvoiceButton size="large">
                                            <DownIcon /> {t('selectTemplate')}
                                        </InvoiceButton>
                                    </Dropdown>
                                </ReverseCol>
                                {template && (
                                    <Row align="middle">
                                        <CloseIcon
                                            className={`${disableTemplate && 'disabled'}`}
                                            onClick={() => {
                                                if (!disableTemplate) {
                                                    setTemplate(undefined);
                                                }
                                            }}
                                            disabled={disableTemplate}
                                        />
                                        <FileIcon /> {template.templateName}
                                    </Row>
                                )}
                            </CustomRow>
                        )}

                        <div>
                            <SectionTitle className={`${!isH1FormTypeSelected ? 'disabled' : ''}`}>
                                Bulk upload submission details (optional)
                            </SectionTitle>
                            <BulkUpload
                                showFile
                                onDownload={handleDownload}
                                onUpload={handleUpload}
                                onDelete={handleDelete}
                                fileType={[FileType.XLS, FileType.XLSX]}
                                disabled={!isH1FormTypeSelected}
                            />
                        </div>
                    </Col>
                </LeftRow>
            </Container>
            <Drawer
                title="Add New Customer"
                width="627"
                visible={showCreateCustomer}
                onClose={() => setShowCreateCustomer(!showCreateCustomer)}
            >
                <CreateNewCustomer
                    refreshCustomers={refreshCustomers}
                    closeModal={() => setShowCreateCustomer(false)}
                    handleOk={addNewCustomer}
                />
            </Drawer>
        </>
    );
};
export default BrokerInvoiceUpload;
