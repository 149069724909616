import { ReactElement, useMemo } from 'react';
import { ValidationSchema, validators } from '../validations/validations';
import AddressBlock from './customer/AddressBlock';
import EoriBlock from './customer/EoriBlock';

export type CustomerFields =
    | 'id'
    | 'name'
    | 'functionCode'
    | 'address'
    | 'address.line'
    | 'address.countryCode'
    | 'address.postcodeID'
    | 'address.cityName'
    | 'communication.id';

export const exporterValidation: {
    childValidators: {
        exporter: ValidationSchema<CustomerFields>;
    };
} = {
    childValidators: {
        exporter: {
            childValidators: {
                id: [validators.eori()],
                name: [validators.max(35)],
                'address.line': [validators.max(35)],
                'address.cityName': [validators.max(35)],
                'address.postcodeID': [validators.max(9)],
                'address.countryCode': [validators.exact(2)],
            },
        },
    },
};

export const consigneeValidation: {
    childValidators: {
        'goodsShipment.consignee': ValidationSchema<CustomerFields>;
    };
} = {
    childValidators: {
        'goodsShipment.consignee': {
            childValidators: {
                id: [validators.eori()],
                name: [validators.max(35)],
                'address.line': [validators.max(35)],
                'address.cityName': [validators.max(35)],
                'address.postcodeID': [validators.max(9)],
                'address.countryCode': [validators.exact(2)],
            },
        },
    },
};

export const representativeValidation: {
    childValidators: {
        agent: ValidationSchema<CustomerFields>;
    };
} = {
    childValidators: {
        agent: {
            childValidators: {
                id: [validators.eori()],
                name: [validators.max(70)],
                'address.line': [validators.max(70)],
                'address.cityName': [validators.max(35)],
                'address.postcodeID': [validators.max(9)],
                'address.countryCode': [validators.exact(2)],
                functionCode: [validators.exact(1)],
            },
        },
    },
};

export const carrierValidation: {
    childValidators: {
        'consignment.carrier': ValidationSchema<CustomerFields>;
    };
} = {
    childValidators: {
        'consignment.carrier': {
            childValidators: {
                id: [validators.eori()],
                name: [validators.max(70)],
                'address.line': [validators.max(70)],
                'address.cityName': [validators.max(35)],
                'address.postcodeID': [validators.max(9)],
                'address.countryCode': [validators.exact(2)],
            },
        },
    },
};

export const consigneeProductValidation: {
    childValidators: {
        consignee: ValidationSchema<CustomerFields>;
    };
} = {
    childValidators: {
        consignee: {
            childValidators: {
                id: [validators.eori()],
                name: [validators.max(35)],
                'address.line': [validators.max(35)],
                'address.cityName': [validators.max(35)],
                'address.postcodeID': [validators.max(9)],
                'address.countryCode': [validators.exact(2)],
            },
        },
    },
};

export const consigneeProductFields = consigneeProductValidation.childValidators.consignee;

interface Props extends BlockProps<CustomerFields> {
    path: string | null;
    options: {
        ucc: string;
    };
}

export const addToItemOfUcc = (ucc: string | undefined, amount: number) => {
    let uccGroup, uccItem;
    let parts: string[] = [];
    let delimiter;

    if (ucc === undefined) return undefined;

    if (ucc.includes('/')) {
        delimiter = '/';
    } else if (ucc.includes('.')) {
        delimiter = '.';
    } else {
        return "Can't parse";
    }

    parts = ucc.split(delimiter);

    uccGroup = parts[0];
    uccItem = parts[1];

    return `${uccGroup}${delimiter}${Number(uccItem) + amount}`;
};

const CustomerBlock = ({ path, fieldOptions, options }: Props): ReactElement => {
    const ucc = useMemo(() => options.ucc, [options.ucc]);

    return (
        <>
            <EoriBlock path={path} fieldOptions={fieldOptions} options={{ ucc: addToItemOfUcc(ucc, 1)! }} />
            <AddressBlock path={path} fieldOptions={fieldOptions} options={{ ucc }} />
        </>
    );
};

export default CustomerBlock;
