import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import NatureOfTransactionBlock, {
    natureOfTransactionBlockProductValidation,
    natureOfTransactionBlockValidation,
} from '../blocks/NatureOfTransactionBlock';

export const natureOfTransactionCardValidation = {
    selfValidators: [natureOfTransactionBlockValidation],
};

export const natureOfTransactionCardProductValidation = {
    selfValidators: [natureOfTransactionBlockProductValidation],
};

const NatureOfTransactionCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { propsPathPrefix } = props;

    return (
        <NewFormCard title={t('natureOfTransaction')}>
            <FormCardContainer>
                <NatureOfTransactionBlock path={propsPathPrefix} />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default NatureOfTransactionCard;
