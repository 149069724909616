import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import Divider from 'components/ui/base/divider';
import { H4, H5 } from 'components/ui/base/typography';
import { ListPayload } from 'core/http/response';
import useSession from 'hooks/useSession';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { JobResponse } from 'store/jobs/job';
import CreateNewDeclarationCard from 'views/jobs/components/CreateNewDeclarationCard';
import JobsTable from 'views/jobs/components/tables/JobsTable';
import { ReverseCol, StyledContainer } from './Dashboard.styles';

interface Props {
    jobs: ListPayload<JobResponse>;
    declarationFromJobs: {
        [key: string]: Declaration[];
    };
    onCreateNewJob: () => void;
    showCreateCard: boolean;
    openCreateCard: () => void;
    closeCreateCard: () => void;
    onDeleteJob: (id: string) => void;
    onDeleteDeclaration: (id: string) => void;
    openAddTeamMember: () => void;
}
const DashboardTable: FC<Props> = ({
    jobs,
    declarationFromJobs,
    onCreateNewJob,
    showCreateCard,
    openCreateCard,
    closeCreateCard,
    onDeleteJob,
    onDeleteDeclaration,
    openAddTeamMember,
}) => {
    const { t } = useTranslation('dashboard');
    const navigate = useNavigate();
    const { isAdminUser } = useSession();

    return (
        <StyledContainer>
            <Row>
                <Col span={10}>
                    <H4 style={{ marginBottom: 0 }}>{t('dashboardTitle')}</H4>
                </Col>
                {isAdminUser && (
                    <ReverseCol span={14}>
                        <Button size="large" style={{ marginLeft: '1.6rem' }} onClick={() => openAddTeamMember()}>
                            {t('btAddTeamClerk')}
                        </Button>
                    </ReverseCol>
                )}
            </Row>
            <Row style={{ marginTop: '4.5rem' }}>
                <Col span={10}>
                    <H5>{t('subTitle')}</H5>
                </Col>
                <ReverseCol span={14}>
                    <Row>
                        <Button size="large" onClick={() => onCreateNewJob()}>
                            {t('btCreateNewJob')}
                        </Button>
                        <div style={{ marginLeft: '1.6rem' }}>
                            <Button size="large" onClick={() => openCreateCard()}>
                                {t('btCreateDeclarations')}
                            </Button>
                            <CreateNewDeclarationCard isActive={showCreateCard} handleCancel={closeCreateCard} />
                        </div>
                    </Row>
                </ReverseCol>
            </Row>
            <Divider />
            <JobsTable
                data={jobs}
                onDeleteJob={onDeleteJob}
                onDeleteDeclaration={onDeleteDeclaration}
                declarationFromJobs={declarationFromJobs}
                onJobDetails={(id: string) => navigate(`/jobs/${id}`)}
                onDeclarationDetails={(id: string) => navigate(`/declarations/${id}`)}
            />
        </StyledContainer>
    );
};
export default DashboardTable;
