import { useFormik } from 'formik';
import useCodelists from 'hooks/useCodelists';
import useProducts from 'hooks/useProducts';
import { FC, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import TaricCodesCard, { TaricDetailsType } from 'views/declarations/common/taric-codes/TaricCodesCard';
import { getIrelandExportTaricCode, updateIrelandExportTaricCode } from 'views/declarations/common/taric-codes/utils';
import ExportBox44Card from './cards/ExportBox44';
import CommodityCard from './cards/products/CommodityCard';
import GoodsInformationCard from './cards/products/GoodsInformationCard';
import IrelandExportDutyTaxFee from './cards/products/IrelandExportDutyTaxFee';
import PackagingCard from './cards/products/PackagingCard';
import PreviousDocumentCard from './cards/products/PreviousDocumentCard';
import { ProductContext } from 'views/declarations/common/declaration-view/DeclarationView';

interface Props {
    formik: ReturnType<typeof useFormik>; // FIXME use correct type
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    selectCard?: (id: string) => void;
    expandAll?: boolean;
}

const IrelandExportProductsSection: FC<Props> = ({ formik, toggleHelp, viewOnly, expandAll, selectCard }) => {
    const { productId, declarationId } = useParams<string>();
    const { getIrelandExportProduct } = useProducts();
    const { codelists } = useCodelists();
    const { setProductId } = useContext(ProductContext);

    //only load when user refresh the page.
    useEffect(() => {
        const fetchProduct = async (declarationId: string, productId: string) => {
            const product = await getIrelandExportProduct(declarationId, productId);
            setProductId?.(productId);
            formik.setValues(product);
        };

        if (declarationId && productId && productId !== 'new') {
            const { values } = formik;

            if (values.id !== productId) {
                fetchProduct(declarationId, productId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, declarationId]);

    return (
        <>
            <TaricCodesCard
                keyCard={`taric-codes-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                toggleHelp={toggleHelp}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={7}
                cardNumber={1}
                updateTaricCode={(code) => updateIrelandExportTaricCode(formik, code)}
                country={DeclarationCountry.IRELAND}
                value={getIrelandExportTaricCode(formik)}
                detailsType={TaricDetailsType.EXPORT}
                selectCard={selectCard}
            />

            <GoodsInformationCard
                keyCard={`goods-information-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={7}
                selectCard={selectCard}
                cardNumber={2}
            />
            <PreviousDocumentCard
                keyCard={`previous-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={7}
                cardNumber={3}
                selectCard={selectCard}
            />
            <CommodityCard
                keyCard={`commodity-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                propsPathPrefix="commodity"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={7}
                cardNumber={4}
                selectCard={selectCard}
            />
            <PackagingCard
                viewOnly={viewOnly}
                keyCard={`packing-card`}
                expandAll={expandAll}
                propsPathPrefix="packaging"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={7}
                cardNumber={5}
                selectCard={selectCard}
            />
            <ExportBox44Card
                keyCard={`additional-information-form-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                title="Additional Information"
                propsPathPrefix="additionalInformation"
                getFieldMeta={formik.getFieldMeta}
                getFieldProps={formik.getFieldProps}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={7}
                cardNumber={6}
                selectCard={selectCard}
                codelists={codelists?.irelandAdditionalInformationCodes || []}
            />
            <ExportBox44Card
                keyCard={`additional-document-form-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                title="Additional Document"
                propsPathPrefix="additionalDocument"
                getFieldMeta={formik.getFieldMeta}
                getFieldProps={formik.getFieldProps}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={7}
                selectCard={selectCard}
                cardNumber={7}
                codelists={codelists?.irelandCommonDocumentsTypes || []}
            />
            <IrelandExportDutyTaxFee
                keyCard={`duty-tax-fee`}
                cardTotal={11}
                viewOnly={viewOnly}
                cardNumber={10}
                expandAll={expandAll}
                getFieldMeta={formik.getFieldMeta}
                getFieldProps={formik.getFieldProps}
                getFieldHelpers={formik.getFieldHelpers}
                propsPathPrefix="dutyTaxFees"
                selectCard={selectCard}
            />
        </>
    );
};
export default IrelandExportProductsSection;
