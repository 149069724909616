import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BorderlessCard from 'views/declarations/common/cards/borderless-card/BorderlessCard';
import { DeclarationMultipleFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { AdditionDeductionTypeLabels } from 'store/declarations/enums/uk/additions-deductions-types';
import { FormCardContainer } from '../../../../../common/cards/NewFormCard';

const AdditionsAndDeductionsCard: FC<DeclarationMultipleFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    const index = props.index;
    const size = props.size;
    const last = index === size - 1;

    const state = useMemo(
        () =>
            getCardState(
                [
                    { name: `additionDeductions.${index}.additionsDeductionsValue`, required: false },
                    { name: `additionDeductions.${index}.additionsDeductionsCurrency`, required: false },
                    { name: `additionDeductions.${index}.type`, required: false },
                ],
                props
            ),
        [index, props]
    );

    return (
        <BorderlessCard
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            title={`B. ${t('additionsDeductions')}`}
            multiple
            addCard={() => {
                if (last) {
                    props.add();
                }
            }}
            removeCard={() => {
                if (props.size > 1) {
                    props.remove(index);
                }
            }}
            isFirst={index === 0}
            isLast={last}
            state={state}
            keyCard={props.keyCard}
        >
            <FormCardContainer>
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`additionDeductions.${index}.additionsDeductionsValue`, props)}
                    refNumber="4.9"
                    label={t('additionsDeductionsValue')}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.9', t('additionsDeductionsValue'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CurrencySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`additionDeductions.${index}.additionsDeductionsCurrency`, props)}
                    refNumber="4.9"
                    tooltip={getCdsTooltipsByRefNumberAndField('4.9', t('currency'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`additionDeductions.${index}.type`, props)}
                    refNumber="4.9"
                    label={t('type')}
                    tooltip={t('defaultTooltip')}
                    selectOptions={AdditionDeductionTypeLabels}
                    condensed
                />
            </FormCardContainer>
        </BorderlessCard>
    );
};
export default AdditionsAndDeductionsCard;
