import ExpandedTable from 'components/ui/base/table/ExpandedTable';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { JobResponse } from 'store/jobs/job';
import declarationColums from 'views/custom-declaration/declaration-table/DeclarationColumns';

export interface Props {
    onDelete?: Function;
    declarationFromJobs: { [key: string]: Declaration[] };
    onDeclarationDetails: (id: string) => void;
    job: JobResponse;
}

const DeclarationsOfJobTable: FC<Props> = ({ onDelete, declarationFromJobs, onDeclarationDetails, job }) => {
    const navigate = useNavigate();

    const handleDuplicate = async (declaration: Declaration) => {
        const country =
            declaration.cdsExportDeclaration || declaration.cdsImportDeclaration
                ? DeclarationCountry.UK
                : DeclarationCountry.IRELAND;
        navigate(`/invoice-upload/${country}/${declaration.declarationInternalType}/job/${declaration.jobId}`, {
            state: { declaration: declaration },
        });
    };

    const renderDeclarations = () => {
        if (declarationFromJobs) {
            const array = declarationFromJobs[job.id];
            return (
                <ExpandedTable
                    onRow={(record) => {
                        return {
                            onClick: (event) => {
                                event.stopPropagation();
                                onDeclarationDetails(record.id!);
                            },
                        };
                    }}
                    rowKey="id"
                    showHeader={false}
                    dataSource={array}
                    columns={declarationColums(handleDuplicate, onDelete)}
                    pagination={false}
                />
            );
        }
        return <></>;
    };

    return <>{renderDeclarations()}</>;
};
export default DeclarationsOfJobTable;
