import { PaginatedParams } from 'core/http/pagination';
import { useCallback, useEffect } from 'react';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { UkProductTemplate } from 'store/products-templates/ukProducts';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import {
    doDeleteH1ProductTemplate,
    doDeleteUkProductTemplate,
    doGetH1Product,
    doGetUkProduct,
    doListH1Product,
    doListH1Tags,
    doListH7Tags,
    doListUkProduct,
    doSaveH1Product,
    doSaveUkProduct,
    doUpdateH1Product,
    doUpdateH7Product,
    doUpdateUkProduct,
    doSaveH7Product,
    doListH7Product,
    doGetH7Product,
    doDeleteH7ProductTemplate,
    doSaveCdsExportProduct,
    doListCdsExportProduct,
    doUpdateCdsExportProduct,
    doListCdsExportTags,
    doListEnsProducts,
    doSaveEnsProduct,
    doDeleteGeneralizedProductTemplate,
    doUpdateEnsProduct,
    doGetGeneralizedProductTemplate,
    doListEnsTags,
} from '../store/products-templates/action';
import { ProductTemplate } from '../store/products-templates/products';
import { ProductTabKeys } from '../views/custom-declaration/products/ListProductTemplates';
import { useTranslation } from 'react-i18next';
import useResponseNotification from 'hooks/useResponseNotification';
import { createProductTemplatesNotifs, editProductTemplatesNotifs } from '../utils/notificationMessages';
import { ListProductTemplateParams } from 'store/products-templates/client';

interface UseProductsTemplatesProps {
    productId?: string;
    country?: DeclarationCountry;
    type?: ProductTabKeys;
    declarationTemplateId?: string;
}

const useProductsTemplates = ({ productId, country, type }: UseProductsTemplatesProps) => {
    const isLoading = useAppSelector((state) => state.productsTemplate.isLoading);
    const error = useAppSelector((state) => state.productsTemplate.error);
    const productsTemplates = useAppSelector((state) => state.productsTemplate.products);
    const productTemplate = useAppSelector((state) =>
        productId ? state.productsTemplate.entities[productId] : ({} as ProductTemplate)
    );
    const requestSuccess = useAppSelector((state) => state.productsTemplate.requestSuccess);
    const tagsList = useAppSelector((state) => state.productsTemplate.tags);
    const { withResponseNotifications } = useResponseNotification();
    const { t } = useTranslation('common');

    const dispatch = useAppDispatch();

    const listH1ProductsTemplates = useCallback(
        (params?: Partial<ListProductTemplateParams>) => dispatch(doListH1Product(params)),
        [dispatch]
    );

    const listH7ProductsTemplates = useCallback(
        (params?: Partial<ListProductTemplateParams>) => dispatch(doListH7Product(params)),
        [dispatch]
    );

    const listCdsExportProductsTemplates = useCallback(
        (params?: Partial<ListProductTemplateParams>) => dispatch(doListCdsExportProduct(params)),
        [dispatch]
    );

    const listEnsProductsTemplates = useCallback(
        (params?: Partial<ListProductTemplateParams>) => dispatch(doListEnsProducts(params)),
        [dispatch]
    );

    const saveH1ProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doSaveH1Product(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const saveH7ProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doSaveH7Product(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const saveCdsExportProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doSaveCdsExportProduct(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const saveEnsProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doSaveEnsProduct(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editH1ProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateH1Product(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editH7ProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateH7Product(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editCdsExportProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateCdsExportProduct(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editEnsProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateEnsProduct(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const getH1ProductTemplate = useCallback((productId: string) => dispatch(doGetH1Product(productId)), [dispatch]);

    const getH7ProductTemplate = useCallback((productId: string) => dispatch(doGetH7Product(productId)), [dispatch]);

    const getGeneralizedProductTemplate = useCallback(
        (productId: string) => dispatch(doGetGeneralizedProductTemplate(productId)),
        [dispatch]
    );

    const listUkProductTemplates = useCallback(
        (params?: Partial<PaginatedParams>) => dispatch(doListUkProduct(params)),
        [dispatch]
    );

    const saveUkProductTemplate = useCallback(
        async (product: UkProductTemplate) =>
            withResponseNotifications(dispatch(doSaveUkProduct(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editUkProductTemplate = useCallback(
        async (product: UkProductTemplate) =>
            withResponseNotifications(dispatch(doUpdateUkProduct(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const getUkProductTemplate = useCallback((productId: string) => dispatch(doGetUkProduct(productId)), [dispatch]);

    const listH1Tags = useCallback(() => dispatch(doListH1Tags()), [dispatch]);

    const listH7Tags = useCallback(() => dispatch(doListH7Tags()), [dispatch]);

    const listCdsExportTags = useCallback(() => dispatch(doListCdsExportTags()), [dispatch]);

    const listEnsTags = useCallback(() => dispatch(doListEnsTags()), [dispatch]);

    const listProducts = useCallback(
        (params?: Partial<PaginatedParams | ListProductTemplateParams>) => {
            if (country === DeclarationCountry.UK) {
                if (type === ProductTabKeys.B1) {
                    listCdsExportProductsTemplates(params);
                } else {
                    listUkProductTemplates(params);
                }
            } else {
                if (type === ProductTabKeys.H7) {
                    listH7ProductsTemplates(params);
                } else if (type === ProductTabKeys.ENS) {
                    listEnsProductsTemplates(params);
                } else {
                    listH1ProductsTemplates(params);
                }
            }
        },
        [
            country,
            type,
            listH1ProductsTemplates,
            listH7ProductsTemplates,
            listUkProductTemplates,
            listCdsExportProductsTemplates,
            listEnsProductsTemplates,
        ]
    );

    const deleteProductTemplate = useCallback(
        (productId: string) => {
            if (country === DeclarationCountry.UK) {
                if (type === ProductTabKeys.B1) {
                    return dispatch(doDeleteGeneralizedProductTemplate(productId));
                } else {
                    return dispatch(doDeleteUkProductTemplate(productId));
                }
            } else {
                if (type === ProductTabKeys.H7) {
                    return dispatch(doDeleteH7ProductTemplate(productId));
                } else if (type === ProductTabKeys.ENS) {
                    return dispatch(doDeleteGeneralizedProductTemplate(productId));
                } else {
                    return dispatch(doDeleteH1ProductTemplate(productId));
                }
            }
        },
        [country, dispatch, type]
    );

    useEffect(() => {
        if (productId && productTemplate?.id !== productId) {
            if (country === DeclarationCountry.UK) {
                if (type === ProductTabKeys.B1) {
                    getGeneralizedProductTemplate(productId);
                } else {
                    getUkProductTemplate(productId);
                }
            } else {
                if (ProductTabKeys.H7 === type) {
                    getH7ProductTemplate(productId);
                } else if (ProductTabKeys.ENS === type) {
                    getGeneralizedProductTemplate(productId);
                } else {
                    getH1ProductTemplate(productId);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, type, country]);

    return {
        isLoading,
        error,
        productsTemplates,
        productTemplate,
        listH1ProductsTemplates,
        listH7ProductsTemplates,
        listUkProductTemplates,
        saveH1ProductTemplate,
        saveH7ProductTemplate,
        saveUkProductTemplate,
        editH1ProductTemplate,
        editH7ProductTemplate,
        editUkProductTemplate,
        requestSuccess,
        listH1Tags,
        listH7Tags,
        tagsList,
        listProducts,
        deleteProductTemplate,
        saveCdsExportProductTemplate,
        listCdsExportProductsTemplates,
        editCdsExportProductTemplate,
        listCdsExportTags,
        listEnsProductsTemplates,
        saveEnsProductTemplate,
        editEnsProductTemplate,
        listEnsTags,
    };
};

export default useProductsTemplates;
