import { ColumnsType } from 'antd/lib/table';
import DashboardTable from 'components/ui/composed/dashboard/DashboardTable';
import { curry } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Template, TemplateResponse, TemplateSortParameter } from 'store/template/template';
import useTemplates from 'hooks/useTemplates';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { declarationExternalTypeToCountry } from 'components/ui/composed/template/template-utils';
import { getDeclarationNameLabels } from '../invoice-upload/utils';
import EditTemplateNameDrawer from './components/EditTemplateNameDrawer';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { AvailableTemplateFormTypes } from 'store/template/action';

interface Props {
    country: DeclarationCountry;
    type: DeclarationInternalType;
    formType: AvailableTemplateFormTypes;
    viewOnly?: boolean;
    onRowClick?: (id: string | undefined) => void;
    withPagination?: boolean;
    options?: {
        visible?: {
            country?: boolean;
            declarationType?: boolean;
            templateName?: boolean;
        };
    };
}

const TemplatesTable = ({
    country,
    type,
    formType,
    viewOnly,
    onRowClick,
    withPagination = true,
    options = { visible: { country: true, declarationType: true, templateName: true } },
}: Props) => {
    const { templates, listTemplates } = useTemplates();
    const { openModal } = useTemplateContext();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [template, setTemplate] = useState<Template | undefined>();

    const [showEmpty, setShowEmpty] = useState(false);

    useEffect(() => {
        if (
            (country === DeclarationCountry.IRELAND &&
                (type === DeclarationInternalType.IMPORT || type === DeclarationInternalType.ENS)) ||
            (country === DeclarationCountry.UK && type === DeclarationInternalType.EXPORT)
        ) {
            listTemplates(country, type, formType, { size: withPagination ? 10 : 9999 }).then(() => {
                setShowEmpty(false);
            });
        } else {
            setShowEmpty(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, type, formType]);

    const toFullFormType = useCallback(
        (formType: string | undefined) => {
            const labels = getDeclarationNameLabels(country as any, type as any) as any[];
            return labels.find(({ key }) => key === formType)?.value;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [templates]
    );

    const columns: ColumnsType<TemplateResponse> = useMemo(() => {
        const _columns: ColumnsType<TemplateResponse> = [];
        if (options.visible?.country)
            _columns.push({
                title: 'Country',
                dataIndex: TemplateSortParameter.DECLARATION_EXTERNAL_TYPE,
                key: TemplateSortParameter.DECLARATION_EXTERNAL_TYPE,
                align: 'left',
                render: (text: string, record, index: number) => (
                    <span>{declarationExternalTypeToCountry(record.declarationExternalType)}</span>
                ),
                sorter: true,
            });
        if (options.visible?.declarationType)
            _columns.push({
                title: 'Declaration Type',
                dataIndex: TemplateSortParameter.DECLARATION_NAME,
                key: TemplateSortParameter.DECLARATION_NAME,
                align: 'left',
                render: (text: string, record, index: number) => <span>{toFullFormType(record.declarationName)}</span>,
                sorter: true,
            });
        if (options.visible?.templateName)
            _columns.push({
                title: 'Template Name',
                dataIndex: TemplateSortParameter.TEMPLATE_NAME,
                key: TemplateSortParameter.TEMPLATE_NAME,
                align: 'left',
                render: (text: string, record, index: number) => <span>{record.templateName}</span>,
                sorter: true,
            });
        return _columns;
    }, [options.visible?.country, options.visible?.declarationType, options.visible?.templateName, toFullFormType]);

    const handleOpenModal = (id: string, viewOnly?: boolean) => {
        const template = templates.list.find((t) => t.id === id);
        const country = template?.declarationExternalType === 'REVENUE' ? 'IRELAND' : 'UK';
        const declarationType = template?.declarationInternalType as 'IMPORT' | 'EXPORT';

        openModal?.({
            templateId: template?.id,
            country,
            declarationType,
            formType: template?.declarationName as any,
            name: template?.templateName,
            viewOnly,
        });
    };

    const openDrawer = (template: Template | undefined) => {
        setDrawerOpen(true);
        setTemplate(template);
    };
    const closeDrawer = () => {
        setDrawerOpen(false);
        setTemplate(undefined);
    };

    const handleEdit = (id: string) => {
        const template = templates.list.find((t) => t.id === id);
        openDrawer(template);
    };

    return (
        <>
            <DashboardTable
                columns={columns}
                data={showEmpty ? undefined : templates}
                withPagination={withPagination}
                onPaginatorChange={curry(listTemplates)(country, type)}
                doSelectOnClick={viewOnly}
                onRowSelection={viewOnly ? (ids) => onRowClick?.(ids[0]) : undefined}
                rowSelectionType="radio"
                onDuplicate={viewOnly ? undefined : handleOpenModal}
                onEdit={viewOnly ? undefined : handleEdit}
                onView={(r) => handleOpenModal(r, true)}
            />
            {!viewOnly && <EditTemplateNameDrawer onClose={closeDrawer} visible={drawerOpen} template={template} />}
        </>
    );
};

export default TemplatesTable;
