import { Text } from 'components/ui/base/typography';
import styled from 'styled-components';

const DeviceCalibrationStyledComponents = {
    ReloadButtonContainer: styled.div`
        height: 100%;
        display: grid;
        place-items: center;
    `,
    DeviceReaderViewContainer: styled.div`
        width: 75%;
        margin-inline: auto;

        margin-top: 2rem;
    `,
    DeviceReaderTextButtonsContainer: styled.div<{ calibrating?: boolean }>`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 3rem;
        flex-wrap: wrap;

        margin-top: 2rem;

        > div {
            flex-basis: ${({ calibrating }) => (calibrating ? 'unset' : 0)};
        }

        > div.inputs {
            display: flex;
            flex-basis: 400px;
            gap: 1rem;

            align-items: center;
        }
    `,
    Text: styled(Text)`
        font-size: 14px;
    `,
    DeviceReaderText: styled(Text)`
        font-size: 14px;
        min-width: 200px;
        flex: 1;
    `,
};

export default DeviceCalibrationStyledComponents;
