import { TemplateResponse } from 'components/ui/composed/template/TemplateContext';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import {
    IrelandExportMessageType,
    irelandExportMessageTypeLabels,
} from 'store/declarations/enums/ireland/message-type';
import { IrelandEntrySummaryDeclarationPayload } from 'store/declarations/ireland/entry-summary-declaration';
import { IrelandExportDeclarationPayload } from 'store/declarations/ireland/export-declaration';
import { IrelandH7ImportDeclarationPayload } from 'store/declarations/ireland/h7-import-declaration';
import { IrelandImportDeclarationPayload } from 'store/declarations/ireland/import-declaration';
import {
    IrelandDeclarationName,
    irelandDeclarationNameLabels,
} from 'store/declarations/ireland/ireland-declaration-name';
import { CdsExportDeclarationPayload, CdsExportMessageType } from 'store/declarations/uk/export-declaration';
import {
    UkImportDeclarationName,
    ukDeclarationNameLabels,
    ukExportDeclarationNameLabels,
} from 'store/declarations/uk/uk-declaration-name';
export const getDeclarationNameLabels = (country: DeclarationCountry, type: DeclarationInternalType) => {
    if (country === DeclarationCountry.UK) {
        if (type === DeclarationInternalType.IMPORT) {
            return [...ukDeclarationNameLabels];
        } else {
            return [...ukExportDeclarationNameLabels];
        }
    } else {
        if (type === DeclarationInternalType.IMPORT) {
            return [...irelandDeclarationNameLabels];
        } else if (type === DeclarationInternalType.EXPORT) {
            return [...irelandExportMessageTypeLabels];
        } else {
            return [];
        }
    }
};

export const getMessageType = (country: DeclarationCountry, type: DeclarationInternalType, value: string) => {
    if (country === DeclarationCountry.UK) {
        if (type === DeclarationInternalType.IMPORT) {
            return ukDeclarationNameLabels.find((e) => e.value === value)?.key;
        } else {
            return ukExportDeclarationNameLabels.find((e) => e.value === value)?.key;
        }
    } else {
        if (type === DeclarationInternalType.IMPORT) {
            return irelandDeclarationNameLabels.find((e) => e.value === value)?.key;
        } else {
            return irelandExportMessageTypeLabels.find((e) => e.value === value)?.key;
        }
    }
};

export const createOrDuplicateDeclarations = async (
    updateDeclaration: Function,
    createDeclaration: Function,
    jobId: string,
    setMyDeclaration: Function,
    country: string,
    customerId?: string,
    duplicateDeclarationId?: string,
    messageType?: UkImportDeclarationName | IrelandDeclarationName | IrelandExportMessageType | CdsExportMessageType
) => {
    if (duplicateDeclarationId) {
        if (customerId) {
            const response = (await updateDeclaration(duplicateDeclarationId, customerId, jobId)) as Declaration;
            if (response) {
                setMyDeclaration(response);
                return response;
            }
        }
    } else {
        if (customerId) {
            let response = {};
            if (messageType) {
                response = (await createDeclaration(country, customerId, jobId, messageType)) as Declaration;
            } else {
                response = (await createDeclaration(country, customerId, jobId)) as Declaration;
            }
            if (response) {
                setMyDeclaration(response);
                return response;
            }
        }
    }
};

export const createDeclarations = async (
    formType: string | undefined,
    country: DeclarationCountry,
    customerId: string,
    jobId: string,
    type: DeclarationInternalType,
    createIrelandH7ImportDeclaration: Function,
    createIrelandImportDeclaration: Function,
    createEntrySummaryDeclaration: Function,
    createTemporaryStorageDeclaration: Function,
    createIrelandExportDeclaration: Function,
    createElectronicTransportDocument: Function,
    createUkImportDeclaration: Function,
    createUkExportDeclaration: Function,
    createUkImportNewDeclaration: Function,
    createArrivalAtExitDeclaration: Function,
    messageType?: IrelandDeclarationName | IrelandExportMessageType | UkImportDeclarationName | CdsExportMessageType,
    template?: TemplateResponse
) => {
    if (country === DeclarationCountry.IRELAND) {
        if (type === DeclarationInternalType.IMPORT) {
            if ((messageType as IrelandDeclarationName) === IrelandDeclarationName.H7) {
                return await createIrelandH7ImportDeclaration(
                    customerId,
                    template?.template?.master.defaults,
                    jobId,
                    template?.id
                );
            } else {
                return await createIrelandImportDeclaration(
                    customerId,
                    { ...template?.template?.master.defaults, messageType: messageType as IrelandDeclarationName },
                    jobId,
                    template?.id
                );
            }
        } else if (type === DeclarationInternalType.ENS) {
            return await createEntrySummaryDeclaration(
                customerId,
                template?.template?.master.defaults,
                jobId,
                template?.id
            );
        } else if (type === DeclarationInternalType.ETD) {
            return await createElectronicTransportDocument(customerId, undefined, jobId);
        } else if (type === DeclarationInternalType.TEMPORARY) {
            return await createTemporaryStorageDeclaration(customerId, undefined, jobId);
        } else if (type === DeclarationInternalType.ARRIVAL) {
            return await createArrivalAtExitDeclaration(customerId, undefined, jobId);
        } else {
            return await createIrelandExportDeclaration(
                customerId,
                { messageType: messageType as IrelandExportMessageType },
                jobId
            );
        }
    } else if (country === DeclarationCountry.UK) {
        if (type === DeclarationInternalType.IMPORT) {
            if (formType?.includes('H2')) {
                return await createUkImportNewDeclaration(customerId, { messageType }, jobId);
            } else {
                return await createUkImportDeclaration(
                    customerId,
                    { messageType: messageType as UkImportDeclarationName },
                    jobId
                );
            }
        } else {
            return await createUkExportDeclaration(
                customerId,
                { cdsExportDeclarationPayload: template?.template?.master.defaults, messageType },
                jobId,
                template?.id,
                template?.template
            );
        }
    }
};
const getIds = (declaration: Declaration, customerId?: string, jobId?: string) => {
    const customer = customerId ?? declaration.customerId;
    const job = jobId ?? declaration.jobId;
    return { customer, job, declaration: declaration.id };
};

export const updateCreatedDeclaration = async (
    updateIrelandImportDeclaration: Function,
    updateIrelandExportDeclaration: Function,
    updateIrelandH7ImportDeclaration: Function,
    updateUkImportDeclaration: Function,
    updateUkExportDeclaration: Function,
    updateEntrySummaryDeclaration: Function,
    declaration: Declaration,
    customerId?: string,
    jobId?: string
) => {
    const ids = getIds(declaration, customerId, jobId);
    if (declaration.cdsImportDeclaration) {
        const newDeclaration = {
            ...declaration,
            customerId: ids.customer,
            jobId: ids.job,
        } as IrelandImportDeclarationPayload;
        return await updateUkImportDeclaration(ids.customer, ids.declaration, newDeclaration);
    } else if (declaration.cdsExportDeclaration) {
        const newDeclaration = {
            ...declaration,
            customerId: ids.customer,
            jobId: ids.job,
        } as CdsExportDeclarationPayload;
        return await updateUkExportDeclaration(ids.customer, ids.declaration, newDeclaration);
    } else if (declaration.irelandExportDeclaration) {
        const newDeclaration = {
            ...declaration,
            customerId: ids.customer,
            jobId: ids.job,
        } as IrelandExportDeclarationPayload;
        return await updateIrelandExportDeclaration(ids.customer, ids.declaration, newDeclaration);
    } else if (declaration.irelandH7ImportDeclaration) {
        const newDeclaration = {
            ...declaration,
            customerId: ids.customer,
            jobId: ids.job,
        } as IrelandH7ImportDeclarationPayload;
        return await updateIrelandH7ImportDeclaration(ids.customer, ids.declaration, newDeclaration);
    } else if (declaration.irelandImportDeclaration) {
        const newDeclaration = {
            ...declaration,
            customerId: ids.customer,
            jobId: ids.job,
        } as IrelandImportDeclarationPayload;
        return await updateIrelandImportDeclaration(ids.customer, ids.declaration, newDeclaration);
    } else if (declaration.entrySummaryDeclaration) {
        const newDeclaration = {
            ...declaration,
            customerId: ids.customer,
            jobId: ids.job,
        } as IrelandEntrySummaryDeclarationPayload;
        return await updateEntrySummaryDeclaration(ids.declaration, newDeclaration);
    }
};
