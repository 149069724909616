import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { normalizeCodesToSelect } from 'store/codelists/code';
import useCodelists from 'hooks/useCodelists';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'modeCode';

export const modeOfTransportAtTheBorderBlockValidation: {
    childValidators: {
        borderTransportMeans: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        borderTransportMeans: {
            childValidators: {
                modeCode: [validators.required(), validators.number(), validators.exact(1)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const ModeOfTransportAtTheBorderBlock = ({ path = 'borderTransportMeans', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();

    const ucc = useMemo(() => '7/4', []);
    return (
        <DeclarationField pathPrefix={path} name="modeCode">
            {({ form, field }: FieldProps<any>) => (
                <FormSelect
                    refNumber={ucc}
                    required
                    label={t('modeOfTransportAtTheBorder')}
                    {...getFormikProps(field.name, form)}
                    selectOptions={normalizeCodesToSelect(codelists?.ukTransportModeTypes || [])}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default ModeOfTransportAtTheBorderBlock;
