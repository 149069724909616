import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';

const GoodItemCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <>
            <FormInput
                maxLength={512}
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.rateOfYield`, props)}
                refNumber="5.5"
                label={t('goodsItems.rateOfYield')}
                tooltip={getH1TooltipsByRefNumberAndField('5.5', t('goodsItems.rateOfYield'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                condensed
            />
            <FormInput
                maxLength={8}
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.processedProductsCommodityCode`, props)}
                refNumber="6.14"
                label={t('goodsItems.commodityCode')}
                tooltip={getH1TooltipsByRefNumberAndField('6.14', t('goodsItems.commodityCode'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                condensed
            />
            <FormInput
                maxLength={512}
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.processedProductsDescriptionOfGoods`, props)}
                refNumber="6.8"
                label={t('goodsItems.goodsDescription')}
                tooltip={getH1TooltipsByRefNumberAndField('6.8', t('goodsItems.goodsDescription'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                condensed
            />
            <FormSelect
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.identificationOfGoodsCode`, props)}
                refNumber="5.8"
                label={t('goodsItems.identificationOfGoodsCode')}
                tooltip={getH1TooltipsByRefNumberAndField('5.8', t('goodsItems.identificationOfGoodsCode'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                selectOptions={normalizeCodesToSelect(codelists?.irelandIdentificationOfGoodsCodes || [])}
                condensed
            />
            <FormInput
                maxLength={512}
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.identification`, props)}
                refNumber="5.8"
                label={t('identification')}
                tooltip={getH1TooltipsByRefNumberAndField('5.8', t('identification'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                condensed
            />
            <FormSelect
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.economicConditionsProcessingProcedure`, props)}
                refNumber="6.2"
                label={t('processingProcedure')}
                tooltip={getH1TooltipsByRefNumberAndField('6.2', t('processingProcedure'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                selectOptions={normalizeCodesToSelect(codelists?.irelandProcessingProcedureCodes || [])}
                condensed
            />
            <FormInput
                maxLength={512}
                viewOnly={props.viewOnly}
                {...getFormikProps(`authorisation.economicConditionsDetails`, props)}
                refNumber="6.2"
                label={t('details')}
                tooltip={getH1TooltipsByRefNumberAndField('6.2', t('details'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                condensed
            />
        </>
    );
};
export default GoodItemCard;
