import { set } from 'lodash';
import addPathPrefix from 'utils/addPathPrefix';
import {
    EORI_REGEX,
    getPatternDigitsAfterComma,
    getFloatValidationMessage,
    invalidEoriMessage,
} from 'views/declarations/utils/validation-utils';

/**
 * @type {Object} ValidationSchema
 * @property {boolean} stopAtNull - Allow for validation to stop on null element. Mainly used for arrays.
 */
export interface ValidationSchema<
    TChildValidators extends Record<string, Validator[] | ValidationSchema | ValidationSchema[]> | string = any
> {
    childValidators?: TChildValidators extends string
        ? { [K in TChildValidators]: Validator[] | ValidationSchema | ValidationSchema[] }
        : TChildValidators;
    selfValidators?: Validator[] | ValidationSchema | ValidationSchema[];
    arrayItemValidators?: Validator[] | ValidationSchema | ValidationSchema[];
    stopAtNull?: boolean;
    isMin1Array?: boolean;
}

export type ExtractValidationSchema<TValidationSchema extends ValidationSchema<any>> = ValidationSchema<
    TValidationSchema['childValidators']
>;

export class FormModel<TValues extends Record<string, any>> {
    private values: TValues;

    constructor(state: TValues) {
        this.values = state;
    }

    getValues() {
        return this.values;
    }

    get(path: string): Record<string, any> | string | undefined {
        if (path === '') return this.values;

        let value = { ...this.values } as any;

        for (let part of path.split('.')) {
            if (value === undefined) return undefined;
            if (part in value) {
                value = value[part];
            } else {
                return undefined;
            }
        }

        return value;
    }
}

// =============================== Validators ===============================
type SingleValueValidator = (path: string, formModel: FormModel<any>) => Promise<string | undefined>;
export type Validator = (
    path: string,
    formModel: FormModel<any>
) => Promise<string | (string | undefined)[] | { [key: string]: string } | undefined>;
const requiredFactory = () => async (path: string, formModel: FormModel<any>) => {
    const value = formModel.get(path);
    if (value == null || value === '') {
        return 'Field is required';
    }
    return undefined;
};

const maxLengthFactory = (number: number) => async (path: string, formModel: FormModel<any>) => {
    const value = formModel.get(path);
    const length = value?.toString().length;
    if (length && length > number) {
        return `Must be ${number} characters or less`;
    }
    return undefined;
};
const minFactory = (number: number) => async (path: string, formModel: FormModel<any>) => {
    const value = formModel.get(path);

    if (isNaN(Number(value))) return undefined;

    if (Number(value) < number) {
        return `Must be greater than ${number}`;
    }
    return undefined;
};
const exactFactory = (number: number) => async (path: string, formModel: FormModel<any>) => {
    const value = formModel.get(path);

    if (!value?.toString().length) return undefined;

    if (value?.toString().length !== number) {
        return `Must be exactly ${number} characters`;
    }

    return undefined;
};
const numberFactory = () => async (path: string, formModel: FormModel<any>) => {
    const value = formModel.get(path);
    if (value && isNaN(Number(value))) {
        return `Must be a number`;
    }
    return undefined;
};
const minLengthFactory = (minLength: number) => async (path: string, formModel: FormModel<any>) => {
    const value = formModel.get(path);

    if (!value || (Array.isArray(value) && value.length < minLength)) {
        return `At least ${minLength} item(s) required`;
    }
    return undefined;
};

const hackArrayMinLengthFactory =
    (key: string, minLength: number, onField?: boolean) => async (path: string, formModel: FormModel<any>) => {
        const fieldPath = addPathPrefix(path, key);
        const value = formModel.get(fieldPath);
        if (!value || !value.length || value.length < minLength)
            return { [`${fieldPath}${onField ? '.0' : ''}`]: `At least ${minLength} item(s) required` };

        return undefined;
    };

const eoriFactory = () => async (path: string, formModel: FormModel<any>) => {
    const value = formModel.get(path) as string;
    let message = invalidEoriMessage;

    if (!value) return undefined;

    if (value.toString().match(EORI_REGEX)) {
        try {
            const isValid = await window.EoriService.checkEori(path, value);

            if (isValid) {
                return undefined;
            }
        } catch (error: any) {
            message = error.reason;
        }
    }

    return message;
};
const floatFactory = (beforeComma: number, afterComma: number) => async (path: string, formModel: FormModel<any>) => {
    const value = formModel.get(path);
    const message = getFloatValidationMessage(beforeComma, afterComma);
    const patternDigitsAfterComma = getPatternDigitsAfterComma(beforeComma, afterComma);

    if (!value?.length) return undefined;

    if (!value?.toString().match(patternDigitsAfterComma)) {
        return message;
    }

    return undefined;
};
const arrayFactory =
    (validators: SingleValueValidator[], options?: { min?: number }) =>
    async (path: string, formModel: FormModel<any>) => {
        let value = formModel.get(path);

        if (!Array.isArray(value)) return undefined;

        if (options?.min) {
            const numberToFill = options.min - value.length;
            if (numberToFill > 0) {
                for (let i = 0; i < numberToFill; i++) {
                    value = [...(value as any[]), null];
                }
            }
        }

        const errors: any[] = [];

        for (let i = 0; i < value.length; i++) {
            let hasError = false;
            for (const validator of validators) {
                const error = await validator(addPathPrefix(path, i.toString()), formModel);
                if (error) {
                    hasError = true;
                    errors.push(error);
                    break;
                }
            }
            if (!hasError) {
                errors.push(undefined);
            }
        }

        return errors;
    };

export const validators = {
    required: requiredFactory,
    max: maxLengthFactory,
    min: minFactory,
    exact: exactFactory,
    number: numberFactory,
    eori: eoriFactory,
    float: floatFactory,
    array: arrayFactory,
    minLength: minLengthFactory,
    hackArrayMinLength: hackArrayMinLengthFactory,
};

const isValidator = (value: any): value is Validator => {
    return value instanceof Function;
};
const isValidationSchema = (value: any): value is ValidationSchema => {
    return (
        value &&
        ((value as ValidationSchema | undefined)?.childValidators !== undefined ||
            (value as ValidationSchema | undefined)?.selfValidators !== undefined ||
            (value as ValidationSchema | undefined)?.stopAtNull !== undefined)
    );
};

const instanceValidate = async (formModel: FormModel<any>, schema: ValidationSchema | undefined, currentKey = '') => {
    let errors: Record<string, string | (string | undefined)[]> = {};

    const doValidate = async (
        validators: Validator[] | ValidationSchema | ValidationSchema[] | undefined,
        key: string
    ): Promise<boolean | undefined> => {
        if (Array.isArray(validators)) {
            for (let validator of validators) {
                if (isValidator(validator)) {
                    const error = await validator(key, formModel);
                    if (error) {
                        if (typeof error === 'string' || Array.isArray(error)) {
                            errors[key] = error;
                            return true;
                        } else {
                            // hack for array validation
                            // This is pretty special case, for validators that return object with errors for each string and array.
                            // We are using this to validate presence of at least one item in array with hackArrayMinLengthFactory
                            Object.assign(errors, error);
                            break;
                        }
                    }
                } else {
                    const error = await validate(formModel, validator, key);
                    if (Object.keys(error).length > 0) {
                        Object.assign(errors, error);
                    }
                }
            }
        } else if (isValidationSchema(validators)) {
            const error = await validate(formModel, validators, key);
            Object.assign(errors, error);
        }
    };

    const handleValidators = async (
        validators: Validator[] | ValidationSchema | ValidationSchema[] | undefined,
        key: string
    ) => {
        // if the value is array we call a recursive validation to validate each item
        if (Array.isArray(formModel.get(key))) {
            if (isValidationSchema(validators)) {
                const hasError = await doValidate(validators.selfValidators, key);
                if (hasError) return;
                // Stop here if there is an error
                const { selfValidators, ...exceptSelfValidators } = validators;
                const error = await validate(formModel, exceptSelfValidators, key);
                Object.assign(errors, error);
            } else {
                const error = await validate(formModel, { selfValidators: validators }, key);
                Object.assign(errors, error);
            }
            return;
        }

        await doValidate(validators, key);
    };

    if (!schema?.stopAtNull || formModel.get(currentKey) != null) {
        for (let [key, schemaOrValidators] of Object.entries(schema?.childValidators ?? {})) {
            /**
             * Used to trigger validations on first element of array flagged as
             * an array with minimum 1 item expected.
             */
            if (schema?.isMin1Array && (!formModel.get(currentKey) || formModel.get(currentKey)?.length === 0)) {
                key = '0.' + key;
            }

            await handleValidators(
                schemaOrValidators as Record<string, Validator[] | ValidationSchema | ValidationSchema[]>,
                addPathPrefix(currentKey, key)
            );
        }
    }
    await handleValidators(schema?.selfValidators, currentKey);
    await handleValidators(schema?.arrayItemValidators, currentKey);

    return errors;
};
export const validate = async (formModel: FormModel<any>, schema: ValidationSchema | undefined, currentKey = '') => {
    let errors: Record<string, string | string[]> = {};

    const value = formModel.get(currentKey);

    if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
            const error = await instanceValidate(formModel, schema, addPathPrefix(currentKey, i.toString()));
            Object.assign(errors, error);
        }
    } else {
        const error = await instanceValidate(formModel, schema, currentKey);
        Object.assign(errors, error);
    }

    return errors;
};
export const transformErrorsForFormik = (errors: Record<string, string | string[]>) => {
    let transformedErrors: Record<string, any> = {};
    for (const [key, value] of Object.entries(errors)) {
        transformedErrors = set(transformedErrors, key, value);
    }
    return transformedErrors;
};

export default validate;
