import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { commodityClassificationTypeCodeLabels } from 'store/declarations/enums/uk/commodity-classification-type-code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import addPathPrefix from '../../../../../../../utils/addPathPrefix';
import { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import MultipleItemsCard from '../../../../../common/MultipleItemsCard';
import { additionalNationalCodes } from '../../validation/UKProductValidationSchema';

const AdditionalNationalCodeCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix!}
            initialValues={{
                additionalNationalCodeIdentifier: '',
                typeCode: '',
            }}
            list={(list) => [
                { field: t('additionalNationalCodeIdentifier'), value: list.additionalNationalCodeIdentifier },
                { field: t('TypeCode'), value: list.typeCode },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            cardNumber={props.cardNumber}
            cardTotal={props.cardTotal}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            title={t('Additional National Code')}
            condensed
            validationSchema={additionalNationalCodes}
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormInput
                        required
                        maxLength={4}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'additionalNationalCodeIdentifier')}`, formProps)}
                        refNumber="6.17"
                        label={t('additionalNationalCodeIdentifier')}
                        tooltip={getCdsTooltipsByRefNumberAndField('6.17', t('additionalNationalCodeIdentifier'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <DeclarationSelect
                        required
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'typeCode')}`, formProps)}
                        refNumber="6.17"
                        label={t('TypeCode')}
                        selectOptions={commodityClassificationTypeCodeLabels}
                        tooltip={getCdsTooltipsByRefNumberAndField('6.17', t('TypeCode'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default AdditionalNationalCodeCard;
