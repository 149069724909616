import { FormikErrors } from 'formik';
import { CardError, ItemsFieldError } from '../../../../../store/declaration-form-errors/DeclarationFormErrors';
import {
    CdsImportDeclaration,
    CdsImportDeclarationPayload,
} from '../../../../../store/declarations/uk/import-declaration';
import {
    parseFieldErrorsForCard,
    parseFieldErrorsForCustomerCard,
    parseFieldErrorsForMultipleCard,
} from '../../../../../utils/validationParserUtils';
import { get } from 'lodash';

const parseValidationErrors = (validation: FormikErrors<CdsImportDeclaration>) => {
    const masterDetailsErrors: CardError[] = [];

    parseFieldErrorsForCard(
        'Master Details',
        [
            { typeCode: 'Declaration Type' },
            { additionalTypeCode: 'Additional Declaration Type' },
            { goodsItemQuantity: 'Number of items' },
            { 'goodsShipment.ucr.traderAssignedReferenceID': 'UCR' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCustomerCard('Importer', 'goodsShipment.importer', masterDetailsErrors, validation);
    parseFieldErrorsForCustomerCard('Declarant', 'declarant', masterDetailsErrors, validation);
    parseFieldErrorsForCustomerCard('Representative', 'agent', masterDetailsErrors, validation);

    parseFieldErrorsForMultipleCard(
        'Holder of the authorisation identification No.',
        'authorisationHolder',
        [{ id: 'Identification Number' }, { categoryType: 'Authorisation Type' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Identification of Warehouse',
        [{ id: 'Warehouse Identifier' }, { typeCode: 'Warehouse type' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Country of Destination',
        [
            { 'goodsShipment.destination.countryCode': 'Country of destination code' },
            { 'goodsShipment.exportCountry.id': 'Country of dispatch / export code' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Location Of Goods',
        [
            { 'goodsShipment.consignment.goodsLocation.id': 'Additional Identifier' },
            { 'goodsShipment.consignment.goodsLocation.name': 'Identification of location' },
            { 'goodsShipment.consignment.goodsLocation.typeCode': 'Goods Location Type' },
            { 'goodsShipment.consignment.goodsLocation.address.line': 'Street And Number' },
            { 'goodsShipment.consignment.goodsLocation.address.cityName': 'City' },
            { 'goodsShipment.consignment.goodsLocation.address.postCodeId': 'Post Code' },
            { 'goodsShipment.consignment.goodsLocation.address.countryCode': 'Country' },
            { 'goodsShipment.consignment.goodsLocation.address.typeCode': 'Goods Location Qualifier' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Supervising customs office',
        [{ 'supervisingOffice.id': 'Supervising customs office' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Customs Office of Presentation',
        [
            { 'declarant.id': 'Person Presenting the Goods to Customs' },
            { 'presentationOffice.id': 'Customs Office of Presentation' },
            { 'exitOffice.id': 'Customs Office of Exit' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Gross Mass',
        [
            { totalGrossMassMeasure: 'Gross Mass' },
            { totalPackageQuantity: 'Total Packages' },
            { 'goodsShipment.consignment.containerCode': 'Container' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Mode of Transport At The Border',
        [
            { 'borderTransportMeans.modeCode': 'Mode of Transport At The Border' },
            { 'goodsShipment.consignment.departureTransportMeans.modeCode': 'Inland mode of transport' },
            {
                'borderTransportMeans.registrationNationalityCode':
                    'Nationality of active means of transport crossing the border',
            },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Identity of means of transport on arrival',
        [
            { 'goodsShipment.consignment.arrivalTransportMeans.identificationTypeCode': 'Type of Identification' },
            { 'goodsShipment.consignment.arrivalTransportMeans.Id': 'Identification Number' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Guarantee Types',
        'obligationGuarantee',
        [{ securityDetailsCode: 'Guarantee Type' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Guarantee Reference',
        'obligationGuarantee',
        [
            { referenceId: 'GRN' },
            { id: 'Other Guarantee reference' },
            { accessCode: 'Access Code' },
            { amountAmount: 'Amount of import duty' },
            { amountAmountCurrencyId: 'Currency' },
            { 'guaranteeOffice.id': 'Customs office of guarantee' },
        ],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForCard(
        'Nature of Transaction',
        [{ 'goodsShipment.transactionNatureCode': 'Nature of Transaction' }],
        masterDetailsErrors,
        validation
    );

    parseFieldErrorsForMultipleCard(
        'Previous Documents',
        'goodsShipment.previousDocument',
        [
            { categoryCode: 'Previous Document Category' },
            { typeCode: 'Previous Document Type' },
            { id: 'Previous Document Reference' },
            { lineNumeric: 'Previous Document Identifier' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'additionalInformation',
        [
            { statementCode: 'Additional Information Code' },
            { statementDescription: 'Additional Information Description' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Additional supply chain actor(s) Ident. No.',
        'goodsShipment.aeoMutualRecognitionParty',
        [{ id: 'Identification Number' }, { roleCode: 'Role Code' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Additional Fiscal References Identification',
        'goodsShipment.domesticDutyTaxParty',
        [{ id: 'VAT Identification Number' }, { roleCode: 'Role Code' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Container Identification Number',
        'goodsShipment.consignment.transportEquipment',
        [{ id: 'Container Identification Number' }],
        masterDetailsErrors,
        validation
    );

    const itemsErrors: ItemsFieldError[] = [];
    parseDeclarationItems(itemsErrors, validation);

    return {
        masterDetails: masterDetailsErrors,
        items: itemsErrors,
    };
};

const parseDeclarationItems = (errors: ItemsFieldError[], validation: FormikErrors<CdsImportDeclarationPayload>) => {
    const products = get(validation, 'goodsShipment.governmentAgencyGoodsItem');

    if (!Array.isArray(products)) return;

    products?.forEach((itemErrors: any, index: number) => {
        const item = { index: index + 1, errors: [] };

        parseFieldErrorsForMultipleCard(
            'Commodity Code - Combined Nomenclature code',
            'commodity.classification',
            [{ id: 'Commodity Code' }, { identificationTypeCode: 'Type Code' }],
            item.errors,
            itemErrors
        );

        parseFieldErrorsForCard(
            'Goods Information',
            [
                { 'commodity.description': 'Description of Goods' },
                { 'ucr.traderAssignedReferenceID': 'UCR' },
                { 'destination.countryCode': 'Country of destination code' },
                { 'goodsShipment.exportCountry.id': 'Country of dispatch / export code' },
                { 'commodity.goodsMeasure.netNetWeightMeasure': 'Net Mass' },
                { 'commodity.goodsMeasure.tariffQuantity': 'Supplementary Units' },
                { 'commodity.goodsMeasure.grossMassMeasure': 'Gross Mass' },
                { statisticalValue: 'Statistical Value - Amount' },
                { statisticalValuesCurrencyId: 'Statistical Value - Currency' },
            ],
            item.errors,
            itemErrors
        );

        parseFieldErrorsForCard(
            'Procedure',
            [
                { 'governmentProcedure.currentCode': 'Procedure Code' },
                { 'governmentProcedure.previousCode': 'Previous Procedure Code' },
                { 'governmentProcedure.additionalCurrentCode': 'Additional Procedure Code' },
            ],
            item.errors,
            itemErrors
        );

        parseFieldErrorsForMultipleCard(
            'Additional Information',
            'additionalInformation',
            [
                { statementCode: 'Additional Information Code' },
                { statementDescription: 'Additional Information Description' },
            ],
            item.errors,
            itemErrors
        );

        parseFieldErrorsForMultipleCard(
            'Additional Document',
            'additionalDocument',
            [
                { categoryCode: 'Documents Produced - Document Category Code' },
                { typeCode: 'Documents Produced - Document Type Code' },
                { id: 'Documents Produced - Document Identifier' },
                { lpcoExemptionCode: 'Documents Produced - Document Status' },
                { name: 'Documents Produced - Document Status Reason' },
            ],
            item.errors,
            itemErrors
        );

        parseFieldErrorsForCard(
            'Nature of Transaction',
            [{ transactionNatureCode: 'Nature of Transaction' }],
            item.errors,
            itemErrors
        );

        parseFieldErrorsForMultipleCard(
            'Packages',
            'packaging',
            [
                { typeCode: 'Type of packages' },
                { quantityQuantity: 'Number of Packages' },
                { marksNumbersID: 'Shipping Marks' },
            ],
            item.errors,
            itemErrors
        );

        parseFieldErrorsForMultipleCard(
            'Previous Documents',
            'previousDocument',
            [
                { categoryCode: 'Previous Document Category' },
                { typeCode: 'Previous Document Type' },
                { id: 'Previous Document Reference' },
                { lineNumeric: 'Goods Item Identifier' },
            ],
            item.errors,
            itemErrors
        );
        parseFieldErrorsForMultipleCard(
            'Additional supply chain actor(s) Ident. No.',
            'aeoMutualRecognitionParty',
            [{ id: 'Identification Number' }, { roleCode: 'Role Code' }],
            item.errors,
            itemErrors
        );
        parseFieldErrorsForMultipleCard(
            'Additional Fiscal References Identification',
            'domesticDutyTaxParty',
            [{ id: 'VAT Identification Number' }, { roleCode: 'Role Code' }],
            item.errors,
            itemErrors
        );

        parseFieldErrorsForCard(
            'Country of Origin',
            [
                { 'origin.countryCode': 'Country of Origin - Country Code' },
                { 'origin.typeCode': 'Country of Origin - Type Code' },
                {
                    'goodsShipment.governmentAgencyGoodsItem.origin.countryCode':
                        'Country of Preferential Origin - Country Code',
                },
                {
                    'goodsShipment.governmentAgencyGoodsItem.origin.typeCode':
                        'Country of Preferential Origin - Type Code',
                },
            ],
            item.errors,
            itemErrors
        );

        parseFieldErrorsForMultipleCard(
            'Container Identification Number',
            'commodity.transportEquipment',
            [{ id: 'Container Identification Number' }],
            item.errors,
            itemErrors
        );

        parseFieldErrorsForCard(
            'Preference',
            [
                {
                    'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee.dutyRegimeCode': 'Preference Code',
                },
            ],
            item.errors,
            itemErrors
        );

        if (item.errors.length > 0) {
            errors.push(item);
        }
    });
};

export default parseValidationErrors;
