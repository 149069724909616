import {
    BorderTransportMeans,
    EnsGoodsShipmentItem,
    GoodsShipment,
    IrelandEntrySummaryDeclaration,
    Packaging,
} from 'store/declarations/ireland/entry-summary-declaration';
import {
    getCustomerValidation,
    getExactLengthValidation,
    getMaxValidation,
    getMinCharactersValidation,
    getRequiredMessage,
    Shape,
} from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';
import { additionalInformation, consignment, customOffice, issuer } from './irelandEnsValidation';

const borderTransportMeans = Yup.object().shape<Shape<BorderTransportMeans>>({
    identityCrossingBorder: getMaxValidation('identityCrossingBorder', 31).nullable(),
    nationalityCrossingBorder: getExactLengthValidation('nationalityCrossingBorder', 2).nullable(),
    conveyanceReferenceNumber: Yup.string().max(30).nullable(),
    transportMode: Yup.number().nullable(),
});

const packages = Yup.object().shape<Shape<Packaging>>({
    marks: getMaxValidation('marks', 140).nullable(),
    type: getExactLengthValidation('type', 2).nullable(),
    numberOfPackages: Yup.number().nullable(),
    numberOfPieces: Yup.number().nullable(),
});

export const goodsItems = Yup.object().shape<Shape<EnsGoodsShipmentItem>>({
    ucr: getMaxValidation('ucr', 35).nullable(),
    transportChargesMethodOfPayment: Yup.string().nullable(),
    itemNumber: getMaxValidation('itemNumber', 3).nullable().required(getRequiredMessage('itemNumber')),
    loadingPlace: getMaxValidation('loadingPlace', 35).nullable(),
    unloadingPlace: getMaxValidation('unloadingPlace', 35).nullable(),
    grossMass: Yup.number().nullable(),
    commodity: Yup.object().shape({
        description: Yup.string().max(50).nullable(),
        combinedNomenclature: getMinCharactersValidation('commodityCode', 8)
            .required(getRequiredMessage('combinedNomenclature'))
            .nullable(),

        dangerousCode: getExactLengthValidation('dangerousCode', 4).nullable(),
    }),
    consignor: getCustomerValidation().nullable(),
    consignee: getCustomerValidation().nullable(),
    notifyParty: getCustomerValidation().nullable(),
    goodsPackaging: Yup.array().of(packages).nullable(),
    borderTransportMeans: Yup.array().of(borderTransportMeans).nullable(),
    additionalInformation: Yup.array().of(additionalInformation).nullable(),
});

const goodsShipment = Yup.object().shape<Shape<GoodsShipment>>({
    ucr: getMaxValidation('ucr', 35).nullable(),
    firstEntryCustomsOffice: customOffice,
    entryCustomsOffice: Yup.array().of(customOffice),
    consignment: consignment,
    goodsItems: Yup.array().of(goodsItems),
});

const irelandEnsDraftValidation = Yup.object().shape<Shape<IrelandEntrySummaryDeclaration>>({
    issuing: issuer,
    numberOfItems: Yup.number().nullable(),
    totalNumberOfPackages: Yup.number().nullable(),
    totalGrossMass: Yup.number().nullable(),
    personLodgingTheSummaryDeclaration: getCustomerValidation().nullable(),
    goodsShipment: goodsShipment,
});

export default irelandEnsDraftValidation;
