import { DeleteOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import Button from 'components/ui/base/button';
import Table from 'components/ui/base/table/Table';
import { ListPayload } from 'core/http/response';
import { allRecordEquals } from 'core/utils/arrays';
import { dateStandardFormat } from 'core/utils/date';
import { enumToText } from 'core/utils/enum-to-text';
import { FC } from 'react';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { JobResponse } from 'store/jobs/job';
import {
    getDeclarantName,
    getDeclarationName,
    getDeclarationPayload,
    getExporterName,
    getImporterName,
} from '../../../declarations/utils/declaration-utils';
import DeclarationsOfJobTable from './DeclarationsOfJobTable';
import { Ellipse, EllipseText } from './JobsTable.styles';

export interface Props {
    data: ListPayload<JobResponse>;
    handlePagination?: Function;
    loading?: boolean;
    onDeleteDeclaration?: Function;
    onDeleteJob?: Function;
    declarationFromJobs: { [key: string]: Declaration[] };
    onJobDetails: (id: string) => void;
    onDeclarationDetails: (id: string) => void;
}

const JobsTable: FC<Props> = ({
    data,
    onDeleteDeclaration,
    handlePagination,
    onDeleteJob,
    declarationFromJobs,
    onJobDetails,
    onDeclarationDetails,
}) => {
    const getNumberOfDeclarationsOfJob = (jobId: string) => {
        if (declarationFromJobs[jobId]) {
            return declarationFromJobs[jobId].length;
        }
        return 0;
    };

    const getJobDeclarationType = (jobId: string) => {
        if (declarationFromJobs[jobId]) {
            const d = declarationFromJobs[jobId]
                .map((res) => {
                    const country = enumToText(res.declarationInternalType);
                    const name = getDeclarationName(res);
                    if (res.declarationExternalEntity === DeclarationExternalEntity.REVENUE) {
                        return `Ireland \n${country} (${name})`;
                    } else {
                        return `UK  \n${country} (${name})`;
                    }
                })
                .filter((res) => res !== '-');
            return allRecordEquals(d) ? <span style={{ whiteSpace: 'pre-wrap' }}>{d[0]}</span> : <span>Multiple</span>;
        }
        return <></>;
    };

    const getJobExporter = (jobId: string) => {
        if (declarationFromJobs[jobId]) {
            const d = declarationFromJobs[jobId]
                .map((res) => {
                    const payload = getDeclarationPayload(res);
                    return getExporterName(payload);
                })
                .filter((res) => res !== '-');
            return allRecordEquals(d) ? <span>{d[0] ?? '-'}</span> : <span>Multiple</span>;
        }
        return <></>;
    };

    const getJobDeclarant = (jobId: string) => {
        if (declarationFromJobs[jobId]) {
            const d = declarationFromJobs[jobId]
                .map((res) => {
                    const payload = getDeclarationPayload(res);
                    return getDeclarantName(payload);
                })
                .filter((res) => res !== '-');
            return allRecordEquals(d) ? <span>{d[0] ?? '-'}</span> : <span>Multiple</span>;
        }
        return <></>;
    };

    const getJobImporter = (jobId: string) => {
        if (declarationFromJobs[jobId]) {
            const d = declarationFromJobs[jobId]
                .map((res) => {
                    const payload = getDeclarationPayload(res);
                    return getImporterName(payload);
                })
                .filter((res) => res !== '-');
            return allRecordEquals(d) ? <span>{d[0] ?? '-'}</span> : <span>Multiple</span>;
        }
        return <></>;
    };

    /*
    const getJobDeclarationStatus = (jobId: string) => {
        if (declarationFromJobs[jobId]) {
            const d = declarationFromJobs[jobId].map((res) => res.status);
            return allRecordEquals(d) ? (
                <DeclarationStatusTag declarationStatus={d[0]}></DeclarationStatusTag>
            ) : (
                <DeclarationStatusTag multiple></DeclarationStatusTag>
            );
        }
        return <></>;
    }; */

    const columns = [
        {
            dataIndex: 'declarationLength',
            key: 'declarationLength',

            render: (text: string, record: JobResponse) => {
                const declarations = declarationFromJobs[record.id];
                return declarations ? (
                    <Ellipse>
                        <EllipseText>{getNumberOfDeclarationsOfJob(record.id)}</EllipseText>
                    </Ellipse>
                ) : (
                    <span>-</span>
                );
            },
        },

        {
            title: 'Job Id',
            dataIndex: 'referenceId',
            key: 'referenceId',

            render: (text: string, record: JobResponse) =>
                record.referenceId ? <span>{record.referenceId}</span> : <span>-</span>,
        },
        {
            title: 'Decl. Type',
            dataIndex: 'referenceId',
            key: 'referenceId',

            render: (text: string, record: JobResponse) => getJobDeclarationType(record.id),
        },

        {
            title: 'Job. Status',
            dataIndex: 'status',
            key: 'status',

            render: (text: string, record: JobResponse) => (record?.status ? record.status : '-'),
        },
        {
            title: 'Consignor',
            dataIndex: 'exporter',
            key: 'exporter',

            render: (text: string, record: JobResponse) => getJobExporter(record.id),
        },
        {
            title: 'Consignee',
            dataIndex: 'importer',
            key: 'importer',

            render: (text: string, record: JobResponse) => getJobImporter(record.id),
        },
        {
            title: 'Declarant',
            dataIndex: 'declarant',
            key: 'declarant',

            render: (text: string, record: JobResponse) => getJobDeclarant(record.id),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',

            render: (text: string, record: JobResponse) =>
                record.createdAt ? dateStandardFormat(new Date(record?.createdAt)) : <span>-</span>,
        },
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            render: (element: string, record: JobResponse) => {
                return (
                    <>
                        <Button
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                return onDeleteJob && onDeleteJob(record.id);
                            }}
                        >
                            Delete <DeleteOutlined />
                        </Button>
                    </>
                );
            },
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={data.list}
            columns={columns}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        event.stopPropagation();
                        onJobDetails(record.id);
                    },
                };
            }}
            expandable={{
                expandIconColumnIndex: 9,
                expandedRowRender: (record: JobResponse) => (
                    <div>
                        <DeclarationsOfJobTable
                            job={record}
                            declarationFromJobs={declarationFromJobs}
                            onDeclarationDetails={onDeclarationDetails}
                            onDelete={onDeleteDeclaration}
                        />
                    </div>
                ),
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                        <UpOutlined
                            style={{ fontSize: '22px' }}
                            onClick={(e) => {
                                e.stopPropagation();

                                return onExpand(record, e);
                            }}
                        />
                    ) : (
                        <DownOutlined
                            style={{ fontSize: '22px' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                return onExpand(record, e);
                            }}
                        />
                    ),
            }}
            pagination={{
                current: data.pageNumber + 1,
                total: data.total,
                pageSize: data.pageSize,
                position: ['bottomCenter'],
                onChange: (page: number | undefined, size: number | undefined) => {
                    if (page && handlePagination) {
                        const params = { page: page - 1, size };
                        handlePagination(params);
                    }
                },
            }}
        />
    );
};
export default JobsTable;
