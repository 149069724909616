import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const ProductInfoCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    if (props.getFieldHelpers === undefined) {
        return <></>;
    }

    return (
        <NewFormCard title={t('goodsItems.title')}>
            <FormCardContainer>
                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`ucr`, props)}
                    refNumber="2.4"
                    label={t('ucr')}
                    condensed
                />

                <DeclarationNumberInput
                    maxLength={4}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('commodity.dangerousCode', props)}
                    label={t('dangerousGoodsCode')}
                    condensed
                />

                <FormInput
                    maxLength={280}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`commodity.description`, props)}
                    label={t('textualDescription')}
                    tooltip={t('textualDescription')}
                    condensed
                />

                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`itemNumber`, props)}
                    refNumber="6.5"
                    label={t('itemNumber')}
                    condensed
                />

                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`transportChargesMethodOfPayment`, props)}
                    label={t('transportChargesMethodOfPayment')}
                    refNumber="A"
                    selectOptions={[
                        {
                            id: 'A',
                            value: 'Payment in cash',
                        },
                        {
                            id: 'B',
                            value: 'Payment by credit card',
                        },
                        {
                            id: 'C',
                            value: 'Payment by cheque',
                        },
                        {
                            id: 'D',
                            value: 'Other (e.g. direct debit to cash account)',
                        },
                        {
                            id: 'H',
                            value: 'Electronic credit transfer',
                        },
                        {
                            id: 'Y',
                            value: 'Account holder with carrier',
                        },
                        {
                            id: 'Z',
                            value: 'Not pre-paid',
                        },
                    ]}
                    condensed
                />

                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`loadingPlace`, props)}
                    label={t('loadingPlace')}
                    tooltip={t('defaultTooltip')}
                    condensed
                />

                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`unloadingPlace`, props)}
                    label={t('unloadingPlace')}
                    tooltip={t('defaultTooltip')}
                    condensed
                />

                <DeclarationNumberInput
                    integerNumbers
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`grossMass`, props)}
                    refNumber="6.5"
                    label={t('grossMass')}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default ProductInfoCard;
