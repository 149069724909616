import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { containerOption } from 'store/declarations/enums/uk/container-option';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'containerCode';

export const containerBlockValidation: {
    childValidators: {
        'goodsShipment.consignment': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.consignment': {
            childValidators: {
                containerCode: [validators.required(), validators.number(), validators.exact(1)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const ContainerBlock = ({ path = 'goodsShipment.consignment', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '7/2', []);
    return (
        <DeclarationField pathPrefix={path} name="containerCode">
            {({ form, field }: FieldProps<any>) => (
                <FormSelect
                    required
                    refNumber={ucc}
                    label={t('container')}
                    {...getFormikProps(field.name, form)}
                    selectOptions={containerOption}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default ContainerBlock;
