import { PaginatedParams } from 'core/http/pagination';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { GoodsShipmentItem as EtdGoodsShipmentItem } from 'store/declarations/ireland/electronic-transport-document';
import { GoodsShipment as TsdGoodsShipmentItem } from 'store/declarations/ireland/temporary-storage-declaration';
import { EnsGoodsShipmentItem } from 'store/declarations/ireland/entry-summary-declaration';
import { RevenueExportCustomsGoodsItem } from 'store/declarations/ireland/export-declaration';
import { GoodsShipmentItem as H7GoodsShipmentItem } from 'store/declarations/ireland/h7-import-declaration';
import { GoodsShipmentItem as H1GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import { GovernmentAgencyGoodsItem } from 'store/declarations/uk/goods-item';
import {
    doClearError,
    doCreateIrelandEnsProduct,
    doCreateIrelandEtdProduct,
    doCreateIrelandTsdProduct,
    doCreateIrelandExportProduct,
    doCreateIrelandH7ImportProduct,
    doCreateIrelandImportProduct,
    doCreateUkImportProduct,
    doDeleteIrelandEnsProduct,
    doDeleteIrelandExportProduct,
    doDeleteIrelandH7ImportProduct,
    doDeleteIrelandImportProduct,
    doDeleteUkImportProduct,
    doGetIrelandEnsProduct,
    doGetIrelandEtdProduct,
    doGetIrelandExportProduct,
    doGetIrelandH1Product,
    doGetIrelandH7Product,
    doGetUkExportProduct,
    doGetUkImportProduct,
    doListIrelandEnsProducts,
    doListIrelandEtdProducts,
    doListIrelandTsdProducts,
    doListIrelandExportProducts,
    doListIrelandH1Products,
    doListIrelandH7Products,
    doListUkExportProducts,
    doListUkImportProducts,
    doUpdateIrelandEnsProduct,
    doUpdateIrelandEtdProduct,
    doUpdateIrelandTsdProduct,
    doUpdateIrelandExportProduct,
    doUpdateIrelandH7ImportProduct,
    doUpdateIrelandImportProduct,
    doUpdateUkImportProduct,
    doDeleteIrelandTsdProduct,
    doGetIrelandTsdProduct,
    doDeleteUkExportProduct,
    doUpdateUkExportProduct,
    doCreateUkExportProduct,
} from 'store/products/action';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import useResponseNotification from 'hooks/useResponseNotification';
import { useTranslation } from 'react-i18next';
import { createUpdateProductNotifs } from '../utils/notificationMessages';
import { CdsExportGovernmentAgencyGoodsItem } from 'store/declarations/uk/export-declaration';
import { CdsExportGovernmentAgencyGoodsItemPayload } from 'store/products/reducer';

interface UseProductsProps {
    productId?: string;
    declarationType?: DeclarationName;
}

export type CreateProductFunction = (product: any, declarationId: string) => Promise<any>;
export type UpdateProductFunction = (product: any, declarationId: string, productId: string) => Promise<any>;
export type DeleteProductFunction = (productId: any, declarationId: string) => Promise<any>;
export type ListProductFunction = (declarationId: string, params: Partial<PaginatedParams>) => Promise<any>;

const object = {} as Record<any, any>;

const useProducts = ({ productId, declarationType }: UseProductsProps = {}) => {
    const isLoading = useAppSelector((state) => state.products.isLoading);
    const error = useAppSelector((state) => state.products.error);
    const products = useAppSelector((state) => state.products.products);
    const product = useAppSelector((state) => (productId ? state.products.entities[productId] : object));
    const { withResponseNotifications } = useResponseNotification();
    const { t } = useTranslation('common');

    const dispatch = useAppDispatch();

    const createIrelandImportProduct = async (product: H1GoodsShipmentItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateIrelandImportProduct(product, declarationId)),
            createUpdateProductNotifs(t)
        );

    const createIrelandEtdProduct = async (product: EtdGoodsShipmentItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateIrelandEtdProduct(product, declarationId)),
            createUpdateProductNotifs(t)
        );

    const createIrelandTsdProduct = async (product: TsdGoodsShipmentItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateIrelandTsdProduct(product, declarationId)),
            createUpdateProductNotifs(t)
        );

    const updateIrelandTsdProduct = async (product: TsdGoodsShipmentItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doUpdateIrelandTsdProduct(product, declarationId)),
            createUpdateProductNotifs(t)
        );

    const deleteIrelandTsdProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteIrelandTsdProduct(productId, declarationId));
    };

    const listIrelandTsdProducts = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListIrelandTsdProducts(declarationId, params));
    };

    const getIrelandTsdProduct = (productId: string, declarationId: string) => {
        return dispatch(doGetIrelandTsdProduct(productId, declarationId));
    };

    const updateIrelandImportProduct = async (product: H1GoodsShipmentItem, declarationId: string, productId: string) =>
        withResponseNotifications(
            await dispatch(doUpdateIrelandImportProduct(product, declarationId, productId)),
            createUpdateProductNotifs(t)
        );

    const deleteIrelandImportProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteIrelandImportProduct(productId, declarationId));
    };

    const createIrelandH7ImportProduct = async (product: H7GoodsShipmentItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateIrelandH7ImportProduct(product, declarationId)),
            createUpdateProductNotifs(t)
        );

    const updateIrelandH7ImportProduct = async (
        product: H7GoodsShipmentItem,
        declarationId: string,
        productId: string
    ) =>
        withResponseNotifications(
            await dispatch(doUpdateIrelandH7ImportProduct(product, declarationId, productId)),
            createUpdateProductNotifs(t)
        );

    const deleteIrelandH7ImportProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteIrelandH7ImportProduct(productId, declarationId));
    };

    const createIrelandExportProduct = async (product: RevenueExportCustomsGoodsItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateIrelandExportProduct(product, declarationId)),
            createUpdateProductNotifs(t)
        );

    const updateIrelandExportProduct = async (
        product: RevenueExportCustomsGoodsItem,
        declarationId: string,
        productId: string
    ) =>
        withResponseNotifications(
            await dispatch(doUpdateIrelandExportProduct(product, declarationId, productId)),
            createUpdateProductNotifs(t)
        );

    const deleteIrelandExportProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteIrelandExportProduct(productId, declarationId));
    };

    const createUkImportProduct = async (product: GovernmentAgencyGoodsItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateUkImportProduct(product, declarationId)),
            createUpdateProductNotifs(t)
        );

    const updateUkImportProduct = async (
        product: GovernmentAgencyGoodsItem,
        declarationId: string,
        productId: string
    ) =>
        withResponseNotifications(
            await dispatch(doUpdateUkImportProduct(product, declarationId, productId)),
            createUpdateProductNotifs(t)
        );

    const deleteUkImportProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteUkImportProduct(productId, declarationId));
    };

    const createIrelandEnsProduct = async (product: EnsGoodsShipmentItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateIrelandEnsProduct(product, declarationId)),
            createUpdateProductNotifs(t)
        );

    const updateIrelandEnsProduct = async (product: EnsGoodsShipmentItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doUpdateIrelandEnsProduct(product, declarationId)),
            createUpdateProductNotifs(t)
        );

    const deleteIrelandEnsProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteIrelandEnsProduct(productId, declarationId));
    };

    const getIrelandEnsProduct = (productId: string, declarationId: string) => {
        return dispatch(doGetIrelandEnsProduct(productId, declarationId));
    };

    const listIrelandEnsProducts = (declarationId: string) => {
        return dispatch(doListIrelandEnsProducts(declarationId));
    };

    const listIrelandH1Products = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListIrelandH1Products(declarationId, params));
    };

    const listIrelandExportProducts = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListIrelandExportProducts(declarationId));
    };

    const getIrelandH1Product = (productId: string, declarationId: string) => {
        return dispatch(doGetIrelandH1Product(productId, declarationId));
    };

    const listIrelandH7Products = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListIrelandH7Products(declarationId));
    };

    const listIrelandEtdProducts = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListIrelandEtdProducts(declarationId));
    };

    const getIrelandH7Product = (productId: string, declarationId: string) => {
        return dispatch(doGetIrelandH7Product(productId, declarationId));
    };

    const getIrelandExportProduct = (productId: string, declarationId: string) => {
        return dispatch(doGetIrelandExportProduct(productId, declarationId));
    };

    const listUkImportProducts = (declarationId: string) => {
        return dispatch(doListUkImportProducts(declarationId));
    };

    const listUkExportProducts = (declarationId: string) => {
        return dispatch(doListUkExportProducts(declarationId));
    };

    const getUkImportProduct = (declarationId: string, productId: string) => {
        return dispatch(doGetUkImportProduct(declarationId, productId));
    };

    const getUkExportProduct = (declarationId: string, productId: string) => {
        return dispatch(doGetUkExportProduct(declarationId, productId));
    };

    const createUkExportProduct = async (product: CdsExportGovernmentAgencyGoodsItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateUkExportProduct(product, declarationId)),
            createUpdateProductNotifs(t)
        );

    const updateUkExportProduct = async (
        product: CdsExportGovernmentAgencyGoodsItemPayload,
        declarationId: string,
        productId: string,
        withNotification: boolean = true
    ) => {
        if (withNotification) {
            return withResponseNotifications(
                await dispatch(doUpdateUkExportProduct(product, declarationId, productId)),
                createUpdateProductNotifs(t)
            );
        } else {
            return await dispatch(doUpdateUkExportProduct(product, declarationId, productId));
        }
    };

    const deleteUkExportProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteUkExportProduct(productId, declarationId));
    };

    const updateIrelandEtdProduct = async (product: EtdGoodsShipmentItem, declarationId: string, productId: string) =>
        withResponseNotifications(
            await dispatch(doUpdateIrelandEtdProduct(product, declarationId, productId)),
            createUpdateProductNotifs(t)
        );

    const getIrelandEtdProduct = (declarationId: string, productId: string) => {
        return dispatch(doGetIrelandEtdProduct(declarationId, productId));
    };

    const clearError = () => {
        return dispatch(doClearError());
    };

    return {
        isLoading,
        error,
        products,
        product,
        createIrelandImportProduct,
        updateIrelandImportProduct,
        deleteIrelandImportProduct,
        createIrelandH7ImportProduct,
        updateIrelandH7ImportProduct,
        deleteIrelandH7ImportProduct,
        createIrelandExportProduct,
        updateIrelandExportProduct,
        deleteIrelandExportProduct,
        createUkImportProduct,
        updateUkImportProduct,
        deleteUkImportProduct,
        createIrelandEnsProduct,
        updateIrelandEnsProduct,
        deleteIrelandEnsProduct,
        getIrelandEnsProduct,
        listIrelandH1Products,
        getIrelandH1Product,
        listIrelandH7Products,
        getIrelandH7Product,
        clearError,
        listIrelandExportProducts,
        getIrelandExportProduct,
        listUkImportProducts,
        listUkExportProducts,
        getUkExportProduct,
        getUkImportProduct,
        createIrelandEtdProduct,
        updateIrelandEtdProduct,
        listIrelandEtdProducts,
        getIrelandEtdProduct,
        listIrelandEnsProducts,
        createIrelandTsdProduct,
        updateIrelandTsdProduct,
        deleteIrelandTsdProduct,
        getIrelandTsdProduct,
        listIrelandTsdProducts,
        createUkExportProduct,
        updateUkExportProduct,
        deleteUkExportProduct,
    };
};

export default useProducts;
