import { ProductTemplate } from '../../../store/products-templates/products';
import { removeEmptyObjectsFromDeclarationArrays } from '../../declarations/utils/form-utils';

export const handleAddDeclarationProductToProductTemplates = async (
    id: string,
    products: any,
    saveProductTemplateFunction: Function | undefined,
    listProductTemplateFunction: Function | undefined,
    declarationTemplateId: string | undefined,
    isCds: boolean | undefined
) => {
    if (!(products?.total && saveProductTemplateFunction && listProductTemplateFunction))
        throw new Error('No products');

    const declarationProducts = [...products?.list];
    let productToAddToTemplate = declarationProducts.find((product) => product.id === id);

    if (!productToAddToTemplate) throw new Error('No product with such id found');

    productToAddToTemplate = removeEmptyObjectsFromDeclarationArrays({ ...productToAddToTemplate });

    if (isCds) {
        productToAddToTemplate = {
            template: productToAddToTemplate.governmentAgencyGoodsItem,
        };
    }

    const productTemplate: ProductTemplate = {
        ...productToAddToTemplate,
        declarationTemplateId,
        tags: [],
    };

    await saveProductTemplateFunction(productTemplate);
    listProductTemplateFunction();
};

export const transformCdsExportProductTemplate = (productTemplate: any, transformFunctionForServer?: Function) => {
    const { tags } = productTemplate;
    delete productTemplate.tags;

    let productTemplateValuesForServer = {};
    const declarationProductOrProductTemplate = productTemplate.governmentAgencyGoodsItem ?? productTemplate;

    if (transformFunctionForServer) {
        productTemplateValuesForServer = { template: transformFunctionForServer(declarationProductOrProductTemplate) };
    } else {
        productTemplateValuesForServer = { template: declarationProductOrProductTemplate };
    }

    return { ...productTemplateValuesForServer, tags };
};
