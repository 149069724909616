import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useMemo } from 'react';
import styled from 'styled-components';
import { MetaData, useTemplateContext } from './TemplateContext';

interface Props {
    fieldPath: string | undefined;
    disabled?: boolean;
    onMakeInvisible?: (name: string | undefined) => void;
    specialName?: string;
    onChange?: (value: any) => void;
    required?: boolean;
}

const TemplateCheckboxes = ({ fieldPath, disabled, onMakeInvisible, specialName, onChange, required }: Props) => {
    const { templateFormik, form } = useTemplateContext();

    const _fieldPath = useMemo(() => {
        // This is to handle fields that have multiple values
        if (fieldPath?.match(/\.[^.]*,.*/g)) return fieldPath?.replace(/\.[^.]*,.*/g, '');
        return fieldPath;
    }, [fieldPath]);

    const removeDefaultValue = () => {
        if (onChange) {
            onChange(null);
        } else if (templateFormik?.getFieldProps(`${form}.defaults.${_fieldPath}`).value) {
            templateFormik?.setFieldValue(`${form}.defaults.${_fieldPath}`, null);
        }
    };

    const metaPath = useMemo(() => `${form}.meta.['${specialName ?? _fieldPath}']`, [_fieldPath, form, specialName]);
    const checked = useMemo<keyof MetaData | null>(() => {
        const meta = templateFormik?.getFieldMeta(metaPath).value as MetaData | undefined;
        if (meta?.isViewable) {
            return 'isViewable';
        } else if (meta?.isEditable || !meta) {
            return 'isEditable';
        } else {
            return null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateFormik?.getFieldMeta(metaPath).value]);

    const handleChange = (e: CheckboxChangeEvent) => {
        const checkboxType = e.target.name as keyof MetaData;
        const meta = templateFormik?.getFieldMeta(metaPath).value as MetaData | undefined;
        let _meta: MetaData = { ...meta } ?? { isViewable: false, isEditable: false };

        let metaCopy = { ..._meta };

        _meta.isViewable = false;
        _meta.isEditable = false;

        const firstCheckIsEditable = checkboxType === 'isEditable' && !meta;
        const wasChecked = metaCopy[checkboxType];

        if (required || (!firstCheckIsEditable && !wasChecked)) {
            _meta[checkboxType] = !_meta[checkboxType];
        }

        if (_meta.isEditable === meta?.isEditable && _meta.isViewable === meta?.isViewable) return;

        if (!_meta.isEditable && !_meta.isViewable) {
            onMakeInvisible?.(_fieldPath);
            removeDefaultValue();
        }

        templateFormik?.setFieldValue(metaPath, { ..._meta });
    };

    return (
        <>
            <TemplateCheckbox
                disabled={disabled}
                name="isViewable"
                onChange={handleChange}
                checked={checked === 'isViewable'}
            />
            <TemplateCheckbox
                disabled={disabled}
                name="isEditable"
                onChange={handleChange}
                checked={checked === 'isEditable'}
            />
        </>
    );
};

enum Size {
    small = 'small',
    medium = 'medium',
    large = 'large',
}

const TemplateCheckbox = styled(Checkbox)<{ size?: keyof typeof Size | Size }>`
    --size: ${({ size }) => (size === Size.small ? '16px' : size === Size.medium ? '24px' : '32px')};
    --tick-size: calc(var(--size) / 2);

    width: min-content;

    display: flex;
    align-items: center;
    margin-top: -6px;

    & + & {
        margin-left: 0;
    }

    .ant-checkbox {
        border-radius: 4px;
    }

    .ant-checkbox .ant-checkbox-checked:hover {
        border-radius: 4px;
    }

    :hover .ant-checkbox-inner::after {
        border: 2px solid #1f92fb;
        border-top: 0;
        border-left: 0;
    }

    :hover .ant-checkbox-disabled .ant-checkbox-inner::after {
        border: 2px solid #cecece;
        border-top: 0;
        border-left: 0;
    }
    :hover .ant-checkbox-checked .ant-checkbox-inner::after {
        border: 2px solid white;
        border-top: 0;
        border-left: 0;
    }

    .ant-checkbox-inner {
        height: var(--size);
        width: var(--size);
        position: relative;
        border-radius: 4px;
    }

    .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
        background-color: rgba(0, 64, 228, 0.2);
        border-radius: 4px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #0040e4;
        border-radius: 4px;
    }

    .ant-checkbox-inner::after {
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%) rotate(45deg) scale(1);
        height: var(--tick-size);
        width: calc(var(--tick-size) / 1.8);
        border: 2px solid #cecece;
        border-top: 0;
        border-left: 0;
        opacity: 1;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
        border: 2px solid white;
        border-top: 0;
        border-left: 0;
    }

    .ant-checkbox-checked::after {
        border-radius: 4px;
    }
`;

export default TemplateCheckbox;

export { TemplateCheckboxes };
