import { BackToList, CodeDiv, MessageDiv, ShowDetailsDiv, StyledErrorRow } from '../DeclarationErrorsDrawer.styles';
import { Text } from 'components/ui/base/typography';
import { IrelandExportNotification } from 'store/declarations/ireland/ireland-export-notification';
import RevenueExportErrorDetails from '../revenue-errors/RevenueExportErrorDetails';

type SubmissionErrors = IrelandExportNotification['submissionErrors'];

const IrelandExportDeclarationErrors = ({
    errors,
    formik,
    isDetailed = undefined,
    selected,
    handleSelected,
}: SpecificDeclarationErrorsProps<SubmissionErrors>) => {
    return !selected || !isDetailed ? (
        <>
            {errors?.map((error, i) => (
                <StyledErrorRow justify="space-between" key={`error-row-${i}`}>
                    <CodeDiv>
                        <Text>{`${error?.errorCode}:` ?? '-'}</Text>
                    </CodeDiv>
                    <MessageDiv>
                        <Text>{error?.errorText ?? '-'}</Text>
                    </MessageDiv>
                    <ShowDetailsDiv>
                        <span onClick={() => handleSelected?.(error)}>Show details</span>
                    </ShowDetailsDiv>
                </StyledErrorRow>
            ))}
        </>
    ) : (
        <>
            <BackToList onClick={() => handleSelected?.(undefined)}>Back to list</BackToList>
            <RevenueExportErrorDetails details={selected} formik={formik} />
        </>
    );
};

export default IrelandExportDeclarationErrors;
