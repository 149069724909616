import { Col, Divider, Row } from 'antd';
import Button from 'components/ui/base/button';
import Drawer from 'components/ui/base/drawer/Drawer';
import CustomModal from 'components/ui/base/modal/Modal';
import Notification from 'components/ui/base/notification/Notification';
import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useJourneys from 'hooks/useJourneys';
import useSession from 'hooks/useSession';
import debounce from 'lodash/debounce';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeclarationCustomer } from 'store/declarations/common/declaration-customer';
import { JobResponse } from 'store/jobs/job';
import { Journey } from 'store/journeys/journeys';
import { ReverseCol } from 'views/dashboard/components/Dashboard.styles';
import CreateJourney from './components/CreateJourney';
import JourneysTable from './components/JourneysTable';

interface Props {
    job: JobResponse;
    exporters?: DeclarationCustomer[];
    importers?: DeclarationCustomer[];
}

const CargoJourneys: FC<Props> = ({ job, exporters, importers }) => {
    const { listJourneys, journeys, isLoading, getJourney, deleteJourney, error } = useJourneys();
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const { isDriverUser } = useSession();
    const [journey, setJourney] = useState<Journey>();
    const [showJourneyDrawer, setShowJourneyDrawer] = useState(false);
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const [showDeleteDrawer, setShowDeleteDrawer] = useState(false);
    const navigate = useNavigate();
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>();

    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Cargo Journeys',
                path: '',
            },
        ]);
        listJourneys({ jobId: job.id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (paginator) {
            listJourneys({ ...paginator, jobId: job.id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator]);

    const handleAddNewJourney = () => {
        setShowJourneyDrawer(true);
        setJourney({} as Journey);
    };

    const handleEditJourney = async (id: String) => {
        const response = await getJourney(id);
        if (response?.id) {
            setJourney(response);
        }
        setShowJourneyDrawer(true);
    };

    const handleSort = () => {};

    const handlePagination = (params: Partial<PaginatedParams>) => {
        setPaginator({ page: params.page, size: paginator?.size ?? defaultPagination.size });
    };

    const handleDetails = (id: string) => {
        navigate(`/cargo-journeys/${id}`);
    };

    const handleDelete = (ids: string[]) => {
        setDeleteIds(ids);
        setShowDeleteDrawer(true);
    };

    const handleCreateError = () => {
        //TODO error
    };

    const handleEditError = () => {
        //TODO error
    };

    const querySearch = async (query: string) => {
        await listJourneys({ jobId: job.id, query: query ?? {} });
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    const clearJourneyData = () => {
        setJourney(undefined);
        setShowJourneyDrawer(false);
    };

    const handleAddNewJourneySuccess = () => {
        setShowJourneyDrawer(false);
        listJourneys({ jobId: job.id });
    };
    return (
        <>
            <>
                <Row style={{ marginTop: '1.9rem' }}>
                    <Col span={18}>
                        <H5>Cargo Journey</H5>
                    </Col>
                    {!isDriverUser && (
                        <ReverseCol span={6}>
                            <Button size="large" type="primary" onClick={handleAddNewJourney}>
                                Add New Journey
                            </Button>
                        </ReverseCol>
                    )}
                </Row>
                <Divider />
                <SearchBar
                    onSearch={(value) => debouncedSearch(value)}
                    inputPlaceholder="Search by reference, status, type"
                    onClear={() => {
                        listJourneys({ jobId: job.id });
                    }}
                />
                <JourneysTable
                    handleSort={handleSort}
                    handlePagination={handlePagination}
                    onEdit={handleEditJourney}
                    onDetails={handleDetails}
                    onDelete={handleDelete}
                    data={journeys}
                    loading={isLoading}
                />
            </>
            <Drawer
                title={!journey || !journey?.id ? 'Add New Journey' : 'Edit Journey'}
                width={627}
                visible={showJourneyDrawer}
                onClose={clearJourneyData}
            >
                <CreateJourney
                    job={job}
                    closeDrawer={clearJourneyData}
                    handleOk={handleAddNewJourneySuccess}
                    journey={journey}
                    exporters={exporters}
                    importers={importers}
                    handleCreateError={handleCreateError}
                    handleEditError={handleEditError}
                />
            </Drawer>
            <CustomModal
                title={
                    deleteIds.length > 1 ? (
                        <H5>Do you want to remove these journeys?</H5>
                    ) : (
                        <H5>Do you want to remove this journey?</H5>
                    )
                }
                centered
                visible={showDeleteDrawer}
                onOk={async () => {
                    const toDelete: string[] = [...deleteIds];

                    for (let i = 0; i < toDelete.length; i++) {
                        await deleteJourney(toDelete[i]);
                    }

                    if (!error) {
                        Notification({
                            type: 'success',
                            messageTitle: 'Journey Deleted',
                            description: 'Journey has been successfully deleted!',
                        });
                    }
                    listJourneys({ jobId: job?.id });
                    setShowDeleteDrawer(false);
                }}
                onCancel={() => setShowDeleteDrawer(false)}
                width={762}
                contentText={
                    deleteIds.length > 1
                        ? 'If you remove all these journeys, you will lose all the information associated to them.'
                        : 'If you remove this journey, you will lose all the associated information.'
                }
            />
        </>
    );
};
export default CargoJourneys;
