import { Row } from 'antd';
import Checkbox from 'components/ui/base/checkbox/Checkbox';
import IconTooltip from 'components/ui/base/tooltip/IconTooltip';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import React, { FC, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInfoCircleFilled } from 'views/declarations/Form.styles';
import { useTemplateContext } from '../../template/TemplateContext';
import { ErrorDiv, ErrorLabel, FlexDiv, LabelDiv, RefDiv } from '../formInput/DeclarationInput.styles';
import { InputLabel, InputLabelRequired, ValueText } from './FormValuationInficator.styles';

export interface FormValuationIndicatorProps {
    label?: string;
    tooltip?: string;
    error?: string;
    refNumber?: string;
    children?: ReactNode;
    formik?: any;
    formikProps1: {
        fieldProps: FieldInputProps<any>;
        fieldMeta?: FieldMetaProps<any>;
        fieldHelper?: FieldHelperProps<any>;
    };
    formikProps2: {
        fieldProps: FieldInputProps<any>;
        fieldMeta?: FieldMetaProps<any>;
        fieldHelper?: FieldHelperProps<any>;
    };
    formikProps3: {
        fieldProps: FieldInputProps<any>;
        fieldMeta?: FieldMetaProps<any>;
        fieldHelper?: FieldHelperProps<any>;
    };
    formikProps4: {
        fieldProps: FieldInputProps<any>;
        fieldMeta?: FieldMetaProps<any>;
        fieldHelper?: FieldHelperProps<any>;
    };
    viewOnly?: boolean;
    required?: boolean;
    refClicked?: (refNumber: string | number) => void;
    condensed?: boolean;
}

const FormValuationIndicator: FC<FormValuationIndicatorProps> = ({
    label,
    refNumber,
    tooltip,
    error,
    formikProps1,
    formikProps2,
    formikProps3,
    formikProps4,
    viewOnly,
    required,
    refClicked,
    condensed,
}) => {
    const { t } = useTranslation('common');
    const { t: formTranslation } = useTranslation('form');
    const { isViewOnly: isTemplateViewOnly } = useTemplateContext();

    const handleRefClick = useCallback(() => {
        if (refClicked) {
            refNumber ? refClicked(refNumber) : refClicked(label ?? '');
        }
    }, [refClicked, refNumber, label]);

    return (
        <>
            <FlexDiv style={{ marginTop: '1rem' }}>
                <LabelDiv>
                    {label && <InputLabel>{label}</InputLabel>}
                    {required && <InputLabelRequired>{condensed ? '*' : t('mandatory')}</InputLabelRequired>}
                    {refNumber && <RefDiv>{refNumber}</RefDiv>}
                    {
                        <IconTooltip
                            title={label}
                            tooltip={tooltip ?? t('defaultTooltip')}
                            icon={<StyledInfoCircleFilled />}
                            tooltipClicked={handleRefClick}
                        />
                    }
                </LabelDiv>
            </FlexDiv>
            <Row style={{ marginTop: '1rem' }}>
                <ValueText>
                    {`${formTranslation('valuationIndicator')} 1 `}
                    <Checkbox
                        disabled={viewOnly || isTemplateViewOnly}
                        checked={formikProps1.fieldProps.value}
                        onChange={(e: any) => {
                            formikProps1.fieldProps.onChange({
                                target: {
                                    value: e.target.checked,
                                    name: formikProps1.fieldProps.name,
                                },
                            });
                        }}
                    />
                </ValueText>

                <ValueText>
                    {`${formTranslation('valuationIndicator')} 2 `}
                    <Checkbox
                        disabled={viewOnly || isTemplateViewOnly}
                        checked={formikProps2.fieldProps.value}
                        onChange={(e: any) => {
                            formikProps2.fieldProps.onChange({
                                target: {
                                    value: e.target.checked,
                                    name: formikProps2.fieldProps.name,
                                },
                            });
                        }}
                    />
                </ValueText>

                <ValueText>
                    {`${formTranslation('valuationIndicator')} 3 `}
                    <Checkbox
                        checked={formikProps3.fieldProps.value}
                        disabled={viewOnly || isTemplateViewOnly}
                        onChange={(e: any) => {
                            formikProps3.fieldProps.onChange({
                                target: {
                                    value: e.target.checked,
                                    name: formikProps3.fieldProps.name,
                                },
                            });
                        }}
                    />
                </ValueText>

                <ValueText>
                    {`${formTranslation('valuationIndicator')} 4 `}
                    <Checkbox
                        disabled={viewOnly || isTemplateViewOnly}
                        checked={formikProps4.fieldProps.value}
                        onChange={(e: any) => {
                            formikProps4.fieldProps.onChange({
                                target: {
                                    value: e.target.checked,
                                    name: formikProps4.fieldProps.name,
                                },
                            });
                        }}
                    />
                </ValueText>
            </Row>
            <ErrorDiv
                error={!!error}
                touched={
                    !!formikProps1.fieldMeta?.touched ||
                    !!formikProps2.fieldMeta?.touched ||
                    !!formikProps3.fieldMeta?.touched ||
                    !!formikProps4.fieldMeta?.touched
                }
                condensed={condensed}
            >
                <ErrorLabel>{error}</ErrorLabel>
            </ErrorDiv>
        </>
    );
};

export default FormValuationIndicator;
