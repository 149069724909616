import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DeclarationFormCardProps,
    DeclarationMultipleFormCardProps,
} from 'views/declarations/common/declaration.form.card';
import { handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import GoodItemCard from './GoodItemCard';

export interface NestedAuthorisationCardProps extends DeclarationFormCardProps {
    initializeList: (path: string) => any[];
    addCard: (path: string, array: any[]) => void;
    removeCard: (path: string, array: any[], index: number) => void;
}

const NestedAuthorisationCard: FC<NestedAuthorisationCardProps> = ({
    viewOnly,
    defaultOpen,
    cardNumber,
    cardTotal,
    getFieldProps,
    getFieldHelpers,
    getFieldMeta,
    keyCard,
    expandAll,
    nestedComponents = [],
    initializeList,
    addCard,
    removeCard,
    selectCard,
    ...remainingProps
}) => {
    const { t } = useTranslation('form');

    return (
        <section
            id="authorisation-form-card"
            onClick={() => {
                if (selectCard) {
                    selectCard('authorisation-form-card');
                }
            }}
        >
            <NewFormCard title={t('Authorisation')}>
                <FormCardContainer style={{ marginBottom: '2rem' }}>
                    <GoodItemCard
                        toggleHelp={(ref: number | string) => handleToggleHelp(ref, remainingProps)}
                        viewOnly={viewOnly}
                        key={`nest-auth-goods-item-0`}
                        getFieldProps={getFieldProps}
                        getFieldMeta={getFieldMeta}
                        keyCard={`nest-auth-goods-item-0`}
                        getFieldHelpers={getFieldHelpers}
                    />
                </FormCardContainer>
                {nestedComponents.map((obj, index) => {
                    if (!obj.multiple) {
                        const component = React.createElement(obj.component as React.FC<DeclarationFormCardProps>, {
                            toggleHelp: (ref: number | string) => handleToggleHelp(ref, remainingProps),
                            viewOnly: viewOnly,

                            propsPathPrefix: obj.propsPathPrefix,
                            keyCard: obj.key,
                            getFieldProps: getFieldProps,
                            getFieldMeta: getFieldMeta,
                            getFieldHelpers: getFieldHelpers,
                        });
                        return <React.Fragment key={`nested-compoent-${index}`}>{component}</React.Fragment>;
                    } else {
                        const listCards = initializeList(obj.propsPathPrefix);
                        if (Array.isArray(listCards)) {
                            return listCards.map((listComponent, indexList) => {
                                const component = React.createElement(
                                    obj.component as React.FC<DeclarationMultipleFormCardProps>,
                                    {
                                        toggleHelp: (ref: number | string) => handleToggleHelp(ref, remainingProps),
                                        viewOnly: viewOnly,

                                        propsPathPrefix: obj.propsPathPrefix,
                                        key: `${obj.key}-${indexList}`,
                                        keyCard: `${obj.key}-${indexList}`,
                                        getFieldProps: getFieldProps,
                                        getFieldMeta: getFieldMeta,
                                        getFieldHelpers: getFieldHelpers,
                                        index: indexList,
                                        size: listCards.length,
                                        remove: (index: number) => {
                                            removeCard(obj.propsPathPrefix, listCards, index);
                                        },
                                        add: () => {
                                            addCard(obj.propsPathPrefix, listCards);
                                        },
                                    }
                                );
                                return <React.Fragment key={`nested-compoent-${index}`}>{component}</React.Fragment>;
                            });
                        }
                        return <></>;
                    }
                })}
            </NewFormCard>
        </section>
    );
};
export default NestedAuthorisationCard;
