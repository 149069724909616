import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { chiefAdditionalDeclarationTypesLabels } from 'store/declarations/enums/common/additional-declaration-type';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'additionalTypeCode';

export const additionalDeclarationTypeBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        additionalTypeCode: [validators.required(), validators.exact(1)],
    },
};

interface Props extends BlockProps<Fields> {}

const AdditionalDeclarationTypeBlock = ({ fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '1/2', []);

    return (
        <DeclarationField name="additionalTypeCode">
            {({ form, field }) => (
                <FormSelect
                    required
                    {...getFormikProps(field.name, form)}
                    refNumber={ucc}
                    label={t('addDeclarationTypes')}
                    selectOptions={chiefAdditionalDeclarationTypesLabels}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default AdditionalDeclarationTypeBlock;
