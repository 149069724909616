import { Col } from 'antd';
import { TestProps } from 'core/utils/testTypes';
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { StyledPanel } from '../../Form.styles';
import { CardState } from './card-state';
import { ButtonsCol, ButtonsRow, CardRow, ColRightHand, PanelHeader, PanelHeaderIcon, Title } from './FormCard.styles';
import MultipleCardButtons from './MultipleCardButtons';
import RightSideCardState from './RightSideCardState';
import StyledCollapse from './StyledCollapse';

export enum OnClickAction {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
}

interface Props extends TestProps {
    title: string;
    multiple?: boolean;
    key?: number | string;
    keyCard?: number | string;
    addCard?: () => void;
    removeCard?: () => void;
    isLast?: boolean;
    isFirst?: boolean;
    total?: number;
    cardNumber?: number;
    action?: {
        title: string;
        onClick: Function;
        icon?: React.ReactNode;
    };
    viewOnly?: boolean;
    defaultOpen?: boolean;
    icon?: React.ReactNode;
    state?: CardState;
    expandAll?: boolean;
    showRightSideCardState?: boolean;
    hide?: () => boolean;
}

const FormCard: FC<PropsWithChildren<Props>> = ({
    title,
    children,
    multiple,
    key = 0,
    addCard,
    removeCard,
    isFirst = false,
    isLast = true,
    total,
    cardNumber,
    action,
    viewOnly,
    defaultOpen,
    icon,
    state,
    keyCard = 0,
    expandAll = false,
    showRightSideCardState = true,
    testId,
    hide,
}) => {
    const invalidState = useMemo(() => state === CardState.INVALID, [state]);

    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        !hide?.() && setShouldRender(true);
    }, [hide]);

    const renderCard = useMemo(() => {
        return (
            <StyledCollapse
                action={action}
                invalid={invalidState}
                viewOnly={viewOnly}
                key={key ? key : 0}
                keyCard={keyCard}
                expandAll={expandAll}
                defaultActiveKey={defaultOpen ? keyCard : undefined}
                testId={testId}
                data-card
            >
                <StyledPanel
                    id={`${title}-form-card`}
                    forceRender={true}
                    header={
                        <PanelHeader>
                            <Title invalid={invalidState} className="section-title">
                                {title}
                            </Title>
                            <PanelHeaderIcon>{icon}</PanelHeaderIcon>
                        </PanelHeader>
                    }
                    key={keyCard}
                    style={{
                        transition: 'background-color 1s',
                    }}
                >
                    {children}
                    {multiple && !viewOnly && (
                        <ButtonsRow>
                            <ButtonsCol span={24}>
                                <MultipleCardButtons
                                    isFirst={isFirst}
                                    isLast={isLast}
                                    removeCard={removeCard}
                                    addCard={addCard}
                                />
                            </ButtonsCol>
                        </ButtonsRow>
                    )}
                </StyledPanel>
            </StyledCollapse>
        );
    }, [
        action,
        invalidState,
        viewOnly,
        key,
        keyCard,
        expandAll,
        defaultOpen,
        testId,
        title,
        shouldRender,
        icon,
        children,
        multiple,
        isFirst,
        isLast,
        removeCard,
        addCard,
    ]);

    const colSize = useMemo(() => {
        if (!viewOnly && showRightSideCardState) {
            return [21, 22];
        }

        return [24, 24];
    }, [viewOnly, showRightSideCardState]);

    if (hide?.()) {
        return null;
    }

    return (
        <CardRow>
            <Col xl={colSize[0]} xxl={colSize[1]} style={{ position: 'unset' }}>
                {renderCard}
            </Col>
            {!viewOnly && showRightSideCardState && (
                <ColRightHand xl={3} xxl={2}>
                    <RightSideCardState title={title} cardNumber={cardNumber} total={total} state={state} />
                </ColRightHand>
            )}
        </CardRow>
    );
};
export default FormCard;
