import Button from 'components/ui/base/button';
import CustomModal from 'components/ui/base/modal/Modal';
import { H5 } from 'components/ui/base/typography';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { objectIsEmpty } from 'core/utils/objects';
import { setNestedObjectValues, useFormik } from 'formik';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Code, normalizeCodesToSelect } from 'store/codelists/code';
import { ProducedDocumentsWritingOffHeader } from 'store/declarations/common/produced-document-writing-off-header';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { RevenueExportAdditionalInformation } from 'store/declarations/ireland/export-declaration';
import { SupportingDocuments } from 'store/declarations/ireland/h7-import-declaration';
import { CardContent } from 'views/declarations/common/box44/Box44Card.styles';
import Box44List from 'views/declarations/common/box44/Box44List';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState } from 'views/declarations/utils/form-utils';
import { getMaxValidation, getRequiredMessage } from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';

const Box44Schema = Yup.object().shape({
    code: getMaxValidation('code', 5).required(getRequiredMessage('code')).nullable(),
    identifier: getMaxValidation('identifier', 255).required(getRequiredMessage('identifier')).nullable(),
});

const ExportBox44Card: FC<DeclarationFormCardProps & { title: string; codelists: Code[] }> = ({
    propsPathPrefix,
    getFieldProps: declarationGetFieldProps,
    getFieldHelpers: declarationGetFieldHelpers,
    getFieldMeta: declarationGetFieldMeta,
    cardTotal,
    cardNumber,
    title,
    viewOnly,
    expandAll,
    keyCard,
    selectCard,
    defaultOpen,
    codelists,
}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [editIndex, setEditIndex] = useState<number | undefined>(undefined);

    const { t } = useTranslation('form');

    const getBox44Items = useMemo(() => {
        return declarationGetFieldProps(propsPathPrefix!).value ?? [];
    }, [declarationGetFieldProps, propsPathPrefix]);

    const box44Items: RevenueExportAdditionalInformation[] = getBox44Items;

    const {
        getFieldProps,
        resetForm,
        getFieldMeta,
        handleSubmit,
        validateForm,
        setTouched,
        setValues,
        getFieldHelpers,
    } = useFormik<RevenueExportAdditionalInformation>({
        initialValues: { code: '', identifier: '' },
        validationSchema: Box44Schema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => addItem(values),
    });

    const addBox44Item = (newItem: RevenueExportAdditionalInformation) => {
        const list = box44Items ? [...box44Items, newItem] : [newItem];
        declarationGetFieldHelpers!(propsPathPrefix!).setValue(list);
    };

    const updateBox44Item = (newItem: RevenueExportAdditionalInformation) => {
        if (editIndex !== undefined) {
            const list = [...box44Items];
            list[editIndex] = newItem;
            declarationGetFieldHelpers!(propsPathPrefix!).setValue(list);
            setEditIndex(undefined);
        }
    };

    const addItem = (values: RevenueExportAdditionalInformation) => {
        setModalVisible(false);
        const item = {
            ...values,
        };
        Number.isFinite(editIndex) ? updateBox44Item(item) : addBox44Item(item);

        resetForm();
    };

    const handleAddItem = async () => {
        const validate = await validateForm().then((v) => {
            setTouched(setNestedObjectValues(v, true));
            return v;
        });
        if (objectIsEmpty(validate)) {
            handleSubmit();
        }
    };

    const handleEdit = (
        item: RevenueExportAdditionalInformation | ProducedDocumentsWritingOffHeader | SupportingDocuments,
        index: number
    ) => {
        setEditIndex(index);
        const i = { ...item } as RevenueExportAdditionalInformation;
        setValues(i);
        setModalVisible(true);
    };

    const handleDelete = (
        item: RevenueExportAdditionalInformation | ProducedDocumentsWritingOffHeader | SupportingDocuments
    ) => {
        const list = [...box44Items].filter((i) => i !== item);
        declarationGetFieldHelpers!(propsPathPrefix!).setValue(list);
    };

    const handleCancel = () => {
        setEditIndex(undefined);
        setModalVisible(false);
        resetForm();
    };

    const arrayState = useMemo(() => {
        const array: { name: string; required: boolean }[] = [];
        box44Items.forEach((obj, index) => {
            array.push({ name: `${index}.code`, required: false });
            array.push({ name: `${index}.identifier`, required: false });
        });
        return array;
    }, [box44Items]);

    return (
        <section
            id={keyCard as string}
            onClick={() => {
                if (selectCard) {
                    selectCard(keyCard! as string);
                }
            }}
        >
            <FormCard
                defaultOpen={defaultOpen}
                viewOnly={viewOnly}
                cardNumber={cardNumber}
                total={cardTotal}
                title={title}
                expandAll={expandAll}
                keyCard={keyCard}
                state={getCardState(arrayState, {
                    getFieldProps: declarationGetFieldProps,
                    getFieldMeta: declarationGetFieldMeta,
                    propsPathPrefix,
                })}
            >
                <CardContent>
                    <Box44List
                        viewOnly={viewOnly}
                        data={box44Items}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                        internalType={DeclarationInternalType.EXPORT}
                    />
                    {!viewOnly && <Button onClick={() => setModalVisible(true)}>Add item</Button>}
                </CardContent>
            </FormCard>
            <CustomModal
                title={<H5>Add item</H5>}
                centered
                cancelText="Cancel"
                confirmText="Continue"
                testId="add-item-export-box-44-modal"
                visible={modalVisible}
                onOk={handleAddItem}
                onCancel={handleCancel}
                width={676}
            >
                <FormSelect
                    required
                    fieldMeta={getFieldMeta('code')}
                    fieldProps={getFieldProps('code')}
                    refNumber="2.3"
                    label={t('codeIdentifier')}
                    tooltip={t('defaultTooltip')}
                    selectOptions={normalizeCodesToSelect(codelists)}
                />
                <FormInput
                    required
                    maxLength={255}
                    fieldMeta={getFieldMeta('identifier')}
                    fieldProps={getFieldProps('identifier')}
                    fieldHelper={getFieldHelpers('identifier')}
                    refNumber="2.3"
                    label={t('additionalInformationDescription')}
                />
            </CustomModal>
        </section>
    );
};
export default ExportBox44Card;
