import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { locationTypesLabels } from 'store/declarations/enums/common/location-types';
import { locationIdentificationQualifierLabels } from 'store/declarations/enums/ireland/location-identification-qualifier';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const GoodsLocationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <section
            id="goods-location-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('goods-location-form-card');
                }
            }}
            data-testid="goods-location-form-card"
        >
            <NewFormCard title={t('Goods location')}>
                <FormCardContainer>
                    <FormSelect
                        required
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`locationTypeCode`, props)}
                        refNumber="5.23"
                        label={t('firstPlaceOfUse.locationTypeCode')}
                        selectOptions={locationTypesLabels}
                        testId="firstPlaceOfUse.locationTypeCode"
                        tooltip={getH1TooltipsByRefNumberAndField('5.23', t('firstPlaceOfUse.locationTypeCode'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormSelect
                        required
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`qualifierIdentification`, props)}
                        refNumber="5.23"
                        label={t('locationGoods.qualifierOfIdentification')}
                        selectOptions={locationIdentificationQualifierLabels}
                        tooltip={getH1TooltipsByRefNumberAndField('5.23', t('locationGoods.qualifierOfIdentification'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        testId="firstPlaceOfUse.qualifierOfIdentification"
                        condensed
                    />

                    <FormSelect
                        required
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`identificationOfLocation`, props)}
                        testId="identification-of-location"
                        refNumber="5.23"
                        label={t('locationGoods.identificationOfLocation')}
                        selectOptions={normalizeCodesToSelect(codelists?.irelandLocationOfGoodsCodes || [])}
                        tooltip={getH1TooltipsByRefNumberAndField('5.23', t('locationGoods.identificationOfLocation'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormInput
                        maxLength={3}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`additionalIdentifier`, props)}
                        refNumber="5.23"
                        testId="locationGoods.additionalIdentifier"
                        label={t('locationGoods.additionalIdentifier')}
                        tooltip={getH1TooltipsByRefNumberAndField('5.23', t('locationGoods.additionalIdentifier'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <CountrySelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`address.country`, props)}
                        refNumber="5.23"
                        testId="address.country"
                        tooltip={getH1TooltipsByRefNumberAndField('5.23', t('currency'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>
        </section>
    );
};
export default GoodsLocationCard;
