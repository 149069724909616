import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';

const GoodsInformationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH7TooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('goodsItems.title')}>
            <FormCardContainer>
                <FormInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`descriptionOfGoods`, props)}
                    refNumber="6.8"
                    label={t('goodsItems.goodsDescription')}
                    tooltip={getH7TooltipsByRefNumberAndField('6.8', t('goodsItems.goodsDescription'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    required
                    maxLength={6}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`commodityCodeHarmonizedSystemSubHeadingCode`, props)}
                    refNumber="6.14"
                    label={t('goodsItems.commodityCodeHarmonizedSystemSubHeadingCode')}
                    tooltip={getH7TooltipsByRefNumberAndField(
                        '6.14',
                        t('goodsItems.commodityCodeHarmonizedSystemSubHeadingCode')
                    )}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    viewOnly={props.viewOnly}
                    maxLength={35}
                    {...getFormikProps(`documentsAuthorisations.referenceNumberUcr`, props)}
                    refNumber="2.4"
                    label={t('referenceNumberUCR')}
                    tooltip={getH7TooltipsByRefNumberAndField('2.4', t('referenceNumberUCR'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`grossMass`, props)}
                    refNumber="6.5"
                    label={t('goodsItems.grossMass')}
                    tooltip={getH7TooltipsByRefNumberAndField('6.5', t('goodsItems.grossMass'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    integerNumbers
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`supplementaryUnits`, props)}
                    refNumber="6.2"
                    label={t('goodsItems.supplementaryUnits')}
                    tooltip={getH7TooltipsByRefNumberAndField('6.2', t('goodsItems.supplementaryUnits'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`packagingNumberOfPackages`, props)}
                    refNumber="6.10"
                    label={t('goodsItems.numberOfPackages')}
                    tooltip={getH7TooltipsByRefNumberAndField('6.10', t('goodsItems.numberOfPackages'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default GoodsInformationCard;
