import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import CustomsOfficeOfExitBlock, { customsOfficeOfExitBlockValidation } from '../blocks/CustomsOfficeOfExitBlock';
import CustomsOfficeOfPresentationBlock, {
    customsOfficeOfPresentationBlockValidation,
} from '../blocks/CustomsOfficeOfPresentationBlock';
import { ExtractValidationSchema } from '../validations/validations';

export const customsOfficesCardValidation = {
    selfValidators: [customsOfficeOfPresentationBlockValidation, customsOfficeOfExitBlockValidation],
};
export type CustomsOfficesCardValidation = ExtractValidationSchema<typeof customsOfficesCardValidation>;

interface Props extends DeclarationFormCardProps {
    isUkCdsImport?: boolean;
}

const CustomsOfficesCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('customsOfficeOfPresentation')}>
            <FormCardContainer>
                <CustomsOfficeOfPresentationBlock />
                {!props.isUkCdsImport && <CustomsOfficeOfExitBlock />}
            </FormCardContainer>
        </NewFormCard>
    );
};
export default CustomsOfficesCard;
