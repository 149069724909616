import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import { unDangerousGoodsCode } from 'store/declarations/enums/uk/un-dangerous-goods-code';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ValidationSchema, validators } from '../validations/validations';

export type Fields = 'undgid';

export const unDangerousGoodsCodeBlockValidation: {
    childValidators: {
        'commodity.dangerousGoods': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'commodity.dangerousGoods': {
            childValidators: {
                undgid: [validators.number(), validators.max(4)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const UnDangerousGoodsCodeBlock = ({ path = 'commodity.dangerousGoods', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '6/12', []);
    return (
        <DeclarationField pathPrefix={path} name="undgid">
            {({ form, field }: FieldProps<any>) => (
                <FormSelect
                    refNumber={ucc}
                    label={t('dangerousGoodsCode')}
                    {...getFormikProps(field.name, form)}
                    selectOptions={unDangerousGoodsCode}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default UnDangerousGoodsCodeBlock;
