import { FC } from 'react';
import { H4, H5 } from 'components/ui/base/typography';
import styled, { StyledComponent } from 'styled-components';
import { colors } from 'theme';
import { ArrowDownOutlined } from '@ant-design/icons';
import Button from 'components/ui/base/button';
import { ButtonProps } from 'antd';

interface StyledComponents {
    Main: StyledComponent<'div', any>;
    ButtonContainer: StyledComponent<'div', any>;
    Container: StyledComponent<'div', any>;
    Text: StyledComponent<'h4', any>;
    TextSmall: StyledComponent<'h5', any>;
    ArrowDown: StyledComponent<typeof ArrowDownOutlined, any>;
    TextSmallContainer: StyledComponent<'div', any>;
}

const { Main, ButtonContainer, Container, Text, TextSmall, ArrowDown, TextSmallContainer }: StyledComponents = {
    Main: styled.div`
        display: flex;
        justify-content: center;

        padding: 3rem 5rem;

        margin-bottom: 2rem;
    `,
    Container: styled.div`
        width: 800px;
    `,
    Text: styled(H4)`
        color: ${colors.darkPurple};
    `,
    TextSmall: styled(H5)`
        color: ${colors.darkPurple};
    `,
    ArrowDown: styled(ArrowDownOutlined)`
        color: ${colors.darkPurple};
        font-size: 3rem;
        margin-top: 1rem;
    `,
    ButtonContainer: styled.div`
        display: flex;
        gap: 3rem;

        margin-bottom: 5rem;
    `,
    TextSmallContainer: styled.div``,
};

interface ButtonPropsType extends ButtonProps {
    key: string;
}

interface Props {
    textAboveButtons?: string;
    textBellowButtons?: string;
    buttons?: ButtonPropsType[];
}

const DashboardSetupHeading: FC<Props> = ({ textAboveButtons, textBellowButtons, buttons }) => {
    return (
        <Main>
            <Container>
                <Text>{textAboveButtons}</Text>

                {buttons?.length && (
                    <ButtonContainer>
                        {buttons?.map((button) => (
                            <Button {...button} key={button.key} type="primary" data-testId={button.key} />
                        ))}
                    </ButtonContainer>
                )}

                <TextSmallContainer>
                    <TextSmall>{textBellowButtons}</TextSmall>

                    {textBellowButtons && <ArrowDown />}
                </TextSmallContainer>
            </Container>
        </Main>
    );
};

export default DashboardSetupHeading;
