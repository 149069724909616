import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../../common/cards/NewFormCard';

const ProcedureCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('Procedure')}>
            <FormCardContainer>
                <FormSelect
                    hideKeys
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps('requestedProcedure', props)}
                    refNumber="1.10"
                    label={t('requestedProcedure')}
                    selectOptions={normalizeCodesToSelect(codelists?.ukImportRequestedProcedures || [])}
                    tooltip={getCdsTooltipsByRefNumberAndField('1.10', t('requestedProcedure'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormSelect
                    hideKeys
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps('previousProcedure', props)}
                    refNumber="1.10"
                    label={t('previousProcedure')}
                    selectOptions={normalizeCodesToSelect(codelists?.ukImportPreviousProcedures || [])}
                    tooltip={getCdsTooltipsByRefNumberAndField('1.10', t('previousProcedure'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ProcedureCard;
