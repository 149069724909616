import { Col, Row } from 'antd';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import FormEori from 'components/ui/composed/formEori/FormEori';
import useCodelists from 'hooks/useCodelists';
import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import addPathPrefix from 'utils/addPathPrefix';
import { addToItemOfUcc } from 'views/declarations/uk/export/blocks/CustomerBlock';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { invalidEoriMessage } from 'views/declarations/utils/validation-utils';
import { Party, Paths } from './PartiesCard';
import PartiesEoriFormProps from './PartiesEoriFormProps';
import { FormCardContainer } from '../cards/NewFormCard';

const createEoriPath = (party: Party, paths: Paths) => {
    return {
        eori: addPathPrefix(party.path, paths.eori),
        ...(party.hasRepresentativeStatus &&
            paths.additional?.representativeStatus && {
                representativeStatus: addPathPrefix(party.path, paths.additional.representativeStatus),
            }),
    };
};

const PartiesEoriForm = ({
    party,
    paths: pathsProp,
    refNumber,
    eoriRequired,
    eoriLabel,
    condensed,
    onBlur,
    ...other
}: PartiesEoriFormProps): ReactElement => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();

    const paths = useMemo(() => createEoriPath(party, pathsProp), [party, pathsProp]);

    const onError = useCallback(() => {
        other.getFieldHelpers!(paths.eori).setError(invalidEoriMessage);
    }, [other.getFieldHelpers, paths.eori]);

    const onSuccess = useCallback(() => {
        other.getFieldHelpers!(paths.eori).setError(undefined);
    }, [other.getFieldHelpers, paths.eori]);

    if (!condensed) {
        return (
            <>
                <Row gutter={12}>
                    <Col sm={24}>
                        <FormEori
                            declaration
                            required={eoriRequired}
                            viewOnly={other.viewOnly}
                            refNumber={addToItemOfUcc(refNumber, 1)}
                            label={t(eoriLabel ?? 'identificationNumber')}
                            {...getFormikProps(paths.eori, other)}
                            testId={paths.eori}
                            refClicked={(ref) => handleToggleHelp(ref, other)}
                            onError={onError}
                            onSuccess={onSuccess}
                            condensed={condensed}
                            onBlur={onBlur}
                        />
                    </Col>
                </Row>
                {paths.representativeStatus && (
                    <FormSelect
                        viewOnly={other.viewOnly}
                        disabled={other.amendment}
                        {...getFormikProps(paths.representativeStatus, other)}
                        refNumber={addToItemOfUcc(refNumber, 1)}
                        label={t('Representative status')}
                        testId={paths.representativeStatus}
                        selectOptions={normalizeCodesToSelect(codelists?.irelandRepresentativeStatusCodes || [])}
                        condensed={condensed}
                        fieldEvents={{
                            onBlur: (e: any) => onBlur?.(e),
                        }}
                    />
                )}
            </>
        );
    }

    return (
        <FormCardContainer>
            <FormEori
                declaration
                required={eoriRequired}
                viewOnly={other.viewOnly}
                refNumber={addToItemOfUcc(refNumber, 1)}
                label={t(eoriLabel ?? 'identificationNumber')}
                {...getFormikProps(paths.eori, other)}
                testId={paths.eori}
                refClicked={(ref) => handleToggleHelp(ref, other)}
                onError={onError}
                onSuccess={onSuccess}
                condensed={condensed}
                onBlur={onBlur}
            />
            {paths.representativeStatus && (
                <FormSelect
                    viewOnly={other.viewOnly}
                    disabled={other.amendment}
                    {...getFormikProps(paths.representativeStatus, other)}
                    refNumber={addToItemOfUcc(refNumber, 1)}
                    label={t('Representative status')}
                    testId={paths.representativeStatus}
                    selectOptions={normalizeCodesToSelect(codelists?.irelandRepresentativeStatusCodes || [])}
                    condensed={condensed}
                    fieldEvents={{
                        onBlur: (e: any) => onBlur?.(e),
                    }}
                />
            )}
        </FormCardContainer>
    );
};

export default PartiesEoriForm;
