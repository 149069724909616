import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState } from 'views/declarations/utils/form-utils';
import TransportCostsToTheFinalDestinationBlock from '../blocks/TransportCostsToTheFinalDestinationBlock';

const TransportCostsToTheFinalDestinationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <FormCard
            hide={props.hide}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            title={t('transportCostToFinalDestination')}
            cardNumber={props.cardNumber}
            total={props.cardTotal}
            state={getCardState(
                [
                    {
                        name: 'goodsShipment.customsValuation.freightChargeAmount',
                        required: false,
                    },
                    {
                        name: 'goodsShipment.customsValuation.freightChargeAmountCurrencyId',
                        required: false,
                    },
                ],
                props
            )}
        >
            <TransportCostsToTheFinalDestinationBlock />
        </FormCard>
    );
};
export default TransportCostsToTheFinalDestinationCard;
