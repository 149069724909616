import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormPhoneNumber from 'components/ui/composed/formPhoneNumber/FormPhoneNumber';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import { useFormik } from 'formik';
import useCountries from 'hooks/useCountries';
import useDebouncedValidate from 'hooks/useDebouncedValidate';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SetupStyledComponents from 'views/setup/styles/Setup.styles';

const { Main, Container, ScreenTitle, ScreenSubtitle, ButtonContainer, ScreenTitleSubtitleContainer } =
    SetupStyledComponents;

interface FormInitialValues {
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneCode: string;
    phoneNumber: string;
    weighbridgeLabel: string;
}

const initialValues: FormInitialValues = {
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneCode: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: '',
    weighbridgeLabel: '',
};

const DeviceOrder = () => {
    const { t } = useTranslation(['weighbridge', 'common']);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: () => {},
    });

    // TODO Get initial data

    useDebouncedValidate(formik);

    const { countries } = useCountries();

    const handleEditInformation = () => {};

    const handleConfirmInformation = () => {
        navigate('./../payment-info');
    };

    return (
        <Main>
            <Container>
                <ScreenTitleSubtitleContainer>
                    <ScreenTitle>{t('deviceOrder.title')}</ScreenTitle>
                    <ScreenSubtitle>{t('deviceOrder.subtitle')}</ScreenSubtitle>
                </ScreenTitleSubtitleContainer>
                <Row gutter={[30, 15]}>
                    <Col xl={12}>
                        <FormInput
                            label={t('companyDetails.companyName.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('companyName')}
                            fieldMeta={formik.getFieldMeta('companyName')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('users.firstName.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('firstName')}
                            fieldMeta={formik.getFieldMeta('firstName')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('companyDetails.addressLine1.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('addressLine1')}
                            fieldMeta={formik.getFieldMeta('addressLine1')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('users.lastName.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('lastName')}
                            fieldMeta={formik.getFieldMeta('lastName')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('companyDetails.addressLine2.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('addressLine2')}
                            fieldMeta={formik.getFieldMeta('addressLine2')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('users.email.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('email')}
                            fieldMeta={formik.getFieldMeta('email')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('companyDetails.city.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('city')}
                            fieldMeta={formik.getFieldMeta('city')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormPhoneNumber
                            required
                            fieldNumberProps={formik.getFieldProps('phoneNumber')}
                            fieldCodeProps={formik.getFieldProps('phoneCode')}
                            fieldCodeMeta={formik.getFieldMeta('phoneCode')}
                            fieldNumberMeta={formik.getFieldMeta('phoneNumber')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormSelect
                            label={t('companyDetails.country.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('country')}
                            fieldMeta={formik.getFieldMeta('country')}
                            selectOptions={countries.list.map((obj) => {
                                return {
                                    id: obj.countryCode,
                                    value: obj.name,
                                };
                            })}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('weighbridge.label.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('weighbridgeLabel')}
                            fieldMeta={formik.getFieldMeta('weighbridgeLabel')}
                        />
                    </Col>
                </Row>

                <ButtonContainer>
                    <Button onClick={() => navigate(-1)}>{t('buttons.back', { ns: 'common' })}</Button>
                    <Button onClick={handleEditInformation}>{t('buttons.editDetails', { ns: 'common' })}</Button>
                    <Button disabled={!isEmpty(formik.errors)} type="primary" onClick={handleConfirmInformation}>
                        {t('buttons.confirm', { ns: 'common' })}
                    </Button>
                </ButtonContainer>
            </Container>
        </Main>
    );
};

export default DeviceOrder;
