import useDeclarations from 'hooks/useDeclarations';
import useJobs from 'hooks/useJobs';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import declarationViewMap from './utils/declarationViewMap';
import DeclarationViewComponent from 'views/declarations/common/declaration-view/DeclarationView';
import { getDeclarationKey } from './utils/declaration-utils';
import { DeclarationContextProvider } from 'utils/DeclarationContext';

export enum FormSection {
    MASTER_DETAIlS = 0,
    PRODUCTS = 1,
}

export enum DeclarationTabKeys {
    FORM = 'form',
    DOCUMENTATION = 'documentation',
    CUSTOMER_DETAILS = 'customer-details',
    NOTIFICATIONS = 'notifications',
    VIEW_ONLY = 'view-only',
    HISTORY = 'history',
    REFUNDS = 'refunds',
}

const DeclarationView: FC = () => {
    const { declarationId } = useParams<{ declarationId: string }>();
    const { declaration } = useDeclarations({ declarationId: declarationId });
    const { job } = useJobs({ jobId: declaration?.jobId });

    const declarationView = useMemo(() => {
        if (!declaration) return;

        if (!(declaration?.id && declaration?.jobId)) return null;

        const declarationType = getDeclarationKey(declaration);

        if (declarationType === null) return null;

        return (
            <DeclarationViewComponent declaration={declaration} job={job} props={declarationViewMap[declarationType]} />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declaration]);

    return <DeclarationContextProvider>{declarationView}</DeclarationContextProvider>;
};

export default DeclarationView;
