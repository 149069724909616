import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { identificationOfLocationCodeList } from 'store/declarations/enums/uk/cds-export-identification-of-location-code-list';
import { identificationOfLocation } from 'store/declarations/enums/uk/identification-of-location';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import CountrySelect from '../../../../../components/ui/composed/declarations/CountrySelect';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { locationTypesLabels } from '../../../../../store/declarations/enums/common/location-types';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'id' | 'name' | 'typeCode' | 'address.countryCode' | 'address.typeCode';

export type LocationOfGoodsBlockValidation = ValidationSchema<Fields>;
export const locationOfGoodsBlockValidation: LocationOfGoodsBlockValidation = {
    childValidators: {
        id: [validators.number(), validators.max(3)],
        name: [validators.max(35)],
        typeCode: [validators.required(), validators.exact(1)],
        'address.countryCode': [validators.required(), validators.exact(2)],
        'address.typeCode': [validators.required(), validators.exact(1)],
    },
};

interface Props extends BlockProps<Fields> {}

const LocationOfGoodsBlock = ({
    path = 'goodsShipment.consignment.goodsLocation',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '5/23', []);
    return (
        <>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        label={t('goodsLocationAddId')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="name">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        label={t('locationGoods.identificationOfLocation')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={identificationOfLocationCodeList}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="typeCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        required
                        refNumber={ucc}
                        label={t('goodsLocationType')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={locationTypesLabels}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="address.countryCode">
                {({ form, field }: FieldProps<any>) => (
                    <CountrySelect
                        required
                        refNumber={ucc}
                        label={t('country')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="address.typeCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        required
                        refNumber={ucc}
                        label={t('goodsLocationQualifier')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={identificationOfLocation}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default LocationOfGoodsBlock;
