import { Col, Row } from 'antd';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import CountrySelect from '../../../../../components/ui/composed/declarations/CountrySelect';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { normalizeCodesToSelect } from 'store/codelists/code';
import useCodelists from 'hooks/useCodelists';

interface Props extends BlockProps<'countryCode' | 'typeCode'> {}

const CountryOfPreferentialOriginBlock = ({
    path = 'goodsShipment.governmentAgencyGoodsItem.origin',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();

    const ucc = useMemo(() => '5/16', []);
    return (
        <Row gutter={18}>
            <Col xs={fieldOptions?.countryCode?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="countryCode"
                    validation={{
                        name: t('countryOfPreferentialOriginCountryCode'),
                        exact: 2,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <CountrySelect
                            refNumber={ucc}
                            label={t('countryOfPreferentialOriginCountryCode')}
                            {...getFormikProps(field.name, form)}
                        />
                    )}
                </DeclarationField>
            </Col>
            <Col xs={fieldOptions?.typeCode?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="typeCode"
                    validation={{
                        name: t('countryOfPreferentialOriginRole'),
                        exact: 1,
                        number: true,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormSelect
                            refNumber={ucc}
                            label={t('countryOfPreferentialOriginRole')}
                            {...getFormikProps(field.name, form)}
                            selectOptions={normalizeCodesToSelect(codelists?.ukPartyRoleStatusTypes || [])}
                        />
                    )}
                </DeclarationField>
            </Col>
        </Row>
    );
};

export default CountryOfPreferentialOriginBlock;
