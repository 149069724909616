import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import { FocusEventHandler, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { addToItemOfUcc } from '../CustomerBlock';

type AddressFields =
    | 'name'
    | 'functionCode'
    | 'address'
    | 'address.line'
    | 'address.countryCode'
    | 'address.postcodeID'
    | 'address.cityName';

interface Props extends BlockProps<AddressFields> {
    path: string | null;
    options: {
        ucc: string;
    };
    condensed?: boolean;
    onBlur?: FocusEventHandler<HTMLInputElement>;
}

const AddressBlock = ({ path, fieldOptions, options, condensed, onBlur }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    return (
        <>
            <DeclarationField pathPrefix={path} name="name">
                {({ form, field }) => (
                    <FormInput
                        maxLength={fieldOptions?.name?.validation?.max ?? 70}
                        {...getFormikProps(field.name, form)}
                        refNumber={options.ucc}
                        label={t('name')}
                        condensed
                        onBlur={onBlur}
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="address.line">
                {({ form, field }) => (
                    <FormInput
                        required={fieldOptions?.address?.required}
                        maxLength={fieldOptions?.['address.line']?.validation?.max ?? 70}
                        {...getFormikProps(field.name, form)}
                        refNumber={options.ucc}
                        label={t('streetAndNumber')}
                        condensed
                        onBlur={onBlur}
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="address.cityName">
                {({ form, field }) => (
                    <FormInput
                        required={fieldOptions?.address?.required}
                        maxLength={35}
                        {...getFormikProps(field.name, form)}
                        refNumber={options.ucc}
                        label={t('city')}
                        condensed
                        onBlur={onBlur}
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="address.postcodeID">
                {({ form, field }) => (
                    <FormInput
                        required={fieldOptions?.address?.required}
                        maxLength={9}
                        {...getFormikProps(field.name, form)}
                        refNumber={options.ucc}
                        label={t('postCode')}
                        condensed
                        onBlur={onBlur}
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="address.countryCode">
                {({ form, field }) => (
                    <CountrySelect
                        label={t('country')}
                        required={fieldOptions?.address?.required}
                        {...getFormikProps(field.name, form)}
                        refNumber={options.ucc}
                        condensed
                        onBlur={onBlur}
                    />
                )}
            </DeclarationField>

            {fieldOptions?.functionCode?.show && (
                <DeclarationField pathPrefix={path} name="functionCode">
                    {({ form, field }) => (
                        <FormSelect
                            {...getFormikProps(field.name, form)}
                            refNumber={addToItemOfUcc(options.ucc, 2)}
                            label={t('statusCode')}
                            selectOptions={normalizeCodesToSelect(codelists?.irelandRepresentativeStatusCodes || [])}
                            condensed={condensed}
                            onBlur={onBlur}
                        />
                    )}
                </DeclarationField>
            )}
        </>
    );
};

export default AddressBlock;
