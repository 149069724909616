export enum DocumentStatus {
    AC = 'AC',
    AE = 'AE',
    AF = 'AF',
    AG = 'AG',
    AP = 'AP',
    AS = 'AS',
    AT = 'AT',
    CC = 'CC',
    EA = 'EA',
    EE = 'EE',
    EL = 'EL',
    EP = 'EP',
    ES = 'ES',
    FP = 'FP',
    GE = 'GE',
    GP = 'GP',
    HP = 'HP',
    IA = 'IA',
    IE = 'IE',
    IP = 'IP',
    IS = 'IS',
    JA = 'JA',
    JE = 'JE',
    JP = 'JP',
    JS = 'JS',
    LE = 'LE',
    LP = 'LP',
    RE = 'RE',
    SP = 'SP',
    TP = 'TP',
    UA = 'UA',
    UE = 'UE',
    UP = 'UP',
    US = 'US',
    XA = 'XA',
    XB = 'XB',
    XF = 'XF',
    XU = 'XU',
    XW = 'XW',
    XX = 'XX',
}

export const documentStatusLabels = [
    { id: DocumentStatus.AC, value: 'AC' },
    { id: DocumentStatus.AE, value: 'AE' },
    { id: DocumentStatus.AF, value: 'AF' },
    { id: DocumentStatus.AG, value: 'AG' },
    { id: DocumentStatus.AP, value: 'AP' },
    { id: DocumentStatus.AS, value: 'AS' },
    { id: DocumentStatus.AT, value: 'AT' },
    { id: DocumentStatus.CC, value: 'CC' },
    { id: DocumentStatus.EA, value: 'EA' },
    { id: DocumentStatus.EE, value: 'EE' },
    { id: DocumentStatus.EL, value: 'EL' },
    { id: DocumentStatus.EP, value: 'EP' },
    { id: DocumentStatus.ES, value: 'ES' },
    { id: DocumentStatus.FP, value: 'FP' },
    { id: DocumentStatus.GE, value: 'GE' },
    { id: DocumentStatus.GP, value: 'GP' },
    { id: DocumentStatus.HP, value: 'HP' },
    { id: DocumentStatus.IA, value: 'IA' },
    { id: DocumentStatus.IE, value: 'IE' },
    { id: DocumentStatus.IP, value: 'IP' },
    { id: DocumentStatus.IS, value: 'IS' },
    { id: DocumentStatus.JA, value: 'JA' },
    { id: DocumentStatus.JE, value: 'JE' },
    { id: DocumentStatus.JP, value: 'JP' },
    { id: DocumentStatus.JS, value: 'JS' },
    { id: DocumentStatus.LE, value: 'LE' },
    { id: DocumentStatus.LP, value: 'LP' },
    { id: DocumentStatus.RE, value: 'RE' },
    { id: DocumentStatus.SP, value: 'SP' },
    { id: DocumentStatus.TP, value: 'TP' },
    { id: DocumentStatus.UA, value: 'UA' },
    { id: DocumentStatus.UE, value: 'UE' },
    { id: DocumentStatus.UP, value: 'UP' },
    { id: DocumentStatus.US, value: 'US' },
    { id: DocumentStatus.XA, value: 'XA' },
    { id: DocumentStatus.XB, value: 'XB' },
    { id: DocumentStatus.XF, value: 'XF' },
    { id: DocumentStatus.XU, value: 'XU' },
    { id: DocumentStatus.XW, value: 'XW' },
    { id: DocumentStatus.XX, value: 'XX' },
] as const;
