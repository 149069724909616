import { DeleteOutlined } from '@ant-design/icons';
import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';
import { ConfigProvider, Modal } from 'antd';
import { TableCurrentDataSource, TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import Button from 'components/ui/base/button/Button';
import Table from 'components/ui/base/table/Table';
import { Text } from 'components/ui/base/typography';
import ListTags from 'components/ui/composed/tag/ListTags';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import useProducts from 'hooks/useProductsTemplates';
import { FC, useState, useCallback, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { ProductTemplate } from 'store/products-templates/products';
import { UkProductTemplate } from 'store/products-templates/ukProducts';
import { EmptyRenderCol, EmptyRenderIcon, EmptyRenderRow } from '../Products.styles';
import { useTranslation } from 'react-i18next';
import { FlexDiv, SpanEllipsis } from '../../declaration-table/components/DeclarationsTable.styled';
import { ProductTabKeys } from '../ListProductTemplates';
import { transformCdsExportProductTemplate } from '../productTemplateUtils';
import { TemplateResponse } from 'store/template/template';
import { capitalize } from 'lodash';

const customizeRenderEmpty = () => (
    <EmptyRenderRow>
        <EmptyRenderCol>
            <EmptyRenderIcon />
            <Text>You don't have any products yet.</Text>
        </EmptyRenderCol>
    </EmptyRenderRow>
);

export type Product<TExtends = unknown> = ProductTemplate<TExtends> | UkProductTemplate<TExtends>;

interface Props {
    source: ListPayload<Product>;
    refresh: Function;
    handlePagination: (paginator: Partial<PaginatedParams>) => void;
    handleSort?: (paginator: Partial<PaginatedParams>) => void;
    commands?: boolean;
    addToDeclaration?: boolean;
    handleAddToDeclaration?: Function;
    country?: DeclarationCountry;
    loading?: boolean;
    onDelete?: (ids: string[]) => void;
    addProductsToDeclaration?: Function;
    internalType?: string;
    declarationTemplatesList?: TemplateResponse[] | null;
}

const ProductsTable: FC<Props> = ({
    source,
    refresh,
    handlePagination,
    handleSort,
    commands = true,
    addToDeclaration,
    country,
    handleAddToDeclaration,
    loading,
    onDelete,
    addProductsToDeclaration,
    internalType,
    declarationTemplatesList,
}) => {
    const { type } = useParams<{ type: ProductTabKeys }>();
    const navigate = useNavigate();
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const {
        saveH1ProductTemplate,
        saveUkProductTemplate,
        saveH7ProductTemplate,
        saveCdsExportProductTemplate,
        saveEnsProductTemplate,
    } = useProducts({ country, type });
    const [isAddProductToDeclarationModalOpen, setAddProductToDeclarationModalOpen] = useState<boolean>(false);
    const [productToAddId, setProductToAddId] = useState<string | undefined>(undefined);
    const { t } = useTranslation();
    const location = useLocation();

    const rowSelection: TableRowSelection<any> = {
        type: 'checkbox',
        onChange: (selectedRowKeys: any, selectedRows: Product[]) => {
            if (!addToDeclaration) {
                const ids = selectedRows.filter((d) => d.id).map((c) => c.id) as string[];
                setDeleteIds(ids);
            } else {
                if (handleAddToDeclaration) {
                    const p = selectedRows.filter((p) => p);
                    handleAddToDeclaration(p);
                }
            }
        },
    };

    const handleDuplicate = useCallback(
        async (productTemplate: Product) => {
            delete productTemplate.id;

            if (country === DeclarationCountry.UK) {
                await (type === ProductTabKeys.B1
                    ? saveCdsExportProductTemplate(transformCdsExportProductTemplate(productTemplate))
                    : saveUkProductTemplate(productTemplate as ProductTemplate));
            } else {
                if (type === ProductTabKeys.H7) {
                    await saveH7ProductTemplate(productTemplate as ProductTemplate);
                } else if (type === ProductTabKeys.ENS) {
                    await saveEnsProductTemplate(transformCdsExportProductTemplate(productTemplate));
                } else {
                    await saveH1ProductTemplate(productTemplate as ProductTemplate);
                }
            }
            refresh();
        },
        [
            country,
            type,
            saveUkProductTemplate,
            saveH7ProductTemplate,
            saveH1ProductTemplate,
            saveCdsExportProductTemplate,
            saveEnsProductTemplate,
            refresh,
        ]
    );

    const onChange = (
        pagination: TablePaginationConfig,
        filters: any,
        sorter: any,
        extra: TableCurrentDataSource<any>
    ) => {
        if (handleSort && !Array.isArray(sorter)) {
            const sortParameter = sorter.columnKey?.toString();
            if (sorter.order === 'ascend') {
                handleSort({ sortParameter, sortDirection: 'ASC' });
            } else if (sorter.order === 'descend') {
                handleSort({ sortParameter, sortDirection: 'DESC' });
            }
        }
    };

    const addToDeclarationHandler = () => {
        if (addProductsToDeclaration) {
            const p = source.list.filter((p) => p.id === productToAddId);
            addProductsToDeclaration(p);
        }
        setAddProductToDeclarationModalOpen(false);
    };

    const handleTags = (tags: string[]) => {
        if (tags?.length) {
            return <ListTags tags={tags} vertical />;
        } else {
            return <span>-</span>;
        }
    };

    const getProductType = (product: Product) => {
        const externalEntity = country ? capitalize(country) : '-';
        const internalEntity = internalType ? capitalize(internalType) : '-';
        const templateType = product?.templateType?.toUpperCase() ?? '-';

        return (
            <FlexDiv>
                <SpanEllipsis>{externalEntity}</SpanEllipsis>
                <SpanEllipsis>{`${internalEntity} (${templateType})`}</SpanEllipsis>
            </FlexDiv>
        );
    };

    const getCommodityCode = (product: Product<any>) => {
        const irelandH1CommodityCode = product?.goodsInformation?.combinedNomenclatureCode;
        const irelandH7CommodityCode = product?.commodityCodeHarmonizedSystemSubHeadingCode;
        const ukH1CommodityCode = product?.commodityCombinedNomenclatureTypeCode;
        const ukB1CommodityCode = product?.commodity?.classification?.[0]?.id;
        const ensCommodityCode = product?.commodity?.combinedNomenclature;

        const commodityCode =
            irelandH1CommodityCode ??
            irelandH7CommodityCode ??
            ukH1CommodityCode ??
            ukB1CommodityCode ??
            ensCommodityCode ??
            '-';

        return <SpanEllipsis>{commodityCode}</SpanEllipsis>;
    };

    const getDescriptionOfGoods = (product: Product<any>) => {
        const irelandH1DescriptionOfGoods = product?.goodsInformation?.goodsDescription;
        const irelandH7DescriptionOfGoods = product?.descriptionOfGoods;
        const ukH1DescriptionOfGoods = product?.goodsDescription;
        const ukB1EnsDescriptionOfGoods = product?.commodity?.description;

        const descriptionOfGoods =
            irelandH1DescriptionOfGoods ??
            irelandH7DescriptionOfGoods ??
            ukH1DescriptionOfGoods ??
            ukB1EnsDescriptionOfGoods ??
            '-';

        return <SpanEllipsis>{descriptionOfGoods}</SpanEllipsis>;
    };

    const getItemPrice = (product: Product<any>) => {
        const irelandH1ItemPrice = product?.itemAmount;
        const irelandH7ItemPrice = product?.itemAmountInvoicedIntrinsicValue?.valueAmount;
        const ukH1ItemPrice = product?.itemPriceAmount;
        const ukB1ItemPrice = product?.statisticalValueAmount;

        const itemPrice = irelandH1ItemPrice ?? irelandH7ItemPrice ?? ukH1ItemPrice ?? ukB1ItemPrice ?? '-';

        return <SpanEllipsis>{itemPrice}</SpanEllipsis>;
    };

    const declarationTemplateNames = useMemo(
        () =>
            declarationTemplatesList?.reduce((acc, template) => {
                acc.set(template.id, template.templateName);
                return acc;
            }, new Map()),
        [declarationTemplatesList]
    );

    const getDeclarationTemplate = (product: Product) => {
        const declarationTemplateName = declarationTemplateNames?.get(product?.declarationTemplateId);
        return <SpanEllipsis>{declarationTemplateName ?? 'DEFAULT'}</SpanEllipsis>;
    };

    const columns = [
        {
            title: 'Product Type',
            dataIndex: 'product_type',
            key: 'product_type',
            render: (text: string, record: Product) => record && getProductType(record),
            sorter: true,
        },
        {
            title: 'Declaration Template',
            dataIndex: 'declaration_template',
            key: 'declaration_template',
            render: (text: string, record: Product) => record && getDeclarationTemplate(record),
            sorter: true,
        },
        {
            title: 'Commodity Code',
            dataIndex: 'commodity_code',
            key: 'commodity_code',
            render: (text: string, record: Product) => record && getCommodityCode(record),
            sorter: true,
        },
        {
            title: 'Description of Goods',
            dataIndex: 'description_of_goods',
            key: 'description_of_goods',
            render: (text: string, record: Product) => record && getDescriptionOfGoods(record),
            sorter: true,
        },
        {
            title: 'Item Price',
            dataIndex: 'item_price',
            key: 'item_price',
            render: (text: string, record: Product) => record && getItemPrice(record),
            sorter: true,
        },
        {
            title: 'Tag',
            dataIndex: 'tag',
            key: 'tag',
            render: (text: string, record: Product) => handleTags(record.tags ?? []),
            sorter: true,
        },
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            render: (text: string, record: Product) => (
                <>
                    <Button
                        style={{ marginRight: '1.6rem' }}
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDuplicate?.(record);
                        }}
                    >
                        Duplicate <CopyOutlined />
                    </Button>
                    <Button
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete?.([record.id as string]);
                        }}
                    >
                        Delete <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    const getColumns = () => (!commands ? columns.filter((c) => c.title !== 'Commands') : columns);

    return (
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Modal
                visible={isAddProductToDeclarationModalOpen}
                onCancel={() => setAddProductToDeclarationModalOpen(false)}
                title={t('addProductDeclarationModal.title', { ns: 'customs_declarations' })}
                data-testId="unsaved-changes-modal"
                footer={[
                    <Button
                        onClick={() => setAddProductToDeclarationModalOpen(false)}
                        data-testId="cancel-button-unsaved-changes-modal"
                    >
                        {t('buttons.cancel', { ns: 'common' })}
                    </Button>,
                    <Button onClick={() => addToDeclarationHandler()} data-testId="leave-button-unsaved-changes-modal">
                        {t('buttons.confirm', { ns: 'common' })}
                    </Button>,
                ]}
            >
                {t('addProductDeclarationModal.content', { ns: 'customs_declarations' })}
            </Modal>

            <div className="config-provider">
                <Table
                    rowKey={(record: Product) => source?.list.findIndex((r) => record === r)}
                    columns={getColumns()}
                    dataSource={source.list}
                    rowSelection={rowSelection}
                    onChange={onChange}
                    loading={loading}
                    onRow={(record: any) => {
                        return {
                            onClick: () => {
                                if (location.pathname.includes(`/declarations`)) {
                                    setAddProductToDeclarationModalOpen(true);
                                    setProductToAddId(record.id);
                                } else {
                                    navigate(`${record.id}`);
                                }
                            },
                        };
                    }}
                    pagination={{
                        current: source?.pageNumber + 1,
                        total: source?.total,
                        showSizeChanger: false,
                        pageSize: source?.pageSize,
                        position: ['bottomCenter'],
                        onChange: (page?: number, size?: number) => {
                            if (page && size) {
                                const params = { page: page - 1, size };
                                handlePagination(params);
                            }
                        },
                    }}
                />
                {deleteIds.length ? (
                    <Button
                        type="primary"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            onDelete && onDelete(deleteIds);
                            setDeleteIds([]);
                        }}
                    >
                        delete
                    </Button>
                ) : (
                    <></>
                )}
            </div>
        </ConfigProvider>
    );
};
export default ProductsTable;
