import { TextSmall } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors } from 'theme';

export const StyledTextSmall = styled(TextSmall)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 250px;
    display: inline-block;
`;

export const DocumentType = styled(TextSmall)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 55px;
    display: inline-block;
`;

export const GreyText = styled(TextSmall)`
    color: ${colors.darkGrey};
    margin-bottom: 3.2rem;
`;

export const Icon = styled.span`
    cursor: pointer;
    font-size: 1.4rem;
`;

export const ListRow = styled.div<{ hasError?: boolean }>`
    display: grid;
    grid-template-columns: auto min-content;
    padding: 0 0.5rem;
    gap: 0 2rem;

    border-radius: 8px;

    transition: 100ms;
    pointer-events: none;

    outline: ${({ hasError }) => (hasError ? '1px solid red' : 'none')};

    &:hover {
        background-color: #f5f5f5;
    }
`;

export const ListRowControls = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
    pointer-events: auto;
`;
