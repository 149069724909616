import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import CurrencySelect from '../../../../../components/ui/composed/declarations/CurrencySelect';
import { ValidationSchema, validators } from '../validations/validations';

export type Fields = 'statisticalValueAmount' | 'statisticalValueAmountCurrencyId';

export const statisticalValueBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        statisticalValueAmount: [validators.number(), validators.float(15, 2)],
        statisticalValueAmountCurrencyId: [validators.exact(3)],
    },
};

interface Props extends BlockProps<Fields> {}

const StatisticalValueBlock = ({ fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '8/6', []);
    return (
        <>
            <DeclarationField name="statisticalValueAmount">
                {({ form, field }: FieldProps<any>) => (
                    <DeclarationNumberInput
                        refNumber={ucc}
                        maxLength={15}
                        label={t('statisticalValueAmount')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField name="statisticalValueAmountCurrencyId">
                {({ form, field }: FieldProps<any>) => (
                    <CurrencySelect
                        refNumber={ucc}
                        label={t('statisticalValueCurrency')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default StatisticalValueBlock;
