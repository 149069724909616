import { Col, Tag, Tooltip } from 'antd';
import { TestProps } from 'core/utils/testTypes';
import { FC, useEffect, useMemo, useState } from 'react';
import { StyledPanel } from '../../../Form.styles';
import { CardState } from '../card-state';
import { ButtonsCol, ButtonsRow, Title } from '../FormCard.styles';
import MultipleCardButtons from '../MultipleCardButtons';
import { CardRow, Header } from './BorderlessCard.styles';
import BorderlessCollapse from './BorderlessCollapse';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { useDeclarationContext } from 'utils/DeclarationContext';
import { useProductTemplateContext } from 'utils/ProductTemplateContext';
import { cloneDeep } from 'lodash';
import useDeclarations from 'hooks/useDeclarations';
import { CardContext, FieldData } from 'components/ui/composed/template/CardContext';
import { useLocation } from 'react-router-dom';
import TemplateCheckbox from 'components/ui/composed/template/TemplateCheckbox';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface Props extends TestProps {
    title: string;
    multiple?: boolean;
    key?: number;
    addCard?: () => void;
    removeCard?: () => void;
    isLast?: boolean;
    isFirst?: boolean;
    invalid?: boolean;
    viewOnly?: boolean;
    defaultOpen?: boolean;
    lastCard?: boolean;
    state?: CardState;
    expandAll?: boolean;
    keyCard?: string | number;
    children: React.ReactNode;
    mic?: { path: string; required?: boolean };
}

const BorderlessCard: FC<Props> = ({
    title,
    children,
    multiple,
    key,
    addCard,
    removeCard,
    isFirst,
    isLast,
    invalid,
    viewOnly,
    defaultOpen,
    expandAll,
    keyCard = 0,
    lastCard,
    state,
    testId,
    mic,
}) => {
    const invalidState = useMemo(
        () => state === CardState.INVALID,

        [state]
    );

    const location = useLocation();
    const { form: templateForm, template, isViewOnly: isTemplateViewOnly, templateFormik } = useTemplateContext();
    const { declarationTemplate: productTemplateDeclarationTemplate } = useProductTemplateContext();
    const { form, registerCard } = useDeclarationContext();
    const { declarationTemplate } = useDeclarations();
    const inProductForm = location.pathname.includes('products');

    const [cardCheckboxChecked, setCardCheckboxChecked] = useState(true);

    const templateData = useMemo(
        () => productTemplateDeclarationTemplate?.template || declarationTemplate?.template,
        [productTemplateDeclarationTemplate, declarationTemplate?.template]
    );

    const [fields, setFields] = useState<FieldData[]>([]);

    const registerField = (fieldData: FieldData) => {
        setFields((prev) => {
            const index = prev.findIndex((el) => fieldData.path === el.path);
            if (index === -1) prev.push(fieldData);
            else prev[index] = fieldData;
            return [...prev];
        });
    };

    const cardHidden = useMemo(() => {
        if (template) return false;
        const _form = productTemplateDeclarationTemplate ? 'product' : form;
        if (!_form) return false;
        const meta = cloneDeep(templateData?.[_form].meta);
        if (!meta) return false;
        if (mic && Object.keys(meta).includes(mic.path))
            return !meta[mic.path]?.isEditable && !meta[mic.path]?.isViewable;
        if (!fields.length) return false; // The card must render once in order to register the fields
        return fields.every(
            (field) => meta?.[field.path] && !meta[field.path]?.isEditable && !meta[field.path]?.isViewable
        );
    }, [template, productTemplateDeclarationTemplate, form, templateData, mic, fields]);

    useEffect(() => {
        registerCard?.({ title, visible: !cardHidden, form: inProductForm ? 'product' : 'master', section: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardHidden]);

    const cardContentHidden = useMemo(() => template && mic, [mic, template]);

    const handleCardCheckboxCheck = (e: CheckboxChangeEvent) => {
        if (!templateForm) return;
        let meta = cloneDeep(templateFormik?.values[templateForm].meta);
        if (!meta) return;
        if (mic) {
            templateFormik?.setFieldValue(`${templateForm}.meta.['${mic.path}']`, {
                isEditable: e.target.checked,
                isViewable: e.target.checked,
            });
            return;
        }
        fields.forEach((field) => {
            if (!field.required) meta![field.path] = { isEditable: e.target.checked, isViewable: false };
        });
        templateFormik?.setFieldValue(`${templateForm}.meta`, { ...meta });
    };

    useEffect(() => {
        if (!template || mic || !templateForm) return;
        let meta = cloneDeep(templateFormik?.values[templateForm].meta);
        setCardCheckboxChecked(
            fields.some(
                (field) =>
                    !field.required &&
                    (meta?.[field.path]?.isEditable || meta?.[field.path]?.isViewable || !meta?.[field.path])
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields, templateFormik?.values]);

    const _cardCheckboxChecked = useMemo(() => {
        if (!templateForm) return false;
        if (mic) {
            let micMeta = cloneDeep(templateFormik?.values[templateForm].meta[mic.path]);
            return !micMeta || (micMeta?.isEditable && micMeta?.isViewable);
        }
        return cardCheckboxChecked;
    }, [cardCheckboxChecked, mic, templateForm, templateFormik]);

    if (cardHidden) return null;

    const renderCard = () => (
        <BorderlessCollapse
            lastCard={lastCard}
            invalid={invalidState}
            viewOnly={viewOnly}
            expandAll={expandAll}
            defaultActiveKey={defaultOpen ? 0 : undefined}
            keyCard={keyCard}
            testId={testId}
        >
            <StyledPanel
                id={`${title}-form-card`}
                data-testid={`${title}-form-card`}
                forceRender
                header={
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Header>
                            <Title invalid={invalidState}> {title}</Title>
                            {mic && (
                                <div style={{ fontWeight: 'normal', marginLeft: '1rem', marginTop: '-0.5rem' }}>
                                    <Tooltip overlay="Multiple Items Card">
                                        <Tag color="blue">MIC</Tag>
                                    </Tooltip>
                                </div>
                            )}
                        </Header>
                        {template && !isTemplateViewOnly && (
                            <div>
                                <TemplateCheckbox
                                    size="medium"
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={handleCardCheckboxCheck}
                                    checked={_cardCheckboxChecked}
                                />
                            </div>
                        )}
                    </div>
                }
                collapsible={cardContentHidden ? 'disabled' : undefined}
                showArrow={!cardContentHidden}
                key={keyCard}
            >
                {!cardContentHidden && (
                    <>
                        <CardContext.Provider value={{ registerField }}>{children}</CardContext.Provider>
                        {multiple && !viewOnly && addCard && (
                            <ButtonsRow>
                                <ButtonsCol span={24}>
                                    {
                                        <MultipleCardButtons
                                            isFirst={isFirst}
                                            isLast={isLast}
                                            removeCard={removeCard}
                                            addCard={addCard}
                                        />
                                    }
                                </ButtonsCol>
                            </ButtonsRow>
                        )}
                    </>
                )}
            </StyledPanel>
        </BorderlessCollapse>
    );

    return (
        <CardRow>
            <Col span={24}>{renderCard()}</Col>
        </CardRow>
    );
};
export default BorderlessCard;
