import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import ContainerBlock, { containerBlockValidation } from '../blocks/ContainerBlock';
import GrossMassBlock, { grossMassBlockValidation } from '../blocks/GrossMassBlock';
import TotalPackagesBlock, { totalPackagesBlockValidation } from '../blocks/TotalPackagesBlock';

export const grossMassCardValidation = {
    selfValidators: [grossMassBlockValidation, totalPackagesBlockValidation, containerBlockValidation],
};

interface Props extends DeclarationFormCardProps {
    fieldOptions?: FieldOptions<'totalGrossMassMeasure' | 'totalPackageQuantity' | 'containerCode'>;
}

const GrossMassCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('goodsItems.grossMass')}>
            <FormCardContainer>
                <GrossMassBlock
                    fieldOptions={{
                        totalGrossMassMeasure: {
                            validation: { float: props.fieldOptions?.totalGrossMassMeasure?.validation?.float },
                            required: props.fieldOptions?.totalGrossMassMeasure?.required,
                        },
                    }}
                />
                <TotalPackagesBlock
                    fieldOptions={{
                        totalPackageQuantity: {
                            required: true,
                            validation: { max: props.fieldOptions?.totalPackageQuantity?.validation?.max },
                        },
                    }}
                />
                <ContainerBlock fieldOptions={{ containerCode: { required: true } }} />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default GrossMassCard;
