import { Col, Row } from 'antd';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import DeclarationField from '../../../common/DeclarationField';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import CurrencySelect from '../../../../../components/ui/composed/declarations/CurrencySelect';

interface Props extends BlockProps<'paymentAmount' | 'paymentAmountCurrencyId'> {}

const CalculationOfTaxesBlock = ({
    path = 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee.payment',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '4/6', []);

    return (
        <Row gutter={18}>
            <Col xs={fieldOptions?.paymentAmount?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="paymentAmount"
                    validation={{
                        name: t('payableTaxAmount'),
                        number: true,
                        float: [16, 2],
                        required: true,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormInput
                            required
                            refNumber={ucc}
                            label={t('payableTaxAmount')}
                            {...getFormikProps(field.name, form)}
                        />
                    )}
                </DeclarationField>
            </Col>

            <Col xs={fieldOptions?.paymentAmountCurrencyId?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="paymentAmountCurrencyId"
                    validation={{
                        name: t('payableTaxAmountCurrency'),
                        exact: 3,
                        required: true,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <CurrencySelect
                            required
                            refNumber={ucc}
                            label={t('payableTaxAmountCurrency')}
                            {...getFormikProps(field.name, form)}
                        />
                    )}
                </DeclarationField>
            </Col>
        </Row>
    );
};

export default CalculationOfTaxesBlock;
