import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { ColumnsType, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import Button from 'components/ui/base/button';
import Table from 'components/ui/base/table/Table';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import { dateStandardFormat } from 'core/utils/date';
import { isValid, parseISO } from 'date-fns';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DigitalCertificate,
    DigitalCertificateSortParameter,
    digitalCertificateType,
} from '../../../../store/digital-certificates/digital-certificates';

interface Props {
    data?: ListPayload<DigitalCertificate>;
    onDelete?: (ids: string[]) => void;
    handlePagination?: (paginator: Partial<PaginatedParams>) => void;
    handleSort?: (paginator: Partial<PaginatedParams>) => void;
    loading?: boolean;
}

const DigitalCertificateTable: FC<Props> = ({ data, onDelete, handlePagination, handleSort, loading }) => {
    const { t } = useTranslation('settings');
    const [deleteIds, setDeleteIds] = useState<string[]>([]);

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: DigitalCertificate[]) => {
            const ids = selectedRows.map((c) => c.id);
            setDeleteIds(ids);
        },
    };

    const handleNullValues = (value?: string) => (value ? <span>{value}</span> : <span>-</span>);
    const handleDate = (value: string) => {
        const date = parseISO(value);
        return isValid(date) ? <span>{dateStandardFormat(date)}</span> : <span>-</span>;
    };

    const columns: ColumnsType<DigitalCertificate> = [
        {
            title: t('digitalCertificate.labels.type'),
            dataIndex: DigitalCertificateSortParameter.TYPE,
            key: DigitalCertificateSortParameter.TYPE,
            align: 'left',

            sorter: true,
            render: (text: string, record: DigitalCertificate, index: number) =>
                handleNullValues(digitalCertificateType.find((t) => t.id === record.digitalCertificateType)?.value),
        },
        {
            title: t('digitalCertificate.labels.title'),
            dataIndex: DigitalCertificateSortParameter.TITLE,
            key: DigitalCertificateSortParameter.TITLE,
            align: 'left',

            sorter: true,
            render: (text: string, record: DigitalCertificate, index: number) => handleNullValues(record.title),
        },
        {
            title: t('digitalCertificate.labels.filename'),
            dataIndex: DigitalCertificateSortParameter.FILENAME,
            key: DigitalCertificateSortParameter.FILENAME,
            align: 'left',
            sorter: true,
            render: (text: string, record: DigitalCertificate, index: number) => handleNullValues(record.filename),
        },
        {
            title: t('digitalCertificate.labels.date'),
            dataIndex: DigitalCertificateSortParameter.CREATED_AT,
            key: DigitalCertificateSortParameter.CREATED_AT,
            align: 'left',

            sorter: true,
            render: (text: string, record: DigitalCertificate, index: number) => handleDate(record.createdAt),
        },
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            render: (text: string, record: DigitalCertificate, index: number) => {
                return (
                    <Button size="small" onClick={() => onDelete && onDelete([record.id!])}>
                        Delete <DeleteOutlined />
                    </Button>
                );
            },
        },
    ];

    function onChange(
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<any> | SorterResult<any>[],
        extra: TableCurrentDataSource<any>
    ) {
        if (!Array.isArray(sorter) && sorter.columnKey) {
            const sortParameter = sorter.columnKey?.toString();
            if (sorter.order === 'ascend') {
                handleSort && handleSort({ sortParameter, sortDirection: 'ASC' });
            } else if (sorter.order === 'descend') {
                handleSort && handleSort({ sortParameter, sortDirection: 'DESC' });
            }
        }
    }

    return (
        <>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data?.list}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                pagination={{
                    defaultCurrent: 1,
                    total: data?.total,
                    showSizeChanger: false,
                    pageSize: data?.pageSize,
                    position: ['bottomCenter'],
                    onChange: (page?: number, size?: number) => {
                        if (page) {
                            const params = { page: page - 1, size };
                            handlePagination && handlePagination(params);
                        }
                    },
                }}
                onChange={onChange}
                loading={loading}
            />
            {deleteIds.length ? (
                <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        onDelete && onDelete(deleteIds);
                        setDeleteIds([]);
                    }}
                >
                    delete
                </Button>
            ) : (
                <></>
            )}
        </>
    );
};

export default DigitalCertificateTable;
