import { Col, Row } from 'antd';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import DeclarationField from '../../../common/DeclarationField';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { additionsDeductionsTypes } from 'store/declarations/enums/uk/additions-deduction-types';
import CurrencySelect from '../../../../../components/ui/composed/declarations/CurrencySelect';

interface Props
    extends BlockProps<'otherChargeDeductionAmount' | 'otherChargeDeductionAmountCurrencyId' | 'chargesTypeCode'> {}

const AdditionsDeductionsBlock = ({
    path = 'goodsShipment.customsValuation.chargeDeduction',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '4/9', []);

    return (
        <Row gutter={18}>
            <Col xs={fieldOptions?.otherChargeDeductionAmount?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="otherChargeDeductionAmount"
                    validation={{
                        name: t('value'),
                        number: true,
                        float: [16, 2],
                        required: true,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormInput required refNumber={ucc} label={t('value')} {...getFormikProps(field.name, form)} />
                    )}
                </DeclarationField>
            </Col>

            <Col xs={fieldOptions?.otherChargeDeductionAmountCurrencyId?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="otherChargeDeductionAmountCurrencyId"
                    validation={{
                        name: t('currency'),
                        exact: 3,
                        required: true,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <CurrencySelect
                            required
                            refNumber={ucc}
                            label={t('currency')}
                            {...getFormikProps(field.name, form)}
                        />
                    )}
                </DeclarationField>
            </Col>

            <Col xs={fieldOptions?.chargesTypeCode?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="chargesTypeCode"
                    validation={{
                        name: t('type'),
                        exact: 2,
                        required: true,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormSelect
                            required
                            refNumber={ucc}
                            label={t('type')}
                            {...getFormikProps(field.name, form)}
                            selectOptions={additionsDeductionsTypes}
                        />
                    )}
                </DeclarationField>
            </Col>
        </Row>
    );
};

export default AdditionsDeductionsBlock;
