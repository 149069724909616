import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { IndividualType } from 'store/individuals/individual';
import { SelectOption } from '../formSelect/FormSelect';
import TagButton from './TagButton';
import { SelectOptions, StyledGroup, StyledRadio, StyledSpace } from './TagsMenu.styles';

interface Props {
    role: IndividualType;
    selectOptions?: Readonly<SelectOption[]>;
    updateRole: (role: IndividualType) => void;
    disabled?: boolean;
}

const TagsMenu: FC<Props> = ({ selectOptions, role, updateRole, disabled = false }) => {
    const [value, setValue] = useState(role);
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const checkIfClickedOutside = useCallback(
        (e: MouseEvent) => {
            if (!ref.current) {
                return;
            }
            const div: HTMLDivElement = ref.current;
            if (!div.contains(e.target as Node)) {
                setOpen(false);
            }
        },
        [ref]
    );
    useEffect(() => {
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [checkIfClickedOutside]);

    const checkSelected = useCallback((role: string) => value === role, [value]);

    return (
        <>
            <TagButton open={open} setOpen={setOpen} role={role} />
            {open && (
                <SelectOptions ref={ref}>
                    <StyledGroup
                        disabled={disabled}
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value);
                            updateRole(e.target.value);
                            setOpen(false);
                        }}
                    >
                        <StyledSpace direction="vertical">
                            {selectOptions?.map((item, i: number) => (
                                <StyledRadio
                                    key={`option-${i}`}
                                    value={item.id}
                                    selected={checkSelected(item.id.toString())}
                                >
                                    {item.value}
                                </StyledRadio>
                            ))}
                        </StyledSpace>
                    </StyledGroup>
                </SelectOptions>
            )}
        </>
    );
};

export default TagsMenu;
