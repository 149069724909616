import { useFormik } from 'formik';
import useAisBusinessRules from 'hooks/useAisBusinessRules';
import { FC, useMemo } from 'react';
import { SubmissionErrors } from 'store/declarations/ireland/ireland-notification';
import AisBusinessRuleRow from 'views/documentation/ais-documentation/AisBusinessRuleRow';
import {
    DetailsFieldsLabel,
    DetailsLabel,
    DetailsLabelDiv,
    DetailsValue,
    DetailsValueDiv,
    StyledErrorRow,
    StyledHr,
    Title,
} from '../DeclarationErrorsDrawer.styles';

export const ErrorRow: FC<{ label: string; value?: string | number }> = ({ label, value }) => {
    return (
        <>
            <StyledErrorRow>
                <DetailsLabelDiv>
                    <DetailsLabel>{label}</DetailsLabel>
                </DetailsLabelDiv>
                <DetailsValueDiv>
                    <DetailsValue>{`${value ?? '-'}`}</DetailsValue>
                </DetailsValueDiv>
            </StyledErrorRow>
        </>
    );
};

const ListErrorsRows: FC<{ section: string; fields: string[]; handleOnClickField?: (value: string[]) => void }> = ({
    fields,
    handleOnClickField,
    section,
}) => {
    return (
        <>
            <StyledErrorRow>
                <DetailsLabelDiv>
                    {handleOnClickField ? (
                        <DetailsFieldsLabel
                            onClick={() => {
                                handleOnClickField(fields);
                            }}
                        >
                            {section}
                        </DetailsFieldsLabel>
                    ) : (
                        <DetailsLabel>{section}</DetailsLabel>
                    )}
                </DetailsLabelDiv>
                <DetailsLabelDiv>
                    {fields.map((field) => {
                        return (
                            <DetailsValueDiv>
                                <DetailsValue>{`${field ?? '-'}`}</DetailsValue>
                            </DetailsValueDiv>
                        );
                    })}
                </DetailsLabelDiv>
            </StyledErrorRow>
        </>
    );
};

export const RevenueImportErrorDetails: FC<{
    details: SubmissionErrors;
    formik?: ReturnType<typeof useFormik>;
}> = ({ details, formik }) => {
    const { rules } = useAisBusinessRules();

    const businessRule = useMemo(() => {
        const r = rules.find((p) => p.code === details.businessRuleCode);

        if (!r) return <></>;

        return (
            <>
                <StyledHr></StyledHr>
                <Title>Revenue business rule</Title>
                <AisBusinessRuleRow rule={r} />
                <span>
                    <a
                        href="https://www.revenue.ie/en/online-services/support/software-developers/documents/ais/ais-business-rules-and-conditions.pdf"
                        rel="noreferrer"
                        target="_blank"
                    >
                        AIS Business Rules and Conditions
                    </a>
                </span>
            </>
        );
    }, [details, rules]);

    const handleOnClickField = async (fields: string[]) => {
        if (!formik) {
            return;
        }
        fields.forEach((field) => {
            if (Array.isArray(formik.getFieldProps(field).value)) {
                formik.getFieldHelpers(field).setError(details?.irelandTranslationTable?.message ?? '-');
                formik?.setFieldTouched(field, true, false);
            } else {
                formik.getFieldHelpers(field).setError(details?.irelandTranslationTable?.message ?? '-');
                formik?.setFieldTouched(field, true, false);
            }
        });
    };

    return (
        <>
            <Title>Error details</Title>
            <ErrorRow label="Code:" value={details?.irelandTranslationTable?.code ?? '-'} />
            <ErrorRow label="Value:" value={details?.value ?? '-'} />
            <ListErrorsRows section="Fields" fields={details?.fields ?? []} handleOnClickField={handleOnClickField} />
            <ErrorRow label="Message:" value={details?.irelandTranslationTable?.message ?? '-'} />
            <ErrorRow label="Error Message:" value={details?.message ?? '-'} />
            <ErrorRow label="Remarks:" value={details?.remarks ?? '-'} />
            <ErrorRow label="Date:" value={details?.date ?? '-'} />
            <ErrorRow label="Pattern Required:" value={details?.patternRequired ?? '-'} />
            <ErrorRow label="Error Reason:" value={details?.errorReason ?? '-'} />
            <ErrorRow label="External Field:" value={details?.externalField ?? '-'} />
            <ErrorRow label="External Error Message:" value={details?.externalErrorMessage ?? '-'} />
            <ListErrorsRows section="Missing Documents:" fields={details?.missingDocuments ?? []} />
            {details?.businessRuleCode ? businessRule : <></>}
        </>
    );
};
