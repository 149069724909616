import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { transportChargesMethod } from 'store/declarations/enums/uk/transport-charges-method';

import { ValidationSchema, validators } from '../validations/validations';
import { DeclarationBlock } from '../../../../../utils/DeclarationBlock';

type Fields = 'paymentMethodCode';

export const transportChargesMethodOfPaymentBlockValidation: {
    childValidators: {
        'consignment.freight': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'consignment.freight': {
            childValidators: {
                paymentMethodCode: [validators.exact(1)],
            },
        },
    },
};

export const consignmentItemsBlockValidation: {
    childValidators: {
        'consignment.consignmentItem.freight': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'consignment.consignmentItem.freight': {
            childValidators: {
                paymentMethodCode: [validators.exact(1)],
            },
        },
    },
};

export const consignmentItemsFields =
    consignmentItemsBlockValidation.childValidators['consignment.consignmentItem.freight'];

interface Props extends BlockProps<Fields> {
    isProduct?: boolean;
}

const TransportChargesMethodOfPaymentBlock = ({
    path = 'consignment.freight',
    isProduct = false,
    hide,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '4/2', []);
    return (
        <DeclarationBlock hide={hide}>
            <DeclarationField name="paymentMethodCode" pathPrefix={path}>
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        label={t('transportChargesMethodOfPayment' + (isProduct ? 'Products' : ''))}
                        {...getFormikProps(field.name, form)}
                        selectOptions={transportChargesMethod}
                        condensed
                    />
                )}
            </DeclarationField>
        </DeclarationBlock>
    );
};

export default TransportChargesMethodOfPaymentBlock;
