import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ValidationSchema, validators } from '../validations/validations';
import { additionalInformationCodeItem } from 'store/declarations/enums/uk/additional-information-code-item';

type Fields = 'statementCode' | 'statementDescription';

export const additionalInformationBlockValidation: {
    childValidators: {
        additionalInformation: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        additionalInformation: {
            childValidators: {
                statementCode: [validators.required(), validators.exact(5)],
                statementDescription: [validators.max(70)],
            },
            stopAtNull: true,
        },
    },
};

export const additionalInformationFields = additionalInformationBlockValidation.childValidators.additionalInformation;

interface Props extends BlockProps<Fields> {}

const AdditionalInformationBlock = ({
    path = 'additionalInformation',
    fieldOptions = { statementCode: { options: additionalInformationCodeItem } },
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '2/2', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="statementCode">
                {({ form, field }) => (
                    <FormSelect
                        required
                        {...getFormikProps(field.name, form)}
                        label={t('additionalInformationCode')}
                        refNumber={ucc}
                        selectOptions={fieldOptions.statementCode?.options}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="statementDescription">
                {({ form, field }) => (
                    <FormInput
                        maxLength={70}
                        refNumber={ucc}
                        {...getFormikProps(field.name, form)}
                        label={t('additionalInformationDescription')}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default AdditionalInformationBlock;
