import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnsType, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import Button from 'components/ui/base/button';
import Table from 'components/ui/base/table/Table';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import useCustomers from 'hooks/useCustomers';
import { FC, useEffect, useState } from 'react';
import { Journey, JourneySortParameter } from 'store/journeys/journeys';

interface Props {
    data?: ListPayload<Journey>;
    onDelete: (ids: string[]) => void;
    onEdit: (id: string) => void;
    onDetails: (id: string) => void;
    handlePagination: (paginator: Partial<PaginatedParams>) => void;
    handleSort: (paginator: Partial<PaginatedParams>) => void;
    loading: boolean;
}

const JourneysTable: FC<Props> = ({ data, onDelete, onEdit, onDetails, handlePagination, handleSort, loading }) => {
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const { customers, listCustomers } = useCustomers();

    useEffect(() => {
        listCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: ColumnsType<any> = [
        {
            title: 'Journey Id',
            dataIndex: JourneySortParameter.ID,
            key: JourneySortParameter.ID,
            align: 'left',
            onCell: (record: Journey, rowIndex) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Journey, index: number) =>
                record.id ? <span>{record.id}</span> : <span>-</span>,
            sorter: true,
        },
        {
            title: 'Consignee',
            dataIndex: JourneySortParameter.CONSIGNEE,
            key: JourneySortParameter.CONSIGNEE,
            align: 'left',
            render: (text, record: Journey) => {
                if (record.consigneeId) {
                    const consignee = customers.list.find((e) => e.id === record.consigneeId);
                    if (consignee?.name) {
                        return <span>{consignee.name}</span>;
                    } else {
                        <span>-</span>;
                    }
                } else {
                    return <span>-</span>;
                }
            },
            sorter: true,
        },
        {
            title: 'Consignor',
            dataIndex: JourneySortParameter.CONSIGNOR,
            key: JourneySortParameter.CONSIGNOR,
            align: 'left',
            onCell: (record: Journey, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text, record: Journey) => {
                if (record.consignorId) {
                    const consignor = customers.list.find((e) => e.id === record.consignorId);
                    if (consignor?.name) {
                        return <span>{consignor.name}</span>;
                    } else {
                        <span>-</span>;
                    }
                } else {
                    return <span>-</span>;
                }
            },
            sorter: true,
        },
        {
            title: 'Start date',
            dataIndex: JourneySortParameter.START_TIME,
            key: JourneySortParameter.START_TIME,
            align: 'left',
            onCell: (record: Journey, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Journey, index: number) =>
                record.startDateTime ? (
                    <span>{new Date(record.startDateTime).toLocaleDateString()}</span>
                ) : (
                    <span>-</span>
                ),
            sorter: true,
        },
        {
            title: 'End Date',
            dataIndex: JourneySortParameter.END_TIME,
            key: JourneySortParameter.END_TIME,
            align: 'left',
            onCell: (record: Journey, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        onDetails(record.id);
                    },
                };
            },
            render: (text: string, record: Journey, index: number) =>
                record.endDateTime ? <span>{new Date(record.endDateTime).toLocaleDateString()}</span> : <span>-</span>,

            sorter: true,
        },
        {
            title: 'Commands',
            dataIndex: 'Commands',
            key: 'commands',
            align: 'center',
            render: (text: string, record: Journey, index: number) => {
                return (
                    <>
                        <span style={{ marginRight: '1.2rem', cursor: 'pointer' }} onClick={() => onEdit(record.id)}>
                            <EditOutlined />
                        </span>
                        <span
                            style={{ marginRight: '1.2rem', cursor: 'pointer' }}
                            onClick={() => onDelete([record.id])}
                        >
                            <DeleteOutlined />
                        </span>
                    </>
                );
            },
        },
    ];

    function onChange(
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<any> | SorterResult<any>[],
        extra: TableCurrentDataSource<any>
    ) {
        if (!Array.isArray(sorter) && sorter.columnKey) {
            const sortParameter = sorter.columnKey?.toString();
            if (sorter.order === 'ascend') {
                handleSort({ sortParameter, sortDirection: 'ASC' });
            } else if (sorter.order === 'descend') {
                handleSort({ sortParameter, sortDirection: 'DESC' });
            }
        }
    }

    return (
        <>
            <Table
                rowKey="id"
                style={{ marginTop: '2.5rem' }}
                columns={columns}
                dataSource={data?.list}
                pagination={{
                    defaultCurrent: 1,
                    total: data?.total,
                    showSizeChanger: false,
                    pageSize: data?.pageSize,
                    position: ['bottomCenter'],
                    onChange: (page: number, size: number | undefined) => {
                        const params = { page: page - 1, size };
                        handlePagination(params);
                    },
                }}
                onChange={onChange}
                loading={loading}
            />
            {deleteIds.length ? (
                <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        onDelete(deleteIds);
                        setDeleteIds([]);
                    }}
                >
                    delete
                </Button>
            ) : (
                <></>
            )}
        </>
    );
};
export default JourneysTable;
