import { DeleteOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import Table from 'components/ui/base/table/Table';
import { handleNullValues } from 'components/ui/base/table/TableUtils';
import { FC, useCallback } from 'react';
import { EnsGoodsShipmentItem } from 'store/declarations/ireland/entry-summary-declaration';
import { colors } from 'theme';
import { ActionButton, InvalidIcon } from 'views/declarations/ireland/common/IrelandProductsTable.styles';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
    data: EnsGoodsShipmentItem[];
    onDelete?: (id: string) => void;
    onEdit?: (id: string) => void;
    onAddToTemplate?: (item: string) => void;
    loading?: boolean;
    viewOnly?: boolean;
    errors?: boolean[];
}

const EnsProductsTable: FC<Props> = ({ data, onDelete, onEdit, onAddToTemplate, loading, viewOnly, errors }) => {
    const checkInvalid = useCallback((index: number) => errors && !!errors[index], [errors]);
    const navigate = useNavigate();
    const { declarationId, historyId } = useParams();
    const getInvalidStyle = useCallback(
        (index: number) => {
            return checkInvalid(index) ? { style: { background: `${colors.invalid}`, border: 'none' } } : {};
        },
        [checkInvalid]
    );

    const handleTableValues = useCallback(
        (index: number, value?: string | number) => {
            const style = getInvalidStyle(index);
            return { props: style, children: handleNullValues(value) };
        },
        [getInvalidStyle]
    );

    const handleInvalidIcon = useCallback(
        (index: number) => {
            const style = getInvalidStyle(index);
            return checkInvalid(index)
                ? {
                      props: style,
                      children: <InvalidIcon />,
                  }
                : { props: style, children: <></> };
        },
        [getInvalidStyle, checkInvalid]
    );

    const columns: ColumnsType<EnsGoodsShipmentItem> = [
        {
            title: 'Commodity Code',
            dataIndex: 'commodity_code',
            key: 'commodity_code',
            render: (text, record: EnsGoodsShipmentItem, index: number) => {
                return handleTableValues(index, record.commodity?.combinedNomenclature);
            },
            sorter: (a: any, b: any) => {
                return a.commodity?.combinedNomenclature - b.commodity?.combinedNomenclature;
            },
        },
        {
            title: 'Description of Goods',
            dataIndex: 'textualDescription',
            key: 'textualDescription',
            render: (text, record: EnsGoodsShipmentItem, index: number) =>
                handleTableValues(index, record.commodity?.description),
        },
        {
            title: 'Item Price',
            dataIndex: 'item_price',
            key: 'item_price',
            render: (text, record: EnsGoodsShipmentItem, index: number) => handleTableValues(index, '-'),
        },
        {
            title: 'Tag',
            dataIndex: 'tag',
            key: 'tag',
            render: (text, record: EnsGoodsShipmentItem, index: number) => handleTableValues(index, '-'),
            sorter: true,
        },
        {
            title: 'Commands',
            dataIndex: 'commands',
            key: 'commands',
            render: (text, record: EnsGoodsShipmentItem, index: number) => ({
                props: getInvalidStyle(index),
                children: (
                    <>
                        {onAddToTemplate && (
                            <ActionButton
                                size="small"
                                invalid={checkInvalid(index)}
                                marginRight="1.2"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onAddToTemplate?.(record.id!);
                                }}
                            >
                                Add to templates
                            </ActionButton>
                        )}

                        {!viewOnly && onDelete && (
                            <ActionButton
                                size="small"
                                invalid={checkInvalid(index)}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDelete?.(record.id!);
                                }}
                            >
                                Delete <DeleteOutlined />
                            </ActionButton>
                        )}
                    </>
                ),
            }),
        },
        {
            dataIndex: 'icon',
            key: 'icon',
            render: (text, record, index: number) => handleInvalidIcon(index),
        },
    ];

    const rowSelection: TableRowSelection<any> = {
        renderCell: (value, record, index: number, originNode: React.ReactNode) => {
            return { props: getInvalidStyle(index), children: originNode };
        },
    };

    return (
        <Table
            rowKey="id"
            dataSource={data}
            columns={columns}
            loading={loading}
            rowSelection={rowSelection}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        event.stopPropagation();
                        onEdit?.(record.id!);
                        navigate(`/declarations/${declarationId}/history/${historyId}/products/${record.id}`);
                    },
                };
            }}
            pagination={{
                current: 1,
                total: data?.length ?? 0, // FIXME hack
                showSizeChanger: false,
                pageSize: 10,
                position: ['bottomCenter'],
            }}
        />
    );
};
export default EnsProductsTable;
