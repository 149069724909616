import DDatePicker from 'components/ui/base/datepicker/Datepicker';
import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { DateFilter as FilterContent } from './Filter.styles';

interface Props {
    selectedDate?: (date: string) => void;
}

interface FilterDate {
    date?: string;
}
export const DateFilter: FC<Props> = ({ selectedDate }) => {
    const formik = useFormik<FilterDate>({
        initialValues: {},
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: () => {},
    });

    useEffect(() => {
        if (formik.values.date && selectedDate) {
            const date = new Date(formik.values.date);
            selectedDate(date.toISOString());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.date]);

    return (
        <FilterContent>
            <DDatePicker fieldProps={formik.getFieldProps('date')} />
        </FilterContent>
    );
};

export default DateFilter;
