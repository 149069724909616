import { FormikProvider, useFormik } from 'formik';
import { FC } from 'react';
import {
    addCardToMultipleCards,
    deleteCardToMultipleCards,
    initializeMultipleCards,
} from 'views/declarations/utils/form-utils';
import FiscalReferenceCard from '../../../common/fiscal-reference/FiscalReferenceCard';
import PartiesCard from '../../../common/parties/PartiesCard';
import AcceptanceDateCard from './cards/AcceptanceDateCard';
import AdditionalConditionalInformationCard, {
    DeclarationFormType,
} from './cards/additional-conditional-information/AdditionalConditionalInformationCard';
import AdditionalInformationCard from './cards/AdditionalnformationCard';
import AuthorizationHolderCard from './cards/AuthorizationHolderCard';
import BorderTransportMeansCard from './cards/BorderTransportMeansCard';
import CountryOfDestinationCard from './cards/CountryOfDestinationCard';
import CustomsOfficesCard from './cards/CustomsOfficesCard';
import DefferedPaymentCard from './cards/DeferredPaymentCard';
import DeliveryTermsCard from './cards/DeliveryTermsCard';
import ExchangeRateCard from './cards/ExchangeRateCard';
import GrossMassCard from './cards/GrossMassCard';
import GuaranteeReferenceCard from './cards/GuaranteeReferenceCard';
import GuaranteeTypeCard from './cards/GuaranteeTypeCard';
import IdentificationWarehouseCard from './cards/IdentificationWarehouseCard';
import LocationOfGoodsCard from './cards/LocationOfGoodsCard';
import MasterDetailsCard from './cards/MasterDetailsCard';
import MeansOfTransportAtArrivalCard from './cards/MeansOfTransportAtArrivalCard';
import NatureOfTransactionCard from './cards/NatureOfTransactionCard';
import PlaceOfLoadingCard from './cards/PlaceOfLoadingCard';
import PreviousDocumentCard from './cards/PreviousDocumentCard';
import TansportCostsFinalDestinationCard from './cards/TansportCostsFinalDestinationCard';
import TotalAmountInvoicedCard from './cards/TotalAmountInvoicedCard';
interface Props {
    formik: ReturnType<typeof useFormik>; // FIXME use correct type
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    expandAll?: boolean;
}

const UkImportH1Form: FC<Props> = ({ formik, toggleHelp, viewOnly, expandAll }) => {
    const additionalSupplyChain = initializeMultipleCards(formik, `goodsShipment.additionalSupplyChainActors`);
    const additionsAndDeductions = initializeMultipleCards(formik, `goodsShipment.additionDeductions`);
    const containerIdentificationNumbers = initializeMultipleCards(
        formik,
        'goodsShipment.containerIdentificationNumbers'
    );

    return (
        <FormikProvider value={formik}>
            <MasterDetailsCard
                keyCard={`master-details-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={1}
            />

            <PreviousDocumentCard
                propsPathPrefix="goodsShipment.previousDocuments"
                viewOnly={viewOnly}
                key={`previous-docs`}
                keyCard={`previous-docs`}
                expandAll={expandAll}
                cardTotal={28}
                cardNumber={2}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                formik={formik}
            />

            <AdditionalInformationCard
                propsPathPrefix="additionalInformations"
                viewOnly={viewOnly}
                key={`additional-info`}
                keyCard={`additional-info`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={3}
            />

            <DefferedPaymentCard
                propsPathPrefix="deferredPayments"
                viewOnly={viewOnly}
                key={`deffered-payment`}
                keyCard={`deffered-payment`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={4}
            />

            <IdentificationWarehouseCard
                keyCard={`identification-warehouse-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={5}
            />

            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'exporter',
                        header: 'Exporter',
                        refNumber: '3.1',
                    },
                    {
                        path: 'goodsShipment.importer',
                        header: 'Importer',
                        refNumber: '3.15',
                    },
                    {
                        path: 'declarant',
                        header: 'Declarant',
                        refNumber: '3.17',
                        eoriRequired: true,
                    },
                    {
                        path: 'representative',
                        header: 'Representative',
                        refNumber: '3.19',
                        hasRepresentativeStatus: true,
                    },
                    {
                        path: 'goodsShipment.seller',
                        header: 'Seller',
                        refNumber: '3.24',
                        hasPhoneNumber: true,
                    },
                    {
                        path: 'goodsShipment.buyer',
                        header: 'Buyer',
                        refNumber: '3.26',
                        hasPhoneNumber: true,
                    },
                ]}
                paths={{
                    address: {
                        city: 'city',
                        country: 'country',
                        name: 'name',
                        postCode: 'postCode',
                        streetAndNumber: 'streetAndNumber',
                    },
                    eori: 'eori',
                    additional: {
                        phoneNumber: 'phoneNumber',
                        representativeStatus: 'statusCode',
                    },
                }}
                viewOnly={viewOnly}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={2}
                keyCard={`parties-card`}
                expandAll={expandAll}
                condensed
            />

            <AuthorizationHolderCard
                propsPathPrefix="holderOfTheAuthorisations"
                viewOnly={viewOnly}
                key={`auth-holder`}
                keyCard={`auth-holer`}
                expandAll={expandAll}
                cardTotal={28}
                cardNumber={12}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
            />

            <DeliveryTermsCard
                viewOnly={viewOnly}
                propsPathPrefix="goodsShipment"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={13}
                keyCard={`delivery-terms-card`}
                expandAll={expandAll}
            />
            <TotalAmountInvoicedCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={14}
                keyCard={`total-amount-invoiced-card`}
                expandAll={expandAll}
            />
            <ExchangeRateCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={15}
                keyCard={`exchange-rate-card`}
                expandAll={expandAll}
            />
            <TansportCostsFinalDestinationCard
                viewOnly={viewOnly}
                propsPathPrefix="goodsShipment"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={16}
                keyCard={`transport-costs--final-destination-card`}
                expandAll={expandAll}
            />
            <CountryOfDestinationCard
                viewOnly={viewOnly}
                propsPathPrefix="goodsShipment"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={17}
                keyCard={`country-destination-card`}
                expandAll={expandAll}
            />
            <PlaceOfLoadingCard
                viewOnly={viewOnly}
                propsPathPrefix="goodsShipment"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                keyCard={`place-of-loading-card`}
                expandAll={expandAll}
                cardNumber={18}
            />
            <LocationOfGoodsCard
                viewOnly={viewOnly}
                propsPathPrefix="goodsShipment"
                keyCard={`location-of-goods-card`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={19}
            />
            <CustomsOfficesCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={20}
                keyCard={`customs-offices-card`}
                expandAll={expandAll}
            />
            <GrossMassCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={21}
                keyCard={`grpss-mass-card`}
                expandAll={expandAll}
            />
            <BorderTransportMeansCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                keyCard={`boarder-transport-means-card`}
                expandAll={expandAll}
                cardNumber={22}
            />
            <MeansOfTransportAtArrivalCard
                propsPathPrefix="goodsShipment"
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={23}
                keyCard={`means-of-trasnport-at-arrival-card`}
                expandAll={expandAll}
            />

            <GuaranteeTypeCard
                propsPathPrefix="guaranteeTypes"
                viewOnly={viewOnly}
                key={`guarantee-types`}
                cardTotal={28}
                cardNumber={24}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                keyCard={`guarantee-type-card`}
                expandAll={expandAll}
            />

            <GuaranteeReferenceCard
                propsPathPrefix="guaranteeReference"
                viewOnly={viewOnly}
                key={`guarantee-reference`}
                cardTotal={28}
                cardNumber={25}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                keyCard={`guarantee-reference`}
                expandAll={expandAll}
            />

            <NatureOfTransactionCard
                propsPathPrefix="goodsShipment"
                viewOnly={viewOnly}
                keyCard={`nature-of-transaction`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={26}
            />
            <AcceptanceDateCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={27}
                keyCard={`acceptance-date-card`}
                expandAll={expandAll}
            />

            <FiscalReferenceCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={27}
                keyCard={`fiscal-ref`}
                expandAll={expandAll}
                propsPathPrefix={'goodsShipment.additionalFiscalReferenceIdentifications'}
            />

            <AdditionalConditionalInformationCard
                fiscalReferenceRequired
                formType={DeclarationFormType.MASTER_DETAILS}
                viewOnly={viewOnly}
                getFieldMeta={formik.getFieldMeta}
                getFieldProps={formik.getFieldProps}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={28}
                keyCard={`additonal-conditional-info-card`}
                expandAll={expandAll}
                supplyChainActor={additionalSupplyChain}
                addSupplyChainActor={() =>
                    addCardToMultipleCards(formik, `goodsShipment.additionalSupplyChainActors`, additionalSupplyChain)
                }
                removeSupplyChainActor={(indexToDelete) =>
                    deleteCardToMultipleCards(
                        formik,
                        `goodsShipment.additionalSupplyChainActors`,
                        additionalSupplyChain,
                        indexToDelete
                    )
                }
                additionsAndDeductions={additionsAndDeductions}
                addAdditionsAndDeductions={() =>
                    addCardToMultipleCards(formik, `goodsShipment.additionDeductions`, additionsAndDeductions)
                }
                removeAdditionsAndDeductions={(indexToDelete) =>
                    deleteCardToMultipleCards(
                        formik,
                        `goodsShipment.additionDeductions`,
                        additionsAndDeductions,
                        indexToDelete
                    )
                }
                containerIdentificationNumbers={containerIdentificationNumbers}
                addContainerIdentificationNumbers={() =>
                    addCardToMultipleCards(
                        formik,
                        `goodsShipment.containerIdentificationNumbers`,
                        containerIdentificationNumbers
                    )
                }
                removeContainerIdentificationNumbers={(indexToDelete) =>
                    deleteCardToMultipleCards(
                        formik,
                        `goodsShipment.containerIdentificationNumbers`,
                        containerIdentificationNumbers,
                        indexToDelete
                    )
                }
            />
        </FormikProvider>
    );
};
export default UkImportH1Form;
