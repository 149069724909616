import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import { normalizeCodesToSelect } from 'store/codelists/code';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { ValidationSchema, validators } from '../validations/validations';

export type Fields = 'typeCode';

export const typeOfPackagesBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        typeCode: [validators.required(), validators.max(2)],
    },
};

interface Props extends BlockProps<Fields> {}

const TypeOfPackagesBlock = ({ path = 'packaging', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();

    const ucc = useMemo(() => '6/9', []);

    return (
        <DeclarationField pathPrefix={path} name="typeCode">
            {({ form, field }: FieldProps<any>) => (
                <FormSelect
                    refNumber={ucc}
                    required
                    label={t('packages.typeOfPackages')}
                    {...getFormikProps(field.name, form)}
                    selectOptions={normalizeCodesToSelect(codelists?.ukPackageTypes || [])}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default TypeOfPackagesBlock;
