import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { ValidationSchema, validators } from '../validations/validations';

export type Fields = 'marksNumbersID';

export const shippingMarksBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        marksNumbersID: [validators.required(), validators.max(42)],
    },
};

interface Props extends BlockProps<Fields> {}

const ShippingMarksBlock = ({ path = 'packaging', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '6/11', []);
    return (
        <DeclarationField pathPrefix={path} name="marksNumbersID">
            {({ form, field }: FieldProps<any>) => (
                <FormInput
                    refNumber={ucc}
                    required
                    label={t('packages.shippingMarks')}
                    {...getFormikProps(field.name, form)}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default ShippingMarksBlock;
