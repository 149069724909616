import { Col, Row } from 'antd';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { valuationIndicatorTypes } from 'store/declarations/enums/uk/valuationIndicator-types';

interface Props extends BlockProps<'additionCode'> {}

const ValuationIndicatorsBlock = ({
    path = 'goodsShipment.governmentAgencyGoodsItem.valuationAdjustment',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '4/13', []);

    return (
        <Row gutter={18}>
            <Col xs={fieldOptions?.additionCode?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="additionCode"
                    validation={{
                        name: t('valuationIndicators'),
                        exact: 4,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormSelect
                            refNumber={ucc}
                            label={t('valuationIndicators')}
                            {...getFormikProps(field.name, form)}
                            selectOptions={valuationIndicatorTypes}
                        />
                    )}
                </DeclarationField>
            </Col>
        </Row>
    );
};

export default ValuationIndicatorsBlock;
