export const paymentMethods = [
    { id: 'A', value: '' },
    { id: 'B', value: '' },
    { id: 'C', value: '' },
    { id: 'D', value: '' },
    { id: 'E', value: '' },
    { id: 'H', value: '' },
    { id: 'M', value: '' },
    { id: 'N', value: '' },
    { id: 'O', value: '' },
    { id: 'P', value: '' },
    { id: 'R', value: '' },
    { id: 'S', value: '' },
    { id: 'T', value: '' },
    { id: 'U', value: '' },
    { id: 'V', value: '' },
    { id: 'X', value: '' },
    { id: 'Z', value: '' },
];
