import Notification from 'components/ui/base/notification/Notification';
import useDeclarations from 'hooks/useDeclarations';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import Archive from 'views/declarations/sections/form-archives';

const UkArchiveView: FC = () => {
    const { t } = useTranslation('customs_declarations');
    const { createUkImportDeclaration, createUkExportDeclaration, error, isLoading } = useDeclarations();
    const navigate = useNavigate();

    useEffect(() => {
        if (error && !isLoading) {
            Notification({
                type: 'error',
                messageTitle: t('archive.use-template-error-title'),
                description: t('archive.use-template-error-message'),
            });
        }
    }, [error, isLoading, t]);

    const handleSetAsTemplate = useCallback(
        async (declaration: Declaration) => {
            if (declaration) {
                if (declaration.cdsImportDeclaration) {
                    const d = await createUkImportDeclaration(
                        declaration.customerId!,
                        declaration.cdsImportDeclaration,
                        declaration.jobId
                    );
                    if (d) {
                        navigate(`/declarations/${d.id}`);
                    }
                } else {
                    const d = await createUkExportDeclaration(
                        declaration.customerId!,
                        declaration.cdsExportDeclaration,
                        declaration.jobId
                    );
                    if (d) {
                        navigate(`/declarations/${d.id}`);
                    }
                }
            }
        },
        [createUkImportDeclaration, createUkExportDeclaration, navigate]
    );

    return (
        <Archive
            declarationInternalType={[DeclarationInternalType.EXPORT, DeclarationInternalType.IMPORT]}
            declarationExternalEntity={[DeclarationExternalEntity.CDS, DeclarationExternalEntity.CHIEF]}
            setAsTemplate={handleSetAsTemplate}
        />
    );
};
export default UkArchiveView;
