import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const CountryOfDestinationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('countryOfDestination')}>
            <FormCardContainer>
                <CountrySelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`countryOfDestination`, props)}
                    refNumber="5.8"
                    label={t('countryOfDestinationCode')}
                    tooltip={getCdsTooltipsByRefNumberAndField('5.8', t('countryOfDestinationCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CountrySelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`countryOfDispatchExport`, props)}
                    refNumber="5.14"
                    label={t('countryOfDispatchCode')}
                    tooltip={getCdsTooltipsByRefNumberAndField('5.14', t('countryOfDispatchCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default CountryOfDestinationCard;
