import { DeclarationCustomer } from '../../../../../store/declarations/common/declaration-customer';
import { DeferredPayment } from '../../../../../store/declarations/common/deferred-payment';
import { GovernmentAgencyGoodsItem } from '../../../../../store/declarations/uk/goods-item';
import { CdsImportDeclaration } from '../../../../../store/declarations/uk/import-declaration';
import countries from '../../../../../utils/countries';

const transformUkCustomerForClient = (customer: DeclarationCustomer | undefined) => {
    if (!customer) return customer;
    const phoneNumber = customer.phoneNumber;

    for (let i = 0; i < countries.length; i++) {
        const countryCodeData = countries[i].phone;

        if (countryCodeData === phoneNumber?.slice(0, 2)) {
            return {
                ...customer,
                phoneNumberCode: phoneNumber?.slice(0, 2),
                phoneNumber: phoneNumber?.substring(2),
            };
        } else if (countryCodeData === phoneNumber?.slice(0, 3)) {
            return {
                ...customer,
                phoneNumberCode: phoneNumber?.slice(0, 3),
                phoneNumber: phoneNumber?.substring(3),
            };
        } else if (countryCodeData === phoneNumber?.slice(0, 4)) {
            return {
                ...customer,
                phoneNumberCode: phoneNumber?.slice(0, 4),
                phoneNumber: phoneNumber?.substring(4),
            };
        } else if (countryCodeData === phoneNumber?.slice(0, 6)) {
            return {
                ...customer,
                phoneNumberCode: phoneNumber?.slice(0, 6),
                phoneNumber: phoneNumber?.substring(6),
            };
        }
    }
};

const transformUkCustomerForServer = (customer: DeclarationCustomer | undefined) => {
    if (!customer) return customer;

    const phoneNumber = `${customer.phoneNumberCode}${customer.phoneNumber}`;

    delete customer.phoneNumberCode;

    return { ...customer, phoneNumber };
};

export const ukImportDeclarationTransformedForClient = (ukDeclaration: CdsImportDeclaration) => {
    const deferredPayments = ukDeclaration?.deferredPayments;
    const buyer = ukDeclaration?.goodsShipment?.buyer;
    const seller = ukDeclaration?.goodsShipment?.seller;

    if (!Array.isArray(deferredPayments)) return ukDeclaration;

    const deferredPaymentsMapped = deferredPayments.map((obj: DeferredPayment) => ({
        deferredPaymentIdentifier: obj.deferredPaymentIdentifier,
        categoryAndType: `${obj.category}${obj.type}`,
    }));

    return {
        ...ukDeclaration,
        deferredPayments: deferredPaymentsMapped,
        goodsShipment: {
            ...ukDeclaration.goodsShipment,
            buyer: transformUkCustomerForClient(buyer),
            seller: transformUkCustomerForClient(seller),
        },
    };
};

export const ukImportDeclarationTransformedForServer = (ukDeclaration: CdsImportDeclaration) => {
    const deferredPayments = ukDeclaration?.deferredPayments;
    const buyer = ukDeclaration?.goodsShipment?.buyer;
    const seller = ukDeclaration?.goodsShipment?.seller;

    if (!Array.isArray(deferredPayments)) return ukDeclaration;

    const deferredPaymentsMapped = deferredPayments.map((obj: DeferredPayment) => ({
        deferredPaymentIdentifier: obj.deferredPaymentIdentifier,
        category: obj?.categoryAndType?.charAt(0),
        type: obj?.categoryAndType?.substring(1),
    }));

    return {
        ...ukDeclaration,
        deferredPayments: deferredPaymentsMapped,
        goodsShipment: {
            ...ukDeclaration.goodsShipment,
            buyer: transformUkCustomerForServer(buyer),
            seller: transformUkCustomerForServer(seller),
        },
    };
};

export const ukImportProductTransformedForClient = (product: GovernmentAgencyGoodsItem) => {
    const buyer = product?.buyer;
    const seller = product?.seller;

    return { ...product, buyer: transformUkCustomerForClient(buyer), seller: transformUkCustomerForClient(seller) };
};

export const ukImportProductTransformedForServer = (product: GovernmentAgencyGoodsItem) => {
    const buyer = product?.buyer;
    const seller = product?.seller;

    return { ...product, buyer: transformUkCustomerForServer(buyer), seller: transformUkCustomerForServer(seller) };
};
