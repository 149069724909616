import { SelectOption } from 'components/ui/composed/declarations/formSelect/DeclarationSelect';

export enum WarehouseType {
    R = 'R', // value="R"  >R - Public Customs Warehouse type I
    S = 'S', // value="S"  >S - Public Customs Warehouse type II
    U = 'U', // value="U"  >U - Private Customs Warehouse
    T = 'T', // Value="T" > T - Present in CDS
    V = 'V', // value="V"  >V - Storage facilities for the temporary storage of goods
    Y = 'Y', // value="Y"  >Y - For a non-customs warehouse
    Z = 'Z', // value="Z"  >Z - For a free zone or free warehouse
}

export const warehouseTypeLabels: Readonly<SelectOption[]> = [
    { id: WarehouseType.R, value: 'Public Customs Warehouse type I' },
    { id: WarehouseType.S, value: 'Public Customs Warehouse type II' },
    { id: WarehouseType.U, value: 'Private Customs Warehouse' },
    { id: WarehouseType.T, value: 'Present in CDS' },
    { id: WarehouseType.V, value: 'Storage facilities for the temporary storage of goods' },
    { id: WarehouseType.Y, value: 'For a non-customs warehouse' },
    { id: WarehouseType.Z, value: 'For a free zone or free warehouse' },
];
