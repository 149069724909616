import Select, { DefaultOptionType, SelectProps } from 'antd/lib/select';
import IconTooltip from 'components/ui/base/tooltip/IconTooltip';
import { TextSmall } from 'components/ui/base/typography';
import { TestProps } from 'core/utils/testTypes';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useDeclarationInputFocused from 'hooks/useDeclarationInputFocused';
import { FC, FocusEvent, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInfoCircleFilled } from 'views/declarations/Form.styles';
import { Option } from '../../../base/select/Select';
import {
    ErrorDiv,
    ErrorLabel,
    FormItem,
    InputDiv,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
    RefDiv,
    ViewOnlyLabel,
} from '../formInput/DeclarationInput.styles';
import { handleBlur, handleFocus } from '../utils';
import { StyledDeclarationSelect } from './DeclarationSelect.styles';
import { getFormikProps, toPlaceholder } from 'views/declarations/utils/form-utils';
import MultipleButtons from '../MultipleButtons';
import { useOutletContext, useLocation } from 'react-router-dom';
import { TemplateCheckboxes } from 'components/ui/composed/template/TemplateCheckbox';
import { useTemplateContext } from '../../template/TemplateContext';
import { useRegisterField } from '../../template/CardContext';
import SearchCustomerButton from 'views/declarations/common/search-customer/SearchCustomerButton';
import useFieldTemplateMetaData from '../../template/useTemplateViewEditControls';

const { OptGroup } = Select;

export interface SelectOption {
    id: string;
    value: string;
    disabled?: boolean;
}

export interface MultipleProps {
    node: ReactNode;
}

export type GroupSelectOption = Record<string, Readonly<SelectOption[]>>;

export interface FormSelectProps extends TestProps, SelectProps {
    label?: string;
    tooltip?: string;
    icon?: ReactNode;
    refNumber?: string;
    selectOptions?: Readonly<SelectOption[]> | Readonly<GroupSelectOption>;
    children?: ReactNode;
    refClicked?: (refNumber: string | number) => void;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    required?: boolean;
    multiple?: MultipleProps;
    hideKeys?: boolean;
    viewOnly?: boolean;
    disabled?: boolean;
    amendment?: boolean;
    fieldEvents?: {
        onChange?: (value: any, option: DefaultOptionType | DefaultOptionType[]) => void;
        onBlur?: (e: FocusEvent<HTMLInputElement> | FocusEvent<HTMLElement>) => void;
    };
    onClear?: () => void;
    condensed?: boolean;
    multipleF?: {
        onAdd: () => void;
        onRemove: () => void;
        canAdd?: boolean;
        canRemove?: boolean;
    };
    onSearchCustomsOfficeOfExit?: () => void;
    onlyKeys?: boolean;
    specialName?: string; // Used for template meta handle of taxBox34Bis fields as of 13/04/23
}

export interface SelectSearchOption {
    children: any;
    key: string;
    props: any;
    value: string;
}

const DeclarationSelect: FC<FormSelectProps> = ({
    label,
    refNumber,
    tooltip,
    selectOptions,
    fieldProps: fieldPropsProp,
    fieldMeta: fieldMetaProp,
    fieldHelper: fieldHelpersProp,
    multiple,
    refClicked,
    required,
    fieldHelper,
    hideKeys,
    viewOnly: viewOnlyProp,
    disabled,
    testId,
    fieldEvents,
    onClear: handleClear,
    condensed,
    multipleF,
    onSearchCustomsOfficeOfExit,
    onlyKeys,
    specialName,
    ...selectProps
}) => {
    const { t } = useTranslation('common');
    const { setFocused } = useDeclarationInputFocused();
    const outletContext = useOutletContext<{
        amendment?: boolean;
    }>();
    const location = useLocation();
    const inViewOnly = location.pathname.includes('view-only');
    const { template, templateFormik, form, isViewOnly: isTemplateViewOnly } = useTemplateContext();

    useRegisterField({ path: fieldPropsProp?.name, required });
    const { isViewable, isEditable, isInvisible } = useFieldTemplateMetaData(fieldPropsProp?.name);

    const { fieldProps, fieldMeta } = useMemo(() => {
        if (template && templateFormik && form) {
            const f = getFormikProps(`${form}.defaults.${fieldPropsProp?.name}`, templateFormik);
            return { ...f, fieldHelpers: f.fieldHelper };
        }

        return { fieldProps: fieldPropsProp, fieldMeta: fieldMetaProp, fieldHelpers: fieldHelpersProp };
    }, [template, templateFormik, form, fieldPropsProp, fieldMetaProp, fieldHelpersProp]);

    const handleRefClick = useCallback(() => {
        if (refClicked) {
            refNumber ? refClicked(refNumber) : refClicked(label ?? '');
        }
    }, [refClicked, refNumber, label]);

    const handleEmptyValues = useCallback((item: SelectOption) => {
        return !!item.value ? item.value : item.id;
    }, []);

    const onMouseClicked = (e: React.MouseEvent<Element>) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const showTooltip = useMemo(() => tooltip && tooltip.length > 0, [tooltip]);

    const viewOnly = useMemo(() => {
        if (template) return isTemplateViewOnly;
        if (outletContext?.amendment) return isViewable;
        return isViewable || inViewOnly;
    }, [template, isTemplateViewOnly, inViewOnly, outletContext?.amendment, isViewable]);

    const optionsRender = useMemo(() => {
        if (!selectOptions) {
            return null;
        }

        if (Array.isArray(selectOptions)) {
            return selectOptions?.map((item: SelectOption, i: number) =>
                onlyKeys ? (
                    <Option onClick={onMouseClicked} key={i} value={item.id} disabled={item.disabled}>
                        {item.id}
                    </Option>
                ) : !hideKeys ? (
                    <Option onClick={onMouseClicked} key={i} value={item.id} disabled={item.disabled}>
                        {`${item.id}${item.value ? ' - ' + item.value : ''}`}
                    </Option>
                ) : (
                    <Option onClick={onMouseClicked} key={i} value={item.id} disabled={item.disabled}>
                        {handleEmptyValues(item)}
                    </Option>
                )
            );
        }

        return Object.entries(selectOptions as Readonly<GroupSelectOption>).map(([group, options]) => {
            return (
                <OptGroup label={group}>
                    {options?.map((item: SelectOption, i: number) =>
                        !hideKeys ? (
                            <Option onClick={onMouseClicked} key={i} value={item.id}>
                                {`${item.id} - ${item.value}`}
                            </Option>
                        ) : (
                            <Option onClick={onMouseClicked} key={i} value={item.id}>
                                {handleEmptyValues(item)}
                            </Option>
                        )
                    )}
                </OptGroup>
            );
        });
    }, [handleEmptyValues, hideKeys, onlyKeys, selectOptions]);

    const fillButtonsDisabled = useMemo(
        () => template && !isEditable && !isViewable,
        [isEditable, isViewable, template]
    );

    if (isInvisible && !template) return null;

    return (
        <>
            {viewOnly ? (
                <>
                    <ViewOnlyLabel>{label && label}:</ViewOnlyLabel>
                    {template && <TemplateCheckboxes disabled fieldPath={fieldPropsProp?.name} />}
                    <TextSmall>{fieldProps.value ?? '-'} </TextSmall>
                </>
            ) : (
                <>
                    <LabelDiv condensed={condensed}>
                        {label && (
                            <InputLabel>
                                {label}
                                {required && (
                                    <InputLabelRequired>{condensed ? '*' : t('mandatory')}</InputLabelRequired>
                                )}
                            </InputLabel>
                        )}

                        {refNumber && <RefDiv>{refNumber}</RefDiv>}
                        {showTooltip && (
                            <IconTooltip
                                title={label}
                                tooltip={tooltip ?? t('defaultTooltip')}
                                icon={<StyledInfoCircleFilled />}
                                tooltipClicked={handleRefClick}
                            />
                        )}
                    </LabelDiv>
                    {template && (
                        <TemplateCheckboxes
                            required={required}
                            fieldPath={fieldPropsProp?.name}
                            specialName={specialName}
                            onChange={
                                fieldEvents?.onChange
                                    ? (value: any) => fieldEvents.onChange!(value, [])
                                    : selectProps.onChange
                                    ? (value: any) => selectProps.onChange!(value, [])
                                    : undefined
                            }
                        />
                    )}
                    <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                        <InputDiv>
                            <div style={{ flex: '1', maxWidth: '100%', width: '100%', minWidth: '0' }}>
                                <StyledDeclarationSelect
                                    disabled={disabled || isInvisible}
                                    placeholder={selectProps.placeholder ?? toPlaceholder(label, 'Select') ?? ''}
                                    showSearch
                                    allowClear
                                    onClear={handleClear}
                                    testId={testId}
                                    {...fieldProps}
                                    status={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}
                                    filterOption={(input: string, option: SelectSearchOption) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(value, option) => {
                                        fieldProps?.onChange({ target: { value, name: fieldProps.name } });
                                        selectProps.onChange?.(value, option);
                                    }}
                                    {...fieldEvents}
                                    onBlur={(e) => {
                                        fieldHelper?.setTouched(true);
                                        handleBlur(setFocused, fieldProps, e);
                                        fieldEvents?.onBlur?.(e);
                                    }}
                                    value={selectProps.value ?? fieldProps.value}
                                    onClick={onMouseClicked}
                                    onFocus={() => handleFocus(setFocused)}
                                    size={condensed ? 'middle' : 'large'}
                                    condensed={condensed}
                                    mode={selectProps.mode}
                                >
                                    {optionsRender}
                                </StyledDeclarationSelect>
                            </div>
                            {!isViewable && !viewOnly && onSearchCustomsOfficeOfExit && (
                                <SearchCustomerButton
                                    compact
                                    disabled={fillButtonsDisabled}
                                    condensed={condensed}
                                    title={'Search Customs Office of Exit'}
                                    onClick={() => onSearchCustomsOfficeOfExit()}
                                />
                            )}

                            {multipleF && <MultipleButtons {...multipleF} />}
                            {multiple ? multiple.node : <></>}
                        </InputDiv>
                        <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched} condensed={condensed}>
                            {!!fieldMeta?.error && !!fieldMeta?.touched && <ErrorLabel>{fieldMeta?.error}</ErrorLabel>}
                        </ErrorDiv>
                    </FormItem>
                </>
            )}
        </>
    );
};

export default DeclarationSelect;
