import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import { ValidationSchema, validators } from '../validations/validations';
import DeclarationField from 'views/declarations/common/DeclarationField';

type Fields = 'id';

export const containerIdentificationNumberBlockValidation: {
    childValidators: {
        'goodsShipment.consignment.transportEquipment': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.consignment.transportEquipment': {
            childValidators: {
                id: [validators.max(17)],
            },
            stopAtNull: true,
        },
    },
};

export const containerIdentificationNumberFieldsValidation =
    containerIdentificationNumberBlockValidation.childValidators['goodsShipment.consignment.transportEquipment'];

export const containerIdentificationNumberBlockProductValidation: {
    childValidators: {
        'commodity.transportEquipment': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'commodity.transportEquipment': {
            childValidators: {
                id: [validators.max(17)],
            },
            stopAtNull: true,
        },
    },
};

interface Props extends BlockProps<Fields> {}

const ContainerIdentificationNumberBlock = ({
    path = 'goodsShipment.consignment.transportEquipment',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '7/10', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }) => (
                    <FormInput
                        refNumber={ucc}
                        label={t('containerIdentificationNumbers')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default ContainerIdentificationNumberBlock;
