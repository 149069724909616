import DeclarationFormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CardState } from 'views/declarations/common/cards/card-state';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps } from 'views/declarations/utils/form-utils';

const IrelandExportDutyTaxFee: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <section
            id="dutty-tax-fee-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('dutty-tax-fee-form-card');
                }
            }}
        >
            <FormCard
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('Duty Tax Fee')}
                cardNumber={props.cardNumber}
                expandAll={props.expandAll}
                keyCard={props.keyCard}
                total={props.cardTotal}
                state={CardState.EMPTY}
            >
                <DeclarationFormInput
                    viewOnly={true}
                    {...getFormikProps(`typeCode`, props)}
                    label={t('dutyTaxFee.typeCode')}
                />
                <DeclarationNumberInput
                    viewOnly={true}
                    {...getFormikProps(`taxBase`, props)}
                    label={t('dutyTaxFee.taxBase')}
                />
                <DeclarationNumberInput
                    viewOnly={true}
                    {...getFormikProps(`taxText`, props)}
                    label={t('dutyTaxFee.taxText')}
                />
                <DeclarationFormInput
                    viewOnly={true}
                    {...getFormikProps(`paymentMethod`, props)}
                    label={t('dutyTaxFee.paymentMethod')}
                />
                <DeclarationNumberInput
                    viewOnly={true}
                    {...getFormikProps(`paymentAmount`, props)}
                    label={t('dutyTaxFee.paymentAmount')}
                />
            </FormCard>
        </section>
    );
};
export default IrelandExportDutyTaxFee;
