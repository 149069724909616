import useCodelists from 'hooks/useCodelists';
import { FC, useCallback } from 'react';
import { ProducedDocumentsWritingOffHeader } from 'store/declarations/common/produced-document-writing-off-header';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { RevenueExportAdditionalInformation } from 'store/declarations/ireland/export-declaration';
import { SupportingDocuments } from 'store/declarations/ireland/h7-import-declaration';
import CardItemRow from '../CardItemRow';
import { GreyText } from './Box44List.styles';

interface Props {
    data: ProducedDocumentsWritingOffHeader[] | SupportingDocuments[] | RevenueExportAdditionalInformation[];
    declarationType?: DeclarationName;
    internalType?: DeclarationInternalType;
    onDelete: (
        item: ProducedDocumentsWritingOffHeader | SupportingDocuments | RevenueExportAdditionalInformation
    ) => void;
    onEdit: (
        item: ProducedDocumentsWritingOffHeader | SupportingDocuments | RevenueExportAdditionalInformation,
        index: number
    ) => void;
    viewOnly?: boolean;
}

const Box44List: FC<Props> = ({ data, declarationType, onDelete, onEdit, internalType, viewOnly }) => {
    const { codelists } = useCodelists();

    const getDescription = useCallback(
        (item: ProducedDocumentsWritingOffHeader | SupportingDocuments | RevenueExportAdditionalInformation) => {
            const list = codelists?.irelandCommonDocumentsTypes;
            if (internalType === DeclarationInternalType.EXPORT) {
                const i = item as RevenueExportAdditionalInformation;
                return list?.find((code) => code.code === i.code)?.description ?? '-';
            } else {
                if (declarationType === DeclarationName.H7) {
                    const i = item as SupportingDocuments;
                    return list?.find((code) => code.code === i.supportingDocumentType)?.description ?? '-';
                } else {
                    const i = item as ProducedDocumentsWritingOffHeader;
                    return list?.find((code) => code.code === i.documentType)?.description ?? '-';
                }
            }
        },
        [declarationType, codelists, internalType]
    );

    const getDocumentType = useCallback(
        (item: ProducedDocumentsWritingOffHeader | SupportingDocuments | RevenueExportAdditionalInformation) => {
            if (internalType === DeclarationInternalType.EXPORT) {
                const i = item as RevenueExportAdditionalInformation;
                return i.code ?? '-';
            } else {
                if (declarationType === DeclarationName.H7) {
                    const i = item as SupportingDocuments;
                    return i.supportingDocumentType ?? '-';
                } else {
                    const i = item as ProducedDocumentsWritingOffHeader;
                    return i.documentType ?? '-';
                }
            }
        },
        [declarationType, internalType]
    );

    const getDocumentIdentifier = useCallback(
        (item: ProducedDocumentsWritingOffHeader | SupportingDocuments | RevenueExportAdditionalInformation) => {
            if (internalType === DeclarationInternalType.EXPORT) {
                const i = item as RevenueExportAdditionalInformation;
                return i.identifier ?? '-';
            } else {
                if (declarationType === DeclarationName.H7) {
                    const i = item as SupportingDocuments;
                    return i.supportingDocumentReferenceNumber ?? '-';
                } else {
                    const i = item as ProducedDocumentsWritingOffHeader;
                    return i.documentIdentifier ?? '-';
                }
            }
        },
        [declarationType, internalType]
    );
    return (
        <>
            {data && data.length ? (
                data.map((item, index) => (
                    <CardItemRow
                        viewOnly={viewOnly}
                        documentType={getDocumentType(item)}
                        description={getDescription(item)}
                        identifier={getDocumentIdentifier(item)}
                        key={`box44-list-${index}`}
                        onDelete={() => onDelete(item)}
                        onEdit={() => onEdit(item, index)}
                    />
                ))
            ) : (
                <GreyText>No box44 items found. </GreyText>
            )}
        </>
    );
};
export default Box44List;
