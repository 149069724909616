import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import Notification from 'components/ui/base/notification/Notification';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useCustomers from 'hooks/useCustomers';
import useDeclarations from 'hooks/useDeclarations';
import useJobs from 'hooks/useJobs';
import useRequest from 'hooks/useRequest';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { deleteDeclaration, listDeclarations as listDeclarationsReq } from 'store/declarations/client';
import { Declaration } from 'store/declarations/declaration';
import { JobResponse } from 'store/jobs/job';
import { DeletedDeclaration, JobWithoutDeclarationsModal } from 'views/jobs/Jobs';
import AddTeamMember from './AddTeamMember';
import ConfirmDeclarationDeleteModal from './ConfirmDeclarationDeleteModal';
import ConfirmJobDeleteModal from './ConfirmJobDeleteModal';
import { ReverseCol } from './Dashboard.styles';
import DashboardSearchBar from './DashboardSearchBar';
import DashboardTable from './DashboardTable';
import LastDeclarationOfJobDeleteModal from './LastDeclarationOfJobDeleteModal';
import { getDeclarationsFromJob } from './utils';

const BrokerDashboard: FC = () => {
    const { t } = useTranslation('dashboard');
    const { jobs, listJobs, deleteJob, error, createJob } = useJobs();
    const [openDrawer, setOpenDrawer] = useState(false);
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const [deleteJobClicked, setDeleteJobClicked] = useState<boolean>(false);
    const [deleteDeclarationClicked, setDeleteDeclarationClicked] = useState<boolean>(false);
    const [showCreateCard, setShowCreateCard] = useState(false);
    const navigate = useNavigate();
    const [jobsModalVisible, setJobsModalVisible] = useState(false);
    const [declarationsModalVisible, setDeclarationsModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState<string>();
    const [declarationsDeleteId, setDeclarationsDeleteId] = useState<DeletedDeclaration | undefined>();
    const { declarations, listDeclarations, isLoading } = useDeclarations();
    const { customers, listCustomers } = useCustomers();
    const [declarationFromJobs, setDeclarationFromJobs] = useState<{
        [key: string]: Declaration[];
    }>({});
    const [jobWithoutDeclarationsModal, setJobWithoutDeclarationsModal] = useState<JobWithoutDeclarationsModal>({
        open: false,
        jobReference: undefined,
    });
    const {
        doRequest: doDeleteDeclaration,
        error: deleteDeclarationError,
        isLoading: deleteDeclarationIsloading,
    } = useRequest(deleteDeclaration);

    const getDeclarations = useCallback(async () => {
        return await getDeclarationsFromJob(jobs, listDeclarationsReq, setDeclarationFromJobs);
    }, [jobs]);

    useEffect(() => {
        if (jobs.list) {
            getDeclarations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobs, getDeclarations]);

    useEffect(() => {
        listJobs();
        listDeclarations();
        listCustomers();
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Home',
                path: '',
            },
            {
                breadcrumbName: 'Dashboard',
                path: '',
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const querySearch = async (query: string) => {
        if (query) {
            const params = { query };
            await listCustomers(params);
            await listDeclarations(params);
            await listJobs(params);
        } else {
            await listCustomers();
            await listDeclarations();
            await listJobs();
        }
    };

    useEffect(() => {
        if (deleteJobClicked && deleteId) {
            if (!error && !isLoading) {
                listJobs();
                Notification({
                    type: 'success',
                    messageTitle: t('job.deleteSuccessTitle'),
                    description: t('job.deleteSuccessMessage'),
                });
            } else {
                Notification({
                    type: 'error',
                    messageTitle: t('job.deleteErrorTitle'),
                    description: t('job.deleteErrorMessage'),
                });
            }
            setDeleteJobClicked(false);
            setDeleteId(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteJobClicked, deleteId, error, isLoading]);

    useEffect(() => {
        const checkIfJobHasMoreDeclarations = async (declarationId: string, jobID?: string) => {
            if (!jobID) {
                return;
            }

            const declarations = await getDeclarations();
            if (declarations) {
                const jobDeclarations = declarations[jobID];
                if (!jobDeclarations) {
                    const job = jobs.list.find((job) => job.id === jobID);
                    setJobWithoutDeclarationsModal({
                        open: true,
                        jobReference: job?.referenceId || '-',
                    });
                }
            }
        };

        if (deleteDeclarationClicked && declarationsDeleteId) {
            if (!deleteDeclarationError && !deleteDeclarationIsloading) {
                checkIfJobHasMoreDeclarations(declarationsDeleteId.declarationId, declarationsDeleteId.jobId);
                Notification({
                    type: 'success',
                    messageTitle: t('declaration.deleteSuccessTitle'),
                    description: t('declaration.deleteSuccessMessage'),
                });
            } else {
                Notification({
                    type: 'error',
                    messageTitle: t('declaration.deleteErrorTitle'),
                    description: t('declaration.deleteErrorMessage'),
                });
            }
            setDeleteDeclarationClicked(false);
            setDeclarationsDeleteId(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteDeclarationClicked, declarationsDeleteId, error]);

    const addedTeamMember = () => {
        setOpenDrawer(false);
        Notification({
            type: 'success',
            messageTitle: t('addNewUser.addSuccessTitle'),
            description: t('addNewUser.addSuccessMessage'),
        });
    };

    const hadleCreateError = () => {
        Notification({
            type: 'error',
            messageTitle: t('addNewUser.addErrorTitle'),
            description: t('addNewUser.addErrorMessage'),
        });
    };

    const handleDeleteJob = async () => {
        if (deleteId) {
            await deleteJob(deleteId);
            setDeleteJobClicked(true);
        }
        setJobsModalVisible(false);
    };

    const handleDeleteJobAfterLastDeclaration = async (jobReference?: string) => {
        if (jobReference) {
            const job = jobs.list.find((job) => job.referenceId === jobReference);
            if (job) {
                await deleteJob(job.id);
                setDeleteJobClicked(true);
                setDeleteId(job.id);
            }
        }
        setJobWithoutDeclarationsModal({
            open: false,
            jobReference: undefined,
        });
    };

    const handleDeleteDeclaration = async () => {
        if (declarationsDeleteId) {
            await doDeleteDeclaration(declarationsDeleteId.declarationId);
            setDeleteDeclarationClicked(true);
        }
        setDeclarationsModalVisible(false);
    };

    const onDeleteJob = async (id: string) => {
        setDeleteId(id);
        setJobsModalVisible(true);
    };

    const onDeleteDeclaration = async (id: string) => {
        const declarations = Object.values(declarationFromJobs).find((declarations) => {
            return declarations.find((declaration) => declaration.id === id);
        });

        const jobId = declarations && declarations.length > 0 ? declarations[0].jobId : undefined;

        setDeclarationsDeleteId({
            declarationId: id,
            jobId: jobId,
        });
        setDeclarationsModalVisible(true);
    };

    const createNewJob = async () => {
        const job = (await createJob()) as JobResponse;
        navigate(`/jobs/${job.id}`, { state: { job } });
    };

    return (
        <>
            <ConfirmJobDeleteModal
                jobsModalVisible={jobsModalVisible}
                handleDeleteJobs={handleDeleteJob}
                closeJobsDrawer={() => setJobsModalVisible(false)}
            />
            <ConfirmDeclarationDeleteModal
                declarationModalVisible={declarationsModalVisible}
                handleDeleteDeclaration={handleDeleteDeclaration}
                closeDeclarationDrawer={() => setDeclarationsModalVisible(false)}
            />
            <LastDeclarationOfJobDeleteModal
                jobsModalVisible={jobWithoutDeclarationsModal.open}
                handleDeleteJobs={() => handleDeleteJobAfterLastDeclaration(jobWithoutDeclarationsModal.jobReference)}
                jobReference={jobWithoutDeclarationsModal.jobReference}
                closeJobsDrawer={() => setJobWithoutDeclarationsModal({ jobReference: undefined, open: false })}
            />
            <Row>
                <Col span={18}>
                    {declarations && (
                        <DashboardSearchBar
                            querySearch={querySearch}
                            declarations={declarations?.list}
                            jobs={jobs?.list}
                            customers={customers?.list}
                            declarationFromJobs={declarationFromJobs}
                        />
                    )}
                </Col>
                <ReverseCol span={6}>
                    <Button size="large" onClick={() => navigate('/help')}>
                        {t('help')}
                    </Button>
                </ReverseCol>
            </Row>

            <DashboardTable
                jobs={jobs}
                declarationFromJobs={declarationFromJobs}
                onCreateNewJob={createNewJob}
                showCreateCard={showCreateCard}
                openCreateCard={() => setShowCreateCard(true)}
                closeCreateCard={() => setShowCreateCard(false)}
                onDeleteJob={onDeleteJob}
                onDeleteDeclaration={onDeleteDeclaration}
                openAddTeamMember={() => setOpenDrawer(true)}
            />

            <AddTeamMember
                visible={openDrawer}
                closeDrawer={() => setOpenDrawer(false)}
                handleOk={addedTeamMember}
                handleCreateError={hadleCreateError}
            />
        </>
    );
};
export default BrokerDashboard;
