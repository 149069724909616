import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { DocumentType, Icon, StyledTextSmall } from './CardItemRow.styles';

interface Props {
    documentType: string;
    description: string;
    identifier: string;
    viewOnly?: boolean;
    onEdit: Function;
    onDelete: Function;
}

const CardItemRow: FC<Props> = ({ documentType, description, identifier, viewOnly, onEdit, onDelete }) => {
    return (
        <>
            <Row justify="space-between">
                <Col>
                    <DocumentType>{documentType} </DocumentType>
                </Col>
                <Col>
                    <StyledTextSmall>{description} </StyledTextSmall>
                </Col>
                <Col>
                    <StyledTextSmall>{identifier} </StyledTextSmall>
                </Col>
                {!viewOnly && (
                    <Col>
                        <Icon onClick={() => onEdit()}>
                            <EditOutlined />
                        </Icon>
                        <Icon onClick={() => onDelete()}>
                            <DeleteOutlined />
                        </Icon>
                    </Col>
                )}
            </Row>
        </>
    );
};
export default CardItemRow;
