import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const TansportCostsFinalDestinationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('transportCostToFinalDestination')}>
            <FormCardContainer>
                <DeclarationNumberInput
                    maxLength={16}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('transportCostsToFinalDestAmount', props)}
                    refNumber="4.19"
                    label={t('amount')}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.19', t('amount'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CurrencySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps('transportCostsToFinalDestCurrency', props)}
                    refNumber="4.19"
                    tooltip={getCdsTooltipsByRefNumberAndField('4.19', t('currency'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default TansportCostsFinalDestinationCard;
