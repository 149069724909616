import { NavItem } from 'views/declarations/common/nav-item';

export const exportSectionsMasterDetails: NavItem[] = [
    { title: 'Master Details', href: 'master-details-form-card' },
    { title: 'Goods shipment', href: 'goods-shipment-form-card' },
    { title: 'Consignor', href: 'consignor-form-card' },
    { title: 'Consignee', href: 'consignee-form-card' },
    { title: 'Delivery Terms', href: 'delivery-terms-form-card' },
    { title: 'Consigment', href: 'consigment-form-card' },
    { title: 'Warehouse', href: 'warehouse-form-card' },
    { title: 'Additional Information', href: 'additional-information-form-card' },
    { title: 'Additional Document', href: 'additional-document-form-card' },
];

export const exportSectionsProducts: NavItem[] = [
    { title: 'Taric Code', href: 'taric-code-form-card' },
    { title: 'Goods Information', href: 'goods-information-form-card' },
    { title: 'Previous Document', href: 'previous-document-form-card' },
    { title: 'Commodity', href: 'commodity-form-card' },
    { title: 'Packages', href: 'packages-form-card' },
    { title: 'Additional Information', href: 'additional-information-form-card' },
    { title: 'Additional Document', href: 'additional-document-form-card' },
    { title: 'Duty Tax Fee', href: 'dutty-tax-fee-form-card' },
];
