import { FormikErrors, FormikProps, setNestedObjectValues } from 'formik';
import { merge } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import transformValidations, {
    ValidationObj,
    Validations,
    ValidationType,
} from 'utils/validations/transformValidations';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { DeclarationErrors } from '../common/declaration-errors';

export const EORI_REGEX = /^[A-Z]{2}[A-Z0-9]{0,15}$/;
export const TARIC_CODE_TYPE = /[0-9]{2}/;
export const CUSTOMS_OFFICE = /[a-zA-Z]{2}[a-zA-Z0-9]{6}/;

export const getPatternDigitsAfterComma = (beforeComma: number, afterComma: number) =>
    new RegExp(`^\\d{0,${beforeComma}}(\\.\\d{0,${afterComma}})?$`);

export type ObjectShapeValues = ObjectShape extends Record<string, infer V> ? V : never;
export type Shape<T extends Record<any, any>> = Partial<Record<keyof T, ObjectShapeValues>>;

export const getFloatValidationMessage = (beforeComma: number, afterComma: number) =>
    `Field cannot be longer than ${beforeComma} characters before comma and longer than ${afterComma} characters after comma.`;

export const getFloatValidation = (property: string, beforeComma: number, afterComma: number) => {
    return Yup.number()
        .positive()
        .test(
            'is-decimal',
            `${camelCaseToSentence(
                property
            )} cannot be longer than ${beforeComma} characters before comma and longer than ${afterComma} characters after comma.`,
            (val: any) => (val ? getPatternDigitsAfterComma(beforeComma, afterComma).test(val.toString()) : true)
        );
};

export const valueTypeForSentence = (value: any) => {
    let endOfSentence;

    if (typeof value === 'string') {
        endOfSentence = 'characters';
    } else if (typeof value === 'number') {
        endOfSentence = 'digits';
    }

    return endOfSentence;
};

export const camelCaseToSentence = (camelCase: string) =>
    camelCase
        .replace(/([A-Z])/g, (match) => ` ${match[0].toLowerCase()}${match.slice(1)}`)
        .replace(/^./, (match) => match[0].toUpperCase())
        .trim();

export const getMaxValidation = (property: string, number: number) => {
    const message = `${camelCaseToSentence(property)} cannot be longer than ${number} characters.`;
    return Yup.string().max(number, message);
};
export const getMinCharactersValidation = (property: string, number: number) => {
    const message = `${camelCaseToSentence(property)} must be at least ${number} characters long.`;
    return Yup.string().min(number, message);
};

export const getNumberMaxDigitsValidation = (property: string, number: number) => {
    return Yup.number().test(
        'num-length',
        `${camelCaseToSentence(property)} cannot be longer than ${number} digits.`,
        (val) => (val ? val?.toString().length <= number : true)
    );
};

export const getNumberMaxValueValidation = (property: string, number: number) => {
    return Yup.number().test('num-max', `${camelCaseToSentence(property)} can not be more than ${number}.`, (val) =>
        val ? val <= number : true
    );
};

export const getRequiredMessage = (property: string) => {
    return `${camelCaseToSentence(property)} is required.`;
};

export const getInvalidMessage = (property: string) => {
    return `${camelCaseToSentence(property)} is invalid.`;
};

export const invalidEoriMessage = `This EORI code is invalid!`;
export const getExactLengthValidation = (property: string, number: number) => {
    return Yup.string().test(
        'exact-len',
        `${camelCaseToSentence(property)} must be exactly ${number} characters.`,
        (val) => (val ? val?.length === number : true)
    );
};

export const getNumberExactLengthValidation = (property: string, number: number) => {
    return Yup.number().test('exact-len', `${camelCaseToSentence(property)} must be exactly ${number} digits.`, (val) =>
        val ? val?.toString().length === number : true
    );
};

export const getEoriValidation = (maxValidationString: string = 'eori') =>
    getMaxValidation(maxValidationString, 17)
        .matches(EORI_REGEX, invalidEoriMessage)
        .nullable()
        .test({
            message: invalidEoriMessage,
            test: async (value, a) => {
                if (!value) {
                    return true;
                }

                const field = a.path;

                try {
                    const isValid = await window.EoriService.checkEori(field, value);
                    return isValid;
                } catch (error: any) {
                    if (error.code === 503 || error.code === 500) {
                        return true;
                    }

                    return a.createError({
                        message: error.reason,
                    });
                }
            },
        });

export const getStringMaxMessage = (field: string, max: number) => {
    return `${camelCaseToSentence(field)} cannot be longer than ${max} characters.`;
};
export const getNumberMaxMessage = (field: string, max: number) => {
    return `${camelCaseToSentence(field)} cannot be longer than ${max} characters.`;
};

type CustomerSchemaFields =
    | 'name'
    | 'city'
    | 'streetAndNumber'
    | 'postCode'
    | 'country'
    | 'eori'
    | 'statusCode'
    | 'phoneNumber';
interface CustomerSchemaArgs extends ValidationObj<CustomerSchemaFields> {
    addressRequired?: boolean;
}

export const getCustomerValidation = (obj?: CustomerSchemaArgs) => {
    const { addressRequired, ...rest } = obj ?? {};

    const defaultCustomerSchema: ValidationObj<CustomerSchemaFields> = {
        eori: {
            type: getEoriValidation(),
        },
        name: {
            type: ValidationType.STRING,
            max: 70,
            required: obj?.addressRequired,
            nullable: true,
        },
        streetAndNumber: {
            type: ValidationType.STRING,
            max: 70,
            required: obj?.addressRequired,
            nullable: true,
        },
        city: {
            type: ValidationType.STRING,
            max: 35,
            required: obj?.addressRequired,
            nullable: true,
        },
        postCode: {
            type: ValidationType.STRING,
            max: 9,
            required: obj?.addressRequired,
            nullable: true,
        },
        country: {
            type: ValidationType.STRING,
            max: 2,
            required: obj?.addressRequired,
            nullable: true,
        },
    };

    return transformValidations(merge(defaultCustomerSchema, rest));
};

// type UkCustomerSchemaFields = 'name' | 'cityName' | 'line' | 'postcodeID' | 'countryCode' | 'id' | 'statusCode';
// interface UkCustomerSchemaArgs extends ValidationObj<CustomerSchemaFields> {
//     addressRequired?: boolean;
// }

type UkCustomerSchemaArgs = {
    id?: Validations;
    name?: Validations;
    addressRequired?: boolean;
    address?: {
        line?: Validations;
        cityName?: Validations;
        countryCode?: Validations;
        postcodeID?: Validations;
        statusCode?: Validations;
    };
};

export const getUkCustomerValidation = (obj?: UkCustomerSchemaArgs) => {
    const { addressRequired, ...rest } = obj ?? {};

    const defaultCustomerSchema: ValidationObj<any> = {
        id: {
            type: getEoriValidation(),
            label: 'identificationNumber',
            nullable: true,
        },
        name: {
            type: ValidationType.STRING,
            max: 70,
            required: obj?.addressRequired,
            nullable: true,
        },
        address: {
            line: {
                type: ValidationType.STRING,
                label: 'streetAndNumber',
                max: 70,
                required: obj?.addressRequired,
                nullable: true,
            },
            cityName: {
                type: ValidationType.STRING,
                label: 'city',
                max: 35,
                required: obj?.addressRequired,
                nullable: true,
            },
            postcodeID: {
                type: ValidationType.STRING,
                label: 'postCode',
                max: 9,
                required: obj?.addressRequired,
                nullable: true,
            },
            countryCode: {
                type: ValidationType.STRING,
                label: 'country',
                max: 2,
                required: obj?.addressRequired,
                nullable: true,
            },
        },
    };

    return transformValidations(merge(defaultCustomerSchema, rest));
};

type ValidateNumberOfItemsParameters<TDeclaration> = {
    formik: FormikProps<TDeclaration>;
    getProductsErrors: (errors: FormikErrors<TDeclaration>) => any[];
    setFormErrors: (formDeclarationErrors: FormikErrors<TDeclaration>) => void;
    setDeclarationErrors: Dispatch<SetStateAction<DeclarationErrors>>;
    formikValues: { numberOfItems: string | number | undefined; productsLength: number | undefined };
};
export const validateNumberOfItems = <TDeclaration>({
    formik,
    getProductsErrors,
    setFormErrors,
    setDeclarationErrors,
    formikValues,
}: ValidateNumberOfItemsParameters<TDeclaration>) => {
    const setError = async (errorMessage: string) => {
        const errors = await formik.validateForm().then((v) => {
            formik.setTouched(setNestedObjectValues(v, true), false);
            return v;
        });
        const formErrors: FormikErrors<TDeclaration> = {
            numberOfItems: errorMessage,
            ...errors,
        };
        formik.setFieldTouched('numberOfItems', true, false);
        formik.setErrors(formErrors);

        const parsedErrors: DeclarationErrors = {
            masterDetailsError: true,
            productsError: (getProductsErrors(errors) || []).map(Boolean),
        };

        setFormErrors(formErrors);
        setDeclarationErrors(parsedErrors);
    };

    const numberOfItems = Number(formikValues.numberOfItems);
    const lengthItems = formikValues.productsLength || 0;

    if (lengthItems === 0) {
        setError('No products attached to declaration.');
        return false;
    }

    if (isNaN(numberOfItems)) {
        setError('Number of items is required.');
        return false;
    }

    if (numberOfItems === 0) {
        setError('Number of items must be one or more.');
        return false;
    }

    if (numberOfItems !== lengthItems) {
        setError('Number of items from the input does not match the number of items in the declaration.');
        return false;
    }

    setDeclarationErrors((prev) => ({ ...prev, masterDetailsError: false }));
    return true;
};

export const getNumberOfItemsValidation = (numberOfItems?: number) => {
    let validation = Yup.number();
    if (numberOfItems === undefined) return validation;
    return validation.test(
        'number-of-item',
        `Number of items from the input does not match the number of items in the declaration.`,
        (val) => {
            if (val == null) return true;
            return val === numberOfItems;
        }
    );
};

export const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};
