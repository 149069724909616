import { FormikErrors } from 'formik';
import { get } from 'lodash';
import { CardError, ItemsFieldError } from 'store/declaration-form-errors/DeclarationFormErrors';
import { CdsExportDeclaration, CdsExportDeclarationPayloadPayload } from 'store/declarations/uk/export-declaration';
import {
    parseFieldErrorsForCard,
    parseFieldErrorsForCustomerCard,
    parseFieldErrorsForMultipleCard,
} from 'utils/validationParserUtils';

const parseValidationErrors = (validation: FormikErrors<CdsExportDeclaration>) => {
    const masterDetailsErrors: CardError[] = [];
    // TODO Put card parsers
    parseFieldErrorsForCard(
        'Master Details',
        [
            { typeCode: 'Declaration Type' },
            { additionalTypeCode: 'Additional Declaration Type' },
            { specificCircumstancesCodeCode: 'Specific Circumstances Indicator' },
            { goodsItemQuantity: 'Number of items' },
            { 'goodsShipment.ucr.traderAssignedReferenceID': 'UCR' },
            { 'consignment.freight.paymentMethodCode': 'Method of payment for transport charges' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCustomerCard('Exporter', 'exporter', masterDetailsErrors, validation);
    parseFieldErrorsForCustomerCard('Consignee', 'goodsShipment.consignee', masterDetailsErrors, validation);
    parseFieldErrorsForCustomerCard('Declarant', 'declarant', masterDetailsErrors, validation);
    parseFieldErrorsForCustomerCard('Representative', 'agent', masterDetailsErrors, validation);
    parseFieldErrorsForCustomerCard('Carrier', 'consignment.carrier', masterDetailsErrors, validation);
    parseFieldErrorsForMultipleCard(
        'Holder of the authorisation identification No.',
        'authorisationHolder',
        [{ id: 'Identification Number' }, { categoryCode: 'Authorisation Type' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard(
        'Identification of Warehouse',
        [
            { 'goodsShipment.warehouse.id': 'Warehouse Identifier' },
            { 'goodsShipment.warehouse.typeCode': 'Warehouse type' },
            { 'goodsShipment.warehouse': '' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard(
        'Total Amount Invoiced',
        [
            { invoiceAmount: 'Total Amount Invoiced - Amount' },
            { invoiceAmountCurrencyId: 'Total Amount Invoiced - Currency' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard(
        'Country of Destination',
        [
            { 'goodsShipment.destination.countryCode': 'Country of destination code' },
            { 'goodsShipment.exportCountry.id': 'Country of dispatch / export code' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard(
        'Location Of Goods',
        [
            { 'goodsShipment.consignment.goodsLocation.id': 'Additional Identifier' },
            { 'goodsShipment.consignment.goodsLocation.name': 'Identification of location' },
            { 'goodsShipment.consignment.goodsLocation.typeCode': 'Goods Location Type' },
            { 'goodsShipment.consignment.goodsLocation.address.countryCode': 'Country' },
            { 'goodsShipment.consignment.goodsLocation.address.typeCode': 'Goods Location Qualifier' },
            { 'goodsShipment.consignment.goodsLocation': '' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard(
        'Supervising customs office',
        [{ 'supervisingOffice.id': 'Supervising customs office' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard(
        'Customs Office of Presentation',
        [
            { 'declarant.id': 'Person Presenting the Goods to Customs' },
            { 'presentationOffice.id': 'Customs Office of Presentation' },
            { 'exitOffice.id': 'Customs Office of Exit' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard(
        'Gross Mass',
        [
            { totalGrossMassMeasure: 'Gross Mass' },
            { totalPackageQuantity: 'Total Packages' },
            { 'goodsShipment.consignment.containerCode': 'Container' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard(
        'Mode of Transport At The Border',
        [
            { 'borderTransportMeans.modeCode': 'Mode of Transport At The Border' },
            { 'goodsShipment.consignment.departureTransportMeans.modeCode': 'Inland mode of transport' },
            {
                'borderTransportMeans.registrationNationalityCode':
                    'Nationality of active means of transport crossing the border',
            },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard(
        'Identity of means of transport at departure',
        [
            { 'goodsShipment.consignment.departureTransportMeans.identificationTypeCode': 'Type of Identification' },
            { 'goodsShipment.consignment.departureTransportMeans.id': 'Identification Number' },
            { 'goodsShipment.consignment.departureTransportMeans': '' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard(
        'Identity of active means of transport crossing the border',
        [
            { 'borderTransportMeans.identificationTypeCode': 'Type of Identification' },
            { 'borderTransportMeans.id': 'Identification Number' },
            { borderTransportMeans: '' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Countries Of Routing Codes',
        'consignment.itinerary',
        [{ sequenceNumeric: 'Sequence Numeric' }, { routingCountryCode: 'Routing Country Code' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Guarantee Types',
        'obligationGuarantee',
        [{ securityDetailsCode: 'Guarantee Type' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Guarantee Reference',
        'obligationGuarantee',
        [
            { referenceId: 'GRN' },
            { id: 'Other Guarantee reference' },
            { accessCode: 'Access Code' },
            { amountAmount: 'Amount of import duty' },
            { amountAmountCurrencyId: 'Currency' },
            { 'guaranteeOffice.id': 'Customs office of guarantee' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard(
        'Nature of Transaction',
        [{ 'goodsShipment.transactionNatureCode': 'Nature of Transaction' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForCard('DUCR/MUCR', [{ ducr: 'DUCR' }], masterDetailsErrors, validation);

    parseFieldErrorsForMultipleCard(
        'Previous Documents',
        'goodsShipment.previousDocument',
        [
            { categoryCode: 'Previous Document Category' },
            { typeCode: 'Previous Document Type' },
            { id: 'Previous Document Reference' },
            { lineNumeric: 'Previous Document Identifier' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'additionalInformation',
        [
            { statementCode: 'Additional Information Code' },
            { statementDescription: 'Additional Information Description' },
        ],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Additional supply chain actor(s) Ident. No.',
        'goodsShipment.aeoMutualRecognitionParty',
        [{ id: 'Identification Number' }, { roleCode: 'Role Code' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Additional Fiscal References Identification',
        'goodsShipment.domesticDutyTaxParty',
        [{ id: 'VAT Identification Number' }, { roleCode: 'Role Code' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Container Identification Number',
        'goodsShipment.consignment.transportEquipment',
        [{ id: 'Container Identification Number' }, { seal: 'Seal' }],
        masterDetailsErrors,
        validation
    );
    parseFieldErrorsForMultipleCard(
        'Consignment Items',
        'consignment.consignmentItem',
        [{ 'freight.paymentMethodCode': 'Method of payment for transport charges' }],
        masterDetailsErrors,
        validation
    );

    const itemsErrors: ItemsFieldError[] = [];
    parseDeclarationItems(itemsErrors, validation);

    return {
        masterDetails: masterDetailsErrors,
        items: itemsErrors,
    };
};

const parseDeclarationItems = (
    errors: ItemsFieldError[],
    validation: FormikErrors<CdsExportDeclarationPayloadPayload>
) => {
    const products = get(validation, 'goodsShipment.governmentAgencyGoodsItem');

    if (!Array.isArray(products)) return;

    products?.forEach((itemErrors: any, index: number) => {
        const item = { index: index + 1, errors: [] };

        parseDeclarationItem(item.errors, itemErrors);

        if (item.errors.length > 0) {
            errors.push(item);
        }
    });
};

export const parseExportProductTemplate = (validation: FormikErrors<CdsExportDeclarationPayloadPayload>) => {
    const productTemplateErrors: CardError[] = [];

    parseDeclarationItem(productTemplateErrors, validation);

    return { template: productTemplateErrors };
};

const parseDeclarationItem = (parsed: CardError[], errors: FormikErrors<CdsExportDeclarationPayloadPayload>) => {
    parseFieldErrorsForCard(
        'Commodity Codes',
        [
            { 'commodity.classification.cv.id': 'CUS Code' },
            { 'commodity.classification.tsp.id': 'Combined Nomenclature Code' },
            { 'commodity.classification.tra.id': 'Taric Additional Code' },
            { 'commodity.classification.gn.id': 'National Additional Code' },
            { 'commodity.classification': '' },
        ],
        parsed,
        errors
    );

    parseFieldErrorsForCard(
        'Goods Information',
        [
            { 'commodity.description': 'Description of Goods' },
            { 'ucr.traderAssignedReferenceID': 'UCR' },
            { 'destination.countryCode': 'Country of destination code' },
            { 'commodity.goodsMeasure.netNetWeightMeasure': 'Net Mass' },
            { 'commodity.goodsMeasure.tariffQuantity': 'Supplementary Units' },
            { 'commodity.goodsMeasure.grossMassMeasure': 'Gross Mass' },
            { statisticalValue: 'Statistical Value - Amount' },
            { statisticalValuesCurrencyId: 'Statistical Value - Currency' },
            { 'commodity.dangerousGoods.undgid': 'UN Dangerous Goods code' },
        ],
        parsed,
        errors
    );
    parseFieldErrorsForCard(
        'Procedure',
        [
            { 'governmentProcedure.currentCode': 'Procedure Code' },
            { 'governmentProcedure.previousCode': 'Previous Procedure Code' },
            { 'governmentProcedure.additionalCurrentCode': 'Additional Procedure Code' },
            { governmentProcedure: '' },
        ],
        parsed,
        errors
    );
    parseFieldErrorsForCustomerCard('Consignee', 'consignee', parsed, errors);
    parseFieldErrorsForMultipleCard(
        'Additional Information',
        'additionalInformation',
        [
            { statementCode: 'Additional Information Code' },
            { statementDescription: 'Additional Information Description' },
        ],
        parsed,
        errors
    );
    parseFieldErrorsForMultipleCard(
        'Additional Document',
        'additionalDocument',
        [
            { categoryCode: 'Documents Produced - Document Category Code' },
            { typeCode: 'Documents Produced - Document Type Code' },
            { id: 'Documents Produced - Document Identifier' },
            { lpcoExemptionCode: 'Documents Produced - Document Status' },
            { name: 'Documents Produced - Document Status Reason' },
            { effectiveDateTime: 'Writing Off - Date of Validity' },
            { 'submitter.name': 'Writing Off - Issuing Authority' },
            { 'writeOff.amountAmount': 'Writing Off - Measurement Unit & Qualifier' },
            { 'writeOff.quantityQuantity': 'Writing Off - Quantity' },
        ],
        parsed,
        errors
    );
    parseFieldErrorsForCard(
        'Nature of Transaction',
        [{ transactionNatureCode: 'Nature of Transaction' }],
        parsed,
        errors
    );
    parseFieldErrorsForMultipleCard(
        'Packages',
        'packaging',
        [
            { typeCode: 'Type of packages' },
            { quantityQuantity: 'Number of Packages' },
            { marksNumbersID: 'Shipping Marks' },
        ],
        parsed,
        errors
    );
    parseFieldErrorsForMultipleCard(
        'Previous Documents',
        'previousDocument',
        [
            { categoryCode: 'Previous Document Category' },
            { typeCode: 'Previous Document Type' },
            { id: 'Previous Document Reference' },
            { lineNumeric: 'Goods Item Identifier' },
        ],
        parsed,
        errors
    );
    parseFieldErrorsForMultipleCard(
        'Additional supply chain actor(s) Ident. No.',
        'aeoMutualRecognitionParty',
        [{ id: 'Identification Number' }, { roleCode: 'Role Code' }],
        parsed,
        errors
    );
    parseFieldErrorsForMultipleCard(
        'Additional Fiscal References Identification',
        'domesticDutyTaxParty',
        [{ id: 'VAT Identification Number' }, { roleCode: 'Role Code' }],
        parsed,
        errors
    );
    parseFieldErrorsForMultipleCard(
        'Country of Origin',
        'origin',
        [{ countryCode: 'Country of Origin - Country Code' }, { typeCode: 'Country of Origin - Type Code' }],
        parsed,
        errors
    );
    parseFieldErrorsForMultipleCard(
        'Container Identification Number',
        'commodity.transportEquipment',
        [{ id: 'Container Identification Number' }],
        parsed,
        errors
    );
};

export default parseValidationErrors;
