import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { Col, Row } from 'antd';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import addPathPrefix from 'utils/addPathPrefix';

const DocumentsAdditionalInfoCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getH7TooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix ?? 'goodsShipment.documentsAuthorisations.additionalInformation'}
            initialValues={{ additionalInformationCode: '', additionalInformationText: '' }}
            list={(list) => [
                { field: t('additionalInformation.code'), value: list.additionalInformationCode },
                { field: t('additionalInformation.description'), value: list.additionalInformationText },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            cardNumber={props.cardNumber}
            cardTotal={props.cardTotal}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            title={t('documentsAdditionalInformation')}
            condensed
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'additionalInformationCode'), formProps)}
                        refNumber="2.2"
                        label={t('additionalInformation.code')}
                        selectOptions={normalizeCodesToSelect(codelists?.irelandAdditionalInformationCodes || [])}
                        tooltip={getH7TooltipsByRefNumberAndField('2.2', t('additionalInformation.code'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormInput
                        viewOnly={props.viewOnly}
                        maxLength={512}
                        {...getFormikProps(addPathPrefix(path, `additionalInformationText`), formProps)}
                        refNumber="2.2"
                        label={t('additionalInformation.description')}
                        tooltip={getH7TooltipsByRefNumberAndField('2.2', t('additionalInformation.description'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            )}
        />
    );
};

export default DocumentsAdditionalInfoCard;
