import config from 'config';
import axiosClient from '../../config/axios';
import { ListPayload, ListResponse } from '../../core/http/response';
import { Code, CodeList } from './code';

export const getUkValuationMethodTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/valuationmethodtypes`)
        .then((response) => response.data.payload);

export const getUkValuationIndicatorTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/valuationindicatortypes`)
        .then((response) => response.data.payload);

export const getUkUnLocodes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/unlocodes`)
        .then((response) => response.data.payload);

export const getUkTransportModeTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/transportmodetypes`)
        .then((response) => response.data.payload);

export const getUkTransportMeansIdentificationTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/transportmeansidentificationtypes`)
        .then((response) => response.data.payload);

export const getUkTransactionNatureTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/transactionnaturetypes`)
        .then((response) => response.data.payload);

export const getUkSupervisingCustomsOffices = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/supervisingcustomsoffices`)
        .then((response) => response.data.payload);

export const getUkSpecialProcedures = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/specialprocedures`)
        .then((response) => response.data.payload);

export const getUkProcedureCombinations = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/procedurecombinations`)
        .then((response) => response.data.payload);

export const getUkPreferences = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/preferences`)
        .then((response) => response.data.payload);

export const getUkPartyRoleStatusTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/partyrolestatustypes`)
        .then((response) => response.data.payload);

export const getUkPackageTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/packagetypes`)
        .then((response) => response.data.payload);

export const getUkImportSpecialMentions = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/importspecialmentions`)
        .then((response) => response.data.payload);

export const getUkImportRequestedProcedures = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/importrequestedprocedures`)
        .then((response) => response.data.payload);

export const getUkImportPreviousProcedures = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/importpreviousprocedures`)
        .then((response) => response.data.payload);

export const getUkImportCertificates = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/importcertificates`)
        .then((response) => response.data.payload);

export const getUkExportSpecialMentions = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/exportspecialmentions`)
        .then((response) => response.data.payload);

export const getUkExportPreviousDocuments = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/exportpreviousdocuments`)
        .then((response) => response.data.payload);

export const getUkExportCertificates = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/exportcertificates`)
        .then((response) => response.data.payload);

export const getUkExitCustomsOffices = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/exitcustomsoffices`)
        .then((response) => response.data.payload);

export const getUkDutyTaxFeeTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/dutytaxfeetypes`)
        .then((response) => response.data.payload);

export const getUkDeferredPaymentDocuments = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/deferredpaymentdocuments`)
        .then((response) => response.data.payload);

export const getUkDeclaredGuaranteeTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/declaredguaranteetypes`)
        .then((response) => response.data.payload);

export const getUkCountryGroupCategories = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/countrygroupcategories`)
        .then((response) => response.data.payload);

export const getUkContainerCategories = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/containercategories`)
        .then((response) => response.data.payload);

export const getUkAllowedDocuments = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/alloweddocuments`)
        .then((response) => response.data.payload);

export const getUkAirports = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/airports`)
        .then((response) => response.data.payload);

export const getUkPaymentMethodTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/paymentmethodtypes`)
        .then((response) => response.data.payload);

export const getUkImportPreviousDocuments = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/importpreviousdocuments`)
        .then((response) => response.data.payload);

export const getUkDocumentCategory = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/uk/documentcategories`)
        .then((response) => response.data.payload);

// Ireland CodeLists

export const getIrelandValuationMethods = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/valuationmethods`)
        .then((response) => response.data.payload);

export const getIrelandTransportModes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/transportmodes`)
        .then((response) => response.data.payload);

export const getIrelandTaxTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/taxtypes`)
        .then((response) => response.data.payload);

export const getIrelandTaricNationalAdditionalCodes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/taricnationaladditionalcodes`)
        .then((response) => response.data.payload);

export const getIrelandRepresentativeStatusCodes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/representativestatuscodes`)
        .then((response) => response.data.payload);

export const getIrelandProcessingProcedureCodes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/processingprocedurecodes`)
        .then((response) => response.data.payload);

export const getIrelandProcedureCodes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/procedurecodes`)
        .then((response) => response.data.payload);

export const getIrelandPreviousDocumentTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/previousdocumenttypes`)
        .then((response) => response.data.payload);

export const getIrelandPreferences = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/preferences`)
        .then((response) => response.data.payload);

export const getIrelandPackageKinds = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/packagekinds`)
        .then((response) => response.data.payload);

export const getIrelandNatureOfTransactions = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/natureoftransactions`)
        .then((response) => response.data.payload);

export const getIrelandNationalCustomsOfficeCodes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/nationalcustomsofficecodes`)
        .then((response) => response.data.payload);

export const getIrelandMeansIdentityTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/meansidentitytypes`)
        .then((response) => response.data.payload);

export const getIrelandLocationOfGoodsCodes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/locationofgoodscodes`)
        .then((response) => response.data.payload);

export const getIrelandIdentificationOfGoodsCodes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/identificationofgoodscodes`)
        .then((response) => response.data.payload);

export const getIrelandGuaranteeTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/guaranteetypes`)
        .then((response) => response.data.payload);

export const getIrelandCommonDocumentsTypes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/commondocumentstypes`)
        .then((response) => response.data.payload);

export const getIrelandBaseMeasurements = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/basemeasurements`)
        .then((response) => response.data.payload);

export const getIrelandAuthorisationTypeCodes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/authorisationtypecodes`)
        .then((response) => response.data.payload);

export const getIrelandAdditionsDeductionsCodes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/additionsdeductionscodes`)
        .then((response) => response.data.payload);

export const getIrelandAdditionalProcedures = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/additionalprocedures`)
        .then((response) => response.data.payload);

export const getIrelandAdditionalInformationCodes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/additionalinformationcodes`)
        .then((response) => response.data.payload);

export const getIrelandDeliveryDestinationLocationCodes = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/deliverydestinationlocationcodes`)
        .then((response) => {
            return response.data.payload;
        });

export const getIrelandOfficeOfExit = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/officeofexit`)
        .then((response) => response.data.payload);

export const getIrelandExportProcedureCategories = (): Promise<ListPayload<Code>> =>
    axiosClient
        .get<ListResponse<Code>>(`${config.cmsUrl}/codelist/ireland/exportprocedurecategories`)
        .then((response) => response.data.payload);

export const getAllCodeLists = (): Promise<CodeList> =>
    axiosClient.get<CodeList>(`${config.cmsUrl}/codelist`).then((response) => response.data);
