import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { warehouseTypeLabels } from 'store/declarations/enums/common/warehouse-type';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';

const WarehouseCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getIrelandExportTooltipsByRefNumberAndField } = useTooltips();

    const handleToggleHelp = (refNumber: string | number): void => {
        props.toggleHelp && props.toggleHelp(refNumber);
    };

    const warehouseIdentifier = useMemo(() => {
        return getFormikProps(`warehouseIdentifier`, props);
    }, [props]);

    const warehouseIdentificationNumber = useMemo(() => {
        return getFormikProps(`warehouseIdentificationNumber`, props);
    }, [props]);

    const warehouseIdentifierType = useMemo(() => {
        return getFormikProps(`warehouseIdentifierType`, props);
    }, [props]);

    const warehouseIdentifierCountry = useMemo(() => {
        return getFormikProps(`warehouseIdentifierCountry`, props);
    }, [props]);

    useEffect(() => {
        const updateWarehouseIdentifier = () => {
            const warehouseId = `${warehouseIdentifierType.fieldProps.value}${warehouseIdentificationNumber.fieldProps.value}${warehouseIdentifierCountry.fieldProps.value}`;
            warehouseIdentifier.fieldHelper?.setValue(warehouseId);
        };

        const fillForm = () => {
            if (
                !warehouseIdentifierType.fieldProps.value &&
                !warehouseIdentificationNumber.fieldProps.value &&
                !warehouseIdentifierCountry.fieldProps.value &&
                warehouseIdentifier.fieldProps.value
            ) {
                const id: string = warehouseIdentifier.fieldProps.value;
                const type = id.substring(0, 1);
                const withoutType = id.substring(1);
                const info = withoutType.substring(0, withoutType.length - 2);
                const country = id.substring(id.length - 2, id.length);
                warehouseIdentifierType.fieldHelper?.setValue(type);
                warehouseIdentificationNumber.fieldHelper?.setValue(info);
                warehouseIdentifierCountry.fieldHelper?.setValue(country);
            }
        };

        if (
            warehouseIdentifierType.fieldProps.value &&
            warehouseIdentificationNumber.fieldProps.value &&
            warehouseIdentifierCountry.fieldProps.value
        ) {
            updateWarehouseIdentifier();
        } else {
            fillForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        warehouseIdentifierType.fieldProps.value,
        warehouseIdentificationNumber.fieldProps.value,
        warehouseIdentifierCountry.fieldProps.value,
        warehouseIdentifier.fieldProps.value,
    ]);

    return (
        <section
            id={'warehouse-form-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('warehouse-form-card');
                }
            }}
        >
            <FormCard
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('Warehouse')}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                expandAll={props.expandAll}
                keyCard={props.keyCard}
                state={getCardState(
                    [
                        { name: `warehouseIdentifierType`, required: false },
                        { name: `warehouseIdentificationNumber`, required: false },
                        { name: `warehouseIdentifierCountry`, required: false },
                    ],
                    props
                )}
            >
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`warehouseIdentifierType`, props)}
                    refNumber="49"
                    label={t('warehouseType')}
                    refClicked={handleToggleHelp}
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('49', 'Warehouse Type')}
                    selectOptions={warehouseTypeLabels}
                />

                <FormInput
                    maxLength={14}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`warehouseIdentificationNumber`, props)}
                    refNumber="49"
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('49', 'Warehouse Identification')}
                    label={t('warehouseIdentificationNumber')}
                />

                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`warehouseIdentifierCountry`, props)}
                    refNumber="49"
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('49', 'Warehouse Country')}
                    label={t('warehouseIdentifierCountry')}
                />
            </FormCard>
        </section>
    );
};

export default WarehouseCard;
