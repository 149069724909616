import { ColumnsType } from 'antd/lib/table';
import DashboardTable from 'components/ui/composed/dashboard/DashboardTable';
import useWeighbridges from 'hooks/useWeighbridges';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import {
    Weighbridge,
    WeighbridgeDeviceResponse,
    WeighbridgeSortParameter,
    WeighbridgeStatus,
} from 'store/weighbridges/weighbridge';

type Props = {
    onWeighbridgesSelection?: (weighbridges: Weighbridge[]) => void;
};

const WeighbridgesTable = ({ onWeighbridgesSelection }: Props) => {
    const { weighbridges, listWeighbridges } = useWeighbridges();

    // TODO Remove test data, use `weighbridges`
    const testData = useMemo(
        () =>
            isEmpty(weighbridges.list)
                ? {
                      list: [
                          {
                              id: '1',
                              label: 'Some weighbridge',
                              lastUsed: '20/10/22',
                              location: 'Dublin',
                              status: 'Active',
                              device: { id: 'aasd-dfggf-1' } as WeighbridgeDeviceResponse,
                          },
                      ],
                      pageNumber: 1,
                      pageSize: 1,
                      total: 1,
                  }
                : weighbridges,
        [weighbridges]
    );

    const columns: ColumnsType<Weighbridge> = [
        {
            title: 'Weighbridge Label',
            dataIndex: WeighbridgeSortParameter.LABEL,
            key: WeighbridgeSortParameter.LABEL,
            align: 'left',
            render: (text: string, record, index: number) => <span>{record.label}</span>,
            sorter: true,
        },
        {
            title: 'Status',
            dataIndex: WeighbridgeSortParameter.STATUS,
            key: WeighbridgeSortParameter.STATUS,
            align: 'left',
            render: (text: string, record, index: number) => <span>{record.status}</span>,
            sorter: true,
        },
        {
            title: 'Location',
            dataIndex: WeighbridgeSortParameter.LOCATION,
            key: WeighbridgeSortParameter.LOCATION,
            align: 'left',
            render: (text: string, record, index: number) => <span>{record.location}</span>,
            sorter: true,
        },
        {
            title: 'Last Used',
            dataIndex: WeighbridgeSortParameter.LAST_USED,
            key: WeighbridgeSortParameter.LAST_USED,
            align: 'left',
            render: (text: string, record, index: number) => <span>{record.lastUsed}</span>,
            sorter: true,
        },
        {
            title: 'Device',
            dataIndex: WeighbridgeSortParameter.DEVICE,
            key: WeighbridgeSortParameter.DEVICE,
            align: 'left',
            render: (text: string, record, index: number) => (
                <span>
                    {/* TODO Fix device status check */}
                    {record.device
                        ? record.device.status !== WeighbridgeStatus.SETUP
                            ? record.device.id
                            : record.device.status
                        : ''}
                </span>
            ),
            sorter: true,
        },
    ];

    const handleRowSelection = (ids: string[]): void => {
        onWeighbridgesSelection?.(testData.list.filter(({ id }) => ids.includes(id)));
    };

    const handleDelete = () => {};
    const handleEdit = () => {};

    return (
        <DashboardTable
            columns={columns}
            data={testData}
            onPaginatorChange={listWeighbridges}
            onRowSelection={handleRowSelection}
            onDelete={handleDelete}
            onEdit={handleEdit}
            doSelectOnClick
        />
    );
};

export default WeighbridgesTable;
