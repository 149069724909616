import { useTranslation } from 'react-i18next';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import CombinedNomenclatureCodeBlock, {
    combinedNomenclatureCodesBlockValidation as combinedNomenclatureCodeBlockValidation,
} from '../../blocks/CombinedNomenclatureCodeBlock';
import CommodityCusCodeBlock, { commodityCusCodeBlockValidation } from '../../blocks/CommodityCusCodeBlock';
import NationalAdditionalCodesBlock, {
    nationalAdditionalCodesBlockValidation,
} from '../../blocks/NationalAdditionalCodesBlock';
import TaricAdditionalCodesBlock, { taricAdditionalCodesBlockValidation } from '../../blocks/TaricAdditionalCodesBlock';

export const commodityCodesCardValidation = {
    selfValidators: [
        commodityCusCodeBlockValidation,
        combinedNomenclatureCodeBlockValidation,
        taricAdditionalCodesBlockValidation,
        nationalAdditionalCodesBlockValidation,
    ],
};

const CommodityCodesCard = () => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('commodityCodes')}>
            <FormCardContainer>
                <CommodityCusCodeBlock />
                <CombinedNomenclatureCodeBlock />
                <TaricAdditionalCodesBlock />
                <NationalAdditionalCodesBlock />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default CommodityCodesCard;
