import { useFormik } from 'formik';
import React, { FC } from 'react';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { MasterDetailsIrelandCardsType } from 'views/declarations/common/skeleton/card-type';
import tsdDeclarationSkeleton from './tsd-skeleton-declaration';

interface Props {
    formik: ReturnType<typeof useFormik>; // FIXME use correct type
    toggleHelp?: (refNumber: string | number) => void;
    expandAll?: boolean;
    viewOnly?: boolean;
    selectCard?: (id: string) => void;
}

const IrelandTsdForm: FC<Props> = ({ formik, toggleHelp, expandAll, viewOnly, selectCard }) => {
    const cards = tsdDeclarationSkeleton.cards.map((obj, index) => {
        if (obj.type === MasterDetailsIrelandCardsType.SINGLE) {
            const card = React.createElement(
                obj.component as React.FC<DeclarationFormCardProps>,
                {
                    getFields: obj.getFields,
                    getFieldProps: formik.getFieldProps,
                    getFieldMeta: formik.getFieldMeta,
                    getFieldHelpers: formik.getFieldHelpers,
                    toggleHelp: toggleHelp,
                    cardTotal: tsdDeclarationSkeleton.cards.length,
                    cardNumber: obj.cardNumber,
                    defaultOpen: obj.defaultOpen,
                    keyCard: obj.key,
                    nestedComponents: obj.children,
                    key: obj.key,
                    propsPathPrefix: obj.propsPathPrefix,
                    viewOnly: viewOnly,
                    expandAll: expandAll,
                    declarationType: obj.declarationType,
                    selectCard: selectCard,
                    amendment: false,
                },
                obj.key
            );
            return card;
        }
        return <></>;
    });

    return <>{cards.map((obj) => obj)}</>;
};
export default IrelandTsdForm;
