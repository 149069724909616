import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { chiefDeclarationTypeLabels } from 'store/declarations/enums/common/declaration-type';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'typeCode';

export const declarationTypeBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        typeCode: [validators.required(), validators.exact(2)],
    },
};

interface Props extends BlockProps<Fields> {}

const DeclarationTypeBlock = ({ fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '1/1', []);

    return (
        <DeclarationField name="typeCode">
            {({ form, field }) => (
                <FormSelect
                    required
                    {...getFormikProps(field.name, form)}
                    refNumber={ucc}
                    label={t('declarationType')}
                    selectOptions={chiefDeclarationTypeLabels}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default DeclarationTypeBlock;
