import { useCallback, useEffect, useState } from 'react';
import useGlobalOverlay from './useGlobalOverlay';

const getErrorMessage = (error: any) => error.response?.data || error.message || 'Something went wrong';

const useRequest = <
    TFunction extends (params: Parameters<TFunction>[0], extraParams: Parameters<TFunction>[1]) => ReturnType<TFunction>
>(
    requestFunc: TFunction
) => {
    const [data, setData] = useState<Awaited<ReturnType<TFunction>> | null>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setLoading] = useState<boolean>(false);

    const doRequest = useCallback(
        async (...params: Parameters<TFunction>): Promise<Awaited<ReturnType<TFunction>> | null> => {
            setLoading(true);
            setError(null);

            let _data = null;

            try {
                _data = (await requestFunc(params[0], params[1])) as Awaited<ReturnType<TFunction>>;
            } catch (error) {
                setError(getErrorMessage(error));
            } finally {
                setData(_data);
                setLoading(false);

                return _data;
            }
        },
        [requestFunc]
    );

    return {
        doRequest,
        isLoading,
        error,
        data,
    };
};

const useRequestPromise = <T extends any>(request: () => Promise<T>, options?: { loadingMessage?: string }) => {
    const [data, setData] = useState<Awaited<T> | null>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setLoading] = useState<boolean>(false);

    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

    const doRequest = async () => {
        setLoading(true);
        setError(null);

        let _data = null;

        try {
            if (options?.loadingMessage)
                showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: options.loadingMessage } });
            _data = await request();
        } catch (error) {
            setError(getErrorMessage(error));
        } finally {
            setData(_data);
            setLoading(false);
            if (options?.loadingMessage) hideGlobalOverlay();

            return _data;
        }
    };

    useEffect(() => {
        doRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        refetch: doRequest,
        isLoading,
        error,
        data,
    };
};

export { useRequestPromise };

export default useRequest;
