import { Col, Row } from 'antd';
import SearchBar from 'components/ui/base/searchbar';
import { PaginatedParams } from 'core/http/pagination';
import useDeclarations from 'hooks/useDeclarations';
import useProducts from 'hooks/useProducts';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { GoodsShipmentItem as EtdGoodsShipmentItem } from 'store/declarations/ireland/electronic-transport-document';
import { EnsGoodsShipmentItem } from 'store/declarations/ireland/entry-summary-declaration';
import { RevenueExportCustomsGoodsItem } from 'store/declarations/ireland/export-declaration';
import { GoodsShipmentItem as H7GoodsShipmentItem } from 'store/declarations/ireland/h7-import-declaration';
import { GoodsShipmentItem as H1GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import { CdsExportGovernmentAgencyGoodsItem } from 'store/declarations/uk/export-declaration';
import { GovernmentAgencyGoodsItem } from 'store/declarations/uk/goods-item';
import IrelandProductsTable from 'views/declarations/ireland/common/IrelandProductsTable';
import EnsProductsTable from 'views/declarations/ireland/ens/cards/products/EnsProductsTable';
import EtdProductsTable from 'views/declarations/ireland/etd/products/EtdProductTable';
import UkProductsTable from 'views/declarations/uk/common/UkProductsTable';
import TsdProductsTable from 'views/declarations/ireland/tsd/products/TsdProductTable';
import useProductsTemplates from '../../../../hooks/useProductsTemplates';
import { handleAddDeclarationProductToProductTemplates } from '../../../custom-declaration/products/productTemplateUtils';
import IrelandImportH1DeclarationUtils from 'views/declarations/ireland/import/h1/utils';
import IrelandImportH7DeclarationUtils from 'views/declarations/ireland/import/h7/utils';
import CdsExportDeclarationUtils from 'views/declarations/uk/export/utils';

interface NavigationProductsTable {
    comingBackFromProductView?: boolean;
}

interface ProductTemplateFunctions {
    saveFunction: undefined | Function;
    listFunction: undefined | Function;
    transformFunction?: undefined | Function;
}

const DeclarationProductsTable: FC<{}> = () => {
    const {
        setSearchQuery,
        errors,
        data,
        declarationType,
        onDelete,
        onEdit,
        internalType,
        viewOnly,
        itemsNumber,
        handlePagination,
        pageNumber,
    } = useDeclarationProductsTable();
    const { declarationId } = useParams<{ declarationId: string }>();
    const { state } = useLocation();
    const comingBackFromProductView = (state as NavigationProductsTable)?.comingBackFromProductView;
    const { declaration } = useDeclarations({ declarationId: declarationId });
    const {
        products,
        listIrelandH1Products,
        listIrelandH7Products,
        listIrelandExportProducts,
        listIrelandEnsProducts,
        listUkImportProducts,
        listUkExportProducts,
        listIrelandEtdProducts,
        listIrelandTsdProducts,
    } = useProducts();
    const { ...allProductTemplateFunctions } = useProductsTemplates({});
    const navigate = useNavigate();

    const getAddDeclarationProductToProductTemplate = useCallback(
        (productId: string) => {
            const productTemplateFunctions: ProductTemplateFunctions = {
                saveFunction: undefined,
                listFunction: undefined,
                transformFunction: undefined,
            };

            if (declaration?.irelandImportDeclaration) {
                productTemplateFunctions.saveFunction = allProductTemplateFunctions.saveH1ProductTemplate;
                productTemplateFunctions.listFunction = allProductTemplateFunctions.listH1ProductsTemplates;
                productTemplateFunctions.transformFunction = IrelandImportH1DeclarationUtils.transformProductForServer;
            } else if (declaration?.irelandH7ImportDeclaration) {
                productTemplateFunctions.saveFunction = allProductTemplateFunctions.saveH7ProductTemplate;
                productTemplateFunctions.listFunction = allProductTemplateFunctions.listH7ProductsTemplates;
                productTemplateFunctions.transformFunction = IrelandImportH7DeclarationUtils.transformProductForServer;
            } else if (declaration?.cdsImportDeclaration) {
                productTemplateFunctions.saveFunction = allProductTemplateFunctions.saveUkProductTemplate;
                productTemplateFunctions.listFunction = allProductTemplateFunctions.listUkProductTemplates;
            } else if (declaration?.cdsExportDeclaration) {
                productTemplateFunctions.saveFunction = allProductTemplateFunctions.saveCdsExportProductTemplate;
                productTemplateFunctions.listFunction = allProductTemplateFunctions.listCdsExportProductsTemplates;
                productTemplateFunctions.transformFunction = CdsExportDeclarationUtils.transformProductForServer;
            } else if (declaration?.entrySummaryDeclaration) {
                productTemplateFunctions.saveFunction = allProductTemplateFunctions.saveEnsProductTemplate;
                productTemplateFunctions.listFunction = allProductTemplateFunctions.listEnsProductsTemplates;
            }

            return handleAddDeclarationProductToProductTemplates(
                productId,
                products,
                productTemplateFunctions.saveFunction,
                productTemplateFunctions.listFunction,
                declaration?.templateId,
                Boolean(declaration?.cdsExportDeclaration || declaration?.cdsImportDeclaration)
            );
        },
        [declaration, products, allProductTemplateFunctions]
    );

    useEffect(() => {
        const checkItems = async (declarationId: string, declaration: Declaration) => {
            let response;

            if (declaration?.irelandImportDeclaration) {
                response = await listIrelandH1Products(declarationId);
            } else if (declaration?.irelandH7ImportDeclaration) {
                response = await listIrelandH7Products(declarationId);
            } else if (declaration?.irelandExportDeclaration) {
                response = await listIrelandExportProducts(declarationId);
            } else if (declaration?.entrySummaryDeclaration) {
                response = await listIrelandEnsProducts(declarationId);
            } else if (declaration?.cdsImportDeclaration) {
                response = await listUkImportProducts(declarationId);
            } else if (declaration?.cdsExportDeclaration) {
                response = await listUkExportProducts(declarationId);
            } else if (declaration?.irelandETDDeclaration) {
                response = await listIrelandEtdProducts(declarationId);
            } else if (declaration?.irelandImportTemporaryStorageDeclaration) {
                response = await listIrelandTsdProducts(declarationId);
            }

            const size = response?.total ?? 0;
            const productId = response?.list?.[0]?.id;

            if (size === 0) {
                navigate(`/declarations/${declarationId}/products/new`, { replace: true });
            }
            if (size === 1 && productId) {
                navigate(
                    `/declarations/${declarationId}${
                        viewOnly ? `/view-only/products/${productId}` : `/products/${productId}`
                    }`,
                    {
                        replace: true,
                    }
                );
            }
        };

        if (declarationId && declaration?.id === declarationId && !comingBackFromProductView) {
            checkItems(declarationId, declaration);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, declaration, declarationId, comingBackFromProductView, viewOnly]);

    const container = useMemo(() => {
        if (declaration) {
            switch (declaration.declarationExternalEntity) {
                case DeclarationExternalEntity.REVENUE: {
                    return (
                        <>
                            {declaration.declarationInternalType !== DeclarationInternalType.ENS &&
                                declaration.declarationInternalType !== DeclarationInternalType.ETD &&
                                declaration.declarationInternalType !== DeclarationInternalType.TSD && (
                                    <IrelandProductsTable
                                        errors={errors}
                                        internalType={internalType}
                                        data={data}
                                        declarationType={declarationType}
                                        onDelete={onDelete}
                                        onEdit={onEdit}
                                        onAddToTemplate={getAddDeclarationProductToProductTemplate}
                                        totalItems={itemsNumber}
                                        viewOnly={viewOnly}
                                        handlePagination={handlePagination}
                                        pageNumber={pageNumber}
                                    />
                                )}
                            {declaration.declarationInternalType === DeclarationInternalType.ENS && (
                                <EnsProductsTable
                                    errors={errors}
                                    data={data as EnsGoodsShipmentItem[]}
                                    onDelete={onDelete}
                                    viewOnly={viewOnly}
                                    onEdit={onEdit}
                                    onAddToTemplate={getAddDeclarationProductToProductTemplate}
                                />
                            )}
                            {declaration.declarationInternalType === DeclarationInternalType.ETD && (
                                <EtdProductsTable
                                    errors={errors}
                                    data={data as EtdGoodsShipmentItem[]}
                                    onDelete={onDelete}
                                    onEdit={onEdit}
                                    onAddToTemplate={getAddDeclarationProductToProductTemplate}
                                    viewOnly={viewOnly}
                                />
                            )}

                            {declaration.declarationInternalType === DeclarationInternalType.TSD && (
                                <TsdProductsTable
                                    errors={errors}
                                    data={data as EtdGoodsShipmentItem[]}
                                    onDelete={onDelete}
                                    onEdit={onEdit}
                                    onAddToTemplate={getAddDeclarationProductToProductTemplate}
                                    viewOnly={viewOnly}
                                />
                            )}
                        </>
                    );
                }
                case DeclarationExternalEntity.CHIEF:
                    return (
                        <UkProductsTable
                            errors={errors}
                            internalType={internalType}
                            data={data as CdsExportGovernmentAgencyGoodsItem[]}
                            onDelete={onDelete}
                            onEdit={onEdit}
                            onAddToTemplate={getAddDeclarationProductToProductTemplate}
                            viewOnly={viewOnly}
                        />
                    );
                case DeclarationExternalEntity.CDS:
                    return (
                        <UkProductsTable
                            errors={errors}
                            internalType={internalType}
                            data={data as CdsExportGovernmentAgencyGoodsItem[]}
                            onDelete={onDelete}
                            onEdit={onEdit}
                            onAddToTemplate={getAddDeclarationProductToProductTemplate}
                            viewOnly={viewOnly}
                        />
                    );
                default:
                    <></>;
            }
        }
        return <></>;
    }, [
        getAddDeclarationProductToProductTemplate,
        data,
        declarationType,
        onDelete,
        onEdit,
        errors,
        declaration,
        internalType,
        viewOnly,
        pageNumber,
        handlePagination,
        itemsNumber,
    ]);

    return (
        <>
            <Row gutter={16} wrap={false} style={{ marginBottom: '2.5rem' }}>
                <Col flex="auto">
                    <SearchBar
                        onSearch={(value) => setSearchQuery(value)}
                        inputPlaceholder="Search by tag, commodity code and description of goods"
                    />
                </Col>
            </Row>

            {container}
        </>
    );
};
export default DeclarationProductsTable;

function useDeclarationProductsTable() {
    return useOutletContext<{
        setSearchQuery: (value: string) => void;
        data:
            | H1GoodsShipmentItem[]
            | H7GoodsShipmentItem[]
            | RevenueExportCustomsGoodsItem[]
            | EnsGoodsShipmentItem[]
            | GovernmentAgencyGoodsItem[]
            | EtdGoodsShipmentItem[]
            | CdsExportGovernmentAgencyGoodsItem[];
        internalType?: DeclarationInternalType;
        declarationType?: DeclarationName;
        onDelete?: (id: string) => void;
        onEdit: (id: string) => void;
        loading?: boolean;
        viewOnly?: boolean;
        errors?: boolean[];
        itemsNumber: number;
        handlePagination: (paginator: Partial<PaginatedParams>) => void;
        pageNumber: number;
    }>();
}
