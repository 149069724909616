import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'description';

export const descriptionOfGoodsBlockValidation: {
    childValidators: {
        commodity: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        commodity: {
            childValidators: {
                description: [validators.required(), validators.max(280)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const DescriptionOfGoodsBlock = ({ path = 'commodity', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '6/8', []);
    return (
        <DeclarationField pathPrefix={path} name="description">
            {({ form, field }: FieldProps<any>) => (
                <FormInput
                    refNumber={ucc}
                    required
                    label={t('goodsItems.goodsDescription')}
                    {...getFormikProps(field.name, form)}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default DescriptionOfGoodsBlock;
