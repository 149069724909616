import {
    AdditionalInfo,
    BorderTransportMeans,
    Consignment,
    Container,
    CustomOffice,
    EnsGoodsShipmentItem,
    GoodsShipment,
    IrelandEntrySummaryDeclaration,
    Issuer,
    Packaging,
    TransportEquipment,
} from 'store/declarations/ireland/entry-summary-declaration';
import {
    getCustomerValidation,
    getFloatValidation,
    getExactLengthValidation,
    getMaxValidation,
    getRequiredMessage,
    Shape,
    getNumberMaxMessage,
    getNumberOfItemsValidation,
} from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';

const IDENTITY_CROSSING_BORDER_REGEX = /^(IMO[0-9]{7}|ENI[0-9]{8})$/;
const identityCrossingBorderRegexMessage = 'Expected format is IMO followed by 7 digits or ENI followed by 8 digits.';

const COMBINED_NOMENCLATURE_REGEX = /^[0-9]{4,8}$/;
const combinedNomenclatureRegexMessage = 'Expected format is between 4 and 8 symbols.';

export const transportEquipment = Yup.object().shape<Shape<TransportEquipment>>({
    sealsIdentity: Yup.string().nullable(),
});

export const customOffice = Yup.object().shape<Shape<CustomOffice>>({
    referenceNumber: getExactLengthValidation('referenceNumber', 8)
        .required(getRequiredMessage('referenceNumber'))
        .nullable(),
    expectedDateTimeArrival: Yup.date().required(getRequiredMessage('expectedDateTimeArrival')).nullable(),
});

export const subsequentEntryCustomsOffice = () =>
    Yup.object().shape({
        referenceNumber: getExactLengthValidation('referenceNumber', 8)
            .required(getRequiredMessage('referenceNumber'))
            .nullable(),
    });

export const sealsIdentity = () =>
    Yup.object().shape({
        sealsIdentity: getMaxValidation('sealsIdentity', 20).required(getRequiredMessage('sealsIdentity')).nullable(),
    });

export const itinerary = () =>
    Yup.object().shape({
        countryOfRoutingCode: Yup.array().of(
            getExactLengthValidation('countryOfRoutingCode', 2)
                .required(getRequiredMessage('countryOfRoutingCode'))
                .nullable()
        ),
    });

export const borderTransport = () =>
    Yup.object().shape({
        nationalityCrossingBorder: getExactLengthValidation('nationalityCrossingBorder', 2).nullable(),
        identityCrossingBorder: Yup.string()
            .required(getRequiredMessage('transportMode'))
            .nullable()
            .test({
                name: 'transport-mode-relation',
                message: identityCrossingBorderRegexMessage,
                test: (value, context) => {
                    const transportMode = (context.options as any)?.from?.at(-1).value?.goodsShipment?.consignment
                        ?.borderTransportMeans?.transportMode;

                    if (transportMode === 1 || transportMode === 8) {
                        if (!value?.match(IDENTITY_CROSSING_BORDER_REGEX)) {
                            return false;
                        }
                    }

                    return true;
                },
            }),
    });

const borderTransportMeans = Yup.object().shape<Shape<BorderTransportMeans>>({
    identityCrossingBorder: Yup.string()
        .nullable()
        .when('transportMode', {
            is: (val: string) => val === '1' || val === '8',
            then: getMaxValidation('identityCrossingBorder', 11)
                .matches(IDENTITY_CROSSING_BORDER_REGEX, identityCrossingBorderRegexMessage)
                .nullable(),
        }),
    nationalityCrossingBorder: getExactLengthValidation('nationalityCrossingBorder', 2).nullable(),
    conveyanceReferenceNumber: getMaxValidation('conveyanceReferenceNumber', 35).nullable(),
    transportMode: getMaxValidation('conveyanceReferenceNumber', 2)
        .required(getRequiredMessage('transportMode'))
        .nullable(),
    itinerary: itinerary(),
});

export const productPackages = Yup.object().shape<Shape<Packaging>>({
    marks: getMaxValidation('marks', 140).nullable(),
    type: getExactLengthValidation('kindOfPackages', 2).required(getRequiredMessage('kindOfPackages')).nullable(),
    numberOfPackages: getMaxValidation('numberOfPackages', 5).nullable(),
    numberOfPieces: getMaxValidation('numberOfPieces', 5).nullable(),
});

export const issuer = Yup.object().shape<Shape<Issuer>>({
    place: Yup.string().max(35).required(getRequiredMessage('declarationIssuingPlace')).nullable(),
});

export const additionalDocument = Yup.object().shape<Shape<AdditionalInfo>>({
    code: getMaxValidation('additionalDocumentIdentifier', 4)
        .required(getRequiredMessage('additionalDocumentIdentifier'))
        .nullable(),
    identifier: getMaxValidation('additionalDocumentText', 255).nullable(),
});

export const additionalInformation = Yup.object().shape<Shape<AdditionalInfo>>({
    code: getMaxValidation('additionalInformationText', 17)
        .required(getRequiredMessage('additionalInformationIdentifier'))
        .nullable(),
    identifier: getMaxValidation('additionalInformationText', 255).nullable(),
});

export const containers = Yup.object().shape<Shape<Container>>({
    equipmentIdentification: getMaxValidation('containerNumber', 11)
        .required(getRequiredMessage('containerNumber'))
        .nullable(),
});

export const goodsItems = Yup.object().shape<Shape<EnsGoodsShipmentItem>>({
    ucr: getMaxValidation('ucr', 35).nullable(),
    transportChargesMethodOfPayment: getExactLengthValidation('transportChargesMethodOfPayment', 1).nullable(),
    itemNumber: getMaxValidation('itemNumber', 3).required(getRequiredMessage('itemNumber')).nullable(),
    loadingPlace: getMaxValidation('loadingPlace', 35).nullable(),
    unloadingPlace: getMaxValidation('unloadingPlace', 35).nullable(),
    grossMass: getFloatValidation('grossMass', 8, 3).nullable(),
    commodity: Yup.object().shape({
        description: getMaxValidation('description', 280).nullable(),
        combinedNomenclature: Yup.string()
            .matches(COMBINED_NOMENCLATURE_REGEX, combinedNomenclatureRegexMessage)
            .required(getRequiredMessage('combinedNomenclature'))
            .nullable(),
        dangerousCode: getExactLengthValidation('dangerousCode', 4).nullable(),
    }),
    borderTransportMeans: Yup.array().of(borderTransport()),
    consignor: getCustomerValidation({ name: { max: 35 } }).nullable(),
    consignee: getCustomerValidation({ name: { max: 35 } }).nullable(),
    notifyParty: getCustomerValidation({ name: { max: 35 } }).nullable(),
});

export const consignment = Yup.object().shape<Shape<Consignment>>({
    borderTransportMeans: borderTransportMeans,
    inlandModeOfTransport: Yup.string().nullable(),
    locationOfGoods: Yup.string().nullable(),
    loadingPlace: getMaxValidation('loadingPlace', 35).nullable(),
    unloadingPlace: getMaxValidation('unloadingPlace', 35).nullable(),
});

const goodsShipment = Yup.object().shape<Shape<GoodsShipment>>({
    ucr: getMaxValidation('ucr', 35).nullable(),
    firstEntryCustomsOffice: customOffice.required('Custom office of first entry is required'),
    consignment: consignment,
    consignor: getCustomerValidation({ name: { max: 35 } }).nullable(),
    consignee: getCustomerValidation({ name: { max: 35 } }).nullable(),
    notifyParty: getCustomerValidation({ name: { max: 35 } }).nullable(),
    transportMethodOfPaymentCode: getExactLengthValidation('transportChargesMethodOfPayment', 1).nullable(),
    goodsItems: Yup.array().of(goodsItems).required('The declaration needs to contain at least one item.'),
});

const irelandEnsValidation = (numberOfItems?: number) =>
    Yup.object().shape<Shape<IrelandEntrySummaryDeclaration>>({
        issuing: issuer,
        numberOfItems: getNumberOfItemsValidation(numberOfItems)
            .required(getRequiredMessage('numberOfItems'))
            .max(3, getNumberMaxMessage('numberOfItems', 3))
            .nullable(),
        totalNumberOfPackages: getMaxValidation('totalNumberOfPackages', 7).nullable(),
        totalGrossMass: getMaxValidation('totalGrossMass', 11).nullable(),
        specificCircumstanceIndicator: getExactLengthValidation('specificCircumstanceIndicator', 1).nullable(),
        agent: getCustomerValidation({ eori: { required: true }, name: { max: 35 } }).required(
            getRequiredMessage('agent')
        ),
        carrier: getCustomerValidation({ name: { max: 35 } }).nullable(),
        // Representative
        personLodgingTheSummaryDeclaration: getCustomerValidation({ name: { max: 35 } }).nullable(),
        goodsShipment: goodsShipment,
    });

export default irelandEnsValidation;
