import { FC, useMemo } from 'react';
import DashboardSetupHeading from 'components/ui/composed/dashboard/DashboardSetupHeading';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface Props {
    handleCreateWeighbridge: () => void;
    handleOrderDevice: () => void;
    handleCalibrateDevice: () => void;
    disabledOrderDevice?: boolean;
    disabledCalibrateDevice?: boolean;
}

interface AfterWCLocationState {
    finishedSetupProcess?: boolean;
    payed?: boolean;
}

const AfterWC: FC<Props> = ({
    handleCreateWeighbridge,
    handleOrderDevice,
    handleCalibrateDevice,
    disabledOrderDevice,
    disabledCalibrateDevice,
}) => {
    const { t } = useTranslation('weighbridge');

    const location = useLocation();
    const locationState = useMemo(() => location.state as AfterWCLocationState | null | undefined, [location.state]);

    if (locationState?.finishedSetupProcess) {
        return <DashboardSetupHeading textAboveButtons={t('afterWC.textAboveButtonsFinishedSetup')} />;
    }

    if (locationState?.payed) {
        return (
            <DashboardSetupHeading
                textAboveButtons={t('afterWC.textAboveButtonsPayed')}
                buttons={[
                    {
                        key: 'afterWC-payed-calibrateDevice-button',
                        children: t('afterWC.textButton3'),
                        onClick: handleCalibrateDevice,
                        disabled: disabledCalibrateDevice,
                        title: t('weighbridgeDashboard.calibrateDevice.title'),
                    },
                ]}
                textBellowButtons={t('afterWC.textBellowButtonsPayed')}
            />
        );
    }

    return (
        <DashboardSetupHeading
            textAboveButtons={t('afterWC.textAboveButtons')}
            buttons={[
                {
                    key: 'afterWC-createWeighbridge-button',
                    children: t('afterWC.textButton1'),
                    onClick: handleCreateWeighbridge,
                },
                {
                    key: 'afterWC-orderDevice-button',
                    children: t('afterWC.textButton2'),
                    onClick: handleOrderDevice,
                    disabled: disabledOrderDevice,
                    title: t('weighbridgeDashboard.orderDevice.title'),
                },
                {
                    key: 'afterWC-calibrateDevice-button',
                    children: t('afterWC.textButton3'),
                    onClick: handleCalibrateDevice,
                    disabled: disabledCalibrateDevice,
                    title: t('weighbridgeDashboard.calibrateDevice.title'),
                },
            ]}
            textBellowButtons={t('afterWC.textBellowButtons')}
        />
    );
};

export default AfterWC;
