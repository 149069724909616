import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { additionalDeclarationTypesLabels } from 'store/declarations/enums/common/additional-declaration-type';
import { declarationTypeLabels } from 'store/declarations/enums/common/declaration-type';
import { IrelandExportMessageType } from 'store/declarations/enums/ireland/message-type';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';

const MasterDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getIrelandExportTooltipsByRefNumber, getIrelandExportTooltipsByRefNumberAndField } = useTooltips();

    const handleToggleHelp = (refNumber: string | number): void => {
        props.toggleHelp && props.toggleHelp(refNumber);
    };

    return (
        <section
            id={'master-details-form-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('master-details-form-card');
                }
            }}
        >
            <FormCard
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('masterDetails')}
                cardNumber={props.cardNumber}
                expandAll={props.expandAll}
                keyCard={props.keyCard}
                total={props.cardTotal}
                state={getCardState(
                    [
                        { name: 'messageType', required: true },
                        { name: `exportationCode`, required: true },
                        { name: `declarationType`, required: true },
                        { name: `numberOfItems`, required: true },
                        { name: `representativeStatus`, required: true },
                        { name: `representativeEori`, required: true },
                        { name: `sealsQuantity`, required: true },
                        { name: `sealsIdentity`, required: true },
                        { name: `specificCircumstanceIndicator`, required: false },
                        { name: `referenceNumber`, required: false },
                        { name: `officeOfExport`, required: true },
                        { name: `transportChargesMethodOfPayment`, required: false },
                    ],
                    props
                )}
            >
                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`messageType`, props)}
                    label={t('messageType')}
                    refClicked={handleToggleHelp}
                    selectOptions={Object.entries(IrelandExportMessageType).map(([key, value]) => {
                        return {
                            id: key,
                            value: value,
                        };
                    })}
                />
                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`exportationCode`, props)}
                    refNumber="1.1"
                    label={t('exportationCode')}
                    tooltip={getIrelandExportTooltipsByRefNumber('1.1')}
                    refClicked={handleToggleHelp}
                    selectOptions={declarationTypeLabels}
                />
                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`declarationType`, props)}
                    tooltip={getIrelandExportTooltipsByRefNumber('1.2')}
                    refClicked={handleToggleHelp}
                    refNumber="1.2"
                    label={t('declarationType')}
                    selectOptions={additionalDeclarationTypesLabels}
                />

                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`representativeStatus`, props)}
                    label={t('representativeStatus')}
                    refNumber="14.1"
                    tooltip={getIrelandExportTooltipsByRefNumber('14.1')}
                    refClicked={handleToggleHelp}
                    selectOptions={normalizeCodesToSelect(codelists?.irelandRepresentativeStatusCodes || [])}
                />

                <DeclarationInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`representativeEori`, props)}
                    label={t('representativeEori')}
                    refNumber="14.2"
                    tooltip={getIrelandExportTooltipsByRefNumber('14.2')}
                    refClicked={handleToggleHelp}
                    maxLength={17}
                />

                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`sealsQuantity`, props)}
                    refNumber="D"
                    tooltip={getIrelandExportTooltipsByRefNumber('D')}
                    refClicked={handleToggleHelp}
                    maxLength={3}
                    label={t('sealsQuantity')}
                />

                <DeclarationInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`sealsIdentity`, props)}
                    label={t('sealsIdentity')}
                    refNumber="D"
                    tooltip={getIrelandExportTooltipsByRefNumber('D')}
                    refClicked={handleToggleHelp}
                    maxLength={20}
                />

                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`specificCircumstanceIndicator`, props)}
                    label={t('specificCircumstanceIndicator')}
                    refNumber="A"
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('A', 'Specific Circumstance Indicator')}
                    refClicked={handleToggleHelp}
                    selectOptions={[
                        {
                            id: 'A',
                            value: 'Postal and express consignments',
                        },
                        {
                            id: 'B',
                            value: 'Postal and express consignments',
                        },
                        {
                            id: 'E',
                            value: 'Authorised economic operators',
                        },
                    ]}
                />

                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`transportChargesMethodOfPayment`, props)}
                    label={t('transportChargesMethodOfPayment')}
                    refNumber="A"
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('A', 'Transport Charges - Method of Payment')}
                    refClicked={handleToggleHelp}
                    selectOptions={[
                        {
                            id: 'A',
                            value: 'Payment in cash',
                        },
                        {
                            id: 'B',
                            value: 'Payment by credit card',
                        },
                        {
                            id: 'C',
                            value: 'Payment by cheque',
                        },
                        {
                            id: 'D',
                            value: 'Other (e.g. direct debit to cash account)',
                        },
                        {
                            id: 'H',
                            value: 'Electronic credit transfer',
                        },
                        {
                            id: 'Y',
                            value: 'Account holder with carrier',
                        },
                        {
                            id: 'Z',
                            value: 'Not pre-paid',
                        },
                    ]}
                />

                <DeclarationInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`referenceNumber`, props)}
                    label={t('referenceNumber')}
                    tooltip={getIrelandExportTooltipsByRefNumber('7')}
                    maxLength={35}
                />

                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`numberOfItems`, props)}
                    refNumber="5"
                    tooltip={getIrelandExportTooltipsByRefNumber('5')}
                    label={t('numberOfItems')}
                    refClicked={handleToggleHelp}
                />

                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`officeOfExport`, props)}
                    label={t('officeOfExport')}
                    refNumber="A"
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('A', 'Office of Export')}
                    refClicked={handleToggleHelp}
                    selectOptions={normalizeCodesToSelect(codelists?.irelandOfficesOfExit || [])}
                />
            </FormCard>
        </section>
    );
};

export default MasterDetailsCard;
