import { Space } from 'antd';
import { AGroup, ARadio } from 'components/ui/base/radio/Radio';
import styled from 'styled-components';
import { colors, fonts } from 'theme';

export const StyledRoleAdmin = styled.span`
    padding: 0 1.6rem;
    background: ${colors.purpleBlue};
    border: 1px solid ${colors.purpleBlue};
    box-sizing: border-box;
    border-radius: 4px;
    color: ${colors.white};
    font-style: normal;
    font-weight: ${fonts.weight.semibold};
    font-size: 1.2rem;
    line-height: 2.2rem;
`;

export const StyledRole = styled.span`
    padding: 0 1.6rem;
    background: ${colors.lightBlue};
    border: 1px solid ${colors.lightBlue};
    box-sizing: border-box;
    border-radius: 4px;
    color: ${colors.white};
    font-family: Open Sans;
    font-style: normal;
    font-weight: ${fonts.weight.semibold};
    font-size: 12px;
    line-height: 22px;
`;

export const StyledOpenIcon = styled.span`
    margin-left: 0.4rem;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid ${colors.darkPurple};
`;

export const SelectOptions = styled.div`
    z-index: 4;
    margin-top: 0.5rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    min-width: 21.7rem;
    min-height: 10.4rem;
    background: ${colors.white};
    box-shadow: 0 2px 7px ${colors.purpleBlueA15};
    border-radius: 4px;
`;

export const StyledRadio = styled(ARadio)<{ selected: boolean }>`
    padding: 0.8rem 0.8rem;
    width: 100%;
    background-color: ${({ selected }) => (selected ? colors.purpleBlue : colors.white)};
    color: ${({ selected }) => (selected ? colors.white : colors.darkPurple)};
    border-radius: 4px;
    &:hover {
        background: ${colors.lightPurple1};
        color: ${colors.darkPurple};
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: ${colors.purpleBlue};
    }
    .ant-radio-inner::after {
        background-color: ${colors.purpleBlue};
    }
`;

export const StyledSpace = styled(Space)`
    width: 100%;
`;

export const StyledGroup = styled(AGroup)`
    width: 100%;
`;
