import { Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState } from 'views/declarations/utils/form-utils';
import ExchangeRateBlock from '../blocks/ExchangeRateBlock';

const ExchangeRateCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <FormCard
            hide={props.hide}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            title={t('exchangeRate')}
            cardNumber={props.cardNumber}
            total={props.cardTotal}
            state={getCardState([{ name: 'currencyExchange.rateNumeric', required: false }], props)}
        >
            <Row gutter={18}>
                <ExchangeRateBlock />
            </Row>
        </FormCard>
    );
};
export default ExchangeRateCard;
