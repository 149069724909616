import { NavItem } from 'views/declarations/common/nav-item';

export const sectionsEtdMainDocument: NavItem[] = [
    { title: 'Goods Information', href: 'etd-goods-information-form-card' },
    { title: 'Custom Office', href: 'person-lodging-summary-declaration-card' },
    { title: 'Representative', href: 'representative-form-card' },
    { title: 'Holder Of The Transit Procedure', href: 'holder-of-the-transit-procedure-form-card' },
    { title: 'Consignee', href: 'etd-consignee-form-card' },
    { title: 'Supply Chain Actor', href: 'etd-supply-chain-actor-form-card' },
    { title: 'Previous Document Information', href: 'etd-simplified-document-writing-off-form-card' },
    { title: 'Produced Documents Writing Off Header', href: 'etd-produced-documents-writing-off-form-card' },
    { title: 'Additional Information', href: 'etd-additional-information-form-card' },
    { title: 'Transport Information', href: 'etd-transport-information-form-card' },
    { title: 'Places', href: 'etd-goods-information-form-card' },
];

export const sectionsEtdProducts: NavItem[] = [
    { title: 'Goods Information', href: 'etd-goods-shipment-item-form-card' },
    { title: 'Previous Document Information', href: 'etd-simplified-document-writing-off-form-card' },
    { title: 'Produced Documents Writing Off Header', href: 'etd-produced-documents-writing-off-form-card' },
    { title: 'Additional Information', href: 'etd-additional-information-form-card' },
    { title: 'Consignee', href: 'etd-consignee-form-card' },
    { title: 'Packaging', href: 'etd-packaging-card-form-card' },
    { title: 'Transport Information', href: 'etd-transport-information-form-card' },
];
