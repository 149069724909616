import { SearchOutlined } from '@ant-design/icons';
import Drawer from 'components/ui/base/drawer/Drawer';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormCheckbox from 'components/ui/composed/formCheckbox/FormCheckbox';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import useDeclarations from 'hooks/useDeclarations';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Declaration, DeclarationFilterFields } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import DeclarationTable, { DeclarationTableType } from 'views/custom-declaration/declaration-table/DeclarationsTable';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';
const MasterDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const [searchDeclarationDrawer, setSearchDeclarationDrawer] = useState(false);
    const { declarations, listDeclarations, isLoading } = useDeclarations();
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>();

    const handlePagination = (params: Partial<PaginatedParams>) => {
        if (paginator && paginator.page && paginator.size) {
            setPaginator({ page: params.page, size: paginator.size });
        } else {
            setPaginator({ page: params.page, size: defaultPagination.size });
        }
    };

    useEffect(() => {
        if (searchDeclarationDrawer) {
            let params: DeclarationFilterFields = {
                ...defaultPagination,
                declarationInternalType: [DeclarationInternalType.EXPORT],
                declarationExternalEntity: [DeclarationExternalEntity.REVENUE],
            };
            listDeclarations(params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchDeclarationDrawer]);

    useEffect(() => {
        if (paginator) {
            const params: DeclarationFilterFields = {
                ...defaultPagination,
                declarationInternalType: [DeclarationInternalType.EXPORT],
                declarationExternalEntity: [DeclarationExternalEntity.REVENUE],
            };
            const p = { ...params, ...paginator };
            listDeclarations(p);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator]);

    const handleSort = (params: Partial<PaginatedParams>) => {
        setPaginator({ ...paginator, ...params });
    };

    return (
        <section>
            <FormCard
                title={t('masterDetails')}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                expandAll={props.expandAll}
                keyCard={props.keyCard}
                action={
                    !props.viewOnly
                        ? {
                              title: 'Search Export Declaration',
                              onClick: () => setSearchDeclarationDrawer(true),
                              icon: <SearchOutlined />,
                          }
                        : undefined
                }
                state={getCardState([], props)}
            >
                <FormInput
                    maxLength={22}
                    {...getFormikProps(`mrn`, props)}
                    label={t('remissionRefund.mrn')}
                    tooltip={t('remissionRefund.mrn')}
                />
                <FormInput
                    {...getFormikProps(`goodsShipment.exitCustomsOffice`, props)}
                    label={t('arrivalAtExit.exitCustomsOffice')}
                    tooltip={t('arrivalAtExit.exitCustomsOffice')}
                />
                <FormInput
                    {...getFormikProps(`goodsShipment.warehouseIdentification`, props)}
                    label={t('arrivalAtExit.warehouseIdentification')}
                    tooltip={t('arrivalAtExit.warehouseIdentification')}
                />
                <FormCheckbox
                    {...getFormikProps(`goodsShipment.storingFlag`, props)}
                    label={t('arrivalAtExit.storingFlag')}
                    tooltip={t('arrivalAtExit.storingFlag')}
                />
            </FormCard>
            <Drawer
                title="Search Declaration"
                width="1098"
                visible={searchDeclarationDrawer}
                onClose={() => {
                    setSearchDeclarationDrawer(false);
                }}
            >
                {declarations && (
                    <DeclarationTable
                        data={declarations}
                        handlePagination={handlePagination}
                        loading={isLoading}
                        handleSort={handleSort}
                        type={DeclarationTableType.SELECTION}
                        onSelect={(record: Declaration) => {
                            if (record.mrn) {
                                getFormikProps(`mrn`, props)?.fieldHelper?.setValue(record.mrn);
                            }
                            setSearchDeclarationDrawer(false);
                        }}
                    />
                )}
            </Drawer>
        </section>
    );
};

export default MasterDetailsCard;
