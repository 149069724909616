import Button from 'components/ui/base/button';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { useFormik } from 'formik';
import useCodelists from 'hooks/useCodelists';
import useModalMultipleCards from 'hooks/useModalMultipleCards';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { Guarantee } from 'store/declarations/ireland/import-declaration';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import CardList from 'views/declarations/common/list-card/CardList';
import ModalEditCardListItem from 'views/declarations/common/modal-edit-list-item/ModalEditCardListItem';
import { handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import { guaranteeSchema } from '../validation/ValidationSchema';
import GuaranteeReferenceCard from './GuaranteeReferenceCard';

const GuaranteeCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();
    const { viewOnly } = props;
    const { template } = useTemplateContext();

    const formik = useFormik<Guarantee>({
        initialValues: {},
        validationSchema: guaranteeSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            addItem(values);
        },
    });

    const { setValues, getFieldProps, getFieldHelpers, getFieldMeta, resetForm } = formik;

    const formikProps = useMemo(() => {
        return {
            fieldProps: props.getFieldProps(`guarantee`),
            fieldMeta: props.getFieldMeta && props.getFieldMeta('guarantee'),
            fieldHelper: props.getFieldHelpers('guarantee'),
        };
    }, [props]);

    const { modalVisible, handleEdit, handleDelete, handleCancel, onClickAddItem, addItem } = useModalMultipleCards({
        formikProps,
        setValues,
        resetForm,
    });

    const dataList = useMemo(() => {
        const list = props.getFieldProps(`guarantee`).value;
        if (Array.isArray(list)) {
            return list.map((obj: Guarantee, index) => {
                return [
                    { field: t('type'), value: obj.guaranteeType },
                    { field: 'Total References', value: obj.guaranteeReference?.length.toString() ?? '0' },
                ];
            });
        }
        return [];
    }, [props, t]);

    return (
        <section
            id={`guarantee-form-card`}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('guarantee-form-card');
                }
            }}
            data-testid="guarantee-form-card"
        >
            <NewFormCard title={t('guarantee')} refNumber="8.2">
                <FormCardContainer>
                    <div>
                        <CardList
                            data={dataList}
                            onDelete={handleDelete}
                            onEdit={handleEdit}
                            viewOnly={viewOnly ?? false}
                        />
                        {!viewOnly && !template && (
                            <Button
                                id="ireland-h1-import-add-item-guarantee-master-details"
                                data-testid="ireland-h1-import-add-item-guarantee-master-details"
                                onClick={onClickAddItem}
                            >
                                Add item
                            </Button>
                        )}
                    </div>
                </FormCardContainer>
                <ModalEditCardListItem
                    visible={modalVisible}
                    onCancel={handleCancel}
                    width={1020}
                    formik={formik}
                    testId="add-item-guarantee-card-modal"
                >
                    <FormSelect
                        viewOnly={props.viewOnly}
                        fieldMeta={getFieldMeta('guaranteeType')}
                        fieldProps={getFieldProps('guaranteeType')}
                        fieldHelper={getFieldHelpers('guaranteeType')}
                        refNumber="8.2"
                        label={t('type')}
                        testId="guaranteeType"
                        tooltip={getH1TooltipsByRefNumberAndField('8.2', t('type'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        selectOptions={normalizeCodesToSelect(codelists?.irelandGuaranteeTypes || [])}
                    />
                    <GuaranteeReferenceCard
                        key={`guarantee-reference`}
                        keyCard={`guarantee-reference`}
                        toggleHelp={(ref) => handleToggleHelp(ref, props)}
                        propsPathPrefix={`guaranteeReference`}
                        viewOnly={props.viewOnly}
                        getFieldProps={getFieldProps}
                        getFieldMeta={getFieldMeta}
                        getFieldHelpers={getFieldHelpers}
                        testId="guarantee-reference"
                    />
                </ModalEditCardListItem>
            </NewFormCard>
        </section>
    );
};
export default GuaranteeCard;
