import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import SearchBar from 'components/ui/base/searchbar';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import { FC, useState } from 'react';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { ProductTemplate } from 'store/products-templates/products';
import { UkProductTemplate } from 'store/products-templates/ukProducts';
import ProductsTable, { Product } from 'views/custom-declaration/products/components/ProductsTable';
import useProducts from 'hooks/useProductsTemplates';
import debounce from 'lodash.debounce';
import useDeclarations from 'hooks/useDeclarations';
import { ProductTabKeys } from '../../custom-declaration/products/ListProductTemplates';

interface Props {
    products: ListPayload<ProductTemplate> | ListPayload<UkProductTemplate>;
    refresh: Function;
    handlePagination: (paginator: Partial<PaginatedParams>) => void;
    handleSort?: (paginator: Partial<PaginatedParams>) => void;
    addProduct?: boolean;
    declarationType?: DeclarationName;
    addProductsToDeclaration?: Function;
    country: DeclarationCountry;
    internalEntity?: string;
    productTemplateType?: ProductTabKeys;
}

const DeclarationsProductsTemplates: FC<Props> = ({
    products,
    refresh,
    handlePagination,
    handleSort,
    addProduct,
    country,
    addProductsToDeclaration,
    internalEntity,
    productTemplateType,
}) => {
    const [newProducts, setNewProducts] = useState<Product[]>([]);
    const { declarationTemplate } = useDeclarations();
    const { listProducts } = useProducts({
        country,
        type: productTemplateType,
    });
    const handleAddToDeclaration = (products: Product[]) => {
        setNewProducts(products);
    };

    const querySearch = (query: string) => {
        const params = query ? { query } : {};

        listProducts({
            ...params,
            declarationTemplateId: declarationTemplate?.id ?? 'default',
        });
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    return (
        <div style={{ paddingRight: '0.8rem' }}>
            {addProduct && addProductsToDeclaration && (
                <Row justify="end" style={{ marginBottom: '2.5rem' }} wrap={false}>
                    <Button
                        disabled={!newProducts || newProducts.length < 1}
                        onClick={() => {
                            addProductsToDeclaration(newProducts);
                            setNewProducts([]);
                        }}
                    >
                        Add to declaration
                    </Button>
                </Row>
            )}
            <Row gutter={16} style={{ marginBottom: '2.5rem' }}>
                <Col span={24}>
                    <SearchBar
                        inputPlaceholder="Search products"
                        onClear={() =>
                            listProducts({
                                declarationTemplateId: declarationTemplate?.id ?? 'default',
                            })
                        }
                        onSearch={(value) => debouncedSearch(value)}
                    />
                </Col>
            </Row>
            <ProductsTable
                internalType={internalEntity}
                country={country}
                commands={false}
                source={products}
                refresh={() => {
                    refresh();
                }}
                // declarationTemplatesList is used to evaluate the declaration template names in the table
                declarationTemplatesList={declarationTemplate ? [declarationTemplate] : []}
                handlePagination={handlePagination}
                handleSort={handleSort}
                handleAddToDeclaration={handleAddToDeclaration}
                addToDeclaration
                addProductsToDeclaration={addProductsToDeclaration}
            />
        </div>
    );
};
export default DeclarationsProductsTemplates;
