import { defaultPagination } from 'core/http/pagination';
import { useCallback, useEffect } from 'react';
import { DeclarationFilterFields, NotifyEmails } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import {
    ElectronicTransportDocument,
    ElectronicTransportDocumentPayload,
} from 'store/declarations/ireland/electronic-transport-document';
import {
    IrelandTemporaryStorageDeclaration,
    IrelandTemporaryStorageDeclarationPayload,
} from 'store/declarations/ireland/temporary-storage-declaration';
import {
    IrelandEntrySummaryDeclaration,
    IrelandEntrySummaryDeclarationPayload,
} from 'store/declarations/ireland/entry-summary-declaration';
import {
    IrelandExportDeclaration,
    IrelandExportDeclarationPayload,
} from 'store/declarations/ireland/export-declaration';
import {
    IrelandH7ImportDeclaration,
    IrelandH7ImportDeclarationPayload,
} from 'store/declarations/ireland/h7-import-declaration';
import {
    IrelandImportDeclaration,
    IrelandImportDeclarationPayload,
} from 'store/declarations/ireland/import-declaration';
import { CdsExportDeclaration, CdsExportDeclarationPayload } from 'store/declarations/uk/export-declaration';
import { CdsImportDeclaration, CdsImportDeclarationPayload } from 'store/declarations/uk/import-declaration';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import {
    doClearDeclarationList,
    doCreateAmendmentIrelandEntrySummaryDeclaration,
    doCreateAmendmentIrelandExportDeclaration,
    doCreateAmendmentIrelandImportDeclaration,
    doCreateArrivalAtExitDeclaration,
    doCreateElectronicTransportDocument,
    doCreateEntrySummaryDeclaration,
    doCreateTemporaryStorageDeclaration,
    doCreateIrelandExportDeclaration,
    doCreateIrelandH7ImportDeclaration,
    doCreateIrelandImportDeclaration,
    doCreateUkExportDeclaration,
    doCreateUkImportDeclaration,
    doDuplicateDeclaration,
    doGetDeclaration,
    doGetDeclarationDocuments,
    doGetDeclarationHistory,
    doInvalidateDeclaration,
    doInvalidateExportDeclaration,
    doListDeclarations,
    doSubmitDeclaration,
    doSubmitIrelandImportDocuments,
    doSubmitImportPresentationNotification,
    doUpdateArrivalAtExitDeclaration,
    doUpdateCoreTemplateStatus,
    doUpdateElectronicTransportDocument,
    doUpdateEntrySummaryDeclaration,
    doUpdateIrelandExportDeclaration,
    doUpdateIrelandH7ImportDeclaration,
    doUpdateIrelandImportDeclaration,
    doUpdateUkExportDeclaration,
    doUpdateUkImportDeclaration,
    doUpdateTemporaryStorageDeclaration,
    doSubmitEntrySummaryDeclaration,
    doCreateIrelandH1ImportDeclarationWithFile,
    doGetDeclarationTemplate,
} from '../store/declarations/actions';
import useResponseNotification from 'hooks/useResponseNotification';
import { ArrivalAtExitDeclaration } from 'store/declarations/ireland/arrival-at-exit-declaration';
import { useTranslation } from 'react-i18next';
import { submitDeclarationNotifs, updateDeclarationNotifs } from '../utils/notificationMessages';

interface UseDeclarationsProps {
    declarationId?: string;
    customerId?: string;
    forceFetchDeclaration?: boolean;
}

export type UpdateDeclarationFunction = (customerId: string, declarationId: string, declaration: any) => Promise<any>;

const useDeclarations = ({ declarationId, forceFetchDeclaration = false }: UseDeclarationsProps = {}) => {
    const declaration = useAppSelector((state) => state.declarations.declaration);
    const declarationTemplate = useAppSelector((state) => state.declarations.declarationTemplate);
    const declarations = useAppSelector((state) => state.declarations.declarations);
    const isLoading = useAppSelector((state) => state.declarations.isLoading);
    const error = useAppSelector((state) => state.declarations.error);
    const documents = useAppSelector((state) => state.declarations.documents);
    const declarationHistory = useAppSelector((state) => state.declarations.declarationHistory);
    const { withResponseNotifications } = useResponseNotification();
    const { t } = useTranslation('common');

    const dispatch = useAppDispatch();

    const getDeclaration = useCallback(
        (declarationId: string) => {
            return dispatch(doGetDeclaration(declarationId));
        },
        [dispatch]
    );
    const getDeclarationTemplate = useCallback(
        (templateId?: string) => {
            return dispatch(doGetDeclarationTemplate(templateId ?? declaration?.templateId));
        },
        [declaration?.templateId, dispatch]
    );

    const listDeclarations = useCallback(
        (params?: Partial<DeclarationFilterFields>) => {
            return dispatch(doListDeclarations(params));
        },
        [dispatch]
    );

    const createIrelandImportDeclaration = useCallback(
        (
            customerId: string,
            irelandImportDeclaration?: IrelandImportDeclaration,
            jobId?: string,
            templateId?: string
        ) =>
            dispatch(
                doCreateIrelandImportDeclaration(
                    customerId,
                    {
                        jobId,
                        declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                        declarationInternalType: DeclarationInternalType.IMPORT,
                        irelandImportDeclaration: irelandImportDeclaration,
                    },
                    templateId
                )
            ),
        [dispatch]
    );

    const createIrelandH1ImportDeclarationWithFile = useCallback(
        (file: File, jobId: string, customerId: string): Promise<IrelandImportDeclarationPayload> | undefined =>
            dispatch(doCreateIrelandH1ImportDeclarationWithFile(file, jobId, customerId)),
        [dispatch]
    );

    const createIrelandH7ImportDeclaration = useCallback(
        (
            customerId: string,
            irelandH7ImportDeclaration?: IrelandH7ImportDeclaration,
            jobId?: string,
            templateId?: string
        ) =>
            dispatch(
                doCreateIrelandH7ImportDeclaration(
                    customerId,
                    {
                        jobId,
                        declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                        declarationInternalType: DeclarationInternalType.IMPORT,
                        irelandH7ImportDeclaration: irelandH7ImportDeclaration,
                    },
                    templateId
                )
            ),
        [dispatch]
    );

    const createIrelandExportDeclaration = useCallback(
        (customerId: string, irelandExportDeclaration?: IrelandExportDeclaration, jobId?: string) =>
            dispatch(
                doCreateIrelandExportDeclaration(customerId, {
                    jobId,
                    declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                    declarationInternalType: DeclarationInternalType.EXPORT,
                    irelandExportDeclaration: irelandExportDeclaration,
                })
            ),
        [dispatch]
    );

    const createEntrySummaryDeclaration = useCallback(
        (customerId: string, declaration?: IrelandEntrySummaryDeclaration, jobId?: string, templateId?: string) =>
            dispatch(
                doCreateEntrySummaryDeclaration(
                    customerId,
                    {
                        jobId,
                        declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                        declarationInternalType: DeclarationInternalType.ENS,
                        entrySummaryDeclaration: declaration,
                    },
                    templateId
                )
            ),
        [dispatch]
    );

    const createTemporaryStorageDeclaration = useCallback(
        (customerId: string, declaration?: IrelandTemporaryStorageDeclaration, jobId?: string) =>
            dispatch(
                doCreateTemporaryStorageDeclaration(customerId, {
                    jobId,
                    declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                    declarationInternalType: DeclarationInternalType.TSD,
                    temporaryStorageDeclaration: declaration,
                })
            ),
        [dispatch]
    );

    const updateTemporaryStorageDeclaration = useCallback(
        async (_: string, declarationId: string, declaration: IrelandTemporaryStorageDeclarationPayload) =>
            withResponseNotifications(
                await dispatch(doUpdateTemporaryStorageDeclaration(declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const createUkImportDeclaration = useCallback(
        (customerId: string, cdsImportDeclaration?: CdsImportDeclaration, jobId?: string) =>
            dispatch(
                doCreateUkImportDeclaration(customerId, {
                    jobId,
                    declarationExternalEntity: DeclarationExternalEntity.CDS,
                    declarationInternalType: DeclarationInternalType.IMPORT,
                    cdsImportDeclaration,
                })
            ),
        [dispatch]
    );

    const createUkImportNewDeclaration = useCallback(
        (customerId: string, cdsExportDeclaration?: CdsExportDeclaration, jobId?: string) =>
            dispatch(
                doCreateUkExportDeclaration(customerId, {
                    jobId,
                    declarationExternalEntity: DeclarationExternalEntity.CDS,
                    declarationInternalType: DeclarationInternalType.IMPORT_NEW,
                    cdsExportDeclaration,
                })
            ),
        [dispatch]
    );

    const createUkExportDeclaration = useCallback(
        (customerId: string, cdsExportDeclaration?: CdsExportDeclaration, jobId?: string, templateId?: string) =>
            dispatch(
                doCreateUkExportDeclaration(
                    customerId,
                    {
                        jobId,
                        declarationExternalEntity: DeclarationExternalEntity.CDS,
                        declarationInternalType: DeclarationInternalType.EXPORT,
                        cdsExportDeclaration,
                    },
                    templateId
                )
            ),
        [dispatch]
    );

    const updateIrelandImportDeclaration = useCallback(
        async (customerId: string, declarationId: string, declaration: IrelandImportDeclarationPayload) =>
            withResponseNotifications(
                await dispatch(doUpdateIrelandImportDeclaration(customerId, declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const updateIrelandH7ImportDeclaration = useCallback(
        async (customerId: string, declarationId: string, declaration: IrelandH7ImportDeclarationPayload) =>
            withResponseNotifications(
                await dispatch(doUpdateIrelandH7ImportDeclaration(customerId, declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const updateIrelandExportDeclaration = useCallback(
        async (customerId: string, declarationId: string, declaration: IrelandExportDeclarationPayload) =>
            withResponseNotifications(
                await dispatch(doUpdateIrelandExportDeclaration(customerId, declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const updateUkImportDeclaration = useCallback(
        async (customerId: string, declarationId: string, declaration: CdsImportDeclarationPayload) =>
            withResponseNotifications(
                await dispatch(doUpdateUkImportDeclaration(customerId, declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const updateUkExportDeclaration = useCallback(
        async (
            customerId: string,
            declarationId: string,
            declaration: CdsExportDeclarationPayload,
            withNotification: boolean = true
        ) => {
            if (withNotification) {
                return withResponseNotifications(
                    await dispatch(doUpdateUkExportDeclaration(customerId, declarationId, declaration)),
                    updateDeclarationNotifs(t)
                );
            } else {
                return await dispatch(doUpdateUkExportDeclaration(customerId, declarationId, declaration));
            }
        },

        [dispatch, t, withResponseNotifications]
    );

    const submitDeclaration = useCallback(
        async (declarationId: string, emails: NotifyEmails) =>
            withResponseNotifications(
                await dispatch(doSubmitDeclaration(declarationId, emails)),
                submitDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const listDeclarationDocuments = useCallback(
        (declarationId: string) =>
            dispatch(
                doGetDeclarationDocuments({
                    ...defaultPagination,
                    size: 1000,
                    declarationId,
                })
            ),
        [dispatch]
    );

    const updateCoreTemplateStatus = useCallback(
        (declarationId: string) => dispatch(doUpdateCoreTemplateStatus(declarationId)),
        [dispatch]
    );

    const updateEntrySummaryDeclaration = useCallback(
        async (customerId: any, declarationId: string, declaration: IrelandEntrySummaryDeclarationPayload) =>
            withResponseNotifications(
                await dispatch(doUpdateEntrySummaryDeclaration(declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const duplicateDeclaration = useCallback(
        (declarationId: string, customerId: string, jobId: string) =>
            dispatch(doDuplicateDeclaration(declarationId, customerId, jobId)),
        [dispatch]
    );

    const invalidateDeclaration = useCallback(
        (declarationId: string, reason: string, declarationType: DeclarationInternalType) => {
            if (declarationType === DeclarationInternalType.IMPORT) {
                return dispatch(doInvalidateDeclaration(declarationId, reason));
            } else if (declarationType === DeclarationInternalType.EXPORT) {
                return dispatch(doInvalidateExportDeclaration(declarationId, reason));
            }
        },
        [dispatch]
    );

    const submitEntrySummaryDeclaration = useCallback(
        (declarationId: string, declaration: IrelandEntrySummaryDeclarationPayload) =>
            dispatch(doSubmitEntrySummaryDeclaration(declarationId, declaration)),
        [dispatch]
    );

    const importPresentationNotification = useCallback(
        async (declarationId: string, type?: string) => {
            return dispatch(doSubmitImportPresentationNotification(declarationId, type));
        },
        [dispatch]
    );

    const submitIrelandImportDocuments = useCallback(
        async (declarationId: string, documentIds: string[]) => {
            return dispatch(doSubmitIrelandImportDocuments(declarationId, documentIds));
        },
        [dispatch]
    );

    const clearDeclarationsList = useCallback(() => {
        return dispatch(doClearDeclarationList());
    }, [dispatch]);

    const createAmendmentIrelandImportDeclaration = useCallback(
        (reason: string) => {
            return dispatch(doCreateAmendmentIrelandImportDeclaration(declarationId, reason));
        },
        [dispatch, declarationId]
    );

    const createAmendmentIrelandExportDeclaration = useCallback(() => {
        return dispatch(doCreateAmendmentIrelandExportDeclaration(declarationId));
    }, [dispatch, declarationId]);

    const createAmendmentIrelandEntrySummaryDeclaration = useCallback(
        (reason: string) => {
            return dispatch(doCreateAmendmentIrelandEntrySummaryDeclaration(declarationId, reason));
        },
        [dispatch, declarationId]
    );

    const getDeclarationHistory = useCallback(() => {
        return dispatch(doGetDeclarationHistory(declarationId));
    }, [dispatch, declarationId]);

    const createElectronicTransportDocument = useCallback(
        (customerId: string, declaration?: ElectronicTransportDocument, jobId?: string) =>
            dispatch(
                doCreateElectronicTransportDocument(customerId, {
                    jobId,
                    declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                    declarationInternalType: DeclarationInternalType.ETD,
                    electronicTransportDocument: declaration,
                })
            ),
        [dispatch]
    );

    const updateElectronicTransportDocument = useCallback(
        async (customerId: string, declarationId: string, declaration: ElectronicTransportDocumentPayload) =>
            withResponseNotifications(
                await dispatch(doUpdateElectronicTransportDocument(customerId, declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const createArrivalAtExitDeclaration = useCallback(
        (customerId: string, declarationId: string, declaration: ArrivalAtExitDeclaration) =>
            dispatch(doCreateArrivalAtExitDeclaration(customerId, declarationId, declaration)),
        [dispatch]
    );

    const updateArrivalAtExitDeclaration = useCallback(
        async (customerId: string, declarationId: string, declaration: ArrivalAtExitDeclaration) =>
            withResponseNotifications(
                await dispatch(doUpdateArrivalAtExitDeclaration(customerId, declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    useEffect(() => {
        if (declarationId && (forceFetchDeclaration || declarationId !== declaration?.id)) {
            getDeclaration(declarationId);
            getDeclarationHistory();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declarationId]);

    return {
        isLoading,
        error,
        declaration,
        declarationTemplate,
        declarations,
        documents,
        getDeclaration,
        getDeclarationTemplate,
        listDeclarations,
        createIrelandImportDeclaration,
        createIrelandH7ImportDeclaration,
        createIrelandExportDeclaration,
        createUkImportDeclaration,
        createUkExportDeclaration,
        createUkImportNewDeclaration,
        updateIrelandImportDeclaration,
        updateIrelandExportDeclaration,
        updateIrelandH7ImportDeclaration,
        updateUkImportDeclaration,
        updateUkExportDeclaration,
        submitDeclaration,
        listDeclarationDocuments,
        updateCoreTemplateStatus,
        updateEntrySummaryDeclaration,
        createEntrySummaryDeclaration,
        submitEntrySummaryDeclaration,
        createTemporaryStorageDeclaration,
        updateTemporaryStorageDeclaration,
        duplicateDeclaration,
        invalidateDeclaration,
        importPresentationNotification,
        submitIrelandImportDocuments,
        clearDeclarationsList,
        createAmendmentIrelandImportDeclaration,
        getDeclarationHistory,
        createAmendmentIrelandExportDeclaration,
        declarationHistory,
        createElectronicTransportDocument,
        updateElectronicTransportDocument,
        createArrivalAtExitDeclaration,
        updateArrivalAtExitDeclaration,
        createAmendmentIrelandEntrySummaryDeclaration,
        createIrelandH1ImportDeclarationWithFile,
    };
};

export default useDeclarations;
