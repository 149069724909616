import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import CountrySelect from '../../../../../components/ui/composed/declarations/CountrySelect';
import { ValidationSchema, validators } from '../validations/validations';

export type CountryOfDispatchExportCodeFields = 'id';

export const countryOfDispatchExportCodeBlockValidation: {
    childValidators: {
        'goodsShipment.exportCountry': ValidationSchema<CountryOfDispatchExportCodeFields>;
    };
} = {
    childValidators: {
        'goodsShipment.exportCountry': {
            childValidators: {
                id: [validators.required(), validators.exact(2)],
            },
        },
    },
};

interface Props extends BlockProps<'id'> {}

const CountryOfDispatchExportCodeBlock = ({
    path = 'goodsShipment.exportCountry',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '5/14', []);

    return (
        <DeclarationField pathPrefix={path} name="id">
            {({ form, field }) => (
                <CountrySelect
                    refNumber={ucc}
                    required={fieldOptions?.id?.required}
                    label={t('countryOfDispatch/exportCode')}
                    {...getFormikProps(field.name, form)}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default CountryOfDispatchExportCodeBlock;
