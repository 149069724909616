import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import FormValuationIndicator from 'components/ui/composed/declarations/formValuationIndicator/FormValuationIndicator';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { commodityClassificationTypeCodeLabels } from 'store/declarations/enums/uk/commodity-classification-type-code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../../common/cards/NewFormCard';

const ItemFieldsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getCdsTooltipsByRefNumberAndField, getCdsTooltipsByField } = useTooltips();

    return (
        <NewFormCard title={t('goodsItems.title')}>
            <FormCardContainer>
                <FormInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps('taricCodeIdentifier', props)}
                    refNumber="6.15"
                    label={t('taricCodeIdentifier')}
                    tooltip={getCdsTooltipsByRefNumberAndField('6.15', t('taricCodeIdentifier'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps('goodsItemNumber', props)}
                    refNumber="1.6"
                    label={t('goodsItems.itemNumber')}
                    tooltip={getCdsTooltipsByRefNumberAndField('1.6', t('goodsItems.itemNumber'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('ucr', props)}
                    refNumber="2.4"
                    label={t('ucr')}
                    numbersAndLetters
                    tooltip={getCdsTooltipsByRefNumberAndField('2.4', t('ucr'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <DeclarationSelect
                    required
                    hideKeys
                    viewOnly={props.viewOnly}
                    {...getFormikProps('valuationMethod', props)}
                    refNumber="4.16"
                    label={t('valuationMethod')}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.16', t('valuationMethod'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={normalizeCodesToSelect(codelists?.ukValuationMethodTypes || [])}
                    condensed
                />
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps('itemPriceAmount', props)}
                    refNumber="4.14"
                    label={t('itemPriceAmount')}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.14', t('itemPriceAmount'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CurrencySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps('itemPriceCurrency', props)}
                    label={t('itemPriceCurrency')}
                    refNumber="4.14"
                    tooltip={getCdsTooltipsByRefNumberAndField('4.14', t('itemPriceCurrency'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps('countryOfDestination', props)}
                    label={t('countryOfDestination')}
                    refNumber="5.8"
                    tooltip={getCdsTooltipsByRefNumberAndField('5.8', t('countryOfDestination'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps('countryOfDispatchExport', props)}
                    label={t('countryOfDispatchExport')}
                    refNumber="5.14"
                    tooltip={getCdsTooltipsByRefNumberAndField('5.14', t('country'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps('countryOfOrigin', props)}
                    label={t('countryOfOrigin')}
                    refNumber="5.15"
                    tooltip={getCdsTooltipsByRefNumberAndField('5.15', t('countryOfOrigin'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationSelect
                    hideKeys
                    viewOnly={props.viewOnly}
                    {...getFormikProps('countryOfOriginRole', props)}
                    refNumber="5.15"
                    label={t('countryOfOriginRole')}
                    tooltip={getCdsTooltipsByRefNumberAndField('5.15', t('countryOfOriginRole'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={normalizeCodesToSelect(codelists?.ukPartyRoleStatusTypes || [])}
                    condensed
                />
                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps('countryOfPreferentialOrigin', props)}
                    label={t('countryOfPreferentialOrigin')}
                    refNumber="5.16"
                    tooltip={getCdsTooltipsByRefNumberAndField('5.16', t('countryOfPreferentialOrigin'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps('countryOfPreferentialOriginRole', props)}
                    refNumber="5.16"
                    label={t('countryOfPreferentialOriginRole')}
                    tooltip={getCdsTooltipsByRefNumberAndField('5.16', t('countryOfPreferentialOriginRole'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={normalizeCodesToSelect(codelists?.ukPartyRoleStatusTypes || [])}
                    condensed
                />
                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps('goodsNetMass', props)}
                    refNumber="6.1"
                    label={t('goodsItems.netMass')}
                    tooltip={getCdsTooltipsByRefNumberAndField('6.1', t('goodsItems.netMass'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    integerNumbers
                    viewOnly={props.viewOnly}
                    {...getFormikProps('goodsSupplementaryUnits', props)}
                    refNumber="6.2"
                    label={t('goodsItems.supplementaryUnits')}
                    tooltip={getCdsTooltipsByRefNumberAndField('6.2', t('goodsItems.supplementaryUnits'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps('goodsGrossMass', props)}
                    refNumber="6.5"
                    label={t('goodsItems.grossMass')}
                    tooltip={getCdsTooltipsByRefNumberAndField('6.5', t('goodsItems.grossMass'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    required
                    maxLength={512}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('goodsDescription', props)}
                    refNumber="6.8"
                    label={t('goodsItems.goodsDescription')}
                    tooltip={getCdsTooltipsByRefNumberAndField('6.8', t('goodsItems.goodsDescription'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    maxLength={8}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('commodityCusCodeIdentifier', props)}
                    refNumber="6.13"
                    label={t('commodityCusCodeIdentifier')}
                    tooltip={getCdsTooltipsByRefNumberAndField('6.13', t('commodityCusCodeIdentifier'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationSelect
                    refNumber="6.13"
                    label={t('commodityCusTypeCode')}
                    tooltip={getCdsTooltipsByRefNumberAndField('6.13', t('commodityCusTypeCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={commodityClassificationTypeCodeLabels}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('commodityCusTypeCode', props)}
                    condensed
                />
                <FormInput
                    required
                    maxLength={8}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('commodityCombinedNomenclatureCodeIdentifier', props)}
                    refNumber="6.14"
                    label={t('commodityCombinedNomenclatureCodeIdentifier')}
                    tooltip={getCdsTooltipsByRefNumberAndField(
                        '6.14',
                        t('commodityCombinedNomenclatureCodeIdentifier')
                    )}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationSelect
                    required
                    refNumber="6.14"
                    label={t('commodityCombinedNomenclatureTypeCode')}
                    tooltip={getCdsTooltipsByRefNumberAndField('6.14', t('commodityCombinedNomenclatureTypeCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={commodityClassificationTypeCodeLabels}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('commodityCombinedNomenclatureTypeCode', props)}
                    condensed
                />
                <DeclarationSelect
                    required
                    refNumber="6.15"
                    label={t('taricTypeCode')}
                    tooltip={getCdsTooltipsByRefNumberAndField('6.15', t('taricTypeCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={commodityClassificationTypeCodeLabels}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('taricTypeCode', props)}
                    condensed
                />
                <FormInput
                    maxLength={6}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('quotaOrderNumber', props)}
                    refNumber="8.1"
                    label={t('quotaOrderNumber')}
                    tooltip={getCdsTooltipsByRefNumberAndField('8.1', t('quotaOrderNumber'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationSelect
                    required
                    hideKeys
                    viewOnly={props.viewOnly}
                    {...getFormikProps('natureOfTransaction', props)}
                    refNumber="8.5"
                    label={t('natureOfTransaction')}
                    tooltip={getCdsTooltipsByRefNumberAndField('8.5', t('natureOfTransaction'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={normalizeCodesToSelect(codelists?.ukTransactionNatureTypes || [])}
                    condensed
                />
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps('statisticalValue', props)}
                    refNumber="8.6"
                    label={t('statisticalValue')}
                    tooltip={getCdsTooltipsByRefNumberAndField('8.6', t('statisticalValue'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CurrencySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps('statisticalValueCurrency', props)}
                    label={t('statisticalValueCurrency')}
                    refNumber="8.6"
                    tooltip={getCdsTooltipsByRefNumberAndField('8.6', t('statisticalValueCurrency'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`vatRate`, props)}
                    label={t('goodsItems.vatRate')}
                    tooltip={getCdsTooltipsByField(t('goodsItems.vatRate'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`dutyRate`, props)}
                    label={t('goodsItems.dutyRate')}
                    tooltip={getCdsTooltipsByField(t('goodsItems.dutyRate'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormValuationIndicator
                    formikProps1={{ ...getFormikProps(`valuationIndicator1`, props) }}
                    formikProps2={{ ...getFormikProps(`valuationIndicator2`, props) }}
                    formikProps3={{ ...getFormikProps(`valuationIndicator3`, props) }}
                    formikProps4={{ ...getFormikProps(`valuationIndicator4`, props) }}
                    refNumber="4.13"
                    label={t('valuationIndicator')}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.13', t('valuationIndicator'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default ItemFieldsCard;
