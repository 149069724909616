import { applyMiddleware, combineReducers, createStore, Reducer, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import aepBusinessRules, {
    aepBusinessRulesInitialState,
    AepBusinessRulesState,
} from 'store/aep-business-rules/reducer';
import aisBusinessRules, {
    aisBusinessRulesInitialState,
    AisBusinessRulesState,
} from 'store/ais-business-rules/reducer';
import icsBusinessRules, {
    icsBusinessRulesInitialState,
    IcsBusinessRulesState,
} from 'store/ics-business-rules/reducer';
import cargos, { cargosInitialState, CargosState } from 'store/cargo/reducer';
import codelists, { codelistsInitialState, CodelistsState } from 'store/codelists/reducer';
import { DeclarationFooterState } from 'store/declaration-footer/declaration-footer';
import declarationFooter, { declarationFooterInitialState } from 'store/declaration-footer/reducer';
import { DeclarationFormErrors } from 'store/declaration-form-errors/DeclarationFormErrors';
import { declarationHeaderInitialState, DeclarationHeaderState } from 'store/declaration-header/declaration-header';
import declarationHeader from 'store/declaration-header/reducer';
import commodityCodeTaxes, {
    commodityCodeTaxesInitialState,
    CommodityCodeTaxesState,
} from 'store/declaration-product-taxes/reducer';
import declarationProduct, {
    DeclarationProductState,
    initialDeclarationProductState,
} from 'store/declaration-product/reducer';
import { DeclarationValidation } from 'store/declaration-validation/declaration-validation';
import declarationValidation, {
    initialState as decalarationValidationInitialState,
} from 'store/declaration-validation/reducer';
import depositRefunds, {
    initialRefundsDepositState,
    RefundsDepositState,
} from 'store/declarations/ireland/refunds/deposits/reducer';
import refunds, { initialRefundsState, RefundsState } from 'store/declarations/ireland/refunds/remission/reducer';
import digitalCertificates, {
    DigitalCertificateState,
    initialDigitalCertificateState,
} from 'store/digital-certificates/reducer';
import documents, { DocumentsState, initalDocumentsState } from 'store/documents/reducer';
import eori, { eoriInitialState, EoriState } from 'store/eori/reducer';
import exchanges, { exchangesInitialState, ExchangesState } from 'store/exchanges/reducer';
import individuals, { individualsInitialState, IndividualsState } from 'store/individuals/reducer';
import jobs, { jobsInitialState, JobsState } from 'store/jobs/reducer';
import legs, { legsInitialState, LegsState } from 'store/journeys/legs/reducer';
import journeys, { journeysInitialState, JourneysState } from 'store/journeys/reducer';
import routes, { routesInitialState, RoutesState } from 'store/journeys/routes/reducer';
import productsTemplate, {
    productsTemplateInitialState,
    ProductsTemplateState,
} from 'store/products-templates/reducer';
import products, { productsInitialState, ProductsState } from 'store/products/reducer';
import taricCodes, { taricCodeInitialState, TaricCodeState } from 'store/taric-codes/reducer';
import tooltips, { tooltipsInitialState, TooltipsState } from 'store/tooltips/reducer';
import vehicles, { vehiclesInitialState, VehiclesState } from 'store/vehicles/reducer';
import { NotificationProps } from '../components/ui/base/notification/Notification';
import loggerMiddleware from '../middleware/logger';
import { BreadcrumbItem } from '../store/breadcrumb/breadcrumbItem';
import breadcrumb from '../store/breadcrumb/reducer';
import countries, { countriesInitialState, CountriesState } from '../store/countries/reducer';
import currencies, { currenciesInitialState, CurrenciesState } from '../store/currencies/reducer';
import customers, { customersInitialState, CustomersState } from '../store/customers/reducer';
import declarationFormErrors, {
    initialState as declarationFormErrorsInitState,
} from '../store/declaration-form-errors/reducer';
import declarations, { DeclarationsState, initialDeclarationsState } from '../store/declarations/reducer';
import drivers, { driversInitialState, DriversState } from '../store/drivers/reducer';
import notification, { initialState as notificationInitialState } from '../store/notification/reducer';
import session, { sessionInitialState, SessionState } from '../store/session/reducers';
import weighbridges, { weighbridgesInitialState, WeighbridgesState } from 'store/weighbridges/reducer';
import globalOverlay, { globalOverlayInitialState } from 'store/global-overlay/reducer';
import { GlobalOverlayState } from 'store/global-overlay/globalOverlay';
import { ProductTemplateFormErrors } from '../store/product-template-form-errors/ProductTemplateFormErrors';
import productTemplateFormErrors, {
    initialState as productTemplateFormErrorsInitState,
} from '../store/product-template-form-errors/reducer';
import { DeclarationFilter } from '../store/filter/DeclarationFilter';
import declarationFilter, { initialState as declarationFilterInitState } from '../store/filter/reducer';
import templates, { templatesInitialState, TemplatesState } from 'store/template/reducer';

interface ApplicationState {
    globalOverlay: GlobalOverlayState;
    session: SessionState;
    countries: CountriesState;
    currencies: CurrenciesState;
    declarations: DeclarationsState;
    breadcrumb: BreadcrumbItem[];
    customers: CustomersState;
    individuals: IndividualsState;
    notification: NotificationProps;
    drivers: DriversState;
    jobs: JobsState;
    codelists: CodelistsState;
    aisBusinessRules: AisBusinessRulesState;
    aepBusinessRules: AepBusinessRulesState;
    icsBusinessRules: IcsBusinessRulesState;
    productsTemplate: ProductsTemplateState;
    declarationFooter: DeclarationFooterState;
    products: ProductsState;
    documents: DocumentsState;
    taricCodes: TaricCodeState;
    digitalCertificates: DigitalCertificateState;
    declarationProduct: DeclarationProductState;
    declarationHeader: DeclarationHeaderState;
    exchanges: ExchangesState;
    commodityCodeTaxes: CommodityCodeTaxesState;
    eori: EoriState;
    tooltips: TooltipsState;
    declarationValidation: DeclarationValidation;
    cargos: CargosState;
    journeys: JourneysState;
    legs: LegsState;
    routes: RoutesState;
    vehicles: VehiclesState;
    declarationFormErrors: DeclarationFormErrors;
    refunds: RefundsState;
    depositRefunds: RefundsDepositState;
    weighbridges: WeighbridgesState;
    productTemplateFormErrors: ProductTemplateFormErrors;
    declarationFilter: DeclarationFilter;
    templates: TemplatesState;
}

const reducers: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    session,
    declarations,
    countries,
    currencies,
    breadcrumb,
    customers,
    individuals,
    notification,
    drivers,
    globalOverlay,
    jobs,
    codelists,
    aisBusinessRules,
    aepBusinessRules,
    icsBusinessRules,
    productsTemplate,
    declarationFooter,
    products,
    documents,
    taricCodes,
    digitalCertificates,
    declarationProduct,
    declarationHeader,
    exchanges,
    commodityCodeTaxes,
    eori,
    tooltips,
    declarationValidation,
    cargos,
    journeys,
    legs,
    routes,
    vehicles,
    declarationFormErrors,
    refunds,
    depositRefunds,
    weighbridges,
    productTemplateFormErrors,
    declarationFilter,
    templates,
});

export const store = configureStore();

export function configureStore(): Store<ApplicationState> {
    const middlewares = [loggerMiddleware, thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const composeEnhancers = composeWithDevTools({});

    // const enhancers = [middlewareEnhancer];

    // // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    // const composeEnhancers =
    //   (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    //   (window as any).__REDUX_DEVTOOLS_EXTENSION__();

    const composedEnhancers = composeEnhancers(middlewareEnhancer);

    const initialState: ApplicationState = {
        globalOverlay: globalOverlayInitialState,
        session: sessionInitialState,
        countries: countriesInitialState,
        currencies: currenciesInitialState,
        declarations: initialDeclarationsState,
        breadcrumb: [],
        customers: customersInitialState,
        individuals: individualsInitialState,
        notification: notificationInitialState,
        drivers: driversInitialState,
        jobs: jobsInitialState,
        codelists: codelistsInitialState,
        aisBusinessRules: aisBusinessRulesInitialState,
        aepBusinessRules: aepBusinessRulesInitialState,
        icsBusinessRules: icsBusinessRulesInitialState,
        productsTemplate: productsTemplateInitialState,
        declarationFooter: declarationFooterInitialState,
        products: productsInitialState,
        documents: initalDocumentsState,
        taricCodes: taricCodeInitialState,
        digitalCertificates: initialDigitalCertificateState,
        declarationProduct: initialDeclarationProductState,
        declarationHeader: declarationHeaderInitialState,
        exchanges: exchangesInitialState,
        commodityCodeTaxes: commodityCodeTaxesInitialState,
        eori: eoriInitialState,
        tooltips: tooltipsInitialState,
        declarationValidation: decalarationValidationInitialState,
        cargos: cargosInitialState,
        journeys: journeysInitialState,
        legs: legsInitialState,
        routes: routesInitialState,
        vehicles: vehiclesInitialState,
        declarationFormErrors: declarationFormErrorsInitState,
        refunds: initialRefundsState,
        depositRefunds: initialRefundsDepositState,
        weighbridges: weighbridgesInitialState,
        productTemplateFormErrors: productTemplateFormErrorsInitState,
        declarationFilter: declarationFilterInitState,
        templates: templatesInitialState,
    };

    const store = createStore(reducers, initialState, composedEnhancers);
    return store;
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
