export enum IrelandExportMessageType {
    X12 = 'X12', //Normal Customs Declaration
    X15 = 'X15', //Supplementary Customs Declaration
    X17 = 'X17', //Correction to a Customs Declaration Post Clearance
    C12 = 'C12', //Used For Notice of Intent (NOI), SAD EX UNDER CONTROL and SAD COM TRANSFER
    E12 = 'E12', //Normal Excise Duty Entry
}

export const irelandExportMessageTypeLabels = [
    { key: IrelandExportMessageType.X12, value: '(X12) Normal Customs Declaration' },
    { key: IrelandExportMessageType.X15, value: '(X15) Supplementary Customs Declaration' },
    { key: IrelandExportMessageType.X17, value: '(X17) Correction to a Customs Declaration Post Clearance' },
    {
        key: IrelandExportMessageType.C12,
        value: '(C12) Used For Notice of Intent (NOI), SAD EX UNDER CONTROL and SAD COM TRANSFER',
    },
    { key: IrelandExportMessageType.E12, value: '(E12) Normal Excise Duty Entry' },
] as const;
