import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { normalizeCodesToSelect } from 'store/codelists/code';
import useCodelists from 'hooks/useCodelists';
import { ValidationSchema, validators } from '../validations/validations';

export type Fields = 'id';

export const customsOfficeOfPresentationBlockValidation: {
    childValidators: {
        presentationOffice: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        presentationOffice: {
            childValidators: {
                id: [validators.exact(8)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const CustomsOfficeOfPresentationBlock = ({ path = 'presentationOffice', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();

    const ucc = useMemo(() => '5/26', []);
    return (
        <DeclarationField pathPrefix={path} name="id">
            {({ form, field }: FieldProps<any>) => (
                <FormSelect
                    refNumber={ucc}
                    label={t('customsOfficeOfPresentation')}
                    {...getFormikProps(field.name, form)}
                    selectOptions={normalizeCodesToSelect(codelists?.ukSupervisingCustomsOffices || [])}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default CustomsOfficeOfPresentationBlock;
