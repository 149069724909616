import { Col } from 'antd';
import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useTooltips from 'hooks/useTooltips';
import { FC, FocusEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';

interface Props {
    countryRequired?: boolean;
    h7PostCode?: number;
    declarationCountry?: DeclarationCountry;
    declarationType?: DeclarationName;
    refNumbers?: {
        streetAndNumber: string;
        country: string;
        postcode: string;
        city: string;
    };
    fieldProps: {
        streetAndNumber: FieldInputProps<any>;
        country: FieldInputProps<any>;
        postCode: FieldInputProps<any>;
        city: FieldInputProps<any>;
    };
    fieldMeta?: {
        streetAndNumber: FieldMetaProps<any>;
        country: FieldMetaProps<any>;
        postCode: FieldMetaProps<any>;
        city: FieldMetaProps<any>;
    };
    fieldHelper: {
        streetAndNumber: FieldHelperProps<any>;
        country: FieldHelperProps<any>;
        postCode: FieldHelperProps<any>;
        city: FieldHelperProps<any>;
    };
    disableFields?: {
        streetAndNumber?: boolean;
        country?: boolean;
        postCode?: boolean;
        city?: boolean;
    };
    required?: boolean;
    viewOnly?: boolean;
    refClicked?: (refNumber: string | number) => void;
    condensed?: boolean;
    onBlur?: FocusEventHandler<HTMLInputElement>;
}

const AddressForm: FC<Props> = ({
    countryRequired,
    h7PostCode,
    refNumbers,
    fieldProps,
    fieldMeta,
    fieldHelper,
    required,
    viewOnly,
    refClicked,
    declarationType,
    disableFields,
    declarationCountry,
    condensed,
    onBlur,
}) => {
    const { t } = useTranslation('common');
    const { getH7TooltipsByRefNumberAndField, getH1TooltipsByRefNumberAndField, getCdsTooltipsByRefNumberAndField } =
        useTooltips();

    const getTooltip = useCallback(
        (sadBox: string, label: string) => {
            if (declarationCountry === DeclarationCountry.UK) {
                return getCdsTooltipsByRefNumberAndField(sadBox, label);
            } else {
                if (declarationType === DeclarationName.H7) {
                    return getH7TooltipsByRefNumberAndField(sadBox, label);
                } else {
                    return getH1TooltipsByRefNumberAndField(sadBox, label);
                }
            }
        },
        [
            declarationCountry,
            declarationType,
            getH7TooltipsByRefNumberAndField,
            getH1TooltipsByRefNumberAndField,
            getCdsTooltipsByRefNumberAndField,
        ]
    );

    if (condensed) {
        return (
            <>
                <FormInput
                    viewOnly={viewOnly}
                    maxLength={70}
                    disabled={disableFields?.streetAndNumber}
                    required={required}
                    refNumber={refNumbers?.streetAndNumber}
                    label={t('address.streetAndNumber')}
                    fieldProps={fieldProps.streetAndNumber}
                    fieldMeta={fieldMeta?.streetAndNumber}
                    fieldHelper={fieldHelper?.streetAndNumber}
                    tooltip={getTooltip(refNumbers?.streetAndNumber ?? '', t('address.streetAndNumber'))}
                    refClicked={refClicked}
                    testId="streetAndNumber"
                    condensed={condensed}
                    onBlur={onBlur}
                />
                <FormInput
                    viewOnly={viewOnly}
                    maxLength={35}
                    required={required}
                    refNumber={refNumbers?.city}
                    disabled={disableFields?.city}
                    label={t('address.city')}
                    testId="city"
                    fieldProps={fieldProps.city}
                    fieldMeta={fieldMeta?.city}
                    fieldHelper={fieldHelper?.city}
                    tooltip={getTooltip(refNumbers?.city ?? '', t('address.city'))}
                    refClicked={refClicked}
                    condensed={condensed}
                    onBlur={onBlur}
                />
                <FormInput
                    viewOnly={viewOnly}
                    maxLength={h7PostCode ?? 9}
                    required={required}
                    refNumber={refNumbers?.postcode}
                    label={t('address.postcode')}
                    testId="postcode"
                    fieldProps={fieldProps.postCode}
                    disabled={disableFields?.postCode}
                    fieldMeta={fieldMeta?.postCode}
                    fieldHelper={fieldHelper?.postCode}
                    tooltip={getTooltip(refNumbers?.postcode ?? '', t('address.postcode'))}
                    refClicked={refClicked}
                    condensed={condensed}
                    onBlur={onBlur}
                />
                <CountrySelect
                    viewOnly={viewOnly}
                    required={required || countryRequired}
                    refNumber={refNumbers?.country}
                    testId="country"
                    fieldProps={fieldProps.country}
                    fieldMeta={fieldMeta?.country}
                    fieldHelper={fieldHelper?.country}
                    disabled={disableFields?.country}
                    tooltip={getTooltip(refNumbers?.country ?? '', t('address.country'))}
                    refClicked={refClicked}
                    condensed={condensed}
                    onBlur={onBlur}
                />
            </>
        );
    }

    return (
        <>
            <Col sm={{ span: 24 }} lg={{ span: 12 }}>
                <FormInput
                    viewOnly={viewOnly}
                    maxLength={70}
                    disabled={disableFields?.streetAndNumber}
                    required={required}
                    refNumber={refNumbers?.streetAndNumber}
                    label={t('address.streetAndNumber')}
                    fieldProps={fieldProps.streetAndNumber}
                    fieldMeta={fieldMeta?.streetAndNumber}
                    fieldHelper={fieldHelper?.streetAndNumber}
                    tooltip={getTooltip(refNumbers?.streetAndNumber ?? '', t('address.streetAndNumber'))}
                    refClicked={refClicked}
                    testId="streetAndNumber"
                    condensed={condensed}
                    onBlur={onBlur}
                />
            </Col>
            <Col sm={{ span: 24 }} lg={{ span: 12 }}>
                <FormInput
                    viewOnly={viewOnly}
                    maxLength={35}
                    required={required}
                    refNumber={refNumbers?.city}
                    disabled={disableFields?.city}
                    label={t('address.city')}
                    testId="city"
                    fieldProps={fieldProps.city}
                    fieldMeta={fieldMeta?.city}
                    fieldHelper={fieldHelper?.city}
                    tooltip={getTooltip(refNumbers?.city ?? '', t('address.city'))}
                    refClicked={refClicked}
                    condensed={condensed}
                    onBlur={onBlur}
                />
            </Col>
            <Col sm={{ span: 24 }} lg={{ span: 12 }}>
                <FormInput
                    viewOnly={viewOnly}
                    maxLength={h7PostCode ?? 9}
                    required={required}
                    refNumber={refNumbers?.postcode}
                    label={t('address.postcode')}
                    testId="postcode"
                    fieldProps={fieldProps.postCode}
                    disabled={disableFields?.postCode}
                    fieldMeta={fieldMeta?.postCode}
                    fieldHelper={fieldHelper?.postCode}
                    tooltip={getTooltip(refNumbers?.postcode ?? '', t('address.postcode'))}
                    refClicked={refClicked}
                    condensed={condensed}
                    onBlur={onBlur}
                />
            </Col>
            <Col sm={{ span: 24 }} lg={{ span: 12 }}>
                <CountrySelect
                    viewOnly={viewOnly}
                    required={required || countryRequired}
                    refNumber={refNumbers?.country}
                    testId="country"
                    fieldProps={fieldProps.country}
                    fieldMeta={fieldMeta?.country}
                    fieldHelper={fieldHelper?.country}
                    disabled={disableFields?.country}
                    tooltip={getTooltip(refNumbers?.country ?? '', t('address.country'))}
                    refClicked={refClicked}
                    condensed={condensed}
                    onChange={onBlur}
                />
            </Col>
        </>
    );
};
export default AddressForm;
