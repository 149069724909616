import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';

const TransportCostsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH7TooltipsByRefNumber } = useTooltips();

    return (
        <NewFormCard title={t('Transport Costs')}>
            <FormCardContainer>
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(
                        `goodsShipment.valuationInformation.transportAndInsuranceCostsToTheDestinationAmount`,
                        props
                    )}
                    refNumber="4.19"
                    label={t('transportAndInsuranceCostsToTheDestination.amount')}
                    tooltip={getH7TooltipsByRefNumber('4.19')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CurrencySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(
                        `goodsShipment.valuationInformation.transportAndInsuranceCostsToTheDestinationCurrency`,
                        props
                    )}
                    label={t('transportAndInsuranceCostsToTheDestination.currency')}
                    refNumber="4.19"
                    tooltip={getH7TooltipsByRefNumber('4.19')}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default TransportCostsCard;
