import { enumToText } from 'core/utils/enum-to-text';
import { FC, useMemo } from 'react';
import { IndividualType } from 'store/individuals/individual';
import { isAdmin } from 'store/individuals/utils';
import styled from 'styled-components';
import { StyledOpenIcon, StyledRole, StyledRoleAdmin } from './TagsMenu.styles';

const StyledButton = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

interface Props {
    role: IndividualType;
    setOpen: Function;
    open: boolean;
}

export const TagButton: FC<Props> = ({ role, open, setOpen }) => {
    const tag = useMemo(() => {
        const text = enumToText(role);
        return isAdmin(role) ? <StyledRoleAdmin>{text}</StyledRoleAdmin> : <StyledRole>{text}</StyledRole>;
    }, [role]);

    return (
        <StyledButton onClick={() => setOpen(!open)}>
            {tag}
            <StyledOpenIcon></StyledOpenIcon>
        </StyledButton>
    );
};

export default TagButton;
