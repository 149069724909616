import { Button, Tooltip, notification } from 'antd';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { FormikProps, useFormikContext } from 'formik';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { CdsExportPreviousDocument } from 'store/declarations/uk/export-declaration';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import { ThunderboltOutlined } from '@ant-design/icons';
import axiosClient from 'config/axios';
import { SuccessResponse } from 'core/http/response';
import config from 'config/config';
import useDeclarations from 'hooks/useDeclarations';
import { getFormikProps } from '../../../utils/form-utils';
import { ValidationSchema, validators } from '../validations/validations';

const ducrValidation: ValidationSchema<'ducr'> = {
    childValidators: {
        ducr: [validators.required()],
    },
};

export const ducrCardValidation = {
    selfValidators: [ducrValidation],
};

interface Props {
    previousDocumentPath: string;
}

const DUCRMUCRCard = ({ previousDocumentPath: previousDocumentPathProp }: Props): ReactElement => {
    const mainFormik: FormikProps<any> = useFormikContext();
    const { declaration } = useDeclarations();
    const { template, form, templateFormik } = useTemplateContext();

    const [generatingDucrFromActionBtn, setGeneratingDucrFromActionBtn] = useState<boolean>(false);

    const formik = useMemo(() => (template ? templateFormik : mainFormik), [mainFormik, template, templateFormik]);

    const previousDocumentPath = useMemo(
        () => (template ? `${form}.defaults.${previousDocumentPathProp}` : previousDocumentPathProp),
        [form, previousDocumentPathProp, template]
    );

    const previousDocument = useMemo(
        () => (formik?.getFieldMeta(previousDocumentPath).value ?? []) as unknown as CdsExportPreviousDocument[],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik?.getFieldMeta(previousDocumentPath)]
    );

    const getIndexOfPrevDocByArg = useCallback(
        (arg: string | undefined) => previousDocument.findIndex((prevDoc) => prevDoc.typeCode === arg),
        [previousDocument]
    );

    const handlePreviousDocument = (code: string, typeCode: 'DCR' | 'MCR') => {
        const index = getIndexOfPrevDocByArg(typeCode);

        const create = () => {
            const newPreviousDocument = [...previousDocument, { categoryCode: 'Y', typeCode, id: code }];
            formik?.setFieldValue(previousDocumentPath, newPreviousDocument);
        };

        const update = () => {
            formik?.setFieldValue(`${previousDocumentPath}.${index}.id`, code);
        };

        const remove = () => {
            if (index === -1) throw new Error('Cannot remove code');
            const newPreviousDocument = [...previousDocument.filter((_, i) => i !== index)];
            formik?.setFieldValue(`${previousDocumentPath}`, newPreviousDocument);
        };

        if (index !== -1) {
            if (code) {
                update();
            } else {
                remove();
            }
        } else {
            if (code) {
                create();
            }
        }
    };

    useEffect(() => {
        const dcrIndex = getIndexOfPrevDocByArg('DCR');
        const mcrIndex = getIndexOfPrevDocByArg('MCR');

        if (dcrIndex !== -1) formik?.setFieldValue('ducr', previousDocument[dcrIndex]?.id);
        if (mcrIndex !== -1) formik?.setFieldValue('mucr', previousDocument[mcrIndex]?.id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousDocument, getIndexOfPrevDocByArg]);

    const generateDucr = () => {
        if (!declaration) throw new Error('No declaration');
        axiosClient
            .get<SuccessResponse<string>>(
                `${config.declarationsUrl}/uk/export/declarations/${declaration.id}/generateDucr`
            )
            .then((data) => {
                formik?.setFieldValue(`ducr`, data?.data.payload);
                handlePreviousDocument(data?.data.payload, 'DCR');
                generatingDucrFromActionBtn && notification.success({ message: 'DUCR generated and filled' });
            })
            .catch(() => {
                generatingDucrFromActionBtn && notification.error({ message: 'There was an issue generating DUCR' });
            })
            .finally(() => {
                setGeneratingDucrFromActionBtn(false);
            });
    };

    useEffect(() => {
        const subscriberId = window.EventBus.subscribe('afterDeclarationSave', () => generateDucr());

        return () => window.EventBus.unsubscribe(subscriberId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getFieldProps, getFieldHelpers, getFieldMeta } = formik!;

    return (
        <NewFormCard title="DUCR/MUCR">
            <FormCardContainer>
                <DeclarationInput
                    name="DCR"
                    label="DUCR"
                    {...getFormikProps(`ducr`, {
                        getFieldProps,
                        getFieldHelpers,
                        getFieldMeta,
                    })}
                    onBlur={(e) => handlePreviousDocument(e.target.value, 'DCR')}
                    condensed
                    after={
                        <Tooltip overlay="Generate DUCR">
                            <Button
                                shape="circle"
                                icon={<ThunderboltOutlined />}
                                onClick={() => {
                                    setGeneratingDucrFromActionBtn(true);
                                    document.getElementById('footer-save-draft-btn')?.click();
                                }}
                            />
                        </Tooltip>
                    }
                    required
                />
                <DeclarationInput
                    name="MCR"
                    label="MUCR"
                    {...getFormikProps(`mucr`, {
                        getFieldProps,
                        getFieldHelpers,
                        getFieldMeta,
                    })}
                    onBlur={(e) => handlePreviousDocument(e.target.value, 'MCR')}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default DUCRMUCRCard;
