import { CopyOutlined, DeleteOutlined, PrinterOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Button from 'components/ui/base/button';
import Tooltip from 'components/ui/base/tooltip/Tooltip';
import DeclarationStatusTag from 'components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import { dateStandardFormat, getHour } from 'core/utils/date';
import { enumToText } from 'core/utils/enum-to-text';
import { FC, useMemo } from 'react';
import {
    Declaration,
    DeclarationAndAgent,
    DeclarationHistory,
    DeclarationSortParameters,
} from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { colors } from 'theme';
import {
    getDeclarantName,
    getDeclarationName,
    getDeclarationPayload,
    getExporterName,
    getImporterName,
    getMrn,
    getReferenceNumber,
} from 'views/declarations/utils/declaration-utils';
import { toTitleCase } from 'views/declarations/utils/validation-utils';
import DeclarationDocumentsStatusTag from '../../../components/ui/composed/declarations/declaration-documents/DeclarationDocumentsStatusTag';
import {
    DivTableActionInline,
    StyledCopyOutlined,
    StyledFileOutlined,
    StyledIsCoreTemplate,
} from '../CustomDeclaration.styles';
import DeclarationDate from './components/DeclarationDate';
import { FlexDiv, SpanEllipsis, SpanMR5Icon, StyledButton, TooltipDiv } from './components/DeclarationsTable.styled';
import DescriptionOfGoods from './components/DescriptionOfGoods';
import ExporterIcon from './components/icons/ExporterIcon';
import ImporterIcon from './components/icons/ImporterIcon';
import { DeclarationTableType } from './DeclarationsTable';

export const getDeclarationStyle = (record: any) => {
    const declarationStatus = record.status ?? record.declaration.status;
    const documentsUploadRequested =
        record?.irelandImportDeclaration?.documentsUploadRequested ??
        record?.irelandH7ImportDeclaration?.documentsUploadRequested ??
        record?.cdsExportDeclaration?.documentsUploadRequested;

    if (documentsUploadRequested) {
        return { style: { background: `${colors.lightOrange2}`, border: 'none' } };
    }

    if (declarationStatus === DeclarationStatus.REJECTED || declarationStatus === DeclarationStatus.INVALID) {
        return { style: { background: `${colors.invalid}`, border: 'none' } };
    }

    return {};
};

const getInternalType = (declaration: Declaration) => {
    if (declaration.declarationInternalType === 'IMPORT_NEW') {
        return enumToText('IMPORT');
    }
    return enumToText(declaration.declarationInternalType);
};

const getDeclarationType = (declaration: Declaration) => {
    const internalType = getInternalType(declaration);
    const name = getDeclarationName(declaration);
    const country = declaration.declarationExternalEntity === DeclarationExternalEntity.REVENUE ? 'Ireland' : 'UK';
    const declarationType = `${internalType} (${name})`;
    return (
        <FlexDiv>
            <SpanEllipsis>{country}</SpanEllipsis>
            <SpanEllipsis>{declarationType}</SpanEllipsis>
        </FlexDiv>
    );
};

const FullItemDescription: FC<{ declaration: Declaration }> = ({ declaration }) => (
    <TooltipDiv>
        <DescriptionOfGoods fullDescription declaration={declaration} />
    </TooltipDiv>
);

const DeclarationIds: FC<{ declaration: Declaration }> = ({ declaration }) => {
    const mrn = getMrn(declaration);
    const ref = getReferenceNumber(declaration);
    return (
        <FlexDiv>
            <SpanEllipsis>Ref: {ref}</SpanEllipsis>
            <SpanEllipsis>MRN: {mrn}</SpanEllipsis>
        </FlexDiv>
    );
};

const DeclarationDescription: FC<{ declaration: Declaration }> = ({ declaration }) => {
    return (
        <FlexDiv>
            <DescriptionOfGoods declaration={declaration} />
        </FlexDiv>
    );
};

const DeclarationExporterAndImporter: FC<{ declaration: Declaration }> = ({ declaration }) => {
    const payload = useMemo(() => {
        return getDeclarationPayload(declaration);
    }, [declaration]);

    if (payload) {
        const importerName = getImporterName(payload);
        const exporterName = getExporterName(payload);

        return (
            <FlexDiv>
                <Row align="middle" wrap={false}>
                    <SpanMR5Icon>
                        <ImporterIcon />
                    </SpanMR5Icon>
                    <SpanEllipsis> {importerName}</SpanEllipsis>
                </Row>
                <Row align="middle" wrap={false}>
                    <SpanMR5Icon>
                        <ExporterIcon />
                    </SpanMR5Icon>
                    <SpanEllipsis> {exporterName}</SpanEllipsis>
                </Row>
            </FlexDiv>
        );
    }
    return <></>;
};

const AgentAndDeclarant: FC<{ declaration: DeclarationAndAgent }> = ({ declaration }) => {
    const agent = declaration?.agent;
    const payload = getDeclarationPayload(declaration);
    const declarant = getDeclarantName(payload);
    return (
        <FlexDiv>
            <SpanEllipsis>{agent}</SpanEllipsis>
            <SpanEllipsis>{declarant}</SpanEllipsis>
        </FlexDiv>
    );
};

const dataColumns: ColumnsType<Declaration> = [
    {
        title: 'Decl. Type',
        dataIndex: DeclarationSortParameters.MESSAGE_TYPE,
        key: DeclarationSortParameters.MESSAGE_TYPE,
        render: (text: string, record: DeclarationAndAgent, index: number) => {
            return record ? (
                {
                    props: getDeclarationStyle(record),
                    children: <SpanEllipsis>{getDeclarationType(record)}</SpanEllipsis>,
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: 'Id',
        dataIndex: DeclarationSortParameters.ID,
        key: DeclarationSortParameters.ID,
        render: (text: string, record: DeclarationAndAgent, index: number) => {
            return record ? (
                {
                    props: getDeclarationStyle(record),
                    children: (
                        <SpanEllipsis>
                            <DeclarationIds declaration={record} />
                        </SpanEllipsis>
                    ),
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: 'Descr. of goods',
        dataIndex: DeclarationSortParameters.DESCRIPTION_OF_GOODS,
        key: DeclarationSortParameters.DESCRIPTION_OF_GOODS,
        render: (text: string, record: DeclarationAndAgent, index: number) => {
            return record ? (
                {
                    props: getDeclarationStyle(record),
                    children: (
                        <Tooltip
                            placement="bottomLeft"
                            color="white"
                            overlay={<FullItemDescription declaration={record} />}
                        >
                            <SpanEllipsis>
                                <DeclarationDescription declaration={record} />
                            </SpanEllipsis>
                        </Tooltip>
                    ),
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },

    {
        title: 'Decl. Status',
        dataIndex: DeclarationSortParameters.STATUS,
        key: DeclarationSortParameters.STATUS,
        render: (text: string, record: DeclarationAndAgent, index: number) => {
            return record.status ? (
                {
                    props: getDeclarationStyle(record),
                    children: <DeclarationStatusTag status={record.status} />,
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },

    {
        title: 'Consignee & Consignor',
        dataIndex: DeclarationSortParameters.EXPORTER,
        key: DeclarationSortParameters.EXPORTER,
        render: (text: string, record: DeclarationAndAgent) => {
            return record ? (
                {
                    props: getDeclarationStyle(record),
                    children: <DeclarationExporterAndImporter declaration={record} />,
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: 'Agent & Declarant',
        dataIndex: DeclarationSortParameters.DECLARANT,
        key: DeclarationSortParameters.DECLARANT,
        render: (text: string, record: DeclarationAndAgent) => {
            return record ? (
                {
                    props: getDeclarationStyle(record),
                    children: <AgentAndDeclarant declaration={record} />,
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: 'Documents',
        dataIndex: DeclarationSortParameters.DOCUMENTS,
        key: DeclarationSortParameters.DOCUMENTS,
        render: (text: string, record: DeclarationAndAgent) => {
            return record.status ? (
                {
                    props: getDeclarationStyle(record),
                    children: (
                        <DeclarationDocumentsStatusTag
                            documentsUploadRequested={
                                record?.irelandImportDeclaration?.documentsUploadRequested ??
                                record?.irelandH7ImportDeclaration?.documentsUploadRequested ??
                                record?.cdsExportDeclaration?.documentsUploadRequested
                            }
                            uploadedDocuments={record.uploadedFiles}
                        />
                    ),
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
    {
        title: 'Last Modified date',
        dataIndex: DeclarationSortParameters.CREATED_AT,
        key: DeclarationSortParameters.CREATED_AT,
        render: (text: string, record: DeclarationAndAgent) => {
            return record.createdAt ? (
                {
                    props: getDeclarationStyle(record),
                    children: (
                        <Tooltip
                            placement="bottomLeft"
                            color="white"
                            overlay={<DeclarationDate createdDate declaration={record} />}
                        >
                            <SpanEllipsis>
                                <DeclarationDate declaration={record} />
                            </SpanEllipsis>
                        </Tooltip>
                    ),
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
];

const getArchivedActions = (handleCoreTemplate?: Function, setAsTemplate?: Function, viewDocuments?: Function) => [
    {
        title: 'Commands',
        dataIndex: 'actions',
        key: 'actions',
        _render: (text: string, record: DeclarationAndAgent, index: number) => (
            <DivTableActionInline>
                <Tooltip placement="top" title={record.isCoreTemplate ? 'Unmark as core' : 'Mark as core'}>
                    <StyledIsCoreTemplate
                        $isCoreTemplate={record.isCoreTemplate}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();
                            handleCoreTemplate && handleCoreTemplate(record.id!);
                        }}
                    ></StyledIsCoreTemplate>
                </Tooltip>
                <Tooltip placement="top" title={'Use as template'}>
                    <StyledCopyOutlined
                        onClick={(e) => {
                            e.stopPropagation();
                            setAsTemplate && setAsTemplate(record.id!, DeclarationTableType.ARCHIVED);
                        }}
                    ></StyledCopyOutlined>
                </Tooltip>
                <Tooltip placement="top" title={'Documents'}>
                    <StyledFileOutlined
                        onClick={(e) => {
                            e.stopPropagation();
                            viewDocuments && viewDocuments(record.id!);
                        }}
                    ></StyledFileOutlined>
                </Tooltip>
            </DivTableActionInline>
        ),
        get render() {
            return this._render;
        },
        set render(value) {
            this._render = value;
        },
    },
];

const getCoreTemplateActions = (handleCoreTemplate?: Function, setAsTemplate?: Function, viewDocuments?: Function) => [
    {
        title: 'Commands',
        dataIndex: 'actions',
        key: 'actions',
        render: (text: string, record: DeclarationAndAgent, index: number) => {
            return (
                <DivTableActionInline>
                    <Tooltip placement="top" title={record.isCoreTemplate ? 'Unmark as core' : 'Mark as core'}>
                        <StyledIsCoreTemplate
                            $isCoreTemplate={record.isCoreTemplate}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCoreTemplate && handleCoreTemplate(record.id!);
                            }}
                        ></StyledIsCoreTemplate>
                    </Tooltip>
                    <Tooltip placement="top" title={'Use as template'}>
                        <StyledCopyOutlined
                            onClick={(e) => {
                                e.stopPropagation();
                                setAsTemplate && setAsTemplate(record.id!, DeclarationTableType.CORE_TEMPLATE);
                            }}
                        ></StyledCopyOutlined>
                    </Tooltip>
                    <Tooltip placement="top" title={'Documents'}>
                        <StyledFileOutlined
                            onClick={(e) => {
                                e.stopPropagation();
                                viewDocuments && viewDocuments(record.id!);
                            }}
                        ></StyledFileOutlined>
                    </Tooltip>
                </DivTableActionInline>
            );
        },
    },
];

const getActionsColums = (handleDuplicate?: Function, handleDelete?: Function, handlePrint?: Function) => {
    return [
        {
            title: 'Commands',
            dataIndex: 'Actions',
            key: 'Actions',
            render: (text: string, record: DeclarationAndAgent) => {
                return {
                    props: getDeclarationStyle(record),
                    children: (
                        <DivTableActionInline style={{ overflow: 'hidden' }}>
                            <StyledButton
                                disabled={record.status === DeclarationStatus.DRAFT}
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handlePrint?.(record.id);
                                }}
                            >
                                <PrinterOutlined />
                            </StyledButton>
                            <StyledButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDuplicate?.(record);
                                }}
                            >
                                <CopyOutlined />
                            </StyledButton>
                            <Button
                                disabled={
                                    record.status !== DeclarationStatus.DRAFT &&
                                    record.status !== DeclarationStatus.REJECTED &&
                                    record.status !== DeclarationStatus.INVALID
                                }
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete?.([record.id]);
                                }}
                            >
                                <DeleteOutlined />
                            </Button>
                        </DivTableActionInline>
                    ),
                };
            },
        },
    ];
};

const historyColumns: ColumnsType<DeclarationHistory> = [
    {
        title: 'Type',
        dataIndex: DeclarationSortParameters.MESSAGE_TYPE,
        key: DeclarationSortParameters.MESSAGE_TYPE,
        render: (text: string, record: DeclarationHistory, index: number) => {
            return record ? (
                {
                    props: getDeclarationStyle(record),
                    children: <SpanEllipsis>{toTitleCase(record.type)}</SpanEllipsis>,
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
        align: 'left',
    },
    {
        title: 'Date',
        dataIndex: DeclarationSortParameters.MESSAGE_TYPE,
        key: DeclarationSortParameters.MESSAGE_TYPE,
        render: (text: string, record: DeclarationHistory, index: number) => {
            const date = record.createdAt ? new Date(record.createdAt) : null;
            return date ? (
                {
                    props: getDeclarationStyle(record),
                    children: (
                        <FlexDiv>
                            <span>{getHour(date)}</span>
                            <span>{dateStandardFormat(date)}</span>
                        </FlexDiv>
                    ),
                }
            ) : (
                <SpanEllipsis>N/A</SpanEllipsis>
            );
        },
        ellipsis: true,
    },
];

export const declarationColums = (handleDuplicate?: Function, handleDelete?: Function, handlePrint?: Function) => {
    return handleDuplicate || handleDelete || handlePrint
        ? [...dataColumns, ...getActionsColums(handleDuplicate, handleDelete, handlePrint)]
        : [...dataColumns];
};

export const declarationHistoryColums = () => {
    return [...historyColumns];
};

export const declarationColumsArchived = (
    handleCoreTemplate?: Function,
    setAsTemplate?: Function,
    viewDocuments?: Function
) => {
    const cols = dataColumns.filter((col) => col.title !== 'Decl. Status');
    const archivedActions = getArchivedActions(handleCoreTemplate, setAsTemplate, viewDocuments);
    return [...cols, ...archivedActions];
};

export const declarationColumnsCoreTemplate = (
    handleCoreTemplate?: Function,
    setAsTemplate?: Function,
    viewDocuments?: Function
) => {
    const cols = dataColumns.filter((col) => col.title !== 'Decl. Status');
    const coreTemplateActions = getCoreTemplateActions(handleCoreTemplate, setAsTemplate, viewDocuments);
    return [...cols, ...coreTemplateActions];
};

export default declarationColums;
