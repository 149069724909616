import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import useTooltips from 'hooks/useTooltips';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../cards/NewFormCard';
import { getBox44FieldPath, h1Box44KeyNames, h1PathBox44, handleBox44Field } from './box-44-utils';

const TransportDocumentInformationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { declarationType, viewOnly } = props;
    const { getH7TooltipsByRefNumberAndField, getH1TooltipsByRefNumberAndField } = useTooltips();
    const { template, form, templateFormik } = useTemplateContext();

    const getTooltip = useCallback(
        (sadBox: string, label: string) => {
            if (declarationType === DeclarationName.H7) {
                return getH7TooltipsByRefNumberAndField(sadBox, label);
            } else {
                return getH1TooltipsByRefNumberAndField(sadBox, label);
            }
        },
        [declarationType, getH7TooltipsByRefNumberAndField, getH1TooltipsByRefNumberAndField]
    );

    return (
        <NewFormCard title={t('transportDocumentInformation')}>
            <FormCardContainer>
                <FormInput
                    numbersAndLetters
                    viewOnly={viewOnly}
                    maxLength={255}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: 'N740' }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h1PathBox44,
                                keyNames: h1Box44KeyNames,
                                type: 'N740',
                            },
                            { template, form }
                        )
                    }
                    refNumber="N740"
                    label={t('airwayBill')}
                    tooltip={t('defaultTooltip')}
                    testId="airwayBill"
                    condensed
                />

                <FormInput
                    numbersAndLetters
                    viewOnly={viewOnly}
                    maxLength={255}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: 'N741' }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h1PathBox44,
                                keyNames: h1Box44KeyNames,
                                type: 'N741',
                            },
                            { template, form }
                        )
                    }
                    refNumber="N741"
                    label={t('masterAirwayBill')}
                    tooltip={getTooltip('N741', t('masterAirwayBill'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    testId="masterAirwayBill"
                    condensed
                />

                <FormInput
                    numbersAndLetters
                    viewOnly={viewOnly}
                    maxLength={255}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: 'N703' }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h1PathBox44,
                                keyNames: h1Box44KeyNames,
                                type: 'N703',
                            },
                            { template, form }
                        )
                    }
                    refNumber="N703"
                    label={t('houseWayBill')}
                    tooltip={getTooltip('N703', t('houseWayBill'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    testId="houseWayBill"
                    condensed
                />

                <FormInput
                    numbersAndLetters
                    viewOnly={viewOnly}
                    maxLength={255}
                    testId="billOfLading"
                    {...getFormikProps(
                        getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: 'N705' }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h1PathBox44,
                                keyNames: h1Box44KeyNames,
                                type: 'N705',
                            },
                            { template, form }
                        )
                    }
                    refNumber="N705"
                    label={t('billOfLading')}
                    tooltip={getTooltip('N705', t('billOfLading'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormInput
                    numbersAndLetters
                    viewOnly={viewOnly}
                    maxLength={255}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: 'N730' }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h1PathBox44,
                                keyNames: h1Box44KeyNames,
                                type: 'N730',
                            },
                            { template, form }
                        )
                    }
                    refNumber="N730"
                    testId="roadConsignmentNote"
                    label={t('roadConsignmentNote')}
                    tooltip={getTooltip('N730', t('roadConsignmentNote'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormInput
                    numbersAndLetters
                    viewOnly={viewOnly}
                    maxLength={255}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: 'N704' }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h1PathBox44,
                                keyNames: h1Box44KeyNames,
                                type: 'N704',
                            },
                            { template, form }
                        )
                    }
                    refNumber="N704"
                    label={t('masterBillOfLading')}
                    tooltip={getTooltip('N704', t('masterBillOfLading'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    testId="masterBillOfLading"
                    condensed
                />

                <FormInput
                    numbersAndLetters
                    viewOnly={viewOnly}
                    maxLength={255}
                    {...getFormikProps(
                        getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: 'N714' }),
                        props
                    )}
                    onChange={(e) =>
                        handleBox44Field(
                            e.target.value,
                            template && templateFormik ? templateFormik : props,
                            {
                                path: h1PathBox44,
                                keyNames: h1Box44KeyNames,
                                type: 'N714',
                            },
                            { template, form }
                        )
                    }
                    refNumber="N714"
                    label={t('houseBillOfLading')}
                    testId="houseBillOfLading"
                    tooltip={getTooltip('N714', t('houseBillOfLading'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default TransportDocumentInformationCard;
