import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { supervisingCustomsOffice } from 'store/declarations/enums/uk/supervising-customs-office';
import { ValidationSchema, validators } from '../validations/validations';

type Fields = 'id';

export type SupervisingCustomsOfficeBlockValidation = ValidationSchema<Fields>;
export const supervisingCustomsOfficeBlockValidation: SupervisingCustomsOfficeBlockValidation = {
    childValidators: {
        id: [validators.exact(8)],
    },
};

interface Props extends BlockProps<Fields> {}

const SupervisingCustomsOfficeBlock = ({ path = 'supervisingOffice', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '5/27', []);
    return (
        <DeclarationField pathPrefix={path} name="id">
            {({ form, field }: FieldProps<any>) => (
                <FormSelect
                    required={fieldOptions?.id?.required}
                    refNumber={ucc}
                    label={t('suppervisingCustomsOffice')}
                    {...getFormikProps(field.name, form)}
                    selectOptions={supervisingCustomsOffice}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default SupervisingCustomsOfficeBlock;
