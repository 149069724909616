import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { appendFieldPath, getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const AcceptanceDateCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByField } = useTooltips();

    const fullFieldPath = appendFieldPath(props.propsPathPrefix);
    const formikProps = getFormikProps('acceptanceDate', props);

    return (
        <NewFormCard title={t('acceptanceDate')}>
            <FormCardContainer>
                <DeclarationDatepicker
                    viewOnly={props.viewOnly}
                    {...formikProps}
                    fieldProps={props.getFieldProps(fullFieldPath('acceptanceDate'))}
                    label={t('acceptanceDate')}
                    tooltip={getCdsTooltipsByField(t('acceptanceDate'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default AcceptanceDateCard;
