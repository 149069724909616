import { TestProps } from 'core/utils/testTypes';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useCurrencies from 'hooks/useCurrencies';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCurrenciesToSelect } from '../selects/utils';
import FormSelect from './formSelect/DeclarationSelect';
import { SelectProps } from 'antd';

export interface CurrencyProps extends TestProps, SelectProps {
    refNumber?: string;
    label?: string;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    required?: boolean;
    viewOnly?: boolean;
    disabled?: boolean;
    tooltip?: string;
    refClicked?: (refNumber: string | number) => void;
    condensed?: boolean;
}

const CurrencySelect: FC<CurrencyProps> = ({
    fieldProps,
    refNumber,
    label,
    fieldMeta,
    required,
    fieldHelper,
    viewOnly,
    disabled,
    tooltip,
    refClicked,
    testId,
    condensed,
    ...selectProps
}) => {
    const { t } = useTranslation('form');
    const { currencies } = useCurrencies();

    const currenciesList = useMemo(() => normalizeCurrenciesToSelect(currencies.list), [currencies.list]);

    return (
        <>
            {currencies && (
                <FormSelect
                    disabled={disabled}
                    viewOnly={viewOnly}
                    required={required}
                    refNumber={refNumber}
                    label={t(label || 'currency')}
                    selectOptions={currenciesList}
                    fieldProps={fieldProps}
                    fieldMeta={fieldMeta}
                    fieldHelper={fieldHelper}
                    refClicked={refClicked}
                    tooltip={tooltip}
                    testId={testId}
                    condensed={condensed}
                    {...selectProps}
                />
            )}
        </>
    );
};

export default CurrencySelect;
