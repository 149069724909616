import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { incotermCodeLabels } from 'store/declarations/enums/ireland/incoterm-code';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';

const DeliveryTermsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getIrelandExportTooltipsByRefNumber } = useTooltips();

    const handleToggleHelp = (refNumber: string | number): void => {
        props.toggleHelp && props.toggleHelp(refNumber);
    };

    const locationsCodes = useMemo(
        () => normalizeCodesToSelect(codelists?.irelandDeliveryDestinationLocationCodes || []),
        [codelists?.irelandDeliveryDestinationLocationCodes]
    );

    return (
        <section
            id="delivery-terms-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('delivery-terms-form-card');
                }
            }}
        >
            <FormCard
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('deliveryTerms')}
                cardNumber={props.cardNumber}
                expandAll={props.expandAll}
                keyCard={props.keyCard}
                total={props.cardTotal}
                state={getCardState(
                    [
                        { name: `incotermCode`, required: false },
                        { name: `deliveryDestination`, required: false },
                        { name: `deliveryDestinationLocationCode`, required: false },
                    ],
                    props
                )}
            >
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`incotermCode`, props)}
                    refNumber="20.1"
                    tooltip={getIrelandExportTooltipsByRefNumber('20.1')}
                    label={t('delivery.incotermCode')}
                    refClicked={handleToggleHelp}
                    selectOptions={incotermCodeLabels}
                />

                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`deliveryDestination`, props)}
                    refNumber="20.3"
                    tooltip={getIrelandExportTooltipsByRefNumber('20.2')}
                    refClicked={handleToggleHelp}
                    label={t('delivery.deliveryDestination')}
                />

                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`deliveryDestinationLocationCode`, props)}
                    refClicked={handleToggleHelp}
                    refNumber="20.2"
                    tooltip={getIrelandExportTooltipsByRefNumber('20.3')}
                    label={t('delivery.deliveryDestinationLocationCode')}
                    selectOptions={locationsCodes}
                />
            </FormCard>
        </section>
    );
};

export default DeliveryTermsCard;
