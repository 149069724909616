import { ErrorResponse } from 'core/http/response';
import { Reducer } from 'redux';
import { CodelistsActions } from './actionType';
import { Code } from './code';

export type ukCodelistType =
    | 'ukAirports'
    | 'ukAllowedAdditionalDocuments'
    | 'ukContainerCategories'
    | 'ukCountryGroupCategories'
    | 'ukDeclaredGuaranteeTypes'
    | 'ukDocumentCategories'
    | 'ukDutyTaxFeeTypes'
    | 'ukExitCustomsOffices'
    | 'ukExportCertificates'
    | 'ukExportPreviousDocuments'
    | 'ukExportSpecialMentions'
    | 'ukImportCertificates'
    | 'ukImportPreviousDocuments'
    | 'ukImportPreviousProcedures'
    | 'ukImportRequestedProcedures'
    | 'ukImportSpecialMentions'
    | 'ukPackageTypes'
    | 'ukPartyRoleStatusTypes'
    | 'ukPaymentMethodTypes'
    | 'ukPreferences'
    | 'ukProcedureCombinations'
    | 'ukSpecialProcedureTypes'
    | 'ukSupervisingCustomsOffices'
    | 'ukTransactionNatureTypes'
    | 'ukTransportMeansIdentificationTypes'
    | 'ukTransportModeTypes'
    | 'ukUnlocodes'
    | 'ukValuationIndicatorTypes'
    | 'ukValuationMethodTypes'
    //
    | 'ukDeferredPaymentDocuments';

export type irelandCodelistType =
    | 'irelandAdditionalInformationCodes'
    | 'irelandAdditionalProcedures'
    | 'irelandAdditionsDeductionsCodes'
    | 'irelandAuthorisationTypeCodes'
    | 'irelandBaseMeasurements'
    | 'irelandCommonDocumentsTypes'
    | 'irelandDeliveryDestinationLocationCodes'
    | 'irelandExportProcedureCategories'
    | 'irelandGuaranteeTypes'
    | 'irelandIdentificationOfGoodsCodes'
    | 'irelandIrishPreferences'
    | 'irelandLocationOfGoodsCodes'
    | 'irelandMeansIdentityTypes'
    | 'irelandNationalCustomsOfficeCodes'
    | 'irelandNatureOfTransactions'
    | 'irelandOfficesOfExit'
    | 'irelandPackageKinds'
    | 'irelandPreviousDocumentTypes'
    | 'irelandProcedureCodes'
    | 'irelandProcessingProcedureCodes'
    | 'irelandRepresentativeStatusCodes'
    | 'irelandTaricNationalAdditionalCodes'
    | 'irelandTaxTypes'
    | 'irelandTransportModes'
    | 'irelandValuationMethods';

export type CodelistType = irelandCodelistType | ukCodelistType;
export interface CodelistsState {
    isLoading: boolean;
    error?: ErrorResponse;
    entities?: Record<CodelistType, Code[]>;
}

export const codelistsInitialState: Readonly<CodelistsState> = {
    isLoading: false,
};

const codelists: Reducer<CodelistsState, CodelistsActions> = (
    state = codelistsInitialState,
    action: CodelistsActions
) => {
    switch (action.type) {
        case 'GET_CODELISTS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_CODELISTS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: action.payload,
            };
        case 'GET_CODELISTS_ERROR':
            return {
                ...state,
                isLoading: false,
                entities: state.entities,
                error: action.error,
            };
        case 'GET_ALL_CODELISTS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_ALL_CODELISTS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: action.payload,
            };
        case 'GET_ALL_CODELISTS_ERROR':
            return {
                ...state,
                isLoading: false,
                entities: state.entities,
                error: action.error,
            };
        default:
            return state;
    }
};

export default codelists;
