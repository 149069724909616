import { Checkbox, Modal, ModalProps } from 'antd';
import { H5 } from 'components/ui/base/typography';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { getFieldTemplateMetaData } from 'components/ui/composed/template/useTemplateViewEditControls';
import { useFormikContext } from 'formik';
import useDeclarations from 'hooks/useDeclarations';
import { cloneDeep, isEqual } from 'lodash';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Code } from 'store/codelists/code';
import { MetaData } from 'store/template/template';

export interface Field {
    name: string;
    label: string;
    codeList?: Code[];
}

interface Props extends Omit<ModalProps, 'onOk' | 'onCancel'> {
    value: string | null;
    prevValue: string | null;
    fields: Field[];
    onOk: (fields: string[]) => void;
    onCancel: () => void;
}
const AutoFillModal = ({
    value,
    prevValue,
    fields: fieldsProp,
    onOk,
    visible: visibleProp,
    ...modalProps
}: Props): ReactElement => {
    const mainFormik = useFormikContext();
    const { form, template, templateFormik } = useTemplateContext();
    const { declarationTemplate } = useDeclarations();

    const [fields, setFields] = useState(fieldsProp);
    const [visible, setVisible] = useState(false);

    const formik = useMemo(() => {
        if (template) return templateFormik;
        return mainFormik;
    }, [mainFormik, template, templateFormik]);

    const [checked, setChecked] = useState<Record<string, boolean>>({});

    useEffect(() => {
        let _fields = cloneDeep(fieldsProp);

        if (template) {
            _fields = _fields
                .filter((field) => {
                    const fieldMeta = templateFormik?.getFieldProps(`${form}.meta.['${field.name}']`).value as
                        | MetaData
                        | undefined;
                    return fieldMeta ? fieldMeta?.isEditable || fieldMeta?.isViewable : true;
                })
                .map((field) => ({ ...field, name: `${form}.defaults.${field.name}` }));
        } else {
            if (declarationTemplate?.template) {
                _fields = _fields.filter((field) => {
                    const { editable } = getFieldTemplateMetaData(field.name, form, declarationTemplate?.template);
                    return editable;
                });
            }
        }
        setFields(_fields);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declarationTemplate?.template, fieldsProp, form, prevValue, template, templateFormik?.getFieldProps, value]);

    useEffect(() => {
        setChecked(fields.reduce((acc, { name }) => ({ ...acc, [name]: true }), {} as Record<string, boolean>));
    }, [fields]);

    useEffect(() => {
        if (visible !== visibleProp) setVisible(Boolean(visibleProp && fields.length));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleProp, fields]);

    /**
     * Disabled is a list of fields that the value
     * is not present in their code list.
     */
    const disabled: string[] = useMemo(() => {
        return fields.reduce((acc, field) => {
            if (
                value != null && value !== ''
                    ? field.codeList?.findIndex(({ code }) => value === code) === -1
                    : formik?.getFieldProps(field.name).value !== prevValue
            ) {
                acc.push(field.name);
            }
            return acc;
        }, [] as string[]);
    }, [fields, formik, prevValue, value]);

    /**
     * Handle disabled fields.
     * If a field is disabled, uncheck it.
     * If the fields is not disabled, check it.
     */
    useEffect(() => {
        setChecked((prev) =>
            Object.entries(prev).reduce((acc, [key]) => ({ ...acc, [key]: !disabled.includes(key) }), {})
        );
    }, [disabled]);

    useEffect(() => {
        const handleModal = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                handleOk();
            } else if (e.key === 'Escape') {
                modalProps.onCancel?.();
            }
        };

        if (visible) {
            document?.addEventListener('keydown', handleModal);
        }

        return () => {
            document?.removeEventListener('keydown', handleModal);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const toggleCheckbox = (name: string, checked: boolean) => {
        setChecked((prev) => ({ ...prev, [name]: checked }));
    };

    const fieldsRender = useMemo(() => {
        return fields.map((field) => (
            <div style={{ display: 'flex', gap: '2rem' }}>
                <Checkbox
                    onChange={(e) => toggleCheckbox(field.name, e.target.checked)}
                    checked={checked[field.name]}
                    disabled={disabled.includes(field.name)}
                >
                    {field.label}
                </Checkbox>
            </div>
        ));
    }, [checked, disabled, fields]);

    const handleOk = () => {
        onOk(
            Object.entries(checked)
                .filter(([key, value]) => value)
                .map(([key]) => key)
        );
        modalProps.onCancel?.();
    };

    const title = useMemo(() => {
        if (
            isEqual(
                disabled,
                fields.map(({ name }) => name)
            )
        )
            return 'Cannot autofill related fields';
        if (!value) return 'Do you want to remove the values from the following fields';
        return `Do you want to autofill the following fields with the value: '${value}'`;
    }, [disabled, fields, value]);

    return (
        <Modal
            {...modalProps}
            visible={visible}
            onOk={handleOk}
            okButtonProps={{ id: 'autofill-ok' }}
            cancelButtonProps={{ id: 'autofill-cancel' }}
        >
            <H5>{title}</H5>
            <div style={{ marginTop: '2rem' }}>{fieldsRender}</div>
        </Modal>
    );
};

export default AutoFillModal;
