import { Form } from 'antd';
import RecaptchaV2 from 'components/RecaptchaV2';
import Button from 'components/ui/base/button/Button';
import Checkbox from 'components/ui/base/checkbox/Checkbox';
import { TextSmall } from 'components/ui/base/typography';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormPassword from 'components/ui/composed/formPassword/FormPassword';
import { useFormik } from 'formik';
import useSession from 'hooks/useSession';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IndividualType } from 'store/individuals/individual';
import { LoginCredentials } from 'store/session/user';
import * as Yup from 'yup';
import {
    ForgetRemDiv,
    ForgotPassword,
    FormItemButtons,
    LegalInfoDiv,
    PrivacyPolicyLink,
    SignUpButton,
    SignUpDiv,
    StyledDiv,
    StyledItem,
    TermsAndConditionsLink,
    Title,
} from './SignInForm.styles';

const formValidation = Yup.object().shape({
    email: Yup.string().email('Email is invalid.').required('Email is missing.'),
    password: Yup.string().required('Password is missing.'),
});

const SignInForm: FC = () => {
    const { t } = useTranslation('login');
    const { signIn, isLoading } = useSession();
    const navigate = useNavigate();
    const [showRecaptchaCheckbox] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember: false,
        },
        validateOnMount: true,
        validationSchema: formValidation,
        enableReinitialize: true,
        onSubmit: (values) => handleSubmit(values),
    });

    useEffect(() => {
        const checkIfClickedEnterKey = (event: any) => {
            if (event.code === 'Enter') {
                formik.handleSubmit();
            }
        };
        document.addEventListener('keydown', checkIfClickedEnterKey);
        return () => {
            document.removeEventListener('keydown', checkIfClickedEnterKey);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (credentials: LoginCredentials) => {
        // TODO: Uncomment this when recaptcha is ready
        // window.grecaptcha.ready(async () => {
        //     let token = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3, {
        //         action: 'signin',
        //     });

        //     // implement backend validation for recaptcha v3 here
        //     // use v2 as a fallback if v3 response doesn't work out
        //     if (showRecaptchaCheckbox === false) {
        //         setShowRecaptchaCheckbox(true);
        //         return;
        //     }
        //     token = window.grecaptcha.getResponse();

        //     const session = await signIn({ ...credentials, recaptcha: token });
        //     if (session.user.role === IndividualType.DRIVER) {
        //         navigate('/mobile/home');
        //     } else {
        //         navigate('/home');
        //     }
        // });

        // TODO: Remove this when recaptcha is ready
        const session = await signIn(credentials);
        if (session.user.role === IndividualType.DRIVER) {
            navigate('/mobile/home');
        } else {
            navigate('/home');
        }
    };

    return (
        <>
            <Title>{t('loginTitle')}</Title>
            <StyledDiv>
                <Form>
                    <FormInput
                        autoFocus
                        label={t('emailLabel')}
                        fieldProps={formik.getFieldProps('email')}
                        fieldMeta={formik.getFieldMeta('email')}
                    />
                    <FormPassword
                        icon
                        label={t('passwordLabel')}
                        fieldProps={formik.getFieldProps('password')}
                        fieldMeta={formik.getFieldMeta('password')}
                    />
                    <ForgetRemDiv>
                        <StyledItem name="remember" valuePropName="checked" noStyle>
                            <Checkbox
                                checked={formik.getFieldProps('remember').value as boolean}
                                onChange={() => {
                                    const values = { ...formik.values };
                                    values.remember = !formik.values.remember;
                                    formik.setValues(values);
                                }}
                            >
                                {t('rememberMe')}
                            </Checkbox>
                        </StyledItem>
                        <ForgotPassword type="link" onClick={() => navigate('/reset')}>
                            {t('forgotPassword')}
                        </ForgotPassword>
                    </ForgetRemDiv>
                    <LegalInfoDiv>
                        <TextSmall>Read our</TextSmall>
                        <TermsAndConditionsLink onClick={() => navigate('/terms-and-conditions')}>
                            Terms & Conditions
                        </TermsAndConditionsLink>
                        <TextSmall>and</TextSmall>
                        <PrivacyPolicyLink onClick={() => navigate('/privacy-policy')}>
                            Privacy Policy
                        </PrivacyPolicyLink>
                        <TextSmall>.</TextSmall>
                    </LegalInfoDiv>
                    {showRecaptchaCheckbox ? (
                        <>
                            <RecaptchaV2 />
                            <p>You may be a bot! Please check the checkbox.</p>
                        </>
                    ) : null}
                    <FormItemButtons>
                        <Button
                            loading={isLoading}
                            block
                            type="primary"
                            size="large"
                            onClick={() => formik.handleSubmit()}
                        >
                            {t('loginTitle')}
                        </Button>
                    </FormItemButtons>
                </Form>
                <SignUpDiv>
                    {t('newToDDispatch')}
                    <SignUpButton
                        type="link"
                        onClick={() => {
                            navigate('/signUp');
                        }}
                    >
                        {t('signUp')}
                    </SignUpButton>
                </SignUpDiv>
            </StyledDiv>
        </>
    );
};

export default SignInForm;
