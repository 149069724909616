import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import DeclarationNumberInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationNumberInput';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { ValidationSchema, validators } from '../validations/validations';

export type Fields = 'quantityQuantity';

export const numberOfPackagesBlockValidation: ValidationSchema<Fields> = {
    childValidators: {
        quantityQuantity: [validators.required(), validators.number(), validators.max(5)],
    },
};

interface Props extends BlockProps<Fields> {}

const NumberOfPackagesBlock = ({ path = 'packaging', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '6/10', []);

    return (
        <DeclarationField pathPrefix={path} name="quantityQuantity">
            {({ form, field }: FieldProps<any>) => (
                <DeclarationNumberInput
                    refNumber={ucc}
                    required
                    label={t('goodsItems.numberOfPackages')}
                    {...getFormikProps(field.name, form)}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default NumberOfPackagesBlock;
