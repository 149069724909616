import { FormikProps, FormikProvider } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import AdditionalInformationBlock, {
    additionalInformationBlockValidation,
    additionalInformationFields,
} from './blocks/AdditionalInformationBlock';
import PreviousDocumentsBlock, {
    previousDocumentsBlockValidation,
    previousDocumentsFields,
} from './blocks/PreviousDocumentsBlock';
import GuaranteeReferenceBlock, {
    guaranteeReferenceBlockValidation,
    guaranteeReferenceFields,
} from './blocks/GuaranteeReferenceBlock';
import { customerCardValidation } from './cards/CustomerCard';
import IdentificationOfWarehouseCard, {
    identificationOfWarehouseCardValidation,
} from './cards/IdentificationOfWarehouseCard';
import MasterDetailsCard, { masterDetailsCardValidation } from './cards/MasterDetailsCard';
import NatureOfTransactionCard, { natureOfTransactionCardValidation } from './cards/NatureOfTransactionCard';
import IdentityOfActiveMeansOfTransportCrossingTheBorderCard, {
    identityOfActiveMeansOfTransportCrossingTheBorderCardValidation,
} from './cards/IdentityOfActiveMeansOfTransportCrossingTheBorderCard';
import IdentityOfMeansOfTransportAtDepartureCard, {
    identityOfMeansOfTransportAtDepartureCardValidation,
} from './cards/IdentityOfMeansOfTransportAtDepartureCard';
import GrossMassCard, { grossMassCardValidation } from './cards/GrossMassCard';
import SupervisingCustomsOfficeCard, {
    supervisingCustomsOfficeCardValidation,
} from './cards/SupervisingCustomsOfficeCard';
import CustomsOfficesCard, { customsOfficesCardValidation } from './cards/CustomsOfficeCard';
import CountryOfDestinationCard, { countryOfDestinationCardValidation } from './cards/CountryOfDestinationCard';
import TotalAmountInvoicedCard, { totalAmountInvoicedCardValidation } from './cards/TotalAmountInvoicedCard';
import AdditionalFiscalReferencesBlock, {
    additionalFiscalReferencesBlockValidation,
    additionalFiscalReferencesFields,
} from './blocks/AdditionalFiscalReferencesBlock';
import HolderOfAuthorisationIdentificationNumberBlock, {
    holderOfAuthorisationIdentificationNumberBlockValidation,
    holderOfAuthorisationIdentificationNumberFields,
} from './blocks/HolderOfAuthorisationIdentificationNumberBlock';
import AdditionalSupplyChainActorBlock, {
    additionalSupplyChainActorBlockValidation,
    additionalSupplyChainActorFields,
} from './blocks/AdditionalSupplyChainActorBlock';
import ModeOfTransportCard, { modeOfTransportCardValidation } from './cards/ModeOfTransportCard';
import LocationOfGoodsCard, { locationOfGoodsCardValidation } from './cards/LocationOfGoodsCard';
import CountriesOfRoutingCodesBlock, {
    countriesOfRoutingCodesBlockValidation,
    countriesOfRoutingCodesFields,
} from './blocks/CountriesOfRoutingCodesBlock';
import { consigneeProductFields } from './blocks/CustomerBlock';
import TransportChargesMethodOfPaymentBlock, {
    consignmentItemsBlockValidation,
    consignmentItemsFields,
} from './blocks/TransportChargesMethodOfPaymentBlock';
import addPathPrefix from 'utils/addPathPrefix';
import { ValidationSchema } from './validations/validations';
import { cdsExportProductSectionValidation } from './UkExportProductsSection';
import { merge } from 'lodash';
import PartiesCard from 'views/declarations/common/parties/PartiesCard';
import PartiesAddressForm from 'views/declarations/common/parties/cds/PartiesAddressForm';
import PartiesEoriForm from 'views/declarations/common/parties/cds/PartiesEoriForm';
import { FormCardContainer } from '../../common/cards/NewFormCard';
import TransportEquipmentCard, { transportEquipmentCardValidation } from './cards/TransportEquipmentCard';
import { additionalInformationCodeHeader } from 'store/declarations/enums/uk/additional-information-code-header';
import DUCRMUCRCard, { ducrCardValidation } from './cards/DUCRMUCRCard';

// Multiple item cards
const holderOfAuthorisationIdentificationNumberCardValidation = {
    selfValidators: [holderOfAuthorisationIdentificationNumberBlockValidation],
};

const countriesOfRoutingCodesCardValidation = {
    selfValidators: [countriesOfRoutingCodesBlockValidation],
};

const guaranteeReferenceCardValidation = {
    selfValidators: [guaranteeReferenceBlockValidation],
};

const previousDocumentsCardValidation = {
    selfValidators: [previousDocumentsBlockValidation],
};

const additionalInformationCardValidation = {
    selfValidators: [additionalInformationBlockValidation],
};

const additionalSupplyChainActorCardValidation = {
    selfValidators: [additionalSupplyChainActorBlockValidation],
};

const additionalFiscalReferencesCardValidation = {
    selfValidators: [additionalFiscalReferencesBlockValidation],
};

const consignmentCodeCardValidation = {
    selfValidators: [consignmentItemsBlockValidation],
};

export const cdsExportFormValidation: ValidationSchema = {
    childValidators: {
        'goodsShipment.governmentAgencyGoodsItem': {
            selfValidators: [cdsExportProductSectionValidation],
            stopAtNull: true,
        },
    },
    selfValidators: [
        masterDetailsCardValidation,
        customerCardValidation,
        holderOfAuthorisationIdentificationNumberCardValidation,
        identificationOfWarehouseCardValidation,
        totalAmountInvoicedCardValidation,
        countryOfDestinationCardValidation,
        locationOfGoodsCardValidation,
        supervisingCustomsOfficeCardValidation,
        customsOfficesCardValidation,
        grossMassCardValidation,
        modeOfTransportCardValidation,
        identityOfMeansOfTransportAtDepartureCardValidation,
        identityOfActiveMeansOfTransportCrossingTheBorderCardValidation,
        countriesOfRoutingCodesCardValidation,
        guaranteeReferenceCardValidation,
        natureOfTransactionCardValidation,
        previousDocumentsCardValidation,
        additionalInformationCardValidation,
        additionalSupplyChainActorCardValidation,
        additionalFiscalReferencesCardValidation,
        transportEquipmentCardValidation,
        consignmentCodeCardValidation,
        ducrCardValidation,
    ],
};

interface Props {
    formik: FormikProps<any>;
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    expandAll?: boolean;
}

const UkExportForm: FC<Props> = ({ formik, toggleHelp, viewOnly, expandAll }) => {
    const { t } = useTranslation('form');

    const totalNumberOfCards = useMemo(() => 27, []);

    return (
        <FormikProvider value={formik}>
            <MasterDetailsCard
                keyCard={`master-details-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={1}
            />
            <PartiesCard
                formik={formik}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'exporter',
                        header: 'Exporter',
                        refNumber: '3/1',
                    },
                    {
                        path: 'goodsShipment.consignee',
                        header: 'Consignee',
                        refNumber: '3/9',
                    },
                    {
                        path: 'declarant',
                        header: 'Declarant',
                        refNumber: '3/17',
                        eoriRequired: true,
                    },
                    {
                        path: 'agent',
                        header: 'Representative',
                        refNumber: '3/19',
                        hasRepresentativeStatus: true,
                    },
                    {
                        path: 'consignment.carrier',
                        header: 'Carrier',
                        refNumber: '3/31',
                    },
                ]}
                paths={{
                    address: {
                        city: 'address.cityName',
                        country: 'address.countryCode',
                        name: 'name',
                        postCode: 'address.postcodeID',
                        streetAndNumber: 'address.line',
                    },
                    additional: {
                        representativeStatus: 'functionCode',
                    },
                    eori: 'id',
                }}
                PartiesAddressForm={PartiesAddressForm}
                PartiesEoriForm={PartiesEoriForm}
                viewOnly={viewOnly}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={2}
                keyCard={`parties-card`}
                expandAll={expandAll}
                condensed
            />
            <MultipleItemsCard
                title={t('Holder of the authorisation identification No.')}
                keyCard="uk-export-holder-of-authorisation"
                viewOnly={viewOnly}
                path="authorisationHolder"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={8}
                expandAll={expandAll}
                validate={holderOfAuthorisationIdentificationNumberFields}
                initialValues={{ id: '', categoryCode: '' }}
                list={(list) => [
                    { field: t('authorisationHolder.identificationNumber'), value: list.id },
                    { field: t('authorisationHolder.authorisationType'), value: list.categoryCode },
                ]}
                formik={formik}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <HolderOfAuthorisationIdentificationNumberBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <IdentificationOfWarehouseCard
                keyCard="identification-of-warehouse-card"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={9}
            />
            <TotalAmountInvoicedCard
                keyCard="uk-export-total-amount-invoiced"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={10}
            />
            <CountryOfDestinationCard
                keyCard="uk-export-country-of-destination"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={11}
            />
            <LocationOfGoodsCard
                keyCard="uk-export-location-of-goods"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={12}
            />
            <SupervisingCustomsOfficeCard
                keyCard={`uk-export-supervising-customs-office`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={13}
            />
            <CustomsOfficesCard
                keyCard="uk-export-customs-office"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={14}
            />
            <GrossMassCard
                keyCard="uk-export-grossmass"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={15}
            />
            <ModeOfTransportCard
                keyCard={`uk-export-mode-of-transport`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={16}
            />
            <IdentityOfMeansOfTransportAtDepartureCard
                keyCard={`uk-export-identity-of-means-of-transport-at-departure`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={17}
            />
            <IdentityOfActiveMeansOfTransportCrossingTheBorderCard
                keyCard={`uk-export-identity-of-active-means-of-transport-crossing-the-border`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={18}
            />
            <MultipleItemsCard
                title={t('countriesOfRoutingCodes')}
                keyCard="uk-export-countries-of-routing-codes"
                viewOnly={viewOnly}
                path="consignment.itinerary"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={19}
                expandAll={expandAll}
                validate={countriesOfRoutingCodesFields}
                initialValues={{ sequenceNumeric: '', routingCountryCode: '' }}
                list={(list) => [
                    {
                        field: t('sequenceNumeric'),
                        value: list.sequenceNumeric,
                    },
                    { field: t('routingCountryCode'), value: list.routingCountryCode },
                ]}
                formik={formik}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <CountriesOfRoutingCodesBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title={t('guaranteeReference.title')}
                keyCard="uk-export-guaranteeReference"
                viewOnly={viewOnly}
                path="obligationGuarantee"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={21}
                expandAll={expandAll}
                validate={guaranteeReferenceFields}
                initialValues={{
                    securityDetailsCode: '',
                    referenceId: '',
                    id: '',
                    accessCode: '',
                    amountAmount: '',
                    amountAmountCurrencyId: '',
                    guaranteeOffice: { id: '' },
                }}
                list={(list) => [
                    { field: t('guaranteeType'), value: list.securityDetailsCode },
                    { field: t('guaranteeReference.grn'), value: list.referenceId },
                    { field: t('guaranteeReference.otherGuaranteeReference'), value: list.id },
                    { field: t('guaranteeReference.accessCode'), value: list.accessCode },
                    { field: t('guaranteeReference.amountOfImportDuty'), value: list.amountAmount },
                    { field: t('guaranteeReference.amountOfImportDutyCurrency'), value: list.amountAmountCurrencyId },
                    { field: t('guaranteeReference.guaranteeOfficeID'), value: list.guaranteeOffice?.id },
                ]}
                formik={formik}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <GuaranteeReferenceBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <NatureOfTransactionCard
                propsPathPrefix="goodsShipment"
                viewOnly={viewOnly}
                keyCard={`nature-of-transaction`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={22}
            />

            <DUCRMUCRCard previousDocumentPath="goodsShipment.previousDocument" />

            <MultipleItemsCard
                title={t('previousDocuments')}
                keyCard="uk-export-previousDocuments"
                viewOnly={viewOnly}
                path="goodsShipment.previousDocument"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={23}
                expandAll={expandAll}
                validate={previousDocumentsFields}
                initialValues={{ categoryCode: '', typeCode: '', id: '', lineNumeric: '' }}
                list={(list) => [
                    { field: t('previousDocumentCategory'), value: list.categoryCode },
                    { field: t('previousDocumentType'), value: list.typeCode },
                    { field: t('previousDocumentReference'), value: list.id },
                    { field: t('previousDocumentCode'), value: list.lineNumeric },
                ]}
                formik={formik}
                required
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <PreviousDocumentsBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title={t('additionalInformation.title')}
                keyCard="uk-export-additionalInformation"
                viewOnly={viewOnly}
                path="additionalInformation"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={24}
                expandAll={expandAll}
                validate={additionalInformationFields}
                initialValues={{ statementCode: '', statementDescription: '' }}
                list={(list) => [
                    { field: t('additionalInformationCode'), value: list.statementCode },
                    { field: t('additionalInformationDescription'), value: list.statementDescription },
                ]}
                condensed
            >
                {(path) => (
                    <FormCardContainer oneColumn>
                        <AdditionalInformationBlock
                            path={path}
                            fieldOptions={{
                                statementCode: {
                                    options: additionalInformationCodeHeader,
                                },
                            }}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title={t('AdditionalSupplyChain')}
                keyCard="uk-export-additional-supply-chain-actor"
                viewOnly={viewOnly}
                path="goodsShipment.aeoMutualRecognitionParty"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={25}
                expandAll={expandAll}
                validate={additionalSupplyChainActorFields}
                initialValues={{ id: '', roleCode: '' }}
                list={(list) => [
                    { field: t('identificationNumber'), value: list.id },
                    { field: t('roleCode'), value: list.roleCode },
                ]}
                formik={formik}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalSupplyChainActorBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
            <MultipleItemsCard
                title={t('AddFiscalRefIdentification')}
                keyCard="uk-export-additional-references"
                viewOnly={viewOnly}
                path="goodsShipment.domesticDutyTaxParty"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={26}
                expandAll={expandAll}
                validate={additionalFiscalReferencesFields}
                initialValues={{ id: '', roleCode: '' }}
                list={(list) => [
                    { field: t('vatIdentificationNumber'), value: list.id },
                    { field: t('roleCode'), value: list.roleCode },
                ]}
                formik={formik}
                condensed
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalFiscalReferencesBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <TransportEquipmentCard
                propsPathPrefix="goodsShipment.consignment.transportEquipment"
                viewOnly={viewOnly}
                keyCard={`uk-export-container-identification-number`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={27}
            />
            <MultipleItemsCard
                title={t('consignmentItems')}
                keyCard="uk-export-container-identification-number"
                viewOnly={viewOnly}
                path="consignment.consignmentItem"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={27}
                expandAll={expandAll}
                validate={merge(consigneeProductFields, consignmentItemsFields)}
                initialValues={{ id: '' }}
                list={(list) => [{ field: t('consignmentItemNr') }]}
                formik={formik}
                condensed
            >
                {(path) => (
                    <FormCardContainer oneColumn>
                        <TransportChargesMethodOfPaymentBlock path={addPathPrefix(path, 'freight')} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>
        </FormikProvider>
    );
};
export default UkExportForm;
