import { PaginatedParams } from 'core/http/pagination';
import {
    createProductTemplate,
    createUkProductTemplate,
    deleteProduct,
    deleteUkProduct,
    editProductTemplate,
    editUkProductTemplate,
    getProduct,
    getUkProduct,
    listProducts,
    listH1Tags,
    listH7Tags,
    listUkProductsTemplates,
    createH7ProductTemplate,
    editH7ProductTemplate,
    listH7Products,
    getH7Product,
    deleteH7Product,
    createCdsExportProductTemplate,
    editCdsExportProductTemplate,
    listCdsExportTags,
    listCdsExportProducts,
    ListProductTemplateParams,
    listEnsProducts,
    createEnsProductTemplate,
    deleteGeneralizedProductTemplate,
    editEnsProductTemplate,
    getGeneralizedProductTemplate,
    listEnsTags,
} from './client';
import { ProductTemplate } from './products';
import { UkProductTemplate } from './ukProducts';
import {
    addTemplateType,
    ProductTemplateTypes,
    generalizeSingleNewProductTemplates,
    generalizeListNewProductTemplates,
} from '../../utils/productTemplateActionUtils';

export const doListH1Product: Function =
    (params?: Partial<ListProductTemplateParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_PRODUCTS_REQUEST' });
        try {
            const payload = addTemplateType(await listProducts(params), ProductTemplateTypes.H1);
            dispatch({
                type: 'LIST_PRODUCTS_REQUEST_SUCCESS',
                payload,
            });
        } catch (e: any) {
            dispatch({ type: 'LIST_PRODUCTS_REQUEST_ERROR', error: e?.response?.data });
        }
    };

export const doListH7Product: Function =
    (params?: Partial<ListProductTemplateParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_PRODUCTS_REQUEST' });
        try {
            const payload = addTemplateType(await listH7Products(params), ProductTemplateTypes.H7);

            dispatch({
                type: 'LIST_PRODUCTS_REQUEST_SUCCESS',
                payload,
            });
        } catch (e: any) {
            dispatch({ type: 'LIST_PRODUCTS_REQUEST_ERROR', error: e?.response?.data });
        }
    };

export const doListCdsExportProduct: Function = (params?: ListProductTemplateParams) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_PRODUCTS_REQUEST' });
    try {
        const payload = generalizeListNewProductTemplates(
            addTemplateType(await listCdsExportProducts(params), ProductTemplateTypes.B1)
        );
        dispatch({
            type: 'LIST_PRODUCTS_REQUEST_SUCCESS',
            payload,
        });
    } catch (e: any) {
        dispatch({ type: 'LIST_PRODUCTS_REQUEST_ERROR', error: e?.response?.data });
    }
};

export const doListEnsProducts: Function = (params?: ListProductTemplateParams) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_PRODUCTS_REQUEST' });
    try {
        const payload = generalizeListNewProductTemplates(
            addTemplateType(await listEnsProducts(params), ProductTemplateTypes.ENS)
        );
        dispatch({
            type: 'LIST_PRODUCTS_REQUEST_SUCCESS',
            payload,
        });
    } catch (e: any) {
        dispatch({ type: 'LIST_PRODUCTS_REQUEST_ERROR', error: e?.response?.data });
    }
};

export const doSaveH1Product: Function = (product: ProductTemplate) => async (dispatch: Function) => {
    dispatch({ type: 'SAVE_PRODUCT_TEMPLATE' });
    try {
        const payload = await createProductTemplate(product);
        dispatch({
            type: 'SAVE_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'SAVE_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
    }
};

export const doSaveH7Product: Function = (product: ProductTemplate) => async (dispatch: Function) => {
    dispatch({ type: 'SAVE_PRODUCT_TEMPLATE' });
    try {
        const payload = await createH7ProductTemplate(product);
        dispatch({
            type: 'SAVE_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'SAVE_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
    }
};

export const doSaveCdsExportProduct: Function = (product: ProductTemplate) => async (dispatch: Function) => {
    dispatch({ type: 'SAVE_PRODUCT_TEMPLATE' });
    try {
        const payload = generalizeSingleNewProductTemplates(await createCdsExportProductTemplate(product));
        dispatch({
            type: 'SAVE_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'SAVE_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
    }
};

export const doSaveEnsProduct: Function = (product: ProductTemplate) => async (dispatch: Function) => {
    dispatch({ type: 'SAVE_PRODUCT_TEMPLATE' });
    try {
        const payload = generalizeSingleNewProductTemplates(await createEnsProductTemplate(product));
        dispatch({
            type: 'SAVE_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'SAVE_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
    }
};

export const doUpdateH1Product: Function = (product: ProductTemplate) => async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_PRODUCT_TEMPLATE' });

    try {
        const payload = await editProductTemplate(product);
        dispatch({
            type: 'UPDATE_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'UPDATE_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
    }
};

export const doUpdateH7Product: Function = (product: ProductTemplate) => async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_PRODUCT_TEMPLATE' });

    try {
        const payload = await editH7ProductTemplate(product);
        dispatch({
            type: 'UPDATE_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'UPDATE_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
    }
};

export const doUpdateCdsExportProduct: Function =
    (product: { template: ProductTemplate }) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_PRODUCT_TEMPLATE' });

        try {
            const payload = generalizeSingleNewProductTemplates(await editCdsExportProductTemplate(product));

            dispatch({
                type: 'UPDATE_PRODUCT_TEMPLATE_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateEnsProduct: Function = (product: { template: ProductTemplate }) => async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_PRODUCT_TEMPLATE' });

    try {
        const payload = generalizeSingleNewProductTemplates(await editEnsProductTemplate(product));

        dispatch({
            type: 'UPDATE_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'UPDATE_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
    }
};

export const doGetH1Product: Function = (productId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_PRODUCT_TEMPLATE' });

    try {
        const payload = await getProduct(productId);
        dispatch({
            type: 'GET_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
    } catch (e: any) {
        dispatch({ type: 'GET_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
    }
};

export const doGetH7Product: Function = (productId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_PRODUCT_TEMPLATE' });

    try {
        const payload = await getH7Product(productId);
        dispatch({
            type: 'GET_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
    } catch (e: any) {
        dispatch({ type: 'GET_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
    }
};

export const doGetGeneralizedProductTemplate: Function = (productId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_PRODUCT_TEMPLATE' });

    try {
        const payload = generalizeSingleNewProductTemplates(await getGeneralizedProductTemplate(productId));

        dispatch({
            type: 'GET_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
    } catch (e: any) {
        dispatch({ type: 'GET_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
    }
};

export const doListH1Tags: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'LIST_PRODUCT_TEMPLATE_TAGS' });
    try {
        const payload = await listH1Tags();
        dispatch({
            type: 'LIST_PRODUCT_TEMPLATE_TAGS_SUCCESS',
            payload,
        });
    } catch (error: any) {
        dispatch({ type: 'LIST_PRODUCT_TEMPLATE_TAGS_ERROR', error: error?.response?.data });
    }
};

export const doListH7Tags: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'LIST_PRODUCT_TEMPLATE_TAGS' });
    try {
        const payload = await listH7Tags();
        dispatch({
            type: 'LIST_PRODUCT_TEMPLATE_TAGS_SUCCESS',
            payload,
        });
    } catch (error: any) {
        dispatch({ type: 'LIST_PRODUCT_TEMPLATE_TAGS_ERROR', error: error?.response?.data });
    }
};

export const doListCdsExportTags: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'LIST_PRODUCT_TEMPLATE_TAGS' });
    try {
        const payload = await listCdsExportTags();
        dispatch({
            type: 'LIST_PRODUCT_TEMPLATE_TAGS_SUCCESS',
            payload,
        });
    } catch (error: any) {
        dispatch({ type: 'LIST_PRODUCT_TEMPLATE_TAGS_ERROR', error: error?.response?.data });
    }
};

export const doListEnsTags: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'LIST_PRODUCT_TEMPLATE_TAGS' });
    try {
        const payload = await listEnsTags();
        dispatch({
            type: 'LIST_PRODUCT_TEMPLATE_TAGS_SUCCESS',
            payload,
        });
    } catch (error: any) {
        dispatch({ type: 'LIST_PRODUCT_TEMPLATE_TAGS_ERROR', error: error?.response?.data });
    }
};

export const doListUkProduct: Function = (params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_UK_PRODUCTS_REQUEST' });
    try {
        const payload = addTemplateType(await listUkProductsTemplates(params), ProductTemplateTypes.H1);
        dispatch({
            type: 'LIST_UK_PRODUCTS_REQUEST_SUCCESS',
            payload,
        });
    } catch (e: any) {
        dispatch({ type: 'LIST_UK_PRODUCTS_REQUEST_ERROR', error: e?.response?.data });
    }
};

export const doSaveUkProduct: Function = (product: UkProductTemplate) => async (dispatch: Function) => {
    dispatch({ type: 'SAVE_UK_PRODUCT_TEMPLATE' });

    try {
        const payload = await createUkProductTemplate(product);
        dispatch({
            type: 'SAVE_UK_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'SAVE_UK_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
    }
};

export const doUpdateUkProduct: Function = (product: UkProductTemplate) => async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_UK_PRODUCT_TEMPLATE' });

    try {
        const payload = await editUkProductTemplate(product);
        dispatch({
            type: 'UPDATE_UK_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'UPDATE_UK_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
    }
};

export const doGetUkProduct: Function = (productId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_UK_PRODUCT_TEMPLATE' });

    try {
        const payload = await getUkProduct(productId);
        dispatch({
            type: 'GET_UK_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
    } catch (e: any) {
        dispatch({ type: 'GET_UK_PRODUCT_TEMPLATE_ERROR', error: e?.response?.data });
    }
};
export const doDeleteH1ProductTemplate: Function = (id: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_PRODUCT_TEMPLATE_REQUEST' });
    try {
        const payload = await deleteProduct(id);
        dispatch({
            type: 'DELETE_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (error: any) {
        dispatch({ type: 'DELETE_PRODUCT_TEMPLATE_ERROR', error: error?.response?.data });
    }
};

export const doDeleteH7ProductTemplate: Function = (id: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_PRODUCT_TEMPLATE_REQUEST' });
    try {
        const payload = await deleteH7Product(id);
        dispatch({
            type: 'DELETE_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (error: any) {
        dispatch({ type: 'DELETE_PRODUCT_TEMPLATE_ERROR', error: error?.response?.data });
    }
};

export const doDeleteUkProductTemplate: Function = (id: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_UK_PRODUCT_TEMPLATE_REQUEST' });
    try {
        const payload = await deleteUkProduct(id);
        dispatch({
            type: 'DELETE_UK_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (error: any) {
        dispatch({ type: 'DELETE_UK_PRODUCT_TEMPLATE_ERROR', error: error?.response?.data });
    }
};

export const doDeleteGeneralizedProductTemplate: Function = (id: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_UK_PRODUCT_TEMPLATE_REQUEST' });
    try {
        const payload = await deleteGeneralizedProductTemplate(id);
        dispatch({
            type: 'DELETE_UK_PRODUCT_TEMPLATE_SUCCESS',
            payload,
        });
        return payload;
    } catch (error: any) {
        dispatch({ type: 'DELETE_UK_PRODUCT_TEMPLATE_ERROR', error: error?.response?.data });
    }
};
