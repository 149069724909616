import { FormikProvider, useFormik } from 'formik';
import { useMemo, FC, useEffect, useContext } from 'react';
import MultipleItemsCard from '../../../common/MultipleItemsCard';
import { useTranslation } from 'react-i18next';
import ItemFieldsCard from '../../export/cards/Products/ItemFieldsCard';
import ProcedureCard from '../../export/cards/Products/ProcedureCard';
import AdditionalInformationBlock from '../../export/blocks/AdditionalInformationBlock';
import AdditionalDocumentBlock from '../../export/blocks/AdditionalDocumentBlock';
import NatureOfTransactionCard from '../../export/cards/NatureOfTransactionCard';
import PackagingCard from '../../export/cards/Products/PackagingCard';
import PreviousDocumentsBlock from '../../export/blocks/PreviousDocumentsBlock';
import AdditionalSupplyChainActorBlock from '../../export/blocks/AdditionalSupplyChainActorBlock';
import AdditionalFiscalReferencesBlock from '../../export/blocks/AdditionalFiscalReferencesBlock';
import CountryOfOriginCard from '../cards/Products/CountryOfOriginCard';
import ContainerIdentificationNumberBlock from '../../export/blocks/ContainerIdentificationNumberBlock';
import PreferenceCard from '../cards/Products/PreferenceCard';
import { Row } from 'antd';
import CombinedNomenclatureCodeBlock from '../../export/blocks/CombinedNomenclatureCodeBlock';
import CustomerCard from '../../export/cards/CustomerCard';
import CalculationOfTaxesCard from '../cards/Products/CalculationOfTaxesCard';
import AdditionsDeductionsCard from '../cards/AdditionsDeductionsCard';
import ValuationIndicatorsCard from '../cards/Products/ValuationIndicatorsCard';
import ItemPriceCard from '../cards/Products/ItemPriceCard';
import ValuationMethodCard from '../cards/Products/ValuationMethodCard';
import ValueDeclaredCustomsValueCard from '../cards/Products/ValueDeclaredCustomsValueCard';
import QuotaOrderNumberCard from '../cards/Products/QuotaOrderNumberCard';
import { ProductContext } from 'views/declarations/common/declaration-view/DeclarationView';
import CdsExportDeclarationUtils from '../../export/utils';
import { CdsExportGovernmentAgencyGoodsItem } from 'store/declarations/uk/export-declaration';
import { useParams } from 'react-router-dom';
import useProducts from 'hooks/useProducts';
import { ValidationSchema } from '../../export/validations/validations';
import useDeclarations from 'hooks/useDeclarations';
import shouldHideEntity from '../../../../../utils/shouldHideEntity';
import { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';

export const cdsImportProductSectionValidation: ValidationSchema = {
    selfValidators: [],
};

interface Props {
    formik: ReturnType<typeof useFormik>; // FIXME use correct type
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    expandAll?: boolean;
    declarationInternalType?: string;
    cancelFetchProduct?: boolean;
}

const UkH2ProductsSection: FC<Props> = ({ formik, toggleHelp, viewOnly, expandAll, cancelFetchProduct = false }) => {
    const { t } = useTranslation('form');
    const { productId, declarationId } = useParams<string>();
    const { getUkImportProduct, product } = useProducts({ productId });
    const { setProductId } = useContext(ProductContext);
    const { declaration } = useDeclarations();

    const totalNumberOfCards = useMemo(() => 17, []);

    useEffect(() => {
        const fetchProduct = async (declarationId: string, productId: string) => {
            const fetchedProduct = await getUkImportProduct(declarationId, productId);
            setProductId?.(productId);
            formik.setValues(
                CdsExportDeclarationUtils.transformProductForClient(
                    (fetchedProduct as any)?.governmentAgencyGoodsItem as CdsExportGovernmentAgencyGoodsItem
                ) ?? {}
            );
        };

        if (declarationId && productId && productId !== 'new') {
            if (product?.id !== productId && !cancelFetchProduct) {
                fetchProduct(declarationId, productId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, declarationId, cancelFetchProduct]);

    return (
        <FormikProvider value={formik}>
            <MultipleItemsCard
                title={t('commodityCombinedNomenclatureCodeIdentifier')}
                keyCard="uk-import-taric-additional-code"
                viewOnly={viewOnly}
                path="commodity.classification"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={2}
                expandAll={expandAll}
                initialValues={{ id: '', identificationTypeCode: '' }}
                list={(list) => [
                    { field: t('commodityCode'), value: list.id },
                    { field: t('TypeCode'), value: list.identificationTypeCode },
                ]}
                required={true}
                formik={formik}
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <Row gutter={18}>
                        <CombinedNomenclatureCodeBlock path={path} />
                    </Row>
                )}
            </MultipleItemsCard>

            <ItemFieldsCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard={`item-fields-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={6}
                isUkCdsImport={true}
                fieldOptions={{
                    netNetWeightMeasure: { required: false, validation: { float: [16, 6] } },
                    grossMassMeasure: { validation: { float: [16, 6] } },
                    statisticalValueAmount: { validation: { float: [16, 2] } },
                }}
            />

            <ValuationIndicatorsCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="valuation-indicators"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={7}
            />

            <ValuationMethodCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="valuation-method"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={7}
            />

            <ItemPriceCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="item-price"
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={7}
            />

            <ProcedureCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                keyCard="procedure-card"
                formik={formik}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={7}
            />

            <CustomerCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="exporter-card"
                path="exporter"
                title={t('exporter')}
                ucc="3/1"
                viewOnly={viewOnly}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={2}
            />

            <CustomerCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="seller-card"
                path="goodsShipment.governmentAgencyGoodsItem.seller"
                title={t('seller')}
                ucc="3/24"
                fieldOptions={{ 'communication.id': { show: true } }}
                viewOnly={viewOnly}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={2}
            />

            <CustomerCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                keyCard="buyer-card"
                path="goodsShipment.governmentAgencyGoodsItem.buyer"
                title={t('buyer')}
                ucc="3/26"
                fieldOptions={{ 'communication.id': { show: true } }}
                viewOnly={viewOnly}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={2}
            />

            <MultipleItemsCard
                title={t('additionalInformation.title')}
                keyCard="uk-import-additionalInformation"
                viewOnly={viewOnly}
                path="additionalInformation"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={8}
                expandAll={expandAll}
                initialValues={{ statementCode: '', statementDescription: '' }}
                list={(list) => [
                    { field: t('additionalInformationCode'), value: list.statementCode },
                    { field: t('additionalInformationDescription'), value: list.statementDescription },
                ]}
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalInformationBlock
                            path={path}
                            fieldOptions={{ statementDescription: { validation: { max: 512 } } }}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title={t('additionalDocument.title')}
                keyCard="uk-import-additionalDocument"
                viewOnly={viewOnly}
                path="additionalDocument"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={9}
                expandAll={expandAll}
                initialValues={{
                    categoryCode: '',
                    typeCode: '',
                    id: '',
                    lpcoExemptionCode: '',
                    name: '',
                }}
                list={(list) => [
                    { field: t('documentsProduced.categoryCode'), value: list.categoryCode },
                    { field: t('documentsProduced.typeCode'), value: list.typeCode },
                    { field: t('documentsProduced.identifier'), value: list.id },
                    { field: t('documentsProduced.status'), value: list.lpcoExemptionCode },
                    { field: t('documentsProduced.reason'), value: list.name },
                ]}
                required={true}
                formik={formik}
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <AdditionalDocumentBlock
                        path={path}
                        hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                    />
                )}
            </MultipleItemsCard>

            <NatureOfTransactionCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                propsPathPrefix=""
                viewOnly={viewOnly}
                keyCard={`nature-of-transaction`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={10}
            />

            <PackagingCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                viewOnly={viewOnly}
                keyCard={`uk-import-packaging-card`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={11}
                formik={formik}
                fieldOptions={{
                    quantityQuantity: { validation: { max: 8 } },
                    marksNumbersID: { validation: { max: 512 } },
                }}
            />

            <MultipleItemsCard
                title={t('previousDocuments')}
                keyCard="uk-import-previousDocuments"
                viewOnly={viewOnly}
                path="previousDocument"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={12}
                expandAll={expandAll}
                initialValues={{ categoryCode: '', typeCode: '', id: '', lineNumeric: '' }}
                list={(list) => [
                    { field: t('previousDocumentCategory'), value: list.categoryCode },
                    { field: t('previousDocumentType'), value: list.typeCode },
                    { field: t('previousDocumentReference'), value: list.id },
                    { field: t('previousDocumentCode'), value: list.lineNumeric },
                ]}
                required={true}
                formik={formik}
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <FormCardContainer>
                        <PreviousDocumentsBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title={t('AdditionalSupplyChain')}
                keyCard="uk-import-additional-supply-chain-actor"
                viewOnly={viewOnly}
                path="aeoMutualRecognitionParty"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={totalNumberOfCards}
                cardNumber={13}
                expandAll={expandAll}
                initialValues={{ id: '', roleCode: '' }}
                list={(list) => [
                    { field: t('identificationNumber'), value: list.id },
                    { field: t('roleCode'), value: list.roleCode },
                ]}
                formik={formik}
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalSupplyChainActorBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title={t('AddFiscalRefIdentification')}
                keyCard="uk-import-additional-references"
                viewOnly={viewOnly}
                path="domesticDutyTaxParty"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={14}
                expandAll={expandAll}
                initialValues={{ id: '', roleCode: '' }}
                list={(list) => [
                    { field: t('vatIdentificationNumber'), value: list.id },
                    { field: t('roleCode'), value: list.roleCode },
                ]}
                formik={formik}
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <FormCardContainer>
                        <AdditionalFiscalReferencesBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <AdditionsDeductionsCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                propsPathPrefix="goodsShipment.governmentAgencyGoodsItem.customsValuation.chargeDeduction"
                viewOnly={viewOnly}
                keyCard={`uk-import-addition-deductions`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={16}
            />

            <ValueDeclaredCustomsValueCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                propsPathPrefix="goodsShipment.governmentAgencyGoodsItem"
                viewOnly={viewOnly}
                keyCard={`uk-import-value-declared-customs-value`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={16}
            />

            <QuotaOrderNumberCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                propsPathPrefix="goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee"
                viewOnly={viewOnly}
                keyCard={`uk-import-quota-order-number`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={16}
            />

            <CountryOfOriginCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                viewOnly={viewOnly}
                keyCard={`country-of-origin`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={15}
            />

            <MultipleItemsCard
                title={t('containerIdentificationNumbers')}
                keyCard="uk-import-container-identification-number"
                viewOnly={viewOnly}
                path="commodity.transportEquipment"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={16}
                expandAll={expandAll}
                initialValues={{ id: '' }}
                list={(list) => [{ field: t('containerIdentificationNumbers'), value: list.id }]}
                formik={formik}
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
            >
                {(path) => (
                    <FormCardContainer>
                        <ContainerIdentificationNumberBlock path={path} />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <PreferenceCard
                hide={() => shouldHideEntity([], declaration?.declarationInternalType!)}
                viewOnly={viewOnly}
                keyCard={`preference-card`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={17}
            />

            <CalculationOfTaxesCard
                hide={() => shouldHideEntity(['IMPORT_NEW'], declaration?.declarationInternalType!)}
                viewOnly={viewOnly}
                keyCard={`calculation-of-taxes-card`}
                expandAll={expandAll}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={totalNumberOfCards}
                cardNumber={17}
            />
        </FormikProvider>
    );
};

export default UkH2ProductsSection;
