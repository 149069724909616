export const calculationTaxesTaxBase = [
    { id: 'ASV', value: '' },
    { id: 'CCT', value: '' },
    { id: 'CEN', value: '' },
    { id: 'CTM', value: '' },
    { id: 'DAP', value: '' },
    { id: 'DHS', value: '' },
    { id: 'DTN', value: '' },
    { id: 'EUR', value: '' },
    { id: 'FC1', value: '' },
    { id: 'GBP', value: '' },
    { id: 'GFI', value: '' },
    { id: 'GP1', value: '' },
    { id: 'GRM', value: '' },
    { id: 'GRT', value: '' },
    { id: 'HLT', value: '' },
    { id: 'HMT', value: '' },
    { id: 'KAC', value: '' },
    { id: 'KCC', value: '' },
    { id: 'KCL', value: '' },
    { id: 'KGM', value: '' },
    { id: 'KLT', value: '' },
    { id: 'KMA', value: '' },
    { id: 'KMT', value: '' },
    { id: 'KNI', value: '' },
    { id: 'KNS', value: '' },
    { id: 'KPH', value: '' },
    { id: 'KPO', value: '' },
    { id: 'KPP', value: '' },
    { id: 'KSD', value: '' },
    { id: 'KSH', value: '' },
    { id: 'KUR', value: '' },
    { id: 'KW1', value: '' },
    { id: 'LHC', value: '' },
    { id: 'LPA', value: '' },
    { id: 'LTR', value: '' },
    { id: 'MIL', value: '' },
    { id: 'MTK', value: '' },
    { id: 'MTQ', value: '' },
    { id: 'MTR', value: '' },
    { id: 'MWH', value: '' },
    { id: 'NAR', value: '' },
    { id: 'NCL', value: '' },
    { id: 'NPR', value: '' },
    { id: 'P1', value: '' },
    { id: 'RET', value: '' },
    { id: 'TCE', value: '' },
    { id: 'TJO', value: '' },
    { id: 'TNE', value: '' },
    { id: 'MGM', value: '' },
    { id: 'MLT', value: '' },
    { id: 'MCG', value: '' },
    { id: 'MCL', value: '' },
    { id: 'WAT', value: '' },
];
