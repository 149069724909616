export const previousDocumentCategoryCodeList = [
    { id: 'Y', value: "Simplified declaration (regular use) or the entry in the declarant's records" },
    { id: 'Z', value: 'Previous document' },
] as const;

export const previousDocumentTypeCodeList = [
    { id: '235', value: 'Container List' },
    { id: '270', value: 'Delivery Note' },
    { id: '271', value: 'Packing List' },
    { id: '325', value: 'Proforma Invoice' },
    { id: '337', value: 'Temporary Storage Declaration' },
    { id: '355', value: 'Entry Summery Declaration (ENS)' },
    { id: '380', value: 'Commercial Invoice' },
    { id: '703', value: 'House Waybill' },
    { id: '704', value: 'Master Bill of Lading' },
    { id: '705', value: 'Bill of Lading' },
    { id: '714', value: 'House Bill of Lading' },
    { id: '720', value: 'Rail Consignment Note' },
    { id: '722', value: 'Road list SMGS' },
    { id: '730', value: 'Road Consignment Note' },
    { id: '740', value: 'Air Waybill' },
    { id: '741', value: 'Master Air Waybill' },
    { id: '750', value: 'Despatch Note (Post Parcels' },
    { id: '760', value: 'MultiModal/ Combined Transport Document' },
    { id: '785', value: 'Cargo Manifest' },
    { id: '787', value: 'Bordereau' },
    { id: '820', value: 'Union/ Common Transit Declaration — Mixed Consignments (T)' },
    { id: '821', value: 'External Union/ Common Transit Declaration (T1)' },
    { id: '822', value: 'Internal Union/ Common Transit Declaration (T2)' },
    { id: '825', value: 'Proof of the Customs Status of Union Goods T2L' },
    { id: '952', value: 'TIR Carnet' },
    { id: '955', value: 'ATA Carnet' },
    { id: 'CLE', value: "Reference/Date of Entry in the Declarant's Records (EIDR)*" },
    { id: 'IF3', value: 'Information Sheet INF3' },
    { id: 'MNS', value: 'Cargo Manifest — Simplified Procedure' },
    { id: 'MRN', value: 'Declaration/ Notification MRN (including reference no. of any EAD)' },
    { id: 'T2F', value: 'Internal Union Transit Declaration — Article 227 of the Code' },
    { id: 'T2G', value: 'Proof of the Customs Status of Union Goods T2LFg' },
    { id: 'T2M', value: 'T2M Proof' },
    { id: 'SDE', value: 'Simplified Declaration** for Simplified Declaration Procedure (SDP)' },
    { id: 'CSE', value: 'Customs Supervised Export' },
    {
        id: 'MCR',
        value: 'Master Unique Consignment Reference (MUCR) (Including any inventory reference where applicable)',
    },
    { id: 'DCR', value: 'Declaration Unique Consignment Reference (DUCR)' },
    { id: 'DCS', value: 'Declaration Unique Consignment Reference Part Number (DUCR Part)' },
    { id: 'ZZZ', value: 'Other' },
    { id: 'AAD', value: 'Administrative Accompanying Document' },
    { id: 'MOU', value: 'Memorandum of Understanding (approved exporters only)' },
    { id: 'CPD', value: 'Carnet de Passages en Douane(CPD)' },
] as const;
