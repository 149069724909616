import { TextSmall } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors, fonts } from 'theme';

interface ErrorProp {
    error: Boolean;
}

export const LabelDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0.3rem;
`;

export const RefDiv = styled.div`
    display: flex;
    justify-content: center;
    background: ${colors.darkGrey};
    margin-left: 0.8rem;
    line-height: 2rem;
    font-size: 1.2rem;
    font-weight: ${fonts.weight.semibold};
    color: ${colors.white};
    width: 4.1rem;
    border-radius: 0.4rem;
    align-items: center;
`;

export const InputLabel = styled.label`
    color: ${colors.darkBlack};
    font-weight: ${fonts.weight.semibold};
    font-size: 1.4rem;
    line-height: 2.2rem;
`;

export const InputLabelRequired = styled.label`
    color: ${colors.error};
    font-weight: ${fonts.weight.semibold};
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-left: 0.8rem;
`;

export const ErrorLabel = styled.label`
    color: ${colors.error};
    font-weight: ${fonts.weight.regular};
    font-size: 1.4rem;
    line-height: 2.2rem;
`;

export const ErrorDiv = styled.div<ErrorProp>`
    width: 100%;
    margin-bottom: 3.2rem; // TODO: Change this div
`;

export const ValueText = styled(TextSmall)`
    display: inline;
    white-space: nowrap;
`;
