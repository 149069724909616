import Fuse from 'fuse.js';
import React, { createRef, FC, useCallback, useEffect, useMemo } from 'react';
import { FilterResult, FilterType } from '../filter-result';
import DateFilter from './DateFilter';
import DeclarationStatusFilter from './DeclarationStatusFilter';
import DeclarationTypeFilter from './DeclarationTypeFilter';
import { FilterCard as Card, FilterHeader, Input } from './Filter.styles';
import FilterOptions from './FilterOptions';

interface FilterProps {
    options: string[];
    isDropdownActive: boolean;
    handleClose: Function;
    onFilter: (filter: FilterResult) => void;
    checked: string[];
    inputValue: string;
    setInputValue: Function;
    selectedFilter: string;
    setSelectedFilter: Function;
    filterOptions: string[];
    setFilterOptions: Function;
}
export const FilterCard: FC<FilterProps> = ({
    options,
    isDropdownActive,
    handleClose,
    onFilter,
    checked,
    inputValue,
    setInputValue,
    selectedFilter,
    setSelectedFilter,
    filterOptions,
    setFilterOptions,
}) => {
    const cardRef = createRef<HTMLDivElement>();

    useEffect(() => {
        const closeDropdown = (e: any) =>
            cardRef.current !== null && !cardRef.current.contains(e.target) && handleClose();

        if (isDropdownActive) window.addEventListener('click', closeDropdown);

        return () => window.removeEventListener('click', closeDropdown);
    }, [isDropdownActive, handleClose, cardRef]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setSelectedFilter('');
            setInputValue(value);
            if (value) {
                const options = { includeMatches: true };
                const fuse = new Fuse(filterOptions, options);
                const result = fuse.search(value);
                const filter = result.map((obj: any) => obj.matches[0].value);
                setFilterOptions(filter);
            } else {
                setFilterOptions(options);
            }
        },
        [filterOptions, setSelectedFilter, setInputValue, options, setFilterOptions]
    );

    const handleView = useMemo(() => {
        const handleSelect = (option: string) => {
            setInputValue(option);
            setSelectedFilter(option);
        };

        const handleDate = (date: string) => {
            onFilter({ type: FilterType.DATE, value: [date] });
        };

        const handleStatus = (status: string[]) => {
            onFilter({ type: FilterType.DECLARATION_STATUS, value: status });
        };

        const handleType = (type: string[]) => {
            onFilter({ type: FilterType.DECLARATION_TYPE, value: type });
        };

        if (!!!selectedFilter) {
            return <FilterOptions options={filterOptions} selectedOption={handleSelect} />;
        } else {
            switch (selectedFilter) {
                case FilterType.DATE:
                    return <DateFilter selectedDate={handleDate} />;
                case FilterType.DECLARATION_STATUS:
                    return <DeclarationStatusFilter selectedStatus={handleStatus} checked={checked} />;
                case FilterType.DECLARATION_TYPE:
                    return <DeclarationTypeFilter selectedType={handleType} checked={checked} />;
                default:
                    <></>;
            }
        }
    }, [selectedFilter, filterOptions, onFilter, checked, setInputValue, setSelectedFilter]);

    return (
        <>
            {isDropdownActive && (
                <Card ref={cardRef}>
                    <FilterHeader>
                        <Input autoFocus placeholder="Filter by ..." onChange={handleChange} value={inputValue} />
                    </FilterHeader>
                    {handleView}
                </Card>
            )}
        </>
    );
};

export default FilterCard;
