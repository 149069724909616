import Drawer from 'components/ui/base/drawer/Drawer';
import { FormikProps } from 'formik';
import { FC, FunctionComponent, useMemo, useState } from 'react';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import DeclarationErrorsWrapper from './declaration-errors/IrelandDeclarationErrorsWrapper';
import IrelandEnsDeclarationErrors from './declaration-errors/IrelandEnsDeclarationErrors';
import IrelandExportDeclarationErrors from './declaration-errors/IrelandExportDeclarationErrors';
import IrelandImportDeclarationErrors from './declaration-errors/IrelandImportDeclarationErrors';
import UkExportDeclarationErrors from './declaration-errors/UkExportDeclarationErrors';
import { IrelandNotification } from 'store/declarations/ireland/ireland-notification';
import { IrelandEnsNotification } from 'store/declarations/ireland/entry-summary-notification';
import { CdsExportNotification } from 'store/declarations/uk/export-declaration';
import { sortBy } from 'lodash';

interface Props {
    declaration: Declaration;
    visible: boolean;
    onClose: () => void;
    declarationType?: DeclarationName;
    formik?: FormikProps<any>;
}

const DeclarationErrorsDrawer: FC<Props> = ({ declaration, visible, onClose, declarationType, formik }) => {
    const [detailed, setDetailed] = useState<DeclarationInternalType | undefined>(undefined);

    const declarationErrors = useMemo(() => {
        const declarationType =
            declaration.irelandImportDeclaration ??
            declaration.irelandH7ImportDeclaration ??
            declaration.irelandExportDeclaration ??
            declaration.entrySummaryDeclaration ??
            declaration.cdsExportDeclaration;

        if (!declarationType?.notifications) {
            return <></>;
        }

        const lastNotificationIndex = declarationType.notifications.length - 1;

        const sortedNotifications = declarationType.notifications.some(
            (declarationNotification) => 'notificationDate' in declarationNotification
        )
            ? sortBy(declarationType.notifications as IrelandNotification[], 'notificationDate')
            : declarationType.notifications;

        const latestNotification = sortedNotifications?.[lastNotificationIndex];

        const errors =
            (latestNotification as IrelandNotification | IrelandEnsNotification | undefined)?.submissionErrors ?? // Ireland
            (latestNotification as CdsExportNotification | undefined)?.validationMessages; // CDS Export
        const additionalInformation = (latestNotification as CdsExportNotification | undefined)
            ?.additionalInformationMessages; // Cds Export

        const declarationErrorsProps: {
            type: DeclarationInternalType | undefined;
            DeclarationErrorsComponent: FunctionComponent<SpecificDeclarationErrorsProps<any, any, any>> | undefined;
        } = {
            type: undefined,
            DeclarationErrorsComponent: undefined,
        };

        switch (declarationType) {
            case declaration.irelandImportDeclaration:
            case declaration.irelandH7ImportDeclaration:
                declarationErrorsProps.type = DeclarationInternalType.IMPORT;
                declarationErrorsProps.DeclarationErrorsComponent = IrelandImportDeclarationErrors;
                break;

            case declaration.irelandExportDeclaration:
                declarationErrorsProps.type = DeclarationInternalType.EXPORT;
                declarationErrorsProps.DeclarationErrorsComponent = IrelandExportDeclarationErrors;
                break;

            case declaration.entrySummaryDeclaration:
                declarationErrorsProps.type = DeclarationInternalType.ENS;
                declarationErrorsProps.DeclarationErrorsComponent = IrelandEnsDeclarationErrors;
                break;

            case declaration.cdsExportDeclaration:
                declarationErrorsProps.type = DeclarationInternalType.EXPORT;
                declarationErrorsProps.DeclarationErrorsComponent = UkExportDeclarationErrors;
                break;

            default:
                return <></>;
        }

        return (
            <DeclarationErrorsWrapper
                errors={errors}
                notifications={sortedNotifications}
                additionalInformation={additionalInformation}
                formik={formik}
                isDetailed={detailed === declarationErrorsProps.type}
                onCompact={() => setDetailed(undefined)}
                onDetailed={() => setDetailed(declarationErrorsProps.type)}
                onClose={() => {
                    setDetailed(undefined);
                    onClose();
                }}
                DeclarationErrorsComponent={declarationErrorsProps.DeclarationErrorsComponent}
            />
        );
    }, [
        declaration.cdsExportDeclaration,
        declaration.entrySummaryDeclaration,
        declaration.irelandExportDeclaration,
        declaration.irelandH7ImportDeclaration,
        declaration.irelandImportDeclaration,
        detailed,
        formik,
        onClose,
    ]);

    return (
        <Drawer
            title="Declaration errors"
            visible={visible}
            onClose={() => {
                setDetailed(undefined);
                return onClose();
            }}
            width={820}
            warning={true}
        >
            {declarationErrors}
        </Drawer>
    );
};
export default DeclarationErrorsDrawer;
