import { Col, Row } from 'antd';
import Button from 'components/ui/base/button/Button';
import { H5 } from 'components/ui/base/typography';
import SelectTag from 'components/ui/composed/selectTag/SelectTag';
import { FormikProvider, useFormik } from 'formik';
import useProductsTemplates from 'hooks/useProductsTemplates';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { Tags } from 'views/declarations/Form.styles';
import { removeEmptyObjectsFromDeclarationArrays } from 'views/declarations/utils/form-utils';
import { StyledHeader, StyledLayout } from './Products.styles';
import { useTranslation } from 'react-i18next';
import useDeclarationNotifications from 'hooks/useDeclarationNotifications';
import { isEmpty } from 'lodash';
import { ProductTabKeys } from './ListProductTemplates';
import useGlobalOverlay from '../../../hooks/useGlobalOverlay';
import useProductTemplateFormErrors from '../../../hooks/useProductTemplateFormErrors';
import DeclarationFormTabContent from '../../declarations/common/declaration-form/DeclarationFormTabContent';
import { DeclarationInternalType } from '../../../store/declarations/enums/common/declaration-internal-type';
import {
    EditProductTemplateFunc,
    ListTagsFuncName,
    productTypeMapHelpers,
    SaveProductTemplateFunc,
} from './productTemplateMap';
import { Box44Context } from '../../declarations/common/box44/Box44';
import { useRequestPromise } from 'hooks/useRequest';
import axiosClient from 'config/axios';
import config from 'config';
import { TemplateResponse } from 'store/template/template';
import { getDeclarationKey } from 'views/declarations/utils/declaration-utils';
import declarationViewMap from 'views/declarations/utils/declarationViewMap';
import validate, { FormModel, transformErrorsForFormik } from 'views/declarations/uk/export/validations/validations';
import { DeclarationContextProvider } from 'utils/DeclarationContext';
import DeclarationInformation from 'views/declarations/common/DeclarationInformation';
import { ProductTemplateContext } from 'utils/ProductTemplateContext';

const AddEditProductTemplate: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const { showErrorNotification } = useDeclarationNotifications();
    const { setFormProductTemplateErrors, clearFormProductTemplateErrors } = useProductTemplateFormErrors();
    const { id, type, country, internalType } = useParams<{
        id: string;
        type: ProductTabKeys;
        country: DeclarationCountry;
        internalType: DeclarationInternalType;
    }>();
    const { productTemplate, tagsList, ...rest } = useProductsTemplates({
        productId: id,
        type,
        country,
    });

    const transform = useMemo(() => {
        const declarationInternalType = internalType;
        const declarationExternalEntity = (country === 'uk' ? 'CDS' : 'REVENUE') as any;
        const declarationKey = getDeclarationKey({
            declarationInternalType,
            declarationExternalEntity,
        });
        if (!declarationKey) return null;
        return declarationViewMap[declarationKey].transformData;
    }, [country, internalType]);

    const [searchParams] = useSearchParams();

    const declarationTemplateId = useMemo(
        () => searchParams.get('declarationTemplateId') ?? productTemplate?.declarationTemplateId,
        [productTemplate?.declarationTemplateId, searchParams]
    );

    const { data: declarationTemplate, refetch } = useRequestPromise<TemplateResponse | undefined>(
        async () => {
            if (!declarationTemplateId) return undefined;
            return axiosClient
                .get<{ payload: TemplateResponse }>(`${config.declarationTemplatesUrl}/${declarationTemplateId}`)
                .then((res) => res.data.payload);
        },
        { loadingMessage: 'Getting declaration template data' }
    );

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declarationTemplateId]);

    const [initialValues, setInitialValues] = useState<any>({});
    const [suggestedTags, setSuggestedTags] = useState<string[]>(tagsList);
    const [usedTags, setUsedTags] = useState<string[]>([]);

    const typeMap = useMemo(
        () => productTypeMapHelpers[`${country}_${type}` as keyof typeof productTypeMapHelpers],
        [type, country]
    );
    const addOrEditRequest = useMemo(
        () =>
            id
                ? (typeMap.editProductTemplateFuncName as EditProductTemplateFunc)
                : (typeMap.saveProductTemplateFuncName as SaveProductTemplateFunc),
        [id, typeMap]
    );

    const formikInitialValues = useMemo(() => {
        const _productTemplateData = declarationTemplate?.template?.product.defaults;
        const productTemplateData =
            country === 'uk' ? _productTemplateData?.governmentAgencyGoodsItem : _productTemplateData;
        if (!id) return transform?.product?.forClient?.(productTemplateData, true) ?? {};

        return initialValues;
    }, [declarationTemplate?.template?.product.defaults, country, id, transform?.product, initialValues]);

    useEffect(() => {
        rest[typeMap.listTagsFuncName as ListTagsFuncName]();
        clearFormProductTemplateErrors();

        return () => clearFormProductTemplateErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tagsList && id) {
            setSuggestedTags(Array.from(new Set([...tagsList, ...usedTags])));
        } else if (tagsList) {
            setSuggestedTags(tagsList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsList, id]);

    useEffect(() => {
        if (productTemplate && id) {
            const initialValues = transform ? transform?.product?.forClient?.(productTemplate, true) : productTemplate;

            setInitialValues(initialValues);
            setUsedTags(productTemplate.tags ?? []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productTemplate, id, transform]);

    const formik = useFormik({
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: typeMap.validationSchema,
        validate:
            typeMap.validate &&
            (async (values) => transformErrorsForFormik(await validate(new FormModel(values), typeMap.validate))),
        onSubmit: () => {},
    });

    const handleFormSubmit = useCallback(
        async () => {
            const formValues = removeEmptyObjectsFromDeclarationArrays({ ...formik.values });

            let addOrEditParams: any = productTemplate.id ? { ...formValues, id: productTemplate.id } : formValues;

            addOrEditParams = transform ? transform?.product?.forServer?.(addOrEditParams, true) : addOrEditParams;

            if (type === ProductTabKeys.B1) {
                addOrEditParams = { template: addOrEditParams.governmentAgencyGoodsItem } as any;
            } else if (type === ProductTabKeys.ENS) {
                addOrEditParams = { template: addOrEditParams };
            }

            if (declarationTemplateId) {
                addOrEditParams = { ...addOrEditParams, declarationTemplateId };
            }

            const response = await rest[addOrEditRequest]({ ...addOrEditParams, tags: usedTags });

            response && navigate(`/customs-declarations/${country}/${internalType}/products/${type}`);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik.values, usedTags, addOrEditRequest, navigate, country, internalType, type, transform]
    );

    const handleValidateForm = useCallback(async () => {
        const errors = await formik.validateForm();

        if (isEmpty(errors)) {
            clearFormProductTemplateErrors();
            return true;
        }

        formik.setTouched(errors as any);
        setFormProductTemplateErrors(typeMap.validationErrorsParser(errors));
        return false;
    }, [formik, typeMap, clearFormProductTemplateErrors, setFormProductTemplateErrors]);

    const box44FieldNamesAndPath = useMemo(
        () =>
            type === ProductTabKeys.H1
                ? {
                      documentTypeName: 'documentType',
                      documentIdentifierName: 'documentIdentifier',
                      path: 'producedDocumentsWritingOff',
                  }
                : {
                      documentTypeName: 'supportingDocumentType',
                      documentIdentifierName: 'supportingDocumentReferenceNumber',
                      path: 'documentsAuthorisations.supportingDocument',
                  },

        [type]
    );

    return (
        <FormikProvider value={formik}>
            <ProductTemplateContext.Provider value={{ declarationTemplate, inProductTemplate: true }}>
                <StyledLayout style={{ height: '100%' }}>
                    <StyledHeader style={{ height: 'auto' }}>
                        <Box44Context.Provider
                            value={{
                                documentTypeName: box44FieldNamesAndPath.documentTypeName,
                                documentIdentifierName: box44FieldNamesAndPath.documentIdentifierName,
                                path: box44FieldNamesAndPath.path,
                            }}
                        >
                            <Row gutter={10} style={{ marginBottom: '2.4rem' }} justify="space-between" align="top">
                                <Col>
                                    <H5> {`${id ? 'Edit' : 'Add'} ${type?.toUpperCase()} product`}</H5>
                                </Col>
                                <Col>
                                    <Button
                                        size="large"
                                        onClick={() => {
                                            navigate(
                                                `/customs-declarations/${country}/${internalType}/products/${type}`
                                            );
                                        }}
                                        style={{ marginRight: '1.6rem' }}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <Button
                                        size="large"
                                        onClick={async () => {
                                            showGlobalOverlay({
                                                type: 'LoadingOverlay',
                                            });

                                            const isValid = await handleValidateForm();

                                            if (!isValid) {
                                                showErrorNotification(
                                                    t('error.product_template_invalid_title'),
                                                    t('error.formRequired')
                                                );
                                                hideGlobalOverlay();
                                                return;
                                            }

                                            handleFormSubmit();

                                            hideGlobalOverlay();
                                        }}
                                    >
                                        {t('buttons.saveProduct')}
                                    </Button>
                                </Col>
                            </Row>
                            <Row gutter={10} style={{ marginBottom: '2.8rem' }} justify="space-between" align="middle">
                                <div style={{ marginRight: '2rem' }}>
                                    <Tags>
                                        <SelectTag
                                            usedTags={usedTags}
                                            suggestedTags={suggestedTags}
                                            onSuggestedTagsChange={(value: string[]) => setSuggestedTags(value)}
                                            onUsedTagsChange={(value: string[]) => setUsedTags(value)}
                                        />
                                    </Tags>
                                </div>
                                <DeclarationInformation
                                    options={{ visible: { template: true } }}
                                    hasBox44={country === DeclarationCountry.IRELAND}
                                />
                            </Row>
                        </Box44Context.Provider>
                    </StyledHeader>
                    <DeclarationContextProvider>
                        <DeclarationFormTabContent navigationItems={typeMap.sectionProducts}>
                            <typeMap.productSection formik={formik} productTemplate />
                        </DeclarationFormTabContent>
                    </DeclarationContextProvider>
                </StyledLayout>
            </ProductTemplateContext.Provider>
        </FormikProvider>
    );
};

export default AddEditProductTemplate;
