import {
    getCustomerValidation,
    getExactLengthValidation,
    getMaxValidation,
    getNumberOfItemsValidation,
    getRequiredMessage,
    getNumberMaxDigitsValidation,
    getNumberExactLengthValidation,
    getFloatValidation,
} from 'views/declarations/utils/validation-utils';
import ukImportProductValidationSchema from './UKProductValidationSchema';
import * as Yup from 'yup';
import {
    additionalInformations,
    additionDeductions,
    additionalSupplyChainActors,
    previousDocuments,
} from './UKCommonValidations';
import { ValidationType } from '../../../../../../utils/validations/transformValidations';

export const deferredPayments = Yup.object()
    .shape({
        deferredPaymentIdentifier: getNumberExactLengthValidation('deferredPaymentIdentifier', 7).nullable(),
        categoryAndType: getMaxValidation('type', 4).nullable(),
    })
    .nullable();

export const holderOfTheAuthorisations = Yup.object()
    .shape({
        holderOfTheAuthorisationIdentifier: getMaxValidation('holderOfTheAuthorisationIdentifier', 17).nullable(),
        authorisationType: getMaxValidation('authorisationType', 4).nullable(),
    })
    .nullable();

export const guaranteeTypes = Yup.object()
    .shape({
        guaranteeType: getMaxValidation('guaranteeType', 1).nullable(),
    })
    .nullable();

export const guaranteeReference = Yup.object().shape({
    grn: getMaxValidation('grn', 24).nullable(),
    otherGuaranteeReference: getMaxValidation('otherGuaranteeReference', 35).nullable(),
    accessCode: getMaxValidation('accessCode', 4).nullable(),
    amountOfImportDuty: getFloatValidation('amountOfImportDuty', 16, 2).nullable(),
    currencyCode: getExactLengthValidation('currencyCode', 3).nullable(),
    customsOfficeOfGuarantee: getExactLengthValidation('customsOfficeOfGuarantee', 8).nullable(),
});

const goodsShipmentSchema = Yup.object({
    importer: getCustomerValidation().nullable(),
    seller: getCustomerValidation({ phoneNumber: { max: 50, type: ValidationType.STRING, nullable: true } }).nullable(),
    buyer: getCustomerValidation({ phoneNumber: { max: 50, type: ValidationType.STRING, nullable: true } }).nullable(),
    ucr: getMaxValidation('ucr', 35).nullable(),
    warehouseIdentifier: getMaxValidation('warehouseIdentifier', 35).nullable(),
    warehouseType: getExactLengthValidation('warehouseType', 1).nullable(),
    additionalSupplyChainActors: additionalSupplyChainActors,
    deliveryIncoterm: getExactLengthValidation('deliveryIncoterm', 3).nullable(),
    deliveryUnlocode: getMaxValidation('deliveryUnLocode', 17).nullable(),
    deliveryCountryCode: getExactLengthValidation('deliveryCountryCode', 2).nullable(),
    deliveryLocationName: getMaxValidation('deliveryLocationName', 35).nullable(),
    additionDeductions: additionDeductions,
    countryOfDestination: getExactLengthValidation('countryOfDestination', 2)
        .required(getRequiredMessage('countryOfDestination'))
        .nullable(),
    countryOfDispatchExport: getExactLengthValidation('countryOfDispatchExport', 2)
        .required(getRequiredMessage('countryOfDispatchExport'))
        .nullable(),
    placeOfLoading: getMaxValidation('placeOfLoading', 17).nullable(),
    goodsLocationAddId: getNumberMaxDigitsValidation('goodsLocationAddId', 3).nullable(),
    goodsLocationId: getMaxValidation('goodsLocationId', 35).nullable(),
    goodsLocationType: getMaxValidation('goodsLocationType', 1)
        .required(getRequiredMessage('goodsLocationType'))
        .nullable(),
    goodsLocationPostCode: getMaxValidation('goodsLocationPostCode', 9).nullable(),
    goodsLocationStreetNumber: getMaxValidation('goodsLocationStreetNumber', 70).nullable(),
    goodsLocationCity: getMaxValidation('goodsLocationCity', 35).nullable(),
    goodsLocationCountry: getMaxValidation('goodsLocationCountry', 2)
        .required(getRequiredMessage('goodsLocationCountry'))
        .nullable(),
    goodsLocationQualifier: getMaxValidation('goodsLocationQualifier', 1)
        .required(getRequiredMessage('goodsLocationQualifier'))
        .nullable(),
    container: getNumberExactLengthValidation('container', 1).required(getRequiredMessage('container')).nullable(),
    inlandModeOfTransport: getNumberExactLengthValidation('inlandModeOfTransport', 1).nullable(),
    governmentAgencyGoodsItems: Yup.array()
        .of(ukImportProductValidationSchema)
        .nullable()
        .required(getRequiredMessage('governmentAgencyGoodsItem')),
    natureOfTransaction: getMaxValidation('natureOfTransaction', 2)
        .required(getRequiredMessage('natureOfTransaction'))
        .nullable(),
    previousDocuments: previousDocuments,
    transportIdentityMeansType: getNumberExactLengthValidation('transportIdentityMeansType', 2).nullable(),
    transportIdentityMeansId: getMaxValidation('transportIdentityMeansId', 35).nullable(),
});

const ukImportValidation = (numberOfItems?: number) =>
    Yup.object().shape({
        numberOfItems: getNumberOfItemsValidation(numberOfItems)
            .required(getRequiredMessage('numberOfItems'))
            .nullable(),
        declarationType: getExactLengthValidation('declarationType', 2)
            .required(getRequiredMessage('delarationType'))
            .nullable(),
        additionalDeclarationType: getExactLengthValidation('additionalDeclarationType', 1)
            .required(getRequiredMessage('additionalDeclarationType'))
            .nullable(),
        goodsShipment: goodsShipmentSchema,
        additionalInformations: additionalInformations,
        deferredPayments: deferredPayments,
        exporter: getCustomerValidation().nullable(),
        representative: getCustomerValidation({
            statusCode: { type: ValidationType.STRING, max: 2, nullable: true },
        }).nullable(),
        declarant: getCustomerValidation({ eori: { required: true } }).nullable(),
        holderOfTheAuthorisations: holderOfTheAuthorisations,
        totalAmountInvoiced: getFloatValidation('totalAmountInvoiced', 16, 2).nullable(),
        totalAmountCurrency: getExactLengthValidation('totalAmountCurrency', 3).nullable(),
        exchangeRate: getFloatValidation('exchangeRate', 12, 5).nullable(),
        transportCostsToFinalDestAmount: getFloatValidation('transportCostsToFinalDestAmount', 16, 2).nullable(),
        transportCostsToFinalDestCurrency: getExactLengthValidation('transportCostsCurrency', 3).nullable(),
        specialPersonPresentingGoodsToCustoms: getMaxValidation('specialPersonPresentingGoodsToCustoms', 17)
            .required(getRequiredMessage('specialPersonPresentingGoodsToCustoms'))
            .nullable(),
        customsOfficeOfPresentation: getExactLengthValidation('customsOfficeOfPresentation', 8).nullable(),
        supervisingCustomsOffice: getExactLengthValidation('supervisingCustomsOffice', 8).nullable(),
        totalGrossMass: getFloatValidation('totalGrossMass', 16, 6)
            .required(getRequiredMessage('totalGrossMass'))
            .nullable(),
        totalPackages: getNumberMaxDigitsValidation('totalPackages', 8)
            .required(getRequiredMessage('totalPackages'))
            .nullable(),
        modeOfTransportAtTheBorder: getNumberExactLengthValidation('modeOfTransportAtTheBorder', 1)
            .required(getRequiredMessage('modeOfTransportAtTheBorder'))
            .nullable(),
        nationalityOfMeansOfTransportAtTheBorder: getMaxValidation(
            'nationalityOfMeansOfTransportAtTheBorder',
            2
        ).nullable(),
        guaranteeTypes: guaranteeTypes,
        guaranteeReference: guaranteeReference,
        acceptanceDate: getMaxValidation('acceptanceDate', 35).nullable(),
    });

export default ukImportValidation;
