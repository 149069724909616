import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { FormikProvider } from 'formik';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { set } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import AutoFillModal from '../components/AutoFillModal';

const TransportInformationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField, getH1TooltipsByField } = useTooltips();
    const { template, templateFormik } = useTemplateContext();

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | null>(null);
    const [prevValue, setPrevValue] = useState<string | null>(null);

    const openModal = (value: string | null) => {
        setOpen(true);
        setValue(value);
    };
    const closeModal = () => {
        setOpen(false);
        setValue(null);
        setPrevValue(value);
    };

    const formik = useMemo(() => {
        if (template && templateFormik) return templateFormik;
        return props.formik;
    }, [props.formik, template, templateFormik]);

    const autoFill = (fields: string[]) => {
        const values = formik?.values;
        const newValues = fields.reduce((acc, field) => set(acc, field, value), values);
        formik?.setValues(newValues);
    };

    return (
        <section
            id="transport-information-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('transport-information-form-card');
                }
            }}
        >
            <FormikProvider value={props.formik!}>
                <NewFormCard title={t('Transport information')}>
                    <FormCardContainer>
                        <FormSelect
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`customsOfficeLodgement`, props)}
                            label={t('customsOfficeLodgement')}
                            disabled={props.amendment}
                            tooltip={getH1TooltipsByField(t('customsOfficeLodgement'))}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            required
                            selectOptions={normalizeCodesToSelect(codelists?.irelandNationalCustomsOfficeCodes || [])}
                            condensed
                            onChange={openModal}
                        />
                        <AutoFillModal
                            visible={open}
                            value={value}
                            prevValue={prevValue}
                            fields={[
                                {
                                    name: 'presentationCustomsOffice',
                                    label: t('presentationCustomsOffice'),
                                    codeList: codelists?.irelandNationalCustomsOfficeCodes,
                                },
                                {
                                    name: 'supervisingCustomsOffice',
                                    label: t('authorisation.supervisingCustomsOffice'),
                                    codeList: codelists?.irelandNationalCustomsOfficeCodes,
                                },
                                {
                                    name: 'goodsShipment.locationGoods.identificationOfLocation',
                                    label: t('locationGoods.identificationOfLocation'),
                                    codeList: codelists?.irelandLocationOfGoodsCodes,
                                },
                            ]}
                            onCancel={closeModal}
                            onOk={autoFill}
                        />

                        <FormSelect
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`presentationCustomsOffice`, props)}
                            refNumber="5.26"
                            label={t('presentationCustomsOffice')}
                            tooltip={getH1TooltipsByRefNumberAndField('5.26', t('presentationCustomsOffice'))}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            selectOptions={normalizeCodesToSelect(codelists?.irelandNationalCustomsOfficeCodes || [])}
                            condensed
                        />

                        <FormSelect
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`supervisingCustomsOffice`, props)}
                            refNumber="5.27"
                            label={t('authorisation.supervisingCustomsOffice')}
                            selectOptions={normalizeCodesToSelect(codelists?.irelandNationalCustomsOfficeCodes || [])}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '5.27',
                                t('authorisation.supervisingCustomsOffice')
                            )}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            condensed
                        />

                        <FormSelect
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`goodsShipment.inlandBorderTransportMode`, props)}
                            refNumber="7.5"
                            label={t('goodsShipment.inlandBorderTransportMode')}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '7.5',
                                t('goodsShipment.inlandBorderTransportMode')
                            )}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            selectOptions={normalizeCodesToSelect(codelists?.irelandTransportModes || [])}
                            condensed
                        />

                        <FormSelect
                            required
                            viewOnly={props.viewOnly}
                            testId="means-identification-type"
                            {...getFormikProps(`goodsShipment.meansIdentificationType`, props)}
                            label={t('goodsShipment.meansIdentificationType')}
                            refNumber="7.9"
                            selectOptions={normalizeCodesToSelect(codelists?.irelandMeansIdentityTypes || [])}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '7.9',
                                t('goodsShipment.meansIdentificationType')
                            )}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            condensed
                        />

                        <FormInput
                            maxLength={35}
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`goodsShipment.meansIdentificationNumber`, props)}
                            required
                            refNumber="7.9"
                            testId="goodsShipment.meansIdentificationNumber"
                            label={t('goodsShipment.meansIdentificationNumber')}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '7.9',
                                t('goodsShipment.meansIdentificationNumber')
                            )}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            condensed
                        />

                        <FormSelect
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`borderTransportMode`, props)}
                            refNumber="7.4"
                            label={t('borderTransportMode')}
                            testId="borderTransportMode"
                            tooltip={getH1TooltipsByRefNumberAndField('7.4', t('borderTransportMode'))}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            selectOptions={normalizeCodesToSelect(codelists?.irelandTransportModes || [])}
                            condensed
                        />

                        <CountrySelect
                            viewOnly={props.viewOnly}
                            {...getFormikProps(`activeBorderTransportMeansNationality`, props)}
                            refNumber="7.15"
                            testId="consignment.borderTransportMeans.nationality"
                            label={t('consignment.borderTransportMeans.nationality')}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '7.15',
                                t('consignment.borderTransportMeans.nationality')
                            )}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                            condensed
                        />
                    </FormCardContainer>
                </NewFormCard>
            </FormikProvider>
        </section>
    );
};
export default TransportInformationCard;
