export enum DeclarationInternalType {
    IMPORT = 'IMPORT',
    IMPORT_NEW = 'IMPORT_NEW',
    EXPORT = 'EXPORT',
    ENS = 'ENS',
    TSD = 'TEMPORARY',
    ETD = 'ETD',
    ARRIVAL = 'ARRIVAL',
    TEMPORARY = 'TEMPORARY',
}

export type CdsInternalType = DeclarationInternalType.IMPORT_NEW | DeclarationInternalType.EXPORT;

export const irelandInternalDeclarationTypes = [
    {
        id: DeclarationInternalType.IMPORT,
        value: 'Import (AIS)',
    },
    { id: DeclarationInternalType.ENS, value: 'ENS (ICS)' },
    { id: DeclarationInternalType.TSD, value: 'TSD' },
    { id: DeclarationInternalType.ETD, value: 'ETD' },
    { id: DeclarationInternalType.EXPORT, value: 'Export (SAD)' },
    { id: DeclarationInternalType.ARRIVAL, value: 'Arrival at exit' },
];

export const ukInternalDeclarationTypes = [
    {
        id: DeclarationInternalType.IMPORT,
        value: 'Import',
    },
    { id: DeclarationInternalType.EXPORT, value: 'Export' },
];
