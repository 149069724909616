import { FormikErrors } from 'formik';
import { Address } from 'store/customers/customer';
import { CardError, FieldError, ItemsFieldError } from 'store/declaration-form-errors/DeclarationFormErrors';
import { DeclarationCustomer } from 'store/declarations/common/declaration-customer';
import { SimplifiedDeclarationDocumentWritingOffHeader } from 'store/declarations/common/simplified-declaration-document-writing-off-header';
import { SupplyChainActor } from 'store/declarations/common/supply-chain-actor';
import {
    AdditionalFiscalReference,
    AdditionalInformation,
    AdditionsDeductions,
    Authorisation,
    AuthorisationHolder,
    GoodsInformation,
    GoodsLocation,
    GoodsShipment,
    GoodsShipmentItem,
    Guarantee,
    GuaranteeReference,
    IrelandImportDeclaration,
    Packages,
    SimplifiedDeclarationDocumentWritingOffItem,
    TaxBox,
} from 'store/declarations/ireland/import-declaration';

export const parseValidationErrors = (validation: FormikErrors<IrelandImportDeclaration>) => {
    const masterDetailsErros: CardError[] = [];
    parseMasterdetails(masterDetailsErros, validation);
    parseExporter(masterDetailsErros, validation);
    parseImporter(masterDetailsErros, validation);
    parseDeclarant(masterDetailsErros, validation);
    parseValueDetails(masterDetailsErros, validation);
    parsePreviousDocumentInformation(masterDetailsErros, validation);
    parseDeliveryTerms(masterDetailsErros, validation);
    parseTransportInformation(masterDetailsErros, validation);
    parseTransportCosts(masterDetailsErros, validation);
    parseTransportDocumentInformation(masterDetailsErros, validation);
    parseGoodsLocation(masterDetailsErros, validation);
    parseOtherDetails(masterDetailsErros, validation);
    parseExtraDetails(masterDetailsErros, validation);
    parseRepresentative(masterDetailsErros, validation);
    parseSeller(masterDetailsErros, validation);
    parseBuyer(masterDetailsErros, validation);
    parseAuthorisation(masterDetailsErros, validation);
    parseAuthorisationHolder(masterDetailsErros, validation);
    parseGuarantee(masterDetailsErros, validation);
    parseGoodsShipment(masterDetailsErros, validation);
    parseAdditionalInformation(masterDetailsErros, validation);
    parseAdditionalConditionalInformation(masterDetailsErros, validation);

    const itemsErrors: ItemsFieldError[] = [];
    parseDeclarationItems(itemsErrors, validation);
    const errors = {
        masterDetails: masterDetailsErros,
        items: itemsErrors,
    };
    return errors;
};

const parseMasterdetails = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    const fields: FieldError[] = [];

    if (validation?.declarationType) {
        fields.push({ name: 'Declaration Type', message: validation?.declarationType });
    }

    if (validation?.additionalDeclarationType) {
        fields.push({ name: 'Additional Declaration Type', message: validation?.additionalDeclarationType });
    }

    if (goodsShipment?.ucr) {
        fields.push({ name: 'Ucr', message: goodsShipment?.ucr });
    }

    if (validation?.numberOfItems) {
        fields.push({ name: 'Number of items', message: validation?.numberOfItems });
    }

    if (goodsShipment?.producedDocumentsWritingOff?.N935?.[0]) {
        fields.push({ name: 'Invoice Number', message: goodsShipment?.producedDocumentsWritingOff?.N935?.[0] });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Master Details', fields });
    }
};

export const addToParties = (errors: CardError[], fields: FieldError[]) => {
    const index = errors.findIndex(({ name }) => name === 'Parties');
    if (index === -1) {
        errors.push({ name: 'Parties', fields });
        return;
    }
    errors[index] = { name: 'Parties', fields: errors[index].fields?.concat(fields) };
};

const parseExporter = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const exporterValidation = validation.exporter as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(exporterValidation, 'Exporter');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};
const parseImporter = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const importerValidation = (validation?.goodsShipment as any)?.importer as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(importerValidation, 'Importer');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};
const parseDeclarant = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const declarantValidation = validation.declarant as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(declarantValidation, 'Declarant');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseValueDetails = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const fields: FieldError[] = [];

    if (validation?.invoiceCurrency) {
        fields.push({ name: 'Invoice Currency', message: validation?.invoiceCurrency });
    }

    if (validation?.invoiceAmount) {
        fields.push({ name: 'Total amount invoiced', message: validation?.invoiceAmount });
    }

    if (validation?.exchangeRate) {
        fields.push({ name: 'Exchange Rate', message: validation?.exchangeRate });
    }

    if (validation?.preferredPaymentMethod) {
        fields.push({ name: 'Preferred Payment Method', message: validation?.preferredPaymentMethod });
    }

    if (validation?.personPayingCustomsDuty) {
        fields.push({ name: 'Customs Duty Payer', message: validation?.personPayingCustomsDuty });
    }

    if (validation?.dv1Declaration) {
        fields.push({ name: 'DV1 Declaration', message: validation?.dv1Declaration });
    }

    if (validation?.vatFreeAuthorisation) {
        fields.push({ name: 'Vat Free Authorisation', message: validation?.vatFreeAuthorisation });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Value details', fields });
    }
};

export const parseDeclarationCustomerErrors = (customer: FormikErrors<DeclarationCustomer>, customerName?: string) => {
    const fields: FieldError[] = [];

    const addCustomerName = (fieldName: string) => {
        if (!customerName) {
            return fieldName;
        }
        return customerName + ' - ' + fieldName;
    };

    if (customer?.eori) {
        fields.push({ name: addCustomerName('Identification Number'), message: customer?.eori });
    }

    if (customer?.name) {
        fields.push({ name: addCustomerName('Name'), message: customer?.name });
    }

    if (customer?.streetAndNumber) {
        fields.push({ name: addCustomerName('Street And Number'), message: customer?.streetAndNumber });
    }

    if (customer?.city) {
        fields.push({ name: addCustomerName('City'), message: customer?.city });
    }

    if (customer?.postCode) {
        fields.push({ name: addCustomerName('Post Code'), message: customer?.postCode });
    }

    if (customer?.country) {
        fields.push({ name: addCustomerName('Country'), message: customer?.country });
    }

    return fields;
};

const parsePreviousDocumentInformation = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    const previousDocument = goodsShipment?.simplifiedDeclarationDocumentWritingOff as unknown as any[] | undefined;
    if (previousDocument && !Array.isArray(previousDocument)) {
        errors.push({ name: `Previous Document`, fields: [{ name: 'Previous Document', message: previousDocument }] });
        return;
    }
    previousDocument?.forEach((element: FormikErrors<SimplifiedDeclarationDocumentWritingOffHeader>, index: number) => {
        const fields: FieldError[] = [];

        if (element?.previousDocumentType) {
            fields.push({
                name: `Previous document type `,
                message: element?.previousDocumentType,
            });
        }

        if (element?.previousDocumentIdentifier) {
            fields.push({
                name: `Previous document identifier`,
                message: element?.previousDocumentIdentifier,
            });
        }

        if (element?.previousDocumentLineId) {
            fields.push({
                name: `Goods Item Identifier`,
                message: element?.previousDocumentLineId,
            });
        }

        if (fields.length > 0) {
            errors.push({ name: `Previous Document ${index}`, fields });
        }
    });
};

const parseDeliveryTerms = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);

    if (goodsShipment?.incotermCode) {
        fields.push({ name: 'INCOTERM code', message: goodsShipment?.incotermCode });
    }

    if (goodsShipment?.countryCode) {
        fields.push({ name: 'Country code', message: goodsShipment?.countryCode });
    }

    if (goodsShipment?.locationName) {
        fields.push({ name: 'Location name', message: goodsShipment?.locationName });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Delivery terms', fields });
    }
};

const parseTransportInformation = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);
    const authorisation = validation.authorisation as FormikErrors<Authorisation>;

    if (validation?.customsOfficeLodgement) {
        fields.push({ name: 'Customs Office of Lodgement', message: validation?.customsOfficeLodgement });
    }

    if (validation?.presentationCustomsOffice) {
        fields.push({ name: 'Customs office of presentation', message: validation?.presentationCustomsOffice });
    }

    if (goodsShipment?.inlandBorderTransportMode) {
        fields.push({ name: 'Inland mode of transport', message: goodsShipment?.inlandBorderTransportMode });
    }

    if (goodsShipment?.meansIdentificationType) {
        fields.push({ name: 'Transport Identity Means Type', message: goodsShipment?.meansIdentificationType });
    }

    if (goodsShipment?.meansIdentificationNumber) {
        fields.push({ name: 'Transport Identity Means Id', message: goodsShipment?.meansIdentificationNumber });
    }

    if (validation?.borderTransportMode) {
        fields.push({ name: 'Mode of transport at the border', message: validation?.borderTransportMode });
    }

    if (validation?.activeBorderTransportMeansNationality) {
        fields.push({
            name: 'Transport Nationality Means at the Border',
            message: validation?.activeBorderTransportMeansNationality,
        });
    }

    if (authorisation?.supervisingCustomsOffice) {
        fields.push({ name: 'Office Import/Expor', message: authorisation?.supervisingCustomsOffice });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Transport information', fields });
    }
};

const parseTransportCosts = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);

    if (goodsShipment?.transportCostsCurrency) {
        fields.push({ name: 'Transport costs currency', message: goodsShipment?.transportCostsCurrency });
    }

    if (goodsShipment?.transportCostsAmount) {
        fields.push({ name: 'Transport costs amount', message: goodsShipment?.transportCostsAmount });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Transport costs', fields });
    }
};

const parseTransportDocumentInformation = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const fields: FieldError[] = [];

    if (validation?.airwayBill) {
        fields.push({ name: 'Airway Bill', message: validation?.airwayBill });
    }

    if (validation?.masterAirwayBill) {
        fields.push({ name: 'Master Airway Bill', message: validation?.masterAirwayBill });
    }

    if (validation?.houseWayBill) {
        fields.push({ name: 'House Way Bill', message: validation?.houseWayBill });
    }

    if (validation?.billOfLading) {
        fields.push({ name: 'Bill of Landing', message: validation?.billOfLading });
    }

    if (validation?.roadConsignmentNote) {
        fields.push({ name: 'Road Consignment Note', message: validation?.roadConsignmentNote });
    }

    if (validation?.masterBillOfLading) {
        fields.push({ name: 'Master Bill of Lading', message: validation?.masterBillOfLading });
    }

    if (validation?.houseBillOfLading) {
        fields.push({ name: 'House Bill of Lading', message: validation?.houseBillOfLading });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Transport document information', fields });
    }
};

const parseGoodsLocation = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsLocation = getGoodShipment(validation)?.locationGoods as FormikErrors<GoodsLocation>;
    const goodsLocationAddress = goodsLocation?.address as FormikErrors<Address>;

    if (goodsLocation?.locationTypeCode) {
        fields.push({ name: 'Type of location code', message: goodsLocation?.locationTypeCode });
    }

    if (goodsLocation?.qualifierIdentification) {
        fields.push({ name: 'Qualifier of the identification', message: goodsLocation?.qualifierIdentification });
    }

    if (goodsLocation?.identificationOfLocation) {
        fields.push({ name: 'Identification of location', message: goodsLocation?.identificationOfLocation });
    }

    if (goodsLocation?.additionalIdentifier) {
        fields.push({ name: 'Additional Identifier', message: goodsLocation?.additionalIdentifier });
    }

    if (goodsLocationAddress?.country) {
        fields.push({ name: 'Country', message: goodsLocationAddress?.country });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Goods location', fields });
    }
};

const parseOtherDetails = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);

    if (goodsShipment?.warehouseType) {
        fields.push({ name: 'Warehouse type', message: goodsShipment?.warehouseType });
    }

    if (goodsShipment?.warehouseIdentifier) {
        fields.push({ name: `Warehouse Identifier`, message: goodsShipment.warehouseIdentifier }); //TODO add translations for file card name.
    }

    if (goodsShipment?.countryDestination) {
        fields.push({ name: 'Country destination', message: goodsShipment?.countryDestination });
    }

    if (goodsShipment?.countryDispatch) {
        fields.push({ name: 'Country dispatch', message: goodsShipment?.countryDispatch });
    }

    if (goodsShipment?.acceptanceDate) {
        fields.push({ name: 'Date of acceptance', message: goodsShipment?.acceptanceDate });
    }

    if (goodsShipment?.estimatedTimeOfArrival) {
        fields.push({ name: 'Estimated Time of Arrival', message: goodsShipment?.estimatedTimeOfArrival });
    }

    if (goodsShipment?.transactionNature) {
        fields.push({ name: 'Nature of transaction', message: goodsShipment?.transactionNature });
    }

    if (validation?.roroShipId) {
        fields.push({ name: 'RoRo Ship IMO', message: validation?.roroShipId });
    }

    if (validation?.roroUnaccompaniedTrailerReg) {
        fields.push({
            name: 'Roro unaccompanied trailer registration number',
            message: validation?.roroUnaccompaniedTrailerReg,
        });
    }

    if (validation?.textilesCertOfOrigin) {
        fields.push({ name: 'Textiles certificate of origin', message: validation?.textilesCertOfOrigin });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Other details', fields });
    }
};

const parseExtraDetails = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const fields: FieldError[] = [];

    if (validation?.deferredPayment) {
        fields.push({ name: 'Deferred Payment', message: validation?.deferredPayment });
    }

    if (validation?.internalCurrency) {
        fields.push({ name: 'Internal Currency', message: validation?.internalCurrency });
    }

    if (validation?.supervisingCustomsOffice) {
        fields.push({ name: 'Office Import/Export', message: validation?.supervisingCustomsOffice });
    }

    if (validation?.personProvidingGuarantee) {
        fields.push({
            name: 'Person Providing a Guarantee identification',
            message: validation?.personProvidingGuarantee,
        });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Extra details', fields });
    }
};

const parseRepresentative = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const representativeValidation = validation?.representative as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(representativeValidation, 'Representative');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseAuthorisation = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const authorisation = validation?.authorisation as FormikErrors<Authorisation>;
    const listOwnerOfGoods = (authorisation?.ownerOfTheGoods as unknown as any[]) || [];
    const firstPlaceOfUse = authorisation?.firstPlaceOfUse as FormikErrors<GoodsLocation>;
    const listOfPlaceOfUse = (authorisation?.placeOfUse as unknown as any[]) || [];
    const fields: FieldError[] = [];

    if (authorisation?.customsOfficeOfDischarge) {
        fields.push({
            name: 'Authorisation Customs office of discharge',
            message: authorisation?.customsOfficeOfDischarge,
        });
    }

    if (authorisation?.supervisingCustomsOffice) {
        fields.push({ name: 'Authorisation Office Import/Export', message: authorisation?.supervisingCustomsOffice });
    }

    if (authorisation?.period) {
        fields.push({ name: 'Authorisation Period', message: authorisation?.period });
    }

    if (authorisation?.periodDetails) {
        fields.push({ name: 'Authorisation Period details', message: authorisation?.periodDetails });
    }

    if (authorisation?.billOfDischargeDeadline) {
        fields.push({
            name: 'Authorisation Bill of discharge deadline',
            message: authorisation?.billOfDischargeDeadline,
        });
    }

    if (authorisation?.billOfDischargeDetails) {
        fields.push({
            name: 'Authorisation Bill of discharge details',
            message: authorisation?.billOfDischargeDetails,
        });
    }

    if (authorisation?.detailsOfPlannedActivities) {
        fields.push({
            name: 'Authorisation Details of planned activities',
            message: authorisation?.detailsOfPlannedActivities,
        });
    }

    if (authorisation?.additionalInformation) {
        fields.push({ name: 'Authorisation Additional Information', message: authorisation?.additionalInformation });
    }

    listOwnerOfGoods.forEach((ownerGoods: FormikErrors<DeclarationCustomer>, index: number) => {
        const fieldsOwner = parseDeclarationCustomerErrors(ownerGoods);
        if (fieldsOwner.length > 0) {
            fieldsOwner.forEach((owner) => {
                const cutomerError: FieldError = {
                    ...owner,
                    name: `Owner Of The Goods ${index} ${owner.name}`,
                };
                fields.push(cutomerError);
            });
        }
    });

    const errorsFirstPlaceOfUse = getGoodsLocation(firstPlaceOfUse, `First place of use`);
    if (errorsFirstPlaceOfUse.length > 0) {
        fields.push(...errorsFirstPlaceOfUse);
    }

    listOfPlaceOfUse.forEach((placesOfUse: FormikErrors<GoodsLocation>, index: number) => {
        const fieldsOwner = getGoodsLocation(placesOfUse, `Place of use ${index + 1}`);
        if (fieldsOwner.length > 0) {
            fields.push(...fieldsOwner);
        }
    });

    if (authorisation) {
        if (authorisation?.rateOfYield) {
            fields.push({ name: `Goods Item rate of Yield`, message: authorisation?.rateOfYield });
        }

        if (authorisation?.processedProductsCommodityCode) {
            fields.push({ name: `Goods Item commodity Code`, message: authorisation?.processedProductsCommodityCode });
        }

        if (authorisation?.processedProductsDescriptionOfGoods) {
            fields.push({
                name: `Goods Item description of Goods`,
                message: authorisation?.processedProductsDescriptionOfGoods,
            });
        }

        if (authorisation?.identificationOfGoodsCode) {
            fields.push({
                name: `Goods Item identification of goods code`,
                message: authorisation?.identificationOfGoodsCode,
            });
        }

        if (authorisation?.identification) {
            fields.push({ name: `Foods Item identification`, message: authorisation?.identification });
        }

        if (authorisation?.economicConditionsProcessingProcedure) {
            fields.push({
                name: `Goods Item processing Procedure`,
                message: authorisation?.economicConditionsProcessingProcedure,
            });
        }

        if (authorisation?.economicConditionsDetails) {
            fields.push({ name: `Goods Item details`, message: authorisation?.economicConditionsDetails });
        }
    }

    if (fields.length > 0) {
        errors.push({ name: 'Authorisation', fields });
    }
};

const parseAuthorisationHolder = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const authorisationHolder = (validation.authorisationHolder as unknown as any[]) || [];
    authorisationHolder.forEach((auth: FormikErrors<AuthorisationHolder>, index: number) => {
        const fields: FieldError[] = [];
        if (auth?.authorisationTypeCode) {
            fields.push({ name: `Authorisation type code`, message: auth?.authorisationTypeCode });
        }

        if (auth?.authorisationHolderIdentification) {
            fields.push({
                name: `Authorisation holder identification`,
                message: auth?.authorisationHolderIdentification,
            });
        }

        if (fields.length > 0) {
            errors.push({ name: `Authorisation Holder ${index + 1}`, fields });
        }
    });
};

const parseGuarantee = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const guarantee = (validation.guarantee as unknown as any[]) || [];

    guarantee.forEach((guarantee: FormikErrors<Guarantee>, index: number) => {
        const fields: FieldError[] = [];
        if (guarantee?.guaranteeType) {
            fields.push({ name: `Guarantee Type`, message: guarantee.guaranteeType });
        }

        if (guarantee?.guaranteeReference) {
            const guaranteeReference = guarantee.guaranteeReference as unknown as any[];
            guaranteeReference.forEach((ref: FormikErrors<GuaranteeReference>, refIndex: number) => {
                if (ref?.grn) {
                    fields.push({ name: `GRN`, message: ref.grn });
                }

                if (ref?.otherGuaranteeReference) {
                    fields.push({
                        name: `Guarantee Reference ${index + 1} type`,
                        message: ref.otherGuaranteeReference,
                    });
                }

                if (ref?.accessCode) {
                    fields.push({ name: `Guarantee Reference ${index + 1} access code `, message: ref.accessCode });
                }

                if (ref?.currency) {
                    fields.push({ name: `Guarantee Reference ${index + 1} currency`, message: ref.currency });
                }

                if (ref?.amount) {
                    fields.push({ name: `Guarantee Reference ${index + 1} amount`, message: ref.amount });
                }

                if (ref?.guaranteeCustomsOffice) {
                    fields.push({
                        name: `Guarantee Reference ${index + 1} customs office`,
                        message: ref.guaranteeCustomsOffice,
                    });
                }
            });
        }

        if (fields.length > 0) {
            errors.push({ name: `Guarantee ${index + 1}`, fields });
        }
    });
};

const parseGoodsShipment = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    const fields: FieldError[] = [];

    if (goodsShipment?.regionDestination) {
        fields.push({ name: `Region destination`, message: goodsShipment.regionDestination });
    }

    if (fields.length > 0) {
        errors.push({ name: `GoodsShipment`, fields });
    }
};

const parseSeller = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const sellerValidation = (validation?.goodsShipment as any)?.seller as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(sellerValidation, 'Seller');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseBuyer = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const buyerValidation = (validation?.goodsShipment as any)?.buyer as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(buyerValidation, 'Buyer');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseAdditionalInformation = (errors: CardError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const addInformation = (getGoodShipment(validation)?.additionalInformation as unknown as any[]) || [];

    addInformation.forEach((info: FormikErrors<AdditionalInformation>, index) => {
        const fields: FieldError[] = [];
        if (info?.additionalInformationCode) {
            fields.push({ name: `Additional Information Code`, message: info.additionalInformationCode });
        }

        if (info?.additionalInformationText) {
            fields.push({ name: `Additional Information Text`, message: info.additionalInformationText });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Information ${index + 1}`, fields });
        }
    });
};

const parseAdditionalConditionalInformation = (
    errors: CardError[],
    validation: FormikErrors<IrelandImportDeclaration>
) => {
    const supplyChainActor = (getGoodShipment(validation)?.supplyChainActor as unknown as any[]) || [];
    supplyChainActor.forEach((supplyChainActor: FormikErrors<SupplyChainActor>, index) => {
        const fields: FieldError[] = [];

        if (supplyChainActor?.roleCode) {
            fields.push({ name: `Role code`, message: supplyChainActor.roleCode });
        }

        if (supplyChainActor?.traderIdentification) {
            fields.push({ name: `Trader identification`, message: supplyChainActor.traderIdentification });
        }

        if (fields.length > 0) {
            errors.push({ name: `Supply Chain Actor ${index + 1}`, fields });
        }
    });

    const additionsAndDeductions = (getGoodShipment(validation)?.additionsDeductions as unknown as any[]) || [];
    additionsAndDeductions.forEach((additionsAndDeductions: FormikErrors<AdditionsDeductions>, index) => {
        const fields: FieldError[] = [];

        if (additionsAndDeductions?.additionsDeductionsCode) {
            fields.push({ name: `Code`, message: additionsAndDeductions.additionsDeductionsCode });
        }

        if (additionsAndDeductions?.amount) {
            fields.push({ name: `Amount`, message: additionsAndDeductions.amount });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additions and Deductions ${index + 1}`, fields });
        }
    });

    // const additionalFiscalReference =
    //     (getGoodShipment(validation)?.additionalFiscalReference as unknown as any[]) || [];
    // additionalFiscalReference.forEach((additionalFiscalReference: FormikErrors<AdditionalFiscalReference>, index) => {
    //     const fields: FieldError[] = [];

    //     if (additionalFiscalReference?.roleCode) {
    //         fields.push({ name: `Reference VAT`, message: additionalFiscalReference.roleCode });
    //     }

    //     if (additionalFiscalReference?.vatNumber) {
    //         fields.push({ name: `Reference ID`, message: additionalFiscalReference.vatNumber });
    //     }

    //     if (fields.length > 0) {
    //         errors.push({ name: `Additional Fiscal Reference ${index + 1}`, fields });
    //     }
    // });

    const container = getGoodShipment(validation)?.container;
    if (container) {
        errors.push({
            name: `Additional Conditional Information`,
            fields: [{ name: 'Container', message: container }],
        });
    }

    const containerIdentificationNumber =
        (getGoodShipment(validation)?.containerIdentificationNumber as unknown as any[]) || [];
    const fields: FieldError[] = [];
    containerIdentificationNumber.forEach((container: FormikErrors<string>, index) => {
        fields.push({ name: `Container Identification Number ${index}`, message: container });
    });
    if (fields.length > 0) {
        errors.push({ name: `Container Identification Numbers`, fields });
    }
};
const getGoodShipment = (validation: FormikErrors<IrelandImportDeclaration>) => {
    return validation.goodsShipment as any;
};

const getGoodsLocation = (goodsLocation: FormikErrors<GoodsLocation>, field: string) => {
    const fields: FieldError[] = [];

    if (goodsLocation?.identificationOfLocation) {
        fields.push({ name: `${field} identification of location`, message: goodsLocation?.identificationOfLocation });
    }

    if (goodsLocation?.qualifierIdentification) {
        fields.push({
            name: `${field}  qualifier of the identification`,
            message: goodsLocation?.qualifierIdentification,
        });
    }

    if (goodsLocation?.additionalIdentifier) {
        fields.push({ name: `${field}  additional Identifier`, message: goodsLocation?.additionalIdentifier });
    }

    if (goodsLocation?.identificationOfLocation) {
        fields.push({ name: `${field}  type of location code`, message: goodsLocation?.identificationOfLocation });
    }

    if (goodsLocation?.address) {
        const firstPlaceOfUseAddress = goodsLocation?.address as FormikErrors<Address>;

        if (firstPlaceOfUseAddress?.addressLine1) {
            fields.push({ name: `${field} street and number`, message: firstPlaceOfUseAddress?.addressLine1 });
        }

        if (firstPlaceOfUseAddress?.city) {
            fields.push({ name: `${field} city`, message: firstPlaceOfUseAddress?.city });
        }

        if (firstPlaceOfUseAddress?.postCode) {
            fields.push({ name: `${field} post code`, message: firstPlaceOfUseAddress?.postCode });
        }

        if (firstPlaceOfUseAddress?.country) {
            fields.push({ name: `${field} country`, message: firstPlaceOfUseAddress?.country });
        }
    }

    return fields;
};

const parseDeclarationItems = (errors: ItemsFieldError[], validation: FormikErrors<IrelandImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    if (!goodsShipment) {
        return;
    }

    const items = (goodsShipment?.goodsShipmentItem as unknown as any[]) || [];

    items?.forEach((itemErrors: FormikErrors<GoodsShipmentItem>, index) => {
        const item = {
            index: index + 1,
            errors: [],
        };
        parseItemsGoodsInformation(item.errors, itemErrors);
        parseItemDetails(item.errors, itemErrors);
        parseItemPackges(item.errors, itemErrors);
        parseItemValueDetails(item.errors, itemErrors);
        parseItemOtherDetails(item.errors, itemErrors);
        parseExtraItemDetails(item.errors, itemErrors);
        parseItemExporter(item.errors, itemErrors);
        parseItemSeller(item.errors, itemErrors);
        parseItemBuyer(item.errors, itemErrors);
        parseItemSimplifiedDeclarationDocumentWritingOffHeader(item.errors, itemErrors);
        parseItemAdditionalInformation(item.errors, itemErrors);
        parseItemAdditionalConditionalInformation(item.errors, itemErrors);
        if (item.errors.length > 0) {
            errors.push(item);
        }
    });
};

export const parseH1ProductTemplate = (validation: FormikErrors<GoodsShipment>) => {
    const productTemplateErrors: CardError[] = [];

    parseItemsGoodsInformation(productTemplateErrors, validation);
    parseItemDetails(productTemplateErrors, validation);
    parseItemPackges(productTemplateErrors, validation);
    parseItemValueDetails(productTemplateErrors, validation);
    parseItemOtherDetails(productTemplateErrors, validation);
    parseExtraItemDetails(productTemplateErrors, validation);
    parseItemExporter(productTemplateErrors, validation);
    parseItemSeller(productTemplateErrors, validation);
    parseItemBuyer(productTemplateErrors, validation);
    parseItemSimplifiedDeclarationDocumentWritingOffHeader(productTemplateErrors, validation);
    parseItemAdditionalInformation(productTemplateErrors, validation);
    parseItemAdditionalConditionalInformation(productTemplateErrors, validation);

    return { template: productTemplateErrors };
};

const parseItemsGoodsInformation = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const goodsInformation = validation?.goodsInformation as FormikErrors<GoodsInformation>;
    const fields: FieldError[] = [];
    if (goodsInformation?.combinedNomenclatureCode) {
        fields.push({ name: `Commodity Code`, message: goodsInformation.combinedNomenclatureCode });
    }

    if (goodsInformation?.taricCode) {
        fields.push({ name: `Taric Code`, message: goodsInformation.taricCode });
    }

    if (goodsInformation?.taricAdditionalCode) {
        fields.push({ name: `TARIC additional code`, message: goodsInformation.taricAdditionalCode });
    }

    if (goodsInformation?.nationalAdditionalCommodityCode) {
        fields.push({ name: `Commodity National Code`, message: goodsInformation.nationalAdditionalCommodityCode });
    }

    if (goodsInformation?.supplementaryUnits) {
        fields.push({ name: `Supplementary Units`, message: goodsInformation.supplementaryUnits });
    }

    if (goodsInformation?.goodsDescription) {
        fields.push({ name: `Description of Goods`, message: goodsInformation.goodsDescription });
    }

    if (goodsInformation?.commodityCondition1) {
        fields.push({ name: `Commodity condition 1`, message: goodsInformation.commodityCondition1 });
    }

    if (goodsInformation?.commodityCondition2) {
        fields.push({ name: `Commodity condition 2`, message: goodsInformation.commodityCondition2 });
    }

    if (validation?.vatRate) {
        fields.push({ name: `Vat Rate`, message: validation.vatRate });
    }

    if (validation?.dutyRate) {
        fields.push({ name: `Duty Rate`, message: validation.dutyRate });
    }

    if (goodsInformation?.invoiceNumber) {
        fields.push({ name: `Invoice Number`, message: goodsInformation.invoiceNumber });
    }

    if (validation?.preference) {
        fields.push({ name: `Preference Code`, message: validation.preference });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Goods Information', fields });
    }
};

const parseItemDetails = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const goodsInformation = validation?.goodsInformation as FormikErrors<GoodsInformation>;
    const fields: FieldError[] = [];
    if (validation?.countryOrigin) {
        fields.push({ name: `Country origin`, message: validation.countryOrigin });
    }

    if (goodsInformation?.grossMass) {
        fields.push({ name: `Gross Mass`, message: goodsInformation.grossMass });
    }

    if (goodsInformation?.netMass) {
        fields.push({ name: `Net Mass`, message: goodsInformation.netMass });
    }

    if (validation?.procedureCode) {
        fields.push({ name: `Procedure Code`, message: validation.procedureCode });
    }

    if (validation?.previousProcedureCode) {
        fields.push({ name: `Previous Procedure Code`, message: validation.previousProcedureCode });
    }

    if (validation?.additionalProcedure) {
        fields.push({ name: `Additional Procedure Code`, message: validation.additionalProcedure });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Item Details', fields });
    }
};

const parseItemPackges = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const goodsInformation = validation?.goodsInformation as FormikErrors<GoodsInformation>;
    const packagesList = (goodsInformation?.packaging as unknown as any[]) || [];

    packagesList.forEach((pack: FormikErrors<Packages>, index: number) => {
        const fields: FieldError[] = [];

        if (pack?.typePackage) {
            fields.push({ name: `Package Type Code`, message: pack.typePackage });
        }

        if (pack?.packageNumber) {
            fields.push({ name: `Number of Packages`, message: pack.packageNumber });
        }

        if (pack?.shippingMarks) {
            fields.push({ name: `Shipping Marks`, message: pack.shippingMarks });
        }

        if (fields.length > 0) {
            errors.push({ name: `Packages ${index + 1}`, fields });
        }
    });
};

const parseItemValueDetails = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const fields: FieldError[] = [];

    if (validation?.itemAmount) {
        fields.push({ name: `Item Price`, message: validation.itemAmount });
    }

    if (validation?.valuationMethod) {
        fields.push({ name: `Valuation Method`, message: validation.valuationMethod });
    }

    if (validation?.valuationIndicator1) {
        fields.push({ name: `Valuation Indicator 1`, message: validation.valuationIndicator1 });
    }

    if (validation?.valuationIndicator2) {
        fields.push({ name: `Valuation Indicator 2`, message: validation.valuationIndicator2 });
    }

    if (validation?.valuationIndicator3) {
        fields.push({ name: `Valuation Indicator 3`, message: validation.valuationIndicator3 });
    }

    if (validation?.valuationIndicator4) {
        fields.push({ name: `Valuation Indicator 4`, message: validation.valuationIndicator4 });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Value Details', fields });
    }
};

const parseItemOtherDetails = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const fields: FieldError[] = [];

    if (validation?.statisticalValue) {
        fields.push({ name: `Statistical Value`, message: validation.statisticalValue });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Other Details', fields });
    }
};

const parseExtraItemDetails = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem> | undefined) => {
    const goodsInformation = validation?.goodsInformation as FormikErrors<GoodsInformation>;

    const fields: FieldError[] = [];

    if (goodsInformation?.cusCode) {
        fields.push({ name: `CUS code`, message: goodsInformation.cusCode });
    }

    if (goodsInformation?.typeGoods) {
        fields.push({ name: `Type of goods`, message: goodsInformation.typeGoods });
    }

    if (validation?.ucr) {
        fields.push({ name: `UCR`, message: validation.ucr });
    }

    if (validation?.taxTotalAmount) {
        fields.push({ name: `Calculation of taxes - Total`, message: validation.taxTotalAmount });
    }

    if (validation?.valuationInformationCurrency) {
        fields.push({ name: `Valuation information currency`, message: validation.valuationInformationCurrency });
    }

    if (validation?.valuationInformationAmount) {
        fields.push({ name: `Valuation information amount`, message: validation.valuationInformationAmount });
    }

    if (validation?.countryDestination) {
        fields.push({ name: `Country destination`, message: validation.countryDestination });
    }

    if (validation?.regionDestination) {
        fields.push({ name: `Region destination`, message: validation.regionDestination });
    }

    if (validation?.countryDispatch) {
        fields.push({ name: `Country dispatch`, message: validation.countryDispatch });
    }

    if (validation?.countryPreferentialOrigin) {
        fields.push({ name: `Country of preferential origin code`, message: validation.countryPreferentialOrigin });
    }

    if (validation?.acceptanceDate) {
        fields.push({ name: `Date of acceptance`, message: validation.acceptanceDate });
    }

    if (validation?.quotaOrderNumber) {
        fields.push({ name: `Quota Order Number`, message: validation.quotaOrderNumber });
    }

    if (validation?.transactionNature) {
        fields.push({ name: `Nature of transaction`, message: validation.transactionNature });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Extra item details', fields });
    }
};

const parseItemExporter = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem> | undefined) => {
    const exporterValidation = validation?.exporter as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(exporterValidation, 'Exporter');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseItemSeller = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem> | undefined) => {
    const sellerValidation = validation?.seller as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(sellerValidation, 'Seller');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseItemBuyer = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem> | undefined) => {
    const buyerValidation = validation?.buyer as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(buyerValidation, 'Buyer');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseItemSimplifiedDeclarationDocumentWritingOffHeader = (
    errors: CardError[],
    validation: FormikErrors<GoodsShipmentItem> | undefined
) => {
    const list = (validation?.simplifiedDeclarationDocumentWritingOff as unknown as any[]) || [];

    list.forEach((declaration: FormikErrors<SimplifiedDeclarationDocumentWritingOffItem>, index: number) => {
        const fields: FieldError[] = [];

        if (declaration?.previousDocumentType) {
            fields.push({ name: `Previous document type`, message: declaration.previousDocumentType });
        }

        if (declaration?.previousDocumentIdentifier) {
            fields.push({ name: `Previous document identifier`, message: declaration.previousDocumentIdentifier });
        }

        if (declaration?.previousDocumentLineId) {
            fields.push({ name: `Goods Item Identifier`, message: declaration.previousDocumentLineId });
        }

        if (declaration?.measurementUnitAndQualifier) {
            fields.push({ name: `Measurement unit and qualifier`, message: declaration.measurementUnitAndQualifier });
        }

        if (declaration?.quantity) {
            fields.push({ name: `Quantity`, message: declaration.quantity });
        }

        if (declaration?.typeOfPackages) {
            fields.push({ name: `Package Type Code`, message: declaration.typeOfPackages });
        }

        if (declaration?.numberOfPackages) {
            fields.push({ name: `Number of Packages`, message: declaration.numberOfPackages });
        }

        if (fields.length > 0) {
            errors.push({ name: `Simplified Declaration Document Writing Off Header ${index + 1}`, fields });
        }
    });
};

const parseItemAdditionalInformation = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const list = (validation?.additionalInformation as unknown as any[]) || [];

    list.forEach((additionalInformation: FormikErrors<AdditionalInformation>, index: number) => {
        const fields: FieldError[] = [];

        if (additionalInformation?.additionalInformationCode) {
            fields.push({ name: `Code`, message: additionalInformation.additionalInformationCode });
        }

        if (additionalInformation?.additionalInformationText) {
            fields.push({ name: `Text`, message: additionalInformation.additionalInformationText });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Information ${index + 1}`, fields });
        }
    });
};

const parseItemAdditionalConditionalInformation = (
    errors: CardError[],
    validation: FormikErrors<GoodsShipmentItem>
) => {
    const supplyChainActor = (validation?.supplyChainActor as unknown as any[]) || [];
    const additionsAndDeductions = (validation?.additionsDeductions as unknown as any[]) || [];
    const additionalFiscalReference = (validation?.additionalFiscalReference as unknown as any[]) || [];
    const taxBox43Bis = (validation?.taxBox43Bis as unknown as any[]) || [];
    supplyChainActor.forEach((supplyChainActor: FormikErrors<SupplyChainActor>, index) => {
        const fields: FieldError[] = [];

        if (supplyChainActor?.roleCode) {
            fields.push({ name: `Role code`, message: supplyChainActor.roleCode });
        }

        if (supplyChainActor?.traderIdentification) {
            fields.push({ name: `Trader identification`, message: supplyChainActor.traderIdentification });
        }

        if (fields.length > 0) {
            errors.push({ name: `Supply Chain Actor ${index + 1}`, fields });
        }
    });

    additionsAndDeductions.forEach((additionsAndDeductions: FormikErrors<AdditionsDeductions>, index) => {
        const fields: FieldError[] = [];

        if (additionsAndDeductions?.additionsDeductionsCode) {
            fields.push({ name: `Code`, message: additionsAndDeductions.additionsDeductionsCode });
        }

        if (additionsAndDeductions?.amount) {
            fields.push({ name: `Amount`, message: additionsAndDeductions.amount });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additions and Deductions ${index + 1}`, fields });
        }
    });

    additionalFiscalReference.forEach((fiscalReference: FormikErrors<AdditionalFiscalReference>, index) => {
        const fields: FieldError[] = [];

        if (fiscalReference?.roleCode) {
            fields.push({ name: `Reference VAT`, message: fiscalReference.roleCode });
        }

        if (fiscalReference?.vatNumber) {
            fields.push({ name: `Reference Id`, message: fiscalReference.vatNumber });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Fiscal Reference ${index + 1}`, fields });
        }
    });

    taxBox43Bis.forEach((tax: FormikErrors<TaxBox>, index) => {
        const fields: FieldError[] = [];

        if (tax?.boxTaxType) {
            fields.push({ name: `Tax Type`, message: tax.boxTaxType });
        }

        if (tax?.boxAmount) {
            fields.push({ name: `Base Amount`, message: tax.boxAmount });
        }

        if (tax?.boxTaxRate) {
            fields.push({ name: `Tax Rate`, message: tax.boxTaxRate });
        }

        if (tax?.boxTaxPayableAmount) {
            fields.push({ name: `Amount of Tax Payable`, message: tax.boxTaxPayableAmount });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Taxes ${index + 1}`, fields });
        }
    });
    const containerIdentificationNumber = (validation?.containerIdentificationNumber as unknown as any[]) || [];
    const fields: FieldError[] = [];
    containerIdentificationNumber.forEach((container: FormikErrors<string>, index) => {
        fields.push({ name: `Container Identification Number ${index}`, message: container });
    });
    if (fields.length > 0) {
        errors.push({ name: `Container Identification Numbers`, fields });
    }
};
