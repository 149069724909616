import { Select } from 'components/ui/base/select/Select';
import styled from 'styled-components';

export const StyledDeclarationSelect = styled(Select)<{ condensed?: boolean }>`
    width: 100%;
    font-weight: normal;

    .ant-select-selector {
        border-radius: ${({ condensed }) => (condensed ? '4px !important' : 'initial')};
        border: ${({ condensed }) => (condensed ? '1px solid #cecece !important' : 'initial')};
    }
`;
