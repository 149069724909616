import { useCallback } from 'react';
import {
    doAddProductTemplateToIrelandExport,
    doAddProductTemplateToIrelandH1,
    doAddProductTemplateToIrelandH7,
    doAddProductTemplateToUkExport,
    doAddProductTemplateToUkImport,
} from 'store/declaration-product/actions';
import { useAppDispatch, useAppSelector } from '../config/hooks';

const useDeclarationProducts = () => {
    const declaration = useAppSelector((state) => state.declarationProduct.declaration);
    const isLoading = useAppSelector((state) => state.declarationProduct.isLoading);
    const error = useAppSelector((state) => state.declarationProduct.error);

    const dispatch = useAppDispatch();

    const addProductsTemplatesToIrelandH1 = useCallback(
        (declarationId: string, productTemplateIds: string[]) =>
            dispatch(doAddProductTemplateToIrelandH1(declarationId, productTemplateIds)),
        [dispatch]
    );

    const addProductsTemplatesToIrelandH7 = useCallback(
        (declarationId: string, productTemplateIds: string[]) =>
            dispatch(doAddProductTemplateToIrelandH7(declarationId, productTemplateIds)),
        [dispatch]
    );

    const addProductsTemplatesToIrelandExport = useCallback(
        (declarationId: string, productTemplateIds: string[]) =>
            dispatch(doAddProductTemplateToIrelandExport(declarationId, productTemplateIds)),
        [dispatch]
    );

    const addProductsTemplatesToUkExport = useCallback(
        (declarationId: string, productTemplateIds: string[]) =>
            dispatch(doAddProductTemplateToUkExport(declarationId, productTemplateIds)),
        [dispatch]
    );

    const addProductsTemplatesToUkImport = useCallback(
        (declarationId: string, productTemplateIds: string[]) =>
            dispatch(doAddProductTemplateToUkImport(declarationId, productTemplateIds)),
        [dispatch]
    );

    return {
        isLoading,
        error,
        declaration,
        addProductsTemplatesToIrelandH1,
        addProductsTemplatesToIrelandH7,
        addProductsTemplatesToIrelandExport,
        addProductsTemplatesToUkExport,
        addProductsTemplatesToUkImport,
    };
};

export default useDeclarationProducts;
