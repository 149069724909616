import { Row } from 'antd';
import { ReactElement } from 'react';
import addPathPrefix from 'utils/addPathPrefix';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState } from 'views/declarations/utils/form-utils';
import CustomerBlock, {
    carrierValidation,
    consigneeProductValidation,
    consigneeValidation,
    CustomerFields,
    exporterValidation,
    representativeValidation,
} from '../blocks/CustomerBlock';

export const customerCardValidation = {
    selfValidators: [exporterValidation, consigneeValidation, representativeValidation, carrierValidation],
};

export const customerCardProductValidation = {
    selfValidators: [consigneeProductValidation],
};

interface Props extends DeclarationFormCardProps {
    title: string;
    path: string;
    ucc: string;
    fieldOptions?: FieldOptions<CustomerFields>;
}

const CustomerCard = (props: Props): ReactElement => {
    return (
        <FormCard
            hide={props.hide}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            title={props.title}
            cardNumber={props.cardNumber}
            total={props.cardTotal}
            state={getCardState(
                [
                    { name: addPathPrefix(props.path, 'id'), required: props.fieldOptions?.id?.required },
                    { name: addPathPrefix(props.path, 'name'), required: props.fieldOptions?.name?.required },
                    {
                        name: addPathPrefix(props.path, 'address.streetAndNumber'),
                        required: props.fieldOptions?.address?.required,
                    },
                    {
                        name: addPathPrefix(props.path, 'address.city'),
                        required: props.fieldOptions?.address?.required,
                    },
                    {
                        name: addPathPrefix(props.path, 'address.postCode'),
                        required: props.fieldOptions?.address?.required,
                    },
                    {
                        name: addPathPrefix(props.path, 'address.country'),
                        required: props.fieldOptions?.address?.required,
                    },
                ],
                props
            )}
        >
            <Row gutter={18}>
                <CustomerBlock path={props.path} options={{ ucc: props.ucc }} fieldOptions={props.fieldOptions} />
            </Row>
        </FormCard>
    );
};

export default CustomerCard;
