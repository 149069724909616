import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { locationTypesLabels } from 'store/declarations/enums/common/location-types';
import { locationIdentificationQualifierLabels } from 'store/declarations/enums/ireland/location-identification-qualifier';
import AddressForm from 'views/declarations/common/AddressForm';
import NewFormCard, { FormCardContainer } from 'views/declarations/common/cards/NewFormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { appendFieldPath, getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';

const LocationOfGoods: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH7TooltipsByRefNumberAndField } = useTooltips();

    const fullFieldPath = appendFieldPath(props.propsPathPrefix);

    return (
        <NewFormCard title={t('locationGoods.title')}>
            <FormCardContainer>
                <FormInput
                    // required by business rules and not documentation
                    required
                    numbersAndLetters
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`locationOfGoodsUnlocode`, props)}
                    refNumber="5.23"
                    label={t('locationGoods.unlocode')}
                    tooltip={getH7TooltipsByRefNumberAndField('5.23', t('locationGoods.unlocode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`locationOfGoodsAdditionalIdentifier`, props)}
                    refNumber="5.23"
                    label={t('locationGoods.additionalIdentifier')}
                    tooltip={getH7TooltipsByRefNumberAndField('5.23', t('locationGoods.additionalIdentifier'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`locationOfGoodsTypeOfLocation`, props)}
                    refNumber="5.23"
                    label={t('locationGoods.typeOfLocation')}
                    tooltip={getH7TooltipsByRefNumberAndField('5.23', t('locationGoods.typeOfLocation'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={locationTypesLabels}
                    condensed
                />
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`locationOfGoodsQualifierOfIdentification`, props)}
                    refNumber="5.23"
                    label={t('locationGoods.qualifierOfIdentification')}
                    tooltip={getH7TooltipsByRefNumberAndField('5.23', t('locationGoods.qualifierOfIdentification'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    selectOptions={locationIdentificationQualifierLabels}
                    condensed
                />
                <AddressForm
                    h7PostCode={17}
                    declarationType={DeclarationName.H7}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    viewOnly={props.viewOnly}
                    fieldProps={{
                        streetAndNumber: props.getFieldProps(fullFieldPath('locationOfGoodsAddress.streetAndNumber')),
                        country: props.getFieldProps(fullFieldPath('locationOfGoodsAddress.country')),
                        postCode: props.getFieldProps(fullFieldPath('locationOfGoodsAddress.postCode')),
                        city: props.getFieldProps(fullFieldPath('locationOfGoodsAddress.city')),
                    }}
                    fieldMeta={
                        props.getFieldMeta && {
                            streetAndNumber: props.getFieldMeta(
                                fullFieldPath('locationOfGoodsAddress.streetAndNumber')
                            ),
                            country: props.getFieldMeta(fullFieldPath('locationOfGoodsAddress.country')),
                            postCode: props.getFieldMeta(fullFieldPath('locationOfGoodsAddress.postCode')),
                            city: props.getFieldMeta(fullFieldPath('locationOfGoodsAddress.city')),
                        }
                    }
                    fieldHelper={
                        props.getFieldHelpers && {
                            streetAndNumber: props.getFieldHelpers(
                                fullFieldPath('locationOfGoodsAddress.streetAndNumber')
                            ),
                            country: props.getFieldHelpers(fullFieldPath('locationOfGoodsAddress.country')),
                            postCode: props.getFieldHelpers(fullFieldPath('locationOfGoodsAddress.postCode')),
                            city: props.getFieldHelpers(fullFieldPath('locationOfGoodsAddress.city')),
                        }
                    }
                    refNumbers={{ streetAndNumber: '5.23', city: '5.23', country: '5.23', postcode: '5.23' }}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default LocationOfGoods;
