interface DeclarationBlockProps {
    hide?: () => boolean;
    children?: any;
}

export const DeclarationBlock = ({ hide, children }: DeclarationBlockProps) => {
    if (!hide?.()) {
        return children;
    }

    return null;
};
