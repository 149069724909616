import { Col, Drawer, Row } from 'antd';
import Button from 'components/ui/base/button';
import Container from 'components/ui/base/container';
import CustomModal from 'components/ui/base/modal/Modal';
import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import Filter from 'components/ui/composed/filter/Filter';
import { FilterResult, FilterType } from 'components/ui/composed/filter/filter-result';
import { handleDeclarationFilters } from 'components/ui/composed/filter/filter-utils';
import ListFilter from 'components/ui/composed/filter/ListFilters';
import SearchCustomer from 'components/ui/composed/searchCustomer/SearchCustomer';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import { removeDuplicate } from 'core/utils/arrays';
import useDeclarations from 'hooks/useDeclarations';
import useJobs from 'hooks/useJobs';
import useRequest from 'hooks/useRequest';
import useSession from 'hooks/useSession';
import debounce from 'lodash.debounce';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CustomerType } from 'store/customers/customer';
import { deleteDeclaration, listDeclarations as listDeclarationsReq } from 'store/declarations/client';
import { DeclarationCustomer } from 'store/declarations/common/declaration-customer';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { acceptJob, assignJob, closeJob, completeJob, rejectJob } from 'store/jobs/client';
import { JobStatus } from 'store/jobs/job';
import CargoJourneys from 'views/cargo-journeys/CargoJourneys';
import DeclarationTable, { DeclarationTableType } from 'views/custom-declaration/declaration-table/DeclarationsTable';
import { ReverseCol } from 'views/dashboard/components/Dashboard.styles';
import { getDeclarationPayload, getExporter, getImporter } from '../../declarations/utils/declaration-utils';
import CreateNewDeclarationCard from './CreateNewDeclarationCard';
import JobsDetailsInfo from './JobsDetailsInfo';

const JobsDetails: FC = () => {
    const navigate = useNavigate();
    const { jobId } = useParams<{ jobId: string }>();
    const { getJob, job, editJob, listTags, tagsList } = useJobs({ jobId });
    const { listDeclarations, declarations, isLoading } = useDeclarations();
    const { doRequest: doDeleteDeclaration } = useRequest(deleteDeclaration);
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>();
    const [showCreateCard, setShowCreateCard] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const [declarationFromJobs, setDeclarationFromJobs] = useState<Declaration[]>();
    const [listOfTags, setListOfTags] = useState(tagsList);
    const [tags, setTags] = useState<string[]>([]);
    const [showFilter, setShowFilter] = useState(false);
    const [filterResult, setFilterResult] = useState<FilterResult[]>([]);
    const { isHaulierUser, isDriverUser } = useSession();
    const [showSelectHaulier, setShowSelectHaulier] = useState(false);
    const { doRequest: assignJobRequest, data: assignJobData } = useRequest(assignJob);
    const { doRequest: closeJobRequest, data: dataCloseJobRequest } = useRequest(closeJob);
    const { doRequest: acceptJobRequest, data: dataAcceptJobRequest } = useRequest(acceptJob);
    const { doRequest: rejectJobRequest, data: dataRejectJobRequest } = useRequest(rejectJob);
    const { doRequest: completeJobRequest, data: dataCompleteJobRequest } = useRequest(completeJob);

    useEffect(() => {
        const getDeclarations = async () => {
            await listDeclarationsReq({ jobId, size: 200 }).then((j) => {
                if (j && j.list) {
                    setDeclarationFromJobs(j.list);
                }
            });
        };

        if (jobId) {
            getDeclarations();
        }
    }, [jobId]);

    useEffect(() => {
        if (assignJobData && assignJobData?.status !== job?.status) {
            getJob(assignJobData.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignJobData]);

    useEffect(() => {
        if (job?.id) {
            getJob(job?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCloseJobRequest, dataAcceptJobRequest, dataCompleteJobRequest, dataRejectJobRequest]);

    const querySearch = async (query: string) => {
        if (query) {
            const params = { query, jobId };
            await listDeclarations(params);
        } else {
            await listDeclarations({ jobId });
        }
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    useEffect(() => {
        if (jobId) {
            getJob(jobId);
            listTags();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId]);

    useEffect(() => {
        if (tagsList) {
            setListOfTags([...tagsList]);
        }
    }, [tagsList]);

    useEffect(() => {
        if (job?.tags) {
            setTags(job.tags);
        }
    }, [job]);

    useEffect(() => {
        if (jobId) {
            listDeclarations({ jobId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId]);

    useEffect(() => {
        if (paginator) {
            listDeclarations({ ...paginator, jobId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator]);

    useEffect(() => {
        if (filterResult?.length) {
            const filters = handleDeclarationFilters(filterResult);
            listDeclarations({ jobId, ...filters });
        } else {
            listDeclarations({ jobId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterResult]);

    const handleDuplicate = async (declaration: Declaration) => {
        const country =
            declaration.declarationExternalEntity === DeclarationExternalEntity.REVENUE
                ? DeclarationCountry.IRELAND
                : DeclarationCountry.UK;
        const type = declaration.declarationInternalType;
        navigate(`/invoice-upload/${country}/${type}/job/${declaration.jobId}`, {
            state: { declaration: declaration },
        });
    };

    const handleDelete = (ids: string[]) => {
        const toDelete = ids;
        setDeleteIds(toDelete);
        setModalVisible(true);
    };

    const deleteDeclarations = async () => {
        setModalVisible(false);
        Promise.all(
            deleteIds.map(async (id) => {
                await doDeleteDeclaration(id);
            })
        ).then(() => listDeclarations({ jobId }));
    };

    const handlePagination = (params: Partial<PaginatedParams>) => {
        if (paginator) {
            setPaginator({ page: params.page, size: paginator.size });
        } else {
            setPaginator({ page: params.page, size: defaultPagination.size });
        }
    };

    const handleSort = (params: Partial<PaginatedParams>) => {
        setPaginator({ ...paginator, ...params });
    };

    const getStatus = useMemo(
        () => filterResult.find((f) => f.type === FilterType.DECLARATION_STATUS)?.value,
        [filterResult]
    );

    const exporter: DeclarationCustomer[] = useMemo(() => {
        if (declarationFromJobs) {
            const exporters: DeclarationCustomer[] = [];
            declarationFromJobs.forEach((res) => {
                const payload = getDeclarationPayload(res);
                const exporter = getExporter(payload);
                if (exporter) {
                    exporters.push(exporter);
                }
            });
            return removeDuplicate(exporters);
        }
        return [];
    }, [declarationFromJobs]);

    const displayBtSelectHaulier = useMemo(() => {
        return job?.status === JobStatus.NEW || job?.status === JobStatus.REJECTED;
    }, [job]);

    const displayBtCloseJob = useMemo(() => {
        return job?.status === JobStatus.COMPLETED;
    }, [job]);

    const displayBtAccpetAndRejectJob = useMemo(() => {
        return job?.status === JobStatus.PROPOSED;
    }, [job]);

    const displayBtCompleteJob = useMemo(() => {
        return job?.status === JobStatus.IN_PROGRESS;
    }, [job]);

    const importer: DeclarationCustomer[] = useMemo(() => {
        if (declarationFromJobs) {
            const importers: DeclarationCustomer[] = [];
            declarationFromJobs.forEach((res) => {
                const payload = getDeclarationPayload(res);
                const importer = getImporter(payload);
                if (importer) {
                    importers.push(importer);
                }
            });
            return removeDuplicate(importers);
        }
        return [];
    }, [declarationFromJobs]);

    const handleSelectHaulier = () => {
        setShowSelectHaulier(true);
    };

    const handleCloseJob = () => {
        closeJobRequest(job?.id);
    };

    const handleCompleteJob = () => {
        completeJobRequest(job?.id);
    };

    const handleAcceptJob = () => {
        acceptJobRequest(job?.id);
    };

    const handleRejectJob = () => {
        rejectJobRequest(job?.id);
    };

    return (
        <>
            <CustomModal
                title={
                    deleteIds.length > 1 ? (
                        <H5>Do you want to remove these declarations?</H5>
                    ) : (
                        <H5>Do you want to remove this declaration?</H5>
                    )
                }
                centered
                visible={modalVisible}
                onOk={deleteDeclarations}
                onCancel={() => setModalVisible(false)}
                width={762}
                contentText={
                    deleteIds.length > 1
                        ? 'If you remove all these declarations, you will lose all the information associated to them.'
                        : 'If you remove this declarations, you will lose all the information associated with it.'
                }
            />
            <Container>
                <Row style={{ marginBottom: '4.4rem' }}>
                    <Col span={18}>
                        <H5>Job Details</H5>
                    </Col>

                    <ReverseCol span={6}>
                        <Button
                            size="large"
                            style={{ marginRight: '1.9rem' }}
                            onClick={() => {
                                navigate(`/jobs`);
                            }}
                        >
                            Back to jobs
                        </Button>
                        {!isHaulierUser && !isDriverUser && (
                            <div style={{ marginRight: '1.9rem' }}>
                                <Button size="large" onClick={() => setShowCreateCard((s) => !s)}>
                                    Create new declaration
                                </Button>
                                <CreateNewDeclarationCard
                                    isActive={showCreateCard}
                                    jobId={jobId}
                                    handleCancel={() => setShowCreateCard(false)}
                                />
                            </div>
                        )}
                        {displayBtSelectHaulier && !isHaulierUser && !isDriverUser && (
                            <>
                                <Button type="primary" size="large" onClick={handleSelectHaulier}>
                                    Select Haulier
                                </Button>
                            </>
                        )}

                        {displayBtCloseJob && !isHaulierUser && !isDriverUser && (
                            <>
                                <Button type="primary" size="large" onClick={handleCloseJob}>
                                    Close Job
                                </Button>
                            </>
                        )}
                        {displayBtAccpetAndRejectJob && isHaulierUser && (
                            <>
                                <Button style={{ marginRight: '1.9rem' }} size="large" onClick={handleRejectJob}>
                                    Reject Job
                                </Button>
                            </>
                        )}
                        {displayBtAccpetAndRejectJob && isHaulierUser && (
                            <>
                                <Button type="primary" size="large" onClick={handleAcceptJob}>
                                    Accept Job
                                </Button>
                            </>
                        )}
                        {displayBtCompleteJob && isHaulierUser && (
                            <>
                                <Button type="primary" size="large" onClick={handleCompleteJob}>
                                    Complete Job
                                </Button>
                            </>
                        )}
                    </ReverseCol>
                </Row>
                {declarationFromJobs && (
                    <JobsDetailsInfo
                        declarationFromJobs={declarationFromJobs}
                        job={job}
                        editJob={editJob}
                        tags={tags}
                        listOfTags={listOfTags}
                        setListOfTags={setListOfTags}
                        setTags={setTags}
                        jobId={jobId!} // FIXME remove assertion
                    />
                )}

                {declarations && !isHaulierUser && !isDriverUser && (
                    <>
                        <Row gutter={16} wrap={false} style={{ marginBottom: '4.5rem', marginTop: '4.5rem' }}>
                            <Col flex="auto">
                                <SearchBar
                                    inputPlaceholder="Search by declaration type, reference code, status, consignor, consignee, declarant and date"
                                    onSearch={(value) => debouncedSearch(value)}
                                    onClear={() => {
                                        listDeclarations({ jobId });
                                    }}
                                />
                            </Col>
                            <Col>
                                <Filter
                                    setShowFilterDropdown={setShowFilter}
                                    showFilterDropdown={showFilter}
                                    options={['Date', 'Declaration status', 'Declaration type']}
                                    statusChecked={getStatus}
                                    filterResult={filterResult}
                                    setFilterResult={setFilterResult}
                                />
                            </Col>
                        </Row>
                        <ListFilter
                            filters={filterResult}
                            updateFilters={(filters) => {
                                setFilterResult(filters);
                            }}
                        />

                        <DeclarationTable
                            handlePagination={handlePagination}
                            data={declarations}
                            handleDuplicate={handleDuplicate}
                            onDelete={handleDelete}
                            handleSort={handleSort}
                            loading={isLoading}
                            type={DeclarationTableType.DECLARATIONS}
                        />
                    </>
                )}
                {job && job.id === jobId && <CargoJourneys job={job} exporters={exporter} importers={importer} />}
            </Container>
            <Drawer
                title={'Select Haulier'}
                width={1098}
                visible={showSelectHaulier}
                onClose={() => {
                    setShowSelectHaulier(false);
                }}
            >
                <SearchCustomer
                    customerType={CustomerType.HAULIER}
                    handleSelect={async (haulier) => {
                        const body = { ...job, haulierId: haulier.id };
                        await assignJobRequest(jobId as string, body);
                        setShowSelectHaulier(false);
                    }}
                />
            </Drawer>
        </>
    );
};
export default JobsDetails;
