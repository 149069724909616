export enum PreviousDocumentCodes {
    X = 'X', //Summary declaration
    Y = 'Y', //Initial declaration
    Z = 'Z', //Previous document
}
export const PreviousDocumentCodesLabels = [
    { id: PreviousDocumentCodes.X, value: 'Summary declaration' },
    { id: PreviousDocumentCodes.Y, value: 'Initial declaration' },
    { id: PreviousDocumentCodes.Z, value: 'Previous document' },
] as const;
