import config from 'config';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { DownloadProductInfo, ProductTemplate } from './products';
import { UkProductTemplate } from './ukProducts';

export type ListProductTemplateParams = Partial<PaginatedParams & { declarationTemplateId?: string }>;

export const listProducts = (params?: Partial<ListProductTemplateParams>): Promise<ListPayload<ProductTemplate>> =>
    axiosClient
        .get<ListResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products`, { params })
        .then((response) => response.data.payload);

export const listH7Products = (params?: Partial<ListProductTemplateParams>): Promise<ListPayload<ProductTemplate>> =>
    axiosClient
        .get<ListResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products/h7`, { params })
        .then((response) => response.data.payload);

export const listCdsExportProducts = (
    params?: Partial<ListProductTemplateParams>
): Promise<ListPayload<ProductTemplate>> =>
    axiosClient
        .get<ListResponse<ProductTemplate>>(`${config.declarationsUrl}${config.productTemplateUrl}/cds/export`, {
            params,
        })
        .then((response) => response.data.payload);

export const listEnsProducts = (params?: Partial<ListProductTemplateParams>): Promise<ListPayload<ProductTemplate>> =>
    axiosClient
        .get<ListResponse<ProductTemplate>>(`${config.declarationsUrl}${config.productTemplateUrl}/ens`, {
            params,
        })
        .then((response) => response.data.payload);

export const createProductTemplate = (product: ProductTemplate): Promise<ProductTemplate> =>
    axiosClient
        .post<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products`, product)
        .then((response) => response.data.payload);

export const createH7ProductTemplate = (product: ProductTemplate): Promise<ProductTemplate> =>
    axiosClient
        .post<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products/h7`, product)
        .then((response) => response.data.payload);

export const createCdsExportProductTemplate = (product: ProductTemplate): Promise<ProductTemplate> =>
    axiosClient
        .post<SuccessResponse<ProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/cds/export`,
            product
        )
        .then((response) => response.data.payload);

export const createEnsProductTemplate = (product: ProductTemplate): Promise<ProductTemplate> =>
    axiosClient
        .post<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}${config.productTemplateUrl}/ens`, product)
        .then((response) => response.data.payload);

export const editProductTemplate = (product: ProductTemplate): Promise<ProductTemplate> =>
    axiosClient
        .put<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products`, product)
        .then((response) => response.data.payload);

export const editH7ProductTemplate = (product: ProductTemplate): Promise<ProductTemplate> =>
    axiosClient
        .put<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products/h7`, product)
        .then((response) => response.data.payload);

export const editCdsExportProductTemplate = (product: { template: ProductTemplate }): Promise<ProductTemplate> =>
    axiosClient
        .put<SuccessResponse<ProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/cds/export/${product.template.id}`,
            product
        )
        .then((response) => response.data.payload);

export const editEnsProductTemplate = (product: { template: ProductTemplate }): Promise<ProductTemplate> =>
    axiosClient
        .put<SuccessResponse<ProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/ens/${product.template.id}`,
            product
        )
        .then((response) => response.data.payload);

export const getProduct = (productId: string): Promise<ProductTemplate> =>
    axiosClient
        .get<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products/${productId}`)
        .then((response) => response.data.payload);

export const getH7Product = (productId: string): Promise<ProductTemplate> =>
    axiosClient
        .get<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products/h7/${productId}`)
        .then((response) => response.data.payload);

export const getGeneralizedProductTemplate = (productId: string): Promise<ProductTemplate> =>
    axiosClient
        .get<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}${config.productTemplateUrl}/${productId}`)
        .then((response) => response.data.payload);

export const deleteProduct = (productId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(`${config.declarationsUrl}/ireland/products/${productId}`)
        .then((response) => {
            return response.data;
        });

export const deleteH7Product = (productId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(`${config.declarationsUrl}/ireland/products/h7/${productId}`)
        .then((response) => {
            return response.data;
        });

export const uploadProductTemplate = (file: File): Promise<ProductTemplate[]> =>
    axiosClient
        .post<ProductTemplate[]>(`${config.declarationsUrl}/ireland/products/upload`, file, {
            params: { filename: file.name },
            headers: { 'content-type': file.type },
        })
        .then((response) => response.data);

export const downloadProductTemplate = (): Promise<DownloadProductInfo> =>
    axiosClient
        .get<SuccessResponse<DownloadProductInfo>>(`${config.declarationsUrl}/ireland/products/download`)
        .then((response) => response.data.payload);

export const createBulkProducts = (products: ProductTemplate[]): Promise<ProductTemplate[]> =>
    axiosClient
        .post<SuccessResponse<ProductTemplate[]>>(`${config.declarationsUrl}/ireland/products/bulk`, products)
        .then((response) => response.data.payload);

export const listH1Tags = (): Promise<string[]> =>
    axiosClient.get<string[]>(`${config.declarationsUrl}/ireland/products/tags`).then((response) => {
        return response.data;
    });

export const listH7Tags = (): Promise<string[]> =>
    axiosClient.get<string[]>(`${config.declarationsUrl}/ireland/products/h7/tags`).then((response) => response.data);

export const listCdsExportTags = (): Promise<string[]> =>
    axiosClient
        .get<string[]>(`${config.declarationsUrl}${config.productTemplateUrl}/cds/export/tags`)
        .then((response) => response.data);

export const listEnsTags = (): Promise<string[]> =>
    axiosClient
        .get<string[]>(`${config.declarationsUrl}${config.productTemplateUrl}/ens/tags`)
        .then((response) => response.data);

export const listUkProductsTemplates = (params?: Partial<PaginatedParams>): Promise<ListPayload<UkProductTemplate>> =>
    axiosClient
        .get<ListResponse<UkProductTemplate>>(`${config.declarationsUrl}/uk/products`, { params })
        .then((response) => response.data.payload);

export const createUkProductTemplate = (product: UkProductTemplate): Promise<UkProductTemplate> =>
    axiosClient
        .post<SuccessResponse<UkProductTemplate>>(`${config.declarationsUrl}/uk/products`, product)
        .then((response) => response.data.payload);

export const editUkProductTemplate = (product: UkProductTemplate): Promise<UkProductTemplate> =>
    axiosClient
        .put<SuccessResponse<UkProductTemplate>>(`${config.declarationsUrl}/uk/products/${product?.id}`, product)
        .then((response) => response.data.payload);

export const getUkProduct = (productId: string): Promise<UkProductTemplate> =>
    axiosClient
        .get<SuccessResponse<UkProductTemplate>>(`${config.declarationsUrl}/uk/products/${productId}`)
        .then((response) => response.data.payload);

export const deleteUkProduct = (productId: string): Promise<SuccessResponse<void>> =>
    axiosClient.delete<SuccessResponse<void>>(`${config.declarationsUrl}/uk/products/${productId}`).then((response) => {
        return response.data;
    });

export const deleteGeneralizedProductTemplate = (productId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(`${config.declarationsUrl}${config.productTemplateUrl}/${productId}`)
        .then((response) => response.data);

export const downloadUkProductTemplate = (): Promise<DownloadProductInfo> =>
    axiosClient
        .get<SuccessResponse<DownloadProductInfo>>(`${config.declarationsUrl}/uk/products/download`)
        .then((response) => response.data.payload);

export const uploadUkProductTemplate = (file: File): Promise<UkProductTemplate[]> =>
    axiosClient
        .post<UkProductTemplate[]>(`${config.declarationsUrl}/uk/products/upload`, file, {
            params: { filename: file.name },
            headers: { 'content-type': file.type },
        })
        .then((response) => response.data);

export const createUkBulkProducts = (products: ProductTemplate[]): Promise<UkProductTemplate[]> =>
    axiosClient
        .post<SuccessResponse<UkProductTemplate[]>>(`${config.declarationsUrl}/uk/products/bulk`, products)
        .then((response) => response.data.payload);
