export enum TransportDocumentType {
    N235 = 'N235', //Container list
    N271 = 'N271', //Packing list
    N703 = 'N703', //House waybill
    N704 = 'N704', //Master bill of lading
    N705 = 'N705', //Bill of lading
    N710 = 'N710', //Maritime manifest (T1)
    N714 = 'N714', //House bill of lading
    N720 = 'N720', //Consignment note CIM
    N722 = 'N722', //Road list - SMGS
    N730 = 'N730', //Road consignment note
    N740 = 'N740', //Air waybill
    N741 = 'N741', //Master airwaybill
    N750 = 'N750', //movement by post including parcel post
    N760 = 'N760', //Multimodal/combined transport document
    N785 = 'N785', //Cargo manifest
    N787 = 'N787', //Bordereau (cargo load list)
    N952 = 'N952', //TIR Carnet
    N955 = 'N955', //ATA carnet
}

export const transportDocumentTypeLabels = [
    { id: TransportDocumentType.N235, value: 'Container list' },
    { id: TransportDocumentType.N271, value: 'Packing list' },
    { id: TransportDocumentType.N703, value: 'House waybill' },
    { id: TransportDocumentType.N704, value: 'Master bill of lading' },
    { id: TransportDocumentType.N705, value: 'Bill of lading' },
    { id: TransportDocumentType.N710, value: 'Maritime manifest (T1)' },
    { id: TransportDocumentType.N714, value: 'House bill of lading' },
    { id: TransportDocumentType.N720, value: 'Consignment note CIM' },
    { id: TransportDocumentType.N722, value: 'Road list - SMGS' },
    { id: TransportDocumentType.N730, value: 'Road consignment note' },
    { id: TransportDocumentType.N740, value: 'Air waybill' },
    { id: TransportDocumentType.N741, value: 'Master airwaybill' },
    { id: TransportDocumentType.N750, value: 'movement by post including parcel post' },
    { id: TransportDocumentType.N760, value: 'Multimodal/combined transport document' },
    { id: TransportDocumentType.N785, value: 'Cargo manifest' },
    { id: TransportDocumentType.N787, value: 'Bordereau (cargo load list)' },
    { id: TransportDocumentType.N952, value: 'TIR Carnet' },
    { id: TransportDocumentType.N955, value: 'ATA carnet' },
] as const;
