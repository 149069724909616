import { Typography } from 'antd';
import { Form, useFormikContext } from 'formik';
import { useMemo } from 'react';
import styled from 'styled-components';
import { AmendmentRecord } from './CdsAmendmentModal';
import CdsAmendmentRecord from './CdsAmendmentRecord';

const { Text } = Typography;

const Wrapper = styled.div`
    display: grid;
    place-items: center;
`;

const AmendmentList = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: ${() => {
        const ratios = [25, 10, 5, 20, 35];
        const toGridColumn = (ratio: number) => `minmax(5rem, ${ratio}%)`;
        return ratios.map(toGridColumn).join(' ');
    }};
    gap: 2rem;
`;

const Header = () => {
    return (
        <>
            <Text strong>Path</Text>
            <Text strong>Value</Text>
            <Text strong>Action</Text>
            <Text strong>
                Amendment Type Code <Text type="danger">*</Text>
            </Text>
            <Text strong>Amendment Reason</Text>
        </>
    );
};

const CdsAmendmentListForm = () => {
    const formik = useFormikContext<AmendmentRecord[]>();

    const amendmentRecordsRender = useMemo(
        () => formik.values.map((amendment, index) => <CdsAmendmentRecord amendment={amendment} index={index} />),
        [formik.values]
    );

    return (
        <Form>
            <Wrapper>
                <AmendmentList>
                    <Header />
                    {amendmentRecordsRender}
                </AmendmentList>
            </Wrapper>
        </Form>
    );
};

export default CdsAmendmentListForm;
