import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import CountryOfDestinationCodeBlock, {
    countryOfDestinationCodeBlockValidation,
} from '../blocks/CountryOfDestinationCodeBlock';
import CountryOfDispatchExportCodeBlock, {
    countryOfDispatchExportCodeBlockValidation,
} from '../blocks/CountryOfDispatchExportCodeBlock';
import { ExtractValidationSchema } from '../validations/validations';

export const countryOfDestinationCardValidation = {
    selfValidators: [countryOfDestinationCodeBlockValidation, countryOfDispatchExportCodeBlockValidation],
};
export type CountryOfDestinationCardValidation = ExtractValidationSchema<typeof countryOfDestinationCardValidation>;

interface Props extends DeclarationFormCardProps {
    fieldOptions?: FieldOptions<'id' | 'countryCode'>;
}

const CountryOfDestinationCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('countryOfDestination')}>
            <FormCardContainer>
                <CountryOfDestinationCodeBlock />
                <CountryOfDispatchExportCodeBlock
                    fieldOptions={{ id: { required: props.fieldOptions?.id?.required ?? true } }}
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default CountryOfDestinationCard;
