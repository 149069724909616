import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { documentStatusLabels } from 'store/declarations/enums/uk/document-status';
import { measureUnitQualifiersLabels } from 'store/declarations/enums/uk/measure-unit-qualifiers';
import { measureUnitTypeLabels } from 'store/declarations/enums/uk/measure-unit-types';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import addPathPrefix from '../../../../../../../utils/addPathPrefix';
import { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import MultipleItemsCard from '../../../../../common/MultipleItemsCard';
import { documentsProduced } from '../../validation/UKProductValidationSchema';

const DocumentProducedCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix!}
            initialValues={{
                documentCode: '',
                documentCode2: '',
                documentId: '',
                status: '',
                statusReason: '',
                writingOffIssuingAuthority: '',
                writingOffDateOfValidity: '',
                writingOffQuantity: '',
                writingOffMeasurementUnit: '',
                writingOffMeasureUnitQualifiers: '',
            }}
            list={(list) => [
                { field: t('documentCode'), value: list.documentCode },
                { field: t('documentCode2'), value: list.documentCode2 },
                { field: t('documentId'), value: list.documentId },
                { field: t('status'), value: list.status },
                { field: t('statusReason'), value: list.statusReason },
                { field: t('writingOffIssuingAuthority'), value: list.writingOffIssuingAuthority },
                { field: t('writingOffDateOfValidity'), value: list.writingOffDateOfValidity },
                { field: t('writingOffQuantity'), value: list.writingOffQuantity },
                { field: t('writingOffMeasurementUnit'), value: list.writingOffMeasurementUnit },
                { field: t('writingOffMeasureUnitQualifiers'), value: list.writingOffMeasureUnitQualifiers },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            cardNumber={props.cardNumber}
            cardTotal={props.cardTotal}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            title={t('Document produced')}
            condensed
            validationSchema={documentsProduced}
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'documentCode')}`, formProps)}
                        refNumber="2.3"
                        label={t('documentCode')}
                        selectOptions={normalizeCodesToSelect(codelists?.ukImportCertificates || [])}
                        tooltip={getCdsTooltipsByRefNumberAndField('2.3', t('documentCode'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'documentCode2')}`, formProps)}
                        refNumber="2.3"
                        label={t('documentCode2')}
                        selectOptions={normalizeCodesToSelect(codelists?.ukImportCertificates || [])}
                        tooltip={getCdsTooltipsByRefNumberAndField('2.3', t('documentCode2'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormInput
                        maxLength={35}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'documentId')}`, formProps)}
                        refNumber="2.3"
                        label={t('documentId')}
                        tooltip={getCdsTooltipsByRefNumberAndField('2.3', t('documentId'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormSelect
                        refNumber="2.3"
                        label={t('status')}
                        selectOptions={documentStatusLabels}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'status')}`, formProps)}
                        tooltip={getCdsTooltipsByRefNumberAndField('2.3', t('status'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormInput
                        maxLength={35}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'statusReason')}`, formProps)}
                        refNumber="2.3"
                        label={t('statusReason')}
                        tooltip={getCdsTooltipsByRefNumberAndField('2.3', t('statusReason'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormInput
                        maxLength={70}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'writingOffIssuingAuthority')}`, formProps)}
                        refNumber="8.7"
                        label={t('writingOffIssuingAuthority')}
                        tooltip={getCdsTooltipsByRefNumberAndField('8.7', t('writingOffIssuingAuthority'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <DeclarationDatepicker
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'writingOffDateOfValidity')}`, formProps)}
                        label={t('writingOffDateOfValidity')}
                        refNumber="8.7"
                        tooltip={getCdsTooltipsByRefNumberAndField('8.7', t('writingOffDateOfValidity'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <DeclarationNumberInput
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'writingOffQuantity')}`, formProps)}
                        refNumber="8.7"
                        label={t('writingOffQuantity')}
                        tooltip={getCdsTooltipsByRefNumberAndField('8.7', t('writingOffQuantity'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormSelect
                        refNumber="8.7"
                        label={t('writingOffMeasurementUnit')}
                        selectOptions={measureUnitTypeLabels}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'writingOffMeasurementUnit')}`, formProps)}
                        tooltip={getCdsTooltipsByRefNumberAndField('8.7', t('writingOffMeasurementUnit'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormSelect
                        refNumber="8.7"
                        label={t('writingOffMeasureUnitQualifiers')}
                        selectOptions={measureUnitQualifiersLabels}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'writingOffMeasureUnitQualifiers')}`, formProps)}
                        tooltip={getCdsTooltipsByRefNumberAndField('8.7', t('writingOffMeasureUnitQualifiers'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default DocumentProducedCard;
