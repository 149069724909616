import config from 'config';
import { ListPayload, ListResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { AepBusinessRule } from './aep-business-rule';

export const listAepBusinessRules = (): Promise<ListPayload<AepBusinessRule>> => {
    return axiosClient
        .get<ListResponse<AepBusinessRule>>(`${config.resourcesUrl}/revenue/aep/business-rules`)
        .then((response) => response.data.payload);
};
