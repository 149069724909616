export enum DeclarationType {
    IM = 'IM', // - Outside EU / Not free circulation
    EU = 'EU', // - EFTA Import
    EX = 'EX', // - Export to outside the Customs territory
    ES = 'ES', // - Exporting from Ireland to a destination outside EU ou via a country outside the EU
    CO = 'CO', // - Non fiscal import
}

export const declarationTypeLabels = [
    { id: DeclarationType.IM, value: 'Outside EU / Not free circulation' },
    { id: DeclarationType.EU, value: 'EFTA Import' },
    { id: DeclarationType.EX, value: 'Export to outside the Customs territory' },
    {
        id: DeclarationType.ES,
        value: 'Exporting from Ireland to a destination outside EU ou via a country outside the EU',
    },
    { id: DeclarationType.CO, value: 'Non fiscal import' },
] as const;

export const chiefDeclarationTypeLabels = [
    { id: DeclarationType.EX, value: 'Export outside EU or COM' },
    { id: DeclarationType.CO, value: 'Special territory of the Community (Import or Export)' },
    { id: DeclarationType.IM, value: '' },
] as const;
