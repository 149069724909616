import { Row } from 'antd';
import DeclarationStatusTag from 'components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import { dateStandardFormat } from 'core/utils/date';
import { enumToText } from 'core/utils/enum-to-text';
import { useCallback } from 'react';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { CloseIcon, FilterButton } from './components/Filter.styles';
import { FilterResult, FilterType } from './filter-result';

interface Props {
    filters: FilterResult[];
    updateFilters: (filters: FilterResult[]) => void;
}
export const ListFilter: React.FC<Props> = ({ filters, updateFilters }) => {
    const update = useCallback(
        (toRemove: FilterResult) => {
            const newFilters = filters.filter((filter) => filter !== toRemove);
            updateFilters(newFilters);
        },
        [filters, updateFilters]
    );

    const updateList = useCallback(
        (s: string, filter: FilterResult) => {
            const newStatus = filter.value.filter((status) => status !== s);
            const index = filters.findIndex((f) => f === filter);
            if (Number.isInteger(index)) {
                const newFilter = [...filters];
                newFilter[index].value = newStatus;
                updateFilters(newFilter);
            }
        },
        [filters, updateFilters]
    );

    const handleValue = useCallback(
        (filter: FilterResult) => {
            if (filter.type === FilterType.DATE) {
                const date = new Date(filter.value[0]);
                return (
                    <FilterButton size="small" onClick={() => update(filter)} key={`${filter.value}`}>
                        {dateStandardFormat(date)} <CloseIcon />
                    </FilterButton>
                );
            } else if (filter.type === FilterType.DECLARATION_TYPE) {
                return filter.value.map((status, i) => (
                    <FilterButton size="small" onClick={() => updateList(status, filter)} key={`${filter.value}-${i}`}>
                        {enumToText(status)} <CloseIcon />
                    </FilterButton>
                ));
            } else if (filter.type === FilterType.DECLARATION_STATUS) {
                return filter.value.map((status, i) => (
                    <FilterButton size="small" onClick={() => updateList(status, filter)} key={`${filter.value}-${i}`}>
                        <Row align="middle">
                            <DeclarationStatusTag status={status as DeclarationStatus} />
                            <CloseIcon />
                        </Row>
                    </FilterButton>
                ));
            }
        },
        [update, updateList]
    );

    return <Row>{filters.map((f) => handleValue(f))}</Row>;
};

export default ListFilter;
