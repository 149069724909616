import DDatePicker from 'components/ui/base/datepicker/Datepicker';
import IconTooltip from 'components/ui/base/tooltip/IconTooltip';
import { TextSmall } from 'components/ui/base/typography';
import { TestProps } from 'core/utils/testTypes';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useDeclarationInputFocused from 'hooks/useDeclarationInputFocused';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useOutletContext } from 'react-router-dom';
import { StyledInfoCircleFilled } from 'views/declarations/Form.styles';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { TemplateCheckboxes } from '../../template/TemplateCheckbox';
import { useRegisterField } from '../../template/CardContext';
import { useTemplateContext } from '../../template/TemplateContext';
import useFieldTemplateMetaData from '../../template/useTemplateViewEditControls';
import {
    ErrorDiv,
    ErrorLabel,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
    RefDiv,
    ViewOnlyLabel,
    FormatDiv,
    FormItem,
} from '../formInput/DeclarationInput.styles';
import { handleBlur, handleFocus } from '../utils';

export interface FormDatepickerProps extends TestProps {
    label?: string;
    tooltip?: string;
    icon?: ReactNode;
    refNumber?: string;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    showTime?: boolean;
    viewOnly?: boolean;
    refClicked?: (refNumber: string | number) => void;
    required?: boolean;
    condensed?: boolean;
    onChange?: (value: moment.Moment | null) => void;
    disabled?: boolean;
    specialName?: string; // Used for template meta handle of taxBox34Bis fields as of 13/04/23
}

const DeclarationDatepicker: FC<FormDatepickerProps> = ({
    label,
    refNumber,
    tooltip,
    fieldProps: fieldPropsProp,
    fieldMeta: fieldMetaProp,
    fieldHelper: fieldHelpersProp,
    showTime,
    viewOnly: viewOnlyProp,
    refClicked,
    testId,
    required = false,
    condensed,
    onChange,
    disabled,
    specialName,
}) => {
    const { t } = useTranslation('common');
    const { setFocused } = useDeclarationInputFocused();
    const outletContext = useOutletContext<{
        amendment?: boolean;
    }>();
    const location = useLocation();
    const inViewOnly = location.pathname.includes('view-only');
    const { template, templateFormik, form, isViewOnly: isTemplateViewOnly } = useTemplateContext();

    useRegisterField({ path: fieldPropsProp?.name, required });
    const { isViewable, isInvisible } = useFieldTemplateMetaData(fieldPropsProp?.name);

    const { fieldProps, fieldMeta } = useMemo(() => {
        if (template && templateFormik && form) {
            const f = getFormikProps(`${form}.defaults.${fieldPropsProp?.name}`, templateFormik);
            return { ...f, fieldHelpers: f.fieldHelper };
        }

        return { fieldProps: fieldPropsProp, fieldMeta: fieldMetaProp, fieldHelpers: fieldHelpersProp };
    }, [template, templateFormik, form, fieldPropsProp, fieldMetaProp, fieldHelpersProp]);

    const handleRefClick = useCallback(() => {
        if (refClicked) {
            refNumber ? refClicked(refNumber) : refClicked(label ?? '');
        }
    }, [refClicked, refNumber, label]);

    const showTooltip = useMemo(() => tooltip && tooltip.length > 0, [tooltip]);

    const viewOnly = useMemo(() => {
        if (template) return isTemplateViewOnly;
        if (outletContext?.amendment) return isViewable;
        return isViewable || inViewOnly;
    }, [template, isTemplateViewOnly, inViewOnly, outletContext?.amendment, isViewable]);

    if (isInvisible && !template) return null;

    return (
        <>
            {viewOnly ? (
                <>
                    <ViewOnlyLabel>{label && label}:</ViewOnlyLabel>
                    {template && <TemplateCheckboxes disabled fieldPath={fieldPropsProp?.name} />}
                    <TextSmall>{fieldProps.value ?? '-'} </TextSmall>
                </>
            ) : (
                <>
                    <LabelDiv condensed={condensed}>
                        {label && (
                            <InputLabel>
                                {label}
                                {required && (
                                    <InputLabelRequired>{condensed ? '*' : t('mandatory')}</InputLabelRequired>
                                )}
                            </InputLabel>
                        )}

                        {refNumber && <RefDiv>{refNumber}</RefDiv>}
                        {FormatDiv && <FormatDiv>DD-MM-YYYY HH:MM</FormatDiv>}
                        {showTooltip && (
                            <IconTooltip
                                title={label}
                                tooltip={tooltip ?? t('defaultTooltip')}
                                icon={<StyledInfoCircleFilled />}
                                tooltipClicked={handleRefClick}
                            />
                        )}
                    </LabelDiv>
                    {template && (
                        <TemplateCheckboxes
                            required={required}
                            fieldPath={fieldPropsProp?.name}
                            specialName={specialName}
                            onChange={onChange}
                        />
                    )}
                    <FormItem>
                        <DDatePicker
                            id={testId}
                            fieldProps={fieldProps}
                            showTime={showTime}
                            disabled={disabled || isInvisible}
                            onFocus={() => handleFocus(setFocused)}
                            onBlur={(e) => handleBlur(setFocused)}
                            onChange={
                                onChange ??
                                ((date) => {
                                    fieldProps.onChange({
                                        target: {
                                            value: date === null ? date : date?.toISOString(),
                                            name: fieldProps.name,
                                        },
                                    });
                                })
                            }
                            condensed={condensed}
                        />
                        <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched} condensed={condensed}>
                            {!!fieldMeta?.error && !!fieldMeta?.touched && <ErrorLabel>{fieldMeta?.error}</ErrorLabel>}
                        </ErrorDiv>
                    </FormItem>
                </>
            )}
        </>
    );
};

export default DeclarationDatepicker;
