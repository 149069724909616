import { IndividualType } from 'store/individuals/individual';
import { User } from 'store/session/user';
import DeviceCalibration from 'views/weighbridges/views/DeviceCalibration';
import DeviceOrder from 'views/weighbridges/views/DeviceOrder';
import PaymentInfo from 'views/weighbridges/views/PaymentInfo';
import WeighbridgesDashboard from 'views/weighbridges/WeighbridgesDashboard';
import { ProtectedRoute, Route } from '../routes';
import wcRoutes from '../setup/wc/wc.routes';

const weighbridgesRoutes = (user?: User): Route[] => {
    return [
        {
            path: 'weighbridges',
            name: 'weighbridges',
            element: (
                <>
                    <ProtectedRoute
                        user={user}
                        allowedUsers={[
                            IndividualType.BROKER_ADMIN,
                            IndividualType.HAULIER_ADMIN,
                            IndividualType.SYSTEM_ADMIN,
                            IndividualType.TRADER_ADMIN,
                        ]}
                    >
                        <WeighbridgesDashboard />
                    </ProtectedRoute>
                </>
            ),
        },
        {
            path: 'weighbridges/:weighbridgeId/device/order',
            name: 'weighbridges-device-order',
            element: <DeviceOrder />,
        },
        {
            path: 'weighbridges/:weighbridgeId/device/payment-info',
            name: 'weighbridges-device-payment-info',
            element: <PaymentInfo />,
        },
        {
            path: 'weighbridges/:weighbridgeId/device/calibrate',
            name: 'weighbridges-device-calibrate',
            element: <DeviceCalibration />,
        },
        ...wcRoutes,
    ];
};

export default weighbridgesRoutes;
