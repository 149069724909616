import { useDispatch } from 'react-redux';
import { doClearDeclarationFilter, doSetDeclarationFilter } from '../store/filter/action';
import { useAppSelector } from '../config/hooks';
import { DeclarationFilter } from '../store/filter/DeclarationFilter';

const useDeclarationFilter = () => {
    const dispatch = useDispatch();
    const filter = useAppSelector((state) => state.declarationFilter.filter);
    const declarationType = useAppSelector((state) => state.declarationFilter.declarationType);

    const setDeclarationFilter = (declarationFilter: DeclarationFilter) => {
        dispatch(doSetDeclarationFilter(declarationFilter));
    };

    const clearDeclarationFilter = () => {
        dispatch(doClearDeclarationFilter());
    };

    return {
        filter,
        declarationType,
        setDeclarationFilter,
        clearDeclarationFilter,
    };
};

export default useDeclarationFilter;
