export const transportChargesMethod = [
    { id: '6', value: '' },
    { id: 'A', value: '' },
    { id: 'B', value: '' },
    { id: 'C', value: '' },
    { id: 'D', value: '' },
    { id: 'H', value: '' },
    { id: 'Y', value: '' },
    { id: 'Z', value: '' },
];
