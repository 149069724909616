import { useFormik } from 'formik';
import useCodelists from 'hooks/useCodelists';
import { FC } from 'react';
import ConsigmentCard from './cards/ConsigmentCard';
import DeliveryTermsCard from './cards/DeliveryTermsCard';
import ExportBox44Card from './cards/ExportBox44';
import ExportCustomerCard, { ExportCustomerTypes } from './cards/ExportCustomerCard';
import GoodsShipmentCard from './cards/GoodsShipmentCard';
import MasterDetailsCard from './cards/MasterDetailsCard';
import WarehouseCard from './cards/WarehouseCard';

interface Props {
    formik: ReturnType<typeof useFormik>; // FIXME use correct type
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    expandAll?: boolean;
    selectCard?: (id: string) => void;
}

const IrelandExportForm: FC<Props> = ({ formik, toggleHelp, viewOnly, expandAll, selectCard }) => {
    const { codelists } = useCodelists();
    return (
        <>
            <MasterDetailsCard
                keyCard={`master-details-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={10}
                cardNumber={1}
                selectCard={selectCard}
            />

            <GoodsShipmentCard
                keyCard={`goods-shipment-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                propsPathPrefix="irelandExportGoodsShipment"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={10}
                cardNumber={3}
                selectCard={selectCard}
            />

            <ExportCustomerCard
                keyCard={`customer-consignor-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                type={ExportCustomerTypes.CONSIGNOR}
                disableSearchCustomer
                addressRefNumber="2.2"
                eoriRefNumber="2.1"
                eoriRequired
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={10}
                cardNumber={4}
                selectCard={selectCard}
            />

            <ExportCustomerCard
                viewOnly={viewOnly}
                keyCard={`customer-consignee-card`}
                expandAll={expandAll}
                type={ExportCustomerTypes.CONSIGNEE}
                addressRefNumber="8.2"
                eoriRefNumber="8.1"
                eoriRequired
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={10}
                selectCard={selectCard}
                cardNumber={5}
            />

            <DeliveryTermsCard
                viewOnly={viewOnly}
                keyCard={`delivery-terms-card`}
                expandAll={expandAll}
                propsPathPrefix="irelandExportGoodsShipment"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={10}
                selectCard={selectCard}
                cardNumber={6}
            />

            <WarehouseCard
                keyCard={`wharehouse-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                propsPathPrefix="irelandExportGoodsShipment"
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={10}
                cardNumber={8}
                selectCard={selectCard}
            />

            <ExportBox44Card
                keyCard={`additional-information-form-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                title="Additional Information"
                propsPathPrefix="irelandExportGoodsShipment.additionalInformation"
                getFieldMeta={formik.getFieldMeta}
                getFieldProps={formik.getFieldProps}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={10}
                cardNumber={9}
                selectCard={selectCard}
                codelists={codelists?.irelandAdditionalInformationCodes || []}
            />

            <ExportBox44Card
                keyCard={`additional-document-form-card`}
                expandAll={expandAll}
                viewOnly={viewOnly}
                title="Additional Document"
                propsPathPrefix="irelandExportGoodsShipment.additionalDocument"
                getFieldMeta={formik.getFieldMeta}
                getFieldProps={formik.getFieldProps}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={10}
                cardNumber={10}
                selectCard={selectCard}
                codelists={codelists?.irelandCommonDocumentsTypes || []}
            />
        </>
    );
};
export default IrelandExportForm;
