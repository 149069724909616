import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import Notification from 'components/ui/base/notification/Notification';
import { H5 } from 'components/ui/base/typography';
import useCustomers from 'hooks/useCustomers';
import useDeclarations from 'hooks/useDeclarations';
import useDocuments from 'hooks/useDocuments';
import useJobs from 'hooks/useJobs';
import useSession from 'hooks/useSession';
import debounce from 'lodash.debounce';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { listDeclarations as listDeclarationsReq } from 'store/declarations/client';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { IrelandExportMessageType } from 'store/declarations/enums/ireland/message-type';
import { IrelandDeclarationName } from 'store/declarations/ireland/ireland-declaration-name';
import { UkImportDeclarationName } from 'store/declarations/uk/uk-declaration-name';
import { JobResponse } from 'store/jobs/job';
import { getDeclarationName } from 'views/declarations/utils/declaration-utils';
import { ColButtonFillForm, Container, TitleRow } from './components/InvoiceUpload.styles';
import PreviewInvoice from './components/PreviewInvoice';
import SelectFormType from './components/SelectFormType';
import SelectInvoiceFile from './components/SelectInvoiceFile';
import SelectJob from './components/SelectJob';
import { createDeclarations, createOrDuplicateDeclarations, getDeclarationNameLabels, getMessageType } from './utils';

const TraderInvoiceUpload: FC = () => {
    const { t } = useTranslation('customs_declarations');
    const { country, type, jobId } = useParams<{
        country: DeclarationCountry;
        type: DeclarationInternalType;
        jobId: string;
    }>();
    const navigate = useNavigate();
    const location = useLocation();
    const [myDeclaration, setMyDeclaration] = useState<Declaration | undefined>();
    const { userInfo } = useSession();
    const {
        createIrelandImportDeclaration,
        createIrelandH7ImportDeclaration,
        createIrelandExportDeclaration,
        createUkImportDeclaration,
        createElectronicTransportDocument,
        createUkExportDeclaration,
        createUkImportNewDeclaration,
        createEntrySummaryDeclaration,
        createTemporaryStorageDeclaration,
        createArrivalAtExitDeclaration,
        duplicateDeclaration,
    } = useDeclarations();
    const { job, createJob, listJobs, jobs } = useJobs({ jobId });
    const [myJob, setMyJob] = useState<JobResponse | undefined>(job);
    const [file, setFile] = useState<{ filename: string; id: string } | undefined>();
    const [formType, setFormType] = useState<string>();
    const [declarationFromJobs, setDeclarationFromJobs] = useState<{
        [key: string]: Declaration[];
    }>({});
    const [fillForm, setFillForm] = useState(false);
    const { deleteDocument, error: documentError, isLoading: documentsIsLoading } = useDocuments();
    const [deleteFileClicked, setDeleteFileClicked] = useState(false);
    const { customer } = useCustomers({ customerId: userInfo?.customerId });
    const [duplicateDeclarationId, setDuplicateDeclarationId] = useState<string>();

    const isChiefDeclaration = useMemo(() => false, []);

    const isEnsDeclaration = useMemo(
        () => (country === DeclarationCountry.IRELAND && type === DeclarationInternalType.ENS ? true : false),
        [country, type]
    );

    const isTsdDeclaration = useMemo(
        () => country === DeclarationCountry.IRELAND && type === DeclarationInternalType.TSD,
        [country, type]
    );

    const isArrivalDeclaration = useMemo(
        () => (country === DeclarationCountry.IRELAND && type === DeclarationInternalType.ARRIVAL ? true : false),
        [country, type]
    );

    const handleFormType = useMemo(
        () => (isChiefDeclaration || isEnsDeclaration || isTsdDeclaration ? false : !formType),
        [formType, isChiefDeclaration, isEnsDeclaration, isTsdDeclaration]
    );

    const disabledFillForm = useMemo(() => !myJob || handleFormType, [myJob, handleFormType]);

    const disableFormType = useMemo(() => !!duplicateDeclarationId, [duplicateDeclarationId]);

    const disableSelectJob = useMemo(() => !!myDeclaration, [myDeclaration]);

    const labelTitle = useMemo(() => {
        return t(`create${country}Declaration${type}`);
    }, [country, type, t]);

    useEffect(() => {
        if (!jobs.list.length) {
            listJobs({ size: 200 });
        }
        if (job) {
            setMyJob(job);
        }
        if (location && location.state) {
            const previousInformation = location.state as { declaration?: Declaration; job?: JobResponse };
            if (previousInformation?.declaration && previousInformation?.declaration?.customerId) {
                setDuplicateDeclarationId(previousInformation.declaration.id);
                const name = getDeclarationName(previousInformation.declaration);
                callbackGetDeclarationNameLabels()!.forEach((a) => {
                    // FIXME remove assertion
                    if (a.key === name) {
                        setFormType(a.value);
                    }
                });
            }
            if (previousInformation?.job && previousInformation?.job?.id) {
                setMyJob(previousInformation.job);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getDeclarations = async () => {
            await Promise.all(jobs.list.map(async (j) => (await listDeclarationsReq({ jobId: j.id })).list)).then(
                (j) => {
                    const declarations = j.map((res) => {
                        if (res && res.length > 0) {
                            const jobId: string = res[0].jobId!;
                            return { [jobId]: [...res] };
                        } else {
                            return {};
                        }
                    });
                    setDeclarationFromJobs(Object.assign({}, ...declarations));
                }
            );
        };

        if (jobs.list) {
            getDeclarations();
        }
    }, [jobs]);

    useEffect(() => {
        if (myDeclaration && fillForm) {
            setFillForm(false);
            navigate(`/declarations/${myDeclaration.id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fillForm, myDeclaration]);

    useEffect(() => {
        if (deleteFileClicked && !documentsIsLoading) {
            if (!documentError) {
                Notification({
                    type: 'success',
                    messageTitle: t('Invoice deleted'),
                    description: t('Invoice has deleted successfully!'),
                });
                setFile(undefined);
            } else {
                Notification({
                    type: 'error',
                    messageTitle: t('Invoice error'),
                    description: t('Failed to delete invoice!'),
                });
            }
        }
    }, [deleteFileClicked, documentsIsLoading, documentError, t]);

    useEffect(() => {
        if (!jobs.list.length) {
            listJobs({ size: 200 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (job) {
            setMyJob(job);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (jobId && jobs) {
            const j = jobs.list.find((value) => value.id === jobId);
            if (j) {
                setMyJob(j);
            }
        }
    }, [jobId, jobs]);

    const handleFillFormClick = () => {
        if (!myDeclaration) {
            handleCreateDeclaration();
        }
        setFillForm(true);
    };

    const callbackGetDeclarationNameLabels = useCallback(() => {
        if (country && type) {
            return getDeclarationNameLabels(country, type);
        }
        return null;
    }, [country, type]);

    const querySearchJob = async (query: string) => {
        if (query) {
            const params = { query };
            await listJobs(params);
        } else {
            await listJobs({ size: 200 });
        }
    };

    const updateDeclaration = async (declarationId: string, customerId: string, jobId: string) => {
        return await duplicateDeclaration(declarationId, customerId, jobId);
    };

    const createDeclaration = async (
        country: DeclarationCountry,
        customerId: string,
        jobId: string,
        messageType?: IrelandDeclarationName | IrelandExportMessageType | UkImportDeclarationName
    ) => {
        return createDeclarations(
            formType,
            country,
            customerId,
            jobId,
            type!, // FIXME remove assertion
            createIrelandH7ImportDeclaration,
            createIrelandImportDeclaration,
            createEntrySummaryDeclaration,
            createIrelandExportDeclaration,
            createElectronicTransportDocument,
            createUkImportDeclaration,
            createUkExportDeclaration,
            createUkImportNewDeclaration,
            createTemporaryStorageDeclaration,
            createArrivalAtExitDeclaration,
            messageType
        );
    };

    const handleCreateDeclaration = async () => {
        if (!myDeclaration) {
            if (!isChiefDeclaration && !isEnsDeclaration && !isTsdDeclaration && formType && country && type) {
                const messageType = getMessageType(country, type, formType);
                const jId = jobId ?? myJob?.id;
                const customerId = userInfo?.customerId;
                return await createOrDuplicateDeclarations(
                    updateDeclaration,
                    createDeclaration,
                    jId!, // FIXME remove assertion
                    setMyDeclaration,
                    country,
                    customerId,
                    duplicateDeclarationId,
                    messageType
                );
            } else {
                const jId = jobId ?? myJob?.id;
                const customerId = userInfo?.customerId;
                return await createOrDuplicateDeclarations(
                    updateDeclaration,
                    createDeclaration,
                    jId!, // FIXME remove assertion
                    setMyDeclaration,
                    country!, // FIXME remove assertion
                    customerId,
                    duplicateDeclarationId
                );
            }
        }
    };

    const createNewJob = async () => {
        const job = (await createJob()) as JobResponse;
        setMyJob(job);
        navigate(`/invoice-upload/${country}/${type}/job/${job.id}`, { state: { job } });
    };

    const handleSelectJob = useCallback(
        async (id: string) => {
            const j = jobs.list.find((e) => e.id === id);
            if (j) {
                setMyJob(j);
                navigate(`/invoice-upload/${country}/${type}/job/${j.id}`, { state: { j } });
            }
        },
        [jobs, country, navigate, type]
    );

    const handleDeleteFile = async (id: string) => {
        await deleteDocument(id);
        setDeleteFileClicked(true);
    };

    const successUploadInvoice = (file: { filename: string; id: string }) => {
        setFile(file);
    };

    const debouncedSearchJob = debounce((query: string) => querySearchJob(query), 500);

    return (
        <Container>
            <TitleRow>
                <Col span={18}>
                    <H5>{labelTitle}</H5>
                </Col>
                <ColButtonFillForm span={6}>
                    <Button size="large" disabled={disabledFillForm} type="primary" onClick={handleFillFormClick}>
                        {t('fillForm')}
                    </Button>
                </ColButtonFillForm>
            </TitleRow>

            <Row gutter={32}>
                <Col span={12}>
                    <SelectJob
                        disabled={disableSelectJob}
                        job={myJob}
                        jobs={jobs?.list}
                        createNewJob={createNewJob}
                        handleSelectJob={handleSelectJob}
                        setJob={setMyJob}
                        declarationFromJobs={declarationFromJobs}
                        search={(e) => debouncedSearchJob(e)}
                    />

                    {!isEnsDeclaration && !isTsdDeclaration && !isArrivalDeclaration && (
                        <SelectFormType
                            customer={customer}
                            job={myJob}
                            setFormType={setFormType}
                            declarationNameLabels={callbackGetDeclarationNameLabels()!} // FIXME remove assertion
                            disableFormType={!customer || !myJob || disableFormType}
                            formType={formType}
                        />
                    )}

                    <SelectInvoiceFile
                        customer={customer}
                        job={myJob}
                        handleDeleteFile={handleDeleteFile}
                        handleCreateDeclaration={handleCreateDeclaration}
                        disableFormType={handleFormType}
                        declarationId={myDeclaration?.id}
                        successUploadInvoice={successUploadInvoice}
                        file={file}
                    />
                </Col>
                <Col span={12}>
                    <PreviewInvoice file={file} customerName={customer?.name} />
                </Col>
            </Row>
        </Container>
    );
};

export default TraderInvoiceUpload;
