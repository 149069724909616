export enum PartySubRoleType {
    CS = 'CS',
    FW = 'FW',
    MF = 'MF',
    WH = 'WH',
}

export const partySubRoleTypeLabels = [
    { id: PartySubRoleType.CS, value: 'CS' },
    { id: PartySubRoleType.FW, value: 'FW' },
    { id: PartySubRoleType.MF, value: 'MF' },
    { id: PartySubRoleType.WH, value: 'WH' },
] as const;
