import { DeleteOutlined } from '@ant-design/icons';
import { TableRowSelection } from 'antd/lib/table/interface';
import Button from 'components/ui/base/button';
import Table from 'components/ui/base/table/Table';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import useIndividuals from 'hooks/useIndividuals';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Declaration, DeclarationAndAgent } from 'store/declarations/declaration';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { colors } from 'theme';
import { declarationHasBeenSubmitted } from '../../declarations/utils/form-utils';
import declarationColums, { declarationColumnsCoreTemplate, declarationColumsArchived } from './DeclarationColumns';
export enum DeclarationTableType {
    ARCHIVED = 'ARCHIVED',
    CORE_TEMPLATE = 'CORE_TEMPLATE',
    DECLARATIONS = 'DECLARATIONS',
    SELECTION = 'SELECTION',
}

interface Props {
    data: ListPayload<Declaration>;
    handleDuplicate?: (declaration: Declaration) => void;
    onDelete?: (ids: string[]) => void;
    handlePagination: (paginator: Partial<PaginatedParams>) => void;
    loading?: boolean;
    handleSort?: (paginator: Partial<PaginatedParams>) => void;
    type: DeclarationTableType;
    handleCoreTemplate?: (id: string) => void;
    viewDocuments?: (id: string) => void;
    setAsTemplate?: (id: string, type: DeclarationTableType) => void;
    onSelect?: (record: Declaration) => void;
    onPrint?: (declarationId: string) => void;
}

const DeclarationTable: FC<Props> = ({
    data,
    handleDuplicate,
    onDelete,
    handlePagination,
    loading,
    handleSort,
    handleCoreTemplate,
    viewDocuments,
    setAsTemplate,
    type,
    onSelect,
    onPrint,
}) => {
    const navigate = useNavigate();
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const [newData, setNewData] = useState<ListPayload<DeclarationAndAgent>>(data);
    const [loadingData, setLoadingData] = useState(false);

    const { getIndividual } = useIndividuals();
    const addAgentToDeclarations = useCallback(async () => {
        setLoadingData(true);
        const ids = data?.list?.map((d) => d?.individualId);
        Promise.all(
            ids.map(async (id) => {
                if (id) {
                    const agent = await getIndividual(id);
                    return agent?.fullName ?? 'N/A';
                } else {
                    return 'N/A';
                }
            })
        ).then((res: string[]) => {
            const declarations = { ...data };
            const declarationsWithAgent = declarations?.list.map((d, i) => ({ ...d, agent: res[i] }));
            declarations.list = declarationsWithAgent;
            setNewData(declarations);
            setLoadingData(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (data) {
            addAgentToDeclarations();
        }
    }, [data, addAgentToDeclarations]);

    const getInvalidStyle = useCallback((declaration: Declaration) => {
        return declaration.status === DeclarationStatus.REJECTED || declaration.status === DeclarationStatus.INVALID
            ? { style: { background: `${colors.invalid}`, border: 'none' } }
            : {};
    }, []);

    const rowSelection: TableRowSelection<any> = {
        type: 'checkbox',
        onChange: (selectedRowKeys: any, selectedRows: Declaration[]) => {
            const ids = selectedRows.filter((d) => d.id).map((c) => c.id) as string[];
            setDeleteIds(ids);
        },
        renderCell: (value: boolean, record: DeclarationAndAgent, index: number, originNode: React.ReactNode) => {
            return { props: getInvalidStyle(record), children: originNode };
        },
    };

    const onChange = (sorter: any) => {
        if (handleSort && !Array.isArray(sorter)) {
            const sortParameter = sorter.columnKey?.toString();
            if (sorter.order === 'ascend') {
                handleSort({ sortParameter, sortDirection: 'ASC' });
            } else if (sorter.order === 'descend') {
                handleSort({ sortParameter, sortDirection: 'DESC' });
            }
        }
    };

    const handleColumnActions = () => {
        switch (type) {
            case DeclarationTableType.DECLARATIONS:
                return declarationColums(handleDuplicate, onDelete, onPrint);
            case DeclarationTableType.ARCHIVED: {
                return declarationColumsArchived(handleCoreTemplate, setAsTemplate, viewDocuments);
            }
            case DeclarationTableType.CORE_TEMPLATE:
                return declarationColumnsCoreTemplate(handleCoreTemplate, setAsTemplate, viewDocuments);
            case DeclarationTableType.SELECTION:
                return declarationColums();
        }
    };

    return (
        <>
            <Table
                rowKey="id"
                columns={handleColumnActions()}
                dataSource={newData?.list}
                rowSelection={type ? undefined : rowSelection}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            event.stopPropagation();
                            if (onSelect && type === DeclarationTableType.SELECTION) {
                                onSelect(record);
                            } else if (declarationHasBeenSubmitted(record.status)) {
                                navigate(`/declarations/${record.id}/view-only`);
                            } else if (record.status === DeclarationStatus.RELEASED) {
                                navigate(`/declarations/${record.id}/summary`);
                            } else {
                                navigate(`/declarations/${record.id}`);
                            }
                        },
                    };
                }}
                pagination={{
                    current: data?.pageNumber + 1,
                    total: data?.total,
                    showSizeChanger: false,
                    pageSize: data?.pageSize,
                    position: ['bottomCenter'],
                    onChange: (page?: number, size?: number) => {
                        if (page && size) {
                            const params = { page: page - 1, size };
                            handlePagination(params);
                        }
                    },
                }}
                onChange={onChange}
                loading={loading || loadingData}
            />
            {deleteIds.length ? (
                <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        onDelete && onDelete(deleteIds);
                        setDeleteIds([]);
                    }}
                >
                    delete
                </Button>
            ) : (
                <></>
            )}
        </>
    );
};

export default DeclarationTable;
