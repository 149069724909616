import useDeclarations from 'hooks/useDeclarations';
import { FC, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';

interface Props {}

const DeclarationHistory: FC<Props> = () => {
    const { declarationId, historyId } = useParams();
    const { declarationHistory } = useDeclarations({ declarationId });

    const declaration = useMemo(() => {
        if (declarationHistory) {
            return declarationHistory.find((history) => history.id === historyId);
        }
    }, [historyId, declarationHistory]);

    return (
        <Outlet
            context={{
                declarationHistory: declarationHistory,
                declarationId: declarationId,
                declaration: declaration,
            }}
        />
    );
};

export default DeclarationHistory;
