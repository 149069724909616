import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import Container from 'components/ui/base/container';
import Divider from 'components/ui/base/divider';
import Drawer from 'components/ui/base/drawer/Drawer';
import CustomModal from 'components/ui/base/modal/Modal';
import Notification from 'components/ui/base/notification/Notification';
import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import { ErrorResponse, ListPayload, SubError } from 'core/http/response';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useCustomers from 'hooks/useCustomers';
import useJobs from 'hooks/useJobs';
import useRequest from 'hooks/useRequest';
import debounce from 'lodash.debounce';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteCustomer } from 'store/customers/client';
import { Customer } from 'store/customers/customer';
import { JobResponse } from 'store/jobs/job';
import CreateNewCustomer from 'views/customers/components/CreateNewCustomer';
import { ReverseCol } from 'views/dashboard/components/Dashboard.styles';
import CustomerDetails from './components/CustomerDetails';
import CustomersTable from './components/CustomersTable';

const Customers: FC = () => {
    const navigate = useNavigate();
    const { customerId } = useParams<{ customerId: string }>();
    const [showCreateCustomer, setShowCreateCustomer] = useState(false);
    const [showCustomerDetails, setShowCustomerDetails] = useState(false);
    const { listCustomers, customers, error, isLoading } = useCustomers();
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const [customerDetails, setCustomerDetails] = useState<{ customer: Customer; jobs: ListPayload<JobResponse> }>();
    const [customer, setCustomer] = useState<Customer>();
    const [customersAndJobs, setCustomersAndJobs] = useState<ListPayload<Customer & { jobs: number }>>();
    const { doRequest: doDeleteCustomer, error: deleteError } = useRequest(deleteCustomer);
    const [jobs, setJobs] = useState<ListPayload<JobResponse>[]>();
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>();
    const { listJobs } = useJobs();

    useEffect(() => {
        if (customerId) {
            handleDetails(customerId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers, jobs]);

    const querySearch = async (query: string) => {
        if (query) {
            const params = { query };
            await listCustomers(params);
        } else {
            await listCustomers();
        }
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    const { setBreadcrumbRoutes } = useBreadcrumb();
    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Customers',
                path: '',
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!customers.list && isLoading !== true) {
            listCustomers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers, isLoading]);

    useEffect(() => {
        if (!jobs?.length && customers && isLoading !== true) {
            getJobsFromCustomer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobs, customers, isLoading]);

    useEffect(() => {
        if (paginator) {
            listCustomers(paginator);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator]);

    const refreshCustomers = () => {
        setJobs(undefined);
        setCustomer(undefined);
        listCustomers();
        getJobsFromCustomer();
    };

    useEffect(() => {
        const data = { ...customers };
        if (jobs && isLoading !== true) {
            data.list = customers.list.map((c, i) => {
                if (jobs[i] && jobs[i].total) {
                    return { ...c, jobs: jobs[i].total };
                } else {
                    return { ...c, jobs: 0 };
                }
            });
            setCustomersAndJobs(data as ListPayload<Customer & { jobs: number }>);
        }
    }, [jobs, customers, isLoading]);

    useEffect(() => {
        if (deleteError) {
            if (deleteIds.length > 1) {
                Notification({
                    type: 'error',
                    messageTitle: 'Delete Error',
                    description: 'Failed to delete customers',
                });
            } else {
                Notification({
                    type: 'error',
                    messageTitle: 'Delete Error',
                    description: 'Failed to delete customer',
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteError]);

    const addedNewCustomer = () => {
        setJobs([]);
        setShowCreateCustomer(false);
        Notification({
            type: 'success',
            messageTitle: 'Customer Created',
            description: 'Customer has been successfully created!',
        });
    };

    const editedCustomer = () => {
        setShowCreateCustomer(false);
        Notification({
            type: 'success',
            messageTitle: 'Customer Edited',
            description: 'Customer has been successfully edited!',
        });
    };

    const getJobsFromCustomer = async () => {
        if (customers.total > 0) {
            const jobs = await Promise.all(
                customers.list.map((c) => {
                    return listJobs({ customerId: c.id });
                })
            );
            setJobs(jobs);
        }
    };

    const handleDelete = (ids: string[]) => {
        setDeleteIds(ids);
        setModalVisible(true);
    };

    const handleEdit = (id: string) => {
        const customer = customers.list.find((element) => element.id === id);
        setCustomer(customer);
        setShowCreateCustomer(true);
    };

    const handleDetails = (id: string) => {
        setShowCustomerDetails(true);
        const customer = customers.list.find((element) => element.id === id);
        if (customer && jobs) {
            const index = customers.list.indexOf(customer);
            setCustomerDetails({ customer: customer, jobs: jobs[index] });
        }
    };

    const deleteCustomers = () => {
        const toDelete: string[] = [...deleteIds];
        toDelete.map(async (id) => {
            await doDeleteCustomer(id);
            refreshCustomers();
        });
        if (!error) {
            setModalVisible(false);
            if (toDelete.length > 1) {
                Notification({
                    type: 'success',
                    messageTitle: 'Customers Deleted',
                    description: 'Customers has been successfully deleted!',
                });
            } else {
                Notification({
                    type: 'success',
                    messageTitle: 'Customer Deleted',
                    description: 'Customer has been successfully deleted!',
                });
            }
        }
    };

    const clearCustomerData = () => {
        setCustomer(undefined);
        setShowCreateCustomer(false);
    };

    const createCustomer = () => {
        setCustomer(undefined);
        setShowCreateCustomer(true);
    };

    const clearCustomerDetailsData = () => {
        if (customerId) {
            navigate(`/customers`);
        }
        setShowCustomerDetails(false);
        setCustomerDetails(undefined);
    };

    const handlePagination = (params: Partial<PaginatedParams>) => {
        if (paginator) {
            setPaginator({ page: params.page, size: paginator.size });
        } else {
            setPaginator({ page: params.page, size: defaultPagination.size });
        }
    };

    const handleSort = (params: Partial<PaginatedParams>) => {
        setPaginator({ ...paginator, ...params });
    };

    const handleEditError = (error: ErrorResponse) => {
        if (error?.subErrors) {
            createSubErrors(error.subErrors, 'edit');
        } else {
            Notification({
                type: 'error',
                messageTitle: 'Edit Error',
                description: 'Failed to edit customer!',
            });
        }
    };

    const handleCreateError = (error: ErrorResponse) => {
        if (error?.subErrors) {
            createSubErrors(error.subErrors, 'create');
        } else {
            Notification({
                type: 'error',
                messageTitle: 'Create Error',
                description: 'Failed to create customer!',
            });
        }
    };

    const createSubErrors = (subErrors: SubError[], action: String) => {
        if (subErrors.length === 1) {
            Notification({
                type: 'error',
                messageTitle: `Missing field ${subErrors[0].field}`,
                description: subErrors[0].message,
            });
        } else {
            Notification({
                type: 'error',
                messageTitle: `Multiple Errors`,
                description: `Multiple errors occurred when ${action} a customer`,
            });
        }
    };

    return (
        <>
            <Drawer
                title={!customer ? 'Add New Customer' : 'Edit Customer'}
                width="627"
                visible={showCreateCustomer}
                onClose={clearCustomerData}
                bodyPaddingRight={0}
            >
                <CreateNewCustomer
                    refreshCustomers={refreshCustomers}
                    closeModal={clearCustomerData}
                    handleOk={addedNewCustomer}
                    handleEdit={editedCustomer}
                    customer={customer}
                    handleCreateError={handleCreateError}
                    handleEditError={handleEditError}
                />
            </Drawer>

            <Container>
                <Row>
                    <Col span={18}>
                        <H5>Customers</H5>
                    </Col>
                    <ReverseCol span={6}>
                        <Button size="large" type="primary" onClick={createCustomer}>
                            Create New Customer
                        </Button>
                    </ReverseCol>
                </Row>
                <Divider />
                <SearchBar
                    inputPlaceholder="Search by EORI, customer name, email and phone number"
                    onClear={() => listCustomers()}
                    onSearch={(value) => debouncedSearch(value)}
                    style={{ marginBottom: '3.7rem' }}
                />

                <CustomersTable
                    handleSort={handleSort}
                    handlePagination={handlePagination}
                    onEdit={handleEdit}
                    onDetails={handleDetails}
                    data={customersAndJobs}
                    loading={isLoading}
                />
                <CustomModal
                    title={
                        deleteIds.length > 1 ? (
                            <H5>Do you want to remove these customers?</H5>
                        ) : (
                            <H5>Do you want to remove this customer?</H5>
                        )
                    }
                    centered
                    visible={modalVisible}
                    onOk={deleteCustomers}
                    onCancel={() => setModalVisible(false)}
                    width={762}
                    contentText={
                        deleteIds.length > 1
                            ? 'If you remove all these customers, you will lose all the information associated to them.'
                            : 'If you remove this customer, you will lose all the information associated with him.'
                    }
                />
            </Container>
            <Drawer
                title="Customers Details"
                width="627"
                visible={showCustomerDetails}
                onClose={clearCustomerDetailsData}
            >
                <CustomerDetails customer={customerDetails?.customer} jobs={customerDetails?.jobs}></CustomerDetails>
            </Drawer>
        </>
    );
};
export default Customers;
