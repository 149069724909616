import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { partySubRoleTypeLabels } from 'store/declarations/enums/uk/party-sub-role-type';
import BorderlessCard from 'views/declarations/common/cards/borderless-card/BorderlessCard';
import { DeclarationMultipleFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { FormCardContainer } from '../../../../../common/cards/NewFormCard';

const SupplyChainActorCard: FC<DeclarationMultipleFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    const index = props.index;
    const size = props.size;
    const last = index === size - 1;

    const state = useMemo(
        () =>
            getCardState(
                [
                    {
                        name: `additionalSupplyChainActors.${index}.additionalSupplyActorIdentifier`,
                        required: false,
                    },
                    {
                        name: `additionalSupplyChainActors.${index}.roleCode`,
                        required: false,
                    },
                ],
                props
            ),
        [index, props]
    );

    return (
        <BorderlessCard
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            title={`A. ${t('supplyChainActor.title')}`}
            multiple
            addCard={() => {
                if (last) {
                    props.add();
                }
            }}
            removeCard={() => {
                if (props.size > 1) {
                    props.remove(index);
                }
            }}
            isFirst={index === 0}
            isLast={last}
            keyCard={props.keyCard}
            state={state}
        >
            <FormCardContainer>
                <FormInput
                    maxLength={17}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`additionalSupplyChainActors.${index}.additionalSupplyActorIdentifier`, props)}
                    refNumber="3.37"
                    label={t('identificationNumber')}
                    tooltip={getCdsTooltipsByRefNumberAndField('3.37', t('identificationNumber'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`additionalSupplyChainActors.${index}.roleCode`, props)}
                    refNumber="3.37"
                    label={t('roleCode')}
                    selectOptions={partySubRoleTypeLabels}
                    tooltip={getCdsTooltipsByRefNumberAndField('3.37', t('roleCode'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </BorderlessCard>
    );
};
export default SupplyChainActorCard;
