import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { locationTypesLabels } from 'store/declarations/enums/common/location-types';
import { locationIdentificationQualifierLabels } from 'store/declarations/enums/ireland/location-identification-qualifier';
import addPathPrefix from 'utils/addPathPrefix';
import AddressForm from 'views/declarations/common/AddressForm';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';

interface Props {
    propsPathPrefix: string | undefined;
    viewOnly?: boolean;
    getFieldProps: (name: string) => FieldInputProps<any>;
    getFieldMeta?: (name: string) => FieldMetaProps<any>;
    getFieldHelpers: (name: string) => FieldHelperProps<any>;
    toggleHelp?: (refNumber: string | number) => void;
}
export const PlaceOfUseForm: FC<Props> = (props) => {
    const { propsPathPrefix, viewOnly, ...remainingProps } = props;
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <>
            <DeclarationSelect
                viewOnly={viewOnly}
                {...getFormikProps(addPathPrefix(propsPathPrefix, 'identificationOfLocation'), remainingProps)}
                refNumber="4.9"
                label={t('firstPlaceOfUse.identificationOfLocation')}
                selectOptions={normalizeCodesToSelect(codelists?.irelandLocationOfGoodsCodes || [])}
                tooltip={getH1TooltipsByRefNumberAndField('4.9', t('firstPlaceOfUse.identificationOfLocation'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                testId="firstPlaceOfUse.identificationOfLocation"
                condensed
            />

            <DeclarationSelect
                viewOnly={viewOnly}
                {...getFormikProps(addPathPrefix(propsPathPrefix, 'qualifierIdentification'), remainingProps)}
                refNumber="4.9"
                label={t('firstPlaceOfUse.qualifierIdentification')}
                selectOptions={locationIdentificationQualifierLabels}
                tooltip={getH1TooltipsByRefNumberAndField('4.9', t('firstPlaceOfUse.qualifierIdentification'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                testId="firstPlaceOfUse.qualifierIdentification"
                condensed
            />

            <DeclarationNumberInput
                viewOnly={viewOnly}
                {...getFormikProps(addPathPrefix(propsPathPrefix, 'additionalIdentifier'), remainingProps)}
                refNumber="4.9"
                label={t('firstPlaceOfUse.additionalIdentifier')}
                tooltip={getH1TooltipsByRefNumberAndField('4.9', t('firstPlaceOfUse.additionalIdentifier'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                testId="firstPlaceOfUse.additionalIdentifier"
                condensed
            />

            <DeclarationSelect
                viewOnly={viewOnly}
                {...getFormikProps(addPathPrefix(propsPathPrefix, 'locationTypeCode'), remainingProps)}
                refNumber="4.9"
                label={t('firstPlaceOfUse.locationTypeCode')}
                selectOptions={locationTypesLabels}
                tooltip={getH1TooltipsByRefNumberAndField('4.9', t('firstPlaceOfUse.locationTypeCode'))}
                refClicked={(ref) => handleToggleHelp(ref, props)}
                testId="firstPlaceOfUse.locationTypeCode"
                condensed
            />

            <AddressForm
                refClicked={(ref) => handleToggleHelp(ref, props)}
                viewOnly={viewOnly}
                fieldProps={{
                    streetAndNumber: props.getFieldProps(addPathPrefix(propsPathPrefix, 'address.streetAndNumber')),
                    country: props.getFieldProps(addPathPrefix(propsPathPrefix, 'address.country')),
                    postCode: props.getFieldProps(addPathPrefix(propsPathPrefix, 'address.postCode')),
                    city: props.getFieldProps(addPathPrefix(propsPathPrefix, 'address.city')),
                }}
                fieldMeta={
                    props.getFieldMeta && {
                        streetAndNumber: props.getFieldMeta(addPathPrefix(propsPathPrefix, 'address.streetAndNumber')),
                        country: props.getFieldMeta(addPathPrefix(propsPathPrefix, 'address.country')),
                        postCode: props.getFieldMeta(addPathPrefix(propsPathPrefix, 'address.postCode')),
                        city: props.getFieldMeta(addPathPrefix(propsPathPrefix, 'address.city')),
                    }
                }
                fieldHelper={
                    props.getFieldHelpers && {
                        streetAndNumber: props.getFieldHelpers(
                            addPathPrefix(propsPathPrefix, 'address.streetAndNumber')
                        ),
                        country: props.getFieldHelpers(addPathPrefix(propsPathPrefix, 'address.country')),
                        postCode: props.getFieldHelpers(addPathPrefix(propsPathPrefix, 'address.postCode')),
                        city: props.getFieldHelpers(addPathPrefix(propsPathPrefix, 'address.city')),
                    }
                }
                refNumbers={{ streetAndNumber: '4.9', city: '4.9', country: '4.9', postcode: '4.9' }}
                condensed
            />
        </>
    );
};

export default PlaceOfUseForm;
