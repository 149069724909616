import { Button, Collapse, Divider, Typography } from 'antd';
import {
    IrelandEnsNotification,
    IrelandEnsSubmissionErrors,
} from 'store/declarations/ireland/entry-summary-notification';
import { ReactElement, ReactNode, useMemo, useState } from 'react';
import { ButtonText, DetailsGrid, Error, MainGrid } from './declaration-errors.styles';
import { NotificationHeader } from './UkExportDeclarationErrors';
import { isEmpty, sortBy } from 'lodash';
import { LinkButton } from '../../../../../components/ui/base/button/Button.styles';
import { useTranslation } from 'react-i18next';
import useDeclarations from '../../../../../hooks/useDeclarations';
import { DeclarationHistory } from '../../../../../store/declarations/declaration';

const CONDITIONS_CODE_URL =
    'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fwww.revenue.ie%2Fen%2Fonline-services%2Fsupport%2Fsoftware-developers%2Fdocuments%2Fimport-control-system%2Fvalidation-error-codes.xls&wdOrigin=BROWSELINK';
const RULE_DETAILS_URL =
    'https://www.revenue.ie/en/online-services/support/software-developers/documents/import-control-system/import-control-system.pdf';

type SubmissionErrors = IrelandEnsNotification['submissionErrors'];

const { Text, Title, Link } = Typography;

const ErrorsList = ({
    errors,
    error,
    onShowDetails,
}: {
    errors?: IrelandEnsSubmissionErrors[];
    error?: IrelandEnsSubmissionErrors | null;
    onShowDetails?: (error: IrelandEnsSubmissionErrors) => void;
}) => {
    if (error) {
        const { businessRuleCode, errorReason } = error;
        return (
            <MainGrid>
                <Error>{businessRuleCode ?? 'n/a'}</Error>
                {errorReason ? (
                    <Link href={CONDITIONS_CODE_URL} target="_blank" style={{ width: 100 }}>
                        {errorReason}
                    </Link>
                ) : (
                    <Text>{'n/a'}</Text>
                )}
            </MainGrid>
        );
    }

    return (
        <>
            {errors?.map((_error) => {
                const { businessRuleCode, errorReason } = _error;
                return (
                    <MainGrid>
                        <Error>{businessRuleCode ?? 'n/a'}</Error>
                        {errorReason ? (
                            <Link href={CONDITIONS_CODE_URL} target="_blank" style={{ width: 100 }}>
                                {errorReason}
                            </Link>
                        ) : (
                            <Text>{'n/a'}</Text>
                        )}

                        <ButtonText onClick={() => onShowDetails?.(_error)}>Show details</ButtonText>
                    </MainGrid>
                );
            })}
        </>
    );
};

const DetailedError = ({
    error,
    onBack: handleBack,
    errorsRender,
}: {
    error: IrelandEnsSubmissionErrors | null;
    onBack: () => void;
    errorsRender: ReactNode;
}) => {
    return (
        <>
            <Button onClick={handleBack}>Back to list</Button>
            <Title level={3} style={{ marginTop: '2rem', fontWeight: 700 }}>
                Condition details
            </Title>
            <DetailsGrid style={{ marginTop: '2rem' }}>
                <Text strong>Field:</Text>
                <Text>{error?.externalField ?? 'n/a'}</Text>
            </DetailsGrid>
            <Divider />
            <>
                <NotificationHeader title="Condition code" />
                {errorsRender}
            </>
            <Link style={{ marginTop: '5rem', display: 'inline-block' }} href={CONDITIONS_CODE_URL} target="_blank">
                ENS Condition Code List
            </Link>
        </>
    );
};

const PreviousErrors = ({ notifications }: { notifications?: IrelandEnsNotification[] }): ReactElement | null => {
    const { declarationHistory } = useDeclarations();
    const lastDateOfSubmit = useMemo(() => getLastDateOfSubmit(declarationHistory), [declarationHistory]);
    const [error, setError] = useState<IrelandEnsSubmissionErrors | null>(null);

    const notificationsWithErrorsAfterSubmit = useMemo(() => {
        const _notifications = notifications && [...notifications];
        _notifications?.pop();
        _notifications?.reverse();
        return _notifications?.filter(
            (notification) =>
                lastDateOfSubmit &&
                notification?.notificationDate &&
                !isEmpty(notification.submissionErrors) &&
                lastDateOfSubmit < notification?.notificationDate
        );
    }, [notifications, lastDateOfSubmit]);

    const defaultActiveKey = useMemo(() => {
        return Array(notificationsWithErrorsAfterSubmit?.length)
            .fill(null)
            .map((_, index) => index);
    }, [notificationsWithErrorsAfterSubmit?.length]);

    const notificationsList = useMemo(() => {
        if (!notificationsWithErrorsAfterSubmit || !notificationsWithErrorsAfterSubmit.length)
            return 'No previous errors';
        return (
            <Collapse defaultActiveKey={defaultActiveKey}>
                {notificationsWithErrorsAfterSubmit?.map((notification, index) => {
                    return (
                        <Collapse.Panel
                            header={new Date(notification.notificationDate ?? '').toLocaleString()}
                            key={index}
                        >
                            {error ? (
                                <DetailedError
                                    error={error}
                                    onBack={() => setError(null)}
                                    errorsRender={<ErrorsList error={error} />}
                                />
                            ) : (
                                <>
                                    <NotificationHeader />
                                    <ErrorsList
                                        errors={notification.submissionErrors}
                                        onShowDetails={(err: IrelandEnsSubmissionErrors) => setError(err)}
                                    />
                                </>
                            )}
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        );
    }, [defaultActiveKey, error, notificationsWithErrorsAfterSubmit]);

    if (isEmpty(notificationsList)) return null;

    return (
        <Collapse defaultActiveKey="1">
            <Collapse.Panel header="Previous errors" key={1}>
                {notificationsList}
            </Collapse.Panel>
        </Collapse>
    );
};

const IrelandEnsDeclarationErrors = ({ errors, notifications }: SpecificDeclarationErrorsProps<SubmissionErrors>) => {
    const { t } = useTranslation('common');
    const [selectedError, setSelectedError] = useState<IrelandEnsSubmissionErrors | null>();

    if (!!selectedError) {
        return (
            <DetailedError
                error={selectedError}
                onBack={() => setSelectedError(null)}
                errorsRender={<ErrorsList error={selectedError} />}
            />
        );
    }

    return (
        <>
            <LinkButton href={RULE_DETAILS_URL} target="_blank" style={{ marginBottom: 20 }}>
                {t('ruleDetails')}
            </LinkButton>
            <NotificationHeader title="Condition code" />
            <ErrorsList errors={errors} onShowDetails={(err: IrelandEnsSubmissionErrors) => setSelectedError(err)} />
            <div style={{ marginTop: '3rem' }}>
                <PreviousErrors notifications={notifications} />
            </div>
        </>
    );
};

export default IrelandEnsDeclarationErrors;

export const getLastDateOfSubmit = (declarationHistory: DeclarationHistory[] | undefined) => {
    if (!declarationHistory) return;

    const filteredDeclarationHistory = declarationHistory?.filter((history) => history.type === 'SUBMIT');
    const lastDeclarationHistoryIndex = declarationHistory?.length && declarationHistory?.length - 1;

    const dateOfLastSubmit = sortBy(filteredDeclarationHistory, 'createdAt')[lastDeclarationHistoryIndex!]?.createdAt;

    return dateOfLastSubmit;
};
