import { FC } from 'react';
import { NavItem } from 'views/declarations/common/nav-item';
import { ValidationSchema } from 'views/declarations/uk/export/validations/validations';
import { AnySchema } from 'yup';
import { sectionsENSProducts } from '../../declarations/ireland/ens/constants';
import EnsProductSection from '../../declarations/ireland/ens/EnsProductSection';
import { goodsItems as ieEnsProductValidationSchema } from '../../declarations/ireland/ens/validation/irelandEnsDrafValidation';
import { parseEnsProductTemplate } from '../../declarations/ireland/ens/validation/parseValidationErrors';
import { sectionsH1Products, sectionsH7Products } from '../../declarations/ireland/import/constants';
import IrelandH1ProductsSection from '../../declarations/ireland/import/h1/IrelandH1ProductsSection';
import { parseH1ProductTemplate } from '../../declarations/ireland/import/h1/validation/utils';
import { goodsShipmentItemSchemaRequired as ieH1ProductValidationSchema } from '../../declarations/ireland/import/h1/validation/ValidationSchema';
import IrelandH7ProductsSection from '../../declarations/ireland/import/h7/IrelandH7ProductsSection';
import governmentAgencyGoodsItem from '../../declarations/ireland/import/h7/validation/H7ProductValidationSchema';
import { parseH7ProductTemplate } from '../../declarations/ireland/import/h7/validation/utils';
import { exportSectionsProducts } from '../../declarations/uk/export/constants';
import { parseExportProductTemplate } from '../../declarations/uk/export/parseValidationErrors';
import UkExportProductsSection, {
    cdsExportProductSectionValidation,
} from '../../declarations/uk/export/UkExportProductsSection';
import { sectionsUkH1Products } from '../../declarations/uk/import/h1/constants';
import UkH1ProductsSection from '../../declarations/uk/import/h1/UkH1ProductsSection';
import ukImportProductValidationSchema from '../../declarations/uk/import/h1/validation/UKProductValidationSchema';
import { parseUkH1ProductTemplate } from '../../declarations/uk/import/h1/validation/utils';

type ProductTypeMapHelpers = {
    [key in string]: {
        saveProductTemplateFuncName: string;
        editProductTemplateFuncName: string;
        listTagsFuncName: string;
        validationErrorsParser: Function;
        validationSchema?: AnySchema;
        validate?: ValidationSchema;
        sectionProducts: NavItem[];
        productSection: FC<any>;
    };
};

export const productTypeMapHelpers: ProductTypeMapHelpers = {
    ireland_h1: {
        saveProductTemplateFuncName: 'saveH1ProductTemplate',
        editProductTemplateFuncName: 'editH1ProductTemplate',
        listTagsFuncName: 'listH1Tags',
        validationErrorsParser: parseH1ProductTemplate,
        validationSchema: ieH1ProductValidationSchema,
        sectionProducts: sectionsH1Products,
        productSection: IrelandH1ProductsSection,
    },
    ireland_h7: {
        saveProductTemplateFuncName: 'saveH7ProductTemplate',
        editProductTemplateFuncName: 'editH7ProductTemplate',
        listTagsFuncName: 'listH7Tags',
        validationErrorsParser: parseH7ProductTemplate,
        validationSchema: governmentAgencyGoodsItem,
        sectionProducts: sectionsH7Products,
        productSection: IrelandH7ProductsSection,
    },
    uk_h1: {
        saveProductTemplateFuncName: 'saveUkProductTemplate',
        editProductTemplateFuncName: 'editUkProductTemplate',
        listTagsFuncName: 'listH1Tags',
        validationErrorsParser: parseUkH1ProductTemplate,
        validationSchema: ukImportProductValidationSchema,
        sectionProducts: sectionsUkH1Products,
        productSection: UkH1ProductsSection,
    },
    uk_b1: {
        saveProductTemplateFuncName: 'saveCdsExportProductTemplate',
        editProductTemplateFuncName: 'editCdsExportProductTemplate',
        listTagsFuncName: 'listCdsExportTags',
        validationErrorsParser: parseExportProductTemplate,
        validate: cdsExportProductSectionValidation,
        sectionProducts: exportSectionsProducts,
        productSection: UkExportProductsSection,
    },
    ireland_ens: {
        saveProductTemplateFuncName: 'saveEnsProductTemplate',
        editProductTemplateFuncName: 'editEnsProductTemplate',
        listTagsFuncName: 'listEnsTags',
        validationErrorsParser: parseEnsProductTemplate,
        validationSchema: ieEnsProductValidationSchema,
        sectionProducts: sectionsENSProducts,
        productSection: EnsProductSection,
    },
};

export type SaveProductTemplateFunc =
    | 'saveH1ProductTemplate'
    | 'saveH7ProductTemplate'
    | 'saveUkProductTemplate'
    | 'saveCdsExportProductTemplate'
    | 'saveEnsProductTemplate';
export type EditProductTemplateFunc =
    | 'editH1ProductTemplate'
    | 'editH7ProductTemplate'
    | 'editUkProductTemplate'
    | 'editCdsExportProductTemplate'
    | 'editEnsProductTemplate';
export type ListTagsFuncName = 'listH1Tags' | 'listH7Tags' | 'listCdsExportTags' | 'listEnsTags';
