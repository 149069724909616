export enum LocationIdentificationQualifier {
    U = 'U',
    V = 'V',
    X = 'X',
    Y = 'Y',
    Z = 'Z',
}
export const locationIdentificationQualifierLabels = [
    { id: LocationIdentificationQualifier.U, value: 'UN/LOCODE' },
    { id: LocationIdentificationQualifier.V, value: 'Customs office identifier' },
    { id: LocationIdentificationQualifier.X, value: 'EORI number' },
    { id: LocationIdentificationQualifier.Y, value: 'Authorisation number' },
    { id: LocationIdentificationQualifier.Z, value: 'Free text' },
] as const;
