import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import useRequest from 'hooks/useRequest';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { documentsPreUpload } from 'store/documents/client';
import { H5Style } from '../../../../../components/ui/base/typography/Typography.styles';
import { beforeUpload, handleOnChange } from './UploadUtils';
import { DeclarationCountry } from '../../../../../store/declarations/enums/common/declaration-country';
import { DeclarationDocument } from '../types';

const { Dragger } = Upload;
interface Props {
    declarationId: string;
    customerId: string;
    country: DeclarationCountry;
    listCmsFiles: () => void;
    setCmsFileAndFormAction: Function;
    addDeclarationDocument: (newDoc: DeclarationDocument) => void;
}

const UploadDocuments: FC<Props> = ({
    listCmsFiles,
    customerId,
    declarationId,
    setCmsFileAndFormAction,
    country,
    addDeclarationDocument,
}) => {
    const { data, doRequest: preUpload } = useRequest(documentsPreUpload);
    const url = data === null ? '' : data.preSignedUrl;
    const { t } = useTranslation('customs_declarations');

    const onChangeHandler = (uploadedFileData: UploadChangeParam<UploadFile<any>>) => {
        const dataForRequests = { uploadedFileData: uploadedFileData.file, customerId, declarationId };
        return handleOnChange(dataForRequests, listCmsFiles, setCmsFileAndFormAction, country, addDeclarationDocument);
    };

    return (
        <div style={{ marginBottom: '2rem' }}>
            <H5Style style={{ marginBottom: '1rem' }}>{t('uploadDocuments')}</H5Style>

            <Dragger
                id="file-upl"
                action={url}
                onChange={(uploadedFileData: UploadChangeParam<UploadFile<any>>) => onChangeHandler(uploadedFileData)}
                beforeUpload={(file: RcFile) => beforeUpload(file, customerId, declarationId, preUpload)}
                method="PUT"
                style={{ width: 'auto', maxHeight: '170px' }}
                showUploadList={false}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('ClickOrDragFile')}</p>
                <p className="ant-upload-hint">{t('supportForSingleBulkOrUpload')}</p>
            </Dragger>
        </div>
    );
};

export default UploadDocuments;
