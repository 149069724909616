import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { TextSmallBold } from 'components/ui/base/typography';
import { FC, useMemo } from 'react';
import { declarationNameLabels } from 'store/declarations/enums/common/declaration-name';
import { FilterCheckbox, FilterGroup, FilterOption } from './Filter.styles';

interface Props {
    selectedType: (status: string[]) => void;
    checked: string[];
}
export const DeclarationTypeFilter: FC<Props> = ({ selectedType, checked }) => {
    const type = useMemo(() => declarationNameLabels.map((s) => s.id), []);

    const onChange = (checkedValues: CheckboxValueType[]) => {
        selectedType(checkedValues as string[]);
    };

    return (
        <FilterGroup onChange={onChange} defaultValue={checked}>
            {type.map((t) => (
                <FilterOption>
                    <FilterCheckbox value={t}>
                        <TextSmallBold>{t}</TextSmallBold>
                    </FilterCheckbox>
                </FilterOption>
            ))}
        </FilterGroup>
    );
};

export default DeclarationTypeFilter;
