import { NavItem } from 'views/declarations/common/nav-item';

export const sectionsUkH1MainDocument: NavItem[] = [
    { title: 'Master Details' },
    { title: 'Previous Document' },
    { title: 'Additional Information' },
    { title: 'Deferred Payment' },
    { title: 'Identification Warehouse' },
    { title: 'Parties' },
    { title: 'Holder of the authorisation identification No.' },
    { title: 'Delivery Terms' },
    { title: 'Total Amount Invoiced' },
    { title: 'Exchange Rate' },
    { title: 'Transport costs to the final destination' },
    { title: 'Country of Destination' },
    { title: 'Place of loading' },
    { title: 'Location of Goods' },
    { title: 'Customs Office of Presentation' },
    { title: 'Gross Mass' },
    { title: 'Mode of Transport At The Border' },
    { title: 'Identity of means of transport on arrival' },
    { title: 'Guarantee Types' },
    { title: 'Guarantee Reference' },
    { title: 'Nature of Transaction' },
    { title: 'Acceptance Date' },
    { title: 'Additional Conditional Information' },
];

export const sectionsUkH1Products: NavItem[] = [
    { title: 'Taric Code' },
    { title: 'Goods Information' },
    { title: 'Procedure' },
    { title: 'Additional Procedure Code' },
    { title: 'Previous Document' },
    { title: 'Additional Information' },
    { title: 'Document produced' },
    { title: 'Parties' },
    { title: 'Packages' },
    { title: 'Additional Taric Code' },
    { title: 'Additional National Code' },
    { title: 'Additional Conditional Information' },
];
