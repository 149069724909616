import { colors } from '../../../../../theme';
import { DeclarationDocument } from '../../../../../views/declarations/sections/customer-documents/types';
import StatusTag from '../../../base/status/StatusTag';

const DeclarationDocumentsStatus = {
    REQUIRED: 'Required',
    SUBMITTED: 'Submitted',
    NA: 'N/A',
} as const;

const declarationDocumentsStatusColor = {
    [DeclarationDocumentsStatus.REQUIRED]: colors.darkOrange,
    [DeclarationDocumentsStatus.SUBMITTED]: colors.lightGreenA70,
    [DeclarationDocumentsStatus.NA]: colors.grey,
};

interface Props {
    documentsUploadRequested: boolean | undefined;
    uploadedDocuments: DeclarationDocument[] | [] | undefined;
}

const DeclarationDocumentsStatusTag = ({ documentsUploadRequested, uploadedDocuments }: Props) => {
    if (documentsUploadRequested) {
        return (
            <StatusTag
                color={declarationDocumentsStatusColor[DeclarationDocumentsStatus.REQUIRED]}
                status={DeclarationDocumentsStatus.REQUIRED}
            />
        );
    }

    if (uploadedDocuments?.some((doc) => doc.status === 'SUBMITTED')) {
        return (
            <StatusTag
                color={declarationDocumentsStatusColor[DeclarationDocumentsStatus.SUBMITTED]}
                status={DeclarationDocumentsStatus.SUBMITTED}
            />
        );
    }

    return (
        <StatusTag
            color={declarationDocumentsStatusColor[DeclarationDocumentsStatus.NA]}
            status={DeclarationDocumentsStatus.NA}
        />
    );
};

export default DeclarationDocumentsStatusTag;
