import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';
import MultipleItemsCard from '../../../../common/MultipleItemsCard';
import { FormikProps } from 'formik';
import addPathPrefix from '../../../../../../utils/addPathPrefix';
import { previousDocuments } from '../validation/UKCommonValidations';

const PreviousDocumentCard: FC<DeclarationFormCardProps & { formik: FormikProps<any> }> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix!}
            initialValues={{
                previousDocumentCategory: '',
                previousDocumentType: '',
                previousDocumentReference: '',
                goodsItemIdentifier: '',
            }}
            list={(list) => [
                { field: t('previousDocumentCategory'), value: list.previousDocumentCategory },
                { field: t('previousDocumentType'), value: list.previousDocumentType },
                { field: t('previousDocumentReference'), value: list.previousDocumentReference },
                { field: t('goodsItemIdentifier'), value: list.goodsItemIdentifier },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            cardNumber={props.cardNumber}
            cardTotal={props.cardTotal}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            title={t('previousDocument')}
            condensed
            required
            formik={props.formik}
            validationSchema={previousDocuments}
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormSelect
                        required
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'previousDocumentCategory')}`, formProps)}
                        refNumber="2.1"
                        label={t('previousDocumentCategory')}
                        selectOptions={normalizeCodesToSelect(codelists?.ukDocumentCategories || [])}
                        tooltip={getCdsTooltipsByRefNumberAndField('2.1', t('previousDocumentCategory'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormSelect
                        required
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'previousDocumentType')}`, formProps)}
                        refNumber="2.1"
                        label={t('previousDocumentType')}
                        selectOptions={normalizeCodesToSelect(codelists?.ukImportPreviousDocuments || [])}
                        tooltip={getCdsTooltipsByRefNumberAndField('2.1', t('previousDocumentType'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormInput
                        required
                        maxLength={35}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'previousDocumentReference')}`, formProps)}
                        refNumber="2.1"
                        label={t('previousDocumentReference')}
                        tooltip={getCdsTooltipsByRefNumberAndField('2.1', t('previousDocumentReference'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <DeclarationNumberInput
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`${addPathPrefix(path, 'goodsItemIdentifier')}`, formProps)}
                        refNumber="2.1"
                        label={t('goodsItemIdentifier')}
                        tooltip={getCdsTooltipsByRefNumberAndField('2.1', t('goodsItemIdentifier'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default PreviousDocumentCard;
