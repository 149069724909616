import { IrelandH7ImportDeclaration } from 'store/declarations/ireland/h7-import-declaration';
import {
    getCustomerValidation,
    getEoriValidation,
    getExactLengthValidation,
    getMaxValidation,
    getNumberOfItemsValidation,
    getRequiredMessage,
    Shape,
} from 'views/declarations/utils/validation-utils';
import * as Yup from 'yup';
import { governmentAgencyGoodsItem } from './H7ProductValidationSchema';
import { valuationInformation } from './H7CommonValidations';

// fiscal reference card validation is in its own component

const h7Validation = (numberOfItems?: number) =>
    Yup.object().shape<Shape<IrelandH7ImportDeclaration>>({
        numberOfItems: getNumberOfItemsValidation(numberOfItems)
            .required(getRequiredMessage('numberOfItems'))
            .max(5)
            .nullable(),
        additionalDeclarationType: getMaxValidation('additionalDeclarationType', 3)
            .required(getRequiredMessage('additionalDeclarationType'))
            .nullable(),
        customsOfficeLodgement: getExactLengthValidation('customsOfficeOfLodgement', 8)
            .required(getRequiredMessage('customsOfficeOfLodgement'))
            .nullable(),
        deferredPayment: getMaxValidation('deferredPayment', 35).nullable(),
        preferredPaymentMethod: getExactLengthValidation('preferredPaymentMethod', 1).nullable(),
        representativeIdentificationNumber: getEoriValidation('representativeIdentificationNumber'),
        representativeStatus: getExactLengthValidation('representativeStatus', 1).nullable(),
        goodsShipment: Yup.object()
            .shape({
                governmentAgencyGoodsItem: Yup.array()
                    .of(governmentAgencyGoodsItem)
                    .required(getRequiredMessage('governmentAgencyGoodsItem'))
                    .nullable(),
                valuationInformation: valuationInformation,
                exporter: getCustomerValidation({ postCode: { max: 17 } }).nullable(),
                importer: getCustomerValidation({ postCode: { max: 17 } }).nullable(),
                locationOfGoodsTypeOfLocation: getExactLengthValidation('locationOfGoodsTypeOfLocation', 1).nullable(),
                locationOfGoodsQualifierOfIdentification: getExactLengthValidation(
                    'locationOfGoodsQualifierOfIdentification',
                    1
                ).nullable(),
                locationOfGoodsUnlocode: getMaxValidation('locationOfGoodsUnlocode', 17)
                    .required(getRequiredMessage('unlocode'))
                    .nullable(),
                locationOfGoodsAdditionalIdentifier: getMaxValidation(
                    'locationOfGoodsAdditionalIdentifier',
                    4
                ).nullable(),
                locationOfGoodsAddress: Yup.object({
                    postCode: getMaxValidation('postCode', 17).nullable(),
                    streetAndNumber: getMaxValidation('streetAndNumber', 70).nullable(),
                    city: getMaxValidation('city', 35).nullable(),
                    country: getMaxValidation('country', 2).nullable(),
                }).nullable(),
            })
            .required('Goods Shipment is required')
            .nullable(),
        declarant: getCustomerValidation({ eori: { required: true }, postCode: { max: 17 } }).nullable(),
    });

export default h7Validation;
