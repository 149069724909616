import { FormikProvider, useFormik } from 'formik';
import useProducts from 'hooks/useProducts';
import { FC, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import TaricCodesCard, { TaricDetailsType } from 'views/declarations/common/taric-codes/TaricCodesCard';
import { updateUkImportTaricCode } from 'views/declarations/common/taric-codes/utils';
import {
    addCardToMultipleCards,
    deleteCardToMultipleCards,
    initializeMultipleCards,
} from 'views/declarations/utils/form-utils';
import AdditionalConditionalInformationCard, {
    DeclarationFormType,
} from './cards/additional-conditional-information/AdditionalConditionalInformationCard';
import AdditionalInformationCard from './cards/AdditionalnformationCard';
import PreviousDocumentCard from './cards/PreviousDocumentCard';
import AdditionalNationalCodeCard from './cards/products/AdditionalNationalCodeCard';
import AdditionalProcedureCard from './cards/products/AdditionalProcedureCard';
import AdditionalTaricCodeCard from './cards/products/AdditionalTaricCodeCard';
import DocumentProducedCard from './cards/products/DocumentProducedCard';
import ItemFieldsCard from './cards/products/ItemFieldsCard';
import PackagingCard from './cards/products/PackagingCard';
import ProcedureCard from './cards/products/ProcedureCard';
import { ProductContext } from 'views/declarations/common/declaration-view/DeclarationView';
import PartiesCard from '../../../common/parties/PartiesCard';
import { ukImportProductTransformedForClient } from './utils';
import FiscalReferenceCard from '../../../common/fiscal-reference/FiscalReferenceCard';

interface Props {
    formik: ReturnType<typeof useFormik>; // FIXME use correct type
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    productTemplate?: boolean;
}

const UkH1ProductsSection: FC<Props> = ({ toggleHelp, formik, viewOnly, productTemplate }) => {
    const { productId, declarationId } = useParams<string>();
    const { getUkImportProduct } = useProducts();
    const additionalSupplyChain = initializeMultipleCards(formik, 'additionalSupplyChainActors');
    const additionsAndDeductions = initializeMultipleCards(formik, 'additionDeductions');
    const calculationTaxes = initializeMultipleCards(formik, 'calculationOfTaxes');
    const containerIdentificationNumbers = initializeMultipleCards(formik, 'containerIdentificationNumbers');
    const { setProductId } = useContext(ProductContext);

    useEffect(() => {
        const fetchProduct = async (declarationId: string, productId: string) => {
            const product = await getUkImportProduct(declarationId, productId);
            setProductId?.(productId);
            formik.setValues(ukImportProductTransformedForClient(product) ?? {});
        };

        if (declarationId && productId && productId !== 'new') {
            const { values } = formik;

            if (values.id !== productId) {
                fetchProduct(declarationId, productId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, declarationId]);

    return (
        <FormikProvider value={formik}>
            <TaricCodesCard
                keyCard={`taric-codes-card`}
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                toggleHelp={toggleHelp}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                cardTotal={14}
                cardNumber={1}
                updateTaricCode={(code) => updateUkImportTaricCode(formik, code)}
                country={DeclarationCountry.UK}
                detailsType={productTemplate ? TaricDetailsType.BOTH : TaricDetailsType.IMPORT}
                path="commodityCombinedNomenclatureCodeIdentifier"
            />

            <ItemFieldsCard
                keyCard={`item-fields-card`}
                viewOnly={viewOnly}
                cardTotal={14}
                cardNumber={2}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
            />
            <ProcedureCard
                viewOnly={viewOnly}
                cardTotal={14}
                cardNumber={3}
                keyCard={`procedure-card`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
            />
            <AdditionalProcedureCard
                viewOnly={viewOnly}
                keyCard={`additional-procedure-card`}
                cardTotal={14}
                cardNumber={4}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
            />

            <PreviousDocumentCard
                propsPathPrefix="previousDocuments"
                viewOnly={viewOnly}
                key={`previous-docs`}
                keyCard={`previous-docs`}
                cardTotal={14}
                cardNumber={5}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                formik={formik}
            />

            <AdditionalInformationCard
                propsPathPrefix="additionalInformations"
                viewOnly={viewOnly}
                key={`additional-info`}
                keyCard={`additional-info`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={14}
                cardNumber={6}
            />

            <DocumentProducedCard
                propsPathPrefix="documentsProduced"
                viewOnly={viewOnly}
                key={`document-produced`}
                keyCard={`document-produced`}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={14}
                cardNumber={7}
            />

            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'seller',
                        header: 'Seller',
                        refNumber: '3.24',
                        hasPhoneNumber: true,
                    },
                    {
                        path: 'buyer',
                        header: 'Buyer',
                        refNumber: '3.26',
                        hasPhoneNumber: true,
                    },
                ]}
                paths={{
                    address: {
                        city: 'city',
                        country: 'country',
                        name: 'name',
                        postCode: 'postCode',
                        streetAndNumber: 'streetAndNumber',
                    },
                    eori: 'eori',
                    additional: {
                        phoneNumber: 'phoneNumber',
                    },
                }}
                viewOnly={viewOnly}
                toggleHelp={toggleHelp}
                cardTotal={9}
                cardNumber={2}
                keyCard={`parties-card`}
                condensed
            />

            <PackagingCard
                viewOnly={viewOnly}
                key={`packaging`}
                keyCard={`packaging`}
                cardTotal={14}
                cardNumber={11}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
            />

            <AdditionalTaricCodeCard
                propsPathPrefix="additionalTaricCodes"
                viewOnly={viewOnly}
                key={`additional-taric-code`}
                keyCard={`additional-taric-code`}
                cardTotal={14}
                cardNumber={12}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
            />

            <AdditionalNationalCodeCard
                propsPathPrefix="additionalNationalCodes"
                viewOnly={viewOnly}
                keyCard={`additional-national-code`}
                key={`additional-national-code`}
                cardTotal={14}
                cardNumber={13}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
            />

            <FiscalReferenceCard
                viewOnly={viewOnly}
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={28}
                cardNumber={27}
                keyCard={`fiscal-ref`}
                propsPathPrefix={'additionalFiscalReferenceIdentifications'}
            />

            <AdditionalConditionalInformationCard
                formType={DeclarationFormType.PRODUCTS}
                viewOnly={viewOnly}
                getFieldMeta={formik.getFieldMeta}
                getFieldProps={formik.getFieldProps}
                getFieldHelpers={formik.getFieldHelpers}
                toggleHelp={toggleHelp}
                cardTotal={14}
                cardNumber={14}
                keyCard={`additional-conditional-card`}
                supplyChainActor={additionalSupplyChain}
                addSupplyChainActor={() =>
                    addCardToMultipleCards(formik, `additionalSupplyChainActors`, additionalSupplyChain)
                }
                removeSupplyChainActor={(indexToDelete) =>
                    deleteCardToMultipleCards(
                        formik,
                        `additionalSupplyChainActors`,
                        additionalSupplyChain,
                        indexToDelete
                    )
                }
                additionsAndDeductions={additionsAndDeductions}
                addAdditionsAndDeductions={() =>
                    addCardToMultipleCards(formik, `additionsDeductions`, additionsAndDeductions)
                }
                removeAdditionsAndDeductions={(indexToDelete) =>
                    deleteCardToMultipleCards(formik, `additionsDeductions`, additionsAndDeductions, indexToDelete)
                }
                calculationOfTaxes={calculationTaxes}
                addCalculationOfTaxes={() => addCardToMultipleCards(formik, `calculationOfTaxes`, calculationTaxes)}
                removeCalculationOfTaxes={(indexToDelete) =>
                    deleteCardToMultipleCards(formik, `calculationOfTaxes`, calculationTaxes, indexToDelete)
                }
                containerIdentificationNumbers={containerIdentificationNumbers}
                addContainerIdentificationNumbers={() =>
                    addCardToMultipleCards(formik, `containerIdentificationNumbers`, containerIdentificationNumbers)
                }
                removeContainerIdentificationNumbers={(indexToDelete) =>
                    deleteCardToMultipleCards(
                        formik,
                        `containerIdentificationNumbers`,
                        containerIdentificationNumbers,
                        indexToDelete
                    )
                }
            />
        </FormikProvider>
    );
};
export default UkH1ProductsSection;
