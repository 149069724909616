import { SpaceBetween } from 'components/styles/layout.styles';
import Button from 'components/ui/base/button';
import Container from 'components/ui/base/container';
import Divider from 'components/ui/base/divider';
import SearchBar from 'components/ui/base/searchbar/SearchBar';
import { H5 } from 'components/ui/base/typography';
import useSetup from 'hooks/useSetup';
import useWeighbridges from 'hooks/useWeighbridges';
import { debounce } from 'lodash';
import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import AfterWC from './components/AfterWC';
import WeighbridgesTable from './components/WeighbridgesTable';
import { Weighbridge } from 'store/weighbridges/weighbridge';
import { useTranslation } from 'react-i18next';

const WeighbridgesDashboard = () => {
    const { t } = useTranslation('weighbridge');

    const { listWeighbridges } = useWeighbridges();
    const { isInSetupProcess } = useSetup();

    const navigate = useNavigate();

    const [selectedWeighbridge, setSelectedWeighbridge] = useState<Weighbridge | undefined>(undefined);

    const disabledCalibrateDevice = useMemo((): boolean => !selectedWeighbridge?.device, [selectedWeighbridge?.device]);

    const disabledOrderDevice = useMemo(
        (): boolean => selectedWeighbridge === undefined || !!selectedWeighbridge?.device,
        [selectedWeighbridge]
    );

    const debouncedSearch = debounce((query: string) => {
        listWeighbridges(query ? { query } : undefined);
    }, 500);

    const handleOrderDevice = (): void => {
        // TODO Navigate to the device order screen
        navigate(`${selectedWeighbridge?.id}/device/order`);
    };

    const handleCalibrateDevice = (): void => {
        navigate(`${selectedWeighbridge?.id}/device/calibrate`);
    };

    const handleCreateWeighbridge = (): void => {
        navigate('create');
    };

    const handleWeighbridgesSelection = (weighbridges: Weighbridge[]): void => {
        setSelectedWeighbridge(weighbridges.length === 1 ? weighbridges[0] : undefined);
    };

    return (
        <>
            {isInSetupProcess && (
                <AfterWC
                    handleCreateWeighbridge={handleCreateWeighbridge}
                    handleOrderDevice={handleOrderDevice}
                    handleCalibrateDevice={handleCalibrateDevice}
                    disabledOrderDevice={disabledOrderDevice}
                    disabledCalibrateDevice={disabledCalibrateDevice}
                />
            )}

            <Container>
                <SpaceBetween>
                    <H5>{t('weighbridgeDashboard.title')}</H5>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <Button
                            size="large"
                            type="primary"
                            onClick={handleCalibrateDevice}
                            disabled={disabledCalibrateDevice}
                            title={t('weighbridgeDashboard.calibrateDevice.title')}
                        >
                            {t('weighbridgeDashboard.calibrateDevice.text')}
                        </Button>
                        <Button
                            size="large"
                            type="primary"
                            onClick={handleOrderDevice}
                            disabled={disabledOrderDevice}
                            title={t('weighbridgeDashboard.orderDevice.title')}
                        >
                            {t('weighbridgeDashboard.orderDevice.text')}
                        </Button>
                        <Button size="large" type="primary" onClick={handleCreateWeighbridge}>
                            {t('weighbridgeDashboard.addNewWeighbridge')}
                        </Button>
                    </div>
                </SpaceBetween>
                <Divider />
                <div>
                    <SearchBar
                        onSearch={debouncedSearch}
                        inputPlaceholder="Search by label, location, device, or status"
                        // onClear={listWeighbridges}
                    />
                    <WeighbridgesTable onWeighbridgesSelection={handleWeighbridgesSelection} />
                </div>
            </Container>
        </>
    );
};

export default WeighbridgesDashboard;
