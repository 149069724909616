export enum FiscalReferenceRole {
    FR1 = 'FR1', // Importer
    FR2 = 'FR2', // Customer
    FR3 = 'FR3', // Tax Representative
    FR4 = 'FR4', // Holder of the deferred payment authorisation
    FR5 = 'FR5', // Vendor (IOSS)
    FR7 = 'FR7', // Taxable person or of the person liable for payment of VAT
}

export const fiscalReferenceRoleLabels = [
    { id: FiscalReferenceRole.FR1, value: 'Importer' },
    { id: FiscalReferenceRole.FR2, value: 'Customer' },
    { id: FiscalReferenceRole.FR3, value: 'Tax Representative' },
    { id: FiscalReferenceRole.FR4, value: 'Holder of the deferred payment authorisation' },
    { id: FiscalReferenceRole.FR5, value: 'Vendor (IOSS)' },
    { id: FiscalReferenceRole.FR7, value: 'Taxable person or of the person liable for payment of VAT' },
] as const;
