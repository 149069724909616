import { Col, Row } from 'antd';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import DeclarationField from '../../../common/DeclarationField';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { paymentMethods } from 'store/declarations/enums/uk/payment-method-types';

interface Props extends BlockProps<'methodCode'> {}

const CalculationOfTaxesMethodOfPaymentBlock = ({
    path = 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee.payment',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '4/8', []);

    return (
        <Row gutter={18}>
            <Col xs={fieldOptions?.methodCode?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="methodCode"
                    validation={{
                        name: t('methodOfPayment'),
                        exact: 1,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormSelect
                            refNumber={ucc}
                            label={t('methodOfPayment')}
                            {...getFormikProps(field.name, form)}
                            selectOptions={paymentMethods}
                        />
                    )}
                </DeclarationField>
            </Col>
        </Row>
    );
};

export default CalculationOfTaxesMethodOfPaymentBlock;
