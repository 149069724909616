import { Layout, Row } from 'antd';
import CrossIcon from 'components/icons/CrossIcon';
import styled from 'styled-components';
import { colors } from 'theme';

const { Header, Sider, Content } = Layout;

export const StyledHeader = styled(Header)`
    background: ${colors.white};
    padding: 0;
    z-index: 1;
    position: sticky;
    width: 100%;
    top: 0;
    height: 100%;
`;

export const StyledLayout = styled(Layout)`
    background: ${colors.white};
    padding: 0;
`;

export const StyledContent = styled(Content)`
    background: ${colors.white};
    padding: 0;
    margin-left: 28rem;
    overflow: auto;
    height: calc(100vh - 24rem);
`;

export const StyledSider = styled(Sider)`
    background: ${colors.white};
    padding: 0;
    position: fixed;
    min-width: 26.4rem !important;
    max-width: 26.4rem !important;
    height: calc(100vh - 24rem);
    overflow-y: auto;
`;

export const MultipleTags = styled(Row)`
    max-width: 6.3rem;
`;

export const ListButtonsDiv = styled.div`
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
`;

export const EmptyRenderRow = styled.div`
    text-align: center;
`;

export const EmptyRenderCol = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
`;

export const EmptyRenderIcon = styled(CrossIcon)`
    font-size: 9;
`;
