import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { PartiesCardProps } from 'views/declarations/common/parties/PartiesCard';
import { MasterDetailsIrelandCardsType } from 'views/declarations/common/skeleton/card-type';
import {
    addCardToMultipleCards,
    deleteCardToMultipleCards,
    initializeMultipleCards,
} from 'views/declarations/utils/form-utils';
import { NestedAuthorisationCardProps } from './cards/authorisation-card/NestedAuthorisationCard';
import h1DeclarationSkeleton from './h1-skeleton-declaration';
interface Props {
    formik: ReturnType<typeof useFormik>; // FIXME use correct type
    toggleHelp?: (refNumber: string | number) => void;
    viewOnly?: boolean;
    expandAll?: boolean;
    selectCard?: (id: string) => void;
    amendment?: boolean;
}

const IrelandH1Form: FC<Props> = ({ formik, toggleHelp, viewOnly, expandAll, selectCard, amendment }) => {
    const cardsOfMessageType = h1DeclarationSkeleton.cards;
    const cards = cardsOfMessageType.map((obj, index) => {
        if (obj.type === MasterDetailsIrelandCardsType.SINGLE) {
            const card = React.createElement(
                obj.component as React.FC<DeclarationFormCardProps>,
                {
                    getFields: obj.getFields,
                    getFieldProps: formik.getFieldProps,
                    getFieldMeta: formik.getFieldMeta,
                    getFieldHelpers: formik.getFieldHelpers,
                    toggleHelp: toggleHelp,
                    cardTotal: h1DeclarationSkeleton.cards.length,
                    cardNumber: obj.cardNumber,
                    defaultOpen: obj.defaultOpen,
                    keyCard: obj.key,
                    nestedComponents: obj.children,
                    key: obj.key,
                    propsPathPrefix: obj.propsPathPrefix,
                    viewOnly: viewOnly,
                    expandAll: expandAll,
                    declarationType: obj.declarationType,
                    selectCard: selectCard,
                    amendment: amendment,
                    formik: formik,
                },
                obj.key
            );
            return card;
        } else if (obj.type === MasterDetailsIrelandCardsType.NESTED_AUTHORIZATION) {
            const card = React.createElement(
                obj.component as React.FC<NestedAuthorisationCardProps>,
                {
                    getFields: obj.getFields,
                    getFieldProps: formik.getFieldProps,
                    getFieldMeta: formik.getFieldMeta,
                    getFieldHelpers: formik.getFieldHelpers,
                    toggleHelp: toggleHelp,
                    cardTotal: h1DeclarationSkeleton.cards.length,
                    cardNumber: obj.cardNumber,
                    defaultOpen: obj.defaultOpen,
                    keyCard: obj.key,
                    key: obj.key,
                    propsPathPrefix: obj.propsPathPrefix,
                    viewOnly: viewOnly,
                    expandAll: expandAll,
                    nestedComponents: obj.children,
                    initializeList: (path) => initializeMultipleCards(formik, path),
                    addCard: (path, array) => {
                        addCardToMultipleCards(formik, path, array);
                    },
                    removeCard: (path, array, index) => deleteCardToMultipleCards(formik, path, array, index),
                    selectCard: selectCard,
                    formik: formik,
                },
                obj.key
            );
            return card;
        } else if (obj.type === MasterDetailsIrelandCardsType.PARTIES) {
            const card = React.createElement(
                obj.component as React.FC<PartiesCardProps>,
                {
                    getFieldProps: formik.getFieldProps,
                    getFieldMeta: formik.getFieldMeta,
                    getFieldHelpers: formik.getFieldHelpers,
                    toggleHelp: toggleHelp,
                    cardTotal: h1DeclarationSkeleton.cards.length,
                    cardNumber: obj.cardNumber,
                    defaultOpen: obj.defaultOpen,
                    keyCard: obj.key,
                    nestedComponents: obj.children,
                    key: obj.key,
                    propsPathPrefix: obj.propsPathPrefix,
                    viewOnly: viewOnly,
                    expandAll: expandAll,
                    declarationType: obj.declarationType,
                    selectCard: selectCard,
                    amendment: amendment,
                    parties: obj.other.parties,
                    paths: obj.other.paths,
                    condensed: obj.condensed,
                } as PartiesCardProps,
                obj.key
            );
            return card;
        } else return <></>;
    });

    return <FormikProvider value={formik}>{cards.map((obj) => obj)}</FormikProvider>;
};
export default IrelandH1Form;
