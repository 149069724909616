import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import DeclarationStatusTag from 'components/ui/composed/declarations/declaration-status/DeclarationStatusTag';
import { FC, useMemo } from 'react';
import { declarationStatusLabels } from 'store/declarations/enums/common/declaration-status';
import { FilterCheckbox, FilterGroup, FilterOption } from './Filter.styles';

interface DeclarationStatusFilterProps {
    selectedStatus: (status: string[]) => void;
    checked: string[];
}
export const DeclarationStatusFilter: FC<DeclarationStatusFilterProps> = ({ selectedStatus, checked }) => {
    const status = useMemo(() => declarationStatusLabels.map((s) => s.id), []);

    const onChange = (checkedValues: CheckboxValueType[]) => {
        selectedStatus(checkedValues as string[]);
    };

    return (
        <FilterGroup onChange={onChange} defaultValue={checked}>
            {status.map((s) => (
                <FilterOption>
                    <FilterCheckbox value={s}>
                        <DeclarationStatusTag status={s} />
                    </FilterCheckbox>
                </FilterOption>
            ))}
        </FilterGroup>
    );
};

export default DeclarationStatusFilter;
