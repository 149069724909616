import { default as FormInput } from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import MultiDeclarationInput from 'components/ui/composed/declarations/formInput/MultiDeclarationInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { appendFieldPath, getCardState, getFormikProps } from 'views/declarations/utils/form-utils';

const PackagingCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getIrelandExportTooltipsByRefNumber, getIrelandExportTooltipsByRefNumberAndField } = useTooltips();

    const fullFieldPath = appendFieldPath(props.propsPathPrefix);

    const handleToggleHelp = (refNumber: string | number): void => {
        props.toggleHelp && props.toggleHelp(refNumber);
    };
    const typeOfPackages = useMemo(
        () => normalizeCodesToSelect(codelists?.irelandPackageKinds || []),
        [codelists?.irelandPackageKinds]
    );

    return (
        <section
            id="packages-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('packages-form-card');
                }
            }}
        >
            <FormCard
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('packages.title')}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                expandAll={props.expandAll}
                keyCard={props.keyCard}
                state={getCardState(
                    [
                        { name: `containerNumbers`, required: false },
                        { name: `typeOfPackage`, required: true },
                        { name: `uniqueLineOfPackageReference`, required: false },
                        { name: `quantityOfPackages`, required: false },
                        { name: `numberOfPieces`, required: false },
                        { name: `marksOfPackages`, required: false },
                        { name: `packagesInItem`, required: false },
                        { name: `itemNumber`, required: false },
                    ],
                    props
                )}
            >
                <MultiDeclarationInput
                    viewOnly={props.viewOnly}
                    maxLength={11}
                    fieldHelpers={props.getFieldHelpers!(fullFieldPath(`containerNumbers`))}
                    getFieldHelpers={props.getFieldHelpers}
                    getFieldProps={(value: string) => props.getFieldProps(value)}
                    getFieldMeta={props.getFieldMeta}
                    path={fullFieldPath(`containerNumbers`)}
                    refNumber="31.2"
                    tooltip={getIrelandExportTooltipsByRefNumber('31.2')}
                    refClicked={handleToggleHelp}
                    label={t('packages.containerNumbers')}
                />

                <DeclarationSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`typeOfPackage`, props)}
                    refNumber="31.3"
                    label={t('packages.packageType')}
                    refClicked={handleToggleHelp}
                    tooltip={getIrelandExportTooltipsByRefNumber('31.3')}
                    selectOptions={typeOfPackages}
                />

                <DeclarationNumberInput
                    required
                    maxLength={8}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`uniqueLineOfPackageReference`, props)}
                    refNumber="31.4"
                    tooltip={getIrelandExportTooltipsByRefNumber('31.4')}
                    refClicked={handleToggleHelp}
                    label={t('packages.uniqueLineOfPackageReference')}
                />

                <DeclarationNumberInput
                    integerNumbers
                    maxLength={5}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`quantityOfPackages`, props)}
                    refNumber="31.5"
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('31.5', 'Number of Packages')}
                    refClicked={handleToggleHelp}
                    label={t('packages.numberOfPackages')}
                />
                <DeclarationNumberInput
                    maxLength={5}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`numberOfPieces`, props)}
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('31.5', 'Number of Pieces')}
                    refClicked={handleToggleHelp}
                    refNumber="31.5"
                    label={t('packages.numberOfPieces')}
                />

                <FormInput
                    maxLength={42}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`marksOfPackages`, props)}
                    refNumber="31.6"
                    tooltip={getIrelandExportTooltipsByRefNumber('31.6')}
                    refClicked={handleToggleHelp}
                    label={t('packages.marksOfPackages')}
                />

                <DeclarationNumberInput
                    maxLength={2}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`packagesInItem`, props)}
                    refNumber="31.8"
                    tooltip={getIrelandExportTooltipsByRefNumber('31.8')}
                    refClicked={handleToggleHelp}
                    label={t('packages.packagesInItem')}
                />

                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`itemNumber`, props)}
                    label={t('packages.itemNumber')}
                    refNumber="32"
                    tooltip={getIrelandExportTooltipsByRefNumber('32')}
                    refClicked={handleToggleHelp}
                />
            </FormCard>
        </section>
    );
};

export default PackagingCard;
