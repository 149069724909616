import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BorderlessCard from 'views/declarations/common/cards/borderless-card/BorderlessCard';
import { DeclarationBorderlessCardProps } from 'views/declarations/common/declaration.form.card';
import { FormCardContainer } from '../../../../../../common/cards/NewFormCard';
import PlaceOfUseForm from './PlaceOfUseForm';

const FirstPlaceOfUseCard: FC<DeclarationBorderlessCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <BorderlessCard
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            title={`B. ${t('firstPlaceOfUse.title')}`}
            keyCard={props.keyCard}
        >
            <FormCardContainer>
                <PlaceOfUseForm propsPathPrefix="firstPlaceOfUse" {...props} />
            </FormCardContainer>
        </BorderlessCard>
    );
};
export default FirstPlaceOfUseCard;
