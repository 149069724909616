import { Col, Row } from 'antd';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import CurrencySelect from '../../../../../components/ui/composed/declarations/CurrencySelect';

interface Props extends BlockProps<'freightChargeAmount' | 'freightChargeAmountCurrencyId'> {}

const TransportCostsToTheFinalDestinationBlock = ({
    path = 'goodsShipment.customsValuation',
    fieldOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '4/19', []);
    return (
        <Row gutter={18}>
            <Col xs={fieldOptions?.freightChargeAmount?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="freightChargeAmount"
                    validation={{
                        name: t('amount'),
                        number: true,
                        float: [16, 2],
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormInput refNumber={ucc} label={t('amount')} {...getFormikProps(field.name, form)} />
                    )}
                </DeclarationField>
            </Col>
            <Col xs={fieldOptions?.freightChargeAmountCurrencyId?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="freightChargeAmountCurrencyId"
                    validation={{
                        name: t('currency'),
                        number: true,
                        exact: 3,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <CurrencySelect refNumber={ucc} label={t('currency')} {...getFormikProps(field.name, form)} />
                    )}
                </DeclarationField>
            </Col>
        </Row>
    );
};

export default TransportCostsToTheFinalDestinationBlock;
