import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { handleToggleHelp } from 'views/declarations/utils/form-utils';
import AdditionsAndDeductionsCard from './AdditionsAndDeductionsCard';
import ContainerIdentificationCard from './ContainerIdentificationNumberCard';
import SupplyChainActorCard from './SupplyChainActorCard';
import TaxCard from './TaxCard';
import NewFormCard from '../../../../../common/cards/NewFormCard';

export enum DeclarationFormType {
    MASTER_DETAILS = 'MASTER_DETAILS',
    PRODUCTS = 'PRODUCTS',
}

interface Props extends DeclarationFormCardProps {
    fiscalReferenceRequired?: boolean;
    formType: DeclarationFormType;
    supplyChainActor: unknown[];
    addSupplyChainActor: () => void;
    removeSupplyChainActor: (index: number) => void;
    additionsAndDeductions: unknown[];
    addAdditionsAndDeductions: () => void;
    removeAdditionsAndDeductions: (index: number) => void;
    calculationOfTaxes?: unknown[];
    addCalculationOfTaxes?: () => void;
    removeCalculationOfTaxes?: (index: number) => void;
    containerIdentificationNumbers?: unknown[];
    addContainerIdentificationNumbers?: () => void;
    removeContainerIdentificationNumbers?: (index: number) => void;
}

const AdditionalConditionalInformationCard: FC<Props> = ({
    formType,
    viewOnly,
    defaultOpen,
    cardNumber,
    cardTotal,
    getFieldProps,
    getFieldHelpers,
    getFieldMeta,
    supplyChainActor,
    addSupplyChainActor,
    removeSupplyChainActor,
    additionsAndDeductions,
    addAdditionsAndDeductions,
    removeAdditionsAndDeductions,
    calculationOfTaxes,
    addCalculationOfTaxes,
    removeCalculationOfTaxes,
    containerIdentificationNumbers,
    addContainerIdentificationNumbers,
    removeContainerIdentificationNumbers,
    expandAll,
    keyCard,
    fiscalReferenceRequired,
    ...remainingProps
}) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('Additional Conditional Information')}>
            {supplyChainActor.map((obj, index: number) => (
                <SupplyChainActorCard
                    toggleHelp={(ref: number | string) => handleToggleHelp(ref, remainingProps)}
                    key={`additional-supply-chain-${index}`}
                    keyCard={`additional-supply-chain-${index}`}
                    viewOnly={viewOnly}
                    propsPathPrefix={formType === DeclarationFormType.MASTER_DETAILS ? 'goodsShipment' : ''}
                    getFieldProps={getFieldProps}
                    getFieldMeta={getFieldMeta}
                    getFieldHelpers={getFieldHelpers}
                    index={index}
                    size={supplyChainActor.length}
                    add={() => addSupplyChainActor()}
                    remove={(indexToDelete) => removeSupplyChainActor(indexToDelete)}
                />
            ))}

            {additionsAndDeductions.map((obj, index: number) => (
                <AdditionsAndDeductionsCard
                    toggleHelp={(ref: number | string) => handleToggleHelp(ref, remainingProps)}
                    key={`additions-and-deduction-${index}`}
                    keyCard={`additions-and-deduction-${index}`}
                    viewOnly={viewOnly}
                    propsPathPrefix={formType === DeclarationFormType.MASTER_DETAILS ? 'goodsShipment' : ''}
                    getFieldProps={getFieldProps}
                    getFieldMeta={getFieldMeta}
                    getFieldHelpers={getFieldHelpers}
                    index={index}
                    size={additionsAndDeductions.length}
                    add={() => addAdditionsAndDeductions()}
                    remove={(indexToDelete) => removeAdditionsAndDeductions(indexToDelete)}
                />
            ))}

            {formType === DeclarationFormType.PRODUCTS && (
                <>
                    {calculationOfTaxes &&
                        addCalculationOfTaxes &&
                        removeCalculationOfTaxes &&
                        calculationOfTaxes.map((obj, _index: number) => (
                            <TaxCard
                                toggleHelp={(ref: number | string) => handleToggleHelp(ref, remainingProps)}
                                viewOnly={viewOnly}
                                key={`calculation-taxes-${_index}`}
                                getFieldProps={getFieldProps}
                                getFieldMeta={getFieldMeta}
                                getFieldHelpers={getFieldHelpers}
                                index={_index}
                                size={calculationOfTaxes.length}
                                add={() => addCalculationOfTaxes()}
                                remove={(indexToDelete) => removeCalculationOfTaxes(indexToDelete)}
                            />
                        ))}
                </>
            )}

            {containerIdentificationNumbers &&
                addContainerIdentificationNumbers &&
                removeContainerIdentificationNumbers &&
                containerIdentificationNumbers.map((obj, _index: number) => (
                    <ContainerIdentificationCard
                        toggleHelp={(ref: number | string) => handleToggleHelp(ref, remainingProps)}
                        key={`container-id-number-${_index}`}
                        keyCard={`container-id-number-${_index}`}
                        viewOnly={viewOnly}
                        propsPathPrefix={formType === DeclarationFormType.MASTER_DETAILS ? 'goodsShipment' : ''}
                        getFieldMeta={getFieldMeta}
                        getFieldProps={getFieldProps}
                        getFieldHelpers={getFieldHelpers}
                        subSection={formType === DeclarationFormType.MASTER_DETAILS ? 'D. ' : 'E. '}
                        index={_index}
                        size={containerIdentificationNumbers.length}
                        add={() => addContainerIdentificationNumbers()}
                        remove={(indexToDelete) => removeContainerIdentificationNumbers(indexToDelete)}
                    />
                ))}
        </NewFormCard>
    );
};
export default AdditionalConditionalInformationCard;
