import { ColumnsType } from 'antd/lib/table';
import { dateStandardFormat, getHour } from 'core/utils/date';
import { DeclarationHistory, DeclarationSortParameters } from 'store/declarations/declaration';
import { getDeclarationStyle } from 'views/custom-declaration/declaration-table/DeclarationColumns';
import { FlexDiv, SpanEllipsis } from 'views/custom-declaration/declaration-table/components/DeclarationsTable.styled';
import { toTitleCase } from 'views/declarations/utils/validation-utils';

const dmsCodeNotificationTypeMap: Record<string, string> = {
    '01': 'DMSACC',
    '02': 'DMSRCV',
    '03': 'DMSREJ',
    '05': 'DMSCTL',
    '06': 'DMSDOC',
    '07': 'DMSRES',
    '08': 'DMSROG',
    '09': 'DMSCLE',
    '10': 'DMSINV',
    '11': 'DMSREQ',
    '13': 'DMSTAX',
    '14': 'DMSCPI',
    '15': 'DMSCPR',
    '16': 'DMSEOG',
    '17': 'DMSEXT',
    '18': 'DMSGER',
    '50': 'DMSALV',
    '51': 'DMSQRY',
};

export const HistoryUtils = {
    columns: {
        statusHistory: (keys: {
            date?: string;
            user?: string;
            action?: string;
            description?: string;
            notificationType?: string;
        }): ColumnsType<any> => {
            const cols: ColumnsType<any> = [];
            if (keys.date) {
                cols.push({
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date',
                    render: (text: string, record: any, index: number) => {
                        const date = new Date((record && keys.date && record[keys.date]) ?? '');
                        return record && keys.date && record[keys.date] ? (
                            {
                                children: (
                                    <FlexDiv>
                                        <span>{getHour(date)}</span>
                                        <span>{dateStandardFormat(date)}</span>
                                    </FlexDiv>
                                ),
                            }
                        ) : (
                            <SpanEllipsis>N/A</SpanEllipsis>
                        );
                    },
                    align: 'left',
                });
            }
            if (keys.user) {
                cols.push({
                    title: 'User',
                    dataIndex: 'user',
                    key: 'user',
                    render: (text: string, record: any, index: number) => {
                        return record && keys.user && record[keys.user] ? (
                            {
                                children: <SpanEllipsis>{record[keys.user]}</SpanEllipsis>,
                            }
                        ) : (
                            <SpanEllipsis>N/A</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.action) {
                cols.push({
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    render: (text: string, record: any, index: number) => {
                        return record && keys.action && record[keys.action] ? (
                            {
                                children: <SpanEllipsis>{toTitleCase(record[keys.action])}</SpanEllipsis>,
                            }
                        ) : (
                            <SpanEllipsis>N/A</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.notificationType) {
                cols.push({
                    title: 'Notification Type',
                    dataIndex: 'notification-type',
                    key: 'notification-type',
                    render: (text: string, record: any, index: number) => {
                        return record && keys.notificationType && record[keys.notificationType] ? (
                            {
                                children: (
                                    <SpanEllipsis>
                                        {HistoryUtils.Cds.dmsCodeToNotificationType(record[keys.notificationType])}
                                    </SpanEllipsis>
                                ),
                            }
                        ) : (
                            <SpanEllipsis>N/A</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.description) {
                cols.push({
                    title: 'Description',
                    dataIndex: 'description',
                    key: 'description',
                    render: (text: string, record: any, index: number) => {
                        return record && keys.description && record[keys.description] ? (
                            {
                                children: <SpanEllipsis>{record[keys.description]}</SpanEllipsis>,
                            }
                        ) : (
                            <SpanEllipsis>N/A</SpanEllipsis>
                        );
                    },
                });
            }
            return cols;
        },
        formHistory: [
            {
                title: 'Type',
                dataIndex: DeclarationSortParameters.MESSAGE_TYPE,
                key: DeclarationSortParameters.MESSAGE_TYPE,
                render: (text: string, record: DeclarationHistory, index: number) => {
                    return record ? (
                        {
                            props: getDeclarationStyle(record),
                            children: <SpanEllipsis>{toTitleCase(record.type)}</SpanEllipsis>,
                        }
                    ) : (
                        <SpanEllipsis>N/A</SpanEllipsis>
                    );
                },
                ellipsis: true,
                align: 'left',
            },
            {
                title: 'Date',
                dataIndex: DeclarationSortParameters.MESSAGE_TYPE,
                key: DeclarationSortParameters.MESSAGE_TYPE,
                render: (text: string, record: DeclarationHistory, index: number) => {
                    const date = record.createdAt ? new Date(record.createdAt) : null;
                    return date ? (
                        {
                            props: getDeclarationStyle(record),
                            children: (
                                <FlexDiv>
                                    <span>{getHour(date)}</span>
                                    <span>{dateStandardFormat(date)}</span>
                                </FlexDiv>
                            ),
                        }
                    ) : (
                        <SpanEllipsis>N/A</SpanEllipsis>
                    );
                },
                ellipsis: true,
            },
        ] as ColumnsType<DeclarationHistory>,
    },
    Cds: {
        dmsCodeToNotificationType(dmsCode: string): string | undefined {
            return dmsCodeNotificationTypeMap[dmsCode] ?? undefined;
        },
    },
};
