import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import Container from 'components/ui/base/container';
import CustomModal from 'components/ui/base/modal/Modal';
import Notification from 'components/ui/base/notification/Notification';
import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useJobs from 'hooks/useJobs';
import useRequest from 'hooks/useRequest';
import useSession from 'hooks/useSession';
import debounce from 'lodash/debounce';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { deleteDeclaration, listDeclarations as listDeclarationsReq } from 'store/declarations/client';
import { Declaration } from 'store/declarations/declaration';
import { JobResponse } from 'store/jobs/job';
import JobsTable from 'views/jobs/components/tables/JobsTable';

export interface DeletedDeclaration {
    declarationId: string;
    jobId?: string;
}

export interface JobWithoutDeclarationsModal {
    open: boolean;
    jobReference?: string;
}

const Jobs: FC = () => {
    const { t } = useTranslation('dashboard');
    const navigate = useNavigate();
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const { deleteJob, listJobs, jobs, isLoading, error, createJob } = useJobs();
    const { isDriverUser, isHaulierUser } = useSession();
    const [modalVisible, setModalVisible] = useState(false);
    const [declarationsModalVisible, setDeclarationsModalVisible] = useState(false);
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>();
    const [declarationFromJobs, setDeclarationFromJobs] = useState<{
        [key: string]: Declaration[];
    }>({});
    const [deleteJobClicked, setDeleteJobClicked] = useState<boolean>(false);
    const [deleteDeclarationClicked, setDeleteDeclarationClicked] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>();
    const [declarationsDeleteId, setDeclarationsDeleteId] = useState<DeletedDeclaration | undefined>();
    const {
        doRequest: doDeleteDeclaration,
        error: deleteDeclarationError,
        isLoading: deleteDeclarationIsloading,
    } = useRequest(deleteDeclaration);

    const [jobWithoutDeclarationsModal, setJobWithoutDeclarationsModal] = useState<JobWithoutDeclarationsModal>({
        open: false,
        jobReference: undefined,
    });

    const getDeclarations = useCallback(async () => {
        const response = await Promise.all(
            jobs.list.map(async (j) => (await listDeclarationsReq({ jobId: j.id })).list)
        ).then((j) => {
            const declarations = j.map((res) => {
                if (res?.length > 0) {
                    const jobId: string = res[0].jobId!;
                    return { [jobId]: [...res] };
                } else {
                    return {};
                }
            });
            setDeclarationFromJobs(Object.assign({}, ...declarations));
            return Object.assign({}, ...declarations);
        });
        return response;
    }, [jobs]);

    useEffect(() => {
        if (jobs.list) {
            getDeclarations();
        }
    }, [jobs, getDeclarations]);

    const querySearch = async (query: string) => {
        if (query) {
            const params = { query };
            await listJobs(params);
        } else {
            await listJobs();
        }
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    useEffect(() => {
        listJobs();
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Home',
                path: '',
            },
            {
                breadcrumbName: 'Jobs',
                path: '',
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!jobs.list && isLoading !== null) {
            listJobs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobs, isLoading]);

    useEffect(() => {
        if (paginator) {
            listJobs(paginator);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator]);

    useEffect(() => {
        if (deleteJobClicked && deleteId) {
            if (!error && !isLoading) {
                listJobs();
                Notification({
                    type: 'success',
                    messageTitle: t('job.deleteSuccessTitle'),
                    description: t('job.deleteSuccessMessage'),
                });
            } else {
                Notification({
                    type: 'error',
                    messageTitle: t('job.deleteErrorTitle'),
                    description: t('job.deleteErrorMessage'),
                });
            }
            setDeleteJobClicked(false);
            setDeleteId(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteJobClicked, deleteId, error, isLoading]);

    useEffect(() => {
        const checkIfJobHasMoreDeclarations = async (declarationId: string, jobID?: string) => {
            if (!jobID) {
                return;
            }

            const declarations = await getDeclarations();
            if (declarations) {
                const jobDeclarations = declarations[jobID];
                if (!jobDeclarations) {
                    const job = jobs.list.find((job) => job.id === jobID);
                    setJobWithoutDeclarationsModal({
                        open: true,
                        jobReference: job?.referenceId || '-',
                    });
                }
            }
        };

        if (deleteDeclarationClicked && declarationsDeleteId) {
            if (!deleteDeclarationError && !deleteDeclarationIsloading) {
                checkIfJobHasMoreDeclarations(declarationsDeleteId.declarationId, declarationsDeleteId.jobId);
                Notification({
                    type: 'success',
                    messageTitle: t('declaration.deleteSuccessTitle'),
                    description: t('declaration.deleteSuccessMessage'),
                });
            } else {
                Notification({
                    type: 'error',
                    messageTitle: t('declaration.deleteErrorTitle'),
                    description: t('declaration.deleteErrorMessage'),
                });
            }
            setDeleteDeclarationClicked(false);
            setDeclarationsDeleteId(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteDeclarationClicked, declarationsDeleteId, error, declarationFromJobs, getDeclarations]);

    const handleDeleteJob = async () => {
        if (deleteId) {
            await deleteJob(deleteId);
            setDeleteJobClicked(true);
        }
        setModalVisible(false);
    };

    const handleDeleteJobAfterLastDeclaration = async (jobReference?: string) => {
        if (jobReference) {
            const job = jobs.list.find((job) => job.referenceId === jobReference);
            if (job) {
                await deleteJob(job.id);
                setDeleteJobClicked(true);
                setDeleteId(job.id);
            }
        }
        setJobWithoutDeclarationsModal({
            open: false,
            jobReference: undefined,
        });
    };

    const handleDeleteDeclaration = async () => {
        if (declarationsDeleteId) {
            await doDeleteDeclaration(declarationsDeleteId.declarationId);
            setDeleteDeclarationClicked(true);
        }
        setDeclarationsModalVisible(false);
    };

    const onDeleteJob = async (id: string) => {
        setDeleteId(id);
        setModalVisible(true);
    };

    const onDeleteDeclaration = async (id: string) => {
        const declarations = Object.values(declarationFromJobs).find((declarations) => {
            return declarations.find((declaration) => declaration.id === id);
        });

        const jobId = declarations && declarations.length > 0 ? declarations[0].jobId : undefined;

        setDeclarationsDeleteId({
            declarationId: id,
            jobId: jobId,
        });
        setDeclarationsModalVisible(true);
    };

    const hadlePagination = (params: Partial<PaginatedParams>) => {
        if (paginator) {
            setPaginator({ page: params.page, size: paginator.size });
        } else {
            setPaginator({ page: params.page, size: defaultPagination.size });
        }
    };

    const createNewJob = async () => {
        const job = (await createJob()) as JobResponse;
        navigate(`/jobs/${job.id}`, { state: job });
    };

    return (
        <>
            <CustomModal
                title={<H5>Do you want to remove this Job?</H5>}
                centered
                visible={modalVisible}
                onOk={handleDeleteJob}
                onCancel={() => setModalVisible(false)}
                width={762}
                contentText={'If you remove this job, you will lose all the information associated with it.'}
            />
            <CustomModal
                title={<H5>Do you want to remove this Declaration?</H5>}
                centered
                visible={declarationsModalVisible}
                onOk={handleDeleteDeclaration}
                onCancel={() => setDeclarationsModalVisible(false)}
                width={762}
                contentText={'If you remove this declaration, you will lose all the information associated with it.'}
            />
            <CustomModal
                title={<H5>Do you want to remove this Job?</H5>}
                centered
                visible={jobWithoutDeclarationsModal.open}
                onOk={() => handleDeleteJobAfterLastDeclaration(jobWithoutDeclarationsModal.jobReference)}
                onCancel={() => setJobWithoutDeclarationsModal({ jobReference: undefined, open: false })}
                width={762}
                contentText={`Job ${jobWithoutDeclarationsModal.jobReference} has no declarations, do you want to delete it?`}
            />
            <Container style={{ minHeight: '80%' }}>
                <Row style={{ marginBottom: '4.4rem' }}>
                    <Col span={18}>
                        <H5>Jobs</H5>
                    </Col>
                    <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {!isHaulierUser && !isDriverUser && (
                            <div>
                                <Button size="large" onClick={() => createNewJob()}>
                                    Create new Job
                                </Button>
                            </div>
                        )}
                    </Col>
                </Row>
                <SearchBar
                    style={{ marginBottom: '6rem' }}
                    inputPlaceholder="Search by job id, declaration type, status, consignor, consignee, declarant and date"
                    onSearch={(value) => debouncedSearch(value)}
                    onClear={() => {
                        listJobs();
                    }}
                />
                <JobsTable
                    onDeleteJob={onDeleteJob}
                    onDeleteDeclaration={onDeleteDeclaration}
                    data={jobs}
                    handlePagination={hadlePagination}
                    loading={isLoading}
                    declarationFromJobs={declarationFromJobs}
                    onJobDetails={(id: string) => navigate(`/jobs/${id}`)}
                    onDeclarationDetails={(id: string) => navigate(`/declarations/${id}`)}
                />
            </Container>
        </>
    );
};
export default Jobs;
