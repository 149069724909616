import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import CountrySelect from '../../../../../components/ui/composed/declarations/CountrySelect';
import { ValidationSchema, validators } from '../validations/validations';

export type Fields = 'countryCode';

export const countryOfDestinationCodeBlockValidation: {
    childValidators: {
        'goodsShipment.destination': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.destination': {
            childValidators: {
                countryCode: [validators.exact(2)],
            },
        },
    },
};

export const countryOfDestinationCodeBlockProductValidation: {
    childValidators: {
        destination: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        destination: {
            childValidators: {
                countryCode: [validators.exact(2)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const CountryOfDestinationCodeBlock = ({ path = 'goodsShipment.destination', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '5/8', []);

    return (
        <DeclarationField pathPrefix={path} name="countryCode">
            {({ form, field }) => (
                <CountrySelect
                    refNumber={ucc}
                    required={fieldOptions?.countryCode?.required}
                    label={t('countryDestinationCode')}
                    {...getFormikProps(field.name, form)}
                    condensed
                />
            )}
        </DeclarationField>
    );
};

export default CountryOfDestinationCodeBlock;
