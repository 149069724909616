import { Spin } from 'antd';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile as downloadRequest } from 'store/documents/client';
import useRequest from '../../../hooks/useRequest';
import { PreviewIcon, PreviewText, SectionTitle } from './InvoiceUpload.styles';

interface Props {
    customerName?: string;
    file?: { filename: string; id: string };
}
const PreviewInvoice: FC<Props> = ({ customerName, file }) => {
    const { data, doRequest: downloadFile } = useRequest(downloadRequest);
    const { t } = useTranslation('customs_declarations');

    useEffect(() => {
        if (file?.id) {
            downloadFile(file.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    const renderPdfView = useMemo(() => {
        if (data?.preSignedUrl) {
            return (
                <div>
                    <object data={data.preSignedUrl} type="application/pdf" width="100%" height="500">
                        <iframe
                            src={`${data.preSignedUrl}#view=fitH`}
                            title="preview document"
                            width="500"
                            height="600"
                        >
                            <p>This browser does not support PDF!</p>
                        </iframe>
                    </object>
                </div>
            );
        }
        return (
            <div style={{ width: 600, height: 500, textAlign: 'center' }}>
                <Spin />
            </div>
        );
    }, [data]);

    return (
        <>
            <SectionTitle className={!file ? 'disabled' : ''}>{t('previewInvoiceTitle')}</SectionTitle>
            {file && (
                <>
                    <div>
                        <PreviewText>
                            <PreviewIcon /> Invoice uploaded with success
                        </PreviewText>
                        <PreviewText>
                            <PreviewIcon /> Form Assigned to Customer {customerName}
                        </PreviewText>
                    </div>
                    <div style={{ textAlign: 'center' }}>{renderPdfView}</div>
                </>
            )}
        </>
    );
};

export default PreviewInvoice;
