import { Col } from 'antd';
import styled from 'styled-components';
import { colors } from 'theme';

export const ReverseCol = styled(Col)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

export const StyledContainer = styled.div`
    background: ${colors.white};
    padding: 40px;
    border: 1px solid ${colors.darkGrey2};
    border-radius: 4px;
    box-shadow: 0px 0px 24px rgba(9, 0, 255, 0.07);
    margin-top: 4.8rem;
`;
