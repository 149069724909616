import { Col } from 'antd';
import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { ValidationSchema, validators } from '../validations/validations';
import MultipleDeclarationField from 'views/declarations/common/MultipleDeclarationField';

type Fields =
    | 'securityDetailsCode'
    | 'referenceID'
    | 'id'
    | 'accessCode'
    | 'amountAmount'
    | 'amountAmountCurrencyId'
    | 'guaranteeOffice.id';

export const guaranteeReferenceBlockValidation: {
    childValidators: {
        obligationGuarantee: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        obligationGuarantee: {
            childValidators: {
                securityDetailsCode: [validators.exact(1)],
                referenceID: [validators.max(24)],
                id: [validators.max(35)],
                accessCode: [validators.max(4)],
                amountAmount: [validators.float(16, 2)],
                amountAmountCurrencyId: [validators.exact(3)],
                'guaranteeOffice.id': [validators.exact(8)],
            },
            stopAtNull: true,
        },
    },
};

export const guaranteeReferenceFields = guaranteeReferenceBlockValidation.childValidators.obligationGuarantee;

interface Props extends BlockProps<Fields> {}

const GuaranteeReferenceBlock = ({ path = 'obligationGuarantee', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');

    const ucc = useMemo(() => '8/3', []);
    return (
        <>
            <MultipleDeclarationField pathPrefix={path} name="securityDetailsCode">
                {({ form, field }, controls) => (
                    // TODO: Make it select
                    <FormInput
                        refNumber={ucc}
                        maxLength={1}
                        label={t('guaranteeType')}
                        {...getFormikProps(field.name, form)}
                        multipleF={controls}
                        condensed
                    />
                )}
            </MultipleDeclarationField>
            <DeclarationField pathPrefix={path} name="referenceID" maxNumberOfFields={9}>
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        maxLength={24}
                        label={t('guaranteeReference.grn')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        maxLength={35}
                        label={t('guaranteeReference.otherGuaranteeReference')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="accessCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        maxLength={4}
                        label={t('guaranteeReference.accessCode')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="amountAmount">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        label={t('guaranteeReference.amountOfImportDuty')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="amountAmountCurrencyId">
                {({ form, field }: FieldProps<any>) => (
                    <CurrencySelect
                        refNumber={ucc}
                        label={t('guaranteeReference.currency')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="guaranteeOffice.id">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        maxLength={8}
                        label={t('guaranteeReference.guaranteeOfficeID')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default GuaranteeReferenceBlock;
