import { isArray, isEmpty, isObject } from 'lodash';

export const objectIsEmpty = (obj: any) => Reflect.ownKeys(obj).length === 0 && obj.constructor === Object;

const hasSomeValues = (obj: Object) => Object.values(obj).some((value) => value != null && value !== '');

export const removeEmptyObjectsFromArray = (arr: any[]) => {
    if (!arr) return [];
    return arr.filter((obj) => obj != null && hasSomeValues(obj));
};

export const removeEmpty = <TData extends Object | Array<any> | string | number | undefined | null>(
    data: TData
): TData => {
    const EMPTY = null as TData;
    // eslint-disable-next-line eqeqeq
    if (data == EMPTY) {
        return EMPTY;
    }
    if (typeof data === 'string' && data.trim() === '') {
        return EMPTY;
    }
    if (isArray(data)) {
        if (isEmpty(data)) return EMPTY;
        const filtered = data.reduce((acc, value) => {
            const a = removeEmpty(value);
            if (a !== EMPTY) acc.push(a);
            return acc;
        }, []);
        if (isEmpty(filtered)) return EMPTY;
        return filtered as TData;
    }
    if (isObject(data)) {
        if (isEmpty(data)) return EMPTY;
        const filtered = Object.entries(data).reduce((acc, [key, value]) => {
            const a = removeEmpty(value);
            if (a !== EMPTY) acc[key] = a;
            return acc;
        }, {} as any);
        if (isEmpty(filtered)) return EMPTY;
        return filtered as TData;
    }
    return data;
};

export const removeEmptyStringFromObject = (obj: any) => {
    Object.keys(obj).forEach((key) => {
        if (obj[key] === '') {
            delete obj[key];
        }
    });
    return obj;
};
