import { NavItem } from 'views/declarations/common/nav-item';

export const sectionsUkH2MainDocument: NavItem[] = [
    { title: 'Master Details' },
    { title: 'Importer' },
    { title: 'Declarant' },
    { title: 'Representative' },
    { title: 'Holder of the authorisation identification No.' },
    { title: 'Identification of Warehouse' },
    { title: 'Country of Destination' },
    { title: 'Location of Goods' },
    { title: 'Supervising Customs Office' },
    { title: 'Customs Office of Presentation' },
    { title: 'Gross Mass' },
    { title: 'Mode of Transport At The Border' },
    { title: 'Identity of means of transport on arrival' },
    { title: 'Guarantee Reference' },
    { title: 'Nature of Transaction' },
    { title: 'Previous Documents' },
    { title: 'Additional Information' },
    { title: 'Additional supply chain actor(s) Ident. No.' },
    { title: 'Additional Fiscal References Identification' },
    { title: 'Container Identification Number' },
];

export const sectionsUkH2Products: NavItem[] = [
    /*{ title: 'Commodity Code - CUS code' },*/
    { title: 'Commodity Code - Combined Nomenclature code' },
    /* { title: 'Commodity Code - TARIC code' },
    { title: 'Commodity Code - Taric additional code(s)' },
    { title: 'Commodity Code - National additional code(s)' },*/
    { title: 'Goods Information' },
    { title: 'Procedure' },
    { title: 'Additional Information' },
    { title: 'Additional Document' },
    { title: 'Nature of Transaction' },
    { title: 'Packages' },
    { title: 'Previous Documents' },
    { title: 'Additional supply chain actor(s) Ident. No.' },
    { title: 'Additional Fiscal References Identification' },
    { title: 'Country of Origin' },
    { title: 'Container Identification Number' },
    { title: 'Preference Code' },
];
