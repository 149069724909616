import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { packaging } from 'views/declarations/ireland/import/h1/validation/ValidationSchema';
import { FormCardContainer } from '../../../../../common/cards/NewFormCard';
import MultipleItemsCard from '../../../../../common/MultipleItemsCard';
import addPathPrefix from '../../../../../../../utils/addPathPrefix';

const PackagesCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix!}
            initialValues={{ packageNumber: '', typePackage: '', shippingMarks: '' }}
            list={(list) => [
                { field: t('packages.packageType'), value: list.typePackage },
                { field: t('packages.numberOfPackages'), value: list.packageNumber },
                { field: t('packages.shippingMarks'), value: list.shippingMarks },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            keyCard={props.keyCard}
            title={t('packages.title')}
            condensed
            validationSchema={packaging()}
            refNumber={['6.09', '6.10', '6.11']}
            form={(formProps, path) => (
                <FormCardContainer oneColumn>
                    <FormSelect
                        required
                        viewOnly={props.viewOnly}
                        refNumber="6.9"
                        label={t('packages.packageType')}
                        {...getFormikProps(addPathPrefix(path, 'typePackage'), formProps)}
                        selectOptions={normalizeCodesToSelect(codelists?.irelandPackageKinds || [])}
                        tooltip={getH1TooltipsByRefNumberAndField('6.9', t('packages.packageType'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <DeclarationNumberInput
                        required
                        viewOnly={props.viewOnly}
                        refNumber="6.10"
                        {...getFormikProps(addPathPrefix(path, 'packageNumber'), formProps)}
                        label={t('packages.numberOfPackages')}
                        tooltip={getH1TooltipsByRefNumberAndField('6.10', t('packages.numberOfPackages'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormInput
                        required
                        maxLength={512}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'shippingMarks'), formProps)}
                        refNumber="6.11"
                        label={t('packages.shippingMarks')}
                        tooltip={getH1TooltipsByRefNumberAndField('6.11', t('packages.shippingMarks'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default PackagesCard;
