import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import { useFormik } from 'formik';
import useModalMultipleCards from 'hooks/useModalMultipleCards';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GuaranteeReference } from 'store/declarations/ireland/import-declaration';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import CardList from 'views/declarations/common/list-card/CardList';
import ModalEditCardListItem from 'views/declarations/common/modal-edit-list-item/ModalEditCardListItem';
import { handleToggleHelp } from 'views/declarations/utils/form-utils';
import { guaranteeReferenceValidation } from '../validation/ValidationSchema';

const GuaranteeReferenceCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField } = useTooltips();
    const { viewOnly } = props;

    const formik = useFormik<GuaranteeReference>({
        initialValues: {
            grn: '',
            otherGuaranteeReference: '',
            accessCode: '',
        },
        validationSchema: guaranteeReferenceValidation,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            addItem(values);
        },
    });

    const { setValues, getFieldProps, getFieldHelpers, getFieldMeta, resetForm } = formik;

    const formikProps = useMemo(() => {
        return {
            fieldProps: props.getFieldProps(`guaranteeReference`),
            fieldMeta: props.getFieldMeta && props.getFieldMeta('guaranteeReference'),
            fieldHelper: props.getFieldHelpers('guaranteeReference'),
        };
    }, [props]);

    const { modalVisible, handleEdit, handleDelete, handleCancel, onClickAddItem, addItem } = useModalMultipleCards({
        formikProps,
        setValues,
        resetForm,
    });

    const dataList = useMemo(() => {
        const list = props.getFieldProps(`guaranteeReference`).value;
        if (Array.isArray(list)) {
            return list.map((obj: GuaranteeReference, index) => {
                return [
                    { field: 'GRN', value: obj.grn },
                    { field: 'Other Ref.', value: obj.otherGuaranteeReference },
                    { field: 'Access Code', value: obj.accessCode },
                    { field: 'Currency', value: obj.currency },
                    { field: 'Amount', value: obj.amount?.toString() },
                    { field: 'Office', value: obj.guaranteeCustomsOffice },
                ];
            });
        }
        return [];
    }, [props]);

    return (
        <>
            <CardList data={dataList} onDelete={handleDelete} onEdit={handleEdit} viewOnly={viewOnly ?? false} />
            {!viewOnly && <Button onClick={onClickAddItem}>Add item</Button>}
            <ModalEditCardListItem
                visible={modalVisible}
                onCancel={handleCancel}
                width={676}
                formik={formik}
                testId="add-item-guarantee-reference-card-modal"
            >
                <Row gutter={12}>
                    <Col xs={24} xxl={12}>
                        <FormInput
                            maxLength={24}
                            viewOnly={props.viewOnly}
                            fieldMeta={getFieldMeta('grn')}
                            fieldProps={getFieldProps('grn')}
                            fieldHelper={getFieldHelpers('grn')}
                            testId="grn"
                            refNumber="8.3"
                            label={t('guaranteeReference.grn')}
                            tooltip={getH1TooltipsByRefNumberAndField('8.3', t('guaranteeReference.grn'))}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                        />
                    </Col>
                    <Col xs={24} xxl={12}>
                        <FormInput
                            maxLength={35}
                            viewOnly={props.viewOnly}
                            fieldMeta={getFieldMeta('otherGuaranteeReference')}
                            fieldProps={getFieldProps('otherGuaranteeReference')}
                            fieldHelper={getFieldHelpers('otherGuaranteeReference')}
                            refNumber="8.3"
                            testId="otherGuaranteeReference"
                            label={t('guaranteeReference.otherGuaranteeReference')}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '8.3',
                                t('guaranteeReference.otherGuaranteeReference')
                            )}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                        />
                    </Col>
                    <Col xs={24} xl={12}>
                        <FormInput
                            maxLength={4}
                            viewOnly={props.viewOnly}
                            fieldMeta={getFieldMeta('accessCode')}
                            fieldProps={getFieldProps('accessCode')}
                            fieldHelper={getFieldHelpers('accessCode')}
                            refNumber="8.3"
                            testId="accessCode"
                            label={t('guaranteeReference.accessCode')}
                            tooltip={getH1TooltipsByRefNumberAndField('8.3', t('guaranteeReference.accessCode'))}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                        />
                    </Col>
                    <Col xs={24} xl={12}>
                        <CurrencySelect
                            viewOnly={props.viewOnly}
                            fieldMeta={getFieldMeta('currency')}
                            fieldProps={getFieldProps('currency')}
                            fieldHelper={getFieldHelpers('currency')}
                            testId="currency"
                            refNumber="8.3"
                            tooltip={getH1TooltipsByRefNumberAndField('8.3', t('currency'))}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                        />
                    </Col>
                    <Col xs={24} xxl={12}>
                        <DeclarationNumberInput
                            viewOnly={props.viewOnly}
                            fieldMeta={getFieldMeta('amount')}
                            fieldProps={getFieldProps('amount')}
                            fieldHelper={getFieldHelpers('amount')}
                            refNumber="8.3"
                            testId="amount"
                            label={t('amount')}
                            tooltip={getH1TooltipsByRefNumberAndField('8.3', t('amount'))}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                        />
                    </Col>
                    <Col xs={24} xxl={12}>
                        <FormInput
                            maxLength={8}
                            viewOnly={props.viewOnly}
                            fieldMeta={getFieldMeta('guaranteeCustomsOffice')}
                            fieldProps={getFieldProps('guaranteeCustomsOffice')}
                            fieldHelper={getFieldHelpers('guaranteeCustomsOffice')}
                            refNumber="8.3"
                            testId="guaranteeCustomsOffice"
                            label={t('guaranteeReference.guaranteeCustomsOffice')}
                            tooltip={getH1TooltipsByRefNumberAndField(
                                '8.3',
                                t('guaranteeReference.guaranteeCustomsOffice')
                            )}
                            refClicked={(ref) => handleToggleHelp(ref, props)}
                        />
                    </Col>
                </Row>
            </ModalEditCardListItem>
        </>
    );
};
export default GuaranteeReferenceCard;
