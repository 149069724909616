import { objectIsEmpty } from 'core/utils/objects';
import { FormikValues } from 'formik';
import { isArray, isBoolean, isEqual, isObject, isString, some } from 'lodash';
import { DeclarationCustomer } from 'store/declarations/common/declaration-customer';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import {
    EnsGoodsShipmentItem,
    IrelandEntrySummaryDeclaration,
} from 'store/declarations/ireland/entry-summary-declaration';
import { IrelandExportDeclaration, revenueExportGoodsShipment } from 'store/declarations/ireland/export-declaration';
import { IrelandH7ImportDeclaration } from 'store/declarations/ireland/h7-import-declaration';
import { IrelandImportDeclaration } from 'store/declarations/ireland/import-declaration';
import { CdsExportDeclaration } from 'store/declarations/uk/export-declaration';
import { CdsImportDeclaration } from 'store/declarations/uk/import-declaration';
import { DeclarationType } from '../common/declarationType';
export const disableSaveOrSubmitDeclarationButton = (status: DeclarationStatus) =>
    status !== DeclarationStatus.DRAFT && status !== DeclarationStatus.INVALID && status !== DeclarationStatus.REJECTED;

export const showInvalidateButton = (status: DeclarationStatus) =>
    status === DeclarationStatus.SUBMITTED ||
    status === DeclarationStatus.REGISTERED ||
    status === DeclarationStatus.ACCEPTED ||
    status === DeclarationStatus.INSUFFICIENT_FUNDS ||
    status === DeclarationStatus.RELEASED;

export type DeclarationsPayloads =
    | CdsImportDeclaration
    | CdsExportDeclaration
    | IrelandImportDeclaration
    | IrelandH7ImportDeclaration
    | IrelandExportDeclaration
    | IrelandEntrySummaryDeclaration
    | any;

export interface DescriptionOfGoodsInformation {
    numberOfItems: number;
    netMass: number;
    grossMass: number;
    numberOfPackages: number;
}

const EMPTY_DESCRIPTION_OF_GOODS: Readonly<DescriptionOfGoodsInformation> = {
    numberOfItems: 0,
    netMass: 0.0,
    grossMass: 0.0,
    numberOfPackages: 0.0,
};

export const getDeclarationPayload = (declaration: Declaration): DeclarationsPayloads => {
    if (declaration.declarationExternalEntity === DeclarationExternalEntity.REVENUE) {
        if (declaration.declarationInternalType === DeclarationInternalType.IMPORT) {
            return declaration.irelandImportDeclaration
                ? declaration.irelandImportDeclaration
                : declaration.irelandH7ImportDeclaration!;
        } else if (declaration.declarationInternalType === DeclarationInternalType.EXPORT) {
            return declaration.irelandExportDeclaration!;
        } else if (declaration.declarationInternalType === DeclarationInternalType.ENS) {
            return declaration.entrySummaryDeclaration!;
        } else if (declaration.declarationInternalType === DeclarationInternalType.ETD) {
            return declaration.irelandETDDeclaration!;
        } else if (declaration.declarationInternalType === DeclarationInternalType.ARRIVAL) {
            return declaration.irelandETDDeclaration!;
        } else if (declaration.declarationInternalType === DeclarationInternalType.TEMPORARY) {
            return declaration.irelandImportTemporaryStorageDeclaration!;
        }
    } else if (declaration.declarationExternalEntity === DeclarationExternalEntity.CDS) {
        return declaration.cdsImportDeclaration!;
    } else if (declaration.declarationExternalEntity === DeclarationExternalEntity.CHIEF) {
        return declaration.cdsExportDeclaration!;
    }
    throw Error('should have declaration');
};

// TODO: review this
export const getDeclarationName = (declaration: Declaration) => {
    if (declaration.declarationExternalEntity === DeclarationExternalEntity.REVENUE) {
        if (declaration.declarationInternalType === DeclarationInternalType.IMPORT) {
            return declaration.irelandImportDeclaration
                ? declaration.irelandImportDeclaration.messageType
                : DeclarationName.H7;
        } else if (declaration.declarationInternalType === DeclarationInternalType.EXPORT) {
            return declaration.irelandExportDeclaration!.messageType;
        } else if (declaration.declarationInternalType === DeclarationInternalType.ENS) {
            return DeclarationName.ENS;
        } else if (declaration.declarationInternalType === DeclarationInternalType.ETD) {
            return DeclarationName.ETD;
        } else if (declaration.declarationInternalType === DeclarationInternalType.ARRIVAL) {
            return DeclarationName.ARRIVAL;
        } else if (declaration.declarationInternalType === DeclarationInternalType.TEMPORARY) {
            return DeclarationName.TEMPORARY;
        }
    } else if (declaration.declarationExternalEntity === DeclarationExternalEntity.CDS) {
        if (declaration.declarationInternalType === DeclarationInternalType.IMPORT) {
            return declaration.cdsImportDeclaration?.messageType;
        } else if (declaration.declarationInternalType === DeclarationInternalType.IMPORT_NEW) {
            return declaration.cdsExportDeclaration?.messageType;
        } else if (declaration.declarationInternalType === DeclarationInternalType.EXPORT) {
            return declaration.cdsExportDeclaration?.messageType;
        }
    } else if (declaration.declarationExternalEntity === DeclarationExternalEntity.CHIEF) {
        if (declaration.declarationInternalType === DeclarationInternalType.EXPORT) {
            return DeclarationName.FULL_DEC; //TODO
        }
    }

    console.error(`Unhandled declaration internal type ${declaration.declarationInternalType}`);

    return '-';
};

export const getDeclarationCountry = (declaration: Declaration) => {
    if (declaration.declarationExternalEntity === DeclarationExternalEntity.REVENUE) {
        return 'IE';
    } else {
        return 'UK';
    }
};

export const getExporterName = (payload: {
    exporter?: DeclarationCustomer;
    consignor?: DeclarationCustomer;
    revenueExportGoodsShipment?: revenueExportGoodsShipment;
    goodsShipment?: any;
}): string => {
    const exporter = getExporter(payload);
    return exporter?.name ?? exporter?.eori ?? 'N/A';
};

export const getExporter = (payload: {
    exporter?: DeclarationCustomer;
    consignor?: DeclarationCustomer;
    revenueExportGoodsShipment?: revenueExportGoodsShipment;
    goodsShipment?: any;
}): DeclarationCustomer | undefined => {
    if (payload?.exporter) {
        return payload?.exporter;
    } else if (payload?.revenueExportGoodsShipment) {
        return payload?.consignor;
    } else if (payload?.goodsShipment?.consignor) {
        return payload?.goodsShipment?.consignor;
    } else if (payload?.consignor) {
        return payload.consignor;
    } else if (payload?.goodsShipment?.exporter) {
        return payload?.goodsShipment?.exporter;
    }
    return undefined;
};

export const getImporterName = (payload: {
    consignee?: DeclarationCustomer;
    goodsShipment?: { importer?: DeclarationCustomer; goodsItems?: EnsGoodsShipmentItem[] };
    revenueExportGoodsShipment?: revenueExportGoodsShipment;
}): string => {
    const importer = getImporter(payload);
    return importer?.name ?? importer?.eori ?? 'N/A';
};

export const getImporter = (payload: {
    consignee?: DeclarationCustomer;
    goodsShipment?: {
        importer?: DeclarationCustomer;
        goodsItems?: EnsGoodsShipmentItem[];
        consignee?: DeclarationCustomer;
    };
    revenueExportGoodsShipment?: revenueExportGoodsShipment;
}): DeclarationCustomer | undefined => {
    if (payload?.goodsShipment?.importer) {
        return payload?.goodsShipment?.importer;
    } else {
        if (payload?.revenueExportGoodsShipment) {
            return payload?.consignee;
        } else {
            if (payload?.consignee) {
                return payload.consignee;
            }
            if (payload?.goodsShipment?.consignee) {
                return payload?.goodsShipment?.consignee;
            }
            if (payload?.goodsShipment?.goodsItems && payload?.goodsShipment?.goodsItems.length) {
                return payload.goodsShipment?.goodsItems[0]?.consignee;
            }
        }
    }
    return undefined;
};

export const getDeclarantName = (payload: {
    declarant?: DeclarationCustomer;
    representative?: DeclarationCustomer;
    representativeEori?: string;
    personLodgingTheSummaryDeclaration?: DeclarationCustomer;
}): string => {
    if (payload?.declarant) {
        return payload?.declarant?.name ?? payload?.declarant?.eori ?? 'N/A';
    } else {
        if (payload?.representative) {
            return payload.representative.name ?? payload.representative.eori ?? 'N/A';
        } else if (payload?.representativeEori) {
            return payload.representativeEori ?? 'N/A';
        }

        if (payload?.personLodgingTheSummaryDeclaration) {
            return (
                payload.personLodgingTheSummaryDeclaration.name ??
                payload.personLodgingTheSummaryDeclaration.eori ??
                'N/A'
            );
        }
    }
    return 'N/A';
};

export const getReferenceNumber = (declaration: Declaration) => {
    return declaration?.referenceNumber ?? 'N/A';
};

export const getMrn = (declaration: Declaration) => {
    return declaration?.mrn ?? 'N/A';
};

const getIrelandH1ItemsInfo = (declaration: Declaration): DescriptionOfGoodsInformation => {
    const items = declaration?.irelandImportDeclaration?.goodsShipment?.goodsShipmentItem;
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const netMass = items.map((i) => i.goodsInformation?.netMass ?? 0.0).reduce((a, b) => a + b, 0);
        const grossMass = items.map((i) => i.goodsInformation?.grossMass ?? 0.0).reduce((a, b) => a + b, 0);

        const numberOfPackages = items
            .map((i) =>
                i.goodsInformation?.packaging
                    ? i.goodsInformation?.packaging.map((p) => +(p.packageNumber ?? 0)).reduce((a, b) => a + b, 0)
                    : 0
            )
            .reduce((a, b) => a + b, 0);

        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getIrelandH7ItemsInfo = (declaration: Declaration): DescriptionOfGoodsInformation => {
    const items = declaration?.irelandH7ImportDeclaration?.goodsShipment?.governmentAgencyGoodsItem;
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const netMass = 0.0;
        const grossMass = items.map((i) => i?.grossMass ?? 0.0).reduce((a, b) => a + b, 0);
        const numberOfPackages = items.map((i) => +(i?.packagingNumberOfPackages ?? 0)).reduce((a, b) => a + b, 0);
        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getIrelandExportItemsInfo = (declaration: Declaration) => {
    const items = declaration?.irelandExportDeclaration?.irelandExportGoodsShipment?.customsGoodsItem;
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const netMass = items?.map((i) => i?.netMass ?? 0.0).reduce((a, b) => a + b, 0);
        const grossMass = items.map((i) => i?.grossMass ?? 0.0).reduce((a, b) => a + b, 0);
        const numberOfPackages = items.map((i) => +(i?.packaging?.quantityOfPackages ?? 0)).reduce((a, b) => a + b, 0);
        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getIrelandEnsItemsInfo = (declaration: Declaration) => {
    const items = declaration?.entrySummaryDeclaration?.goodsShipment?.goodsItems;
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const netMass = 0.0;
        const grossMass = items.map((i) => i?.grossMass ?? 0.0).reduce((a, b) => a + b, 0);
        const numberOfPackages = items
            .map((i) =>
                i.goodsPackaging
                    ? i.goodsPackaging.map((p) => +(p.numberOfPackages ?? 0)).reduce((a, b) => a + b, 0)
                    : 0
            )
            .reduce((a, b) => a + b, 0);
        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};
const getUkImportItemsInfo = (declaration: Declaration) => {
    const items = declaration?.cdsImportDeclaration?.goodsShipment?.governmentAgencyGoodsItems;
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const netMass = items.map((i) => i?.goodsNetMass ?? 0.0).reduce((a, b) => a + b, 0);
        const grossMass = items.map((i) => i?.goodsGrossMass ?? 0.0).reduce((a, b) => a + b, 0);
        const numberOfPackages = items
            .map((i) =>
                i.packagings ? i.packagings.map((p) => +(p.numberOfPackages ?? 0)).reduce((a, b) => a + b, 0) : 0
            )
            .reduce((a, b) => a + b, 0);
        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getUkExportItemsInfo = (declaration: Declaration) => {
    const items = declaration?.cdsExportDeclaration?.cdsExportGovernmentAgencyGoodsItems?.map(
        ({ governmentAgencyGoodsItem }) => governmentAgencyGoodsItem
    );
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const numberOfPackages = items
            .map(
                (i) =>
                    i?.packaging?.reduce((acc, { quantityQuantity }) => acc + (Number(quantityQuantity) || 0), 0) ?? 0
            )
            .reduce((acc, current) => acc + current, 0);
        const netMass = items
            .map((i) => i?.commodity?.goodsMeasure?.netNetWeightMeasure ?? 0.0)
            .reduce((a: any, b: any) => a + b, 0);
        const grossMass = items
            .map((i) => i?.commodity?.goodsMeasure?.grossMassMeasure ?? 0.0)
            .reduce((a: any, b: any) => a + b, 0);
        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

export const getDescriptionOfGoods = (declaration: Declaration): DescriptionOfGoodsInformation => {
    if (declaration.declarationExternalEntity === DeclarationExternalEntity.REVENUE) {
        if (declaration.declarationInternalType === DeclarationInternalType.IMPORT) {
            return declaration.irelandImportDeclaration
                ? getIrelandH1ItemsInfo(declaration)
                : getIrelandH7ItemsInfo(declaration);
        } else if (declaration.declarationInternalType === DeclarationInternalType.EXPORT) {
            return getIrelandExportItemsInfo(declaration);
        } else if (declaration.declarationInternalType === DeclarationInternalType.ENS) {
            return getIrelandEnsItemsInfo(declaration);
        }
    } else if (declaration.declarationExternalEntity === DeclarationExternalEntity.CDS) {
        return declaration.cdsImportDeclaration ? getUkImportItemsInfo(declaration) : getUkExportItemsInfo(declaration);
    }

    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getObjectDifferences = (data: FormikValues, oldData: FormikValues) => {
    const record: any = {};
    Object.keys(data).forEach((key: string) => {
        const old = oldData[key] ?? {};
        const current = data[key] ?? {};
        if (isString(current) || Number.isFinite(current) || isBoolean(current) || !current) {
            if (!isEqual(old, current)) {
                record[key] = current;
            }
        } else {
            if (!isEqual(current, old)) {
                record[key] = getObjectDifferences(current, old);
            }
        }
    });
    return record;
};

const handleEmptyObjects = (data: any, contains: boolean[]) => {
    Object.values(data).forEach((d) => {
        if (isObject(d)) {
            if (objectIsEmpty(d)) {
                contains.push(true);
            } else {
                handleEmptyObjects(d, contains);
            }
        } else {
            if (isArray(d)) {
                d.forEach((obj) => {
                    if (obj && Object.keys(obj).length === 0) {
                        contains.push(true);
                    }
                });
            }
        }
    });
};

/**
 * @param initialValue
 * @param currentValue
 * @returns
 * False if no changes are made on the declaration\
 * True if changes are made on the declaration
 */
// TODO CHECK LOGIC There is an issue with this implementation that is why in some places only isEqual is used for the comparison.
// In some occasions it says that the declaration is not changed but it actually it is.
export const declarationChanged = (initialValue: FormikValues, currentValue: FormikValues) => {
    const initial = { ...initialValue };
    const current = { ...currentValue };
    if (isEqual(initial, current)) {
        return false;
    } else {
        const diff = getObjectDifferences(current, initial);
        const containsEmptyObjects: [] = [];
        handleEmptyObjects(diff, containsEmptyObjects);
        if (containsEmptyObjects?.length) {
            if (containsEmptyObjects.every((o) => !!o === true)) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    }
};

export const isDeclarationAmendable = (declaration: Declaration) => {
    const amendmentStatuses = [
        DeclarationStatus.RELEASED,
        DeclarationStatus.ACCEPTED,
        DeclarationStatus.REGISTERED,
        DeclarationStatus.AWAITING_RISK,
        DeclarationStatus.INSUFFICIENT_FUNDS,
        DeclarationStatus.UNDER_PAYMENT,
        DeclarationStatus.RISKED,
        DeclarationStatus.SUBMITTED,
    ];

    const isAmendable = (statuses: DeclarationStatus[], exclude = false) => {
        const hasSome = some(statuses, (status) => declaration?.status === status);
        return exclude ? !hasSome : hasSome;
    };

    return isAmendable(amendmentStatuses);
};

export const isDeclarationRefundRemissionRequested = (declaration: Declaration | undefined) => {
    const declarationType = declaration?.irelandImportDeclaration ?? declaration?.irelandH7ImportDeclaration;

    return Boolean(declarationType?.refundApplicationForDebtRemissionRequested);
};

export const isDeclarationRefundDepositRequested = (declaration: Declaration | undefined) => {
    const declarationType = declaration?.irelandImportDeclaration ?? declaration?.irelandH7ImportDeclaration;

    return Boolean(declarationType?.refundApplicationForDebtDepositRequested);
};

export const getDeclarationType = (declaration: Declaration | undefined | null) => {
    if (!declaration) return '';

    if (declaration.irelandImportDeclaration) {
        return 'H1';
    } else if (declaration.irelandH7ImportDeclaration) {
        return 'H7';
    } else if (declaration.entrySummaryDeclaration) {
        return 'ENS';
    } else if (declaration.cdsExportDeclaration) {
        return 'B1';
    } else if (declaration.cdsImportDeclaration) {
        return 'H1';
    } else if (declaration.cdsImportDeclarationUsingBlocks) {
        return 'H2';
    } else {
        return declaration.declarationInternalType;
    }
};

export const getDeclarationKey = (
    declaration: Pick<
        Declaration,
        'declarationExternalEntity' | 'declarationInternalType' | 'irelandH7ImportDeclaration'
    >
) => {
    const getIrelandDeclarationType = () => {
        switch (declaration.declarationInternalType) {
            case DeclarationInternalType.IMPORT:
                if (declaration.irelandH7ImportDeclaration === undefined) {
                    return DeclarationType.IE_IMPORT_H1;
                } else {
                    return DeclarationType.IE_IMPORT_H7;
                }
            case DeclarationInternalType.EXPORT:
                return DeclarationType.IE_EXPORT;
            case DeclarationInternalType.ENS:
                return DeclarationType.IE_ENS;
            case DeclarationInternalType.ETD:
                return DeclarationType.IE_ETD;
            case DeclarationInternalType.TSD:
                return DeclarationType.IE_TSD;
            case DeclarationInternalType.ARRIVAL:
                return DeclarationType.IE_ARRIVAL;
            default:
                return null;
        }
    };

    const getChiefDeclarationType = () => {
        switch (declaration.declarationInternalType) {
            case DeclarationInternalType.EXPORT:
                return DeclarationType.UK_EXPORT;
            default:
                return null;
        }
    };

    const getCdsDeclarationType = () => {
        switch (declaration.declarationInternalType) {
            case DeclarationInternalType.IMPORT:
                return DeclarationType.UK_IMPORT;
            case DeclarationInternalType.IMPORT_NEW:
                return DeclarationType.UK_IMPORT_USING_BLOCKS;
            case DeclarationInternalType.EXPORT:
                return DeclarationType.UK_EXPORT;
            default:
                return null;
        }
    };

    switch (declaration.declarationExternalEntity) {
        case DeclarationExternalEntity.REVENUE:
            return getIrelandDeclarationType();
        case DeclarationExternalEntity.CHIEF:
            return getChiefDeclarationType();
        case DeclarationExternalEntity.CDS:
            return getCdsDeclarationType();
        default:
            // shouldn't happen, or should show an error page
            throw Error('Declaration must have an external entity');
    }
};
