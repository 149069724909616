import {
    CheckCircleOutlined,
    CloseOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    FileOutlined,
    InboxOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import Button from 'components/ui/base/button/Button';
import { CustomDiv as AntContainer } from 'components/ui/base/container/Container.styles';
import { H5, Text, TextSmall } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors } from 'theme';

export const Container = styled(AntContainer)`
    height: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: column;
`;

export const LeftRow = styled(Row)`
    max-height: 100%;
    overflow-y: scroll;
`;

export const TitleRow = styled(Row)`
    margin-bottom: 8.5rem;
`;

export const ColButtonFillForm = styled(Col)`
    display: flex;
    justify-content: flex-end;
`;

export const ReverseCol = styled(Col)`
    direction: rtl;
    justify-content: flex-end;
`;

export const CustomerOption = styled.div`
    background: rgba(255, 255, 255, 0.1);
    height: 5.6rem;
    padding: 16px;
`;

export const EmptyContainer = styled.div`
    color: ${colors.darkPurple};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.8rem;
    margin-bottom: 5.4rem;

    &.disabled {
        color: ${colors.darkGrey2};
    }
`;

export const CustomRow = styled(Row)`
    margin-bottom: 4rem;
    &.disabled {
        cursor: not-allowed;
    }
`;

export const CustomDiv = styled.div`
    width: 100%;
    margin-top: 1.6rem;
`;

export const EditIcon = styled(EditOutlined)`
    margin-right: 2.165rem;
    font-size: 18px;
    &:hover {
        cursor: pointer;
    }
`;

export const DeleteIcon = styled(DeleteOutlined)`
    margin-right: 2.165rem;
    font-size: 18px;
    &:hover {
        cursor: pointer;
    }
`;

export const CloseIcon = styled(CloseOutlined)`
    font-size: 18px;
    margin-right: 2rem;
    cursor: pointer;
    &.disabled {
        cursor: not-allowed;
        color: ${colors.darkGrey2};
    }
`;

export const CustomH5 = styled(H5)`
    &.disabled {
        cursor: not-allowed;
        color: ${colors.darkGrey2};
    }
`;

export const SectionTitle = styled(H5)`
    font-size: 22px;
    &.disabled {
        cursor: not-allowed;
        color: ${colors.darkGrey2};
    }
`;

export const OptionText = styled(TextSmall)`
    width: 8rem;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
`;

export const PreviewText = styled(TextSmall)`
    margin-bottom: 1.8rem;
`;

export const PreviewIcon = styled(CheckCircleOutlined)`
    margin-right: 2.5rem;
    color: ${colors.lightGreen};
`;

export const DownIcon = styled(DownOutlined)`
    margin-left: 0.5rem;
`;

export const FileIcon = styled(FileOutlined)`
    margin-right: 0.5rem;
`;

export const InvoiceButton = styled(Button)`
    width: 16rem;
`;

export const BlueText = styled(Text)`
    color: ${colors.blueLink};
`;

export const InboxOutlinedIcon = styled(InboxOutlined)`
    font-size: 1.6rem;
    color: ${colors.purpleBlue};
`;

export const BrowseSpan = styled.span`
    color: ${colors.purpleBlue};
`;

export const DraggerRow = styled(CustomRow)`
    margin-bottom: 8rem;
`;
