import { PaginatedParams } from 'core/http/pagination';
import { DeclarationExternalEntity } from './enums/common/declaration-external-entity';
import { DeclarationInternalType } from './enums/common/declaration-internal-type';
import { DeclarationStatus } from './enums/common/declaration-status';
import { ArrivalAtExitDeclaration } from './ireland/arrival-at-exit-declaration';
import { ElectronicTransportDocument } from './ireland/electronic-transport-document';
import { IrelandTemporaryStorageDeclaration } from './ireland/temporary-storage-declaration';
import { IrelandEntrySummaryDeclaration } from './ireland/entry-summary-declaration';
import { IrelandExportDeclaration } from './ireland/export-declaration';
import { IrelandH7ImportDeclaration } from './ireland/h7-import-declaration';
import { IrelandImportDeclaration } from './ireland/import-declaration';
import { CdsExportDeclaration } from './uk/export-declaration';
import { CdsImportDeclaration } from './uk/import-declaration';
import { CdsImportDeclarationUsingBlocks } from './uk/import-declaration-new';
import { DeclarationDocument } from '../../views/declarations/sections/customer-documents/types';

export interface NotifyEmails {
    customerEmail?: string;
    clerkEmail?: string;
}

export interface DeclarationFilterFields extends PaginatedParams {
    jobId?: string;
    costumerId?: string;
    declarationStatus?: DeclarationStatus[];
    declarationExternalEntity?: DeclarationExternalEntity[] | DeclarationExternalEntity;
    declarationInternalType?: DeclarationInternalType[] | DeclarationInternalType;
    query?: string;
    isCoreTemplate?: boolean;
    createdAt?: string;
}

export interface DeclarationDocumentsFilterFields extends PaginatedParams {
    query?: string;
    customerId?: string;
    declarationId?: string;
}

export interface DeclarationDocuments {
    id: string;
    link?: string;
    fileSize?: number;
    contentType?: string;
    documentType?: string;
    filename?: string;
    description?: string;
    documentDescription?: string;
    documentReference?: string;
    codeMappingAdditionalDocument?: string;
    createdAt?: string;
}

// don't like to have everything optional, consider creating requests and responses interfaces
export interface Declaration {
    id?: string;

    isCoreTemplate?: boolean;

    customerId?: string;

    individualId?: string;

    status: DeclarationStatus;

    jobId?: string;

    declarationExternalEntity?: DeclarationExternalEntity;

    declarationInternalType?: DeclarationInternalType;

    routing?: RoutingStatus;

    uploadedFiles?: DeclarationDocument[];

    // specific declarations, based on external entity and internal type, could be removed from here
    cdsImportDeclarationUsingBlocks?: CdsImportDeclarationUsingBlocks;
    cdsExportDeclaration?: CdsExportDeclaration;
    cdsImportDeclaration?: CdsImportDeclaration;
    irelandImportDeclaration?: IrelandImportDeclaration;
    irelandH7ImportDeclaration?: IrelandH7ImportDeclaration;
    irelandExportDeclaration?: IrelandExportDeclaration;
    irelandImportTemporaryStorageDeclaration?: IrelandTemporaryStorageDeclaration;
    entrySummaryDeclaration?: IrelandEntrySummaryDeclaration;
    irelandETDDeclaration?: ElectronicTransportDocument;
    ieArrivalExitDeclarationResponse?: ArrivalAtExitDeclaration;
    createdAt?: string;
    updatedAt?: string;
    mrn?: string;
    acceptanceDate?: string;
    referenceNumber?: string;
    templateId?: string;
}

export enum DeclarationSortParameters {
    ID = 'ID',
    MESSAGE_TYPE = 'MESSAGE_TYPE',
    STATUS = 'STATUS',
    EXTERNAL_ENTITY = 'EXTERNAL_ENTITY',
    INTERNAL_TYPE = 'INTERNAL_TYPE',
    EXPORTER = 'EXPORTER',
    IMPORTER = 'IMPORTER',
    DECLARANT = 'DECLARANT',
    CREATED_AT = 'CREATED_AT',
    DESCRIPTION_OF_GOODS = 'DESCRIPTION_OF_GOODS',
    DOCUMENTS = 'DOCUMENTS',
}

export interface Notifiable<TNotification> {
    notifications?: TNotification[];
}

export interface Amendable {
    amendmentAccepted?: boolean;
    amendmentRejected?: boolean;
    revenueAmendmentRequested?: boolean;
    userAmendmentSubmitted?: boolean;
}

export interface HasDocuments {
    documentsUploadRequested?: boolean;
    documentsUploaded?: boolean;
    documentsPresentationRequested?: boolean;
    uploadedDocuments?: string[];
}

export type DeclarationAndAgent = Declaration & { agent?: string };

export enum RoutingStatus {
    GREEN = 'GREEN',
    ORANGE = 'ORANGE',
    YELLOW = 'YELLOW',
    RED = 'RED',
}

export interface DeclarationHistory {
    id?: string;
    declarationId: string;
    type: 'SUBMIT';
    declaration: Declaration;
    createdAt: string;
    createdBy: string;
}
