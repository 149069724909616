import { IndividualType } from './individual';

export const isAdmin = (type: IndividualType): boolean => {
    return (
        type === IndividualType.BROKER_ADMIN ||
        type === IndividualType.TRADER_ADMIN ||
        type === IndividualType.HAULIER_ADMIN ||
        type === IndividualType.SYSTEM_ADMIN
    );
};

export const isHaulier = (type: IndividualType): boolean => {
    return type === IndividualType.DRIVER || type === IndividualType.HAULIER_ADMIN;
};

export const isHaulierAdmin = (type: IndividualType): boolean => {
    return type === IndividualType.HAULIER_ADMIN;
};

export const isDriver = (type: IndividualType): boolean => {
    return type === IndividualType.DRIVER;
};

export const isBroker = (type: IndividualType): boolean => {
    return type === IndividualType.BROKER_ADMIN || type === IndividualType.BROKER_CLERK;
};

export const isBrokerAdmin = (type: IndividualType): boolean => {
    return type === IndividualType.BROKER_ADMIN;
};

export const isBrokerClerk = (type: IndividualType): boolean => {
    return type === IndividualType.BROKER_CLERK;
};

export const isTrader = (type: IndividualType): boolean => {
    return type === IndividualType.TRADER_ADMIN || type === IndividualType.TRADER_USER;
};

export const isTraderAdmin = (type: IndividualType): boolean => {
    return type === IndividualType.TRADER_ADMIN;
};

export const isTraderUser = (type: IndividualType): boolean => {
    return type === IndividualType.TRADER_USER;
};
