import { useFormik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { H5Style } from '../../../../../components/ui/base/typography/Typography.styles';
import FormInput from '../../../../../components/ui/composed/declarations/formInput';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import FormTextArea from '../../../../../components/ui/composed/formTextArea/FormTextArea';
import { FormCardContainer } from '../../../common/cards/NewFormCard';
import * as Yup from 'yup';
import { getRequiredMessage } from '../../../utils/validation-utils';
import useCodelists from '../../../../../hooks/useCodelists';
import { normalizeCodesToSelect } from '../../../../../store/codelists/code';
import { HollowButton } from '../../../common/box44/Box44';
import { DocumentFormButtonsDiv } from '../CustomerDocuments.styles';
import { CloseOutlined, CheckOutlined, EyeOutlined } from '@ant-design/icons';
import useDeclarations from '../../../../../hooks/useDeclarations';
import { isEmpty } from 'lodash';
import { createDocumentAis, updateDocumentAis } from '../../../../../store/file-upload/client';
import { deleteFileCms, updateCmsFile } from '../../../../../store/documents/client';
import { CmsFileAndFormAction, DeclarationDocument } from '../types';
import CustomModal from 'components/ui/base/modal/Modal';
import { H5 } from '../../../../../components/ui/base/typography';
import { viewDocumentAction } from './DocumentsTable';
import { message } from 'antd';

const documentFromValidationSchema = Yup.object().shape({
    mrn: Yup.string().required('Declaration must have MRN.'),
    lrn: Yup.string().required('LRN is required.'),
    filename: Yup.string().required(getRequiredMessage('fileName')),
    documentType: Yup.string().required(getRequiredMessage('documentType')),
    documentIdentifier: Yup.string().required(getRequiredMessage('documentIdentifier')),
    documentReference: Yup.string().required(getRequiredMessage('documentReference')),
    documentDescription: Yup.string().required(getRequiredMessage('documentDescription')),
});

interface Props {
    declarationId: string;
    listCmsFiles: Function;
    cmsFileAndFormAction: CmsFileAndFormAction;
    declarationDocument: DeclarationDocument | undefined;
    cancelForm: Function;
    addDeclarationDocument: (newDoc: DeclarationDocument) => void;
    updateDeclarationDocument: (updatedDoc: DeclarationDocument) => void;
}

const DocumentForm = ({
    cmsFileAndFormAction,
    cancelForm,
    declarationId,
    declarationDocument,
    listCmsFiles,
    addDeclarationDocument,
    updateDeclarationDocument,
}: Props) => {
    const { t } = useTranslation('form');
    const { declaration } = useDeclarations();
    const { codelists } = useCodelists();
    const [isDeleteCmsFileModalVisible, setIsDeleteCmsFileModalVisible] = useState<boolean>(false);

    const refNumber = useMemo(() => '2.3', []);

    const formikInitialValues = useMemo(
        () => ({
            // declaration values
            mrn: declaration?.mrn,
            lrn: declaration?.irelandImportDeclaration?.lrn ?? declaration?.irelandH7ImportDeclaration?.lrn,
            // uploaded file / CMS requests values
            fileSize: cmsFileAndFormAction.file?.fileSize,
            filename: cmsFileAndFormAction.file?.filename,
            documentType: cmsFileAndFormAction.file?.documentType,
            link: cmsFileAndFormAction.file?.link,
            // uploaded document / AIS requests values
            documentReference: declarationDocument?.fileInfo.documentReference,
            documentIdentifier: declarationDocument?.fileInfo.documentIdentifier,
            documentDescription: declarationDocument?.fileInfo.documentDescription,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const formik = useFormik({
        initialValues: formikInitialValues ?? {},
        validateOnMount: true,
        validationSchema: documentFromValidationSchema,
        enableReinitialize: true,
        onSubmit: () => {},
    });

    const handleSubmit = useCallback(async () => {
        const errors = await formik.validateForm();
        formik.setTouched(errors as any);

        if (!isEmpty(errors)) return;

        const aisDocumentBody = {
            fileId: cmsFileAndFormAction.file?.id,
            fileReference: formik.values.link,
            fileName: formik.values.filename,
            fileInfo: {
                documentDescription: formik.values.documentDescription,
                documentType: formik.values.documentType,
                documentReference: formik.values.documentReference,
                documentIdentifier: formik.values.documentIdentifier,
            },
        };

        const cmsFileBody = {
            id: cmsFileAndFormAction.file?.id,
            type: formik.values.documentType,
            filename: formik.values.filename,
            description: formik.values.documentDescription,
        };

        if (cmsFileAndFormAction.action === 'upload') {
            addDeclarationDocument(await createDocumentAis(aisDocumentBody, declarationId));
        } else if (cmsFileAndFormAction.action === 'edit') {
            updateDeclarationDocument(
                await updateDocumentAis(
                    { ...aisDocumentBody, id: declarationDocument?.id, status: declarationDocument?.status },
                    declarationId
                )
            );
            message.success('File updated successfully.');
        }

        await updateCmsFile(cmsFileBody.id, cmsFileBody);
        cancelForm?.();
        listCmsFiles?.();
    }, [
        formik,
        declarationId,
        cancelForm,
        listCmsFiles,
        cmsFileAndFormAction,
        declarationDocument,
        addDeclarationDocument,
        updateDeclarationDocument,
    ]);

    return (
        <>
            <H5Style style={{ marginBottom: '1rem' }}>{t('customerDocuments.upload_document')}</H5Style>
            <FormCardContainer oneColumn>
                <FormInput
                    disabled
                    label={'MRN'}
                    fieldProps={formik.getFieldProps('mrn')}
                    fieldMeta={formik.getFieldMeta('mrn')}
                    condensed
                    required
                    refNumber={refNumber}
                    tooltip="MRN"
                />
                <FormInput
                    disabled
                    label={'LRN'}
                    fieldProps={formik.getFieldProps('lrn')}
                    fieldMeta={formik.getFieldMeta('lrn')}
                    condensed
                    required
                    refNumber={refNumber}
                    tooltip="LRN"
                />
                <FormInput
                    disabled
                    label={'File Size'}
                    fieldProps={formik.getFieldProps('fileSize')}
                    fieldMeta={formik.getFieldMeta('fileSize')}
                    condensed
                    refNumber={refNumber}
                    tooltip="File Size"
                />
                <FormInput
                    required
                    label={'File Name'}
                    fieldProps={formik.getFieldProps('filename')}
                    fieldMeta={formik.getFieldMeta('filename')}
                    condensed
                    refNumber={refNumber}
                    tooltip="File Name"
                />
                <FormSelect
                    label={'Document Type'}
                    fieldProps={formik.getFieldProps('documentType')}
                    fieldMeta={formik.getFieldMeta('documentType')}
                    condensed
                    required
                    refNumber={refNumber}
                    tooltip="Document Type"
                    selectOptions={normalizeCodesToSelect(codelists?.irelandCommonDocumentsTypes ?? [])}
                />
                <FormInput
                    label={'Document Identifier'}
                    fieldProps={formik.getFieldProps('documentIdentifier')}
                    fieldMeta={formik.getFieldMeta('documentIdentifier')}
                    condensed
                    required
                    refNumber={refNumber}
                    tooltip="Document Identifier"
                />
                <FormTextArea
                    label={'Document Reference'}
                    fieldProps={formik.getFieldProps('documentReference')}
                    fieldMeta={formik.getFieldMeta('documentReference')}
                    required
                    refNumber={refNumber}
                    tooltip="Document Reference"
                    condensed
                    rows={6}
                />
                <FormTextArea
                    required
                    label={'Document Description'}
                    fieldProps={formik.getFieldProps('documentDescription')}
                    fieldMeta={formik.getFieldMeta('documentDescription')}
                    refNumber={refNumber}
                    tooltip="Document Description"
                    condensed
                    rows={5}
                />
            </FormCardContainer>
            <DocumentFormButtonsDiv>
                <HollowButton size="small" onClick={() => viewDocumentAction(cmsFileAndFormAction.file)}>
                    Preview <EyeOutlined />
                </HollowButton>
                <HollowButton
                    size="small"
                    onClick={() =>
                        cmsFileAndFormAction.action === 'upload' ? setIsDeleteCmsFileModalVisible(true) : cancelForm?.()
                    }
                >
                    Cancel <CloseOutlined />
                </HollowButton>
                <HollowButton size="small" onClick={() => handleSubmit()}>
                    {cmsFileAndFormAction.action === 'upload' ? 'Add' : 'Update'} <CheckOutlined />
                </HollowButton>
            </DocumentFormButtonsDiv>
            <CustomModal
                title={<H5>You will delete the uploaded file.</H5>}
                centered
                visible={isDeleteCmsFileModalVisible}
                onOk={async () => {
                    await deleteFileCms(cmsFileAndFormAction.file?.id!);
                    cancelForm?.();
                }}
                onCancel={() => setIsDeleteCmsFileModalVisible(false)}
                width={762}
                contentText={'If you delete the uploaded file, you will lose all the information associated with it.'}
            />
        </>
    );
};

export default DocumentForm;
