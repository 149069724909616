import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';

import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import IdentificationOfWarehouseBlock, {
    identificationOfWarehouseBlockValidation,
} from '../blocks/IdentificationOfWarehouseBlock';

export const identificationOfWarehouseCardValidation = {
    selfValidators: [identificationOfWarehouseBlockValidation],
};

interface Props extends DeclarationFormCardProps {
    fieldOptions?: FieldOptions<'id' | 'typeCode'>;
}

const IdentificationOfWarehouseCard = (props: Props): ReactElement => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('identificationOfWarehouse.title')}>
            <FormCardContainer>
                <IdentificationOfWarehouseBlock
                    fieldOptions={{
                        id: { required: props.fieldOptions?.id?.required },
                        typeCode: { required: props.fieldOptions?.typeCode?.required },
                    }}
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default IdentificationOfWarehouseCard;
