import { FormikProps } from 'formik';

export const updateIrelandH7ImportTaricCode = (formik: FormikProps<any>, code: string) => {
    const taric = code.substring(0, 6);
    formik.setFieldValue('commodityCodeHarmonizedSystemSubHeadingCode', taric);
    formik.setFieldValue('commodityCodeField', code);
};

export const getIrelandH7ImportTaricCode = (formik: FormikProps<any>) => {
    return formik.getFieldProps('commodityCodeHarmonizedSystemSubHeadingCode').value;
};

export const updateIrelandH1ImportTaricCode = (formik: FormikProps<any>, code: string) => {
    const taric = code.substring(0, 8);
    const addTaricCode = code.substring(8);
    formik.setFieldValue('goodsInformation.combinedNomenclatureCode', taric);
    formik.setFieldValue('goodsInformation.taricCode', addTaricCode);
    formik.setFieldValue('commodityCodeField', code);
};

export const getIrelandH1ImportTaricCode = (formik: FormikProps<any>) => {
    return formik.getFieldProps('goodsInformation.combinedNomenclatureCode').value;
};

export const updateIrelandExportTaricCode = (formik: FormikProps<any>, code: string) => {
    const taric = code.substring(0, 8);
    formik.setFieldValue('commodity.nomenclatureCode', taric);
};

export const getIrelandExportTaricCode = (formik: FormikProps<any>) => {
    return formik.getFieldProps('commodity.nomenclatureCode').value;
};
export const updateIrelandEnsTaricCode = (formik: FormikProps<any>, code: string) => {
    const taric = code.substring(0, 8);
    formik.setFieldValue('commodity.combinedNomenclature', taric);
    formik.setFieldValue('commodityCodeField', code);
};

export const getIrelandEnsTaricCode = (formik: FormikProps<any>) => {
    return formik.getFieldProps('commodityCode').value;
};

export const updateUkImportTaricCode = (formik: FormikProps<any>, code: string) => {
    const taric = code.substring(0, 8);
    formik.setFieldValue('commodityCombinedNomenclatureCodeIdentifier', taric);
};

export const getUkImportTaricCode = (formik: FormikProps<any>) => {
    return formik.getFieldProps('commodityCombinedNomenclatureCodeIdentifier').value;
};

export const updateUkExportTaricCode = (formik: FormikProps<any>, code: string) => {
    const taric = code.substring(0, 8);
    formik.setFieldValue('baseCommodityCode', taric);
    formik.setFieldValue('commodityCodeField', code);
};

export const getUkExportTaricCode = (formik: FormikProps<any>) => {
    return formik.getFieldProps('baseCommodityCode').value;
};
