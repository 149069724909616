import { CheckCircleFilled, CloseCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import plusIcon from 'assets/plus.svg';
import Button from 'components/ui/base/button';
import { TextSmall, TextSmallBold } from 'components/ui/base/typography';
import styled from 'styled-components';
import { colors } from 'theme';
import { CardState } from './card-state';

interface InvalidProps {
    invalid?: boolean;
}

interface StateProps {
    state?: CardState;
}

const handleStateColor = (state?: CardState) => {
    switch (state) {
        case CardState.INVALID:
            return colors.error;
        case CardState.VALID:
            return colors.lightGreen;
        default:
            return colors.darkGrey;
    }
};

export const AddCardIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    margin-left: 1rem;
    cursor: pointer;
`;

export const DivRightHand = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const CardRow = styled(Row)`
    margin-bottom: 0.8rem;
`;

export const ColRightHand = styled(Col)`
    overflow: hidden;
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
`;

export const VerticalLine = styled.div<StateProps>`
    height: 100%;
    width: 0.1rem;
    margin-left: 1.2rem;
    margin-bottom: 0.8rem;
    background: ${({ state }) => handleStateColor(state)};
`;

AddCardIcon.defaultProps = {
    src: plusIcon,
};

export const Title = styled.div<InvalidProps>`
    color: ${({ invalid }) => (invalid ? colors.error : colors.black)};
`;

export const DeleteButton = styled(Button)`
    margin-right: 1.6rem;
`;

export const PanelHeader = styled.div`
    display: flex;
    min-height: 3.7rem;
`;

export const PanelHeaderIcon = styled.div`
    margin-left: 4rem;
    margin-top: -0.6rem;
`;

export const FilledIcon = styled(CheckCircleFilled)`
    margin-right: 1rem;
    color: ${colors.lightGreen};
    font-size: 2.4rem;
`;

export const ErrorIcon = styled(CloseCircleFilled)`
    margin-right: 1rem;
    color: ${colors.error};
    font-size: 2.4rem;
`;

export const EmptyIcon = styled(MinusCircleFilled)`
    margin-right: 1rem;
    color: ${colors.darkGrey};
    font-size: 2.4rem;
`;

export const InvalidTextSmallBold = styled(TextSmallBold)`
    color: ${colors.error};
`;

export const CardTextSmall = styled(TextSmall)<StateProps>`
    color: ${({ state }) => handleStateColor(state)};
`;

export const ButtonsRow = styled(Row)`
    width: 90%;
    margin-top: 1rem;
`;

export const ButtonsCol = styled(Col)`
    text-align: right;
`;
