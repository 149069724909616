import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import addPathPrefix from 'utils/addPathPrefix';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import MultipleItemsCard from 'views/declarations/common/MultipleItemsCard';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { FormCardContainer } from '../../../../common/cards/NewFormCard';

const AdditionalInformationCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <MultipleItemsCard
            getFieldHelpers={props.getFieldHelpers}
            getFieldProps={props.getFieldProps}
            path={props.propsPathPrefix ?? 'goodsShipment.additionalInformation'}
            initialValues={{ additionalInformationCode: '', additionalInformationText: '' }}
            list={(list) => [
                {
                    field: t('additionalInformation.code'),
                    value: list.additionalInformationCode,
                },
                {
                    field: t('additionalInformation.description'),
                    value: list.additionalInformationText,
                },
            ]}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            keyCard={props.keyCard}
            title={t('additionalInformation.title')}
            condensed
            refNumber="2.2"
            form={(formProps, path) => (
                <FormCardContainer>
                    <FormSelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'additionalInformationCode'), formProps)}
                        refNumber="2.2"
                        testId="additionalInformationCode"
                        label={t('additionalInformation.code')}
                        condensed
                        selectOptions={normalizeCodesToSelect(codelists?.irelandAdditionalInformationCodes || [])}
                        tooltip={getH1TooltipsByRefNumberAndField('2.2', t('additionalInformation.code'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                    />
                    <FormInput
                        maxLength={512}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(addPathPrefix(path, 'additionalInformationText'), formProps)}
                        refNumber="2.2"
                        condensed
                        testId="additionalInformationText"
                        label={t('additionalInformation.description')}
                        tooltip={getH1TooltipsByRefNumberAndField('2.2', t('additionalInformation.description'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                    />
                </FormCardContainer>
            )}
        />
    );
};
export default AdditionalInformationCard;
