import { Divider, Radio, RadioChangeEvent } from 'antd';
import { TemplateContextProvider } from 'components/ui/composed/template/TemplateContext';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import Container from 'components/ui/base/container';
import TemplatesTable from './TemplatesTable';
import { SpaceBetween } from 'components/styles/layout.styles';
import { H5 } from 'components/ui/base/typography';
import Button from 'components/ui/base/button/Button';
import CreateTemplateDrawer from './components/CreateTemplateDrawer';
import TemplateModal from 'components/ui/composed/template/TemplateModal';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { AvailableTemplateFormTypes } from 'store/template/action';

const templateFormValues = {
    IRELAND: {
        IMPORT: ['H1', 'H7'],
        ENS: [],
    },
    UK: {
        EXPORT: ['B1'],
    },
} as Record<string, Record<string, string[]>>;

const TemplateDashboard = (): ReactElement => {
    const [visible, setVisible] = useState<boolean>(false);
    const [country, setCountry] = useState<DeclarationCountry>(DeclarationCountry.IRELAND);
    const [type, setType] = useState<DeclarationInternalType>(DeclarationInternalType.IMPORT as any);
    const [formType, setFormType] = useState<AvailableTemplateFormTypes>('H1');

    const internalTypeOptions = useMemo(() => {
        if (!country) return undefined;

        return Object.keys(templateFormValues?.[country.toUpperCase()]).map((ft) => ({
            value: ft,
            label: ft,
        }));
    }, [country]);

    const formTypeOptions = useMemo(() => {
        if (!country || !type) return undefined;
        return templateFormValues?.[country.toUpperCase()]?.[type]?.map((ft) => ({
            value: ft,
            label: ft,
        }));
    }, [country, type]);

    // Set default form type on country and type change
    useEffect(() => {
        setFormType(templateFormValues?.[country.toUpperCase()]?.[type]?.[0] as any);
    }, [country, type]);

    const handleOpen = () => {
        setVisible(true);
    };
    const handleClose = () => {
        setVisible(false);
    };

    const handleCountryChange = ({ target: { value } }: RadioChangeEvent) => {
        const parseValue = (value: any) => {
            switch (value) {
                case 'ireland':
                case 'uk':
                    return value;
                default:
                    return 'ireland';
            }
        };
        setCountry(parseValue(value));
    };
    const handleTypeChange = ({ target: { value } }: RadioChangeEvent) => {
        setType(value);
    };
    const handleFormTypeChange = ({ target: { value } }: RadioChangeEvent) => {
        setFormType(value);
    };

    return (
        <Container>
            <SpaceBetween>
                <H5>Submission Form Templates</H5>

                <Button size="large" type="primary" onClick={handleOpen}>
                    Create New Template
                </Button>
                <TemplateContextProvider>
                    <CreateTemplateDrawer visible={visible} onClose={handleClose} />
                </TemplateContextProvider>
            </SpaceBetween>
            <Divider />
            <div>
                <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                    <Radio.Group
                        options={[
                            { value: 'ireland', label: 'Ireland' },
                            { value: 'uk', label: 'Uk' },
                        ]}
                        value={country}
                        optionType="button"
                        buttonStyle="solid"
                        onChange={handleCountryChange}
                    />
                    <Radio.Group
                        options={internalTypeOptions}
                        value={type}
                        optionType="button"
                        buttonStyle="solid"
                        onChange={handleTypeChange}
                    />
                </div>
                <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                    <Radio.Group
                        options={formTypeOptions}
                        value={formType && formType?.toUpperCase()}
                        optionType="button"
                        buttonStyle="solid"
                        onChange={handleFormTypeChange}
                    />
                </div>
                <TemplateContextProvider>
                    <TemplatesTable country={country} type={type} formType={formType} />
                    <TemplateModal />
                </TemplateContextProvider>
            </div>
        </Container>
    );
};

export default TemplateDashboard;
