import { Col, Row, Tabs } from 'antd';
import Alert from 'components/ui/base/alert/Alert';
import Button from 'components/ui/base/button/Button';
import Drawer from 'components/ui/base/drawer/Drawer';
import Modal from 'components/ui/base/modal/Modal';
import Notification from 'components/ui/base/notification/Notification';
import Searchbar from 'components/ui/base/searchbar';
import { H4, H5 } from 'components/ui/base/typography';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import useProductsTemplates from 'hooks/useProductsTemplates';
import debounce from 'lodash.debounce';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import ProductsTable, { Product } from './components/ProductsTable';
import { ListButtonsDiv } from './Products.styles';
import CustomModal from 'components/ui/base/modal/Modal';
import BulkUploadDrawer from './bulk-upload/BulkUploadDrawer';
import ProductBulkUploadModal from './bulk-upload/ProductBulkUploadModal';
import useRequest from 'hooks/useRequest';
import { createBulkProducts, createUkBulkProducts } from 'store/products-templates/client';
import { DeclarationInternalType } from '../../../store/declarations/enums/common/declaration-internal-type';
import { StyledTabs } from 'views/declarations/Form.styles';

import { useRequestPromise } from 'hooks/useRequest';
import TemplatesTable from 'views/templates/TemplatesTable';
import styled from 'styled-components';
import { getTemplates } from 'store/template/client';
import { AvailableTemplateFormTypes } from 'store/template/action';

const TableWrapper = styled.div`
    height: calc(100% - (100vh - 100%));

    .ant-table-wrapper {
        height: 100%;
        overflow: auto;
    }
`;

const { TabPane } = Tabs;

export enum ProductTabKeys {
    H1 = 'h1',
    H7 = 'h7',
    B1 = 'b1',
    ENS = 'ens',
}

const ListProductTemplates: FC<{}> = () => {
    const { country, internalType, type } = useParams<{
        country: DeclarationCountry;
        internalType: DeclarationInternalType;
        type: ProductTabKeys;
    }>();
    const { t } = useTranslation('customs_declarations');
    const { productsTemplates, listProducts, isLoading, deleteProductTemplate, error } = useProductsTemplates({
        country,
        type,
    });
    const navigate = useNavigate();
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>();
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const [deletedProducts, setDeletedProducts] = useState<boolean>(false);
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [showProductBulkUpload, setShowProductBulkUpload] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);
    const bulkRequest = country === DeclarationCountry.UK ? createUkBulkProducts : createBulkProducts;
    const { doRequest: createBulk } = useRequest(bulkRequest);
    const [messages, setMessages] = useState<{ message: string; type: 'success' | 'error' }[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const [selectedTemplateId, setSelectedTemplateId] = useState<string | undefined>(undefined);
    const handleTemplateSelect = (templateId: string | undefined) => {
        setSelectedTemplateId(templateId);
    };

    const { data: declarationTemplates, refetch } = useRequestPromise(async () => {
        if (!type) throw new Error('No declaration type provided');
        return getTemplates(
            country ?? 'ireland',
            (internalType?.toLowerCase() as 'import' | 'export' | undefined) ?? 'import',
            type.toUpperCase() as any,
            { size: 9999 }
        ).then((payload) => payload.list);
    });

    // Get the right templates when the url changes
    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, internalType, type]);

    useEffect(() => {
        if (deletedProducts && !isLoading) {
            if (!error) {
                if (deleteIds.length > 1) {
                    Notification({
                        type: 'success',
                        messageTitle: t('product-template.multiple-delete-success-title'),
                        description: t('product-template.multiple-delete-success-message'),
                    });
                } else {
                    Notification({
                        type: 'success',
                        messageTitle: t('product-template.delete-success-title'),
                        description: t('product-template.delete-success-message'),
                    });
                }
                setDeletedProducts(false);
                setDeleteIds([]);
                listProducts();
            } else {
                if (deleteIds.length > 1) {
                    Notification({
                        type: 'error',
                        messageTitle: t('product.delete-error-title'),
                        description: t('product-template.multiple-delete-error-message'),
                    });
                } else {
                    Notification({
                        type: 'error',
                        messageTitle: t('product.delete-error-title'),
                        description: t('product-template.delete-error-message'),
                    });
                    setDeletedProducts(false);
                }
            }
        }
    }, [deletedProducts, isLoading, deleteIds, listProducts, error, t]);

    const handlePagination = (params: Partial<PaginatedParams>) => {
        if (paginator && paginator.page && paginator.size) {
            setPaginator({ page: params.page, size: paginator.size });
        } else {
            setPaginator({ page: params.page, size: defaultPagination.size });
        }
    };

    const handleSort = (params: Partial<PaginatedParams>) => {
        setPaginator({ ...paginator, ...params });
    };

    useEffect(() => {
        if (paginator) {
            const p = { ...paginator };
            listProducts(p);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator, country]);

    useEffect(() => {
        listProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, type]);

    const querySearch = async (query: string) => {
        if (query) {
            const params = { query };
            await listProducts(params);
        } else {
            await listProducts();
        }
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    const deleteProduct = async () => {
        setModalVisible(false);
        const toDelete: string[] = [...deleteIds];
        Promise.all(toDelete.map(async (e) => await deleteProductTemplate(e))).then((res) => setDeletedProducts(true));
    };

    const handleDelete = (ids: string[]) => {
        setDeleteIds(ids);
        setModalVisible(true);
    };

    const handleCreateBulk = async () => {
        const productsWithTags = products.map((p) => ({ ...p, tags: selectedTags }));
        const createBulkData = await createBulk(productsWithTags);

        if (createBulkData !== null) {
            listProducts({ ...paginator });
            setShowProductBulkUpload(false);
            showMessage(t('bulk-upload.success-message'), 'success');
        } else {
            showMessage(t('bulk-upload.error-message'), 'error');
        }
    };

    const handleUploadedProducts = (products: Product[]) => {
        setProducts(products);
        setShowBulkUpload(false);
        setShowProductBulkUpload(true);
    };

    const showAlert = useMemo(() => {
        return messages?.map(
            (message, i) =>
                message?.type &&
                message?.message && (
                    <Alert
                        key={i}
                        type={message.type}
                        message={message.message}
                        showIcon
                        style={{ marginBottom: '2.4rem' }}
                        closable
                    />
                )
        );
    }, [messages]);

    const showMessage = (message: string, type: 'success' | 'error') => {
        setMessages((prev) => [...prev, { message, type }]);
    };

    const [declarationTemplateDrawerShown, setDeclarationTemplateDrawerShown] = useState(false);
    const openDeclarationTemplateDrawer = () => {
        setDeclarationTemplateDrawerShown(true);
    };
    const closeDeclarationTemplateDrawer = () => {
        setDeclarationTemplateDrawerShown(false);
        setSelectedTemplateId(undefined);
    };

    const redirectToAddProductView = useCallback(() => {
        let url = `/customs-declarations/${country}/${internalType}/products/${type}/new`;
        if (selectedTemplateId) url = url.concat(`?declarationTemplateId=${selectedTemplateId}`);
        navigate(url);
    }, [country, internalType, navigate, selectedTemplateId, type]);

    const productsButtons = useMemo(() => {
        return (
            <ListButtonsDiv>
                <Button
                    size="large"
                    style={{ marginRight: '1.6rem' }}
                    onClick={() => {
                        openDeclarationTemplateDrawer();
                    }}
                >
                    {t(`addNew${type}Product`)}
                </Button>
                {type === ProductTabKeys.H1 && country === DeclarationCountry.IRELAND && (
                    <Button size="large" onClick={() => setShowBulkUpload(true)}>
                        {t('bulk-upload.add-bulk-h1-upload')}
                    </Button>
                )}
            </ListButtonsDiv>
        );
    }, [t, type, country]);

    const irelandTemplateViewTabsAndButtons = useMemo(() => {
        return country === DeclarationCountry.IRELAND ? (
            <Row style={{ position: 'relative' }}>
                <Col span={24}>
                    <StyledTabs
                        onChange={(k) => {
                            const tab = k as ProductTabKeys;
                            if (tab === ProductTabKeys.H1) {
                                navigate('/customs-declarations/ireland/IMPORT/products/h1');
                            } else if (tab === ProductTabKeys.H7) {
                                navigate(`/customs-declarations/ireland/IMPORT/products/h7`);
                            } else if (tab === ProductTabKeys.ENS) {
                                navigate(`/customs-declarations/ireland/ens/products/ens`);
                            }
                        }}
                        activeKey={type}
                    >
                        <TabPane tab={'H1 View'} key={'h1'} />
                        <TabPane tab={'H7 View'} key={'h7'} />
                        <TabPane tab={'ENS View'} key={'ens'} />
                    </StyledTabs>
                </Col>
                <Col style={{ position: 'absolute', right: '0' }}>{productsButtons}</Col>
            </Row>
        ) : null;
    }, [country, productsButtons, type, navigate]);

    return (
        <>
            <Row style={{ marginBottom: '1.5rem' }}>
                <Col span={20}>
                    <H5>
                        {country === DeclarationCountry.IRELAND
                            ? `${t(`productsFromIrelandCustoms`)}`
                            : `${t(`${internalType}ProductsFromUkCustoms`)}`}
                    </H5>
                </Col>
                {country === DeclarationCountry.UK && <Col span={4}>{productsButtons}</Col>}
            </Row>
            {irelandTemplateViewTabsAndButtons}
            {showAlert}
            <Row gutter={16} wrap={false} style={{ marginBottom: '3.2rem' }}>
                <Col flex="auto">
                    <Searchbar
                        inputPlaceholder="Search products"
                        onClear={() => listProducts()}
                        onSearch={(value) => debouncedSearch(value)}
                    />
                </Col>
            </Row>

            <ProductsTable
                country={country}
                internalType={internalType}
                loading={isLoading}
                source={productsTemplates}
                refresh={() => {
                    listProducts();
                }}
                handlePagination={handlePagination}
                declarationTemplatesList={declarationTemplates}
                handleSort={handleSort}
                onDelete={handleDelete}
            />
            <Modal
                title={
                    deleteIds.length > 1 ? (
                        <H5>{t('product-template.multiple-delete-title')}</H5>
                    ) : (
                        <H5>{t('product-template.delete-title')}</H5>
                    )
                }
                centered
                visible={modalVisible}
                onOk={deleteProduct}
                onCancel={() => setModalVisible(false)}
                width={762}
                contentText={
                    deleteIds.length > 1
                        ? t('product-template.multiple-delete-text')
                        : t('product-template.delete-text')
                }
            />
            <Drawer
                title={'Bulk Upload'}
                width="1003"
                visible={showBulkUpload}
                onClose={() => setShowBulkUpload(false)}
            >
                <BulkUploadDrawer setUploadedProducts={handleUploadedProducts} />
            </Drawer>
            <CustomModal
                title={<H4>Product Bulk Upload</H4>}
                visible={showProductBulkUpload}
                width={1143}
                testId="continue-modal"
                confirmText="Continue"
                cancelText="Cancel"
                onCancel={() => setShowProductBulkUpload(false)}
                onOk={() => handleCreateBulk()}
            >
                <ProductBulkUploadModal
                    products={products}
                    selectedTags={selectedTags}
                    onTagsSelected={setSelectedTags}
                />
            </CustomModal>
            {country && internalType && (
                <Drawer
                    title="Select declaration template"
                    visible={declarationTemplateDrawerShown}
                    onClose={closeDeclarationTemplateDrawer}
                    width="60rem"
                    destroyOnClose
                >
                    <Button
                        type="primary"
                        style={{ marginLeft: 'auto', display: 'block' }}
                        size="large"
                        onClick={() => {
                            redirectToAddProductView();
                            closeDeclarationTemplateDrawer();
                        }}
                    >
                        Create product template
                    </Button>
                    <TableWrapper>
                        <TemplatesTable
                            options={{ visible: { templateName: true } }}
                            withPagination={false}
                            viewOnly
                            country={country}
                            type={internalType?.toUpperCase() as DeclarationInternalType}
                            formType={type?.toUpperCase() as AvailableTemplateFormTypes}
                            onRowClick={handleTemplateSelect}
                        />
                    </TableWrapper>
                </Drawer>
            )}
        </>
    );
};

export default ListProductTemplates;
