import { FormikErrors } from 'formik';
import { CardError, FieldError, ItemsFieldError } from 'store/declaration-form-errors/DeclarationFormErrors';
import { AdditionDeduction } from 'store/declarations/common/addition-deduction';
import { AdditionalFiscalReferenceIdentification } from 'store/declarations/common/additional-fiscal-reference-identification';
import { AdditionalInformation } from 'store/declarations/common/additional-information';
import { AdditionalNationalCode } from 'store/declarations/common/additional-national-code';
import { AdditionalProcedure } from 'store/declarations/common/additional-procedure';
import { AdditionalSupplyChainActor } from 'store/declarations/common/additional-supply-chain-actor';
import { AdditionalTaricCode } from 'store/declarations/common/additional-taric-code';
import { ContainerIdentificationNumber } from 'store/declarations/common/container-identification-number';
import { DeclarationCustomer } from 'store/declarations/common/declaration-customer';
import { DeferredPayment } from 'store/declarations/common/deferred-payment';
import { DocumentProduced } from 'store/declarations/common/document-produced';
import { GuaranteeReference } from 'store/declarations/common/guarantee-reference';
import { GuaranteeType } from 'store/declarations/common/guarantee-type';
import { Packaging } from 'store/declarations/common/packaging';
import { PreviousDocument } from 'store/declarations/common/previous-document';
import { Tax } from 'store/declarations/common/tax';
import { HolderOfTheAuthorisations } from 'store/declarations/enums/common/holder-of-the-authorisation-type';
import { GovernmentAgencyGoodsItem } from 'store/declarations/uk/goods-item';
import { GoodsShipment } from 'store/declarations/uk/goods-shipment';
import { CdsImportDeclaration } from 'store/declarations/uk/import-declaration';

export const parseValidationErrors = (validation: FormikErrors<CdsImportDeclaration>) => {
    const masterDetailsErros: CardError[] = [];
    parseMasterdetails(masterDetailsErros, validation);
    parsePreviousDocument(masterDetailsErros, validation);
    parseAdditionalInformation(masterDetailsErros, validation);
    parseDefferedPayment(masterDetailsErros, validation);
    parseIdentificationWarehouse(masterDetailsErros, validation);
    parseExporter(masterDetailsErros, validation);
    parseImporter(masterDetailsErros, validation);
    parseDeclarant(masterDetailsErros, validation);
    parseRepresentative(masterDetailsErros, validation);
    parseSeller(masterDetailsErros, validation);
    parseBuyer(masterDetailsErros, validation);
    parseHolderOfTheAuthorisation(masterDetailsErros, validation);
    parseDeliveryTerms(masterDetailsErros, validation);
    parseTotalAmountInvoiced(masterDetailsErros, validation);
    parseExchangeRate(masterDetailsErros, validation);
    parseTransportCostsToFinalDestination(masterDetailsErros, validation);
    parseCountryDestination(masterDetailsErros, validation);
    parsePlaceOfloading(masterDetailsErros, validation);
    parseLocationOfGoods(masterDetailsErros, validation);
    parseCustomsOfficeOfPresentation(masterDetailsErros, validation);
    parseGrossMass(masterDetailsErros, validation);
    parseModeOfTransportAtBorder(masterDetailsErros, validation);
    parseIdOfMeansTransportOnArrival(masterDetailsErros, validation);
    parseGuaranteeTypes(masterDetailsErros, validation);
    parseGuaranteeReference(masterDetailsErros, validation);
    parseNatureOfTransation(masterDetailsErros, validation);
    parseAcceptanceDate(masterDetailsErros, validation);
    parseAdditionalConditionalInformation(masterDetailsErros, validation);
    const itemsErrors: ItemsFieldError[] = [];
    parseDeclarationItems(itemsErrors, validation);
    const errors = {
        masterDetails: masterDetailsErros,
        items: itemsErrors,
    };
    return errors;
};

function parseMasterdetails(errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);

    if (validation?.declarationType) {
        fields.push({ name: 'Declaration Type', message: validation?.declarationType });
    }

    if (validation?.additionalDeclarationType) {
        fields.push({ name: 'Additional Declaration Type', message: validation?.additionalDeclarationType });
    }

    if (goodsShipment?.ucr) {
        fields.push({ name: 'Ucr', message: goodsShipment?.ucr });
    }

    if (validation?.numberOfItems) {
        fields.push({ name: 'Number of items', message: validation?.numberOfItems });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Master Details', fields });
    }
}

// NO SUCH FIELD IN SCHEMA BUT THERE IS A CARD FOR THIS
// const parseValueDetails: ParseFunction = (errors, validation) => {
//     const fields: FieldError[] = [];
//     if (fields.length > 0) {
//         errors.push({ name: 'Value details', fields });
//     }
// };

const parsePreviousDocument = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    const previousDocument = goodsShipment?.previousDocuments as unknown as any[];
    previousDocument?.forEach((element: FormikErrors<PreviousDocument>, index: number) => {
        const fields: FieldError[] = [];

        if (element?.previousDocumentCategory) {
            fields.push({
                name: `Previous Document Category`,
                message: element?.previousDocumentCategory,
            });
        }

        if (element?.previousDocumentType) {
            fields.push({
                name: `Previous Document Type`,
                message: element?.previousDocumentType,
            });
        }

        if (element?.previousDocumentReference) {
            fields.push({
                name: `Previous Document Teference`,
                message: element?.previousDocumentReference,
            });
        }

        if (element?.goodsItemIdentifier) {
            fields.push({
                name: `Goods Item Identifier`,
                message: element?.goodsItemIdentifier,
            });
        }

        if (fields.length > 0) {
            errors.push({ name: `Previous document ${index}`, fields });
        }
    });
};

const parseAdditionalInformation = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const additionalInformations = validation?.additionalInformations as unknown as any[];

    if (!Array.isArray(additionalInformations)) return;

    additionalInformations?.forEach((element: FormikErrors<AdditionalInformation>, index: number) => {
        const fields: FieldError[] = [];

        if (element?.additionalInformationCode) {
            fields.push({
                name: `Code`,
                message: element?.additionalInformationCode,
            });
        }

        if (element?.additionalInformationText) {
            fields.push({
                name: `Text`,
                message: element?.additionalInformationText,
            });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Information ${index}`, fields });
        }
    });
};

const parseDefferedPayment = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const defferedPayments = validation?.additionalInformations as unknown as any[];

    if (!Array.isArray(defferedPayments)) return;

    defferedPayments?.forEach((element: FormikErrors<DeferredPayment>, index: number) => {
        const fields: FieldError[] = [];

        if (element?.deferredPaymentIdentifier) {
            fields.push({
                name: `id`,
                message: element?.deferredPaymentIdentifier,
            });
        }

        if (element?.categoryAndType) {
            fields.push({
                name: `Category/Type`,
                message: element?.categoryAndType,
            });
        }

        if (fields.length > 0) {
            errors.push({ name: `Deferred Payment ${index}`, fields });
        }
    });
};

const parseIdentificationWarehouse = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);

    if (goodsShipment?.warehouseIdentifier) {
        fields.push({ name: 'Warehouse Identifier', message: goodsShipment?.warehouseIdentifier });
    }

    if (goodsShipment?.warehouseType) {
        fields.push({ name: 'Warehouse Type', message: goodsShipment?.warehouseType });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Identification Warehouse', fields });
    }
};

const parseExporter = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const exporterValidation = validation?.exporter as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(exporterValidation);
    if (fields.length > 0) {
        errors.push({ name: 'Exporter', fields });
    }
};

const parseImporter = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    const importerValidation = goodsShipment?.importer as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(importerValidation);
    if (fields.length > 0) {
        errors.push({ name: 'Importer', fields });
    }
};

const parseDeclarant = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const declarantValidation = validation?.declarant as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(declarantValidation);
    if (fields.length > 0) {
        errors.push({ name: 'Declarant', fields });
    }
};

const parseRepresentative = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const declarantValidation = validation?.representative as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(declarantValidation);
    if (fields.length > 0) {
        errors.push({ name: 'Representative', fields });
    }
};

const parseSeller = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    const declarantValidation = goodsShipment?.seller as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(declarantValidation);
    if (fields.length > 0) {
        errors.push({ name: 'Seller', fields });
    }
};

const parseBuyer = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    const declarantValidation = goodsShipment?.buyer as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(declarantValidation);
    if (fields.length > 0) {
        errors.push({ name: 'Buyer', fields });
    }
};

const getGoodShipment = (validation: FormikErrors<CdsImportDeclaration>) => {
    return validation.goodsShipment as FormikErrors<GoodsShipment>;
};

const parseDeclarationCustomerErrors = (customer: FormikErrors<DeclarationCustomer> | undefined) => {
    if (!customer) return [];

    const fields: FieldError[] = [];

    if (customer?.eori) {
        fields.push({ name: 'Identification Number', message: customer?.eori });
    }

    if (customer?.name) {
        fields.push({ name: 'Name', message: customer?.name });
    }

    if (customer?.streetAndNumber) {
        fields.push({ name: 'Street And Number', message: customer?.streetAndNumber });
    }

    if (customer?.city) {
        fields.push({ name: 'City', message: customer?.city });
    }

    if (customer?.postCode) {
        fields.push({ name: 'Post Code', message: customer?.postCode });
    }

    if (customer?.country) {
        fields.push({ name: 'Country', message: customer?.country });
    }

    return fields;
};

const parseHolderOfTheAuthorisation = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const holderOfTheAuthorisations = validation?.holderOfTheAuthorisations as unknown as any[];

    if (!Array.isArray(holderOfTheAuthorisations)) return;

    holderOfTheAuthorisations?.forEach((element: FormikErrors<HolderOfTheAuthorisations>, index: number) => {
        const fields: FieldError[] = [];

        if (element?.holderOfTheAuthorisationIdentifier) {
            fields.push({
                name: `Identification number`,
                message: element?.holderOfTheAuthorisationIdentifier,
            });
        }

        if (element?.authorisationType) {
            fields.push({
                name: `Authorisation type`,
                message: element?.authorisationType,
            });
        }

        if (fields.length > 0) {
            errors.push({ name: `Holder of the authorisation identification No. ${index}`, fields });
        }
    });
};

const parseDeliveryTerms = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);

    if (goodsShipment?.deliveryIncoterm) {
        fields.push({ name: 'INCOTERM code', message: goodsShipment?.deliveryIncoterm });
    }

    if (goodsShipment?.deliveryUnlocode) {
        fields.push({ name: 'UN/LOCODE code', message: goodsShipment?.deliveryUnlocode });
    }

    if (goodsShipment?.deliveryCountryCode) {
        fields.push({ name: 'Country code', message: goodsShipment?.deliveryCountryCode });
    }

    if (goodsShipment?.deliveryLocationName) {
        fields.push({ name: 'Location name', message: goodsShipment?.deliveryLocationName });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Delivery Terms', fields });
    }
};

const parseTotalAmountInvoiced = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];

    if (validation?.totalAmountInvoiced) {
        fields.push({ name: 'Total Amount Invoiced', message: validation?.totalAmountInvoiced });
    }

    if (validation?.totalAmountCurrency) {
        fields.push({ name: 'Currency', message: validation?.totalAmountCurrency });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Total Amount Invoiced', fields });
    }
};

const parseExchangeRate = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];

    if (validation?.exchangeRate) {
        fields.push({ name: 'Exchange Rate', message: validation?.exchangeRate });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Exchange Rate', fields });
    }
};

const parseTransportCostsToFinalDestination = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);

    if (goodsShipment?.transportCostsToFinalDestAmount) {
        fields.push({ name: 'Amount', message: goodsShipment?.transportCostsToFinalDestAmount });
    }

    if (goodsShipment?.transportCostsToFinalDestCurrency) {
        fields.push({ name: 'Currency', message: goodsShipment?.transportCostsToFinalDestCurrency });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Transport costs to the final destination', fields });
    }
};

const parseCountryDestination = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);

    if (goodsShipment?.countryOfDestination) {
        fields.push({ name: 'Country of destination code', message: goodsShipment?.countryOfDestination });
    }

    if (goodsShipment?.countryOfDispatchExport) {
        fields.push({ name: 'Country of dispatch/export code', message: goodsShipment?.countryOfDispatchExport });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Country of Destination', fields });
    }
};

const parsePlaceOfloading = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);

    if (goodsShipment?.placeOfLoading) {
        fields.push({ name: 'Place of loading', message: goodsShipment?.placeOfLoading });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Place of Loading', fields });
    }
};

const parseLocationOfGoods = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);

    if (goodsShipment?.goodsLocationAddId) {
        fields.push({ name: 'Additional Identifier', message: goodsShipment?.goodsLocationAddId });
    }

    if (goodsShipment?.goodsLocationId) {
        fields.push({ name: 'Identification of location', message: goodsShipment?.goodsLocationId });
    }

    if (goodsShipment?.goodsLocationType) {
        fields.push({ name: 'Goods Location Type', message: goodsShipment?.goodsLocationType });
    }

    if (goodsShipment?.goodsLocationStreetNumber) {
        fields.push({ name: 'Street Number', message: goodsShipment?.goodsLocationStreetNumber });
    }

    if (goodsShipment?.goodsLocationCity) {
        fields.push({ name: 'City', message: goodsShipment?.goodsLocationCity });
    }

    if (goodsShipment?.goodsLocationPostcode) {
        fields.push({ name: 'Post Code', message: goodsShipment?.goodsLocationPostcode });
    }

    if (goodsShipment?.goodsLocationCountry) {
        fields.push({ name: 'Country', message: goodsShipment?.goodsLocationCountry });
    }

    if (goodsShipment?.goodsLocationQualifier) {
        fields.push({ name: 'Goods Location Qualifier', message: goodsShipment?.goodsLocationQualifier });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Location of Goods', fields });
    }
};

const parseCustomsOfficeOfPresentation = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];

    if (validation?.specialPersonPresentingGoodsToCustoms) {
        fields.push({
            name: 'Person Presenting the Goods to Customs',
            message: validation?.specialPersonPresentingGoodsToCustoms,
        });
    }

    if (validation?.customsOfficeOfPresentation) {
        fields.push({ name: 'Customs Office of Presentation', message: validation?.customsOfficeOfPresentation });
    }

    if (validation?.supervisingCustomsOffice) {
        fields.push({ name: 'Office Import/Export', message: validation?.supervisingCustomsOffice });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Customs Office of Presentation', fields });
    }
};

const parseGrossMass = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);

    if (validation?.totalGrossMass) {
        fields.push({ name: 'Gross Mass', message: validation?.totalGrossMass });
    }

    if (validation?.totalPackages) {
        fields.push({ name: 'Total packages', message: validation?.totalPackages });
    }

    if (goodsShipment?.container) {
        fields.push({ name: 'Container', message: goodsShipment?.container });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Gross Mass', fields });
    }
};

const parseModeOfTransportAtBorder = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);

    if (validation?.modeOfTransportAtTheBorder) {
        fields.push({ name: 'Mode of Transport At The Borde', message: validation?.modeOfTransportAtTheBorder });
    }

    if (goodsShipment?.inlandModeOfTransport) {
        fields.push({ name: 'Inland Mode of Transport', message: goodsShipment?.inlandModeOfTransport });
    }

    if (validation?.nationalityOfMeansOfTransportAtTheBorder) {
        fields.push({
            name: 'Nationality of active means of transport crossing the border',
            message: validation?.nationalityOfMeansOfTransportAtTheBorder,
        });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Mode of Transport At The Border', fields });
    }
};

const parseIdOfMeansTransportOnArrival = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];

    if (validation?.transportIdentityMeansType) {
        fields.push({ name: 'Type of Identification', message: validation?.transportIdentityMeansType });
    }

    if (validation?.transportIdentityMeansId) {
        fields.push({ name: 'Identification Number', message: validation?.transportIdentityMeansId });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Identity of means of transport on arrival', fields });
    }
};

const parseGuaranteeTypes = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const guaranteeTypes = validation?.guaranteeTypes as unknown as any[];
    guaranteeTypes?.forEach((element: FormikErrors<GuaranteeType>, index: number) => {
        const fields: FieldError[] = [];
        if (element?.guaranteeType) {
            fields.push({ name: 'Guarantee Type', message: element?.guaranteeType });
        }

        if (fields.length > 0) {
            errors.push({ name: `Guarantee Types ${index}`, fields });
        }
    });
};

const parseGuaranteeReference = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const guaranteeTypes = validation?.guaranteeTypes as unknown as any[];
    guaranteeTypes?.forEach((element: FormikErrors<GuaranteeReference>, index: number) => {
        const fields: FieldError[] = [];
        if (element?.grn) {
            fields.push({ name: 'GRN', message: element?.grn });
        }

        if (element?.otherGuaranteeReference) {
            fields.push({ name: 'Other guarantee reference', message: element?.otherGuaranteeReference });
        }

        if (element?.accessCode) {
            fields.push({ name: 'Access Code', message: element?.accessCode });
        }

        if (element?.amountOfImportDuty) {
            fields.push({ name: 'Amount of import duty', message: element?.amountOfImportDuty });
        }

        if (element?.currencyCode) {
            fields.push({ name: 'Currency', message: element?.currencyCode });
        }

        if (element?.customsOfficeOfGuarantee) {
            fields.push({ name: 'Guarantee customs office', message: element?.customsOfficeOfGuarantee });
        }

        if (fields.length > 0) {
            errors.push({ name: `Guarantee Reference ${index}`, fields });
        }
    });
};

const parseNatureOfTransation = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);

    if (goodsShipment?.natureOfTransaction) {
        fields.push({ name: 'Nature of Transaction', message: goodsShipment?.natureOfTransaction });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Nature of Transaction', fields });
    }
};

const parseAcceptanceDate = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const fields: FieldError[] = [];

    if (validation?.acceptanceDate) {
        fields.push({ name: 'Acceptance Date', message: validation?.acceptanceDate });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Acceptance Date', fields });
    }
};

const parseAdditionalConditionalInformation = (errors: CardError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const supplyChainActor = (getGoodShipment(validation)?.additionalSupplyChainActors as unknown as any[]) || [];
    supplyChainActor.forEach((supplyChainActor: FormikErrors<AdditionalSupplyChainActor>, index) => {
        const fields: FieldError[] = [];

        if (supplyChainActor?.additionalSupplyActorIdentifier) {
            fields.push({ name: `Identification Number`, message: supplyChainActor.additionalSupplyActorIdentifier });
        }

        if (supplyChainActor?.roleCode) {
            fields.push({ name: `Role code`, message: supplyChainActor.roleCode });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Conditional Information - Supply Chain Actor ${index + 1}`, fields });
        }
    });

    const additionsAndDeductions = (getGoodShipment(validation)?.additionDeductions as unknown as any[]) || [];
    additionsAndDeductions.forEach((additionsAndDeductions: FormikErrors<AdditionDeduction>, index) => {
        const fields: FieldError[] = [];

        if (additionsAndDeductions?.additionsDeductionsValue) {
            fields.push({ name: `Value`, message: additionsAndDeductions.additionsDeductionsValue });
        }

        if (additionsAndDeductions?.additionsDeductionsCurrency) {
            fields.push({ name: `Currency`, message: additionsAndDeductions.additionsDeductionsCurrency });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Conditional Information - Additions and Deductions ${index + 1}`, fields });
        }
    });

    const fiscalReferences =
        (getGoodShipment(validation)?.additionalFiscalReferenceIdentifications as unknown as any[]) || [];
    fiscalReferences.forEach((fiscalReference: FormikErrors<AdditionalFiscalReferenceIdentification>, index) => {
        const fields: FieldError[] = [];

        if (fiscalReference?.additionalFiscalReferenceRole) {
            fields.push({ name: `Reference VAT`, message: fiscalReference.additionalFiscalReferenceRole });
        }

        if (fiscalReference?.additionalFiscalReferenceIdentifier) {
            fields.push({ name: `Reference Id`, message: fiscalReference.additionalFiscalReferenceIdentifier });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Conditional Information - Fiscal Reference ${index + 1}`, fields });
        }
    });

    const containerIdentificationNumbers =
        (getGoodShipment(validation)?.containerIdentificationNumbers as unknown as any[]) || [];
    const fields: FieldError[] = [];
    containerIdentificationNumbers.forEach((container: FormikErrors<string>, index) => {
        fields.push({ name: `Container Identification Number ${index}`, message: container });
    });
    if (fields.length > 0) {
        errors.push({ name: `Additional Conditional Information - Container Identification Numbers`, fields });
    }
};

const parseDeclarationItems = (errors: ItemsFieldError[], validation: FormikErrors<CdsImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    if (!goodsShipment) {
        return;
    }

    const items = (goodsShipment?.governmentAgencyGoodsItems as unknown as any[]) || [];

    items.forEach((itemErrors: FormikErrors<GovernmentAgencyGoodsItem>, index) => {
        const item = {
            index: index + 1,
            errors: [],
        };

        parseItemsGoodsInformation(item.errors, itemErrors);
        parseItemProcedure(item.errors, itemErrors);
        parseItemAdditionalProcedure(item.errors, itemErrors);
        parseItemPreviousDocument(item.errors, itemErrors);
        parseItemAdditionalInformation(item.errors, itemErrors);
        parseItemDocumentProduced(item.errors, itemErrors);
        parseItemImport(item.errors, itemErrors);
        parseItemSeller(item.errors, itemErrors);
        parseItemBuyer(item.errors, itemErrors);
        parseItemPackagings(item.errors, itemErrors);
        parseItemAdditionalTaricCode(item.errors, itemErrors);
        parseItemAdditionalNationalCode(item.errors, itemErrors);
        parseItemAdditionalConditionalInformation(item.errors, itemErrors);

        if (item.errors.length > 0) {
            errors.push(item);
        }
    });
};

export const parseUkH1ProductTemplate = (validation: FormikErrors<GoodsShipment>) => {
    const productTemplateErrors: CardError[] = [];

    parseItemsGoodsInformation(productTemplateErrors, validation);
    parseItemProcedure(productTemplateErrors, validation);
    parseItemAdditionalProcedure(productTemplateErrors, validation);
    parseItemPreviousDocument(productTemplateErrors, validation);
    parseItemAdditionalInformation(productTemplateErrors, validation);
    parseItemDocumentProduced(productTemplateErrors, validation);
    parseItemImport(productTemplateErrors, validation);
    parseItemSeller(productTemplateErrors, validation);
    parseItemBuyer(productTemplateErrors, validation);
    parseItemPackagings(productTemplateErrors, validation);
    parseItemAdditionalTaricCode(productTemplateErrors, validation);
    parseItemAdditionalNationalCode(productTemplateErrors, validation);
    parseItemAdditionalConditionalInformation(productTemplateErrors, validation);

    return { template: productTemplateErrors };
};

const parseItemsGoodsInformation = (errors: CardError[], validation: FormikErrors<GovernmentAgencyGoodsItem>) => {
    const fields: FieldError[] = [];
    if (validation?.taricCodeIdentifier) {
        fields.push({ name: `Taric Code Identifier`, message: validation.taricCodeIdentifier });
    }

    if (validation?.goodsItemNumber) {
        fields.push({ name: `Goods Item Number`, message: validation.goodsItemNumber });
    }

    if (validation?.ucr) {
        fields.push({ name: `UCR`, message: validation.ucr });
    }

    if (validation?.valuationIndicator1) {
        fields.push({ name: `valuationIndicator1`, message: validation.valuationIndicator1 });
    }

    if (validation?.valuationIndicator2) {
        fields.push({ name: `valuationIndicator2`, message: validation.valuationIndicator2 });
    }

    if (validation?.valuationIndicator3) {
        fields.push({ name: `valuationIndicator3`, message: validation.valuationIndicator3 });
    }

    if (validation?.valuationIndicator4) {
        fields.push({ name: `valuationIndicator4`, message: validation.valuationIndicator4 });
    }

    if (validation?.valuationMethod) {
        fields.push({ name: `Valuation Method`, message: validation.valuationMethod });
    }

    if (validation?.itemPriceAmount) {
        fields.push({ name: `Item Price - Amount`, message: validation.itemPriceAmount });
    }

    if (validation?.itemPriceCurrency) {
        fields.push({ name: `Item Price - Currency`, message: validation.itemPriceCurrency });
    }

    if (validation?.countryOfDestination) {
        fields.push({ name: `Country of Destination`, message: validation.countryOfDestination });
    }

    if (validation?.countryOfDispatchExport) {
        fields.push({ name: `Country of Dispatch Export`, message: validation.countryOfDispatchExport });
    }

    if (validation?.countryOfOrigin) {
        fields.push({ name: `Country of Origin`, message: validation.countryOfOrigin });
    }

    if (validation?.countryOfOriginRole) {
        fields.push({ name: `Country of Origin - Type Code`, message: validation.countryOfOriginRole });
    }

    if (validation?.countryOfPreferentialOrigin) {
        fields.push({ name: `Country of Preferential Origin`, message: validation.countryOfPreferentialOrigin });
    }

    if (validation?.countryOfPreferentialOriginRole) {
        fields.push({
            name: `Country of Preferential Origin - Type Code`,
            message: validation.countryOfPreferentialOriginRole,
        });
    }

    if (validation?.goodsNetMass) {
        fields.push({ name: `Net Mass`, message: validation.goodsNetMass });
    }

    if (validation?.goodsSupplementaryUnits) {
        fields.push({ name: `Supplementary Units`, message: validation.goodsSupplementaryUnits });
    }

    if (validation?.goodsGrossMass) {
        fields.push({ name: `Gross Mass`, message: validation.goodsGrossMass });
    }

    if (validation?.goodsDescription) {
        fields.push({ name: `Description of Goods`, message: validation.goodsDescription });
    }

    if (validation?.commodityCusCodeIdentifier) {
        fields.push({ name: `CUS Code`, message: validation.commodityCusCodeIdentifier });
    }

    if (validation?.commodityCusTypeCode) {
        fields.push({ name: `CUS Code - Type Code`, message: validation.commodityCusTypeCode });
    }

    if (validation?.commodityCombinedNomenclatureCodeIdentifier) {
        fields.push({
            name: `Commodity Code - Combined Nomenclature code`,
            message: validation.commodityCombinedNomenclatureCodeIdentifier,
        });
    }

    if (validation?.commodityCombinedNomenclatureTypeCode) {
        fields.push({
            name: `Commodity Code - Combined Nomenclature code - Type Code`,
            message: validation.commodityCombinedNomenclatureTypeCode,
        });
    }

    if (validation?.taricTypeCode) {
        fields.push({ name: `Taric Code - Type Code`, message: validation.taricTypeCode });
    }

    if (validation?.quotaOrderNumber) {
        fields.push({ name: `Quota Order Number`, message: validation.quotaOrderNumber });
    }

    if (validation?.natureOfTransaction) {
        fields.push({ name: `Nature of Transaction`, message: validation.natureOfTransaction });
    }

    if (validation?.statisticalValue) {
        fields.push({ name: `Statistical Value`, message: validation.statisticalValue });
    }

    if (validation?.statisticalValueCurrency) {
        fields.push({ name: `Statistical Value - Currency`, message: validation.statisticalValueCurrency });
    }

    if (validation?.vatRate) {
        fields.push({ name: `Vat Rate`, message: validation.vatRate });
    }

    if (validation?.dutyRate) {
        fields.push({ name: `Duty Rate`, message: validation.dutyRate });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Goods Information', fields });
    }
};

const parseItemProcedure = (errors: CardError[], validation: FormikErrors<GovernmentAgencyGoodsItem>) => {
    const fields: FieldError[] = [];

    if (validation?.requestedProcedure) {
        fields.push({ name: `Procedure Code`, message: validation.requestedProcedure });
    }

    if (validation?.previousProcedure) {
        fields.push({ name: `Previous Procedure Code`, message: validation.previousProcedure });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Procedure', fields });
    }
};

const parseItemAdditionalProcedure = (errors: CardError[], validation: FormikErrors<GovernmentAgencyGoodsItem>) => {
    const addProcedures = (validation?.additionalProcedures as unknown as any[]) || [];
    addProcedures.forEach((addProcedures: FormikErrors<AdditionalProcedure>, index) => {
        const fields: FieldError[] = [];

        if (addProcedures?.additionalProcedure) {
            fields.push({ name: `Additional Procedure Code`, message: addProcedures.additionalProcedure });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Procedure Code ${index + 1}`, fields });
        }
    });
};

const parseItemPreviousDocument = (errors: CardError[], validation: FormikErrors<GovernmentAgencyGoodsItem>) => {
    const previousDocuments = (validation?.additionalProcedures as unknown as any[]) || [];
    previousDocuments.forEach((document: FormikErrors<PreviousDocument>, index) => {
        const fields: FieldError[] = [];

        if (document?.previousDocumentCategory) {
            fields.push({ name: `Previous Document Category`, message: document.previousDocumentCategory });
        }

        if (document?.previousDocumentType) {
            fields.push({ name: `Previous Document Type`, message: document.previousDocumentType });
        }

        if (document?.previousDocumentReference) {
            fields.push({ name: `Previous Document Reference`, message: document.previousDocumentReference });
        }

        if (document?.goodsItemIdentifier) {
            fields.push({ name: `Goods Item Identifier`, message: document.goodsItemIdentifier });
        }

        if (fields.length > 0) {
            errors.push({ name: `Previous document ${index + 1}`, fields });
        }
    });
};

const parseItemAdditionalInformation = (errors: CardError[], validation: FormikErrors<GovernmentAgencyGoodsItem>) => {
    const addInformations = (validation?.additionalProcedures as unknown as any[]) || [];
    addInformations.forEach((information: FormikErrors<AdditionalInformation>, index) => {
        const fields: FieldError[] = [];

        if (information?.additionalInformationCode) {
            fields.push({ name: `Code`, message: information.additionalInformationCode });
        }

        if (information?.additionalInformationText) {
            fields.push({ name: `Text`, message: information.additionalInformationText });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Information ${index + 1}`, fields });
        }
    });
};

const parseItemDocumentProduced = (errors: CardError[], validation: FormikErrors<GovernmentAgencyGoodsItem>) => {
    const documents = (validation?.additionalProcedures as unknown as any[]) || [];
    documents.forEach((document: FormikErrors<DocumentProduced>, index) => {
        const fields: FieldError[] = [];

        if (document?.documentCode) {
            fields.push({ name: `Document Code`, message: document.documentCode });
        }

        if (document?.documentCode2) {
            fields.push({ name: `Document Code 2`, message: document.documentCode2 });
        }

        if (document?.documentId) {
            fields.push({ name: `Document Identifier`, message: document.documentId });
        }

        if (document?.status) {
            fields.push({ name: `Status`, message: document.status });
        }

        if (document?.statusReason) {
            fields.push({ name: `Document Status Reason`, message: document.statusReason });
        }

        if (document?.writingOffIssuingAuthority) {
            fields.push({ name: `Writing Off - Issuing Authority`, message: document.writingOffIssuingAuthority });
        }

        if (document?.writingOffDateOfValidity) {
            fields.push({ name: `Writing Off - Date Of Validity`, message: document.writingOffDateOfValidity });
        }

        if (document?.writingOffQuantity) {
            fields.push({ name: `Writing Off - Quantity`, message: document.writingOffQuantity });
        }

        if (document?.writingOffMeasurementUnit) {
            fields.push({ name: `Writing Off - Measurement Unit`, message: document.writingOffMeasurementUnit });
        }

        if (document?.writingOffMeasureUnitQualifiers) {
            fields.push({
                name: `Writing Off - Measurement Unit & Qualifier`,
                message: document.writingOffMeasureUnitQualifiers,
            });
        }

        if (fields.length > 0) {
            errors.push({ name: `Document Produced ${index + 1}`, fields });
        }
    });
};

const parseItemImport = (errors: CardError[], validation: FormikErrors<GovernmentAgencyGoodsItem>) => {
    const importerValidation = validation?.importer as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(importerValidation);
    if (fields.length > 0) {
        errors.push({ name: 'Importer', fields });
    }
};

const parseItemSeller = (errors: CardError[], validation: FormikErrors<GovernmentAgencyGoodsItem>) => {
    const importerValidation = validation?.seller as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(importerValidation);
    if (fields.length > 0) {
        errors.push({ name: 'Seller', fields });
    }
};

const parseItemBuyer = (errors: CardError[], validation: FormikErrors<GovernmentAgencyGoodsItem>) => {
    const importerValidation = validation?.buyer as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(importerValidation);
    if (fields.length > 0) {
        errors.push({ name: 'Buyer', fields });
    }
};

const parseItemPackagings = (errors: CardError[], validation: FormikErrors<GovernmentAgencyGoodsItem>) => {
    const packagings = (validation?.packagings as unknown as any[]) || [];
    packagings.forEach((pack: FormikErrors<Packaging>, index) => {
        const fields: FieldError[] = [];

        if (pack?.packageType) {
            fields.push({ name: `Package Type Code`, message: pack.packageType });
        }

        if (pack?.numberOfPackages) {
            fields.push({ name: `Number of Packages`, message: pack.numberOfPackages });
        }

        if (pack?.shippingMarks) {
            fields.push({ name: `Shipping Marks`, message: pack.shippingMarks });
        }

        if (fields.length > 0) {
            errors.push({ name: `Packagings ${index + 1}`, fields });
        }
    });
};

const parseItemAdditionalTaricCode = (errors: CardError[], validation: FormikErrors<GovernmentAgencyGoodsItem>) => {
    const additionalTaricCodes = (validation?.additionalTaricCodes as unknown as any[]) || [];
    additionalTaricCodes.forEach((taricCode: FormikErrors<AdditionalTaricCode>, index) => {
        const fields: FieldError[] = [];

        if (taricCode?.additionalTaricCodeIdentifier) {
            fields.push({ name: `Additional Taric Code Identifier`, message: taricCode.additionalTaricCodeIdentifier });
        }

        if (taricCode?.typeCode) {
            fields.push({ name: `Type Code`, message: taricCode.typeCode });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Taric Code ${index + 1}`, fields });
        }
    });
};

const parseItemAdditionalNationalCode = (errors: CardError[], validation: FormikErrors<GovernmentAgencyGoodsItem>) => {
    const nationalCodes = (validation?.additionalTaricCodes as unknown as any[]) || [];
    nationalCodes.forEach((nationalCode: FormikErrors<AdditionalNationalCode>, index) => {
        const fields: FieldError[] = [];

        if (nationalCode?.additionalNationalCodeIdentifier) {
            fields.push({
                name: `Additional National Code Identifier`,
                message: nationalCode.additionalNationalCodeIdentifier,
            });
        }

        if (nationalCode?.typeCode) {
            fields.push({ name: `Type Code`, message: nationalCode.typeCode });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional National Code ${index + 1}`, fields });
        }
    });
};

const parseItemAdditionalConditionalInformation = (
    errors: CardError[],
    validation: FormikErrors<GovernmentAgencyGoodsItem>
) => {
    const supplyChainActor = (validation?.additionalSupplyChainActors as unknown as any[]) || [];
    supplyChainActor.forEach((supplyChainActor: FormikErrors<AdditionalSupplyChainActor>, index) => {
        const fields: FieldError[] = [];

        if (supplyChainActor?.additionalSupplyActorIdentifier) {
            fields.push({ name: `Identification Number`, message: supplyChainActor.additionalSupplyActorIdentifier });
        }

        if (supplyChainActor?.roleCode) {
            fields.push({ name: `Role code`, message: supplyChainActor.roleCode });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Conditional Information - Supply Chain Actor ${index + 1}`, fields });
        }
    });

    const additionsAndDeductions = (validation?.additionDeductions as unknown as any[]) || [];
    additionsAndDeductions.forEach((additionsAndDeductions: FormikErrors<AdditionDeduction>, index) => {
        const fields: FieldError[] = [];

        if (additionsAndDeductions?.additionsDeductionsValue) {
            fields.push({ name: `Value`, message: additionsAndDeductions.additionsDeductionsValue });
        }

        if (additionsAndDeductions?.additionsDeductionsCurrency) {
            fields.push({ name: `Currency`, message: additionsAndDeductions.additionsDeductionsCurrency });
        }

        if (additionsAndDeductions?.type) {
            fields.push({ name: `Type`, message: additionsAndDeductions.type });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Conditional Information - Additions and Deductions ${index + 1}`, fields });
        }
    });

    const fiscalReferences = (validation?.additionalFiscalReferenceIdentifications as unknown as any[]) || [];
    fiscalReferences.forEach((fiscalReference: FormikErrors<AdditionalFiscalReferenceIdentification>, index) => {
        const fields: FieldError[] = [];

        if (fiscalReference?.additionalFiscalReferenceRole) {
            fields.push({ name: `Reference VAT`, message: fiscalReference.additionalFiscalReferenceRole });
        }

        if (fiscalReference?.additionalFiscalReferenceIdentifier) {
            fields.push({ name: `Reference Id`, message: fiscalReference.additionalFiscalReferenceIdentifier });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Conditional Information - Fiscal Reference ${index + 1}`, fields });
        }
    });

    const calculationOfTaxes = (validation?.calculationOfTaxes as unknown as any[]) || [];
    calculationOfTaxes.forEach((taxes: FormikErrors<Tax>, index) => {
        if (taxes?.taxType) {
            fields.push({ name: `Tax Type`, message: taxes.taxType });
        }

        if (taxes?.taxBaseQuantity) {
            fields.push({ name: `Tax Base - Quantity`, message: taxes.taxBaseQuantity });
        }

        if (taxes?.taxRate) {
            fields.push({ name: `Tax Rate`, message: taxes.taxRate });
        }

        if (taxes?.types) {
            fields.push({ name: `Types`, message: taxes.types });
        }

        if (taxes?.qualifiers) {
            fields.push({ name: `Tax Base - Unit and Qualifier`, message: taxes.qualifiers });
        }

        if (taxes?.payableTaxAmount) {
            fields.push({ name: `Payable tax amount`, message: taxes.payableTaxAmount });
        }

        if (taxes?.payableTaxAmountCurrency) {
            fields.push({ name: `Payable Tax Amount - Currency`, message: taxes.payableTaxAmountCurrency });
        }

        if (taxes?.taxTotal) {
            fields.push({ name: `Calculation of Taxes - Total`, message: taxes.taxTotal });
        }

        if (taxes?.taxTotalCurrency) {
            fields.push({ name: `Currency`, message: taxes.taxTotalCurrency });
        }

        if (taxes?.method) {
            fields.push({ name: `Method of Payment`, message: taxes.method });
        }

        if (taxes?.preference) {
            fields.push({ name: `Preference Code`, message: taxes.preference });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional Conditional Information - Calculation of Taxes ${index + 1}`, fields });
        }
    });

    const containerIdentificationNumbers = (validation?.containerIdentificationNumbers as unknown as any[]) || [];
    const fields: FieldError[] = [];
    containerIdentificationNumbers.forEach((container: FormikErrors<ContainerIdentificationNumber>, index) => {
        if (container?.containerIdentificationNumber) {
            fields.push({
                name: `Container Identification Number ${index}`,
                message: container.containerIdentificationNumber,
            });
        }
    });
    if (fields.length > 0) {
        errors.push({ name: `Additional Conditional Information - Container Identification Numbers`, fields });
    }
};
