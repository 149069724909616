import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import SupervisingCustomsOfficeBlock, {
    supervisingCustomsOfficeBlockValidation,
} from '../blocks/SupervisingCustomsOfficeBlock';

export const supervisingCustomsOfficeCardValidation = {
    childValidators: {
        supervisingOffice: supervisingCustomsOfficeBlockValidation,
    },
};

interface Props extends DeclarationFormCardProps {
    fieldOptions?: FieldOptions<'id'>;
}

const SupervisingCustomsOfficeCard: FC<Props> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('suppervisingCustomsOffice')}>
            <FormCardContainer>
                <SupervisingCustomsOfficeBlock
                    fieldOptions={{
                        id: { required: props.fieldOptions?.id?.required },
                    }}
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default SupervisingCustomsOfficeCard;
