import { FunctionComponent, useState } from 'react';
import { FormikProps } from 'formik';

interface DeclarationErrorsProps<
    TErrors extends any[] | undefined,
    TAdditionalInformation extends any[] | undefined,
    TNotifications extends any[] | undefined
> {
    errors: TErrors;
    formik?: FormikProps<any>;
    DeclarationErrorsComponent: FunctionComponent<
        SpecificDeclarationErrorsProps<TErrors, TAdditionalInformation, TNotifications>
    >;
    isDetailed?: boolean;
    onCompact?: () => void;
    onDetailed?: () => void;
    onClose?: () => void;
    additionalInformation?: TAdditionalInformation;
    notifications?: TNotifications;
}

const DeclarationErrorsWrapper = <
    TErrors extends any[] | undefined,
    TAdditionalInformation extends any[] | undefined,
    TNotifications extends any[] | undefined
>({
    errors,
    formik,
    DeclarationErrorsComponent,
    isDetailed = undefined,
    onCompact = () => {},
    onDetailed = () => {},
    onClose = () => {},
    additionalInformation,
    notifications,
}: DeclarationErrorsProps<TErrors, TAdditionalInformation, TNotifications>) => {
    const [selected, setSelected] = useState<NonNullable<TErrors>[number] | undefined>(undefined);

    const handleSelected = (selectedError: NonNullable<TErrors>[number] | undefined) => {
        setSelected(selectedError);
        selectedError ? onDetailed() : onCompact();
    };

    return (
        <DeclarationErrorsComponent
            errors={errors}
            notifications={notifications}
            additionalInformation={additionalInformation}
            formik={formik}
            onClose={onClose}
            selected={selected}
            handleSelected={handleSelected}
            isDetailed={isDetailed}
        />
    );
};

export default DeclarationErrorsWrapper;
