export enum CommodityClassificationTypeCode {
    CV = 'CV',
    GN = 'GN',
    SSO = 'SSO',
    TRA = 'TRA',
    TRC = 'TRC',
    TSP = 'TSP',
}

export const commodityClassificationTypeCodeLabels = [
    { id: CommodityClassificationTypeCode.CV, value: 'CV' },
    { id: CommodityClassificationTypeCode.GN, value: 'GN' },
    { id: CommodityClassificationTypeCode.SSO, value: 'SSO' },
    { id: CommodityClassificationTypeCode.TRA, value: 'TRA' },
    { id: CommodityClassificationTypeCode.TRC, value: 'TRC' },
    { id: CommodityClassificationTypeCode.TSP, value: 'TSP' },
] as const;

export const commodityClassificationTypeCodeLabelsCdsExport = [
    { id: CommodityClassificationTypeCode.SSO, value: 'SSO' },
    { id: CommodityClassificationTypeCode.TRA, value: 'TRA' },
    { id: CommodityClassificationTypeCode.TRC, value: 'TRC' },
    { id: CommodityClassificationTypeCode.TSP, value: 'TSP' },
] as const;
