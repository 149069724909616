import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';
import IdentityOfActiveMeansOfTransportCrossingTheBorderBlock, {
    identityOfActiveMeansOfTransportCrossingTheBorderBlockValidation,
} from '../blocks/IdentityOfActiveMeansOfTransportCrossingTheBorderBlock';

export const identityOfActiveMeansOfTransportCrossingTheBorderCardValidation = {
    selfValidators: [identityOfActiveMeansOfTransportCrossingTheBorderBlockValidation],
};

const IdentityOfActiveMeansOfTransportCrossingTheBorderCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');

    return (
        <NewFormCard title={t('identityOfActiveMeansOfTransportCrossingTheBorder')}>
            <FormCardContainer>
                <IdentityOfActiveMeansOfTransportCrossingTheBorderBlock />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default IdentityOfActiveMeansOfTransportCrossingTheBorderCard;
