import { deleteFileCms, listDocuments } from './client';
import { DocumentsFilterFields } from './document';

export const doListDocuments: Function = (params: Partial<DocumentsFilterFields>) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_DOCUMENTS_REQUEST' });

    try {
        const payload = await listDocuments(params);
        dispatch({
            type: 'LIST_DOCUMENTS_SUCCESS',
            payload,
        });

        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_DOCUMENTS_ERROR', error: e?.response?.data });
    }
};

export const doDeleteDocument: Function = (id: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_DOCUMENT_REQUEST' });

    try {
        const payload = await deleteFileCms(id);
        dispatch({
            type: 'DELETE_DOCUMENT_SUCCESS',
            payload,
        });

        return payload;
    } catch (e: any) {
        dispatch({ type: 'DELETE_DOCUMENT_ERROR', error: e?.response?.data });
    }
};
