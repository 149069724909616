import { FormikErrors } from 'formik';
import { get } from 'lodash';
import { CardError, FieldError, ItemsFieldError } from 'store/declaration-form-errors/DeclarationFormErrors';
import { DeclarationAddress, DeclarationCustomer } from 'store/declarations/common/declaration-customer';
import {
    AdditionalFiscalReference,
    AdditionalInformation,
    AdditionalProcedure,
    DocumentsAuth,
    GoodsShipment,
    GoodsShipmentItem,
    IrelandH7ImportDeclaration,
    Payment,
    PreviousDocument,
    SupportingDocuments,
    TransportDocument,
    ValuationInformation,
} from 'store/declarations/ireland/h7-import-declaration';
import { parseAnyValidationError } from 'utils/validationParserUtils';
import { addToParties, parseDeclarationCustomerErrors } from '../../h1/validation/utils';

export const parseValidationErrors = (validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const masterDetailsErros: CardError[] = [];
    parseMasterdetails(masterDetailsErros, validation);
    parseExporter(masterDetailsErros, validation);
    parseImporter(masterDetailsErros, validation);
    parseDeclarant(masterDetailsErros, validation);
    parseDocumentsAdditionalInformation(masterDetailsErros, validation);
    parsePreviousDocument(masterDetailsErros, validation);
    parseTransportDocument(masterDetailsErros, validation);
    parseTransportCosts(masterDetailsErros, validation);
    parseLocationOfGoods(masterDetailsErros, validation);
    const itemsErrors: ItemsFieldError[] = [];
    parseDeclarationItems(itemsErrors, validation);
    const errors = {
        masterDetails: masterDetailsErros,
        items: itemsErrors,
    };
    return errors;
};

const parseMasterdetails = (errors: CardError[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const fields: FieldError[] = [];

    if (validation?.additionalDeclarationType) {
        fields.push({ name: 'Additional Declaration Type', message: validation?.additionalDeclarationType });
    }

    if (validation?.customsOfficeLodgement) {
        fields.push({ name: 'Customs Office of Lodgement', message: validation?.customsOfficeLodgement });
    }

    if (validation?.deferredPayment) {
        fields.push({ name: 'Deferred Payment', message: validation?.deferredPayment });
    }

    if (validation?.preferredPaymentMethod) {
        fields.push({ name: 'Preferred Payment Method', message: validation?.preferredPaymentMethod });
    }

    if (validation?.representativeIdentificationNumber) {
        fields.push({
            name: 'Representative Identification Number',
            message: validation?.representativeIdentificationNumber,
        });
    }

    if (validation?.representativeStatus) {
        fields.push({ name: 'Representative Status', message: validation?.representativeStatus });
    }

    if (validation?.numberOfItems) {
        fields.push({ name: 'Number of items', message: validation?.numberOfItems });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Master Details', fields });
    }
};

const getGoodShipment = (validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    return validation.goodsShipment as FormikErrors<GoodsShipment>;
};

const parseExporter = (errors: CardError[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const goodShipment = getGoodShipment(validation);
    const exporterValidation = goodShipment?.exporter as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(exporterValidation, 'Exporter');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseImporter = (errors: CardError[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const goodShipment = getGoodShipment(validation);
    const importerValidation = goodShipment?.importer as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(importerValidation, 'Importer');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseDeclarant = (errors: CardError[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const declarantValidation = validation?.declarant as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(declarantValidation, 'Declarant');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseDocumentsAdditionalInformation = (
    errors: CardError[],
    validation: FormikErrors<IrelandH7ImportDeclaration>
) => {
    const goodsShipment = getGoodShipment(validation);
    const documentsAuthorisations = goodsShipment?.documentsAuthorisations as FormikErrors<DocumentsAuth>;
    const docuemnts = (documentsAuthorisations?.additionalInformation as unknown as any[]) || [];

    docuemnts.forEach((document: FormikErrors<AdditionalInformation>, index) => {
        const fields: FieldError[] = [];

        if (document?.additionalInformationCode) {
            fields.push({ name: `Code`, message: document.additionalInformationCode });
        }

        if (document?.additionalInformationText) {
            fields.push({ name: `Text`, message: document.additionalInformationText });
        }

        if (fields.length > 0) {
            errors.push({ name: `Documents Additional Information ${index + 1}`, fields });
        }
    });
};

const parsePreviousDocument = (errors: CardError[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    const documentsAuthorisations = goodsShipment?.documentsAuthorisations as FormikErrors<DocumentsAuth>;
    const docuemnts = (documentsAuthorisations?.previousDocument as unknown as any[]) || [];

    docuemnts.forEach((document: FormikErrors<PreviousDocument>, index) => {
        const fields: FieldError[] = [];

        if (document?.previousDocumentReferenceNumber) {
            fields.push({ name: `Reference Number`, message: document.previousDocumentReferenceNumber });
        }

        if (document?.previousDocumentType) {
            fields.push({ name: `Document Type`, message: document.previousDocumentType });
        }

        if (fields.length > 0) {
            errors.push({ name: `Previous Document ${index + 1}`, fields });
        }
    });
};

const parseTransportDocument = (errors: CardError[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    const valuationInformation = goodsShipment?.valuationInformation as FormikErrors<ValuationInformation>;
    const docuemnts = (valuationInformation?.transportDocument as unknown as any[]) || [];

    docuemnts.forEach((document: FormikErrors<TransportDocument>, index) => {
        const fields: FieldError[] = [];

        if (document?.transportDocumentReferenceNumber) {
            fields.push({ name: `Reference Number`, message: document.transportDocumentReferenceNumber });
        }

        if (document?.transportDocumentType) {
            fields.push({ name: `Type`, message: document.transportDocumentType });
        }

        if (fields.length > 0) {
            errors.push({ name: `Transport Document ${index + 1}`, fields });
        }
    });
};

const parseTransportCosts = (errors: CardError[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);
    const valuationInformation = goodsShipment?.valuationInformation as FormikErrors<ValuationInformation>;

    if (valuationInformation?.transportAndInsuranceCostsToTheDestinationAmount) {
        fields.push({
            name: 'Transport And Insurance Costs to the Destination Amount',
            message: valuationInformation?.transportAndInsuranceCostsToTheDestinationAmount,
        });
    }

    if (valuationInformation?.transportAndInsuranceCostsToTheDestinationCurrency) {
        fields.push({
            name: 'Transport And Insurance Costs to the Destination Currency',
            message: valuationInformation?.transportAndInsuranceCostsToTheDestinationCurrency,
        });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Transport Costs', fields });
    }
};

const parseLocationOfGoods = (errors: CardError[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const fields: FieldError[] = [];
    const goodsShipment = getGoodShipment(validation);
    const locationOfGoodsAddress = goodsShipment?.locationOfGoodsAddress as FormikErrors<DeclarationAddress>;

    if (goodsShipment?.locationOfGoodsUnlocode) {
        fields.push({ name: 'Unlocode', message: goodsShipment?.locationOfGoodsUnlocode });
    }

    if (goodsShipment?.locationOfGoodsAdditionalIdentifier) {
        fields.push({ name: 'Additional Identifier', message: goodsShipment?.locationOfGoodsAdditionalIdentifier });
    }

    if (goodsShipment?.locationOfGoodsTypeOfLocation) {
        fields.push({ name: 'Type of Location code', message: goodsShipment?.locationOfGoodsTypeOfLocation });
    }

    if (goodsShipment?.locationOfGoodsQualifierOfIdentification) {
        fields.push({
            name: 'Qualifier of the identification',
            message: goodsShipment?.locationOfGoodsQualifierOfIdentification,
        });
    }

    if (locationOfGoodsAddress?.streetAndNumber) {
        fields.push({ name: 'Street Number', message: locationOfGoodsAddress?.streetAndNumber });
    }

    if (locationOfGoodsAddress?.city) {
        fields.push({ name: 'City', message: locationOfGoodsAddress?.city });
    }

    if (locationOfGoodsAddress?.postCode) {
        fields.push({ name: 'Post Code', message: locationOfGoodsAddress?.postCode });
    }

    if (locationOfGoodsAddress?.country) {
        fields.push({ name: 'Country', message: locationOfGoodsAddress?.country });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Location Of Goods', fields });
    }
};

const parseDeclarationItems = (errors: ItemsFieldError[], validation: FormikErrors<IrelandH7ImportDeclaration>) => {
    const goodsShipment = getGoodShipment(validation);
    if (!goodsShipment) {
        return;
    }

    const items = (goodsShipment?.governmentAgencyGoodsItem as unknown as any[]) || [];

    items.forEach((itemErrors: FormikErrors<GoodsShipmentItem> | undefined, index) => {
        if (itemErrors === undefined) return;
        const item = {
            index: index + 1,
            errors: [],
        };

        parseItemsGoodsInformation(item.errors, itemErrors);
        parseAdditionalProcedureCode(item.errors, itemErrors);
        parseItemBox44(item.errors, itemErrors);
        parseItemsAdditionalInformation(item.errors, itemErrors);
        parseItemPreviousDocument(item.errors, itemErrors);
        parseItemValuationInformation(item.errors, itemErrors);
        parseItemTransportDocuments(item.errors, itemErrors);
        parseItemExporter(item.errors, itemErrors);
        parseItemFiscalReferences(item.errors, itemErrors);
        parseItemDutiesAndTaxes(item.errors, itemErrors);
        if (item.errors.length > 0) {
            errors.push(item);
        }
    });
};

export const parseH7ProductTemplate = (validation: FormikErrors<GoodsShipment>) => {
    const productTemplateErrors: CardError[] = [];

    parseItemsGoodsInformation(productTemplateErrors, validation);
    parseAdditionalProcedureCode(productTemplateErrors, validation);
    parseItemBox44(productTemplateErrors, validation);
    parseItemsAdditionalInformation(productTemplateErrors, validation);
    parseItemPreviousDocument(productTemplateErrors, validation);
    parseItemValuationInformation(productTemplateErrors, validation);
    parseItemTransportDocuments(productTemplateErrors, validation);
    parseItemExporter(productTemplateErrors, validation);
    parseItemFiscalReferences(productTemplateErrors, validation);
    parseItemDutiesAndTaxes(productTemplateErrors, validation);

    return { template: productTemplateErrors };
};

const parseItemsGoodsInformation = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const fields: FieldError[] = [];
    if (validation?.descriptionOfGoods) {
        fields.push({ name: `Description of Goods`, message: validation.descriptionOfGoods });
    }

    if (validation?.commodityCodeHarmonizedSystemSubHeadingCode) {
        fields.push({ name: `Commodity Code`, message: validation.commodityCodeHarmonizedSystemSubHeadingCode });
    }

    const documentsAuthorisations = validation.documentsAuthorisations as FormikErrors<DocumentsAuth>;
    if (documentsAuthorisations?.referenceNumberUcr) {
        fields.push({ name: 'Reference Number UCR', message: documentsAuthorisations?.referenceNumberUcr });
    }

    if (validation?.grossMass) {
        fields.push({ name: `Gross Mass`, message: validation.grossMass });
    }

    if (validation?.supplementaryUnits) {
        fields.push({ name: `Supplementary Units`, message: validation.supplementaryUnits });
    }

    if (validation?.packagingNumberOfPackages) {
        fields.push({ name: `Number of Packages`, message: validation.packagingNumberOfPackages });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Goods Information', fields });
    }
};

const parseAdditionalProcedureCode = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem> | undefined) => {
    const additionalProcedure = validation?.additionalProcedure as unknown as AdditionalProcedure[] | string;
    const additionalProcedureIsArray = Array.isArray(additionalProcedure);
    if (!additionalProcedureIsArray || (additionalProcedureIsArray && !additionalProcedure.length)) {
        errors.push({
            name: 'Additional Procedure Code',
            fields: [{ name: 'Additional Procedure Code', message: 'Needs at least one item' }],
        });
    } else if (validation) {
        parseFieldErrorsForMultipleCard(
            'Additional Procedure Code',
            'additionalProcedure',
            ['additionalProcedure'],
            errors,
            validation
        );
    }
};

const parseItemBox44 = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const documentsAuthorisations = validation?.documentsAuthorisations as FormikErrors<DocumentsAuth>;
    const box44 = (documentsAuthorisations?.supportingDocument as unknown as any[]) || [];

    box44.forEach((box44Item: FormikErrors<SupportingDocuments>, index) => {
        const fields: FieldError[] = [];

        if (box44Item?.supportingDocumentType) {
            fields.push({ name: `Document Type`, message: box44Item.supportingDocumentType });
        }

        if (box44Item?.supportingDocumentReferenceNumber) {
            fields.push({ name: `Document Identifier`, message: box44Item.supportingDocumentReferenceNumber });
        }

        if (fields.length > 0) {
            errors.push({ name: `Additional documents and references (Box 44) ${index + 1}`, fields });
        }
    });
};

const parseItemsAdditionalInformation = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const documentsAuthorisations = validation?.documentsAuthorisations as FormikErrors<DocumentsAuth>;
    const docuemnts = (documentsAuthorisations?.additionalInformation as unknown as any[]) || [];

    docuemnts.forEach((document: FormikErrors<AdditionalInformation>, index) => {
        const fields: FieldError[] = [];

        if (document?.additionalInformationCode) {
            fields.push({ name: `Code`, message: document.additionalInformationCode });
        }

        if (document?.additionalInformationText) {
            fields.push({ name: `Text`, message: document.additionalInformationText });
        }

        if (fields.length > 0) {
            errors.push({ name: `Documents Additional Information ${index + 1}`, fields });
        }
    });
};

const parseItemPreviousDocument = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const documentsAuthorisations = validation?.documentsAuthorisations as FormikErrors<DocumentsAuth>;
    const docuemnts = (documentsAuthorisations?.previousDocument as unknown as any[]) || [];

    docuemnts.forEach((document: FormikErrors<PreviousDocument>, index) => {
        const fields: FieldError[] = [];

        if (document?.previousDocumentReferenceNumber) {
            fields.push({ name: `Reference Number`, message: document.previousDocumentReferenceNumber });
        }

        if (document?.previousDocumentType) {
            fields.push({ name: `Document Type`, message: document.previousDocumentType });
        }

        if (fields.length > 0) {
            errors.push({ name: `Previous Document ${index + 1}`, fields });
        }
    });
};

const parseItemValuationInformation = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const fields: FieldError[] = [];
    const itemAmountInvoicedIntrinsicValue =
        validation.itemAmountInvoicedIntrinsicValue as FormikErrors<ValuationInformation>;
    if (itemAmountInvoicedIntrinsicValue?.transportAndInsuranceCostsToTheDestinationAmount) {
        fields.push({
            name: `Transport And Insurance Costs To The Destination Amount`,
            message: itemAmountInvoicedIntrinsicValue.transportAndInsuranceCostsToTheDestinationAmount,
        });
    }

    if (itemAmountInvoicedIntrinsicValue?.transportAndInsuranceCostsToTheDestinationCurrency) {
        fields.push({
            name: `Transport And Insurance Costs To The Destination Currency`,
            message: itemAmountInvoicedIntrinsicValue.transportAndInsuranceCostsToTheDestinationCurrency,
        });
    }

    if (itemAmountInvoicedIntrinsicValue?.valueAmount) {
        fields.push({
            name: `Item Amount Invoiced Intrinsic Value Amount`,
            message: itemAmountInvoicedIntrinsicValue.valueAmount,
        });
    }

    if (itemAmountInvoicedIntrinsicValue?.valueCurrency) {
        fields.push({
            name: `Item Amount Invoiced Intrinsic Value Currency`,
            message: itemAmountInvoicedIntrinsicValue.valueCurrency,
        });
    }

    if (fields.length > 0) {
        errors.push({ name: 'Valuation Information', fields });
    }
};

const parseItemTransportDocuments = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const itemAmountInvoicedIntrinsicValue =
        validation.itemAmountInvoicedIntrinsicValue as FormikErrors<ValuationInformation>;
    const transportDocuments = (itemAmountInvoicedIntrinsicValue?.transportDocument as unknown as any[]) || [];

    transportDocuments.forEach((document: FormikErrors<TransportDocument>, index) => {
        const fields: FieldError[] = [];

        if (document?.transportDocumentReferenceNumber) {
            fields.push({ name: `Reference Number`, message: document.transportDocumentReferenceNumber });
        }

        if (document?.transportDocumentType) {
            fields.push({ name: `Type`, message: document.transportDocumentType });
        }

        if (fields.length > 0) {
            errors.push({ name: `Transport Document ${index + 1}`, fields });
        }
    });
};

const parseItemExporter = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const exporterValidation = validation?.exporter as FormikErrors<DeclarationCustomer>;
    const fields: FieldError[] = parseDeclarationCustomerErrors(exporterValidation, 'Exporter');
    if (fields.length > 0) {
        addToParties(errors, fields);
    }
};

const parseItemFiscalReferences = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const fiscalReferences = (validation.additionalFiscalReference as unknown as any[]) || [];

    fiscalReferences.forEach((document: FormikErrors<AdditionalFiscalReference>, index) => {
        const fields: FieldError[] = [];

        if (document?.additionalFiscalReferenceRole) {
            fields.push({ name: `Reference VAT`, message: document.additionalFiscalReferenceRole });
        }

        if (document?.additionalFiscalReferenceVatIdentificationNumber) {
            fields.push({ name: `Reference Id`, message: document.additionalFiscalReferenceVatIdentificationNumber });
        }

        if (fields.length > 0) {
            errors.push({ name: `Fiscal Reference ${index + 1}`, fields });
        }
    });
};

const parseItemDutiesAndTaxes = (errors: CardError[], validation: FormikErrors<GoodsShipmentItem>) => {
    const fiscalReferences = (validation.dutiesAndTaxes as unknown as any[]) || [];

    fiscalReferences.forEach((payment: FormikErrors<Payment>, index) => {
        const fields: FieldError[] = [];

        if (payment?.dutiesAndTaxesMethodOfPayment) {
            fields.push({ name: `Method Of Payment`, message: payment.dutiesAndTaxesMethodOfPayment });
        }

        if (payment?.boxTaxType) {
            fields.push({ name: `Box Tax Type`, message: payment.boxTaxType });
        }

        if (payment?.boxTaxBaseUnit) {
            fields.push({ name: `Box Tax Base Unit`, message: payment.boxTaxBaseUnit });
        }

        if (payment?.boxAmount) {
            fields.push({ name: `Box Amount`, message: payment.boxAmount });
        }

        if (fields.length > 0) {
            errors.push({ name: `Duties And Taxes ${index + 1}`, fields });
        }
    });
};

const parseFieldErrorsForMultipleCard = (
    cardName: string,
    cardPath: string,
    paths: (string | { [path: string]: string })[],
    errors: CardError[],
    validation: FormikErrors<any>
) => {
    let hasErrors = false;

    (get(validation, cardPath) as any)?.forEach((val: any, index: number) => {
        const fields = parseAnyValidationError(paths, val);

        if (!hasErrors && fields.length > 0) {
            errors.push({ name: cardName, fields: undefined });
            hasErrors = true;
        }

        if (fields.length > 0) {
            errors.push({ name: `${cardName} ${index + 1}`, fields });
        }
    });
};
