import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { incotermCodeLabels } from 'store/declarations/enums/ireland/incoterm-code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const DeliveryTermsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField } = useTooltips();

    return (
        <section
            id="delivery-terms-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('delivery-terms-form-card');
                }
            }}
            data-testid="delivery-terms-form-card"
        >
            <NewFormCard title={t('Delivery terms')}>
                <FormCardContainer>
                    <FormSelect
                        required
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`incotermCode`, props)}
                        refNumber="4.1"
                        label={t('goodsShipment.incotermCode')}
                        tooltip={getH1TooltipsByRefNumberAndField('4.1', t('goodsShipment.incotermCode'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        selectOptions={incotermCodeLabels}
                        testId="incoterm-code"
                        condensed
                    />
                    <CountrySelect
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`countryCode`, props)}
                        label={t('goodsShipment.countryCode')}
                        refNumber="4.1"
                        testId="goodsShipment.countryCode"
                        tooltip={getH1TooltipsByRefNumberAndField('4.1', t('goodsShipment.countryCode'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                    <FormInput
                        maxLength={35}
                        viewOnly={props.viewOnly}
                        {...getFormikProps(`place`, props)}
                        numbersAndLetters
                        refNumber="4.1"
                        testId="goodsShipment.locationName"
                        label={t('goodsShipment.locationName')}
                        tooltip={getH1TooltipsByRefNumberAndField('4.1', t('goodsShipment.locationName'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>
        </section>
    );
};
export default DeliveryTermsCard;
