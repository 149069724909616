import { Col, Modal, Row } from 'antd';
import Container from 'components/ui/base/container';
import CustomModal from 'components/ui/base/modal/Modal';
import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import Filter from 'components/ui/composed/filter/Filter';
import { FilterResult, FilterType } from 'components/ui/composed/filter/filter-result';
import { handleDeclarationFilters } from 'components/ui/composed/filter/filter-utils';
import ListFilter from 'components/ui/composed/filter/ListFilters';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import { enumToText } from 'core/utils/enum-to-text';
import { capitalize } from 'core/utils/strings';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useDeclarations from 'hooks/useDeclarations';
import useRequest from 'hooks/useRequest';
import debounce from 'lodash.debounce';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    deleteDeclaration,
    getIrelandSadDraft,
    getIrelandClearanceDraft,
    generateIrelandSadDraft,
    generateIrelandClearanceDraft,
} from 'store/declarations/client';
import { Declaration, DeclarationFilterFields } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { CreateButton, StyledDivider, TableDiv } from './CustomDeclaration.styles';
import DeclarationTable, { DeclarationTableType } from './declaration-table/DeclarationsTable';
import useDeclarationFilter from '../../hooks/useDeclarationFilter';
import { DocumentsDiv, SadDocTitle } from '../declarations/common/summary-view/SummaryView.styles';
import DocumentTable from '../declarations/common/summary-view/DocumentTable';

const CustomDeclaration: FC = () => {
    const { country, type } = useParams<{ country: DeclarationCountry; type: DeclarationInternalType }>();
    const navigate = useNavigate();
    const { t } = useTranslation('customs_declarations');
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const { doRequest: doDeleteDeclaration } = useRequest(deleteDeclaration);
    const { declarations, listDeclarations, isLoading, clearDeclarationsList } = useDeclarations();
    const { filter, declarationType, setDeclarationFilter } = useDeclarationFilter();

    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>();
    const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
    const [filterResult, setFilterResult] = useState<FilterResult[]>(
        declarationType === type && filter.length ? filter : []
    );
    const [clickedDeclarationId, setClickedDeclarationId] = useState<string | undefined>(undefined);
    const [printModalVisible, setPrintModalVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!(type && filterResult)) return;
        setDeclarationFilter({ declarationType: type, filter: filterResult });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, filterResult]);

    const querySearch = async (query: string) => {
        if (query) {
            const params = getDeclarationExternalEntityParams();
            listDeclarations({ ...params, query });
        } else {
            const params = getDeclarationExternalEntityParams();
            listDeclarations({ ...params });
        }
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    const handleButtonClick = async () => {
        navigate(`/invoice-upload/${country}/${type}`);
    };

    const getDeclarationExternalEntityParams = () => {
        const declarationInternalType = [type!];

        if (type === 'IMPORT' && country === 'uk') {
            declarationInternalType.push(DeclarationInternalType.IMPORT_NEW);
        }

        let params: DeclarationFilterFields = { ...defaultPagination, declarationInternalType };
        if (country === DeclarationCountry.IRELAND) {
            params = { ...params, declarationExternalEntity: [DeclarationExternalEntity.REVENUE] };
        } else if (country === DeclarationCountry.UK) {
            params = {
                ...params,
                declarationExternalEntity: [DeclarationExternalEntity.CDS, DeclarationExternalEntity.CHIEF],
            };
        }
        return params;
    };
    useEffect(() => {
        const refreshList = async () => {
            const params = getDeclarationExternalEntityParams();
            const result = await listDeclarations(params);
            if (!result) {
                clearDeclarationsList();
            }
        };

        setBreadcrumbRoutes([
            {
                breadcrumbName: 'Customs Declarations',
                path: '',
            },
            {
                breadcrumbName: t(`custom${country}`),
                path: '',
            },
            {
                breadcrumbName: t(`${type}${country}`),
                path: '',
            },
        ]);
        if (!paginator && !filterResult) {
            refreshList();
        }

        return () => setFilterResult([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, type]);

    useEffect(() => {
        if (paginator) {
            const params = getDeclarationExternalEntityParams();
            const p = { ...params, ...paginator };
            listDeclarations(p);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator]);

    useEffect(() => {
        if (filterResult?.length) {
            const filters = handleDeclarationFilters(filterResult);
            const params = getDeclarationExternalEntityParams();
            listDeclarations({ ...params, ...filters });
        } else {
            const params = getDeclarationExternalEntityParams();
            listDeclarations({ ...params });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterResult]);

    useEffect(() => {
        if (!clickedDeclarationId) return;

        Promise.all([
            generateIrelandSadDraft(clickedDeclarationId),
            generateIrelandClearanceDraft(clickedDeclarationId),
        ]).then(() => setPrintModalVisible(true));
    }, [clickedDeclarationId]);

    const handleDuplicate = async (declaration: Declaration) => {
        navigate(`/invoice-upload/${country}/${type}/job/${declaration.jobId}`, {
            state: { declaration: declaration },
        });
    };

    const handleDelete = (ids: string[]) => {
        const toDelete = ids;
        setDeleteIds(toDelete);
        setDeleteModalVisible(true);
    };

    const deleteDeclarations = async () => {
        setDeleteModalVisible(false);
        Promise.all(deleteIds.map(async (id) => await doDeleteDeclaration(id))).then((res) => {
            const params = getDeclarationExternalEntityParams();
            listDeclarations({ ...params, page: paginator?.page });
        });
    };

    const handlePagination = (params: Partial<PaginatedParams>) => {
        if (paginator && paginator.page && paginator.size) {
            setPaginator({ page: params.page, size: paginator.size });
        } else {
            setPaginator({ page: params.page, size: defaultPagination.size });
        }
    };

    const handleSort = (params: Partial<PaginatedParams>) => {
        setPaginator({ ...paginator, ...params });
    };

    const getStatus = useMemo(
        () => filterResult.find((f) => f.type === FilterType.DECLARATION_STATUS)?.value,
        [filterResult]
    );

    const createDeclarationButtonText = useMemo(() => {
        return type !== DeclarationInternalType.ARRIVAL
            ? `Create ${enumToText(type)} ${capitalize(country!)} Declaration`
            : `Create Arrival at Exit Declaration`;
    }, [type, country]);

    return (
        <>
            <CustomModal
                title={
                    deleteIds.length > 1 ? (
                        <H5>Do you want to remove these declarations?</H5>
                    ) : (
                        <H5>Do you want to remove this declaration?</H5>
                    )
                }
                centered
                visible={isDeleteModalVisible}
                onOk={deleteDeclarations}
                onCancel={() => setDeleteModalVisible(false)}
                width={762}
                contentText={
                    deleteIds.length > 1
                        ? 'If you remove all these declarations, you will lose all the information associated to them.'
                        : 'If you remove this declarations, you will lose all the information associated with it.'
                }
            />
            <Modal
                centered
                width={762}
                visible={printModalVisible}
                onCancel={() => setPrintModalVisible(false)}
                footer={null}
                bodyStyle={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '3.2rem',
                    minHeight: '236px',
                }}
            >
                <>
                    <DocumentsDiv>
                        <SadDocTitle>SAD Document</SadDocTitle>
                        <DocumentTable id={clickedDeclarationId} request={getIrelandSadDraft} fileName="sad-document" />
                    </DocumentsDiv>
                    <DocumentsDiv>
                        <SadDocTitle>Clearance Slip</SadDocTitle>
                        <DocumentTable
                            id={clickedDeclarationId}
                            request={getIrelandClearanceDraft}
                            fileName="clearanceslip-document"
                        />
                    </DocumentsDiv>
                </>
            </Modal>
            <Container>
                <H5>{`${t(type!)} ${t('declarations')}`}</H5>

                <CreateButton type="primary" size="large" onClick={handleButtonClick}>
                    {createDeclarationButtonText}
                </CreateButton>
                <StyledDivider />
                <Row gutter={16} wrap={false}>
                    <Col flex="auto">
                        <SearchBar
                            inputPlaceholder={t('SearchByDeclarationTable')}
                            onSearch={(value) => debouncedSearch(value)}
                            onClear={() => {
                                listDeclarations({ ...getDeclarationExternalEntityParams() });
                            }}
                        />
                    </Col>
                    <Col>
                        <Filter
                            setShowFilterDropdown={setShowFilterDropdown}
                            showFilterDropdown={showFilterDropdown}
                            options={['Date', 'Declaration status', 'Declaration type']}
                            statusChecked={getStatus}
                            filterResult={filterResult}
                            setFilterResult={setFilterResult}
                        />
                    </Col>
                </Row>

                <TableDiv>
                    <ListFilter
                        filters={filterResult}
                        updateFilters={(filters) => {
                            setFilterResult(filters);
                        }}
                    />
                    {declarations && (
                        <DeclarationTable
                            data={declarations}
                            handleDuplicate={handleDuplicate}
                            onDelete={handleDelete}
                            handlePagination={handlePagination}
                            loading={isLoading}
                            handleSort={handleSort}
                            type={DeclarationTableType.DECLARATIONS}
                            onPrint={(declarationId: string) => setClickedDeclarationId(declarationId)}
                        />
                    )}
                </TableDiv>
            </Container>
        </>
    );
};

export default CustomDeclaration;
