import { Drawer, notification, Tabs, Modal, Button } from 'antd';
import { ARadio } from 'components/ui/base/radio/Radio';
import { RadioGroup } from 'components/ui/composed/declarations/customerEoriAddressRadioButton/CustomerEoriAddressRadioButton.styles';
import SearchCustomer from 'components/ui/composed/searchCustomer/SearchCustomer';
import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Customer } from 'store/customers/customer';
import { fillCustomerFromListOfCustomers } from 'views/declarations/utils/form-utils';
import { DeclarationFormCardProps } from '../declaration.form.card';
import PartiesAddressForm from './PartiesAddressForm';
import PartiesEoriForm from './PartiesEoriForm';
import PartiesEoriFormProps from './PartiesEoriFormProps';
import PartiesAddressFormProps from './PartiesAddressFormProps';
import NewFormCard from '../cards/NewFormCard';
import ConditionalWrapper from 'components/ConditionalWrapper';
import FormCard from '../cards/FormCard';
import { SearchOutlined } from '@ant-design/icons';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import addPathPrefix from 'utils/addPathPrefix';
import useDeclarations from 'hooks/useDeclarations';
import { useDeclarationContext } from 'utils/DeclarationContext';
import { capitalize, set } from 'lodash';
import { useProductTemplateContext } from 'utils/ProductTemplateContext';
import AutoFillModal, { Field } from '../../ireland/import/h1/components/AutoFillModal';
import { FormikProps, FormikProvider, useFormikContext } from 'formik';
import { deleteLevelValues, levelHasValues } from './partiesLevelsValidationUtils';

const toTabKey = (tab: string) => {
    return `${tab.toLowerCase()}-tab-key`;
};
const toHeader = (tabKey: string | undefined) => {
    return capitalize(tabKey?.split('-')[0]);
};

export interface Party {
    path: string;
    header: string;
    refNumber: string | undefined;
    eoriRequired?: boolean;
    hasPhoneNumber?: boolean;
    hasRepresentativeStatus?: boolean;
    eoriAutofill?: Field[];
}

export interface Address {
    name: string;
    streetAndNumber: string;
    country: string;
    postCode: string;
    city: string;
}

export interface AdditionalPaths {
    representativeStatus?: string;
    phoneNumber?: string;
}

export interface Paths {
    eori: string;
    address: Address;
    additional?: AdditionalPaths;
}

enum ToggleState {
    EORI,
    ADDRESS,
}

export interface SpecificPartiesCardProps {
    parties: Party[];
    paths: Paths;
    labels?: Partial<Paths>;
    PartiesEoriForm?: FC<PartiesEoriFormProps>;
    PartiesAddressForm?: FC<PartiesAddressFormProps>;
    condensed?: boolean;
}

export interface PartiesCardProps extends SpecificPartiesCardProps, DeclarationFormCardProps {}

const PartiesCard = ({
    parties,
    paths,
    labels,
    PartiesAddressForm: PartiesAddressFormProp,
    PartiesEoriForm: PartiesEoriFormProp,
    condensed,
    formik: declarationFormik,
    ...formCardProps
}: PartiesCardProps): ReactElement => {
    const [toggle, setToggle] = useState<ToggleState>(ToggleState.EORI);
    const [isChoosePartyLevelModalOpen, setChoosePartyLevelModalOpen] = useState<boolean>(false);
    const [showCustomers, setShowCustomers] = useState<string>();
    const { template, templateFormik, form: templateForm, isViewOnly } = useTemplateContext();
    const { declarationTemplate: productTemplateDeclarationTemplate } = useProductTemplateContext();
    const { declarationTemplate } = useDeclarations();
    const { form } = useDeclarationContext();
    const declarationFormikContext: FormikProps<any> = useFormikContext();

    const templateData = useMemo(
        () => productTemplateDeclarationTemplate?.template ?? declarationTemplate?.template,
        [productTemplateDeclarationTemplate, declarationTemplate?.template]
    );

    const EoriForm = useMemo(() => PartiesEoriFormProp ?? PartiesEoriForm, [PartiesEoriFormProp]);
    const AddressForm = useMemo(() => PartiesAddressFormProp ?? PartiesAddressForm, [PartiesAddressFormProp]);

    const [activeTabKey, setActiveTabKey] = useState<string | undefined>(undefined);

    // Sets the toggle based on whether eori or address section is available for the party
    const handleToggleForParty = (partyHeader: string) => {
        if (visibleEoriAddressPerParty[partyHeader]) {
            if (toggle === ToggleState.ADDRESS && !visibleEoriAddressPerParty[partyHeader]?.address) {
                setToggle(ToggleState.EORI);
            } else if (toggle === ToggleState.EORI && !visibleEoriAddressPerParty[partyHeader]?.eori) {
                setToggle(ToggleState.ADDRESS);
            }
        }
    };

    const changeParty = (activeKey: string) => {
        const partyHeader = toHeader(activeKey);
        handleToggleForParty(partyHeader);
        setActiveTabKey(activeKey);
    };

    const getPartyFieldsMeta = (party: Party) => {
        const _templateData = template ? templateFormik?.values : templateData;
        const _form = template || productTemplateDeclarationTemplate ? templateForm : form;
        if (!_templateData || !_form) return null;

        const meta = _templateData?.[_form]?.meta ?? {};
        const partyMeta = Object.entries(meta).filter(([key]) => new RegExp(`^${party.path}`, 'g').exec(key)?.length);
        return Object.fromEntries(partyMeta);
    };

    /**
     * Construct eori and address visibility per party from template meta configuration
     */
    const visibleEoriAddressPerParty = useMemo(
        () =>
            parties.reduce((acc, party) => {
                const partyFieldsMeta = getPartyFieldsMeta(party);
                if (template || !partyFieldsMeta || !Object.keys(partyFieldsMeta)?.length) {
                    acc[party.header] = { eori: true, address: true };
                    return acc;
                }

                const eoriMeta = Object.entries(partyFieldsMeta).find(([key]) => key.includes(paths.eori));
                const eoriVisible = !eoriMeta || !!eoriMeta?.[1].isEditable || !!eoriMeta?.[1].isViewable;
                const addressMeta = Object.entries(partyFieldsMeta).filter(([key]) =>
                    Object.values({ ...paths.additional, ...paths.address }).some((path) => key.includes(path))
                );
                const addressVisible =
                    !addressMeta.length ||
                    addressMeta.some(([_, value]) => !value || value.isEditable || value.isViewable);

                acc[party.header] = { eori: eoriVisible, address: addressVisible };

                return acc;
            }, {} as Record<string, { eori: boolean; address: boolean } | undefined>),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [form, templateData]
    );

    /**
     * Filter the visible parties based on the template meta configuration
     */
    const visibleParties = useMemo(
        () =>
            parties.filter((party) => {
                const partyVisibleEoriAddress = visibleEoriAddressPerParty[party.header];
                return !partyVisibleEoriAddress || partyVisibleEoriAddress.eori || partyVisibleEoriAddress.address;
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [form, templateData]
    );

    /**
     * Set the first visible party as the active tab key
     */
    useEffect(() => {
        if (!visibleParties[0]) return;
        const partyHeader = visibleParties[0].header;
        setActiveTabKey(toTabKey(visibleParties[0].header));
        handleToggleForParty(partyHeader);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleEoriAddressPerParty, visibleParties]);

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | null>(null);
    const [prevValue, setPrevValue] = useState<string | null>(null);

    const customerWithAutoFill = useMemo(
        () =>
            parties?.reduce((customers: string[], party) => {
                if (party.eoriAutofill) customers.push(party.header);
                return customers;
            }, []),
        [parties]
    );

    const openAutoFillModal = (value: any | null) => {
        const activeTabPartyHasAutoFill = customerWithAutoFill.some((customer) => customer === toHeader(activeTabKey!));

        if (!activeTabPartyHasAutoFill || prevValue === value) return;

        setOpen(true);
        setValue(value);
    };
    const closeAutoFillModal = () => {
        setOpen(false);
        setValue(null);
        setPrevValue(value);
    };

    const autoFill = (fields: string[]) => {
        const values = formik?.values;
        const newValues = fields.reduce((acc, field) => set(acc, field, value), values);
        formik?.setValues(newValues);
    };

    const formik = useMemo(() => {
        if (template && templateFormik) return templateFormik;
        return declarationFormik ?? declarationFormikContext;
    }, [declarationFormik, template, templateFormik, declarationFormikContext]);

    const autoFillFields = parties?.reduce((fields: Field[], party) => {
        if (party.eoriAutofill) {
            party.eoriAutofill.forEach((autoFill) => fields.push(autoFill));
        }

        return fields;
    }, []);

    const activeTabPartyPath = useMemo(() => {
        const activeTabParty = parties.find((party) => party.header === toHeader(activeTabKey!));

        return template ? addPathPrefix(`${templateForm}.defaults`, activeTabParty?.path) : activeTabParty?.path;
    }, [activeTabKey, parties, template, templateForm]);

    const addressLevelFieldPaths = { ...paths.address, phoneNumber: paths.additional?.phoneNumber };
    const eoriLevelFieldPaths = { eori: paths.eori, representativeStatus: paths.additional?.representativeStatus };

    const checkIfBothLevelsHaveValues = useCallback(
        () =>
            levelHasValues(activeTabPartyPath, addressLevelFieldPaths, formik) &&
            levelHasValues(activeTabPartyPath, eoriLevelFieldPaths, formik) &&
            setChoosePartyLevelModalOpen(true),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeTabPartyPath, formik]
    );

    useEffect(() => {
        const closeChoosePartyLevelModal = (e: KeyboardEvent) =>
            e.key === 'Escape' && setChoosePartyLevelModalOpen(false);

        window.addEventListener('keyup', closeChoosePartyLevelModal);

        return () => window.removeEventListener('keyup', closeChoosePartyLevelModal);
    }, []);

    return (
        <>
            {formik && (
                <FormikProvider value={formik}>
                    <AutoFillModal
                        visible={open}
                        value={value}
                        prevValue={prevValue}
                        fields={autoFillFields}
                        onCancel={closeAutoFillModal}
                        onOk={autoFill}
                    />
                </FormikProvider>
            )}

            <ConditionalWrapper
                condition={!condensed}
                wrapper={(children) => (
                    <FormCard
                        title="Parties"
                        {...formCardProps}
                        action={
                            !formCardProps.viewOnly
                                ? {
                                      title: 'Search Customer',
                                      onClick: () => setShowCustomers(activeTabKey),
                                      icon: <SearchOutlined />,
                                  }
                                : undefined
                        }
                    >
                        {children}
                    </FormCard>
                )}
            >
                <ConditionalWrapper
                    condition={condensed}
                    wrapper={(children) => (
                        <NewFormCard
                            title="Parties"
                            showCustomers={() => setShowCustomers(activeTabKey)}
                            special={{ partyPath: activeTabPartyPath }}
                        >
                            {children}
                        </NewFormCard>
                    )}
                >
                    <Tabs onChange={changeParty} activeKey={activeTabKey}>
                        {visibleParties?.map((party) => {
                            const handleSelectCustomer = (customer: Customer) => {
                                const { filledAddress, filledEori } = fillCustomerFromListOfCustomers(
                                    customer,
                                    template ? addPathPrefix(`${templateForm}.defaults`, party.path) : party.path,
                                    setShowCustomers,
                                    template ? templateFormik?.getFieldHelpers : formCardProps.getFieldHelpers,
                                    { eori: paths.eori, ...paths.address },
                                    { customerMeta: getPartyFieldsMeta(party), template },
                                    toggle === ToggleState.EORI ? 'eori' : 'address'
                                );

                                if (filledEori) {
                                    setToggle(ToggleState.EORI);
                                    openAutoFillModal(customer.eori);
                                } else if (filledAddress) setToggle(ToggleState.ADDRESS);
                                else
                                    notification.warning({
                                        message: 'Could not fill party',
                                        description: 'Fields are hidden or no customer data to fill',
                                    });
                            };
                            return (
                                <Tabs.TabPane forceRender tab={party.header} key={toTabKey(party.header)}>
                                    {!template && (
                                        <RadioGroup value={toggle} onChange={(e) => setToggle(e.target.value)}>
                                            {visibleEoriAddressPerParty[party.header]?.eori && (
                                                <ARadio value={ToggleState.EORI}>Eori number</ARadio>
                                            )}
                                            {visibleEoriAddressPerParty[party.header]?.address && (
                                                <ARadio value={ToggleState.ADDRESS}>Customer Address</ARadio>
                                            )}
                                        </RadioGroup>
                                    )}
                                    {(toggle === 0 || template) && (
                                        <EoriForm
                                            refNumber={party.refNumber}
                                            eoriRequired={party.eoriRequired}
                                            eoriLabel={labels?.eori}
                                            paths={paths}
                                            party={party}
                                            condensed={condensed}
                                            onBlur={(e) => {
                                                openAutoFillModal(e.target.value);
                                                checkIfBothLevelsHaveValues();
                                            }}
                                            {...formCardProps}
                                        />
                                    )}
                                    {template && (
                                        <span
                                            style={{
                                                display: 'block',
                                                marginBottom: isViewOnly ? '1rem' : '0.5rem',
                                            }}
                                        />
                                    )}
                                    {(toggle === 1 || template) && (
                                        <AddressForm
                                            refNumber={party.refNumber}
                                            paths={paths}
                                            party={party}
                                            condensed={condensed}
                                            onBlur={() => checkIfBothLevelsHaveValues()}
                                            {...formCardProps}
                                        />
                                    )}
                                    <Drawer
                                        title="Search for customer"
                                        width="1098"
                                        visible={showCustomers === toTabKey(party.header)}
                                        onClose={() => {
                                            setShowCustomers(undefined);
                                        }}
                                    >
                                        <SearchCustomer handleSelect={handleSelectCustomer} />
                                    </Drawer>
                                </Tabs.TabPane>
                            );
                        })}
                    </Tabs>
                </ConditionalWrapper>
            </ConditionalWrapper>
            <Modal
                title={null}
                closable={false}
                visible={isChoosePartyLevelModalOpen}
                footer={
                    <>
                        <Button type="primary" onClick={() => setChoosePartyLevelModalOpen(false)}>
                            Ignore
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                deleteLevelValues(activeTabPartyPath, addressLevelFieldPaths, formik);
                                setChoosePartyLevelModalOpen(false);
                            }}
                        >
                            EORI
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                deleteLevelValues(activeTabPartyPath, eoriLevelFieldPaths, formik);
                                setChoosePartyLevelModalOpen(false);
                            }}
                        >
                            Address
                        </Button>
                    </>
                }
            >
                <div>
                    {toHeader(activeTabKey!)} has information on both levels - EORI and Address. Please choose only one
                    level.
                </div>
            </Modal>
        </>
    );
};

export default PartiesCard;
