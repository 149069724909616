import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import TransportDocumentInformationCard from 'views/declarations/common/box44/TransportDocumentInformationCard';
import {
    DeclarationBorderlessCardProps,
    DeclarationFormCardProps,
    DeclarationMultipleBorderlessCardProps,
    DeclarationMultipleFormCardProps,
} from 'views/declarations/common/declaration.form.card';
import PartiesCard, { SpecificPartiesCardProps } from 'views/declarations/common/parties/PartiesCard';
import { Card, MasterDetailsIrelandCardsType } from 'views/declarations/common/skeleton/card-type';
import AdditionsDeductionsCard from './cards/AdditionsDeductionsCard';
import ContainerIdentificationCard from './cards/ContainerIdentificationCard';
import SupplyChainActorCard from './cards/SupplyChainActorCard';
import AdditionalInformationCard from './cards/AdditionalInformationCard';
import AuthorisationCard from './cards/authorisation-card/AuthorisationCard';
import NestedAuthorisationCard, {
    NestedAuthorisationCardProps,
} from './cards/authorisation-card/NestedAuthorisationCard';
import OwnerOfTheGoodsCard from './cards/authorisation-card/OwnerOfTheGoodsCard';
import FirstPlaceOfUseCard from './cards/authorisation-card/place-of-use/FirstPlaceOfUseCard';
import PlaceOfUseCard from './cards/authorisation-card/place-of-use/PlaceOfUseCard';
import AuthorisationHolderCard from './cards/AuthorisationHolderCard';
import DeliveryTermsCard from './cards/DeliveryTermsCard';
import ExtraDetailsCard from './cards/ExtraDetailsCard';
import GoodsLocationCard from './cards/GoodsLocationCard';
import GoodsShipmentCard from './cards/GoodsShipmentCard';
import GuaranteeCard from './cards/GuaranteeCard';
import GuaranteeReferenceCard from './cards/GuaranteeReferenceCard';
import MasterDetailsCard from './cards/MasterDetailsCard';
import OtherDetailsCard from './cards/OtherDetailsCard';
import PreviousDocumentInformationCard from './cards/PreviousDocumentInformationCard';
import TransportInformationCard from './cards/TransportInformationCard';
import ValueDetailsCard from './cards/ValueDetailsCard';
import ContainerCheckboxCard from './cards/ContainerCheckboxCard';

export type ComponentTypeForm =
    | React.FC<DeclarationFormCardProps>
    | React.FC<DeclarationMultipleFormCardProps>
    | React.FC<NestedAuthorisationCardProps>;
export enum MasterDetailsIrelandH1CardsType {
    SINGLE,
    MULTIPLE,
    NESTED_AUTHORIZATION,
    ADDITIONAL_CONDITIONAL_INFORMATION,
}

export type ChildrenComponent =
    | React.FC<DeclarationBorderlessCardProps>
    | React.FC<DeclarationMultipleBorderlessCardProps>
    | React.FC<DeclarationFormCardProps & { subSection: string }>;

export interface MasterDetailsIrelandH1Cards {
    cards: Card[];
}

const h1DeclarationSkeleton: MasterDetailsIrelandH1Cards = {
    cards: [
        {
            key: 'master-details-card',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 1,
            defaultOpen: true,
            title: 'masterDetails',
            valid: false,
            component: MasterDetailsCard,
            declarationType: DeclarationName.H1,
        },
        {
            key: 'parties-card',
            cardNumber: 2,
            defaultOpen: false,
            valid: false,
            condensed: true,
            component: PartiesCard,
            declarationType: DeclarationName.H1,
            type: MasterDetailsIrelandCardsType.PARTIES,
            other: {
                parties: [
                    {
                        header: 'Exporter',
                        path: 'exporter',
                        refNumber: '3.1',
                    },
                    {
                        header: 'Importer',
                        path: 'goodsShipment.importer',
                        refNumber: '3.15',
                        eoriRequired: true,
                    },
                    {
                        header: 'Declarant',
                        path: 'declarant',
                        refNumber: '3.17',
                        eoriRequired: true,
                        eoriAutofill: [
                            {
                                name: 'personPayingCustomsDuty',
                                label: 'Customs Duty Payer',
                            },
                        ],
                    },
                    {
                        header: 'Representative',
                        path: 'representative',
                        refNumber: '3.19',
                        hasRepresentativeStatus: true,
                    },
                    {
                        header: 'Buyer',
                        path: 'goodsShipment.buyer',
                        refNumber: '3.26',
                    },
                    {
                        header: 'Seller',
                        path: 'goodsShipment.seller',
                        refNumber: '3.24',
                    },
                ],
                paths: {
                    eori: 'eori',
                    address: {
                        city: 'city',
                        country: 'country',
                        name: 'name',
                        postCode: 'postCode',
                        streetAndNumber: 'streetAndNumber',
                    },
                    additional: {
                        representativeStatus: 'statusCode',
                    },
                },
            } as SpecificPartiesCardProps,
        },
        {
            key: 'value-details-card',
            cardNumber: 3,
            defaultOpen: false,
            valid: false,
            component: ValueDetailsCard,
            declarationType: DeclarationName.H1,
            type: MasterDetailsIrelandCardsType.SINGLE,
        },
        {
            key: 'ireland-h1-previous-doc-info',
            cardNumber: 4,
            defaultOpen: false,
            valid: false,
            listPath: `goodsShipment.simplifiedDeclarationDocumentWritingOff`,
            propsPathPrefix: 'goodsShipment',
            component: PreviousDocumentInformationCard,
            declarationType: DeclarationName.H1,
            type: MasterDetailsIrelandCardsType.SINGLE,
        },
        {
            key: 'delivery-terms-card',
            cardNumber: 5,
            defaultOpen: false,
            valid: false,
            component: DeliveryTermsCard,
            declarationType: DeclarationName.H1,
            type: MasterDetailsIrelandCardsType.SINGLE,
            propsPathPrefix: 'goodsShipment',
        },
        {
            key: 'transport-information-card',
            cardNumber: 6,
            defaultOpen: false,
            valid: false,
            declarationType: DeclarationName.H1,
            component: TransportInformationCard,
            type: MasterDetailsIrelandCardsType.SINGLE,
        },
        {
            key: 'transport-document-information-card',
            cardNumber: 7,
            defaultOpen: false,
            valid: false,
            declarationType: DeclarationName.H1,
            component: TransportDocumentInformationCard,
            type: MasterDetailsIrelandCardsType.SINGLE,
        },
        {
            key: 'goods-location-card',
            cardNumber: 8,
            defaultOpen: false,
            valid: false,
            propsPathPrefix: 'goodsShipment.locationGoods',
            component: GoodsLocationCard,
            declarationType: DeclarationName.H1,
            type: MasterDetailsIrelandCardsType.SINGLE,
        },
        {
            key: 'other-details-card',
            cardNumber: 9,
            declarationType: DeclarationName.H1,
            defaultOpen: false,
            valid: false,
            component: OtherDetailsCard,
            type: MasterDetailsIrelandCardsType.SINGLE,
        },
        {
            key: 'extra-details-card',
            cardNumber: 10,
            defaultOpen: false,
            declarationType: DeclarationName.H1,
            valid: false,
            component: ExtraDetailsCard,
            type: MasterDetailsIrelandCardsType.SINGLE,
        },
        {
            key: 'nested-authorisation-card',
            cardNumber: 11,
            defaultOpen: false,
            valid: false,
            component: NestedAuthorisationCard,
            declarationType: DeclarationName.H1,
            type: MasterDetailsIrelandCardsType.NESTED_AUTHORIZATION,
            pathOwnerOfGoods: `authorisation.ownerOfTheGoods`,
            pathPlacesOfUse: `authorisation.placeOfUse`,
            children: [
                {
                    component: AuthorisationCard,
                    propsPathPrefix: 'authorisation',
                    key: `authorisation-simple-card`,
                },
                {
                    component: FirstPlaceOfUseCard,
                    propsPathPrefix: 'authorisation.firstPlaceOfUse',
                    key: `first-place-of-use-card`,
                },
            ],
        },
        {
            key: 'ireland-h1-authorisation-placeOfUse',
            cardNumber: 14,
            defaultOpen: false,
            declarationType: DeclarationName.H1,
            valid: false,
            listPath: 'authorisation.placeOfUse',
            component: PlaceOfUseCard,
            type: MasterDetailsIrelandCardsType.SINGLE,
        },
        {
            key: 'ireland-h1-authorisation-owner-of-goods',
            cardNumber: 14,
            defaultOpen: false,
            declarationType: DeclarationName.H1,
            valid: false,
            listPath: 'authorisation.ownerOfTheGoods',
            component: OwnerOfTheGoodsCard,
            type: MasterDetailsIrelandCardsType.SINGLE,
        },
        {
            key: 'ireland-h1-auth-holder-',
            cardNumber: 12,
            defaultOpen: false,
            declarationType: DeclarationName.H1,
            valid: false,
            listPath: 'authorisationHolder',
            component: AuthorisationHolderCard,
            type: MasterDetailsIrelandCardsType.SINGLE,
        },
        {
            key: 'ireland-h1-guarantee-',
            multiple: false,
            cardNumber: 13,
            defaultOpen: false,
            valid: false,
            propsPathPrefix: 'guarantee',
            listPath: 'guarantee',
            declarationType: DeclarationName.H1,
            component: GuaranteeCard,
            type: MasterDetailsIrelandCardsType.SINGLE,
            children: [
                {
                    component: GuaranteeReferenceCard,
                    propsPathPrefix: 'guarantee',
                    key: `guarantee-reference-`,
                    listPath: 'guaranteeReference',
                    multiple: true,
                },
            ],
        },
        {
            key: 'gooods-shipment-card',
            cardNumber: 14,
            defaultOpen: false,
            valid: false,
            propsPathPrefix: 'goodsShipment',
            component: GoodsShipmentCard,
            declarationType: DeclarationName.H1,
            type: MasterDetailsIrelandCardsType.SINGLE,
        },
        {
            key: 'ireland-h1-additional-info',
            multiple: true,
            declarationType: DeclarationName.H1,
            cardNumber: 15,
            defaultOpen: false,
            valid: false,
            listPath: 'goodsShipment.additionalInformation',
            component: AdditionalInformationCard,
            type: MasterDetailsIrelandCardsType.SINGLE,
        },
        {
            key: 'supply-chain-actor',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 18,
            defaultOpen: false,
            title: 'supplyChainActor',
            valid: false,
            component: SupplyChainActorCard,
            propsPathPrefix: 'goodsShipment.supplyChainActor',
        },
        {
            key: 'additions-deductions',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 19,
            defaultOpen: false,
            title: 'additionsDeductions',
            valid: false,
            component: AdditionsDeductionsCard,
            propsPathPrefix: 'goodsShipment.additionsDeductions',
        },
        {
            key: 'container-checkbox',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 20,
            defaultOpen: false,
            valid: false,
            component: ContainerCheckboxCard,
            propsPathPrefix: 'goodsShipment.container',
        },
        {
            key: 'container-identification',
            type: MasterDetailsIrelandCardsType.SINGLE,
            cardNumber: 21,
            defaultOpen: false,
            valid: false,
            component: ContainerIdentificationCard,
            propsPathPrefix: 'goodsShipment',
        },
    ],
};
export default h1DeclarationSkeleton;
