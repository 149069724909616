import { useFormik } from 'formik';
import useAepBusinessRules from 'hooks/useAepBusinessRules';
import { FC, useMemo } from 'react';
import { IrelandExportSubmissionError } from 'store/declarations/ireland/ireland-export-notification';
import AepBusinessRuleRow from 'views/documentation/aep-documentation/AepBusinessRuleRow';
import { StyledHr, Title } from '../DeclarationErrorsDrawer.styles';
import { ErrorRow } from './RevenueImportErrorDetails';

export const RevenueExportErrorDetails: FC<{
    details: IrelandExportSubmissionError;
    formik?: ReturnType<typeof useFormik>;
}> = ({ details, formik }) => {
    const { rules } = useAepBusinessRules();

    const date = useMemo(() => {
        if (details?.errorDate) {
            const date = new Date(details.errorDate);
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        }
        return '-';
    }, [details?.errorDate]);

    const businessRule = useMemo(() => {
        const r = rules.find((p) => p.code === details.errorCode);

        if (!r) return <></>;

        return (
            <>
                <StyledHr></StyledHr>
                <Title>Revenue business rule</Title>
                <AepBusinessRuleRow rule={r} />
                <span>
                    <a
                        href="https://www.revenue.ie/en/customs-traders-and-agents/documents/electronic/error-spec1.txt"
                        rel="noreferrer"
                        target="_blank"
                    >
                        AEP Business Rules and Conditions
                    </a>
                </span>
            </>
        );
    }, [details, rules]);

    return (
        <>
            <Title>Error details</Title>
            <ErrorRow label="Code:" value={details?.errorCode ?? '-'} />
            <ErrorRow label="Message:" value={details?.errorText ?? '-'} />
            <ErrorRow label="Date:" value={date} />
            <ErrorRow label="Field Identifier:" value={details?.fieldIdentifier ?? '-'} />
            <ErrorRow label="Sequence Identifier:" value={details?.sequenceIdentifier ?? '-'} />
            <ErrorRow label="Validation Type:" value={details?.validationType ?? '-'} />
            {details?.errorCode ? businessRule : <></>}
        </>
    );
};
export default RevenueExportErrorDetails;
