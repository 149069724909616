import { SelectProps } from 'antd';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FC, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ErrorDiv,
    ErrorLabel,
    FormItem,
    InputLabel,
    InputLabelRequired,
    LabelDiv,
} from '../formInput/FormInput.styles';
import { Option } from './../../base/select/Select';
import { StyledSelect } from './FormSelect.styles';
// TODO could have select option in a more common place
export interface SelectOption {
    id: string | number;
    value: string;
}

export interface FormSelectProps {
    mode?: SelectProps['mode'];
    label?: string;
    selectOptions?: Readonly<SelectOption[]>;
    children?: ReactNode;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    required?: boolean;
    placeholder?: string;
    hideKeys?: boolean;
    disabled?: boolean;
    handleCountryChange?: (e: any) => void;
}

export interface SelectSearchOption {
    children: any;
    key: string;
    props: any;
    value: string;
}

const FormSelect: FC<FormSelectProps> = ({
    label,
    selectOptions,
    fieldProps,
    fieldMeta,
    required,
    fieldHelper,
    placeholder,
    hideKeys,
    disabled = false,
    mode,
    handleCountryChange,
}) => {
    const { t } = useTranslation('common');

    const handleEmptyValues = useCallback((item: SelectOption) => {
        return !!item.value ? item.value : item.id;
    }, []);

    const onMouseClicked = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <>
            <LabelDiv>
                {label && <InputLabel>{label}</InputLabel>}
                {required && <InputLabelRequired>{t('*')}</InputLabelRequired>}
            </LabelDiv>
            <FormItem validateStatus={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}>
                <div style={{ display: 'flex' }}>
                    <StyledSelect
                        mode={mode}
                        disabled={disabled}
                        placeholder={placeholder ?? ''}
                        showSearch
                        allowClear
                        size="large"
                        {...fieldProps}
                        status={fieldMeta?.error && !!fieldMeta?.touched ? 'error' : ''}
                        filterOption={(input: string, option: SelectSearchOption) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value: any) => {
                            if (value) {
                                handleCountryChange?.(value);
                                fieldProps.onChange({ target: { value, name: fieldProps.name } });
                            } else {
                                fieldHelper?.setValue(null);
                            }
                        }}
                        onBlur={(value: any) => {
                            fieldHelper?.setTouched(true);
                        }}
                        onClick={onMouseClicked}
                    >
                        {selectOptions &&
                            selectOptions?.map((item: SelectOption, i: number) =>
                                !hideKeys ? (
                                    <Option onClick={onMouseClicked} key={i} value={item.id}>
                                        {`${item.id} - ${item.value}`}
                                    </Option>
                                ) : (
                                    <Option onClick={onMouseClicked} key={i} value={item.id}>
                                        {handleEmptyValues(item)}
                                    </Option>
                                )
                            )}
                    </StyledSelect>
                </div>
                <ErrorDiv error={!!fieldMeta?.error} touched={!!fieldMeta?.touched}>
                    {!!fieldMeta?.error && !!fieldMeta?.touched && <ErrorLabel>{fieldMeta?.error}</ErrorLabel>}
                </ErrorDiv>
            </FormItem>
        </>
    );
};

export default FormSelect;
