import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState } from 'views/declarations/utils/form-utils';
import PreferenceBlock from '../../blocks/PreferenceBlock';

const PreferenceCard = (props: DeclarationFormCardProps): ReactElement => {
    const { t } = useTranslation('form');

    return (
        <FormCard
            hide={props.hide}
            expandAll={props.expandAll}
            keyCard={props.keyCard}
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            title={t('preference')}
            cardNumber={props.cardNumber}
            total={props.cardTotal}
            state={getCardState(
                [
                    {
                        name: 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee.dutyRegimeCode',
                        required: false,
                    },
                ],
                props
            )}
        >
            <PreferenceBlock />
        </FormCard>
    );
};

export default PreferenceCard;
