import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import FormCard from 'views/declarations/common/cards/FormCard';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps } from 'views/declarations/utils/form-utils';

const GoodsShipmentCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getIrelandExportTooltipsByRefNumber } = useTooltips();

    const handleToggleHelp = (refNumber: string | number): void => {
        props.toggleHelp && props.toggleHelp(refNumber);
    };

    const natureOfTransaction = useMemo(
        () => normalizeCodesToSelect(codelists?.irelandNatureOfTransactions || []),
        [codelists?.irelandNatureOfTransactions]
    );

    const OfficeOfExit = useMemo(
        () => normalizeCodesToSelect(codelists?.irelandOfficesOfExit || []),
        [codelists?.irelandOfficesOfExit]
    );

    return (
        <section
            id={'goods-shipment-form-card'}
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('goods-shipment-form-card');
                }
            }}
        >
            <FormCard
                defaultOpen={props.defaultOpen}
                viewOnly={props.viewOnly}
                title={t('goodsShipment.title')}
                cardNumber={props.cardNumber}
                total={props.cardTotal}
                expandAll={props.expandAll}
                keyCard={props.keyCard}
                state={getCardState(
                    [
                        { name: `ucr`, required: false },
                        { name: `exportationCountry`, required: false },
                        { name: `destinationCountry`, required: false },
                        { name: `deferredPayment`, required: false },
                        { name: `currencyCode`, required: false },
                        { name: `rateNumeric`, required: false },
                        { name: `totalAmountInvoiced`, required: false },
                        { name: `totalGrossMass`, required: false },
                        { name: `firstNatureOfTransactionCode`, required: false },
                        { name: `secondNatureOfTransactionCode`, required: false },
                        { name: `exitOffice`, required: true },
                    ],
                    props
                )}
            >
                <DeclarationInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    numbersAndLetters
                    {...getFormikProps(`ucr`, props)}
                    tooltip={getIrelandExportTooltipsByRefNumber('5')}
                    refNumber="7"
                    label={t('ucr')}
                    refClicked={handleToggleHelp}
                />

                <CountrySelect
                    viewOnly={props.viewOnly}
                    tooltip={getIrelandExportTooltipsByRefNumber('15a')}
                    refClicked={handleToggleHelp}
                    {...getFormikProps(`exportationCountry`, props)}
                    refNumber="15a"
                    label={t('countryOfDispatchCode')}
                />

                <CountrySelect
                    viewOnly={props.viewOnly}
                    tooltip={getIrelandExportTooltipsByRefNumber('17a')}
                    refClicked={handleToggleHelp}
                    {...getFormikProps(`destinationCountry`, props)}
                    refNumber="17a"
                    label={t('countryOfDestination')}
                />

                <DeclarationInput
                    maxLength={12}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`deferredPayment`, props)}
                    refNumber="48"
                    refClicked={handleToggleHelp}
                    tooltip={getIrelandExportTooltipsByRefNumber('48')}
                    label={t('deferredPayment')}
                />

                <CurrencySelect
                    {...getFormikProps(`currencyCode`, props)}
                    viewOnly={props.viewOnly}
                    refNumber="22.1"
                    refClicked={handleToggleHelp}
                    tooltip={getIrelandExportTooltipsByRefNumber('22.1')}
                />

                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`rateNumeric`, props)}
                    label={t('rateNumeric')}
                    refNumber="22.1"
                    refClicked={handleToggleHelp}
                />

                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`totalAmountInvoiced`, props)}
                    label={t('totalAmountInvoiced')}
                    refNumber="22.2"
                    refClicked={handleToggleHelp}
                    tooltip={getIrelandExportTooltipsByRefNumber('22.2')}
                />

                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`totalGrossMass`, props)}
                    label={t('totalGrossMass')}
                    refNumber="22.2"
                    refClicked={handleToggleHelp}
                    tooltip={getIrelandExportTooltipsByRefNumber('22.2')}
                />

                <DeclarationSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`firstNatureOfTransactionCode`, props)}
                    refNumber="24.1"
                    label={t('firstNatureOfTransaction')}
                    refClicked={handleToggleHelp}
                    tooltip={getIrelandExportTooltipsByRefNumber('24.1')}
                    selectOptions={natureOfTransaction}
                />

                <DeclarationSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`secondNatureOfTransactionCode`, props)}
                    refNumber="24.2"
                    refClicked={handleToggleHelp}
                    tooltip={getIrelandExportTooltipsByRefNumber('24.2')}
                    label={t('secondNatureOfTransaction')}
                    selectOptions={natureOfTransaction}
                />

                <DeclarationSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`exitOffice`, props)}
                    refNumber="29"
                    refClicked={handleToggleHelp}
                    tooltip={getIrelandExportTooltipsByRefNumber('29')}
                    label={t('exitOffice')}
                    selectOptions={OfficeOfExit}
                />
            </FormCard>
        </section>
    );
};

export default GoodsShipmentCard;
