import * as Yup from 'yup';
import {
    getNumberExactLengthValidation,
    getExactLengthValidation,
    getMaxValidation,
    getRequiredMessage,
    getNumberMaxDigitsValidation,
    getCustomerValidation,
    getFloatValidation,
} from '../../../../utils/validation-utils';
import {
    additionalInformations,
    additionDeductions,
    additionalSupplyChainActors,
    previousDocuments,
} from './UKCommonValidations';
import { ValidationType } from '../../../../../../utils/validations/transformValidations';

export const packagings = () =>
    Yup.object().shape({
        packageType: getExactLengthValidation('packageType', 2).nullable().required(getRequiredMessage('packageType')),
        numberOfPackages: getNumberMaxDigitsValidation('numberOfPackages', 8)
            .nullable()
            .required(getRequiredMessage('numberOfPackages')),
        shippingMarks: getMaxValidation('shippingMarks', 512).nullable().required(getRequiredMessage('shippingMarks')),
    });

export const documentsProduced = Yup.object().shape({
    documentCode: getExactLengthValidation('documentCode', 1).nullable(),
    documentCode2: getExactLengthValidation('documentCode2', 3).nullable(),
    documentId: getMaxValidation('documentId', 35).nullable(),
    status: getExactLengthValidation('status', 2).nullable(),
    statusReason: getMaxValidation('statusReason', 35).nullable(),
    writingOffIssuingAuthority: getMaxValidation('writingOffIssuingAuthority', 70).nullable(),
    writingOffDateOfValidity: Yup.date().nullable(),
    writingOffQuantity: getFloatValidation('writingOffQuantity', 16, 6).nullable(),
    writingOffMeasurementUnit: getMaxValidation('writingOffMeasurementUnit', 5).nullable(),
    writingOffMeasureUnitQualifiers: getMaxValidation('writingOffMeasureUnitQualifiers', 5).nullable(),
});

export const additionalTaricCodes = Yup.object().shape({
    additionalTaricCodeIdentifier: getExactLengthValidation('additionalTaricCodeIdentifier', 4)
        .required(getRequiredMessage('additionalTaricCodeIdentifier'))
        .nullable(),
    typeCode: getExactLengthValidation('typeCode', 3).required(getRequiredMessage('typeCode')).nullable(),
});

export const additionalNationalCodes = Yup.object().shape({
    additionalNationalCodeIdentifier: getExactLengthValidation('additionalNationalCodeIdentifier', 4)
        .required(getRequiredMessage('additionalNationalCodes'))
        .nullable(),
    typeCode: getExactLengthValidation('typeCode', 2).required(getRequiredMessage('typeCode')).nullable(),
});

const additionalProcedures = Yup.array()
    .of(
        Yup.object().shape({
            additionalProcedure: getExactLengthValidation('additionalProcedures', 3)
                .nullable()
                .required(getRequiredMessage('additionalProcedures')),
        })
    )
    .nullable();

const calculationOfTaxes = Yup.array()
    .of(
        Yup.object().shape({
            payableTaxAmount: getFloatValidation('payableTaxAmount', 16, 2).nullable(),
            payableTaxAmountCurrency: getMaxValidation('payableTaxAmountCurrency', 3).nullable(),
            taxType: getExactLengthValidation('taxType', 3).nullable(),
            taxBaseQuantity: getFloatValidation('taxBaseQuantity', 16, 6).nullable(),
            types: getMaxValidation('types', 6).nullable(),
            qualifiers: getMaxValidation('qualifiers', 6).nullable(),
            taxTotal: getFloatValidation('taxTotal', 16, 2).nullable(),
            taxTotalCurrency: getMaxValidation('taxTotalCurrency', 3).nullable(),
            method: getMaxValidation('method', 1).nullable(),
            preference: getNumberExactLengthValidation('preference', 3)
                .required(getRequiredMessage('preference'))
                .nullable(),
            taxRate: getFloatValidation('taxRate', 17, 3).nullable(),
        })
    )
    .nullable();

const containerIdentificationNumbers = Yup.array()
    .of(
        Yup.object().shape({
            containerIdentificationNumber: getMaxValidation('containerIdentificationNumber', 17).nullable(),
        })
    )
    .nullable();

export const ukImportProductValidationSchema = Yup.object().shape({
    additionalInformations: Yup.array().of(additionalInformations).nullable(),
    additionDeductions: Yup.array().of(additionDeductions).nullable(),
    additionalSupplyChainActors: Yup.array().of(additionalSupplyChainActors).nullable(),
    documentsProduced: Yup.array().of(documentsProduced).nullable(),
    additionalTaricCodes: Yup.array().of(additionalTaricCodes).nullable(),
    additionalNationalCodes: Yup.array().of(additionalNationalCodes).nullable(),
    additionalProcedures: Yup.array().of(additionalProcedures).nullable(),
    previousDocuments: Yup.array().of(previousDocuments).nullable(),
    calculationOfTaxes: Yup.array().of(calculationOfTaxes).nullable(),
    containerIdentificationNumbers: Yup.array().of(containerIdentificationNumbers).nullable(),
    packagings: Yup.array().of(packagings()).nullable(),
    taricCodeIdentifier: getExactLengthValidation('taricCodeIdentifier', 2)
        .nullable()
        .required(getRequiredMessage('taricCodeIdentifier')),
    requestedProcedure: getExactLengthValidation('requestedProcedure', 2)
        .nullable()
        .required(getRequiredMessage('requestedProcedure')),
    previousProcedure: getExactLengthValidation('previousProcedure', 2)
        .nullable()
        .required(getRequiredMessage('previousProcedure')),
    ucr: getMaxValidation('ucr', 35).nullable(),
    valuationIndicators: getMaxValidation('valuationIndicators', 4).nullable(),
    valuationMethod: getNumberExactLengthValidation('valuationMethod', 1)
        .required(getRequiredMessage('valuationMethod'))
        .nullable(),
    itemPriceAmount: getFloatValidation('itemPriceAmount', 16, 2).nullable(),
    itemPriceCurrency: getMaxValidation('itemPriceCurrency', 3).nullable(),
    countryOfOrigin: getMaxValidation('countryOfOrigin', 2).nullable(),
    countryOfOriginRole: getNumberExactLengthValidation('countryOfOriginRole', 1).nullable(),
    countryOfDestination: getMaxValidation('countryOfDestination', 2).nullable(),
    countryOfDispatchExport: getMaxValidation('countryOfDispatchExport', 2).nullable(),
    countryOfPreferentialOrigin: getMaxValidation('countryOfPreferentialOrigin', 2).nullable(),
    countryOfPreferentialOriginRole: getNumberExactLengthValidation('countryOfPreferentialOriginRole', 1).nullable(),
    commodityCusCodeIdentifier: getExactLengthValidation('commodityCusCodeIdentifier', 8).nullable(),
    commodityCusTypeCode: getExactLengthValidation('commodityCusTypeCode', 3).nullable(),
    commodityCombinedNomenclatureCodeIdentifier: getExactLengthValidation(
        'commodityCombinedNomenclatureCodeIdentifier',
        8
    )
        .required(getRequiredMessage('commodityCombinedNomenclatureCodeIdentifier'))
        .nullable(),
    commodityCombinedNomenclatureTypeCode: getExactLengthValidation('commodityCombinedNomenclatureTypeCode', 3)
        .required(getRequiredMessage('commodityCombinedNomenclatureTypeCode'))
        .nullable(),
    taricTypeCode: getExactLengthValidation('taricTypeCode', 3)
        .required(getRequiredMessage('taricTypeCode'))
        .nullable(),
    quotaOrderNumber: getExactLengthValidation('quotaOrderNumber', 6).nullable(),
    statisticalValue: getFloatValidation('statisticalValue', 16, 2)
        .nullable()
        .test('currency-provided', 'Statistical value must be provided as well', function (value) {
            if (!value && !!this.parent.statisticalValueCurrency) {
                return false;
            }
            return true;
        }),
    statisticalValueCurrency: getMaxValidation('statisticalValueCurrency', 3)
        .nullable()
        .test('value-provided', 'Statistical Value - Currency must be provided as well', function (value) {
            if (!value && !!this.parent.statisticalValue) {
                return false;
            }
            return true;
        }),
    seller: getCustomerValidation({ phoneNumber: { type: ValidationType.STRING, max: 50, nullable: true } }).nullable(),
    buyer: getCustomerValidation({ phoneNumber: { type: ValidationType.STRING, max: 50, nullable: true } }).nullable(),
    goodsItemNumber: getNumberMaxDigitsValidation('goodsItemNumber', 2)
        .nullable()
        .required('Goods item number is required.'),
    natureOfTransaction: getNumberMaxDigitsValidation('natureOfTransaction', 2)
        .nullable()
        .required(getRequiredMessage('natureOfTransaction')),
    goodsNetMass: getFloatValidation('goodsNetMass', 16, 6)
        .nullable()
        .test('netMass-not-larger-grossMass', 'Net mass cannot be larger than gross mass', function (value) {
            if (!value || !this.parent.goodsGrossMass) {
                return true;
            }
            const numberValue = parseFloat(value.toString());
            return this.parent.goodsGrossMass > numberValue;
        })
        .required(getRequiredMessage('goodsNetMass')),
    goodsSupplementaryUnits: getFloatValidation('goodsSupplementaryUnits', 16, 6).nullable(),
    goodsGrossMass: getFloatValidation('goodsGrossMass', 16, 6)
        .nullable()
        .required(getRequiredMessage('goodsGrossMass')),
    goodsDescription: getMaxValidation('goodsDescription', 512)
        .required(getRequiredMessage('goodsDescription'))
        .nullable(),
});

export default ukImportProductValidationSchema;
