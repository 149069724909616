import { FC, useState } from 'react';
import FilterButton from './components/FilterButton';
import FilterCard from './components/FilterCard';
import { FilterResult } from './filter-result';
import { updateFiltersList } from './filter-utils';

interface Props {
    options: string[];
    setShowFilterDropdown: Function;
    showFilterDropdown: boolean;
    filterResult: FilterResult[];
    setFilterResult: Function;
    statusChecked?: string[];
}
export const Filter: FC<Props> = ({
    options,
    setShowFilterDropdown,
    showFilterDropdown,
    filterResult,
    setFilterResult,
    statusChecked,
}) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [selectedFilter, setSelectedFilter] = useState<string>('');
    const [filterOptions, setFilterOptions] = useState<string[]>(options);

    const clearValues = () => {
        setInputValue('');
        setSelectedFilter('');
        setFilterOptions(options);
    };

    const onHandleClose = () => {
        setShowFilterDropdown(false);
        clearValues();
    };

    const onToggle = () => {
        setShowFilterDropdown((s: boolean) => !s);
        clearValues();
    };

    return (
        <>
            <FilterButton
                onClick={() => {
                    onToggle();
                }}
            />
            <FilterCard
                isDropdownActive={showFilterDropdown}
                options={options}
                handleClose={onHandleClose}
                onFilter={(filter) => {
                    updateFiltersList(filter, filterResult, setFilterResult);
                }}
                checked={statusChecked ?? []}
                inputValue={inputValue}
                setInputValue={setInputValue}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
            />
        </>
    );
};

export default Filter;
