export enum MeasureUnitQualifiers {
    A = 'A',
    B = 'B',
    C = 'C',
    E = 'E',
    F = 'F',
    G = 'G',
    I = 'I',
    J = 'J',
    K = 'K',
    L = 'L',
    M = 'M',
    P = 'P',
    R = 'R',
    S = 'S',
    T = 'T',
    X = 'X',
    Z = 'Z',
}

export const measureUnitQualifiersLabels = [
    { id: MeasureUnitQualifiers.A, value: 'A' },
    { id: MeasureUnitQualifiers.B, value: 'B' },
    { id: MeasureUnitQualifiers.C, value: 'C' },
    { id: MeasureUnitQualifiers.E, value: 'E' },
    { id: MeasureUnitQualifiers.F, value: 'F' },
    { id: MeasureUnitQualifiers.G, value: 'G' },
    { id: MeasureUnitQualifiers.I, value: 'I' },
    { id: MeasureUnitQualifiers.J, value: 'J' },
    { id: MeasureUnitQualifiers.K, value: 'K' },
    { id: MeasureUnitQualifiers.L, value: 'L' },
    { id: MeasureUnitQualifiers.M, value: 'M' },
    { id: MeasureUnitQualifiers.P, value: 'P' },
    { id: MeasureUnitQualifiers.R, value: 'R' },
    { id: MeasureUnitQualifiers.S, value: 'S' },
    { id: MeasureUnitQualifiers.T, value: 'T' },
    { id: MeasureUnitQualifiers.X, value: 'X' },
    { id: MeasureUnitQualifiers.Z, value: 'Z' },
] as const;
