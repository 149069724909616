import { Col, Row } from 'antd';
import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { normalizeCodesToSelect } from 'store/codelists/code';
import useCodelists from 'hooks/useCodelists';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';

interface Props extends BlockProps<'id' | 'categoryAndType'> {}

const DeferredPaymentBlock = ({ path = 'additionalDocument', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();

    const ucc = useMemo(() => '2/6', []);

    return (
        <Row gutter={18}>
            <Col xs={fieldOptions?.id?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="id"
                    validation={{
                        name: t('additionalDocumentID'),
                        number: true,
                        exact: 7,
                        required: true,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormInput
                            required
                            refNumber={ucc}
                            label={t('additionalDocumentID')}
                            {...getFormikProps(field.name, form)}
                        />
                    )}
                </DeclarationField>
            </Col>

            <Col xs={fieldOptions?.categoryAndType?.columnSpan ?? 24}>
                <DeclarationField
                    pathPrefix={path}
                    name="categoryAndType"
                    validation={{
                        name: t('Category/Type'),
                        max: 4,
                        required: true,
                    }}
                >
                    {({ form, field }: FieldProps<any>) => (
                        <FormSelect
                            required
                            refNumber={ucc}
                            label={t('Category/Type')}
                            {...getFormikProps(field.name, form)}
                            selectOptions={normalizeCodesToSelect(codelists?.ukDeferredPaymentDocuments || [])}
                        />
                    )}
                </DeclarationField>
            </Col>
        </Row>
    );
};

export default DeferredPaymentBlock;
