import * as Yup from 'yup';
import {
    getExactLengthValidation,
    getMaxValidation,
    getRequiredMessage,
    getNumberMaxDigitsValidation,
    getFloatValidation,
} from '../../../../utils/validation-utils';

/**
 * File has only the common objects
 * There are common fields in ValidationSchema and UKProductValidationSchema that are not here
 */

export const additionalInformations = Yup.object()
    .shape({
        additionalInformationText: getMaxValidation('additionalInformationCode', 512).nullable(),
        additionalInformationCode: getExactLengthValidation('additionalInformationCode', 5).nullable(),
    })
    .nullable();

export const additionDeductions = Yup.array()
    .of(
        Yup.object().shape({
            additionsDeductionsValue: getFloatValidation('additionsDeductionsValue', 16, 2).nullable(),
            additionsDeductionsCurrency: getExactLengthValidation('additionsDeductionsCurrency', 3).nullable(),
            type: getExactLengthValidation('type', 2).nullable(),
        })
    )
    .nullable();

export const additionalSupplyChainActors = Yup.array()
    .of(
        Yup.object().shape({
            additionalSupplyActorIdentifier: getMaxValidation('additionalSupplyActorIdentifier', 17).nullable(),
            roleCode: getMaxValidation('roleCode', 4).nullable(),
        })
    )
    .nullable();

export const previousDocuments = Yup.object()
    .shape({
        previousDocumentCategory: getExactLengthValidation('previousDocumentCategory', 1)
            .required(getRequiredMessage('previousDocumentCategory'))
            .nullable(),
        previousDocumentType: getMaxValidation('previousDocumentType', 3)
            .required(getRequiredMessage('previousDocumentType'))
            .nullable(),
        previousDocumentReference: getMaxValidation('previousDocumentReference', 35)
            .required(getRequiredMessage('previousDocumentReference'))
            .nullable(),
        goodsItemIdentifier: getNumberMaxDigitsValidation('goodsItemIdentifier', 3).nullable(),
    })
    .nullable();
