import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import { H5 } from 'components/ui/base/typography';
import FormInput from 'components/ui/composed/formInput/FormInput';
import FormSelect from 'components/ui/composed/formSelect/FormSelect';
import RadioCard from 'components/ui/composed/RadioCard';
import { useFormik } from 'formik';
import useCountries from 'hooks/useCountries';
import useDebouncedValidate from 'hooks/useDebouncedValidate';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SetupStyledComponents from 'views/setup/styles/Setup.styles';

const { Main, Container, ScreenTitle, ButtonContainer, ScreenTitleSubtitleContainer } = SetupStyledComponents;

interface FormInitialValues {
    addressUsage: 'mailingAsBillingAddress' | 'differentBillingAddress';
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    country: string;
    eirCode: string;
    nameOnCard: string;
    cardNumber: string;
    expirationDate: string;
    cvv: string;
    vatNumber: string;
}

const initialValues: FormInitialValues = {
    addressUsage: 'mailingAsBillingAddress',
    addressLine1: '',
    addressLine2: '',
    city: '',
    county: '',
    country: '',
    eirCode: '',
    nameOnCard: '',
    cardNumber: '',
    expirationDate: '',
    cvv: '',
    vatNumber: '',
};

const PaymentInfo = () => {
    const { t } = useTranslation(['weighbridge', 'common']);

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: () => {},
    });

    // TODO Get initial data

    useDebouncedValidate(formik);

    const { countries } = useCountries();

    const handleConfirm = (): void => {
        // TODO Order
        navigate('/weighbridges', { state: { payed: true } });
    };

    return (
        <Main>
            <Container>
                <ScreenTitleSubtitleContainer>
                    <ScreenTitle>{t('paymentInfo.title', { ns: 'weighbridge' })}</ScreenTitle>
                </ScreenTitleSubtitleContainer>
                <Row gutter={[30, 15]}>
                    <Col xl={12}>
                        <H5>{t('paymentInfo.billingAddress', { ns: 'weighbridge' })}</H5>
                    </Col>
                    <Col xl={12}>
                        <H5>{t('paymentInfo.cardDetails', { ns: 'weighbridge' })}</H5>
                    </Col>
                    <Col xl={12}>
                        <RadioCard
                            formik={formik}
                            formikProperty="addressUsage"
                            radioButtons={[
                                {
                                    title: 'Use Mailing Address As Billing Address',
                                    value: 'mailingAsBillingAddress',
                                },
                                {
                                    title: 'Use Different Billing Address',
                                    value: 'differentBillingAddress',
                                },
                            ]}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('paymentInfo.nameOnCard.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('nameOnCard')}
                            fieldMeta={formik.getFieldMeta('nameOnCard')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('companyDetails.addressLine1.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('addressLine1')}
                            fieldMeta={formik.getFieldMeta('addressLine1')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('paymentInfo.cardNumber.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('cardNumber')}
                            fieldMeta={formik.getFieldMeta('cardNumber')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('companyDetails.addressLine2.label', { ns: 'weighbridge' })}
                            fieldProps={formik.getFieldProps('addressLine2')}
                            fieldMeta={formik.getFieldMeta('addressLine2')}
                        />
                    </Col>
                    <Col xl={12}>
                        <Row gutter={12}>
                            <Col xl={12}>
                                <FormInput
                                    label={t('paymentInfo.expirationDate.label', { ns: 'weighbridge' })}
                                    required
                                    fieldProps={formik.getFieldProps('expirationDate')}
                                    fieldMeta={formik.getFieldMeta('expirationDate')}
                                />
                            </Col>
                            <Col xl={12}>
                                <FormInput
                                    label={t('paymentInfo.cvv.label', { ns: 'weighbridge' })}
                                    required
                                    fieldProps={formik.getFieldProps('cvv')}
                                    fieldMeta={formik.getFieldMeta('cvv')}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('companyDetails.city.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('city')}
                            fieldMeta={formik.getFieldMeta('city')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('paymentInfo.vatNumber.label', { ns: 'weighbridge' })}
                            fieldProps={formik.getFieldProps('vatNumber')}
                            fieldMeta={formik.getFieldMeta('vatNumber')}
                        />
                    </Col>
                    <Col xl={12}>
                        <FormInput
                            label={t('companyDetails.county.label', { ns: 'weighbridge' })}
                            required
                            fieldProps={formik.getFieldProps('county')}
                            fieldMeta={formik.getFieldMeta('county')}
                        />
                    </Col>
                    <Col xl={{ span: 12, offset: 1, pull: 1 }}>
                        <Row gutter={15}>
                            <Col md={12}>
                                <FormSelect
                                    label={t('companyDetails.country.label', { ns: 'weighbridge' })}
                                    required
                                    fieldProps={formik.getFieldProps('country')}
                                    fieldMeta={formik.getFieldMeta('country')}
                                    selectOptions={countries.list.map((obj) => {
                                        return {
                                            id: obj.countryCode,
                                            value: obj.name,
                                        };
                                    })}
                                />
                            </Col>
                            <Col md={12}>
                                <FormInput
                                    label={t('companyDetails.eirCode.label', { ns: 'weighbridge' })}
                                    required
                                    fieldProps={formik.getFieldProps('eirCode')}
                                    fieldMeta={formik.getFieldMeta('eirCode')}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <ButtonContainer>
                    <Button onClick={() => navigate(-1)}>{t('buttons.back', { ns: 'common' })}</Button>
                    <Button type="primary" onClick={handleConfirm}>
                        {t('buttons.confirmPaymentDetails', { ns: 'common' })}
                    </Button>
                </ButtonContainer>
            </Container>
        </Main>
    );
};

export default PaymentInfo;
