import {
    getAllCodeLists,
    getIrelandAdditionalInformationCodes,
    getIrelandAdditionalProcedures,
    getIrelandAdditionsDeductionsCodes,
    getIrelandAuthorisationTypeCodes,
    getIrelandBaseMeasurements,
    getIrelandCommonDocumentsTypes,
    getIrelandDeliveryDestinationLocationCodes,
    getIrelandExportProcedureCategories,
    getIrelandGuaranteeTypes,
    getIrelandIdentificationOfGoodsCodes,
    getIrelandLocationOfGoodsCodes,
    getIrelandMeansIdentityTypes,
    getIrelandNationalCustomsOfficeCodes,
    getIrelandNatureOfTransactions,
    getIrelandOfficeOfExit,
    getIrelandPackageKinds,
    getIrelandPreferences,
    getIrelandPreviousDocumentTypes,
    getIrelandProcedureCodes,
    getIrelandProcessingProcedureCodes,
    getIrelandRepresentativeStatusCodes,
    getIrelandTaricNationalAdditionalCodes,
    getIrelandTaxTypes,
    getIrelandTransportModes,
    getIrelandValuationMethods,
    getUkAirports,
    getUkAllowedDocuments,
    getUkContainerCategories,
    getUkCountryGroupCategories,
    getUkDeclaredGuaranteeTypes,
    getUkDeferredPaymentDocuments,
    getUkDocumentCategory,
    getUkDutyTaxFeeTypes,
    getUkExitCustomsOffices,
    getUkExportCertificates,
    getUkExportPreviousDocuments,
    getUkExportSpecialMentions,
    getUkImportCertificates,
    getUkImportPreviousDocuments,
    getUkImportPreviousProcedures,
    getUkImportRequestedProcedures,
    getUkImportSpecialMentions,
    getUkPackageTypes,
    getUkPartyRoleStatusTypes,
    getUkPaymentMethodTypes,
    getUkPreferences,
    getUkProcedureCombinations,
    getUkSpecialProcedures,
    getUkSupervisingCustomsOffices,
    getUkTransactionNatureTypes,
    getUkTransportMeansIdentificationTypes,
    getUkTransportModeTypes,
    getUkUnLocodes,
    getUkValuationIndicatorTypes,
    getUkValuationMethodTypes,
} from './client';
import { Code, CodeList } from './code';
import { CodelistType } from './reducer';

export const doGetAllCodelists: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'GET_ALL_CODELISTS_REQUEST' });
    try {
        const all: CodeList = await getAllCodeLists();
        // missing in request be
        const ukDeferredPaymentDocuments = (await getUkDeferredPaymentDocuments()).list;
        const uk = { ...all.uk };
        const ireland = { ...all.ireland };
        const ukKeys = Object.keys(uk).map((k) => `uk${k[0].toUpperCase()}${k.slice(1)}`);
        const ukValues = Object.values(uk);
        const irelandKeys = Object.keys(ireland).map((k) => `ireland${k[0].toUpperCase()}${k.slice(1)}`);
        const irelandValues = Object.values(ireland);

        const ukData = ukValues.reduce((acc, code, index) => {
            return { ...acc, [ukKeys[index]]: code };
        }, {});

        const irelandData = irelandValues.reduce((acc, code, index) => {
            return { ...acc, [irelandKeys[index]]: code };
        }, {});

        const data = { ...ukData, ukDeferredPaymentDocuments: ukDeferredPaymentDocuments, ...irelandData } as Record<
            CodelistType,
            Code[]
        >;
        dispatch({ type: 'GET_ALL_CODELISTS_SUCCESS', payload: data });
    } catch (e: any) {
        dispatch({ type: 'GET_ALL_CODELISTS_ERROR', error: e?.response?.data });
    }
};

export const doGetCodelists: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'GET_CODELISTS_REQUEST' });
    try {
        // TODO this could be done with Promise.all to fetch them in parallel
        // also one failing results in action failing...
        const data: Record<CodelistType, Code[]> = {
            ukValuationMethodTypes: (await getUkValuationMethodTypes()).list,
            ukValuationIndicatorTypes: (await getUkValuationIndicatorTypes()).list,
            ukUnlocodes: (await getUkUnLocodes()).list,
            ukTransportModeTypes: (await getUkTransportModeTypes()).list,
            ukTransportMeansIdentificationTypes: (await getUkTransportMeansIdentificationTypes()).list,
            ukTransactionNatureTypes: (await getUkTransactionNatureTypes()).list,
            ukSupervisingCustomsOffices: (await getUkSupervisingCustomsOffices()).list,
            ukSpecialProcedureTypes: (await getUkSpecialProcedures()).list,
            ukProcedureCombinations: (await getUkProcedureCombinations()).list,
            ukPreferences: (await getUkPreferences()).list,
            ukPartyRoleStatusTypes: (await getUkPartyRoleStatusTypes()).list,
            ukPackageTypes: (await getUkPackageTypes()).list,
            ukImportSpecialMentions: (await getUkImportSpecialMentions()).list,
            ukImportRequestedProcedures: (await getUkImportRequestedProcedures()).list,
            ukImportPreviousProcedures: (await getUkImportPreviousProcedures()).list,
            ukImportCertificates: (await getUkImportCertificates()).list,
            ukExportSpecialMentions: (await getUkExportSpecialMentions()).list,
            ukExportPreviousDocuments: (await getUkExportPreviousDocuments()).list,
            ukExportCertificates: (await getUkExportCertificates()).list,
            ukExitCustomsOffices: (await getUkExitCustomsOffices()).list,
            ukDutyTaxFeeTypes: (await getUkDutyTaxFeeTypes()).list,
            ukDeferredPaymentDocuments: (await getUkDeferredPaymentDocuments()).list,
            ukDeclaredGuaranteeTypes: (await getUkDeclaredGuaranteeTypes()).list,
            ukCountryGroupCategories: (await getUkCountryGroupCategories()).list,
            ukContainerCategories: (await getUkContainerCategories()).list,
            ukAllowedAdditionalDocuments: (await getUkAllowedDocuments()).list,
            ukAirports: (await getUkAirports()).list,
            ukPaymentMethodTypes: (await getUkPaymentMethodTypes()).list,
            ukImportPreviousDocuments: (await getUkImportPreviousDocuments()).list,
            ukDocumentCategories: (await getUkDocumentCategory()).list,
            irelandValuationMethods: (await getIrelandValuationMethods()).list,
            irelandTransportModes: (await getIrelandTransportModes()).list,
            irelandTaxTypes: (await getIrelandTaxTypes()).list,
            irelandTaricNationalAdditionalCodes: (await getIrelandTaricNationalAdditionalCodes()).list,
            irelandRepresentativeStatusCodes: (await getIrelandRepresentativeStatusCodes()).list,
            irelandProcessingProcedureCodes: (await getIrelandProcessingProcedureCodes()).list,
            irelandProcedureCodes: (await getIrelandProcedureCodes()).list,
            irelandPreviousDocumentTypes: (await getIrelandPreviousDocumentTypes()).list,
            irelandIrishPreferences: (await getIrelandPreferences()).list,
            irelandPackageKinds: (await getIrelandPackageKinds()).list,
            irelandNatureOfTransactions: (await getIrelandNatureOfTransactions()).list,
            irelandNationalCustomsOfficeCodes: (await getIrelandNationalCustomsOfficeCodes()).list,
            irelandMeansIdentityTypes: (await getIrelandMeansIdentityTypes()).list,
            irelandLocationOfGoodsCodes: (await getIrelandLocationOfGoodsCodes()).list,
            irelandIdentificationOfGoodsCodes: (await getIrelandIdentificationOfGoodsCodes()).list,
            irelandGuaranteeTypes: (await getIrelandGuaranteeTypes()).list,
            irelandCommonDocumentsTypes: (await getIrelandCommonDocumentsTypes()).list,
            irelandBaseMeasurements: (await getIrelandBaseMeasurements()).list,
            irelandAuthorisationTypeCodes: (await getIrelandAuthorisationTypeCodes()).list,
            irelandAdditionsDeductionsCodes: (await getIrelandAdditionsDeductionsCodes()).list,
            irelandAdditionalProcedures: (await getIrelandAdditionalProcedures()).list,
            irelandAdditionalInformationCodes: (await getIrelandAdditionalInformationCodes()).list,
            irelandDeliveryDestinationLocationCodes: (await getIrelandDeliveryDestinationLocationCodes()).list,
            irelandOfficesOfExit: (await getIrelandOfficeOfExit()).list,
            irelandExportProcedureCategories: (await getIrelandExportProcedureCategories()).list,
        };

        dispatch({ type: 'GET_CODELISTS_SUCCESS', payload: data });
    } catch (e: any) {
        dispatch({ type: 'GET_CODELISTS_ERROR', error: e?.response?.data });
    }
};
