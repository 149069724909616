import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { locationTypesLabels } from 'store/declarations/enums/common/location-types';
import AddressForm from 'views/declarations/common/AddressForm';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getAddressFormikProps, getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const LocationOfGoodsCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('goodsLocation')}>
            <FormCardContainer>
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsLocationAddId`, props)}
                    refNumber="5.23"
                    label={t('goodsLocationAddId')}
                    tooltip={getCdsTooltipsByRefNumberAndField('5.23', t('goodsLocationAddId'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsLocationId`, props)}
                    refNumber="5.23"
                    label={t('goodsLocationId')}
                    tooltip={getCdsTooltipsByRefNumberAndField('5.23', t('goodsLocationId'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <FormSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsLocationType`, props)}
                    refNumber="5.23"
                    label={t('goodsLocationType')}
                    selectOptions={locationTypesLabels}
                    tooltip={getCdsTooltipsByRefNumberAndField('5.23', t('goodsLocationType'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />

                <AddressForm
                    countryRequired
                    declarationCountry={DeclarationCountry.UK}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    viewOnly={props.viewOnly}
                    {...getAddressFormikProps(
                        {
                            streetAndNumber: 'goodsLocationStreetNumber',
                            country: 'goodsLocationCountry',
                            postCode: 'goodsLocationPostcode',
                            city: 'goodsLocationCity',
                        },
                        props
                    )}
                    refNumbers={{ streetAndNumber: '5.23', city: '5.23', country: '5.23', postcode: '5.23' }}
                    condensed
                />

                <FormInput
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsLocationQualifier`, props)}
                    refNumber="5.23"
                    label={t('goodsLocationQualifier')}
                    tooltip={getCdsTooltipsByRefNumberAndField('5.23', t('goodsLocationQualifier'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default LocationOfGoodsCard;
