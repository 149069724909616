import DeclarationDatepicker from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { documentStatus } from 'store/declarations/enums/uk/documents-category-code';
import { ValidationSchema, validators } from '../validations/validations';
import DeclarationNumberInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationNumberInput';
import { DeclarationBlock } from '../../../../../utils/DeclarationBlock';
import documentCode from 'store/declarations/enums/uk/document-code';

export type Fields =
    | 'code'
    | 'id'
    | 'lpcoExemptionCode'
    | 'name'
    | 'effectiveDateTime'
    | 'submitter.name'
    | 'writeOff.amountAmount'
    | 'writeOff.quantityQuantity';

export const additionalDocumentBlockValidation: {
    childValidators: {
        additionalDocument: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        additionalDocument: {
            childValidators: {
                code: [validators.required(), validators.exact(4)],
                id: [validators.max(35)],
                lpcoExemptionCode: [validators.exact(2)],
                name: [validators.max(35)],
                effectiveDateTime: [],
                'submitter.name': [validators.max(70)],
                'writeOff.amountAmount': [validators.max(5)],
                'writeOff.quantityQuantity': [validators.number(), validators.float(16, 6)],
            },
            stopAtNull: true,
        },
    },
};

export const additionalDocumentFields = additionalDocumentBlockValidation.childValidators.additionalDocument;

interface Props extends BlockProps<Fields> {}

const AdditionalDocumentBlock = ({ path = 'additionalDocument', fieldOptions, hide }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const ucc = useMemo(() => '8/7', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="code">
                {({ form, field }) => (
                    <FormSelect
                        required
                        {...getFormikProps(field.name, form)}
                        label={t('documentsProduced.code')}
                        refNumber={ucc}
                        selectOptions={documentCode}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }) => (
                    <FormInput
                        maxLength={35}
                        refNumber={ucc}
                        {...getFormikProps(field.name, form)}
                        label={t('documentsProduced.identifier')}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="lpcoExemptionCode">
                {({ form, field }) => (
                    <FormSelect
                        {...getFormikProps(field.name, form)}
                        label={t('documentsProduced.status')}
                        refNumber={ucc}
                        selectOptions={documentStatus}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="name">
                {({ form, field }) => (
                    <FormInput
                        maxLength={35}
                        refNumber={ucc}
                        {...getFormikProps(field.name, form)}
                        label={t('documentsProduced.reason')}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationBlock hide={hide}>
                <DeclarationField pathPrefix={path} name="effectiveDateTime">
                    {({ form, field }) => (
                        <DeclarationDatepicker
                            refNumber={ucc}
                            {...getFormikProps(field.name, form)}
                            label={t('writingOff.dateOfValidity')}
                            condensed
                        />
                    )}
                </DeclarationField>
                <DeclarationField pathPrefix={path} name="submitter.name">
                    {({ form, field }) => (
                        <FormInput
                            maxLength={70}
                            refNumber={ucc}
                            {...getFormikProps(field.name, form)}
                            label={t('writingOff.issuingAuthority')}
                            condensed
                        />
                    )}
                </DeclarationField>
                <DeclarationField pathPrefix={path} name="writeOff.amountAmount">
                    {({ form, field }) => (
                        <FormInput
                            maxLength={5}
                            refNumber={ucc}
                            {...getFormikProps(field.name, form)}
                            label={t('writingOff.measurementUnitAndQualifier')}
                            condensed
                        />
                    )}
                </DeclarationField>
                <DeclarationField pathPrefix={path} name="writeOff.quantityQuantity">
                    {({ form, field }) => (
                        <DeclarationNumberInput
                            maxLength={22}
                            refNumber={ucc}
                            {...getFormikProps(field.name, form)}
                            label={t('writingOff.quantity')}
                            condensed
                        />
                    )}
                </DeclarationField>
            </DeclarationBlock>
        </>
    );
};

export default AdditionalDocumentBlock;
