import { FormikProps } from 'formik';
import addPathPrefix from '../../../../utils/addPathPrefix';
import { set } from 'lodash';

export const levelHasValues = (
    partyPath: string | undefined,
    toggledLevelFieldPaths: any,
    formik: FormikProps<any> | undefined
) =>
    Object.values(toggledLevelFieldPaths).some((fieldPath) => {
        if (typeof fieldPath !== 'string' || !partyPath) return false;

        return formik?.getFieldMeta(addPathPrefix(partyPath, fieldPath)).value !== undefined;
    });

export const deleteLevelValues = (
    partyPath: string | undefined,
    toggledLevelFieldPaths: any,
    formik: FormikProps<any> | undefined
) => {
    if (typeof partyPath !== 'string') return;

    const deletedToggledLevelFieldPaths = Object.values(toggledLevelFieldPaths).reduce((acc: {}, fieldPath) => {
        if (typeof fieldPath !== 'string') return acc;

        return set(acc, fieldPath, undefined);
    }, formik?.getFieldProps(partyPath).value);

    return formik?.getFieldHelpers(partyPath).setValue(deletedToggledLevelFieldPaths);
};
