import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import useTooltips from 'hooks/useTooltips';
import { FC, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { taxPaymentMethodLabels } from 'store/declarations/enums/ireland/tax-payment-method';
import {
    getBox44FieldPath,
    h1Box44KeyNames,
    h1PathBox44,
    handleBox44Field,
} from 'views/declarations/common/box44/box-44-utils';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import SearchCustomer from 'views/declarations/common/search-customer/SearchCustomer';
import useSearchCustomer from 'views/declarations/common/search-customer/useSearchCustomer';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';
import FormEori from 'components/ui/composed/formEori/FormEori';
import ModalExchangeRating from 'views/declarations/common/ModalExchangeRating';
import { Exchange } from 'store/exchanges/exchange';
import useExchanges from 'hooks/useExchanges';

interface ExchangeRatingModalState {
    open: boolean;
    currency: Exchange | undefined;
}

const ValueDetailsCard: FC<DeclarationFormCardProps> = (props) => {
    const { viewOnly } = props;
    const { t } = useTranslation('form');
    const { getH1TooltipsByRefNumberAndField, getH1TooltipsByRefNumber } = useTooltips();
    const { template, form, templateFormik } = useTemplateContext();

    const { exchanges } = useExchanges();
    const [exchangeRatingModal, setExchangeRatingModal] = useReducer(
        (state: ExchangeRatingModalState, newState: Partial<ExchangeRatingModalState>) => ({
            ...state,
            ...newState,
        }),
        { open: false, currency: undefined }
    );

    const {
        handleSelectCustomer,
        show: showSearchCustomerDrawer,
        triggerShowDrawer: triggerShowSearchCustomerDrawer,
        triggerHideDrawer: triggerHideSearchCustomerDrawer,
    } = useSearchCustomer();

    return (
        <section
            id="value-details-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('value-details-form-card');
                }
            }}
            data-testid="value-details-form-card"
        >
            <NewFormCard title={t('Value details')}>
                <FormCardContainer>
                    <CurrencySelect
                        required
                        label="invoiceCurrency"
                        viewOnly={viewOnly}
                        {...getFormikProps(`invoiceCurrency`, props)}
                        onChange={(value) => {
                            const dt = new Date();
                            const fullMonth = dt.toLocaleDateString('en-US', { month: 'long' });
                            const currency = exchanges.find(
                                (exchange) => exchange.currency.code === value && exchange.month === fullMonth
                            );

                            if (currency) {
                                setExchangeRatingModal({ open: true, currency });
                            }
                        }}
                        refNumber="4.10"
                        tooltip={getH1TooltipsByRefNumber('4.10')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        testId="invoice-currency"
                        condensed
                    />
                    <ModalExchangeRating
                        isOpen={exchangeRatingModal.open}
                        close={() => {
                            setExchangeRatingModal({ open: false });
                        }}
                        onConfirm={(exchange: Exchange) => {
                            props.getFieldHelpers('exchangeRate').setValue(exchange.rate);
                        }}
                        currency={exchangeRatingModal.currency}
                    />

                    <DeclarationNumberInput
                        viewOnly={viewOnly}
                        {...getFormikProps(`invoiceAmount`, props)}
                        refNumber="4.11"
                        label={t('invoiceAmount')}
                        testId="invoiceAmount"
                        tooltip={getH1TooltipsByRefNumberAndField('4.11', t('invoiceAmount'))}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <DeclarationNumberInput
                        refNumber="4.15"
                        viewOnly={viewOnly}
                        {...getFormikProps(`exchangeRate`, props)}
                        label={t('exchangeRate')}
                        testId="exchangeRate"
                        tooltip={getH1TooltipsByRefNumber('4.15')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormSelect
                        required
                        viewOnly={viewOnly}
                        {...getFormikProps(`preferredPaymentMethod`, props)}
                        refNumber="4.8"
                        label={t('preferredPaymentMethod')}
                        selectOptions={taxPaymentMethodLabels}
                        tooltip={getH1TooltipsByRefNumber('4.8')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        testId="prefered-payment-method"
                        condensed
                    />

                    <FormEori
                        required
                        maxLength={17}
                        viewOnly={viewOnly}
                        {...getFormikProps(`personPayingCustomsDuty`, props)}
                        refNumber="3.46"
                        label={t('personPayingCustomsDuty')}
                        tooltip={getH1TooltipsByRefNumber('3.46')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        testId="customs-duty-payer"
                        condensed
                        onSearchCustomer={({ fieldHelpers }) => {
                            triggerShowSearchCustomerDrawer((customer) => {
                                fieldHelpers?.setValue(customer.eori);
                            });
                        }}
                    />

                    <FormInput
                        numbersAndLetters
                        maxLength={255}
                        viewOnly={viewOnly}
                        testId="dv1Declaration"
                        {...getFormikProps(
                            getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: 'N934' }),
                            props
                        )}
                        onChange={(e) =>
                            handleBox44Field(
                                e.target.value,
                                template && templateFormik ? templateFormik : props,
                                {
                                    path: h1PathBox44,
                                    keyNames: h1Box44KeyNames,
                                    type: 'N934',
                                },
                                { template, form }
                            )
                        }
                        refNumber="N934"
                        label={t('dv1Declaration')}
                        tooltip={getH1TooltipsByRefNumber('N934')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <FormInput
                        numbersAndLetters
                        maxLength={255}
                        viewOnly={viewOnly}
                        {...getFormikProps(
                            getBox44FieldPath({ path: h1PathBox44, keyNames: h1Box44KeyNames, type: '1A01' }),
                            props
                        )}
                        onChange={(e) =>
                            handleBox44Field(
                                e.target.value,
                                template && templateFormik ? templateFormik : props,
                                {
                                    path: h1PathBox44,
                                    keyNames: h1Box44KeyNames,
                                    type: '1A01',
                                },
                                { template, form }
                            )
                        }
                        refNumber="1A01"
                        testId="vatFreeAuthorisation"
                        label={t('vatFreeAuthorisation')}
                        tooltip={getH1TooltipsByRefNumber('1A01')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        condensed
                    />

                    <SearchCustomer.Drawer
                        onClose={triggerHideSearchCustomerDrawer}
                        show={showSearchCustomerDrawer}
                        onSelectCustomer={handleSelectCustomer}
                    />
                </FormCardContainer>
            </NewFormCard>
        </section>
    );
};

export default ValueDetailsCard;
