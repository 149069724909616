export const additionsDeductionsTypes = [
    { id: 'AB', value: '' },
    { id: 'AC', value: '' },
    { id: 'AD', value: '' },
    { id: 'AE', value: '' },
    { id: 'AF', value: '' },
    { id: 'AG', value: '' },
    { id: 'AH', value: '' },
    { id: 'AI', value: '' },
    { id: 'AJ', value: '' },
    { id: 'AK', value: '' },
    { id: 'AL', value: '' },
    { id: 'AM', value: '' },
    { id: 'AP', value: '' },
    { id: 'AQ', value: '' },
    { id: 'AR', value: '' },
    { id: 'AS', value: '' },
    { id: 'AT', value: '' },
    { id: 'AV', value: '' },
    { id: 'AW', value: '' },
    { id: 'AX', value: '' },
    { id: 'AZ', value: '' },
    { id: 'BA', value: '' },
    { id: 'BB', value: '' },
    { id: 'BD', value: '' },
    { id: 'BF', value: '' },
    { id: 'BH', value: '' },
    { id: 'BI', value: '' },
    { id: 'BL', value: '' },
    { id: 'BM', value: '' },
    { id: 'BR', value: '' },
    { id: 'BS', value: '' },
    { id: 'BT', value: '' },
    { id: 'BU', value: '' },
];
