import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    FileOutlined,
    SaveOutlined,
    EyeOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { colors } from 'theme';
import { Button as AButton } from 'antd';

export const MainDiv = styled.div`
    background: ${colors.white};
    padding: 2.4rem;
    border: 1px solid ${colors.darkGrey2};
    box-sizing: border-box;
    border-radius: 0.4rem;
    margin-bottom: 4rem;
    filter: drop-shadow(0px 0px 24px rgba(9, 0, 255, 0.07));
`;

export const StyledFileOutlined = styled(FileOutlined)`
    font-size: 2.2rem;
    margin-right: 2.4rem;
`;

export const StyledDownloadOutlined = styled(DownloadOutlined)`
    font-size: 2.2rem;
    margin-left: 1.778rem;
    cursor: pointer;
    &:hover {
        color: ${colors.purpleBlue};
    }
`;

export const StyledEyeOutlined = styled(EyeOutlined)`
    font-size: 2.2rem;
    margin-left: 1.778rem;
    cursor: pointer;
    &:hover {
        color: ${colors.darkPurple};
    }
`;

export const StyledSaveOutlined = styled(SaveOutlined)`
    font-size: 2.2rem;
    margin-left: 1.778rem;
    cursor: pointer;
    &:hover {
        color: ${colors.darkPurple};
    }
`;

export const StyledEditOutlined = styled(EditOutlined)`
    font-size: 2.2rem;
    margin-left: 1.778rem;
    cursor: pointer;
    &:hover {
        color: ${colors.purpleBlue};
    }
`;

export const StyledDeleteOutlined = styled(DeleteOutlined)`
    font-size: 2.2rem;
    margin-left: 1.778rem;
    cursor: pointer;
    &:hover {
        color: ${colors.purpleBlue};
    }
`;

export const DeclarationInformationWrapper = styled.div`
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: solid 2px ${colors.lightGrey5};
`;

export const DocumentUploadButton = styled(AButton)`
    padding: 0 1rem;
    border-radius: 4px;
    border-color: ${colors.blueLink};

    font-weight: 400;

    :focus,
    :hover {
        background-color: #0041e4dc;
        color: white;
    }
`;

export const Container = styled.div<{ view: string | undefined }>`
    display: grid;
    grid-template-columns: 10px 1.2fr 0.6fr 1fr 0.6fr 1.2fr 0.2fr;
    grid-gap: 20px;

    ${({ view }) =>
        view === 'archiveView' &&
        `display: flex;
        gap: 3rem;
        flex: 1;`}

    ${({ view }) =>
        view === 'submittedDocs' &&
        `
        grid-template-columns: 1.2fr 0.6fr 1fr 0.6fr 1.2fr 0.2fr;
        `}

    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 1.5rem;
`;

export const DocumentFormButtonsDiv = styled.div`
    display: flex;
    justify-content: end;
    gap: 15px;
`;
