import * as Yup from 'yup';
import {
    getCustomerValidation,
    CUSTOMS_OFFICE,
    getEoriValidation,
    getExactLengthValidation,
    getMaxValidation,
    getRequiredMessage,
    invalidEoriMessage,
    TARIC_CODE_TYPE,
    getFloatValidation,
    getNumberOfItemsValidation,
    getNumberMaxDigitsValidation,
    getMinCharactersValidation,
} from '../../../../utils/validation-utils';
import { IrelandImportDeclaration } from 'store/declarations/ireland/import-declaration';

const addressSchema = (required: boolean) =>
    required
        ? Yup.object({
              postCode: getMaxValidation('postCode', 9).required(getRequiredMessage('postCode')).nullable(),
              streetAndNumber: getMaxValidation('streetAndNumber', 70)
                  .required(getRequiredMessage('streetAndNumber'))
                  .nullable(),
              city: getMaxValidation('city', 35).required(getRequiredMessage('city')).nullable(),
              country: getMaxValidation('country', 2).required(getRequiredMessage('country')).nullable(),
          })
        : Yup.object({
              postCode: getMaxValidation('postCode', 9).nullable(),
              streetAndNumber: getMaxValidation('streetAndNumber', 70).nullable(),
              city: getMaxValidation('city', 35).nullable(),
              country: getMaxValidation('country', 2).nullable(),
          });

export const firstPlaceOfUse = () =>
    Yup.object().shape({
        identificationOfLocation: getMaxValidation('identificationOfLocation', 35).nullable(),
        qualifierIdentification: getExactLengthValidation('qualifierIdentification', 1).nullable(),
        additionalIdentifier: getExactLengthValidation('additionalIdentifier', 3).nullable(),
        locationTypeCode: getExactLengthValidation('locationTypeCode', 1).nullable(),
        address: addressSchema(false).nullable(),
    });

const placeOfUse = () => {
    return Yup.array().of(firstPlaceOfUse());
};

const ownerOfTheGoodsSchema = () => Yup.array().of(getCustomerValidation());

const authorisationSchema = Yup.object({
    customsOfficeOfDischarge: Yup.string().matches(CUSTOMS_OFFICE, invalidEoriMessage).nullable(),

    supervisingCustomsOffice: Yup.string().matches(CUSTOMS_OFFICE, invalidEoriMessage).nullable(),
    period: getMaxValidation('period', 2).nullable(),
    periodDetails: getMaxValidation('periodDetails', 512).nullable(),
    billOfDischargeDeadline: getMaxValidation('billOfDischargeDeadline', 2).nullable(),
    billOfDischargeDetails: getMaxValidation('billOfDischargeDetails', 512).nullable(),
    detailsOfPlannedActivities: getMaxValidation('detailsOfPlannedActivities', 512).nullable(),
    additionalInformation: getMaxValidation('additionalInformation', 512).nullable(),
    firstPlaceOfUse: firstPlaceOfUse().nullable(),
    placeOfUse: placeOfUse().nullable(),
    ownerOfTheGoods: ownerOfTheGoodsSchema().nullable(),
    rateOfYield: getMaxValidation('rateOfYield', 512).nullable(),
    processedProductsCommodityCode: getMaxValidation('processedProductsCommodityCode', 8).nullable(),
    processedProductsDescriptionOfGoods: getMaxValidation('processedProductsDescriptionOfGoods', 512).nullable(),
    identificationOfGoodsCode: getExactLengthValidation('identificationOfGoodsCode', 1).nullable(),
    identification: getMaxValidation('identification', 512).nullable(),
    economicConditionsProcessingProcedure: getMaxValidation('economicConditionsProcessingProcedure', 2).nullable(),
    economicConditionsDetails: getMaxValidation('economicConditionsDetails', 512).nullable(),
});

export const additionalInformationSchema = () =>
    Yup.object().shape({
        additionalInformationCode: getExactLengthValidation('additionalInformationCode', 5).nullable(),
        additionalInformationText: getMaxValidation('additionalInformationText', 512).nullable(),
    });
export const additionalInformationListSchema = () => Yup.array().of(additionalInformationSchema());

export const simplifiedDeclarationDocumentWritingOffObject = Yup.object().shape({
    previousDocumentType: getMaxValidation('previousDocumentType', 5).nullable(),
    previousDocumentIdentifier: getMaxValidation('previousDocumentIdentifier', 35).nullable(),
    previousDocumentLineId: getMaxValidation('previousDocumentLineId', 5).nullable(),
});

const simplifiedDeclarationDocumentWritingOff = () =>
    Yup.array()
        .of(simplifiedDeclarationDocumentWritingOffObject)
        .test({
            name: 'at-least-one-previous-document',
            message: 'At least one previous document is required',
            test: (value, context) => {
                const data: IrelandImportDeclaration = (context as any).from.at(-1).value;
                const atLeastOneRequired = ['A', 'D', 'Y'].includes(data.additionalDeclarationType ?? '');
                if (atLeastOneRequired && !value?.length) return false;
                return true;
            },
        });

const additionsDeductions = () =>
    Yup.array().of(
        Yup.object().shape({
            additionsDeductionsCode: getExactLengthValidation('additionsDeductionsCode', 2).nullable(),
            amount: getMaxValidation('amount', 16).nullable(),
        })
    );

const supplyChainActor = () =>
    Yup.array().of(
        Yup.object().shape({
            roleCode: getMaxValidation('roleCode', 3).nullable(),
            traderIdentification: getMaxValidation('traderIdentification', 17).nullable(),
        })
    );

export const packaging = () =>
    Yup.object().shape({
        typePackage: getMaxValidation('typePackage', 2).required(getRequiredMessage('typePackage')).nullable(),
        packageNumber: getNumberMaxDigitsValidation('packageNumber', 8)
            .required(getRequiredMessage('packageNumber'))
            .nullable(),
        shippingMarks: getMaxValidation('shippingMarks', 512).required(getRequiredMessage('shippingMarks')).nullable(),
    });

const nationalAdditionalCommodityCode = () =>
    Yup.array().of(getMaxValidation('nationalAdditionalCommodityCode', 4).nullable());

const goodsInformation = () =>
    Yup.object().shape({
        netMass: getMaxValidation('netMass', 16)
            .required(getRequiredMessage('netMass'))
            .nullable()
            .test('netMass-not-larger-grossMass', 'Net mass cannot be larger than gross mass', function (value) {
                if (!value || !this.parent.grossMass) {
                    return true;
                }
                const numberValue = parseFloat(value);
                return this.parent.grossMass >= numberValue;
            }),
        supplementaryUnits: getMaxValidation('supplementaryUnits', 16).nullable(),
        grossMass: getFloatValidation('grossMass', 16, 6).required(getRequiredMessage('grossMass')).nullable(),
        goodsDescription: getMaxValidation('goodsDescription', 512)
            .required(getRequiredMessage('goodsDescription'))
            .nullable()
            .required(getRequiredMessage('goodsDescription')),
        cusCode: getExactLengthValidation('cusCode', 8).nullable(),
        combinedNomenclatureCode: getMaxValidation('combinedNomenclatureCode', 8)
            .required(getRequiredMessage('combinedNomenclatureCode'))
            .nullable()
            .required(getRequiredMessage('combinedNomenclatureCode')),
        taricCode: getExactLengthValidation('taricCode', 2)
            .required(getRequiredMessage('taricCode'))
            .matches(TARIC_CODE_TYPE, 'Taric code should have two integer.')
            .nullable()
            .required(getRequiredMessage('taricCode')),
        typeGoods: getMaxValidation('typeGoods', 2).nullable(),
        packaging: Yup.array().of(packaging()).nullable(),
        nationalAdditionalCommodityCode: nationalAdditionalCommodityCode().nullable(),
    });

const additionalProceduresSchema = () => Yup.array().of(getExactLengthValidation('additionalProcedures', 3).nullable());
const containerIdentificationNumber = () =>
    Yup.array().of(getMaxValidation('containerIdentificationNumber', 17).nullable());

export const additionalFiscalReference = () => Yup.array().of(additionalFiscalReferenceObj);

export const additionalFiscalReferenceObj = Yup.object().shape({
    vatNumber: getEoriValidation('referenceId').required(getRequiredMessage('referenceId')),
    roleCode: getMaxValidation('referenceVat', 3).required(getRequiredMessage('referenceVat')).nullable(),
});

export const simplifiedDeclarationDocumentWritingOffItem = Yup.object().shape({
    previousDocumentType: getMaxValidation('previousDocumentType', 5).nullable(),
    previousDocumentIdentifier: getMaxValidation('previousDocumentIdentifier', 35).nullable(),
    previousDocumentLineId: getMaxValidation('previousDocumentLineId', 5).nullable(),
    taricAdditionalCode: getExactLengthValidation('taricAdditionalCode', 4).nullable(),
    measurementUnitAndQualifier: getMaxValidation('measurementUnitAndQualifier', 6).nullable(),
    quantity: getMaxValidation('quantity', 16).nullable(),
    typeOfPackages: getMaxValidation('typeOfPackages', 2).nullable(),
    numberOfPackages: getMaxValidation('numberOfPackages', 8).nullable(),
});

export const simplifiedDeclarationDocumentWritingOffItems = () =>
    Yup.array().of(simplifiedDeclarationDocumentWritingOffItem);

const taxBox43Bis = () =>
    Yup.array().of(
        Yup.object().shape({
            boxTaxType: getExactLengthValidation('boxTaxType', 3).nullable(),
            boxAmount: getMaxValidation('boxAmount', 16).nullable(),
            boxTaxRate: getMaxValidation('boxTaxRate', 17).nullable(),
            boxTaxPayableAmount: getMaxValidation('boxTaxPayableAmount', 16).nullable(),
            boxTaxPaymentMethod: getExactLengthValidation('boxTaxPaymentMethod', 1).nullable(),
        })
    );

const getStatisticalValueValidation = () => {
    return getNumberMaxDigitsValidation('statisticalValue', 16)
        .nullable()
        .test({
            name: 'statistical-value',
            message: `The value entered in DE '8/6 Statistical value' must be positive number and match the following calculation: (DE '4/14 Item price/amount' divided by DE '4/15 Exchange rate') plus (the sum of Additions in DE '4/9 Additions and deductions') minus (the sum of Deductions in DE '4/9 Additions and deductions').  excluding 1X, BA, BF & BB.`,
            test: (value, context) => {
                if (value == null) return true;
                return value >= 0;
            },
        });
};

export const goodsShipmentItemSchemaRequired = Yup.object().shape({
    procedureCode: getExactLengthValidation('procedureCode', 2).nullable(),
    previousProcedureCode: getExactLengthValidation('previousProcedureCode', 2).nullable(),
    ucr: getMaxValidation('ucr', 35).nullable(),
    taxTotalAmount: getMaxValidation('taxTotalAmount', 35).nullable(),
    itemAmount: getMaxValidation('itemAmount', 16).required(getRequiredMessage('itemAmount')).nullable(),
    valuationMethod: getExactLengthValidation('valuationMethod', 1)
        .required(getRequiredMessage('valuationMethod'))
        .nullable(),
    preference: getExactLengthValidation('preference', 3).required(getRequiredMessage('preference')).nullable(),
    valuationInformationCurrency: getExactLengthValidation('valuationInformationCurrency', 3).nullable(),
    valuationInformationAmount: getMaxValidation('valuationInformationAmount', 16).nullable(),
    countryDestination: getExactLengthValidation('countryDestination', 2).nullable(),
    regionDestination: getExactLengthValidation('regionDestination', 2).nullable(),
    countryDispatch: getExactLengthValidation('countryDispatch', 2).nullable(),
    countryOrigin: getExactLengthValidation('countryOrigin', 2)
        .required(getRequiredMessage('countryOrigin'))
        .nullable(),
    countryPreferentialOrigin: getMaxValidation('countryPreferentialOrigin', 4).nullable(),
    acceptanceDate: Yup.date().nullable(),
    quotaOrderNumber: getExactLengthValidation('quotaOrderNumber', 6).nullable(),
    transactionNature: getMaxValidation('transactionNature', 2).nullable(),
    statisticalValue: getStatisticalValueValidation(),
    goodsInformation: goodsInformation().nullable(),
    additionalProcedures: additionalProceduresSchema().nullable(),
    containerIdentificationNumber: containerIdentificationNumber().nullable(),
    simplifiedDeclarationDocumentWritingOff: simplifiedDeclarationDocumentWritingOffItems().nullable(),
    additionalInformation: additionalInformationSchema().nullable(),
    taxBox43Bis: taxBox43Bis().nullable(),
    additionsDeductions: additionsDeductions().nullable(),
    supplyChainActor: supplyChainActor().nullable(),
    additionalFiscalReference: additionalFiscalReference().nullable(),
});

const goodsShipmentItem = () => Yup.array().of(goodsShipmentItemSchemaRequired);

const locationGoods = () =>
    Yup.object().shape({
        identificationOfLocation: getMaxValidation('identificationOfLocation', 35)
            .required(getRequiredMessage('identificationOfLocation'))
            .nullable(),
        additionalIdentifier: getExactLengthValidation('additionalIdentifier', 3).nullable(),
        locationTypeCode: getExactLengthValidation('locationTypeCode', 1)
            .required(getRequiredMessage('locationTypeCode'))
            .nullable(),
        qualifierIdentification: getExactLengthValidation('qualifierIdentification', 1)
            .required(getRequiredMessage('qualifierIdentification'))
            .nullable(),
        address: Yup.object()
            .shape({
                country: getMaxValidation('country', 2).nullable(),
            })
            .nullable(),
    });

const goodsShipmentSchema = Yup.object({
    importer: getCustomerValidation({ eori: { required: true } }).nullable(),
    seller: getCustomerValidation().nullable(),
    buyer: getCustomerValidation().nullable(),
    additionalInformation: additionalInformationSchema().nullable(),
    simplifiedDeclarationDocumentWritingOff: simplifiedDeclarationDocumentWritingOff().nullable(),
    additionsDeductions: additionsDeductions().nullable(),
    supplyChainActor: supplyChainActor().nullable(),
    goodsShipmentItem: goodsShipmentItem().required(getRequiredMessage('goodsShipmentItem')).nullable(),
    ucr: getMaxValidation('ucr', 35).nullable(),
    warehouseType: getExactLengthValidation('warehouseType', 1).nullable(),
    warehouseIdentifier: getMaxValidation('warehouseIdentifier', 35).nullable(),
    incotermCode: getExactLengthValidation('incotermCode', 3).required(getRequiredMessage('incotermCode')).nullable(),
    unlocode: getEoriValidation('unlocode'),
    countryCode: getExactLengthValidation('countryCode', 2).nullable(),
    place: getMaxValidation('place', 35).nullable(),
    transportCostsCurrency: getMaxValidation('transportCostsCurrency', 3).nullable(),
    transportCostsAmount: getMaxValidation('transportCostsAmount', 16).nullable(),
    countryDestination: getExactLengthValidation('countryDestination', 2)
        .required(getRequiredMessage('countryDestination'))
        .nullable(),
    regionDestination: getMaxValidation('regionDestination', 9).nullable(),
    countryDispatch: getExactLengthValidation('countryDispatch', 2)
        .required(getRequiredMessage('countryDispatch'))
        .nullable(),
    container: Yup.boolean().nullable(),
    inlandBorderTransportMode: getExactLengthValidation('inlandBorderTransportMode', 1).nullable(),
    meansIdentificationType: getExactLengthValidation('meansIdentificationType', 2)
        .required(getRequiredMessage('meansIdentificationType'))
        .nullable(),
    meansIdentificationNumber: getMaxValidation('meansIdentificationNumber', 35)
        .required(getRequiredMessage('meansIdentificationNumber'))
        .nullable(),
    transactionNature: getMaxValidation('transactionNature', 2).nullable(),
    locationGoods: locationGoods().nullable(),
    producedDocumentsWritingOff: Yup.object().shape({
        N935: Yup.array().of(getMinCharactersValidation('invoiceNumber', 5)),
    }),
});

export const authorisationHolder = Yup.array().of(
    Yup.object().shape({
        authorisationTypeCode: getMaxValidation('authorisationTypeCode', 4).nullable(),
        authorisationHolderIdentification: getMaxValidation('authorisationHolderIdentification', 17).nullable(),
    })
);

export const guaranteeReferenceValidation = Yup.object().shape({
    grn: getMaxValidation('grn', 24).nullable(),
    otherGuaranteeReference: getMaxValidation('otherGuaranteeReference', 35).nullable(),
    accessCode: getMaxValidation('accessCode', 4).nullable(),
    currency: getExactLengthValidation('currency', 3).nullable(),
    amount: getMaxValidation('amount', 16).nullable(),
    guaranteeCustomsOffice: getExactLengthValidation('guaranteeCustomsOffice', 8)
        .matches(CUSTOMS_OFFICE, invalidEoriMessage)
        .nullable(),
});

export const guaranteeReferences = Yup.array().of(guaranteeReferenceValidation).nullable();

export const guaranteeSchema = Yup.array().of(
    Yup.object().shape({
        guaranteeType: getMaxValidation('guaranteeType', 1).nullable(),
        guaranteeReference: guaranteeReferences,
    })
);

const h1Validation = (numberOfItems?: number) =>
    Yup.object().shape({
        declarationType: Yup.string().required(getRequiredMessage('declarationType')).nullable(),
        numberOfItems: getNumberOfItemsValidation(numberOfItems)
            .required(getRequiredMessage('numberOfItems'))
            .nullable(),
        additionalDeclarationType: Yup.string().required('Additional declaration type is required.').nullable(),
        deferredPayment: getMaxValidation('deferredPayment', 35).nullable(),
        invoiceCurrency: getMaxValidation('invoiceCurrency', 3)
            .required(getRequiredMessage('invoiceCurrency'))
            .nullable(),
        invoiceAmount: getMaxValidation('invoiceAmount', 16).nullable(),
        internalCurrency: getMaxValidation('internalCurrency', 3).nullable(),
        exchangeRate: getMaxValidation('exchangeRate', 12).nullable(),
        borderTransportMode: getMaxValidation('borderTransportMode', 2).nullable(),
        activeBorderTransportMeansNationality: getMaxValidation('activeBorderTransportMeansNationality', 2).nullable(),
        presentationCustomsOffice: getExactLengthValidation('presentationCustomsOffice', 8).nullable(),
        supervisingCustomsOffice: Yup.string().matches(CUSTOMS_OFFICE, invalidEoriMessage).nullable(),
        customsOfficeLodgement: getExactLengthValidation('customsOfficeLodgement', 8)
            .required(getRequiredMessage('customsOfficeLodgement'))
            .nullable(),
        representativeIdentification: getMaxValidation('representativeIdentification', 17).nullable(),
        representativeStatus: getMaxValidation('representativeStatus', 1).nullable(),
        personProvidingGuarantee: getEoriValidation(),
        personPayingCustomsDuty: getEoriValidation().required(getRequiredMessage('personPayingCustomsDuty')),
        preferredPaymentMethod: getMaxValidation('preferredPaymentMethod', 1)
            .required(getRequiredMessage('preferredPaymentMethod'))
            .nullable(),
        exporter: getCustomerValidation().nullable(),
        declarant: getCustomerValidation({ eori: { required: true } }).nullable(),
        representative: getCustomerValidation().nullable(),
        authorisation: authorisationSchema,
        goodsShipment: goodsShipmentSchema,
        guarantee: guaranteeSchema,
        authorisationHolder: authorisationHolder,
        airwayBill: getMaxValidation('airwayBill', 255).nullable(),
        masterAirwayBill: getMaxValidation('masterAirwayBill', 255).nullable(),
        houseWayBill: getMaxValidation('houseWayBill', 255).nullable(),
        billOfLading: getMaxValidation('billOfLading', 255).nullable(),
        roadConsignmentNote: getMaxValidation('roadConsignmentNote', 255).nullable(),
        masterBillOfLading: getMaxValidation('masterBillOfLading', 255).nullable(),
        houseBillOfLading: getMaxValidation('houseBillOfLading', 255).nullable(),
        dv1Declaration: getMaxValidation('dv1Declaration', 255).nullable(),
        vatFreeAuthorisation: getMaxValidation('vatFreeAuthorisation', 255).nullable(),
        roroShipId: getMaxValidation('roroShipId', 8).nullable(),
        roroUnaccompaniedTrailerReg: getMaxValidation('roroUnaccompaniedTrailerReg', 32).nullable(),
        textilesCertOfOrigin: getMaxValidation('textilesCertOfOrigin', 255).nullable(),
    });

export default h1Validation;
