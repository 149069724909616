import { Col, Row } from 'antd';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import addPathPrefix from 'utils/addPathPrefix';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import AddressForm from '../AddressForm';
import PartiesAddressFormProps from './PartiesAddressFormProps';
import { Party, Paths } from './PartiesCard';
import { FormCardContainer } from '../cards/NewFormCard';
import { phoneCodeOptions } from '../../../../components/ui/composed/formPhoneNumber/FormPhoneNumber';

const addPathPrefixToValues = <TType extends Object>(prefix: string, object: TType) => {
    const newObject = {} as any;
    Object.entries(object).forEach(([key, value]) => {
        newObject[key] = addPathPrefix(prefix, value);
    });
    return newObject as TType;
};
const createAddressPath = (party: Party, paths: Omit<Paths, 'eori'>) => {
    return {
        ...addPathPrefixToValues(party.path, paths.address),
        ...(party.hasPhoneNumber &&
            paths.additional?.phoneNumber && {
                phoneNumber: addPathPrefix(party.path, paths.additional.phoneNumber),
                phoneNumberCode: addPathPrefix(party.path, 'phoneNumberCode'),
            }),
    };
};

const PartiesAddressForm = ({
    paths: pathsProp,
    party,
    refNumber,
    condensed,
    onBlur,
    ...other
}: PartiesAddressFormProps): ReactElement => {
    const { t } = useTranslation('form');

    const paths = useMemo(() => createAddressPath(party, pathsProp), [party, pathsProp]);

    if (condensed) {
        return (
            <FormCardContainer>
                <FormInput
                    {...getFormikProps(paths.name, other)}
                    refNumber={refNumber}
                    label={t('name')}
                    viewOnly={other.viewOnly}
                    testId={paths.name}
                    condensed={condensed}
                    onBlur={onBlur}
                />
                <AddressForm
                    declarationType={DeclarationName.H1}
                    viewOnly={other.viewOnly}
                    fieldProps={{
                        streetAndNumber: other.getFieldProps(paths.streetAndNumber),
                        country: other.getFieldProps(paths.country),
                        postCode: other.getFieldProps(paths.postCode),
                        city: other.getFieldProps(paths.city),
                    }}
                    fieldMeta={{
                        streetAndNumber: other.getFieldMeta!(paths.streetAndNumber),
                        country: other.getFieldMeta!(paths.country),
                        postCode: other.getFieldMeta!(paths.postCode),
                        city: other.getFieldMeta!(paths.city),
                    }}
                    fieldHelper={{
                        streetAndNumber: other.getFieldHelpers(paths.streetAndNumber),
                        country: other.getFieldHelpers(paths.country),
                        postCode: other.getFieldHelpers(paths.postCode),
                        city: other.getFieldHelpers(paths.city),
                    }}
                    refNumbers={{
                        streetAndNumber: refNumber ?? '',
                        city: refNumber ?? '',
                        country: refNumber ?? '',
                        postcode: refNumber ?? '',
                    }}
                    condensed={condensed}
                    onBlur={onBlur}
                />
                {paths.phoneNumber && paths.phoneNumberCode && (
                    <>
                        <FormSelect
                            label="Country Code"
                            viewOnly={other.viewOnly}
                            refNumber={refNumber}
                            condensed={condensed}
                            selectOptions={phoneCodeOptions}
                            {...getFormikProps(paths.phoneNumberCode, other)}
                            onBlur={onBlur}
                        />

                        <FormInput
                            maxLength={50}
                            viewOnly={other.viewOnly}
                            {...getFormikProps(paths.phoneNumber, other)}
                            refNumber={refNumber}
                            testId={paths.phoneNumber}
                            label={t('phoneNumber')}
                            condensed={condensed}
                            onBlur={onBlur}
                        />
                    </>
                )}
            </FormCardContainer>
        );
    }

    return (
        <Row gutter={12}>
            <Col xs={24}>
                <FormInput
                    {...getFormikProps(paths.name, other)}
                    refNumber={refNumber}
                    label={t('name')}
                    viewOnly={other.viewOnly}
                    testId={paths.name}
                    condensed={condensed}
                    onBlur={onBlur}
                />
            </Col>
            <AddressForm
                declarationType={DeclarationName.H1}
                viewOnly={other.viewOnly}
                fieldProps={{
                    streetAndNumber: other.getFieldProps(paths.streetAndNumber),
                    country: other.getFieldProps(paths.country),
                    postCode: other.getFieldProps(paths.postCode),
                    city: other.getFieldProps(paths.city),
                }}
                fieldMeta={{
                    streetAndNumber: other.getFieldMeta!(paths.streetAndNumber),
                    country: other.getFieldMeta!(paths.country),
                    postCode: other.getFieldMeta!(paths.postCode),
                    city: other.getFieldMeta!(paths.city),
                }}
                fieldHelper={{
                    streetAndNumber: other.getFieldHelpers(paths.streetAndNumber),
                    country: other.getFieldHelpers(paths.country),
                    postCode: other.getFieldHelpers(paths.postCode),
                    city: other.getFieldHelpers(paths.city),
                }}
                refNumbers={{
                    streetAndNumber: refNumber ?? '',
                    city: refNumber ?? '',
                    country: refNumber ?? '',
                    postcode: refNumber ?? '',
                }}
                condensed={condensed}
                onBlur={onBlur}
            />
            {paths.phoneNumber && (
                <Col xs={12}>
                    <FormInput
                        maxLength={50}
                        viewOnly={other.viewOnly}
                        {...getFormikProps(paths.phoneNumber, other)}
                        refNumber={refNumber}
                        testId={paths.phoneNumber}
                        label={t('phoneNumber')}
                        condensed={condensed}
                        onBlur={onBlur}
                    />
                </Col>
            )}
        </Row>
    );
};

export default PartiesAddressForm;
