import { TestProps } from 'core/utils/testTypes';
import { FC, ReactNode, useEffect, useState } from 'react';
import { CollapseIcon, ExpandedIconDiv, ExpandIcon, StyledCollapse } from './BorderlessCard.styles';

interface Props extends TestProps {
    children: ReactNode;
    invalid?: boolean;
    lastCard?: boolean;
    viewOnly?: boolean;
    defaultActiveKey?: number;
    expandAll?: boolean;
    keyCard?: number | string;
}
const BorderlessCollapse: FC<Props> = ({
    children,
    invalid,
    defaultActiveKey,
    viewOnly,
    lastCard,
    expandAll = false,
    keyCard,
    testId,
}) => {
    const [expanded, setExpanded] = useState<string | number | undefined>(defaultActiveKey);
    const expandedIcon = (props?: any) => {
        return props?.isActive ? <CollapseIcon /> : <ExpandIcon />;
    };

    const handleExpandIcon = () => {
        if (expanded) {
            setExpanded(undefined);
        } else {
            setExpanded(keyCard);
        }
    };

    useEffect(() => {
        const expandedState = expandAll ? keyCard : undefined;
        if (expanded !== expandedState) {
            setExpanded(expandedState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandAll, keyCard]);

    const handleOnChangeCollaps = () => {
        if (expanded) {
            setExpanded(undefined);
        } else {
            setExpanded(keyCard);
        }
    };

    return (
        <StyledCollapse
            invalid={invalid}
            viewOnly={viewOnly}
            lastCard={lastCard}
            ghost
            expandIconPosition="end"
            defaultActiveKey={defaultActiveKey}
            key={keyCard}
            onChange={handleOnChangeCollaps}
            activeKey={expanded}
            expandIcon={(props) => <ExpandedIconDiv onClick={handleExpandIcon}>{expandedIcon(props)}</ExpandedIconDiv>}
            data-testid={testId}
        >
            {children}
        </StyledCollapse>
    );
};
export default BorderlessCollapse;
