import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclarationFormCardProps } from '../../../../common/declaration.form.card';
import MultipleItemsCard from '../../../../common/MultipleItemsCard';
import CalculationOfTaxesBlock from '../../blocks/CalculationOfTaxesBlock';
import CalculationOfTaxesMethodOfPaymentBlock from '../../blocks/CalculationOfTaxesMethodOfPaymentBlock';
import CalculationOfTaxesTaxBaseBlock from '../../blocks/CalculationOfTaxesTaxBaseBlock';
import CalculationOfTaxesTaxTypeBlock from '../../blocks/CalculationOfTaxesTaxTypeBlock';
import CalculationOfTaxesTotalBlock from '../../blocks/CalculationOfTaxesTotalBlock';

const CalculationOfTaxesCard = ({
    viewOnly,
    getFieldHelpers,
    getFieldMeta,
    getFieldProps,
    toggleHelp,
    cardTotal,
    expandAll,
    hide,
    propsPathPrefix,
    keyCard,
}: DeclarationFormCardProps): ReactElement => {
    const { t } = useTranslation('form');

    return (
        <MultipleItemsCard
            title={t('calculationOfTaxe')}
            keyCard={keyCard}
            viewOnly={viewOnly}
            path={propsPathPrefix!}
            getFieldProps={getFieldProps}
            getFieldMeta={getFieldMeta}
            getFieldHelpers={getFieldHelpers}
            toggleHelp={toggleHelp}
            cardTotal={cardTotal}
            cardNumber={24}
            expandAll={expandAll}
            initialValues={{
                typeCode: '',
                specificTaxBaseQuantity: '',
                unitCode: '',
                paymentAmount: '',
                paymentAmountCurrencyId: '',
                taxAssessedAmount: '',
                taxAssessedAmountCurrencyId: '',
                methodCode: '',
            }}
            list={(list) => [
                {
                    field: 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee.typeCode',
                    value: list.typeCode,
                },
                {
                    field: 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee.specificTaxBaseQuantity',
                    value: list.specificTaxBaseQuantity,
                },
                {
                    field: 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee.specificTaxBaseQuantity.unitCode',
                    value: list.unitCode,
                },
                {
                    field: 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee.payment.paymentAmount',
                    value: list.paymentAmount,
                },
                {
                    field: 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee.payment.paymentAmountCurrencyId',
                    value: list.paymentAmountCurrencyId,
                },
                {
                    field: 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee.payment.taxAssessedAmount',
                    value: list.taxAssessedAmount,
                },
                {
                    field: 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee.payment.taxAssessedAmountCurrencyId',
                    value: list.taxAssessedAmount,
                },
                {
                    field: 'goodsShipment.governmentAgencyGoodsItem.commodity.dutyTaxFee.payment.methodCode',
                    value: list.methodCode,
                },
            ]}
            hide={hide}
        >
            {(path) => (
                <>
                    <CalculationOfTaxesTaxTypeBlock path={path} />
                    <CalculationOfTaxesTaxBaseBlock path={path} />
                    <CalculationOfTaxesBlock path={path} />
                    <CalculationOfTaxesTotalBlock path={path} />
                    <CalculationOfTaxesMethodOfPaymentBlock path={path} />
                </>
            )}
        </MultipleItemsCard>
    );
};

export default CalculationOfTaxesCard;
