import { Col, Row, Table, Tabs } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Drawer from '../../../../../components/ui/base/drawer/Drawer';
import Searchbar from '../../../../../components/ui/base/searchbar';
import Fuse from 'fuse.js';
import Button from '../../../../../components/ui/base/button';
import { getFormikProps } from '../../../utils/form-utils';
import { useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { getExactLengthValidation, getRequiredMessage } from '../../../utils/validation-utils';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import { StyledTabs } from '../../../Form.styles';
import { StyledDeleteOutlined } from '../../../sections/customer-documents/CustomerDocuments.styles';
import { CustomsOfficeOfExit } from '../../../../../store/customs-office-of-exit/CustomsOfficeOfExit';
import {
    createCustomsOfficeOfExit,
    deleteCustomsOfficeOfExit,
} from '../../../../../store/customs-office-of-exit/client';
import CustomModal from 'components/ui/base/modal/Modal';
import { H5 } from '../../../../../components/ui/base/typography';
import { useTemplateContext } from '../../../../../components/ui/composed/template/TemplateContext';
import addPathPrefix from '../../../../../utils/addPathPrefix';

const { TabPane } = Tabs;

interface Props {
    title?: any;
    visible?: boolean;
    onClose?: () => void;
    rowsDataSource?: any;
}

const CustomsOfficeOfExitDrawer = ({ title, visible, onClose, rowsDataSource }: Props) => {
    const [selectedRowKey, setSelectedRowKey] = useState<React.Key[]>([]);
    const [dataSourceWithKeys, setDataSourceWithKeys] = useState<CustomsOfficeOfExit[]>([]);
    const [filteredRows, setFilteredRows] = useState<CustomsOfficeOfExit[]>([]);
    const [isForm, setSwitchTabs] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [officeCode, setOfficeCode] = useState<string | undefined>(undefined);

    const { getFieldHelpers, getFieldProps } = useFormikContext();
    const { template, templateFormik } = useTemplateContext();

    const columns = [
        { title: 'Region', dataIndex: 'region', key: 'region' },
        { title: 'City', dataIndex: 'city', key: 'city' },
        { title: 'Name', dataIndex: 'description', key: 'name' },
        { title: 'Code', dataIndex: 'code', key: 'code' },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text: string, record: CustomsOfficeOfExit) => (
                <>
                    {record.customerId && (
                        <span
                            onClick={() => {
                                setIsDeleteModalVisible(true);
                                setOfficeCode(record.code);
                            }}
                        >
                            <StyledDeleteOutlined />
                        </span>
                    )}
                </>
            ),
        },
    ];

    useEffect(() => {
        if (!rowsDataSource) return;

        setDataSourceWithKeys(rowsDataSource.map((office: any) => ({ key: office.code, ...office })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsDataSource]);

    useEffect(() => {
        setFilteredRows(dataSourceWithKeys);
    }, [dataSourceWithKeys]);

    const exitOfficeField = useMemo(() => {
        const path = template ? addPathPrefix(`master.defaults`, 'exitOffice.id') : 'exitOffice.id';

        const formikProps = template
            ? { getFieldProps: templateFormik?.getFieldProps!, getFieldHelpers: templateFormik?.getFieldHelpers! }
            : { getFieldProps, getFieldHelpers };

        return getFormikProps(path, {
            ...formikProps,
        });
    }, [getFieldHelpers, getFieldProps, templateFormik?.getFieldProps, templateFormik?.getFieldHelpers, template]);

    const getSelectedExitOffice = () =>
        dataSourceWithKeys?.filter((rowData: any) => rowData.key === selectedRowKey?.[0])?.[0];

    const handleFieldAndCodelistChange = () => {
        const { code } = getSelectedExitOffice();

        exitOfficeField?.fieldHelper.setValue(code);
        onClose?.();
    };

    const handleSearch = (input: string) => {
        if (!input) return setFilteredRows(dataSourceWithKeys);

        const fuse = new Fuse(dataSourceWithKeys, { keys: ['region', 'city', 'description', 'code'] });
        const searchResult = fuse.search(input);
        const filterResult = searchResult.map((rowData: any) => rowData.item);

        setFilteredRows(filterResult);
    };

    const formik = useFormik<CustomsOfficeOfExit>({
        initialValues: { region: '', city: '', description: '', code: '' },
        validateOnMount: true,
        validationSchema: Yup.object().shape({
            code: getExactLengthValidation('codeOfCustomsOffice', 8)
                .required(getRequiredMessage('codeOfCustomsOffice'))
                .test('code-used', 'This code already exists.', (val: any) =>
                    val ? !dataSourceWithKeys.some((rowData: CustomsOfficeOfExit) => rowData.code === val) : true
                )
                .nullable(),
        }),
        enableReinitialize: true,
        onSubmit: () => {},
    });

    const handleFormSubmit = useCallback(async () => {
        const validation = await formik.validateForm();
        formik.setTouched(validation as any);

        if (!isEmpty(validation)) return;

        const newRow = await createCustomsOfficeOfExit(formik.values);

        setSelectedRowKey([newRow.code]);

        setDataSourceWithKeys((allRows: any) => [{ key: newRow.code, ...newRow }, ...allRows]);

        setSwitchTabs((lastTab) => !lastTab);

        formik.resetForm();
    }, [formik]);

    const handleDelete = async () => {
        if (!officeCode) return;

        await deleteCustomsOfficeOfExit(officeCode);

        setDataSourceWithKeys(dataSourceWithKeys.filter((element) => element.code !== officeCode));

        setIsDeleteModalVisible(false);
    };

    return (
        <Drawer title={title} visible={visible} onClose={onClose} width={1000}>
            <Row justify="space-between" style={{ paddingRight: '9px' }}>
                <StyledTabs
                    onChange={() => {
                        setSwitchTabs((lastTab) => !lastTab);
                        setSelectedRowKey([]);
                        setFilteredRows(dataSourceWithKeys);
                    }}
                    activeKey={!isForm ? 'lookUp' : 'form'}
                >
                    <TabPane tab={'Look up customs office'} key={'lookUp'} />
                    <TabPane tab={'Add new customs office'} key={'form'} />
                </StyledTabs>
                <Col>
                    <Button
                        size="large"
                        style={{ marginRight: '2rem' }}
                        disabled={!isForm && !selectedRowKey.length}
                        onClick={() => {
                            if (!isForm) {
                                setSelectedRowKey([]);
                            } else {
                                formik.resetForm();
                                setSwitchTabs((lastTab) => !lastTab);
                            }
                        }}
                    >
                        {!isForm ? 'Reset' : 'Cancel'}
                    </Button>
                    <Button
                        size="large"
                        disabled={!isForm ? !selectedRowKey.length : false}
                        onClick={!isForm ? handleFieldAndCodelistChange : handleFormSubmit}
                    >
                        {!isForm ? 'Populate field' : 'Add new customs office'}
                    </Button>
                </Col>
            </Row>

            {!isForm ? (
                <>
                    <Col>
                        <Searchbar
                            inputPlaceholder="Enter region, city, name or customs code"
                            onChange={handleSearch}
                        />
                    </Col>

                    <Col style={{ marginTop: '1.5rem' }}>
                        <Table
                            columns={columns}
                            dataSource={filteredRows}
                            pagination={{ position: ['bottomCenter'] }}
                            rowSelection={{
                                type: 'radio',
                                selectedRowKeys: selectedRowKey,
                                onChange: (key) => setSelectedRowKey(key),
                            }}
                            onRow={(record) => ({
                                onClick: () => {
                                    if (!record.key) return;
                                    setSelectedRowKey([record.key]);
                                },
                            })}
                        />
                    </Col>
                </>
            ) : (
                <Col span={18} style={{ marginTop: '2.5' }}>
                    <FormInput
                        label={'Region of customs office'}
                        fieldProps={formik.getFieldProps('region')}
                        fieldMeta={formik.getFieldMeta('region')}
                    />
                    <FormInput
                        label={'City of customs office'}
                        fieldProps={formik.getFieldProps('city')}
                        fieldMeta={formik.getFieldMeta('city')}
                    />
                    <FormInput
                        label={'Name of customs office'}
                        fieldProps={formik.getFieldProps('description')}
                        fieldMeta={formik.getFieldMeta('description')}
                    />
                    <FormInput
                        required
                        label={'Code of customs office'}
                        fieldProps={formik.getFieldProps('code')}
                        fieldMeta={formik.getFieldMeta('code')}
                    />
                </Col>
            )}
            <CustomModal
                title={<H5>Do you want to delete this customs office of exit?</H5>}
                centered
                visible={isDeleteModalVisible}
                onOk={handleDelete}
                onCancel={() => setIsDeleteModalVisible(false)}
                width={762}
                contentText={
                    'If you remove this customs office of exit, you will lose all the information associated with it.'
                }
            />
        </Drawer>
    );
};

export default CustomsOfficeOfExitDrawer;
