import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { ColumnType, FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import Table from 'components/ui/base/table/Table';
import { ListPayload } from 'core/http/response';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { EditOutlined, DeleteOutlined, CopyOutlined, EyeOutlined } from '@ant-design/icons';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import { Button, ButtonProps, Tooltip } from 'antd';
import useGlobalOverlay from 'hooks/useGlobalOverlay';

interface CommandButtonProps extends ButtonProps {
    icon: ReactElement;
    tooltip: string;
}
const CommandButton = ({ icon, tooltip, ...buttonProps }: CommandButtonProps) => {
    return (
        <Tooltip overlay={tooltip}>
            <Button type="text" shape="circle" icon={icon} {...buttonProps} />
        </Tooltip>
    );
};

type Entity = { id: string };

type Props<TEntity extends Entity> = {
    columns: ColumnsType<TEntity>;
    data: ListPayload<TEntity> | undefined;
    doSelectOnClick?: boolean;
    onEdit?: (entityId: string) => void;
    onDelete?: (entityId: string) => void;
    onDuplicate?: (entityId: string) => void;
    onRowSelection?: (ids: string[]) => void;
    onPaginatorChange?: (paginator: Partial<PaginatedParams>) => void;
    onRowClick?: (entityId: string) => void;
    onTableChange?: (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<TEntity> | SorterResult<TEntity>[],
        extra: TableCurrentDataSource<TEntity>
    ) => void;
    loading?: boolean;
    rowSelectionType?: 'checkbox' | 'radio';
    withPagination?: boolean;
    onView?: (entityId: string) => void;
};

const DashboardTable = <TEntity extends Entity>({
    columns: columnsProp,
    data,
    onEdit,
    onDelete,
    onDuplicate,
    onRowClick,
    onRowSelection,
    onPaginatorChange,
    onTableChange,
    loading,
    doSelectOnClick,
    rowSelectionType = 'checkbox',
    withPagination = true,
    onView,
}: Props<TEntity>) => {
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>(defaultPagination);
    const [selections, setSelections] = useState<string[]>([]);

    const handlePagination = async () => {
        showGlobalOverlay({ type: 'LoadingOverlay' });
        await onPaginatorChange?.(paginator);
        hideGlobalOverlay();
    };

    useEffect(() => {
        if (paginator === defaultPagination) return;
        handlePagination();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator]);

    const columns: ColumnsType<TEntity> = useMemo(() => {
        return [
            ...columnsProp,
            ...(onEdit || onDelete || onView
                ? [
                      {
                          title: 'Commands',
                          dataIndex: 'commands',
                          key: 'commands',
                          align: 'center',
                          render: (text: string, record, index: number) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          gap: '1rem',
                                          justifyContent: 'center',
                                      }}
                                  >
                                      {onEdit && (
                                          <CommandButton
                                              icon={<EditOutlined />}
                                              tooltip="Edit"
                                              onClick={() => onEdit(record.id)}
                                          />
                                      )}
                                      {onDelete && (
                                          <CommandButton
                                              icon={<DeleteOutlined />}
                                              tooltip="Delete"
                                              onClick={() => onDelete(record.id)}
                                          />
                                      )}
                                      {onDuplicate && (
                                          <CommandButton
                                              icon={<CopyOutlined />}
                                              tooltip="Duplicate"
                                              onClick={() => onDuplicate(record.id)}
                                          />
                                      )}
                                      {onView && (
                                          <CommandButton
                                              icon={<EyeOutlined />}
                                              tooltip="View"
                                              onClick={() => onView(record.id)}
                                          />
                                      )}
                                  </div>
                              );
                          },
                      } as ColumnType<TEntity>,
                  ]
                : []),
        ];
    }, [columnsProp, onDelete, onDuplicate, onEdit, onView]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<TEntity> | SorterResult<TEntity>[],
        extra: TableCurrentDataSource<TEntity>
    ) => {
        if (!Array.isArray(sorter) && sorter.columnKey) {
            const sortParameter = sorter.columnKey?.toString();
            if (sorter.order === 'ascend') {
                setPaginator((prev) => ({ ...prev, sortParameter, sortDirection: 'ASC' }));
            } else if (sorter.order === 'descend') {
                setPaginator((prev) => ({ ...prev, sortParameter, sortDirection: 'DESC' }));
            }
        }

        onTableChange?.(pagination, filters, sorter, extra);
    };

    return (
        <Table
            rowKey="id"
            style={{ marginTop: '2.5rem' }}
            columns={columns}
            dataSource={data?.list}
            onRow={(record, rowIndex) => ({
                onClick: () => {
                    if (doSelectOnClick) {
                        setSelections((prev) => {
                            if (rowIndex === undefined) return prev;

                            const id = record.id;

                            let result;
                            if (rowSelectionType === 'radio') {
                                result = [id];
                            } else if (prev.includes(id)) {
                                result = [...prev.filter((_id) => _id !== id)];
                            } else {
                                result = [...prev, id];
                            }
                            onRowSelection?.(result);
                            return result;
                        });
                    } else {
                        onRowClick?.(record.id);
                    }
                },
            })}
            rowSelection={
                onRowSelection
                    ? {
                          type: rowSelectionType,
                          onChange: (selectedRowKeys: unknown, selectedRows: TEntity[]) => {
                              const ids = selectedRows.map((c) => c.id);
                              onRowSelection(ids);
                              setSelections(ids);
                          },
                          selectedRowKeys: selections,
                      }
                    : undefined
            }
            pagination={
                withPagination
                    ? {
                          defaultCurrent: 1,
                          total: data?.total,
                          showSizeChanger: false,
                          pageSize: data?.pageSize,
                          position: ['bottomCenter'],
                          onChange: (page: number, size: number | undefined) => {
                              const params = { page: page - 1, size };
                              setPaginator((prev) => ({ ...prev, ...params }));
                          },
                      }
                    : false
            }
            onChange={handleTableChange}
            loading={loading}
        />
    );
};

export default DashboardTable;
