import { useCallback, useEffect } from 'react';
import { doGetAllCodelists } from 'store/codelists/actions';
import { useAppDispatch, useAppSelector } from '../config/hooks';

const useCodelists = (load = false) => {
    const codelists = useAppSelector((state) => state.codelists.entities);
    const error = useAppSelector((state) => state.codelists.error);
    const isLoading = useAppSelector((state) => state.codelists.isLoading);

    const dispatch = useAppDispatch();

    const getCodelists = useCallback(() => dispatch(doGetAllCodelists()), [dispatch]);

    useEffect(() => {
        if (load) {
            getCodelists();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoading,
        error,
        codelists,
    };
};

export default useCodelists;
