import { SelectOption } from 'components/ui/composed/declarations/formSelect/DeclarationSelect';

export interface Code {
    code: string;
    description: string;
}

export const normalizeCodesToSelect = (values: Code[]): SelectOption[] =>
    values?.map((item) => ({
        id: item.code,
        value: item.description,
    }));

export interface CodeList {
    ireland: IrelandCodelist;
    uk: UkCodeList;
}

export interface IrelandCodelist {
    additionalInformationCodes: Code[];
    additionalProcedures: Code[];
    additionsDeductionsCodes: Code[];
    authorisationTypeCodes: Code[];
    baseMeasurements: Code[];
    commonDocumentsTypes: Code[];
    deliveryDestinationLocationCodes: Code[];
    exportProcedureCategories: Code[];
    guaranteeTypes: Code[];
    identificationOfGoodsCodes: Code[];
    irishPreferences: Code[];
    locationOfGoodsCodes: Code[];
    meansIdentityTypes: Code[];
    nationalCustomsOfficeCodes: Code[];
    natureOfTransactions: Code[];
    officesOfExit: Code[];
    packageKinds: Code[];
    previousDocumentTypes: Code[];
    procedureCodes: Code[];
    processingProcedureCodes: Code[];
    representativeStatusCodes: Code[];
    taricNationalAdditionalCodes: Code[];
    taxTypes: Code[];
    transportModes: Code[];
    valuationMethods: Code[];
}

export interface UkCodeList {
    airports: Code[];
    allowedAdditionalDocuments: Code[];
    containerCategories: Code[];
    countryGroupCategories: Code[];
    declaredGuaranteeTypes: Code[];
    documentCategories: Code[];
    dutyTaxFeeTypes: Code[];
    exitCustomsOffices: Code[];
    exportCertificates: Code[];
    exportPreviousDocuments: Code[];
    exportSpecialMentions: Code[];
    importCertificates: Code[];
    importPreviousDocuments: Code[];
    importPreviousProcedures: Code[];
    importRequestedProcedures: Code[];
    importSpecialMentions: Code[];
    packageTypes: Code[];
    partyRoleStatusTypes: Code[];
    paymentMethodTypes: Code[];
    preferences: Code[];
    procedureCombinations: Code[];
    specialProcedureTypes: Code[];
    supervisingCustomsOffices: Code[];
    transactionNatureTypes: Code[];
    transportMeansIdentificationTypes: Code[];
    transportModeTypes: Code[];
    unlocodes: Code[];
    valuationIndicatorTypes: Code[];
    valuationMethodTypes: Code[];
}
