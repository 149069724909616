import { CopyOutlined, FileOutlined, StarTwoTone } from '@ant-design/icons';
import Button from 'components/ui/base/button/Button';
import Divider from 'components/ui/base/divider/Divider';
import styled from 'styled-components';
import * as colors from 'theme/colors';

export const StyledDivider = styled(Divider)`
    margin-bottom: 2.5rem;
`;

export const CreateButton = styled(Button)`
    margin-top: 1.6rem;
    margin-bottom: 0.5rem;
`;

export const TableDiv = styled.div`
    margin-top: 5.1rem;
`;

export const StyledCopyOutlined = styled(CopyOutlined)`
    font-size: 2.2rem;
    margin-left: 1.778rem;
    cursor: pointer;
    color: ${colors.black};
    &:hover {
        color: ${colors.black};
        font-size: 2.2rem;
    }
`;

export const StyledFileOutlined = styled(FileOutlined)`
    font-size: 2.2rem;
    margin-left: 1.778rem;
    cursor: pointer;
    color: ${colors.black};
    &:hover {
        color: ${colors.black};
        font-size: 2.2rem;
    }
`;

export const StyledIsCoreTemplate = styled(StarTwoTone)<{ $isCoreTemplate?: boolean }>`
    font-size: 2.2rem;
    cursor: pointer;
    path:nth-child(2) {
        fill: ${colors.yellow};
    }
    path:nth-child(1) {
        fill: ${(props) => (props.$isCoreTemplate ? colors.yellow : colors.white)};
    }
    &:hover {
        font-size: 2.2rem;
        path:nth-child(1) {
            fill: ${(props) => (props.$isCoreTemplate ? colors.white : colors.yellow)};
        }
        path:nth-child(2) {
            fill: ${colors.yellow};
        }
    }
`;

export const DivTableActionInline = styled.div`
    display: flex;
`;
