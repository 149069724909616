import { FieldProps } from 'formik';
import { ReactElement, useMemo, useState } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import CustomsOfficeOfExitDrawer from '../components/CustomsOfficeOfExitBlockDrawer';
import { ValidationSchema, validators } from '../validations/validations';
import useCodelists from 'hooks/useCodelists';
import { normalizeCodesToSelect } from 'store/codelists/code';

export type Fields = 'id';

export const customsOfficeOfExitBlockValidation: {
    childValidators: {
        exitOffice: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        exitOffice: {
            childValidators: {
                id: [validators.required(), validators.exact(8)],
            },
        },
    },
};

interface Props extends BlockProps<Fields> {}

const CustomsOfficeOfExitBlock = ({ path = 'exitOffice' }: Props): ReactElement => {
    const { codelists } = useCodelists();
    const { t } = useTranslation('form');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const ucc = useMemo(() => '5/12', []);

    const customsOfficeOfExitDrawer = useMemo(() => {
        return (
            <CustomsOfficeOfExitDrawer
                title={t('customsOfficeOfExit')}
                visible={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                rowsDataSource={codelists?.ukExitCustomsOffices}
            />
        );
    }, [isDrawerOpen, t, codelists?.ukExitCustomsOffices]);

    return (
        <>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        required
                        refNumber={ucc}
                        label={t('customsOfficeOfExit')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={normalizeCodesToSelect(codelists?.ukExitCustomsOffices!)}
                        condensed
                        onSearchCustomsOfficeOfExit={() => setIsDrawerOpen(true)}
                    />
                )}
            </DeclarationField>
            {customsOfficeOfExitDrawer}
        </>
    );
};

export default CustomsOfficeOfExitBlock;
