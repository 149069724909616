import { ButtonProps } from 'antd/lib/button/button';
import { TestProps } from 'core/utils/testTypes';
import { ReactElement } from 'react';
import { DefaultButton, LinkButton, PrimaryButton, TextButton } from './Button.styles';

export interface CustomButtonProps extends ButtonProps, TestProps {}

export type BaseButtonType = (props: CustomButtonProps) => ReactElement;

const Button: BaseButtonType = (props) => {
    const { type } = props;

    switch (type) {
        case 'primary':
            return <PrimaryButton {...props}></PrimaryButton>;
        case 'text':
            return <TextButton {...props}></TextButton>;
        case 'link':
            return <LinkButton {...props}></LinkButton>;
        default:
            return <DefaultButton {...props}></DefaultButton>;
    }
};

export default Button;
