import { NavItem } from 'views/declarations/common/nav-item';

export const sectionsENSMainDocument: NavItem[] = [
    { title: 'Master Details', href: '#master-details' },
    { title: 'Parties', href: 'parties-declaration-card' },
    { title: 'Goods shipment', href: 'goodshipment-card' },
    { title: 'CUSTOMS OFFICE of first Entry', href: 'first-entry-card' },
    { title: 'CUSTOMS OFFICE of subsequent Entry', href: 'subsequent-entry-card' },
    { title: 'Transport Information', href: 'border-transport-means-card' },
    { title: 'Consignment', href: 'consignment-card' },
    { title: 'Itinerary', href: 'itinerary-card' },
    { title: 'Seals', href: 'seals-card' },
];

export const sectionsENSProducts: NavItem[] = [
    { title: 'Taric Code', href: '#taric-code' },
    { title: 'Goods Information', href: '#goods-information' },
    { title: 'Parties', href: 'parties-declaration-card' },
    { title: 'Containers', href: 'containers-form-card' },
    { title: 'Identity Of Means Of Transport At Border', href: 'Identity Of Means Of Transport At Border-form-card' },
    { title: 'Packages', href: 'enspackage-card' },
    { title: 'Additional Document', href: 'additional-document-card' },
    { title: 'Additional Information', href: 'additional-information-card' },
];
