export interface Individual {
    id: string;
    fullName: string;
    address: Address;
    phone: string;
    type: IndividualType;
    email: string;
    customerId: string;
    preferredJobType?: JobType;
    vehicleType?: VehicleType;
    isInvited?: boolean;
    vehicleRegistrationNumber?: string;
    phoneCountryCode?: string;
    readonly createdAt?: string;
    readonly lastLogin?: string;
    readonly hasHmrcToken: boolean;
}
export interface Address {
    addressLine1: string;
    addressLine2: string;
    postCode: string;
    city: string;
    country: string;
}

export enum IndividualType {
    TRADER_ADMIN = 'TRADER_ADMIN',
    TRADER_USER = 'TRADER_USER',
    BROKER_ADMIN = 'BROKER_ADMIN',
    BROKER_CLERK = 'BROKER_CLERK',
    HAULIER_ADMIN = 'HAULIER_ADMIN',
    DRIVER = 'DRIVER',
    SYSTEM_ADMIN = 'SYSTEM_ADMIN',
}

export enum VehicleType {
    CAR = 'CAR',
    MINIVAN = 'MINIVAN',
    TRUCK = 'TRUCK',
    PANEL_VAN = 'PANEL_VAN',
    VAN = 'VAN',
}

export enum JobType {
    CDL_TRUCK_DRIVER = 'CDL_TRUCK_DRIVER',
    EXPRESS_DRIVER = 'EXPRESS_DRIVER',
}

export enum IndividualSortParameter {
    ID = 'ID',
    CUSTOMER = 'CUSTOMER',
    TYPE = 'TYPE',
    EMAIL = 'EMAIL',
    FULL_NAME = 'FULL_NAME',
    CREATED_AT = 'CREATED_AT',
    LAST_LOGIN = 'LAST_LOGIN',
}

export const individualTypeLabel = [
    { id: IndividualType.TRADER_ADMIN, value: 'Trader admin' },
    { id: IndividualType.TRADER_USER, value: 'Trader user' },
    { id: IndividualType.BROKER_ADMIN, value: 'Broker admin' },
    { id: IndividualType.BROKER_CLERK, value: 'Broker clerk' },
    { id: IndividualType.HAULIER_ADMIN, value: 'Haulier admin' },
    { id: IndividualType.DRIVER, value: 'Driver' },
] as const;

export const traderIndividualTypeLabel = [
    { id: IndividualType.TRADER_ADMIN, value: 'Trader admin' },
    { id: IndividualType.TRADER_USER, value: 'Trader user' },
] as const;

export const brokerIndividualTypeLabel = [
    { id: IndividualType.BROKER_ADMIN, value: 'Broker admin' },
    { id: IndividualType.BROKER_CLERK, value: 'Broker clerk' },
] as const;

export const driverIndividualTypeLabel = [
    { id: IndividualType.HAULIER_ADMIN, value: 'Haulier admin' },
    { id: IndividualType.DRIVER, value: 'Driver' },
] as const;
