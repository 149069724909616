import { AutoComplete, AutoCompleteProps } from 'antd';
import { FC } from 'react';
import { StyledAutoComplete, Input, SearchOutlined } from './SearchBar.style';

interface Props extends Omit<AutoCompleteProps, 'placeholder'> {
    inputPlaceholder?: string;
}

const SearchBar: FC<Props> = (props) => {
    const { inputPlaceholder, ...autoCompleteProps } = props;
    const size = props.size ? props.size : 'large';

    return (
        <StyledAutoComplete>
            <AutoComplete
                getPopupContainer={(trigger: any) => trigger.parentElement}
                allowClear
                {...autoCompleteProps}
                dropdownStyle={{ borderRadius: '4px' }}
            >
                <Input size={size} bordered={false} placeholder={inputPlaceholder ?? ''} prefix={<SearchOutlined />} />
            </AutoComplete>
        </StyledAutoComplete>
    );
};

export const { Option } = AutoComplete;
export default SearchBar;
