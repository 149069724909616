import CurrencySelect from 'components/ui/composed/declarations/CurrencySelect';
import DeclarationNumberInput from 'components/ui/composed/declarations/formInput/DeclarationNumberInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { measureUnitQualifiersLabels } from 'store/declarations/enums/uk/measure-unit-qualifiers';
import { measureUnitTypeLabels } from 'store/declarations/enums/uk/measure-unit-types';
import BorderlessCard from 'views/declarations/common/cards/borderless-card/BorderlessCard';
import { DeclarationMultipleFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getCardState, getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { FormCardContainer } from '../../../../../common/cards/NewFormCard';

const TaxCard: FC<DeclarationMultipleFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    const index = props.index;
    const size = props.size;
    const last = index === size - 1;

    const state = useMemo(
        () =>
            getCardState(
                [
                    { name: `calculationOfTaxes.${index}.payableTaxAmount`, required: false },
                    { name: `calculationOfTaxes.${index}.payableTaxAmountCurrency`, required: false },
                    { name: `calculationOfTaxes.${index}.taxType`, required: false },
                    { name: `calculationOfTaxes.${index}.taxBaseQuantity`, required: false },
                    { name: `calculationOfTaxes.${index}.taxRate`, required: false },
                    { name: `calculationOfTaxes.${index}.types`, required: false },
                    { name: `calculationOfTaxes.${index}.qualifiers`, required: false },
                    { name: `calculationOfTaxes.${index}.taxTotal`, required: false },
                    { name: `calculationOfTaxes.${index}.taxTotalCurrency`, required: false },
                    { name: `calculationOfTaxes.${index}.method`, required: false },
                    { name: `calculationOfTaxes.${index}.preference`, required: true },
                ],
                props
            ),
        [index, props]
    );

    return (
        <BorderlessCard
            defaultOpen={props.defaultOpen}
            viewOnly={props.viewOnly}
            title={`D. ${t('Calculation of Taxes')}`}
            multiple
            addCard={() => {
                if (last) {
                    props.add();
                }
            }}
            removeCard={() => {
                if (props.size > 1) {
                    props.remove(index);
                }
            }}
            isLast={last}
            isFirst={index === 0}
            state={state}
        >
            <FormCardContainer>
                <DeclarationSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`calculationOfTaxes.${index}.taxType`, props)}
                    refNumber="4.3"
                    label={t('taxType')}
                    selectOptions={normalizeCodesToSelect(codelists?.ukDutyTaxFeeTypes || [])}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.3', t('taxType'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`calculationOfTaxes.${index}.taxBaseQuantity`, props)}
                    refNumber="4.4"
                    label={t('taxBaseQuantity')}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.4', t('taxBaseQuantity'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`calculationOfTaxes.${index}.taxRate`, props)}
                    refNumber="4.5"
                    label={t('taxRate')}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.5', t('taxRate'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationSelect
                    hideKeys
                    refNumber="4.4"
                    label={t('types')}
                    selectOptions={measureUnitTypeLabels}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`calculationOfTaxes.${index}.types`, props)}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.4', t('types'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationSelect
                    hideKeys
                    refNumber="4.4"
                    label={t('qualifiers')}
                    selectOptions={measureUnitQualifiersLabels}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`calculationOfTaxes.${index}.qualifiers`, props)}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.4', t('qualifiers'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`calculationOfTaxes.${index}.payableTaxAmount`, props)}
                    refNumber="4.6"
                    label={t('payableTaxAmount')}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.6', t('payableTaxAmount'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CurrencySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`calculationOfTaxes.${index}.payableTaxAmountCurrency`, props)}
                    refNumber="4.6"
                    label={t('payableTaxAmountCurrency')}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.6', t('payableTaxAmountCurrency'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationNumberInput
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`calculationOfTaxes.${index}.taxTotal`, props)}
                    refNumber="4.7"
                    label={t('calculationOfTaxeTotal')}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.7', t('calculationOfTaxeTotal'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <CurrencySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`calculationOfTaxes.${index}.taxTotalCurrency`, props)}
                    refNumber="4.7"
                    tooltip={getCdsTooltipsByRefNumberAndField('4.7', t('currency'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`calculationOfTaxes.${index}.method`, props)}
                    refNumber="4.8"
                    label={t('methodOfPayment')}
                    selectOptions={normalizeCodesToSelect(codelists?.ukPaymentMethodTypes || [])}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.8', t('methodOfPayment'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <DeclarationSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`calculationOfTaxes.${index}.preference`, props)}
                    refNumber="4.17"
                    label={t('preference')}
                    selectOptions={normalizeCodesToSelect(codelists?.ukPreferences || [])}
                    tooltip={getCdsTooltipsByRefNumberAndField('4.17', t('preference'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </BorderlessCard>
    );
};
export default TaxCard;
