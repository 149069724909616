import CountrySelect from 'components/ui/composed/declarations/CountrySelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useCodelists from 'hooks/useCodelists';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeCodesToSelect } from 'store/codelists/code';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../common/cards/NewFormCard';

const BorderTransportMeansCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { codelists } = useCodelists();
    const { getIrelandExportTooltipsByRefNumber, getIrelandExportTooltipsByRefNumberAndField } = useTooltips();

    const handleToggleHelp = (refNumber: string | number): void => {
        props.toggleHelp && props.toggleHelp(refNumber);
    };

    return (
        <NewFormCard title={t('transportInformation')}>
            <FormCardContainer>
                <FormInput
                    maxLength={35}
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsShipment.consignment.borderTransportMeans.identityCrossingBorder`, props)}
                    refNumber="21.1"
                    tooltip="Click for more information"
                    refClicked={handleToggleHelp}
                    label={t('consignment.borderTransportMeans.identity')}
                    condensed
                />

                <FormInput
                    viewOnly={props.viewOnly}
                    maxLength={35}
                    {...getFormikProps(
                        `goodsShipment.consignment.borderTransportMeans.conveyanceReferenceNumber`,
                        props
                    )}
                    label={t('conveyanceReferenceNumber')}
                    tooltip={t('defaultTooltip')}
                    condensed
                />

                <DeclarationSelect
                    required
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsShipment.consignment.borderTransportMeans.transportMode`, props)}
                    refNumber="26"
                    tooltip={getIrelandExportTooltipsByRefNumber('26')}
                    refClicked={handleToggleHelp}
                    label={t('consignment.borderTransportMeans.modeOfTransport')}
                    selectOptions={normalizeCodesToSelect(codelists?.irelandTransportModes || [])}
                    condensed
                />

                <FormSelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(`goodsShipment.transportMethodOfPaymentCode`, props)}
                    label={t('transportChargesMethodOfPayment')}
                    refNumber="A"
                    tooltip={getIrelandExportTooltipsByRefNumberAndField('A', 'Transport Charges - Method of Payment')}
                    refClicked={handleToggleHelp}
                    selectOptions={[
                        {
                            id: 'A',
                            value: 'Payment in cash',
                        },
                        {
                            id: 'B',
                            value: 'Payment by credit card',
                        },
                        {
                            id: 'C',
                            value: 'Payment by cheque',
                        },
                        {
                            id: 'D',
                            value: 'Other (e.g. direct debit to cash account)',
                        },
                        {
                            id: 'H',
                            value: 'Electronic credit transfer',
                        },
                        {
                            id: 'Y',
                            value: 'Account holder with carrier',
                        },
                        {
                            id: 'Z',
                            value: 'Not pre-paid',
                        },
                    ]}
                    condensed
                />

                <CountrySelect
                    viewOnly={props.viewOnly}
                    {...getFormikProps(
                        `goodsShipment.consignment.borderTransportMeans.nationalityCrossingBorder`,
                        props
                    )}
                    refNumber="21.2"
                    tooltip={getIrelandExportTooltipsByRefNumber('21.2')}
                    refClicked={handleToggleHelp}
                    label={t('consignment.borderTransportMeans.nationality')}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};

export default BorderTransportMeansCard;
