import { Col, Row } from 'antd';
import Button from 'components/ui/base/button';
import Modal from 'components/ui/base/modal/Modal';
import Notification from 'components/ui/base/notification/Notification';
import { H5 } from 'components/ui/base/typography';
import { PaginatedParams } from 'core/http/pagination';
import useDigitalCertificates from 'hooks/useDigitalCertificates';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReverseCol } from 'views/dashboard/components/Dashboard.styles';
import AddDigitalCertificate from './AddDigitalCertificate';
import DigitalCertificateTable from './DigitalCertificatesTable';

const DigitalCertificate: FC = () => {
    const { t } = useTranslation('settings');
    const { digitalCertificates, isLoading, listDigitalCertificates, deleteDigitalCertificate } =
        useDigitalCertificates();
    const [addDigitalCertificate, setAddDigitalCertificate] = useState(false);
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>({ size: 10 });
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    useEffect(() => {
        if (paginator) {
            listDigitalCertificates(paginator);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator]);

    const handleSort = (params: Partial<PaginatedParams>) => {
        setPaginator({ ...paginator, ...params });
    };

    const handlePagination = (params: Partial<PaginatedParams>) => {
        setPaginator({ page: params.page, size: paginator.size });
    };

    const handleDelete = (ids: string[]) => {
        setDeleteIds(ids);
        setDeleteModalVisible(true);
    };

    const deleteDigitalCertificates = async () => {
        Promise.all(
            deleteIds?.map(async (id: string) => {
                await deleteDigitalCertificate(id);
            })
        )
            .then(() => {
                listDigitalCertificates();
                setDeleteModalVisible(false);
                setDeleteIds([]);
                Notification({
                    type: 'success',
                    messageTitle: t('digitalCertificate.deleteSuccessTitle'),
                    description: t('digitalCertificate.deleteSuccessMessage'),
                });
            })
            .catch(() => {
                Notification({
                    type: 'error',
                    messageTitle: t('digitalCertificate.deleteErrorTitle'),
                    description: t('digitalCertificate.deleteErrorMessage'),
                });
            });
    };

    return (
        <>
            <Row>
                <Col span={12}>
                    <H5>{t('digitalCertificate.title')}</H5>
                </Col>
                <ReverseCol span={12}>
                    <Button size="large" onClick={() => setAddDigitalCertificate(true)}>
                        {t('digitalCertificate.addCertificate')}
                    </Button>
                </ReverseCol>
            </Row>

            <DigitalCertificateTable
                data={digitalCertificates}
                loading={isLoading}
                handleSort={handleSort}
                handlePagination={handlePagination}
                onDelete={handleDelete}
            />

            <AddDigitalCertificate
                visible={addDigitalCertificate}
                handleCancel={() => setAddDigitalCertificate(false)}
                handleOk={() => {
                    listDigitalCertificates();
                    setAddDigitalCertificate(false);
                }}
            />

            <Modal
                title={
                    deleteIds.length > 1 ? (
                        <H5>{t('digitalCertificate.multiple-delete-title')}</H5>
                    ) : (
                        <H5> {t('digitalCertificate.delete-title')}</H5>
                    )
                }
                centered
                visible={deleteModalVisible}
                onOk={deleteDigitalCertificates}
                onCancel={() => setDeleteModalVisible(false)}
                width={762}
                contentText={
                    deleteIds.length > 1
                        ? t('digitalCertificate.multiple-delete-text')
                        : t('digitalCertificate.delete-text')
                }
            />
        </>
    );
};

export default DigitalCertificate;
