import { FormikProps } from 'formik';
import useDeclarationHeader from 'hooks/useDeclarationHeader';
import useDeclarations from 'hooks/useDeclarations';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import { useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Declaration, NotifyEmails } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { CdsExportDeclaration, CdsExportGovernmentAgencyGoodsItem } from 'store/declarations/uk/export-declaration';
import { DeclarationErrors } from 'views/declarations/common/declaration-errors';
import DeclarationErrorsDrawer from 'views/declarations/common/declaration-errors-drawer/DeclarationErrorsDrawer';
import SubmitModal from 'views/declarations/common/submit-modal/SubmitModal';
import DeclarationTabKeys from 'views/declarations/utils/DeclarationTabKeys';
import { ContentWithoutFooter } from 'views/declarations/Form.styles';
import { FormAction } from 'views/declarations/utils/form-utils';
import { DeclarationStatus } from '../../../../store/declarations/enums/common/declaration-status';

interface Props {
    activeTabKey: DeclarationTabKeys;
    hasHmrc?: boolean;
    setActiveTabKey: Function;
    declaration: Declaration;
    formik: FormikProps<CdsExportDeclaration>;
    productsFormik: FormikProps<CdsExportGovernmentAgencyGoodsItem>;
    declarationErrors: DeclarationErrors;
    setEmails: Function;
    showSubmitModal: boolean;
    setShowSubmitModal: Function;
    setCancelClicked: Function;
    cancelClicked: boolean;
    saveProductClicked: boolean;
    setSaveProductClicked: Function;
    jobCustomerId: string;
    showErrorDrawer: boolean;
    setShowErrorDrawer: Function;
    handleSubmitButton: () => void;
}
export const UkExportContent = ({
    activeTabKey,
    setActiveTabKey,
    declaration,
    formik,
    productsFormik,
    declarationErrors,
    setEmails,
    setShowSubmitModal,
    showSubmitModal,
    cancelClicked,
    setCancelClicked,
    saveProductClicked,
    setSaveProductClicked,
    hasHmrc,
    showErrorDrawer,
    setShowErrorDrawer,
    handleSubmitButton,
}: Props) => {
    const { declarationHeaderCollapsed } = useDeclarationHeader();
    const { getDeclaration } = useDeclarations();

    const declarationId = useMemo(() => declaration?.id ?? '', [declaration]);
    const declarationCustomerId = useMemo(() => declaration?.customerId ?? '', [declaration]);
    const { setFormAction } = useDeclarationValidation();

    const navigate = useNavigate();
    const [createAmendment, setCreateAmendment] = useState(false);

    const formTabContent = useMemo(() => {
        const handleSubmitAndNotify = (emails: NotifyEmails) => {
            setEmails(emails);
            setFormAction(FormAction.SUBMIT);
            setShowSubmitModal(false);
        };

        if (declaration) {
            return (
                declaration.status !== DeclarationStatus.RELEASED && (
                    <>
                        <Outlet
                            context={{
                                declarationErrors,
                                hasAlert: !hasHmrc,
                                formik,
                                productsFormik,
                                cancelProducts: cancelClicked,
                                clearCancel: async () => {
                                    await getDeclaration(declarationId);
                                    setCancelClicked(false);
                                },
                                saveProduct: saveProductClicked,
                                clearSaveProduct: () => {
                                    setSaveProductClicked(false);
                                },
                                updateDeclaration: () => getDeclaration(declarationId),
                            }}
                        />
                        <SubmitModal
                            visible={showSubmitModal}
                            handleSubmit={handleSubmitButton}
                            handleCancel={() => {
                                setShowSubmitModal(false);
                            }}
                            handleSubmitAndNotify={handleSubmitAndNotify}
                            customerId={declaration.customerId!}
                        />
                    </>
                )
            );
        }

        return <></>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        handleSubmitButton,
        formik,
        declaration,
        cancelClicked,
        getDeclaration,
        productsFormik,
        saveProductClicked,
        showSubmitModal,
        declarationErrors,
        declarationId,
        setCancelClicked,
        setEmails,
        setSaveProductClicked,
        setShowSubmitModal,
        setActiveTabKey,
    ]);

    const handleContent = useMemo(() => {
        switch (activeTabKey) {
            case DeclarationTabKeys.FORM:
                return formTabContent;
            case DeclarationTabKeys.SUMMARY:
                return (
                    <Outlet
                        context={{
                            declaration: declaration,
                            country: DeclarationCountry.UK,
                            showFormInfo: () => {
                                setActiveTabKey(DeclarationTabKeys.VIEW_ONLY);
                                navigate(`/declarations/${declarationId}/${DeclarationTabKeys.VIEW_ONLY}`, {
                                    state: { activeTabKey: DeclarationTabKeys.VIEW_ONLY },
                                });
                            },
                            createAmendment: () => {
                                setCreateAmendment(true);
                                navigate(`/declarations/${declarationId}/${DeclarationTabKeys.VIEW_ONLY}`, {
                                    state: { activeTabKey: DeclarationTabKeys.VIEW_ONLY },
                                });
                                setActiveTabKey(DeclarationTabKeys.VIEW_ONLY);
                            },
                        }}
                    />
                );
            case DeclarationTabKeys.DOCUMENTATION:
                return (
                    <ContentWithoutFooter collapsedHeader={declarationHeaderCollapsed}>
                        <Outlet
                            context={{
                                customerId: declarationCustomerId,
                                formId: declarationId,
                                country: DeclarationCountry.UK,
                            }}
                        />
                    </ContentWithoutFooter>
                );
            case DeclarationTabKeys.CUSTOMER_DETAILS:
                return (
                    <ContentWithoutFooter collapsedHeader={declarationHeaderCollapsed}>
                        <Outlet context={{ customerId: declarationCustomerId }} />
                    </ContentWithoutFooter>
                );
            case DeclarationTabKeys.HISTORY:
                return <Outlet />;

            case DeclarationTabKeys.VIEW_ONLY:
                return (
                    <Outlet
                        context={{
                            formik: formik,
                            productsFormik: productsFormik,
                            declaration: declaration!,
                            country: DeclarationCountry.UK,
                            activeTabKey: activeTabKey,
                            amendment: createAmendment,
                            createAmendment: () => {
                                setCreateAmendment(true);
                            },
                            cancelAmendment: () => {
                                if (declaration.status === 'RISKED') {
                                    setCreateAmendment(false);
                                } else {
                                    setCreateAmendment(false);
                                    setActiveTabKey(DeclarationTabKeys.FORM);
                                    navigate(`/declarations/${declarationId}`, {
                                        state: { activeTabKey: DeclarationTabKeys.FORM },
                                    });
                                }
                            },
                        }}
                    />
                );
        }
    }, [
        activeTabKey,
        formTabContent,
        declaration,
        declarationHeaderCollapsed,
        declarationCustomerId,
        declarationId,
        formik,
        productsFormik,
        createAmendment,
        setActiveTabKey,
        navigate,
    ]);

    return (
        <>
            {handleContent}
            <DeclarationErrorsDrawer
                visible={showErrorDrawer}
                onClose={() => setShowErrorDrawer(false)}
                declaration={declaration}
                formik={formik}
            />
        </>
    );
};

export default UkExportContent;
