import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import useTooltips from 'hooks/useTooltips';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { warehouseTypeLabels } from 'store/declarations/enums/common/warehouse-type';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import { StyledInfoCircleFilled } from 'views/declarations/Form.styles';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import NewFormCard, { FormCardContainer } from '../../../../common/cards/NewFormCard';

const IdentificationWarehouseCard: FC<DeclarationFormCardProps> = (props) => {
    const { t } = useTranslation('form');
    const { getCdsTooltipsByRefNumberAndField } = useTooltips();

    return (
        <NewFormCard title={t('identificationWarehouse')}>
            <FormCardContainer>
                <FormInput
                    maxLength={35}
                    icon={<StyledInfoCircleFilled />}
                    refNumber="2.7"
                    label={t('warehouseIdentifier')}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('goodsShipment.warehouseIdentifier', props)}
                    tooltip={getCdsTooltipsByRefNumberAndField('2.7', t('warehouseIdentifier'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
                <FormSelect
                    icon={<StyledInfoCircleFilled />}
                    refNumber="2.7"
                    label={t('warehouseType')}
                    selectOptions={warehouseTypeLabels}
                    viewOnly={props.viewOnly}
                    {...getFormikProps('goodsShipment.warehouseType', props)}
                    tooltip={getCdsTooltipsByRefNumberAndField('2.7', t('warehouseType'))}
                    refClicked={(ref) => handleToggleHelp(ref, props)}
                    condensed
                />
            </FormCardContainer>
        </NewFormCard>
    );
};
export default IdentificationWarehouseCard;
