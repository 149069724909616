import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { useFormikContext } from 'formik';
import { find } from 'lodash';
import { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    previousProcedureCodeOptions,
    requestedProcedureCodeOptions,
} from 'store/declarations/enums/uk/cds-export-procedure-code-code-lists';
import { TemplateData } from 'store/template/template';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { ValidationSchema, validators } from '../validations/validations';

export type GovernmentProcedure = {
    governmentProcedure: { currentCode: string; previousCode: string; additionalCurrentCode: string[] };
};

export type Fields = 'currentCode' | 'previousCode';

export const procedureBlockValidation: {
    childValidators: {
        governmentProcedure: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        governmentProcedure: {
            childValidators: {
                currentCode: [validators.required(), validators.exact(2)],
                previousCode: [validators.required(), validators.exact(2)],
            },
        },
    },
};

export const procedureFields = procedureBlockValidation.childValidators.governmentProcedure;

interface Props extends BlockProps<Fields> {}

const ProcedureBlock = ({ path = 'governmentProcedure', fieldOptions }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { template, templateFormik, form } = useTemplateContext();

    const mainFormik = useFormikContext<GovernmentProcedure>();

    const formik = useMemo(
        () => (template && templateFormik ? templateFormik : mainFormik),
        [mainFormik, template, templateFormik]
    );

    const { governmentProcedure, governmentProcedurePath } = useMemo(() => {
        if (template && form && templateFormik)
            return {
                governmentProcedurePath: `${form}.defaults.governmentProcedure`,
                governmentProcedure: (formik.values as TemplateData)[form].defaults
                    .governmentProcedure as unknown as GovernmentProcedure['governmentProcedure'],
            };
        return {
            governmentProcedurePath: 'governmentProcedure',
            governmentProcedure: (formik.values as GovernmentProcedure | undefined)?.governmentProcedure,
        };
    }, [form, formik.values, template, templateFormik]);

    const previousProcedureCodeCodeList = useMemo(
        () => previousProcedureCodeOptions[governmentProcedure?.currentCode as '10' | '11' | '21' | '22' | '23' | '31'],
        [governmentProcedure]
    );

    const ucc = useMemo(() => '1/10', []);

    useEffect(() => {
        const previousCode = governmentProcedure?.previousCode;

        if (!find(previousProcedureCodeCodeList, (code: any) => code.id === previousCode)) {
            formik.getFieldHelpers(`${governmentProcedurePath}.previousCode`).setValue(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.values, previousProcedureCodeCodeList]);

    return (
        <>
            <DeclarationField pathPrefix={path} name="currentCode">
                {({ form, field }) => (
                    <FormSelect
                        required
                        {...getFormikProps(field.name, form)}
                        refNumber={ucc}
                        label={t('requestedProcedure')}
                        selectOptions={requestedProcedureCodeOptions}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="previousCode">
                {({ form, field }) => (
                    <FormSelect
                        required
                        {...getFormikProps(field.name, form)}
                        refNumber={ucc}
                        label={t('previousProcedure')}
                        selectOptions={previousProcedureCodeCodeList}
                        condensed
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default ProcedureBlock;
