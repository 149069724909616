import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import { FC } from 'react';
import { Icon, ListRow, ListRowControls, StyledTextSmall } from '../CardItemRow.styles';
import { isEmpty } from 'lodash';

interface PropsRowCol {
    field: string;
    value: string | string[];
}

interface PropsRow {
    cols: PropsRowCol[];
    onDelete?: Function;
    onEdit: Function;
    viewOnly: boolean | undefined;
    condensed?: boolean;
    last?: boolean;
    hasError?: boolean;
}

type Data = { field: string; value: string | undefined };

interface Props {
    data: Data[][];
    onDelete: (index: number, obj: Data[]) => void;
    onEdit: (index: number, obj: Data[]) => void;
    viewOnly: boolean | undefined;
    required?: boolean;
    condensed?: boolean;
    errors?: any[];
}

const CardItemRow: FC<PropsRow> = ({ cols, onEdit, onDelete, viewOnly, condensed, last, hasError }) => {
    const parseValue = (value: string | string[]) => {
        if (!value ?? isEmpty(value)) return '-';
        return Array.isArray(value) ? value.join(',') : value;
    };

    return (
        <>
            <ListRow hasError={hasError}>
                <div style={{ display: 'flex', gap: '0.5rem 5rem', flexWrap: 'wrap' }}>
                    {cols.map((obj, index) => {
                        return (
                            <div key={index}>
                                <StyledTextSmall style={{ display: 'flex', width: 'auto' }}>
                                    <span>{obj.field}: </span>
                                    <span style={{ marginLeft: '7px' }}>{parseValue(obj.value)} </span>
                                </StyledTextSmall>
                            </div>
                        );
                    })}
                </div>
                {!viewOnly && (
                    <ListRowControls>
                        <Icon onClick={() => onEdit()}>
                            <EditOutlined />
                        </Icon>
                        {onDelete && (
                            <Icon onClick={() => onDelete?.()}>
                                <DeleteOutlined />
                            </Icon>
                        )}
                    </ListRowControls>
                )}
            </ListRow>
            {!last && <Divider style={{ margin: condensed ? '0.5rem 0' : '1rem 0 2rem 0' }} />}
        </>
    );
};

const CardList: FC<Props> = ({ data, onDelete, onEdit, viewOnly, required, condensed, errors }) => {
    return (
        <div style={{ marginTop: required ? '2rem' : '0' }}>
            {data?.map((obj, index) => {
                const hasError = errors && !isEmpty(errors?.at(index));
                return (
                    <CardItemRow
                        key={index}
                        hasError={hasError}
                        cols={obj as PropsRowCol[]}
                        viewOnly={viewOnly}
                        onDelete={required && index === 0 ? undefined : () => onDelete(index, obj)}
                        onEdit={() => onEdit(index, obj)}
                        last={index === data.length - 1}
                        condensed={condensed}
                    />
                );
            })}
        </div>
    );
};

export default CardList;
