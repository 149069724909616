import { useDispatch } from 'react-redux';
import { DocumentsFilterFields } from 'store/documents/document';
import { useAppSelector } from '../config/hooks';
import { doDeleteDocument, doListDocuments } from '../store/documents/action';

const useDocuments = () => {
    const dispatch = useDispatch();
    const documents = useAppSelector((state) => state.documents.documents);
    const isLoading = useAppSelector((state) => state.documents.isLoading);
    const error = useAppSelector((state) => state.documents.error);

    const listDocuments = (params?: Partial<DocumentsFilterFields>) => {
        return dispatch(doListDocuments(params));
    };

    const deleteDocument = (id: string) => {
        return dispatch(doDeleteDocument(id));
    };

    return {
        listDocuments,
        documents,
        deleteDocument,
        isLoading,
        error,
    };
};

export default useDocuments;
