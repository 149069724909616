import useTooltips from 'hooks/useTooltips';
import { FC, useEffect } from 'react';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationFormCardProps } from 'views/declarations/common/declaration.form.card';
import FormInput from '../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import taricCodeLookUp from '../../../../utils/taricCodeLookUp';
import { getFormikProps, handleToggleHelp } from '../../utils/form-utils';
import NewFormCard, { FormCardContainer } from '../cards/NewFormCard';
import EuIcon from './components/EuIcon';
import UkIcon from './components/UkIcon';

export enum TaricDetailsType {
    IMPORT = 'IMPORT',
    EXPORT = 'EXPORT',
    BOTH = 'BOTH',
}

interface Props extends DeclarationFormCardProps {
    updateTaricCode: (code: string) => void;
    country: DeclarationCountry;
    value?: string;
    detailsType: TaricDetailsType;
    required?: boolean;
    label?: string;
    path?: string;
}

const TaricCodesCard: FC<Props> = (props) => {
    const { getH7TooltipsByRefNumberAndField } = useTooltips();
    const {
        defaultOpen,
        viewOnly,
        cardNumber,
        cardTotal,
        updateTaricCode,
        country,
        value,
        detailsType,
        expandAll,
        required,
        label,
        path,
        ...formikProps
    } = props;

    const commodityCodeProps = getFormikProps(path ?? 'commodityCodeField', formikProps);

    useEffect(() => {
        if (!!value) {
            commodityCodeProps.fieldHelper?.setValue(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <section
            id="taric-code-form-card"
            onClick={() => {
                if (props.selectCard) {
                    props.selectCard('taric-code-form-card');
                }
            }}
        >
            <NewFormCard title="Taric Code" icon={country === DeclarationCountry.IRELAND ? <EuIcon /> : <UkIcon />}>
                <FormCardContainer>
                    <FormInput
                        required={required}
                        viewOnly={viewOnly}
                        label={label ?? 'Commodity Code'}
                        {...commodityCodeProps}
                        tooltip={getH7TooltipsByRefNumberAndField('6.14', 'Commodity Code')}
                        refClicked={(ref) => handleToggleHelp(ref, props)}
                        fieldChange={{
                            onChange: (e) => commodityCodeProps.fieldHelper?.setValue(e.target.value),
                        }}
                        onSearchTaricCode={() => taricCodeLookUp(props.country)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>
        </section>
    );
};
export default TaricCodesCard;
