import { cloneDeep } from 'lodash';
import {
    CdsExportAdditionalDocument,
    CdsExportClassificationBE,
    CdsExportDeclaration,
    CdsExportGovernmentAgencyGoodsItem,
    CdsExportGovernmentProcedureServer,
} from 'store/declarations/uk/export-declaration';

type CommodityCodeIdentifier = { id: string | undefined };
type ClientCommodityCodes = {
    cv: CommodityCodeIdentifier;
    tsp: CommodityCodeIdentifier;
    tra: CommodityCodeIdentifier[];
    gn: CommodityCodeIdentifier[];
};

type AdditionalDocumentFE = Omit<CdsExportAdditionalDocument, 'categoryType' | 'typeCode'> & { code?: string };

const transformAdditionalDocumentForClient = (product: CdsExportGovernmentAgencyGoodsItem | undefined | null) => {
    return product?.additionalDocument?.reduce((acc, document) => {
        const code = (document.categoryCode ?? '') + (document.typeCode ?? '');
        delete document.categoryCode;
        delete document.typeCode;
        const newDocument = cloneDeep(document) as AdditionalDocumentFE;
        newDocument.code = code;
        acc.push(newDocument);
        return acc;
    }, [] as AdditionalDocumentFE[]);
};
const transformAdditionalDocumentForServer = (product: CdsExportGovernmentAgencyGoodsItem | undefined | null) => {
    return product?.additionalDocument?.reduce((acc, _document) => {
        const document = cloneDeep(_document) as AdditionalDocumentFE;
        const categoryCode = document.code?.substring(0, 1);
        const typeCode = document.code?.substring(1);
        delete document.code;
        const newDocument = cloneDeep(document) as CdsExportAdditionalDocument;
        newDocument.categoryCode = categoryCode;
        newDocument.typeCode = typeCode;
        acc.push(newDocument);
        return acc;
    }, [] as AdditionalDocumentFE[]);
};

const transformCommodityCodesForClient = (product: CdsExportGovernmentAgencyGoodsItem | undefined | null) => {
    return product?.commodity?.classification?.reduce((acc, commodityCode) => {
        const { id, identificationTypeCode } = commodityCode;
        const key = identificationTypeCode?.toLowerCase() as 'cv' | 'tsp' | 'tra' | 'gn';
        switch (identificationTypeCode) {
            case 'CV':
            case 'TSP':
                acc[key as 'cv' | 'tsp'] = { id };
                return acc;
            case 'TRA':
            case 'GN':
                if (!acc[key]) acc[key as 'tra' | 'gn'] = [];
                acc[key as 'tra' | 'gn'].push({ id });
                return acc;
            default:
                return acc;
        }
    }, {} as ClientCommodityCodes);
};
const transformCommodityCodesForServer = (product: CdsExportGovernmentAgencyGoodsItem | undefined | null) => {
    const commodityCodes = product?.commodity?.classification as unknown as ClientCommodityCodes | undefined;
    return (
        commodityCodes &&
        Object.entries(commodityCodes).reduce((acc, [key, value]) => {
            switch (key) {
                case 'cv':
                case 'tsp':
                    if ((value as CommodityCodeIdentifier | undefined)?.id)
                        acc.push({
                            id: (value as CommodityCodeIdentifier).id,
                            identificationTypeCode: key.toUpperCase(),
                        });
                    return acc;
                case 'tra':
                case 'gn':
                    (value as (CommodityCodeIdentifier | undefined)[]).forEach((v) => {
                        if (v?.id) acc.push({ id: v.id, identificationTypeCode: key.toUpperCase() });
                    });
                    return acc;
                default:
                    return acc;
            }
        }, [] as CdsExportClassificationBE[])
    );
};

class CdsExportDeclarationUtils {
    static transformForClient(_cdsDeclaration: CdsExportDeclaration) {
        const cdsDeclaration = cloneDeep(_cdsDeclaration);
        const payload = { ...cdsDeclaration.cdsExportDeclarationPayload };

        const typeCode = payload?.typeCode?.substring(0, 2);
        const additionalTypeCode = payload?.typeCode?.substring(2);

        const ducr = payload.goodsShipment?.previousDocument?.find((prevDoc) => prevDoc.typeCode === 'DCR')?.id;
        const mucr = payload.goodsShipment?.previousDocument?.find((prevDoc) => prevDoc.typeCode === 'MCR')?.id;

        return {
            ...cdsDeclaration,
            cdsExportDeclarationPayload: {
                ...payload,
                typeCode,
                additionalTypeCode,
                ducr,
                mucr,
                goodsShipment: {
                    ...payload.goodsShipment,
                    governmentAgencyGoodsItem: cdsDeclaration.cdsExportGovernmentAgencyGoodsItems?.map((item) =>
                        this.transformProductForClient(item.governmentAgencyGoodsItem)
                    ),
                },
            },
        };
    }

    static transformForServer(_cdsDeclaration: CdsExportDeclaration) {
        const cdsDeclaration = cloneDeep(_cdsDeclaration);
        const payload = { ...cdsDeclaration.cdsExportDeclarationPayload };

        delete payload.goodsShipment?.governmentAgencyGoodsItem;

        let typeCode = payload.typeCode;
        if (payload.additionalTypeCode) {
            typeCode += payload.additionalTypeCode;
            delete payload.additionalTypeCode;
        }

        delete payload?.ducr;
        delete payload?.mucr;

        return {
            ...cdsDeclaration,
            declarationType: cdsDeclaration.cdsExportDeclarationPayload?.typeCode,
            additionalDeclarationType: cdsDeclaration.cdsExportDeclarationPayload?.additionalTypeCode,
            cdsExportDeclarationPayload: {
                ...payload,
                typeCode,
            },
        };
    }

    static transformProductForClient(_product: CdsExportGovernmentAgencyGoodsItem | null) {
        const product = cloneDeep(_product);
        let currentCode: string | undefined;
        let previousCode: string | undefined;
        let additionalCurrentCode: (string | undefined)[] = [];

        (product?.governmentProcedure as CdsExportGovernmentProcedureServer)?.forEach((procedure, index) => {
            if (index === 0) {
                currentCode = procedure.currentCode;
                previousCode = procedure.previousCode;
            } else {
                additionalCurrentCode?.push(procedure.currentCode);
            }
        });

        const dangerousGoods = (product?.commodity?.dangerousGoods as any[])?.[0];
        const commodityCodes = transformCommodityCodesForClient(product);
        const additionalDocument = transformAdditionalDocumentForClient(product);

        return {
            ...product,
            governmentProcedure: {
                currentCode,
                previousCode,
                additionalCurrentCode,
            },
            commodity: {
                ...product?.commodity,
                dangerousGoods: dangerousGoods,
                classification: commodityCodes,
            },
            additionalDocument,
        };
    }

    static transformProductForServer(_product: CdsExportGovernmentAgencyGoodsItem) {
        const product = cloneDeep(_product);
        const additionalCurrentCode = product.governmentProcedure?.additionalCurrentCode?.map(
            (additionalCurrentCode) => ({
                currentCode: additionalCurrentCode,
            })
        );

        let procedures: CdsExportGovernmentProcedureServer = [
            {
                currentCode: product.governmentProcedure?.currentCode,
                previousCode: product.governmentProcedure?.previousCode,
            },
        ];

        if (additionalCurrentCode) {
            procedures = [...procedures, ...additionalCurrentCode];
        }

        const dangerousGoods = [{ undgid: product?.commodity?.dangerousGoods?.undgid }];
        const commodityCodes = transformCommodityCodesForServer(product);
        const additionalDocument = transformAdditionalDocumentForServer(product);

        return {
            ...product,
            governmentProcedure: procedures,
            commodity: {
                ...product?.commodity,
                dangerousGoods: dangerousGoods,
                classification: commodityCodes,
            },
            additionalDocument,
        };
    }
}

export const transformGovernmentProcedurePath = (path: string) => {
    const fieldNameParts = path.split('.');

    const index = fieldNameParts.at(-2);

    fieldNameParts.splice(-2, 1);

    if (index !== '1') {
        fieldNameParts.push((Number(index) - 2).toString());
    }

    return fieldNameParts.join('.');
};

export const transformDangerousGoodsPath = (path: string) => {
    const fieldNameParts = path.split('.');

    if (fieldNameParts.at(-1) !== 'dangerousGoods') {
        fieldNameParts.splice(-2, 1);
    } else {
        fieldNameParts.push('undgid');
    }

    return fieldNameParts.join('.');
};

export const transformAgentPath = (path: string) => {
    return path.replaceAll(/agent/gi, 'representative');
};

export default CdsExportDeclarationUtils;
