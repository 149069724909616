export const documentsCategoryCode = [
    { id: '9001', value: '' },
    { id: '9104', value: '' },
    { id: '9105', value: '' },
    { id: '9106', value: '' },
    { id: '9107', value: '' },
    { id: '9108', value: '' },
    { id: '9111', value: '' },
    { id: '9112', value: '' },
    { id: '9113', value: '' },
    { id: '9114', value: '' },
    { id: '9300', value: '' },
    { id: '9CLM', value: '' },
    { id: '9CPD', value: '' },
    { id: '9ING', value: '' },
    { id: '9RCP', value: '' },
    { id: '9SDC', value: '' },
    { id: 'C019', value: '' },
    { id: 'C034', value: '' },
    { id: 'C039', value: '' },
    { id: 'C040', value: '' },
    { id: 'C041', value: '' },
    { id: 'C042', value: '' },
    { id: 'C043', value: '' },
    { id: 'C047', value: '' },
    { id: 'C050', value: '' },
    { id: 'C052', value: '' },
    { id: 'C064', value: '' },
    { id: 'C068', value: '' },
    { id: 'C069', value: '' },
    { id: 'C070', value: '' },
    { id: 'C100', value: '' },
    { id: 'C400', value: '' },
    { id: 'C401', value: '' },
    { id: 'C402', value: '' },
    { id: 'C403', value: '' },
    { id: 'C404', value: '' },
    { id: 'C405', value: '' },
    { id: 'C406', value: '' },
    { id: 'C501', value: '' },
    { id: 'C502', value: '' },
    { id: 'C503', value: '' },
    { id: 'C504', value: '' },
    { id: 'C505', value: '' },
    { id: 'C506', value: '' },
    { id: 'C507', value: '' },
    { id: 'C509', value: '' },
    { id: 'C510', value: '' },
    { id: 'C511', value: '' },
    { id: 'C512', value: '' },
    { id: 'C513', value: '' },
    { id: 'C514', value: '' },
    { id: 'C516', value: '' },
    { id: 'C517', value: '' },
    { id: 'C518', value: '' },
    { id: 'C519', value: '' },
    { id: 'C520', value: '' },
    { id: 'C521', value: '' },
    { id: 'C522', value: '' },
    { id: 'C523', value: '' },
    { id: 'C524', value: '' },
    { id: 'C525', value: '' },
    { id: 'C600', value: '' },
    { id: 'C601', value: '' },
    { id: 'C603', value: '' },
    { id: 'C604', value: '' },
    { id: 'C605', value: '' },
    { id: 'C606', value: '' },
    { id: 'C610', value: '' },
    { id: 'C612', value: '' },
    { id: 'C613', value: '' },
    { id: 'C614', value: '' },
    { id: 'C615', value: '' },
    { id: 'C616', value: '' },
    { id: 'C617', value: '' },
    { id: 'C618', value: '' },
    { id: 'C619', value: '' },
    { id: 'C620', value: '' },
    { id: 'C621', value: '' },
    { id: 'C624', value: '' },
    { id: 'C625', value: '' },
    { id: 'C626', value: '' },
    { id: 'C627', value: '' },
    { id: 'C640', value: '' },
    { id: 'C644', value: '' },
    { id: 'C645', value: '' },
    { id: 'C647', value: '' },
    { id: 'C651', value: '' },
    { id: 'C652', value: '' },
    { id: 'C656', value: '' },
    { id: 'C657', value: '' },
    { id: 'C659', value: '' },
    { id: 'C660', value: '' },
    { id: 'C661', value: '' },
    { id: 'C669', value: '' },
    { id: 'C670', value: '' },
    { id: 'C672', value: '' },
    { id: 'C673', value: '' },
    { id: 'C676', value: '' },
    { id: 'C990', value: '' },
    { id: 'D019', value: '' },
    { id: 'E012', value: '' },
    { id: 'E013', value: '' },
    { id: 'E014', value: '' },
    { id: 'E020', value: '' },
    { id: 'E990', value: '' },
    { id: 'N002', value: '' },
    { id: 'N003', value: '' },
    { id: 'N018', value: '' },
    { id: 'N235', value: '' },
    { id: 'N271', value: '' },
    { id: 'N325', value: '' },
    { id: 'N380', value: '' },
    { id: 'N703', value: '' },
    { id: 'N704', value: '' },
    { id: 'N705', value: '' },
    { id: 'N710', value: '' },
    { id: 'N714', value: '' },
    { id: 'N720', value: '' },
    { id: 'N722', value: '' },
    { id: 'N730', value: '' },
    { id: 'N740', value: '' },
    { id: 'N741', value: '' },
    { id: 'N750', value: '' },
    { id: 'N760', value: '' },
    { id: 'N785', value: '' },
    { id: 'N787', value: '' },
    { id: 'N820', value: '' },
    { id: 'N821', value: '' },
    { id: 'N822', value: '' },
    { id: 'N825', value: '' },
    { id: 'N830', value: '' },
    { id: 'N864', value: '' },
    { id: 'N865', value: '' },
    { id: 'N933', value: '' },
    { id: 'N941', value: '' },
    { id: 'N951', value: '' },
    { id: 'N952', value: '' },
    { id: 'N954', value: '' },
    { id: 'N955', value: '' },
    { id: 'N990', value: '' },
    { id: 'U045', value: '' },
    { id: 'U048', value: '' },
    { id: 'U088', value: '' },
    { id: 'U090', value: '' },
    { id: 'U091', value: '' },
    { id: 'U162', value: '' },
    { id: 'U164', value: '' },
    { id: 'U165', value: '' },
    { id: 'U166', value: '' },
    { id: 'U167', value: '' },
    { id: 'X001', value: '' },
    { id: 'X002', value: '' },
    { id: 'X035', value: '' },
    { id: 'Y019', value: '' },
    { id: 'Y020', value: '' },
    { id: 'Y021', value: '' },
    { id: 'Y022', value: '' },
    { id: 'Y023', value: '' },
    { id: 'Y024', value: '' },
    { id: 'Y025', value: '' },
    { id: 'Y026', value: '' },
    { id: 'Y027', value: '' },
    { id: 'Y028', value: '' },
    { id: 'Y029', value: '' },
    { id: 'Y031', value: '' },
    { id: 'Y034', value: '' },
    { id: 'Y036', value: '' },
    { id: 'Y037', value: '' },
    { id: 'Y062', value: '' },
    { id: 'Y082', value: '' },
    { id: 'Y083', value: '' },
    { id: 'Y900', value: '' },
    { id: 'Y901', value: '' },
    { id: 'Y903', value: '' },
    { id: 'Y904', value: '' },
    { id: 'Y906', value: '' },
    { id: 'Y907', value: '' },
    { id: 'Y909', value: '' },
    { id: 'Y915', value: '' },
    { id: 'Y916', value: '' },
    { id: 'Y917', value: '' },
    { id: 'Y919', value: '' },
    { id: 'Y920', value: '' },
    { id: 'Y921', value: '' },
    { id: 'Y922', value: '' },
    { id: 'Y923', value: '' },
    { id: 'Y924', value: '' },
    { id: 'Y925', value: '' },
    { id: 'Y927', value: '' },
    { id: 'Y932', value: '' },
    { id: 'Y934', value: '' },
    { id: 'Y935', value: '' },
    { id: 'Y939', value: '' },
    { id: 'Y945', value: '' },
    { id: 'Y946', value: '' },
    { id: 'Y947', value: '' },
    { id: 'Y948', value: '' },
    { id: 'Y949', value: '' },
    { id: 'Y952', value: '' },
    { id: 'Y953', value: '' },
    { id: 'Y961', value: '' },
    { id: 'Y966', value: '' },
    { id: 'Y967', value: '' },
    { id: 'Y969', value: '' },
    { id: 'Y015', value: '' },
    { id: 'C012', value: '' },
    { id: 'Y107', value: '' },
    { id: 'Y108', value: '' },
    { id: 'Y223', value: '' },
    { id: 'Y069', value: '' },
    { id: 'C073', value: '' },
    { id: 'C076', value: '' },
    { id: 'C077', value: '' },
    { id: 'C700', value: '' },
    { id: 'C710', value: '' },
    { id: 'U110', value: '' },
    { id: 'U111', value: '' },
    { id: 'U112', value: '' },
    { id: 'Y105', value: '' },
    { id: 'Y109', value: '' },
    { id: 'Y200', value: '' },
    { id: 'Y201', value: '' },
    { id: 'Y202', value: '' },
    { id: 'Y203', value: '' },
    { id: 'Y204', value: '' },
    { id: 'Y205', value: '' },
    { id: 'Y206', value: '' },
    { id: 'Y207', value: '' },
    { id: 'Y208', value: '' },
    { id: 'Y209', value: '' },
    { id: 'Y210', value: '' },
    { id: 'Y211', value: '' },
    { id: 'Y212', value: '' },
    { id: 'Y213', value: '' },
    { id: 'Y214', value: '' },
    { id: 'Y215', value: '' },
    { id: 'Y216', value: '' },
    { id: 'Y217', value: '' },
    { id: 'Y218', value: '' },
    { id: 'Y219', value: '' },
    { id: 'Y220', value: '' },
    { id: 'Y221', value: '' },
    { id: 'Y222', value: '' },
    { id: 'Y970', value: '' },
    { id: 'Y971', value: '' },
    { id: 'Y957', value: '' },
    { id: '093Y', value: '' },
    { id: '011Y', value: '' },
    { id: 'C658', value: '' },
    { id: 'C089', value: '' },
    { id: 'Y981', value: '' },
    { id: '999L', value: '' },
    { id: 'C689', value: '' },
    { id: 'Y300', value: '' },
    { id: 'Y301', value: '' },
    { id: 'Y918', value: '' },
    { id: 'U113', value: '' },
    { id: 'U114', value: '' },
    { id: 'U115', value: '' },
    { id: 'U116', value: '' },
    { id: 'U117', value: '' },
    { id: 'U118', value: '' },
    { id: 'Y115', value: '' },
    { id: 'Y968', value: '' },
    { id: 'C008', value: '' },
    { id: 'C030', value: '' },
    { id: 'C046', value: '' },
    { id: 'L001', value: '' },
    { id: 'L082', value: '' },
    { id: 'N934', value: '' },
    { id: '9005', value: '' },
    { id: '9103', value: '' },
    { id: 'I004', value: '' },
    { id: 'N852', value: '' },
    { id: 'C678', value: '' },
    { id: 'Y100', value: '' },
    { id: '9011', value: '' },
    { id: '1MIB', value: '' },
];

export const documentStatus = [
    { id: 'AC', value: 'Document attached for certification by customs' },
    {
        id: 'AE',
        value: 'Document attached — exhausted by (or only applies to) this entry (document returned to the trader)',
    },
    {
        id: 'AF',
        value: 'Document attached — exhausted by (or only applies to) this entry (document returned to the relevant department)',
    },
    { id: 'AG', value: 'Document attached — exhausted by (or only applies to) this entry (document retained)' },
    {
        id: 'AP',
        value: 'Document attached — part use (applies to this and other entries) (document returned to the trader)',
    },
    { id: 'AS', value: 'Document attached — surrendered (document returned to the trader)' },
    { id: 'AT', value: 'Document attached — surrendered (document returned to the relevant department)' },
    { id: 'CC', value: 'Authorisation issued under the Community Customs Code — CCG not required' },
    {
        id: 'EA',
        value: 'Electronic details on Customs Declaration Services (CDS) — already attributed on simplified declaration',
    },
    { id: 'EE', value: 'Electronic details on CDS — exhausted' },
    { id: 'EL', value: 'Electronic details on CDS — ‘Late’ declaration after licence completed' },
    { id: 'EP', value: 'Electronic details on CDS — part use (applies to this and other entries)' },
    { id: 'ES', value: 'Electronic details on CDS — surrendered' },
    { id: 'FP', value: 'Facsimile attached — part use (applies to this and other entries)' },
    { id: 'GE', value: 'Document with the goods — exhausted by (or only applies to) this entry' },
    { id: 'GP', value: 'Document with the goods — part use (applies to this and other entries)' },
    { id: 'HP', value: 'Facsimile with the goods — part use (applies to this and other entries)' },
    { id: 'IA', value: 'Details held by issuing authority — already attributed on simplified declaration' },
    { id: 'IE', value: 'Details held by issuing authority — exhausted by (or only applies to) this entry' },
    { id: 'IP', value: 'Details held by issuing authority — part use (applies to this and other entries)' },
    { id: 'IS', value: 'Details held by issuing authority — surrendered' },
    { id: 'JA', value: 'Document held by authorised trader — already attributed on simplified declaration' },
    { id: 'JE', value: 'Document held by authorised trader — exhausted by (or only applies to) this entry' },
    { id: 'JP', value: 'Evidence required is available from trader — part use (applies to this and other entries)' },
    { id: 'JS', value: 'Document held by authorised trader — surrendered' },
    { id: 'LE', value: 'Lodged previously — exhausted by (or only applies to) this entry' },
    { id: 'LP', value: 'Lodged previously — part use (applies to this and other entries)' },
    { id: 'RE', value: 'To be used when declaration of a REX number is required' },
    {
        id: 'SP',
        value: 'Special authorisation for attached facsimile (paper held by trader) — part use (applies to this and other entries)',
    },
    {
        id: 'TP',
        value: 'Special authorisation for facsimile with the goods (paper held by trader) — part use (applies to this and other entries)',
    },
    { id: 'UA', value: 'Paper (held by trader) unavailable — already attributed on simplified declaration' },
    { id: 'UE', value: 'Paper (held by trader) unavailable — exhausted by (or only applies to) this entry' },
    { id: 'UP', value: 'Evidence required is unavailable — part use (applies to this and other entries)' },
    { id: 'US', value: 'Paper (held by trader) unavailable — surrendered' },
    { id: 'XA', value: 'Electronic or paper document to be advised' },
    { id: 'XB', value: 'Document not required — quantity below ‘de minimus’' },
    { id: 'XF', value: 'Document not required — goods not going into free circulation' },
    { id: 'XO', value: 'To be used when declaration of a REX number is not required' },
    {
        id: 'XU',
        value: 'Unknown, specific document cannot be identified — security and/or undertaking may be required as detailed for the document or Procedure Code/Additional Procedure Code',
    },
    { id: 'XW', value: 'Waiver claimed' },
    {
        id: 'XX',
        value: 'To declare that goods are not subject to any licences other than those declared. Please see individual doc code completion rules where xx status code is allowed for details',
    },
];

let categoryCodes: any = [];
let typeCodes: any = [];
const categorySet = new Set();

documentsCategoryCode.forEach((element) => {
    const rest = element.id.slice(1, 4);
    categorySet.add(element.id[0]);
    typeCodes.push({ id: rest, value: '' });
});

categorySet.forEach((element) => {
    categoryCodes.push({ id: element, value: '' });
});

export { categoryCodes, typeCodes };
