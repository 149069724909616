import { NavItem } from 'views/declarations/common/nav-item';

export const sectionsTsdMainDocument: NavItem[] = [
    { title: 'Custom Office', href: 'person-lodging-summary-declaration-card' },
    { title: 'Representative', href: 'representative-form-card' },
    { title: 'Declarant', href: 'declarant-form-card' },
    { title: 'Presentation', href: 'tsd-goods-information-presentation-card' },
    { title: 'Mode of Transport At The Border', href: 'tsd-active-border-transport-means-form-card' },
    { title: 'Simplified Documents Writing Off Header', href: 'tsd-simplified-documents-writing-off-form-card' },
    { title: 'Additional Information', href: 'tsd-additional-information-form-card' },
    { title: 'Produced Documents Writing Off Header', href: 'tsd-produced-documents-writing-off-form-card' },
    { title: 'Places', href: 'tsd-goods-information-form-card' },
    { title: 'Transport Information', href: 'tsd-transport-information-form-card' },
    { title: 'Container Identification', href: 'tsd-container-identification-card' },
    { title: 'Supply Chain Actors', href: 'tsd-supply-chain-actors-form-card' },
];

export const sectionsTsdProducts: NavItem[] = [
    { title: 'Goods Information', href: 'tsd-goods-shipment-item-form-card' },
    { title: 'Simplified Document Writing Off', href: 'tsd-simplified-document-writing-off-form-card' },
    { title: 'Produced Documents Writing Off Header', href: 'tsd-produced-documents-writing-off-form-card' },
    { title: 'Additional Information', href: 'tsd-additional-information-form-card' },
    { title: 'Supply Chain Actors', href: 'tsd-supply-chain-actors-form-card' },
    { title: 'Packaging', href: 'tsd-packaging-card-form-card' },
    { title: 'Transport Information', href: 'tsd-transport-information-form-card' },
];
